const open = "Open";
const rejected = "Rejected";
const accepted = "Accepted";
const checkedOut = "CheckedOut";

export {
    open,
    rejected,
    accepted,
    checkedOut,
};
