import React from "react";

interface Props {
    fontAwesomeTitleLogoString?: string,
    logoUrl: string,
    title: string,
    body: React.ReactNode | string
}

const OrderConfirmedBanner = ({ logoUrl, title, body, fontAwesomeTitleLogoString }: Props) => (
    <div className="tw-rounded-lg tw-bg-white tw-grid lg:tw-grid-cols-3 tw-border">
        <div className="tw-rounded-t-lg tw-rounded-bl-none xl:tw-rounded-l-lg tw-col-span-1 lg:tw-col-span-1">
            <img
                src={logoUrl}
                alt="order confirmed"
                className="tw-rounded-t-lg tw-rounded-bl-none xl:tw-rounded-l-lg xl:tw-rounded-tr-none tw-w-full tw-h-full"
            />
        </div>
        <div className="tw-p-4 lg:tw-p-8 xl:tw-p-10 tw-col-span-1 lg:tw-col-span-2">
            <div className="tw-text-brand-primary tw-font-semibold tw-text-2xl tw-py-2 tw-w-full">
                {fontAwesomeTitleLogoString && <i className="fa fa-check-circle tw-text-brand-accent-yellow tw-mr-2" />}
                {title}
            </div>
            <div className="tw-text-sm lg:tw-text-base">
                {body}
            </div>
        </div>
    </div>
);

export default OrderConfirmedBanner;
