import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Rating from "@/Components/Rating";
import { Image } from "@/Components/Image";
import { MultilineTextTruncate } from "@/Components/MultilineTextTruncate";

const SupplierTile = ({ id, name, description, categoryId, categoryName, primarySiteColour, logo, rating, onSupplierClick, selectedSupplierId, selectedCategoryId }) => (
    <button
        type="button"
        data-testid={name}
        onClick={onSupplierClick}
        className={classnames(styles.container, "w-100 p-0 position-relative rounded-lg bg-transparent m-2", !onSupplierClick && styles.notActive)}
        // used to style a selected supplier as well as disable the button
        disabled={(selectedSupplierId === id && selectedCategoryId === categoryId) || !onSupplierClick}
    >
        <div className={classnames(styles.colourBar)} style={{ backgroundColor: primarySiteColour }} />
        <div className={classnames(styles.content, "position-relative p-4 d-flex flex-column box-shadow rounded-lg overflow-hidden")}>
            <div className="d-flex mb-3">
                <div className={classnames(styles.imageContainer, "ml-1 mr-3")}>
                    {logo
                        ? <Image src={logo} alt="logo" testId="supplierlogo" />
                        : <span className={styles.noImage} />}
                </div>
                <div>
                    <h5 className="overflow-hidden mb-1 font-weight-bold text-left">{name}</h5>
                    {rating > 0 && (<Rating rating={rating} className="text-left" />)}
                    {rating < 1 && (<div className="text-left">Not rated</div>)}
                </div>
            </div>
            <MultilineTextTruncate lines={10} text={description} className={classnames(styles.description, "flex-grow-1 text-left ml-1 mb-3")} />
            <div className={classnames(styles.category, "text-primary text-left font-weight-bold ml-1")}>{categoryName}</div>
        </div>
    </button>
);

SupplierTile.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    categoryId: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    primarySiteColour: PropTypes.string.isRequired,
    logo: PropTypes.string,
    rating: PropTypes.number.isRequired,
    onSupplierClick: PropTypes.func,
    selectedSupplierId: PropTypes.string,
    selectedCategoryId: PropTypes.string,
};

export default SupplierTile;
