export const areDuplicatesPresent = (array: any[]): boolean => {
    const count = {};

    for (let i = 0; i < array.length; i++) {
        const element = array[i];

        if (count[element]) {
            count[element]++;
        } else {
            count[element] = 1;
        }

        if (count[element] > 1) return true;
    }

    return false;
};

export const isDuplicated = <T>(value: T, array: T[]): boolean => {
    let count = 0;

    for (let i = 0; i < array.length; i++) {
        const element = array[i];

        // eslint-disable-next-line no-continue
        if (element !== value) continue;

        if (count === 1) { // We've already detected this one
            return true;
        }

        count++;
    }

    return false;
};
