import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, FormGroup } from "reactstrap";
import classnames from "classnames";
import { v4 as uuid } from "uuid";

import CustomiseModal from "./CustomiseModal";
import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { ColumnType, VariationType, Variations, VariationState } from "@/Components/ConfigureProductRow/ColumnTypes";
import Textbox from "@/Components/Textbox";
import { ConfigureProductRow } from "@/Components/ConfigureProductRow";
import { TableScrollbars } from "@/Components/TableScrollbars";
import { getSupplierOwnedVariations } from "@/Apis/Suppliers";
import DownloadListedCsvTemplate from "@/Modals/ServiceModal/Configure/DownloadListedCsvTemplate";
import UploadListedCsv from "@/Modals/ServiceModal/Configure/UploadListedCsv";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";

interface Props {
    onChange(key: string);
    serviceId: string;
    serviceName: string;
    requireMinimumQuantity?: boolean;
    productVariant: keyof ProductVariant;
    variations: Variations;
    previewElement: React.ReactElement;
    showPreview: boolean;
}

const Configure = ({ serviceId, onChange, serviceName, productVariant, variations, previewElement, showPreview, requireMinimumQuantity = false }: Props) => {
    const createNewRow = (column: ColumnType, rowId: string, rowIndex: number, value: any = undefined): VariationType => ({
        rowId,
        rowIndex,
        variationId: column.variationId,
        variationIndex: column.variationIndex,
        name: column.name,
        component: column.component,
        value: column.component === "ConfigurableSelect" ? value ?? column.defaultOptions : value,
    });

    const CreateColumnsAndRows = (variationsResponse: ColumnType[]) => variationsResponse.map(x => {
        if (variations.variationItems.length === 0) {
            return x;
        }

        const hasVariation = variations.variationItems.find(variation => variation.variationId === x.variationId) !== undefined;
        return {
            ...x,
            checked: hasVariation,
            currentVariations: hasVariation
                ? variations.variationItems.filter(variation => variation.variationId === x.variationId)
                : ProductVariations.getDistinctRows(variations.variationItems)
                    .map(rowId => createNewRow(x, rowId, variations.variationItems.find(r => r.rowId === rowId)?.rowIndex as number)),
        };
    });

    const [showCustomiseModal, setShowCustomiseModal] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [variationState, setVariationState] = useState<VariationState>({ allowMultipleSelection: variations.allowMultipleSelection, columns: [] });
    const findColumn = (columns: Array<ColumnType>, columnId: string) => columns.find(x => x.variationId === columnId) as ColumnType;
    const findRow = (column: ColumnType, rowId: string) => column.currentVariations.find(x => x.rowId === rowId) as VariationType;

    useEffect(() => {
        getSupplierOwnedVariations()
            .then((variationsResponse) => {
                let configuredVariations: ColumnType[] = [];

                if (productVariant === ProductVariants.listedProduct) {
                    configuredVariations = variationsResponse.filter(x => x.name !== "Quantity");
                } else {
                    configuredVariations = variationsResponse.map(x => (x.name === "Quantity" && requireMinimumQuantity ? { ...x, checked: true } : x));
                }

                setVariationState({ allowMultipleSelection: variations.allowMultipleSelection, columns: CreateColumnsAndRows(configuredVariations) });
            });
    }, []);

    useEffect(() => {
        if (variationState.columns[0]?.currentVariations && variationState.columns[0].currentVariations.length === 0) {
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
        }
    }, [variationState]);

    const onColumnChange = (columnId: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const updatedVariationState = { ...variationState };
        findColumn(updatedVariationState.columns, columnId).name = e.target.value;
        setVariationState(updatedVariationState);
    };

    const toggleCustomiseModal = () => setShowCustomiseModal(!showCustomiseModal);

    const updateProductVariations = (updatedVariationState: VariationState) => {
        setVariationState(updatedVariationState);
        const variationItems: Array<VariationType> = updatedVariationState.columns.filter(x => x.checked)
            .reduce((prev, current) => [...prev, ...current.currentVariations], [] as Array<VariationType>);
        const variationData: Variations = {
            allowMultipleSelection: updatedVariationState.allowMultipleSelection,
            variationItems,
        };
        onChange("variations")({ target: { value: { variationData } } });
    };

    const toggleCheckbox = (value: boolean) => () => updateProductVariations({ ...variationState, allowMultipleSelection: value });

    const onAddColumns = (columns: Array<ColumnType>) => {
        updateProductVariations({ ...variationState, columns });
    };

    const onAddRowClick = () => {
        const updatedVariationState = { ...variationState };
        const rowId: string = uuid();
        const rowIndex: number = updatedVariationState.columns[0].currentVariations.length;

        variationState.columns.forEach((customisation) => {
            const column = findColumn(updatedVariationState.columns, customisation.variationId);
            column.currentVariations = [...column.currentVariations, createNewRow(column, rowId, rowIndex)];
        });
        updateProductVariations(updatedVariationState);
    };

    const onComponentChange = (rowId: string, columnId: string) => (e: any) => {
        const updatedVariationState = { ...variationState };
        const column = findColumn(updatedVariationState.columns, columnId);
        const row = findRow(column, rowId);
        if (row) {
            row.value = e.target.value;
            updateProductVariations(updatedVariationState);
        }
    };

    return (
        <div className="mt-4">
            <div className="d-flex">
                <FormGroup>
                    {productVariant === ProductVariants.listedProduct
                    && (
                        <Checkbox
                            onChange={toggleCheckbox(!variationState.allowMultipleSelection)}
                            checked={variationState.allowMultipleSelection}
                            label="Allow multiple selection"
                            id="allowMultipleSelection"
                        />)}
                </FormGroup>
                <div className="m-auto text-danger">
                    {isInvalid && (<>Please add at least one row</>)}
                </div>
                <div className="ml-auto">
                    <Button outline color="primary" className="font-weight-bold" onClick={toggleCustomiseModal} data-testid="addColumn">
                        <i className="fas fa-plus-circle mr-1" />
                        Add Column
                    </Button>
                </div>
            </div>
            <div className={classnames(styles.boxShadowHeader, "d-flex")} />
            <TableScrollbars className="mt-4 table-responsive" xOverflow>
                <table className="table table-borderless">
                    <thead>
                        <tr className={classnames(styles.headRow, "d-block mb-4")}>
                            {variationState.columns
                                .map(customisation => customisation.checked && (
                                    <th scope="col" key={customisation.variationId}>
                                        <div
                                            className={classnames(ProductVariations.getComponentMeta(customisation.component).showHeader && styles.columnEdit, "d-flex")}
                                            style={{ width: ProductVariations.getComponentMeta(customisation.component).width }}
                                        >
                                            <div className="d-flex w-100">
                                                {ProductVariations.getComponentMeta(customisation.component).showHeader && (
                                                    <>
                                                        <div>
                                                            <Textbox
                                                                value={customisation.name}
                                                                onChange={onColumnChange(customisation.variationId)}
                                                                className="mb-0"
                                                                inputClasses={classnames(styles.columnTextbox, "p-0 rounded-0 font-weight-bold border-0")}
                                                            />
                                                        </div>
                                                        <div className="ml-auto">
                                                            <i className="fa fa-pen text-primary" />
                                                        </div>
                                                    </>)}
                                            </div>
                                        </div>
                                    </th>))}
                        </tr>
                    </thead>
                    <TableScrollbars style={{ height: "335px" }} yOverflow tBody>
                        {variationState.columns[0]?.currentVariations.map(row => (
                            <ConfigureProductRow
                                isEditor
                                key={row.rowId}
                                rowId={row.rowId}
                                serviceId={serviceId}
                                columns={variationState.columns}
                                onComponentChange={onComponentChange}
                            />))}
                    </TableScrollbars>
                </table>
            </TableScrollbars>
            <div className={classnames(styles.boxShadowFooter, "d-flex")} />
            <div className="d-flex">
                <Button outline color="primary" className="font-weight-bold mr-auto mt-4" onClick={onAddRowClick} data-testid="addrow">
                    <i className="fas fa-plus-circle mr-1" />
                    Add Row
                </Button>
                <div>
                    {productVariant === ProductVariants.listedProduct && (
                        <>
                            <DownloadListedCsvTemplate variationState={variationState} productName={serviceName} />
                            <UploadListedCsv variationState={variationState} createNewRow={createNewRow} updateProductVariations={updateProductVariations} />
                        </>)}
                    <Button outline color="primary" className="font-weight-bold ml-auto mt-4" onClick={previewElement.props.toggle} data-testid="preview">
                        Preview
                    </Button>
                </div>
            </div>
            <div className={classnames(styles.separator, "border-bottom mt-4")} />
            {showCustomiseModal && (<CustomiseModal
                toggle={toggleCustomiseModal}
                onAddColumns={onAddColumns}
                columns={variationState.columns}
                setVariationState={setVariationState}
                productVariant={productVariant}
            />)}
            {showPreview && (previewElement)}
        </div>
    );
};

export { Configure };
