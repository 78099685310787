import { nameof } from "@/Utils/objectHelper";
import { enumDisplay } from "@/Utils/formatStringHelper";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";

export interface FlexPlanOrder {
    id: string,
    orderNumber: number,
    orderStatus: FlexPlanOrderStatus,
    payFileId?: string,
    dateReceived: string,
    invoiceNumber: number,
    invoiceTotal: number,
    approvedTotal: number,
    orderItemIds: string[],
    // Participant
    participantId: string,
    participantName: string,
    // Supplier
    supplierId: string,
    supplierBusinessName: string,
}

export interface OrderSummary {
    new: number,
    hold: number,
    inProgress: number,
    approved: number,
    payFileCreated: number,
    paidFull: number,
    paidPartial: number,
    invalid: number,
}

export const orderStatusToDisplay = (field: FlexPlanOrderStatus | keyof OrderSummary) => {
    switch (field.toLowerCase()) {
        case "paidfull":
            return "Paid - Full";
        case "paidpartial":
            return "Paid - Partial";
        default:
            return enumDisplay(field);
    }
};

export const getFieldName = (name: keyof FlexPlanOrder) => nameof<FlexPlanOrder>(name);
