import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { formatStatus } from "@/Utils/orderStatusHelper";

const OrderStatusIcon = (props) => {
    const className = classnames("fa fa-circle mr-2 mb-1", styles.icon, styles[props.status.toLowerCase()]);
    return <span className="d-flex align-items-center py-2"><i className={className} />{formatStatus(props.status)}</span>;
};

OrderStatusIcon.propTypes = {
    status: PropTypes.string.isRequired,
};

export default OrderStatusIcon;
