import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import CreatableSelect from "react-select/creatable";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import TextArea from "@/Components/TextArea";
import { AutoSaveFileUpload } from "@/Components/AutoSaveFileUpload";
import { ProductVariants } from "@/Utils/ProductVariants";
import { acceptedFileTypes } from "@/Utils/constants";
import { preProcessImage, preProcessImageThumbnail } from "@/Utils/imageHelper";
import sortingUtil from "@/Utils/sortingUtil";

const General = ({
    serviceId,
    categories,
    subCategories = [],
    categoryId = "",
    subCategoryName = "",
    name = "",
    description = "",
    image,
    termsDocument,
    termsDocumentSaving,
    errors = {},
    onChange,
    onChangeMultiple,
    productType,
    productVariant,
}) => {
    const [localSubCategories, changeSubCategories] = useState({ categories: [], name: subCategoryName });
    const productTypes = [
        {
            label: "Other",
            value: "",
        },
        {
            label: "Company Formations",
            value: ProductVariants.formationsProduct,
        },
        {
            label: "Ready-made Company",
            value: ProductVariants.readyMadeFormationsProduct,
        },
        {
            label: "Bank Account",
            value: ProductVariants.bankAccountProduct,
        },
    ];
    const currentProductType = productVariant === ProductVariants.singleProduct
        && productType !== ProductVariants.formationsProduct
        && productType !== ProductVariants.readyMadeFormationsProduct
        && productType !== ProductVariants.bankAccountProduct ? "" : productType;

    const onDrop = (files) => {
        files.forEach((x) => {
            preProcessImage(x) // compressed main product image to fit within upload limit
                .then((encodedImageMain) => (
                    preProcessImageThumbnail(x) // process a thumbnail
                        .then((encodedImageThumb) => onChangeMultiple({ image: encodedImageMain.target.result, imageThumbnail: encodedImageThumb.target.result }))));
        });
    };

    const onChangeSubcategory = (event) => {
        if (!event) {
            changeSubCategories({ ...localSubCategories, name: "" });
            return;
        }
        changeSubCategories({ ...localSubCategories, name: event.label });
        onChange("subCategoryName")({ target: { value: event.label } });
    };

    const onHandleCreateSubcategory = (inputValue) => {
        // push the new value into the state so we can display what the user has typed
        changeSubCategories({
            categories: [...localSubCategories.categories, { label: inputValue, value: inputValue }],
            name: inputValue,
        });
        onChange("subCategoryName")({ target: { value: inputValue } });
    };

    const onTermsDocumentAdded = () => {
        termsDocumentSaving(true);
    };

    const onTermsDocumentUploaded = (fileName) => {
        termsDocumentSaving(false);
        onChangeMultiple({ termsDocument: fileName });
    };

    return (
        <div>
            <p className="my-4">Please fill up all needed information below to add a service.</p>

            <FormGroup>
                <Label>Category *</Label>
                <Input
                    type="select"
                    placeholder="Pick Category"
                    value={categoryId || "none"}
                    invalid={!!errors.categoryId}
                    onChange={onChange("categoryId")}
                    data-testid="service-category"
                >
                    <option disabled value="none">Pick Category</option>
                    {categories
                        .sort((x, y) => sortingUtil.sort(x, y, "name", true))
                        .map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                </Input>
                <FormFeedback className="font-weight-bold">{errors.categoryId}</FormFeedback>
            </FormGroup>

            <FormGroup>
                <Label>Sub Category *</Label>
                <CreatableSelect
                    placeholder="Enter a sub category where this service will belong to"
                    styles={errors.subCategoryName && { control: x => ({ ...x, border: "1px solid #b73847" }) }}
                    value={[...subCategories, ...localSubCategories.categories].find(x => x.label === localSubCategories.name) || {}}
                    isClearable
                    isSearchable
                    isDisabled={!categoryId}
                    onChange={onChangeSubcategory}
                    onCreateOption={onHandleCreateSubcategory}
                    options={[...subCategories, ...localSubCategories.categories]}
                />
                <FormFeedback className={classnames(errors.subCategoryName && "d-block font-weight-bold")}>{errors.subCategoryName}</FormFeedback>
            </FormGroup>

            <FormGroup className="pb-3">
                <Label>Service Name *</Label>
                <Input
                    type="text"
                    placeholder="Service Name"
                    value={name || ""}
                    invalid={!!errors.name}
                    onChange={onChange("name")}
                    data-testid="service-name"
                />
                <FormFeedback className="font-weight-bold">{errors.serviceName}</FormFeedback>

            </FormGroup>

            <TextArea
                label="Service Description *"
                type="textarea"
                placeholder="Service Description"
                value={description || ""}
                error={errors.description}
                onChange={onChange("description")}
                maxCharacters={140}
                data-testid="service-description"
            />

            <FormGroup className={classnames("mt-3 border rounded-lg p-3", errors.image && "border-danger")}>
                <Dropzone onDrop={onDrop} maxSize={8000000} accept={acceptedFileTypes.images}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="d-flex justify-content-between align-items-center">
                            {image
                                ? <Image src={image} alt="service" className={styles.image} />
                                : <span>Please select an image file for your service *</span>}
                            <Button type="button" color="primary" {...getRootProps()}>
                                <input {...getInputProps()} data-testid="service-image" />
                                {image ? "Change File" : "Pick File"}
                            </Button>
                        </div>
                    )}
                </Dropzone>
                <FormFeedback className={classnames(errors.image && "d-block font-weight-bold")}>{errors.image}</FormFeedback>
            </FormGroup>
            <div className="tw-mb-4">
                <Label>
                    Product Type
                </Label>
                <Select
                    value={productTypes?.find(x => productVariant === ProductVariants.singleProduct && x.value === currentProductType)}
                    onChange={e => onChange("productType")({ target: { value: e?.value } })}
                    options={productTypes}
                    isDisabled={productVariant !== ProductVariants.singleProduct}
                />
            </div>
            <AutoSaveFileUpload
                documentName={termsDocument}
                onFileAdded={onTermsDocumentAdded}
                onFileUploaded={onTermsDocumentUploaded}
                heading="Terms and Conditions Document"
                autoSaveRoute={`services/${serviceId}/terms`}
            />
        </div>
    );
};

General.propTypes = {
    serviceId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeMultiple: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    categoryId: PropTypes.string,
    subCategoryName: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    termsDocument: PropTypes.string,
    termsDocumentSaving: PropTypes.func.isRequired,
    errors: PropTypes.shape(),
    useDescription: PropTypes.bool,
    isCompanyFormationsProduct: PropTypes.bool,
    productType: PropTypes.string,
    productVariant: PropTypes.oneOf([ProductVariants.singleProduct, ProductVariants.listedProduct, ProductVariants.customisableProduct]),
};

export { General };
