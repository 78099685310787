import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Media } from "reactstrap";

import { SignUpForm } from "@/Components/SignUpForm";
import { LoginForm } from "@/Components/LoginForm";
import CloseButton from "@/Components/CloseButton";
import Modal from "@/Modals/Modal";
import * as authentication from "@/Utils/authentication";
import notificationActions from "@/Store/Notifications/actions";
import actions from "@/Store/User/actions";

const GuestAuthenticationModal = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onLoginSuccess = (response) => {
        authentication.setToken(response.token, response.tokenExpiry);
        response.profileMetadata.guestCheckout = true;
        dispatch(actions.setUser(response.profileMetadata));
        dispatch(notificationActions.loadNotifications$());
        history.push("/checkout");
    };

    const closeModal = () => history.push("/basket");

    const navigateToLogin = () => history.push("/basket/login");

    const navigateToSignUp = () => history.push("/basket/sign-up");

    return (
        <>
            <Route
                path="/basket"
                render={({ match: { url } }) => (
                    <Switch>
                        <Route exact path={`${url}/login`}>
                            <Modal isOpen>
                                <CloseButton onClick={closeModal} />
                                <Media object src={`${window.cdnUrl}misc/logo-with-text.jpg`} alt="logo" className="d-block my-4 mx-auto w-50" />
                                <span className="text-center my-4 d-inline-block">
                                    Login or create an account for the <strong>Business Support Club</strong> to experience all the features we can offer for your business.
                                </span>
                                <LoginForm onSuccess={onLoginSuccess} onNavigateToSignUp={navigateToSignUp} />
                            </Modal>
                        </Route>
                        <Route path={`${url}/sign-up`}>
                            <Modal isOpen>
                                <CloseButton onClick={closeModal} />
                                <SignUpForm onSuccess={onLoginSuccess} onNavigateToLogin={navigateToLogin} />
                            </Modal>
                        </Route>
                    </Switch>
                )}
            />
        </>
    );
};

export default GuestAuthenticationModal;
