import React, { useState } from "react";
import { Button, Table } from "reactstrap";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";

import { DebounceTextbox } from "@/Components/DebounceTextbox";
import FormatDateHelper from "@/Utils/formatDateHelper";
import Spinner from "@/Components/Spinner";
import { Paging } from "@/Components/Paging";
import Checkbox from "@/Components/Checkbox";
import { toCurrencyWithCommas } from "@/FlexPlan/Utils/currency";
import { newOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Hooks/newOrderHelper";
import { FlexPlanOrder, getFieldName, orderStatusToDisplay } from "@/FlexPlan/Pages/Orders/OrderManagement/Types";
import { useSupplierOrders } from "@/FlexPlan/Pages/Orders/OrderManagement/Hooks/useSupplierOrders";
import { FilterOrdersDropdown } from "@/FlexPlan/Pages/Orders/OrderManagement/Components/FilterOrdersDropdown";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";
import { CreatePayFileModal } from "@/FlexPlan/Pages/Orders/OrderManagement/Modals/CreatePayFileModal";

const columns = [
    {
        displayName: "Order No.",
        fieldName: getFieldName("orderNumber"),
    },
    {
        displayName: "Order Status",
        fieldName: getFieldName("orderStatus"),
    },
    {
        displayName: "Pay File ID",
        fieldName: getFieldName("payFileId"),
    },
    {
        displayName: "Participant",
        fieldName: getFieldName("participantName"),
    },
    {
        displayName: "Supplier",
        fieldName: getFieldName("supplierBusinessName"),
    },
    {
        displayName: "Date Received",
        fieldName: getFieldName("dateReceived"),
    },
    {
        displayName: "Invoice No.",
        fieldName: getFieldName("invoiceNumber"),
    },
    {
        displayName: "Invoice Total",
        fieldName: getFieldName("invoiceTotal"),
    },
    {
        displayName: "Approved Total",
        fieldName: getFieldName("approvedTotal"),
    },
] as { displayName: string, fieldName: keyof FlexPlanOrder }[];

const OrderListing = () => {
    const {
        onSort,
        sortColumn,
        sortDescending,
        loading,
        error,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
        onSearch,
        onChangeFilter,
        filters,
        getTailwindStatusColour,
        onCheckboxChange,
        checkedRows,
        initialItems, // We use these to find checked rows spanning different pages
        refreshOrders,
        onGetPaymentStatusForOrders,
        updatingOrderPaymentStatuses,
    } = useSupplierOrders();

    const { push } = useHistory();

    const renderSortIcon = (column: keyof FlexPlanOrder) => {
        if (sortColumn && sortColumn === column) {
            if (sortDescending) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }

        return <i className="fa fa-sort" />;
    };

    const renderColumn = (displayName: string, fieldName: keyof FlexPlanOrder) => (
        <th style={{ width: "11.1%" }} key={displayName}>
            <button
                type="button"
                className="bg-transparent p-0 b-0"
                onClick={() => onSort(fieldName)}
            >
                {displayName}&nbsp;
                {renderSortIcon(fieldName)}&nbsp;
            </button>
        </th>
    );

    // Create Pay File Modal
    const [payFileModalOpen, setPayFileModalOpen] = useState<boolean>(false);
    const toggleOpen = () => setPayFileModalOpen(prev => !prev);

    const payFileRows = initialItems?.filter(x => x.orderStatus === FlexPlanOrderStatus.Approved
        && x.supplierId
        && x.participantId
        && checkedRows.includes(x.id)) ?? [];

    return (
        <div>
            {/* Filters */}
            <div className="tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0 md:tw-flex-row md:tw-space-x-4 tw-mt-10">
                <div className="tw-relative tw-w-full md:tw-w-1/5 tw-mr-3">
                    <DebounceTextbox
                        placeholder="Search Participant/Supplier"
                        callBack={onSearch}
                        name="Search Supplier Orders"
                    />
                    <i className="fa-lg fas fa-search tw-absolute tw-right-4 tw-top-2" />
                </div>
                <Button
                    color="primary"
                    className="tw-h-9"
                    onClick={() => {
                        const createdOrder = newOrder();
                        push(`/orders/${createdOrder.id}`, createdOrder);
                    }}
                >
                    Upload Invoice
                </Button>
                <Button
                    color="primary"
                    className="tw-h-9"
                    onClick={toggleOpen}
                    disabled={checkedRows.length === 0}
                >
                    Create Pay File
                </Button>
                <Button color="primary" className="tw-h-9" disabled={updatingOrderPaymentStatuses} onClick={onGetPaymentStatusForOrders}>
                    {updatingOrderPaymentStatuses ? <Spinner className="tw-mx-auto" size="25" /> : "Update PR Status"}
                </Button>
                {filters && (
                    <FilterOrdersDropdown
                        filters={filters}
                        onChange={onChangeFilter}
                    />
                )}
            </div>
            {/* Table */}
            <Table responsive hover className="tw-mt-3">
                <thead>
                    <tr>
                        <td />
                        {/* checkbox */}
                        {columns.map(col => (
                            renderColumn(col.displayName, col.fieldName)
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!loading && !error && paginatedItems && paginatedItems.length > 0 && (
                        paginatedItems?.map(row => (
                            <tr key={row.id}>
                                <td>
                                    {row.orderStatus === FlexPlanOrderStatus.Approved
                                        && row.participantId
                                        && row.supplierId
                                        && (
                                            <Checkbox
                                                onChange={onCheckboxChange(row.id)}
                                                checked={checkedRows.includes(row.id)}
                                                id={row.id}
                                            />
                                        )}
                                </td>
                                <td>
                                    <Link
                                        to={`/orders/${row.id}`}
                                        className="!tw-text-flexplan-secondary !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {row.orderNumber}
                                    </Link>
                                </td>
                                <td>
                                    <span className="tw-flex tw-items-center">
                                        <i className={classnames("fa fa-circle mr-2 mb-1 [&>*]:tw-w-2 [&>svg]:tw-h-2", getTailwindStatusColour(row.orderStatus))} />
                                        <span>{orderStatusToDisplay(row.orderStatus)}</span>
                                    </span>
                                </td>
                                <td>
                                    {row.payFileId}
                                </td>
                                <td>
                                    <Link
                                        to={`/participants/${row.participantId}/personal-information`}
                                        className="!tw-text-flexplan-secondary !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {row.participantName}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={`/suppliers/${row.supplierId}/public-information`}
                                        className="!tw-text-flexplan-secondary !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {row.supplierBusinessName}
                                    </Link>
                                </td>
                                <td className="tw-flex tw-flex-col">
                                    <span>{FormatDateHelper.format(row.dateReceived, "DD/MM/YYYY")}</span>
                                    <span className="tw-text-gray-400">{FormatDateHelper.format(row.dateReceived, "HH:mm:ss")}</span>
                                </td>
                                <td>
                                    {row.invoiceNumber}
                                </td>
                                <td>
                                    {toCurrencyWithCommas(row.invoiceTotal)}
                                </td>
                                <td>
                                    {toCurrencyWithCommas(row.approvedTotal)}
                                </td>
                            </tr>
                        ))
                    )}
                    {loading && (
                        <tr>
                            <td colSpan={9}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                        </tr>
                    )}
                    {error && (
                        <tr className="tw-text-red-600 tw-mx-auto tw-mt-4">
                            <td colSpan={9}>{error}</td>
                        </tr>
                    )}
                    {!loading && !error && paginatedItems?.length === 0 && (
                        <tr className="tw-mx-auto tw-mt-4">
                            <td colSpan={9}>No Supplier Orders</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
            {initialItems && checkedRows.length > 0 && (
                <CreatePayFileModal
                    isOpen={payFileModalOpen}
                    rows={payFileRows}
                    toggle={toggleOpen}
                    refreshOrders={refreshOrders}
                />
            )}
        </div>
    );
};

export { OrderListing };
