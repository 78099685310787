import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const SideBarLink = ({ text, icon, isActive, to, disabled }) => (
    <Button
        tag={Link}
        to={to}
        color="link"
        className={classnames(
            styles.container,
            isActive && styles.active,
            "d-flex py-3 text-nowrap bg-transparent",
        )}
        disabled={disabled || isActive}
    >
        {icon}
        {text}
    </Button>
);

SideBarLink.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    to: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

SideBarLink.defaultProps = {
    disabled: false,
};

export default SideBarLink;
