import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Select from "react-select";

import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { UserTypeItem, UserUpsell } from "@/Apis/UserTypes";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { ToolTip } from "@/Components/ToolTip";
import CloseButton from "@/Components/CloseButton";

interface Props {
    isOpenModal: boolean;
    productId: string;
    onCancel: () => void,
}

const PurchaseUpsellsModal = ({ isOpenModal, productId, onCancel } : Props) => {
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();
    const { get, post } = useAPI({ handle500WithRedirect: true });

    const [userUpsell, setUserUpsell] = useState<UserUpsell>();
    const [userTypeItems, setUserTypeItems] = useState<UserTypeItem[]>([]);

    const [isDirty, setIsDirty] = useState<boolean>(false);

    const [processing, setProcessing] = useState<boolean>(false);
    const [cancelOpenModal, setCancelOpenModal] = useState<boolean>(false);
    const [saveOpenModal, setSaveOpenModal] = useState<boolean>(false);

    const getAllUserTypeItems = () => {
        get<UserTypeItem[]>(Urls.userUpsells.userTypes.base)
            .then(response => setUserTypeItems(response))
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true));
    };

    const getUserUpsell = (isReload: boolean = false) => {
        setProcessing(true);
        get<UserUpsell>(Urls.userUpsells.get(productId))
            .then((response) => {
                setUserUpsell({
                    ...response,
                    savedUserTypeId: isReload ? userUpsell?.savedUserTypeId : response?.savedUserTypeId,
                    savedTagId: isReload ? userUpsell?.savedTagId : response?.savedTagId,
                } as UserUpsell);
                setProcessing(false);
                setIsDirty(false);
            })
            .catch(() => {
                setPopupErrorMessage("Unfortunately an error occurred", true);
                onCancel();
            });
    };

    useEffect(() => {
        getAllUserTypeItems();
        getUserUpsell();
    }, []);

    const handleCancelRequest = () => {
        if (!isDirty) {
            onCancel();
        } else {
            setCancelOpenModal(true);
        }
    };

    const handleCancelConfirmRequest = () => {
        onCancel();
    };

    const handleSaveRequest = () => {
        if (!userUpsell?.savedUserTypeId) {
            setPopupErrorMessage("User Type is required", true);
            return;
        }
        setSaveOpenModal(true);
    };

    const handleSaveConfirmRequest = () => {
        setProcessing(true);
        post(Urls.userUpsells.base, {
            productId: userUpsell?.productId,
            userTypeId: userUpsell?.savedUserTypeId,
            tagId: userUpsell?.savedTagId,
        })
            .then(() => {
                setSuccessMessage("Tags updated successfully", true);
                setIsDirty(false);
                getUserUpsell(true);
            })
            .catch(() => {
                setPopupErrorMessage("Unfortunately an error occurred", true);
            })
            .finally(() => {
                setSaveOpenModal(false);
                setProcessing(false);
            });
    };

    const handleUserTypesSelectChange = (selectedOption) => {
        setUserUpsell({
            ...userUpsell,
            savedUserTypeId: selectedOption?.value,
            savedTagId: userUpsell?.savedUpsells?.find(x => x.userTypeId === selectedOption?.value)?.tagId ?? null,
        } as UserUpsell);
        setIsDirty(true);
    };

    const handleTagsSelectChange = (selectedOption) => {
        setUserUpsell({
            ...userUpsell,
            savedTagId: selectedOption?.value,
        } as UserUpsell);
        setIsDirty(true);
    };

    const selectFilterOption = (option: any, inputValue: string): boolean => (option.label?.toString()?.toLowerCase().match(inputValue?.toLowerCase()) || []).length > 0;

    return (
        <Modal
            isOpen={isOpenModal}
            centered
            size="lg"
        >
            <CloseButton onClick={handleCancelRequest} />
            <ModalBody
                data-testid="purchase-upsells-modal"
                className="tw-m-8"
            >
                {!processing && (
                    <>
                        <div className="tw-text-xl tw-font-bold tw-mb-5">
                            Set Purchase Upsells for {userUpsell?.productName}
                        </div>
                        <div>
                            <>
                                Current Product tile is
                                <b><i> {userUpsell?.productName} </i></b>
                                {userUpsell?.productSupplierName && (<>by <b><i> {userUpsell?.productSupplierName}</i></b></>)}.
                                Select a user type to manage Product Upsell tags.
                            </>
                        </div>
                        <div>
                            User Type
                        </div>
                        <Select
                            options={userTypeItems}
                            value={userTypeItems.filter(x => x.value === userUpsell?.savedUserTypeId)}
                            onChange={handleUserTypesSelectChange}
                            isClearable
                            placeholder="Select User Type"
                            filterOption={selectFilterOption}
                        />
                        <div className="tw-pt-4">
                            Select a tag for this product below:
                        </div>
                        <div>
                            Tags
                        </div>
                        <div className="tw-grid tw-grid-cols-12">
                            <div className="tw-col-span-11">
                                <Select
                                    options={userTypeItems?.find(x => x.value === userUpsell?.savedUserTypeId)?.selectedTagItems}
                                    value={userTypeItems?.find(x => x.value === userUpsell?.savedUserTypeId)?.selectedTagItems
                                        ?.find(y => y.value === userUpsell?.savedTagId) ?? null}
                                    onChange={handleTagsSelectChange}
                                    isClearable
                                    placeholder="Select Tags"
                                    filterOption={selectFilterOption}
                                />
                            </div>
                            <div className="tw-col-span-1">
                                <ToolTip
                                    description={
                                        <>
                                            You can find a list of different tags for this selected User Type <br />
                                            which can be customized for this product’s recommended upsells. <br />
                                            Go to <b>Admin Tools &#62; Manage Tags</b> to add more tags for this User Type.
                                        </>
                                    }
                                    id="ttip-tags"
                                    className="float-right"
                                >
                                    <i className="fas fa-info-circle tw-text-gray-400" />
                                </ToolTip>
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-8">
                            <Button
                                color="primary"
                                outline
                                onClick={handleCancelRequest}
                                data-testid="btn-cancel"
                                className="tw-w-16"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                onClick={handleSaveRequest}
                                data-testid="btn-done"
                                disabled={!userUpsell?.savedUserTypeId}
                                className="tw-w-16"
                            >
                                Save
                            </Button>
                        </div>
                    </>
                )}
            </ModalBody>
            <ConfirmCancelModal
                isOpen={cancelOpenModal}
                text="You have unsaved changes. Are you sure that you want to cancel?"
                onConfirm={handleCancelConfirmRequest}
                onClose={() => setCancelOpenModal(false)}
                loading={processing}
            />
            <ConfirmCancelModal
                isOpen={saveOpenModal}
                text="Are you sure that you want to save?"
                onConfirm={handleSaveConfirmRequest}
                onClose={() => setSaveOpenModal(false)}
                loading={processing}
            />
        </Modal>
    );
};

export { PurchaseUpsellsModal };
