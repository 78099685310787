import React, { useEffect, useState } from "react";
import { FormFeedback, Input, Label } from "reactstrap";

import styles from "./styles.module.scss";

import { countDecimals, countDots, isNumeric } from "@/Utils/numberHelper";

interface Props {
    className?: string,
    label: string,
    value?: number | string,
    min?: number,
    max?: number,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    prefixChar?: string,
    suffixChar?: string,
    maxDecimalPlaces?: string | number,
    testId?: string,
    maxLength?: number,
    isRequired?: boolean,
    error?: string,
    disabled?: boolean,
    placeholder?: string,
    greaterThanMsg?: string,
    name?: string,
    onBlur?: () => void,
}

const NumberField = ({ className,
    label,
    value,
    min,
    max,
    onChange,
    prefixChar,
    suffixChar,
    maxDecimalPlaces,
    testId,
    maxLength,
    isRequired,
    error,
    disabled,
    placeholder,
    greaterThanMsg,
    onBlur,
    name }: Props) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const oldValueRef = React.useRef<string>(value?.toString() ?? "");

    // Display errors for 2 seconds as we automatically remove bad data
    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorMessage(undefined);
        }, 2000);

        return () => clearTimeout(timer);
    }, [errorMessage]);

    const setDataChanged = (e: React.ChangeEvent<HTMLInputElement>, isValid: boolean, newValue: string) => {
        if (isValid) {
            e.target.value = newValue;
            oldValueRef.current = newValue;
        } else {
            const oldValue = oldValueRef.current;
            e.target.value = oldValue;
        }
        onChange(e);
    };

    const onChangeOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value.replace(/^0{2,}/, "0").replace(/^-0{2,}/, "-0");
        if (newValue !== "0" && !newValue.startsWith("0.")) {
            newValue = newValue.replace(/^0+/, "");
        }
        if (newValue === "") {
            return setDataChanged(e, true, newValue);
        }
        if (newValue === "-" && ((min && min < 0) || (!min && min !== 0))) {
            return setDataChanged(e, true, newValue);
        }
        if (!isNumeric(newValue)) {
            return setDataChanged(e, false, newValue);
        }
        const numericValue = parseFloat(newValue);
        if ((max || max === 0) && numericValue > max) { // Ensure max not breached
            setErrorMessage(greaterThanMsg ?? `Must be less than ${max}`);
            return setDataChanged(e, false, newValue);
        }
        if ((min || min === 0) && numericValue < min) { // Ensure min not breached
            setErrorMessage(`Must be greater than ${min}`);
            return setDataChanged(e, false, newValue);
        }
        if (maxDecimalPlaces === 0 && countDots(newValue)) { // Ensure only integer
            setErrorMessage("Only integer number");
            return setDataChanged(e, false, newValue);
        }
        if (!!maxDecimalPlaces && countDecimals(newValue) > maxDecimalPlaces) { // Check decimal places
            setErrorMessage(`Max ${maxDecimalPlaces} decimal places`);
            return setDataChanged(e, false, newValue);
        }
        if (errorMessage) { // Remove error if we're good
            setErrorMessage(undefined);
        }
        return setDataChanged(e, true, newValue);
    };

    return (
        <div className={`tw-flex-col tw-relative ${className}`} onBlur={onBlur}>
            {label && <Label>{label}</Label>}
            <Input
                type="text"
                min={min}
                max={max}
                maxLength={maxLength}
                onChange={onChangeOverride}
                value={value ?? ""}
                className={`${prefixChar && "!tw-pl-6"} ${suffixChar && "!tw-pr-6"}`}
                data-testid={testId}
                required={isRequired}
                invalid={!!error || !!errorMessage}
                disabled={disabled}
                placeholder={placeholder}
                name={name}
            />
            {prefixChar && <span className={`tw-absolute tw-text-sm ${label ? styles.top41px : styles.top9px} tw-left-4`}>{prefixChar}</span>}
            {suffixChar && <span className={`tw-absolute tw-text-sm ${label ? styles.top41px : styles.top9px} tw-right-4`}>{suffixChar}</span>}
            {(!!error || !!errorMessage) && <FormFeedback className="font-weight-bold" data-testid={`${testId}-error`}>{error || errorMessage}</FormFeedback>}
        </div>
    );
};

export default NumberField;
