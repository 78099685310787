import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button, FormGroup, Modal, ModalBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

import Tag from "@/Components/Tag";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import ComponentBuilder from "@/Components/ComponentBuilder";
import { MessagingUrls } from "@/Apis/Messaging";
import { useAPI } from "@/Apis/useAPI";
import { encodeFilesToBase64WithFileName } from "@/Utils/base64EncodeHelper";
import api from "@/Utils/api";
import actions from "@/Store/Global/actions";
import { acceptedFileTypes } from "@/Utils/constants";

const EnquiryFormModal = ({ onClose, enquiryFormId, startingPrice, name, description }) => {
    const dispatch = useDispatch();
    const [conversationId] = useState(uuid());
    const [editorState, setEditorState] = useState(() => createEditorState(null));
    const [showDropValidation, setShowDropValidation] = useState(false);
    const [isEncodingFiles, setIsEncodingFiles] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [enquiryFormFields, setEnquiryFormFields] = useState([]);
    const [enquiryFormValidation, setEnquiryFormValidation] = useState(false);
    const { loading, post, get } = useAPI({ handle500WithRedirect: true });

    const enquiryFormValidator = () => {
        if (!editorState.getCurrentContent().getPlainText()) {
            return false;
        }

        if (enquiryFormFields.find(x => x.isRequired && !x.value)) {
            return false;
        }
        return true;
    };

    const onChange = (changedEditorState) => {
        setEditorState(() => changedEditorState);
        setEnquiryFormValidation(enquiryFormValidator());
    };

    const onFieldChange = (fieldId, e) => {
        const field = enquiryFormFields.find(x => x.fieldId === fieldId);
        if (field) {
            if (field.type === "Date" || field.type === "UploadAttachment" || field.type === "YesNo") {
                field.value = e;
            } else if (field.type === "AddressLookup") {
                field.value = JSON.stringify(e);
            } else {
                field.value = e.target.value;
            }
        }
        setEnquiryFormFields([...enquiryFormFields]);
        setEnquiryFormValidation(enquiryFormValidator());
    };

    const onDrop = async (files) => {
        setShowDropValidation(() => false);
        setIsEncodingFiles(() => true);
        const encodedFiles = await encodeFilesToBase64WithFileName(files);

        encodedFiles.forEach(file => {
            const url = MessagingUrls.uploadConversationAttachment(conversationId);
            const payload = { base64: file.data, conversationId, filename: file.name };

            post(url, payload)
                .then(response => {
                    setAttachments(prevState => ([...prevState, response]));
                    setIsEncodingFiles(() => false);
                });
        });
    };

    useEffect(() => {
        get(`enquiryform/get-enquiry-fields/${enquiryFormId}`)
            .then((response) => {
                setEnquiryFormFields(response.formFields);
            });
    }, []);

    const onDropRejected = () => {
        setShowDropValidation(true);
    };

    const onDeleteAttachment = nameToDelete => () => {
        setAttachments(attachments.filter(x => x.name !== nameToDelete));
    };

    const onSubmit = async () => {
        try {
            setIsSubmitting(() => true);
            await api.post("enquiryform", {
                enquiryFormId,
                conversationId,
                description: getContentStateAsJson(editorState),
                attachments,
                enquiryFormFields,
            });
            onClose();
            dispatch(actions.setToastMessage(true, "Your enquiry has been sent to the supplier!"));
        } catch (error) {
            dispatch(actions.setErrorToastMessage(true, error.message));
        } finally {
            setIsSubmitting(() => false);
        }
    };

    return (
        <Modal size="lg" centered isOpen>
            <ModalBody className="m-3">
                <h4 className="font-weight-bold">{name}</h4>
                <p className="mb-4">Starts at <span className="h6 font-weight-bold ml-1">&pound; {startingPrice.toFixed(2)}</span></p>
                {description && <p className="flex-grow-1">{description}</p>}
                <FormGroup>
                    <RichTextEditor
                        placeholder="Type your enquiry here"
                        onChange={onChange}
                        editorState={editorState}
                    />
                </FormGroup>
                <div className="d-flex flex-wrap mt-4">
                    {attachments.map(x => (<Tag
                        key={x.filename}
                        classnames="border-dark text-dark"
                        name={x.filename}
                        useAttachmentIcon
                        displayClose
                        onClose={onDeleteAttachment(x.name)}
                    />))}
                </div>
                {enquiryFormFields
                    && enquiryFormFields.map(formField => (<ComponentBuilder
                        key={formField.fieldId}
                        fields={[{ ...formField, className: "!tw-max-w-full !tw-w-full" }]}
                        onChange={() => (e, isValid = true) => {
                            onFieldChange(formField.fieldId, e, isValid);
                        }}
                    />))}
                <div className="d-flex mt-4">
                    <div>
                        {showDropValidation && <div className="alert alert-warning">Files must be less than 8MB, not a .exe or .dll.</div>}
                        <Dropzone disabled={isEncodingFiles} onDrop={onDrop} onDropRejected={onDropRejected} maxSize={8000000} accept={acceptedFileTypes.documents} multiple>
                            {({ getRootProps, getInputProps }) => (
                                <button type="button" className={classnames(styles.icon, "p-0 border-0 bg-transparent text-dark")} disabled={isEncodingFiles} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {!isEncodingFiles ? (<i className="fa fa-paperclip mr-2" />) : (<i className="fas fa-circle-notch fa-spin" />)}
                                </button>
                            )}
                        </Dropzone>
                    </div>
                    <div className="ml-auto">
                        <Button color="secondary" className="mr-2" onClick={onClose}>Cancel</Button>
                        <Button color="primary" disabled={!enquiryFormValidation || loading} onClick={onSubmit}>
                            {!isSubmitting ? "Submit" : (<i className="fas fa-circle-notch fa-spin" />)}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

EnquiryFormModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    enquiryFormId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    startingPrice: PropTypes.number.isRequired,
};

export { EnquiryFormModal };
