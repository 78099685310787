import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input, Row, Col, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";

import Checkbox from "../../Checkbox";
import { ViewBespokeQuoteItemPrice } from "../BespokeQuoteItemPrice";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";

const EditBespokeQuoteItem = ({ item, onSend, onItemRemoved, onItemUpdated }) => {
    const { handleSubmit, register, errors, triggerValidation, setValue } = useForm();

    useEffect(() => {
        setValue("name", item.name);
        setValue("price", item.price);
        setValue("totalUnits", item.totalUnits);
        setValue("pricingUnit", item.pricingUnit);
        setValue("description", item.description);

        onSend(item.id, () => new Promise(resolve => handleSubmit(() => {})()
            .then(() => triggerValidation().then(formValid => resolve({ item, formValid })))));
    }, [item, onSend, handleSubmit, setValue, triggerValidation]);

    const fieldUpdated = (name, value) => onItemUpdated({
        ...item,
        [name]: value,
    });

    const onRemove = () => onItemRemoved(item.id);

    return (
        <Form className="rounded-lg border mx-2 mt-3 p-3 pt-4 position-relative" data-testid="edit-bespoke-quote-item">
            <CloseButton onClick={onRemove} className={styles.closeButton} />
            <Row className="mt-2">
                <Col xs={6}>
                    <FormGroup>
                        <Label>Item Name</Label>
                        <Input
                            type="text"
                            name="name"
                            className={styles.underline}
                            invalid={Boolean(errors.name)}
                            innerRef={register({ required: true })}
                            onChange={e => fieldUpdated(e.target.name, e.target.value)}
                        />
                        {errors.name && <FormFeedback className="font-weight-bold">Please provide item name</FormFeedback>}
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <Label>Pricing</Label>
                        <Input
                            type="number"
                            name="price"
                            min="0"
                            step="0.25"
                            className={styles.underline}
                            invalid={Boolean(errors.price)}
                            innerRef={register({ required: true, min: 0 })}
                            onChange={e => fieldUpdated(e.target.name, e.target.value)}
                        />
                        {errors.price && <FormFeedback className="font-weight-bold">Invalid price</FormFeedback>}
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <Label for="pricingUnit">Unit</Label>
                        <Input
                            type="select"
                            name="pricingUnit"
                            innerRef={register()}
                            onChange={e => fieldUpdated(e.target.name, e.target.value)}
                        >
                            <option value={1}>/ hr</option>
                            <option value={0}>/ ea</option>
                            <option value={2}>/ day</option>
                            <option value={3}>/ mo</option>
                            <option value={4}>/ set</option>
                            <option value={5}>N/A</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <Label>Total Units</Label>
                        <Input
                            min="1"
                            type="number"
                            name="totalUnits"
                            className={styles.underline}
                            invalid={Boolean(errors.totalUnits)}
                            innerRef={register({ required: true, min: 1 })}
                            onChange={e => fieldUpdated(e.target.name, e.target.value)}
                        />
                        {errors.totalUnits && <FormFeedback className="font-weight-bold">Invalid total units</FormFeedback>}
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label>Item Description</Label>
                <Input
                    type="text"
                    name="description"
                    className={styles.underline}
                    invalid={Boolean(errors.description)}
                    innerRef={register}
                    onChange={e => fieldUpdated(e.target.name, e.target.value)}
                />
                {errors.description && <FormFeedback className="font-weight-bold">Please provide item description</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Checkbox
                    checked={item.applyVat}
                    id="applyVat"
                    label="Apply VAT To Item"
                    onChange={checked => fieldUpdated("applyVat", checked)}
                />
            </FormGroup>

            <ViewBespokeQuoteItemPrice item={item} />
        </Form>
    );
};

EditBespokeQuoteItem.propTypes = {
    onSend: PropTypes.func.isRequired,
    onItemRemoved: PropTypes.func.isRequired,
    onItemUpdated: PropTypes.func.isRequired,
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        pricingUnit: PropTypes.string.isRequired,
        totalUnits: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        applyVat: PropTypes.bool.isRequired,
    }).isRequired,
};

export { EditBespokeQuoteItem };
