/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import styles from "./styles.module.scss";

import BuildAPackageIcon from "@/Assets/Icons/Coloured/build-a-package.svg";
import BuildPackageIcon from "@/Assets/Icons/Coloured/build-package.svg";
import PackageCodeIcon from "@/Assets/Icons/Coloured/package-code.svg";
import PackageCommissionIcon from "@/Assets/Icons/Coloured/package-commission.svg";
import Footer from "@/Components/Footer";
import { Visible } from "@/Components/Visible";
import { PackageSpecificsModal } from "@/Modals/PackageSpecificsModal";

const cardClass = classnames(styles.card, "d-flex flex-column justify-content-end text-center border border-light box-shadow bg-white p-2 p-lg-4 mx-lg-3 mx-md-0");

const PackageWelcomePage = () => {
    const isSupplier = useSelector<any>(state => state.user.isSupplier);
    const [showPackageSpecifics, setShowPackageSpecifics] = useState(false);
    document.title = "BSC - Packages";
    const history = useHistory();
    const sendGtmEvent = useGTMDispatch();

    const addProducts = ({ name, description, showOnMinisite }) => {
        sendGtmEvent(
            {
                event: "add_package",
                ecommerce: null,
                items: [
                    {
                        package_name: `${name}`,
                        description: `${description}`,
                        show_on_mini_site: showOnMinisite,
                    },
                ],
            },
        );
        history.push({
            pathname: "/packages/package-directory",
            state: { name, description, showOnMinisite },
        });
    };

    useEffect(() => {
        // Scroll to top
        const element = document.getElementById("mainScrollableDiv");
        if (element) {
            element.scrollTop = 0;
        }
    }, []);

    return (
        <div className={styles.negativeMargin}>
            <div className={classnames(styles.header, "d-flex flex-column flex-lg-row pt-4 p-lg-4 bg-primary-gradient")}>
                <div className={classnames(styles.headerIconContainer, "rounded-circle bg-light p-3 mx-auto mx-lg-0")}>
                    <BuildAPackageIcon />
                </div>
                <div className="d-flex flex-column align-content-center justify-content-center m-4">
                    <Visible below="Lg">
                        <h4 className="font-weight-bold mb-4">
                            Create a package of complimentary goods and services and pass it on
                        </h4>
                    </Visible>
                    <Visible forAndAbove="Lg">
                        <h3 className="font-weight-bold mb-4">
                            Create a package of complimentary goods and services and pass it on
                        </h3>
                    </Visible>
                    <p className="mt-2">
                        Our package builder gives you a unique way to bundle together goods and services for your customers. You can build a unique package comprising of any BSC
                        products and services supplied by you and other suppliers. You can use a package to compliment your existing offerings or up-sell to current customers.
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column flex-lg-row mx-2 mx-lg-0 mt-2 my-lg-5 justify-content-center">
                <div className={classnames(styles.section, "my-2 m-lg-0")}>
                    <div className={cardClass}>
                        <h2 className="text-primary font-weight-bold">1</h2>
                        <div className={classnames(styles.iconContainer, "py-3 mx-auto")}><BuildPackageIcon /></div>
                        <p className={classnames(styles.info)}>
                            Select the products and services that will make up your package. These can come from multiple or individual suppliers.
                        </p>
                    </div>
                </div>

                <div className={classnames(styles.section, "my-2 m-lg-0")}>
                    <div className={cardClass}>
                        <h2 className="text-primary font-weight-bold">2</h2>
                        <div className={classnames(styles.iconContainer, "py-3 mx-auto")}><PackageCodeIcon /></div>
                        <p className={classnames(styles.info)}>
                            Confirm your selection and generate your unique code which will link your referrals to the package you’ve created.
                        </p>
                    </div>
                </div>

                <div className={classnames(styles.section, "my-2 m-lg-0")}>
                    <div className={cardClass}>
                        <h2 className="text-primary font-weight-bold">3</h2>
                        <div className={classnames(styles.iconContainer, "py-3 mx-auto")}><PackageCommissionIcon /></div>
                        <p className={classnames(styles.info)}>
                            Pass your code to your referrals. By redeeming the code, your customers will have a quick way to buy your products with minimal hassle.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-4 mb-5">
                <div className="d-flex justify-content-center">
                    <Button onClick={() => setShowPackageSpecifics(true)} className="px-3 px-md-4" color="primary">Let’s Proceed</Button>
                </div>
            </div>
            <Footer />
            {showPackageSpecifics && <PackageSpecificsModal
                onClose={() => setShowPackageSpecifics(false)}
                onAddProducts={addProducts}
                isSupplier={isSupplier || false}
            />}
        </div>
    );
};

export default PackageWelcomePage;
