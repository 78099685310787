import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Row, Col, Input } from "reactstrap";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { ClickAwayListener } from "@/Components/ClickAwayListener";

const asciiCodeForA = 65;

const AtoZFilter = (props) => {
    const [filtersOpen, setFiltersOpen] = useState(false);

    const onLetterFilterSelect = value => () => {
        const newFilters = props.selectedLetters.includes(value)
            ? props.selectedLetters.filter(x => x !== value)
            : [...props.selectedLetters, value];
        props.onLetterFilter(newFilters);
    };

    const toggleFilter = () => setFiltersOpen(!filtersOpen);

    const toggleAll = () => {
        props.onLetterFilter([]);
    };

    const onChange = (e) => {
        setFiltersOpen(false);
        props.onLetterFilter([]);
        props.onSearch(e);
    };

    return (
        <Col
            xs="12"
            className={classnames(
                "border shadow-sm tw-bg-white px-0",
                props.isReskin2022 ? "tw-bg-opacity-70 tw-backdrop-blur-xs tw-rounded-lg" : styles.filter,
                props.isReskin2022 ? "" : "mt-3",
            )}
        >
            <div className="d-flex align-items-center p-3 my-2">
                <button
                    type="button"
                    data-testid="toggle-filter"
                    className={classnames(
                        "d-flex mr-2 p-0 font-weight-bold px-2 rounded-lg border-0",
                        filtersOpen || props.selectedCategories.length > 0 ? "bg-primary text-white" : "bg-transparent",
                    )}
                    onClick={toggleFilter}
                >
                    <i className="fa fa-filter mr-2" />
                    Filters
                </button>
                <Input id="categorySearchBox" data-testid="category-search" placeholder="Enter supplier name or keywords" onChange={onChange} />
            </div>
            <div className={classnames(styles.container, "d-flex justify-content-start px-3")}>
                <div className={classnames(
                    "tw-hidden md:tw-flex tw-mb-4 md:tw-mb-6 tw-max-w-full md:tw-items-center",
                    props.isReskin2022 ? "tw-justify-between tw-w-full" : "tw-space-x-2",
                )}
                >
                    <button
                        type="button"
                        className={classnames(
                            "tw-mr-2 tw-px-3 tw-font-semibold tw-rounded-md tw-border-0 box-shadow",
                            props.selectedLetters.length === 0 ? "bg-primary text-white" : "bg-transparent",
                        )}
                        onClick={toggleAll}
                        disabled={props.selectedLetters.length === 0}
                    >
                        ALL
                    </button>

                    {[...Array(26)
                        .keys()].map((x) => {
                        const character = String.fromCharCode(asciiCodeForA + x);
                        return (
                            <button
                                key={x}
                                type="button"
                                className={classnames(
                                    "tw-mr-1 tw-font-semibold tw-rounded-md tw-border-0 box-shadow",
                                    "!tw-text-3xs xl:!tw-text-2xs tw-h-5 tw-w-5 xl:tw-h-6 xl:tw-w-6 tw-flex tw-flex-row tw-justify-center tw-items-center",
                                    props.selectedLetters.includes(character) ? "bg-primary text-white" : "bg-white",
                                )}
                                onClick={onLetterFilterSelect(character)}
                            >
                                {character}
                            </button>
                        );
                    })}
                </div>
            </div>

            {filtersOpen && (

                <ClickAwayListener onClickAway={() => setFiltersOpen(false)}>
                    <div className={classnames(styles.filterCategories, "bg-white border rounded-0 position-absolute overflow-y-auto")}>
                        <Row className="d-flex flex-grow-1 flex-wrap my-3 mx-2">
                            <span className="col-12 font-weight-bold mb-3">Category</span>
                            {props.categories.map(x => (
                                <Col xs="12" sm="4" md="3" className="mb-3" key={x.id}>
                                    <Checkbox
                                        onChange={() => props.onCategoryFilter(x.id)}
                                        id={x.id}
                                        checked={props.selectedCategories.includes(x.id)}
                                        label={x.name}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </ClickAwayListener>
            )}

        </Col>
    );
};

AtoZFilter.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onLetterFilter: PropTypes.func.isRequired,
    onCategoryFilter: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    })).isRequired,
    selectedCategories: PropTypes.arrayOf(PropTypes.string),
    selectedLetters: PropTypes.arrayOf(PropTypes.string),
    isReskin2022: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    goBack: PropTypes.func,
};

AtoZFilter.defaultProps = {
    selectedCategories: [],
    selectedLetters: [],
    isReskin2022: false,
};

export default AtoZFilter;
