import React from "react";
import classnames from "classnames";

import { ParticipantStatus } from "@/FlexPlan/Types/Participant";
import { getColour, renderParticipantStatusLabel } from "@/FlexPlan/Types/Participant/participantStatuses";

interface Props {
    status: ParticipantStatus
}

const ParticipantStatusIcon = ({ status }: Props) => (
    <span className="tw-flex tw-items-center">
        <i className={classnames("fa fa-circle mr-2 mb-1 [&>*]:tw-w-2 [&>svg]:tw-h-2", getColour(status))} />
        <span>{renderParticipantStatusLabel(status)}</span>
    </span>
);

export default ParticipantStatusIcon;
