export const uppercaseFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const substringFromLastIndexOf = (value: string, splitOn: string): string => value.substring(value ? value.lastIndexOf(splitOn) + 1 : 0);

export const toCurrency = (value: number | string): string => (new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}).format(parseFloat(Number(value).toString())).substring(1));

export const joinStrings = (value: string[], separator: string, separatorLast?: string): string => {
    const last = separatorLast ?? separator;
    let text = "";
    for (let i = 0; i < value.length; i++) {
        text += value[i];
        if (value.length > 1) {
            if (i === value.length - 2) {
                text += last;
            } else if (i < value.length - 2) {
                text += separator;
            }
        }
    }
    return text;
};

export const toCurrencyWithCommas = (value: number | string, currency = "GBP", locale: string = "en-GB"): string => {
    let number: number;

    if (typeof value === "string") {
        const parsedNumber = parseFloat(value);

        if (parsedNumber) {
            number = parsedNumber;
        } else {
            return value;
        }
    } else {
        number = value;
    }

    const currencyFormatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
    });

    return currencyFormatter.format(number);
};

export const pascalCaseToTitleCase = (value: string) => value.replace(/([A-Z])/g, " $1");

export const getFrequency = (paymentFrequency: string) => {
    switch (paymentFrequency) {
        case "One Time Payment":
            return "";
        case "Weekly Subscription":
            return "/ Weekly";
        case "Monthly Subscription":
            return "/ Monthly";
        case "Yearly Subscription":
            return "/ Yearly";
        default:
            return "";
    }
};

export const getFrequencyBasic = (paymentFrequency: string) => {
    switch (paymentFrequency) {
        case "One Time Payment":
            return "";
        case "Weekly Subscription":
            return "Weekly";
        case "Monthly Subscription":
            return "Monthly";
        case "Yearly Subscription":
            return "Yearly";
        default:
            return "";
    }
};

export const getFrequencyAlternate = (priceFrequency: string) => {
    switch (priceFrequency) {
        case "Weekly":
        case "Weekly Subscription":
            return "/week";
        case "Monthly":
        case "Monthly Subscription":
            return "/month";
        case "Annually":
        case "Yearly Subscription":
            return "/year";
        default:
            return "";
    }
};

export const getDelayedPaymentPeriodValue = (delayedPaymentPeriodValue: number, delayedPaymentFor: number) => {
    switch (delayedPaymentPeriodValue) {
        case 0:
            return `week${delayedPaymentFor > 1 ? "s" : ""}`;
        case 1:
            return `month${delayedPaymentFor > 1 ? "s" : ""}`;
        case 2:
            return `year${delayedPaymentFor > 1 ? "s" : ""}`;
        default:
            return "";
    }
};

export const toPaddedNumber = (number: string | number) => String(number).padStart(6, "0");

export const toPlural = (value: string): string => `${value}s`;

export const enumDisplay = (str: string) => {
    if (!str) return "";

    const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    const spacedString = str.replace(rex, "$1$4 $2$3$5");
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1); // Uppercase first letter
};

export const companyFormationsGroupToTitle = (group: string) => group.replace("-", " ");
