import React, { ReactNode } from "react";
import classnames from "classnames";

interface Props {
    onClick: () => void,
    className?: string,
    children: ReactNode,
    disabled?: boolean,
    testId?: string,
    id?: string
}

export const CircleButton = ({ onClick, className, children, disabled, testId, id } : Props) => (
    <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={classnames(className, "d-flex justify-content-center rounded-circle align-items-center")}
        data-testid={testId}
        id={id}
    >
        {children}
    </button>
);
