import moment from "moment";

const sort = <T>(x, y, sortBy: keyof T, ascending) => {
    let first = x[sortBy] || "";
    let next = y[sortBy] || "";

    // need to use moment with a format here as "project 1" is a valid date in JS
    if (moment(first, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS, true).isValid()) {
        first = new Date(first).getTime();
        next = new Date(next).getTime();
    } else if (typeof first === "string" && typeof next === "string") {
        first = first.toLowerCase();
        next = next.toLowerCase();
    }

    if (first === next) {
        return 0;
    }
    if (ascending) {
        return first > next ? 1 : -1;
    }

    // -1 - next is greater than first
    // 1 - first is greater than next
    // 0 - names must be equal
    return first < next ? 1 : -1;
};

export default {
    sort,
};
