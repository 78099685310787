import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
    onClickGridView: () => void,
    onClickListView: () => void,
    testId?: string,
    isExpanded: boolean,
}

export const GridListToggle = ({ isExpanded, onClickGridView, onClickListView, testId }: Props) => (
    <div>
        <button
            type="button"
            onClick={onClickListView}
            id={`${testId}-list`}
            className={classnames(styles.gridButton, isExpanded && styles.unselected, "border-0 tw-mr-2")}
            data-testid={`${testId}-list`}
        >
            <i
                className="fa fa-th-list h5"
            />
        </button>
        <button
            type="button"
            onClick={onClickGridView}
            id={`${testId}-grid`}
            className={classnames(styles.gridButton, !isExpanded && styles.unselected, "border-0")}
            data-testid={`${testId}-grid`}
        >
            <i
                className="fa fa-th-large h5"
            />
        </button>
    </div>
);
