import { useEffect, useState } from "react";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { Stripe, StripeCardNumberElement, StripeElements } from "@stripe/stripe-js";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import globalActions from "@/Store/Global/actions";
import { useBasketContext } from "@/Context/BasketContext";
import { PAYMENTS_FAILED_TO_COMPLETE_BASKET, useLoggingContext } from "@/Context/LoggingContext";
import { CheckoutResponsePayment, completeOrder, onOrderComplete, PaymentDetails } from "@/Apis/Orders";
import { initialisePayment, updateSubscription } from "@/Apis/Payments";
import { useAPI } from "@/Apis/useAPI";

interface Props {
    stripe?: Stripe | null,
    elements?: StripeElements | null,
    onPaymentSuccess?: () => void,
    amendPaymentOrderId?: string | undefined,
}

const useCheckout = ({ stripe, elements, onPaymentSuccess, amendPaymentOrderId }: Props) => {
    // Context
    const { basket } = useBasketContext();
    const applicationInsights = useLoggingContext();
    // Hooks
    const sendGtmEvent = useGTMDispatch();
    const dispatch = useDispatch();
    const { post } = useAPI({ handle500WithToastMessage: true });
    // State
    const [loading, setLoading] = useState<boolean>(false);
    const [hasOffsitePayment, setHasOffsitePayment] = useState(false);
    const [hasDelayedPayment, setHasDelayedPayment] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [paymentError, setPaymentError] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [orderIds, setOrderIds] = useState<string[]>();

    const user = useSelector((state: any) => state.user);
    // Variables
    useEffect(() => {
        setHasOffsitePayment(basket.groups.flatMap(_ => _.items).some(x => x.isOffsitePayment || x.paymentFrequency === "N/A"));
        setHasDelayedPayment(basket.groups.flatMap(_ => _.items).some(x => x.isDelayedPayment));
        setHasSubscription(basket.groups.flatMap(_ => _.items).some(x => x.isSubscription));
        setOrderIds(basket.groups.flatMap(_ => _.items).map(x => x.orderId));
    }, [basket.groups]);

    const sendGtmEventOnPayment = () => {
        sendGtmEvent(
            {
                event: "purchase",
                ecommerce: {
                    transaction_id: `${orderIds}`,
                    value: basket.dueNowTotal,
                    currency: "GBP",
                    items: [
                        ...basket.groups.flatMap(x => x.items.map(i => ({
                            item_id: `${i.productId}`,
                            item_name: `${i.serviceName}`,
                            currency: "GBP",
                            item_brand: `${i.supplierName}`,
                            item_category: `${i.category}`,
                            price: i.price,
                            quantity: i.quantity,
                        })))],
                },
            },
        );
    };

    const handleFailedToCompleteBasket = (error) => {
        if (error.message) {
            setPaymentError(error.message);
        }
        if (applicationInsights) {
            const errorDetails = { userId: user.email, items: orderIds };
            applicationInsights.trackException({
                exception: new Error(PAYMENTS_FAILED_TO_COMPLETE_BASKET),
                properties: { details: errorDetails, error },
            });
        }
    };

    const checkoutWithoutSubscription = async (postcode: string) => {
        if (!stripe || !elements || !orderIds) {
            return;
        }

        const paymentRequest: any = {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: `${user.forename} ${user.surname}`,
                    email: user.email,
                    address: {
                        postal_code: postcode,
                    },
                },
            },
        };

        const intent = await initialisePayment(basket.groups.flatMap(_ => _.items).map(x => x.orderId));
        if (!intent.token) {
            setPaymentError("Sorry something unexpected happened please try again.");
        }
        const response = await stripe.confirmCardPayment(intent.token, paymentRequest);

        if (response.error) {
            setPaymentError(response.error.message);
            setLoading(false);
        } else {
            const details: PaymentDetails = {
                source: "stripe",
                orderIds,
                stripePaymentId: response.paymentIntent?.id,
            };

            completeOrder(details)
                .then(_ => onOrderComplete(dispatch, onPaymentSuccess)(_.payments))
                .catch(handleFailedToCompleteBasket)
                .finally(() => setLoading(false));
        }
    };

    const setupIntent = async () => {
        try {
            const response = await post<{ clientSecret: string }>("subscription/payment-intent", {});
            return response.clientSecret;
        } catch (e: any) {
            setPaymentError(e.error);
        }

        return "";
    };

    const confirmCardSetup = async (postCode: string, clientSecret: string) => {
        if (!stripe || !elements || !orderIds) {
            return "";
        }

        try {
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
                    billing_details: {
                        name: `${user.forename} ${user.surname}`,
                        email: user.email,
                        address: {
                            postal_code: postCode,
                        },
                    },
                },
            });

            if (result.error) {
                setPaymentError(result.error.message);
            } else {
                return result.setupIntent.payment_method;
            }
        } catch (e: any) {
            setPaymentError(e.error);
        }

        return "";
    };

    const checkoutWithSubscription = async (postCode: string) => {
        if (!stripe || !elements || !orderIds) {
            return;
        }

        // Set up a payment method intent
        const clientSecret = await setupIntent();

        if (!clientSecret) {
            setLoading(false);
            return;
        }

        // Submit the card details for the setup intent to stripe
        // It is at this point that 3DS ought to be triggered.
        // It will be triggered just once regardless of the number of subscriptions in basket
        const paymentMethodId = await confirmCardSetup(postCode, clientSecret);

        if (!paymentMethodId) {
            setLoading(false);
            return;
        }

        // Create the subscription
        post<CheckoutResponsePayment>("subscription", {
            orderIds,
            paymentMethodId,
        })
            .then(_ => onOrderComplete(dispatch, onPaymentSuccess)([_]))
            .catch(handleFailedToCompleteBasket)
            .finally(() => setLoading(false));
    };

    const onSubmit = (postCode: string) => async () => {
        setLoading(true);

        if (hasSubscription) {
            await checkoutWithSubscription(postCode);
        } else {
            await checkoutWithoutSubscription(postCode);
        }
        sendGtmEventOnPayment();
    };

    const createPaymentMethod = async (postCode: string) => {
        if (!stripe || !elements) {
            return null;
        }

        const paymentRequest: any = {
            type: "card",
            card: elements.getElement(CardNumberElement),

            billing_details: {
                name: `${user.forename} ${user.surname}`,
                email: user.email,
                address: {
                    postal_code: postCode,
                },
            },
        };

        const { error, paymentMethod } = await stripe.createPaymentMethod(paymentRequest);
        if (error) {
            setPaymentError(error.message);
            setLoading(false);
            return null;
        }
        return paymentMethod;
    };

    const amendPaymentDetail = (postCode: string, isDefaultCard: boolean) => async () => {
        setLoading(true);
        const paymentMethod = await createPaymentMethod(postCode);

        if (!paymentMethod) {
            setLoading(false);
            return;
        }

        updateSubscription({ orderId: amendPaymentOrderId, paymentMethodId: paymentMethod.id, setDefault: isDefaultCard })
            .then((response) => {
                if (!response.isSuccess) {
                    setErrorMessage(response.message);
                    return;
                }
                dispatch(push("/customer-orders"));
                dispatch(globalActions.setToastMessage(true, "Payment details amended successfully!"));
            })
            .catch(() => setLoading(false))
            .finally(() => setLoading(false));
    };

    const submitFreeOrder = async () => {
        if (!orderIds) {
            return;
        }

        setLoading(true);

        const details: PaymentDetails = {
            source: "free",
            orderIds,
        };

        completeOrder(details)
            .then(_ => onOrderComplete(dispatch, onPaymentSuccess)(_.payments))
            .catch(handleFailedToCompleteBasket)
            .finally(() => setLoading(false));
    };

    return {
        loading,
        onSubmit,
        hasDelayedPayment,
        hasOffsitePayment,
        hasSubscription,
        paymentError,
        createPaymentMethod,
        amendPaymentDetail,
        errorMessage,
        submitFreeOrder,
    };
};

export default useCheckout;
