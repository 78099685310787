import React, { useState } from "react";

import { DocumentDetails } from "@/Components/DocumentUpload/DocumentDetails";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { substringFromLastIndexOf } from "@/Utils/formatStringHelper";

interface Props {
    onAdd: (documents: AddedDocument[]) => void,
    onRemove: (document: Document) => void,
    documents: Document[];
    children: any;
}

export interface Document {
    id: string;
    name: string;
    location: string;
}

export interface AddedDocument {
    name: string;
    data: string;
}

const DocumentUpload = ({ onAdd, onRemove, documents, children }: Props) => {
    const [duplicateFiles, setDuplicateFiles] = useState<string[]>([]);

    const onDocumentAdded = (acceptedFiles: Document[]) => {
        const files = {};
        const duplicates: string[] = [];

        documents.forEach(file => {
            files[file.name] = true;
        });

        acceptedFiles.forEach(file => {
            if (files[file.name] === true) {
                duplicates.push(file.name);
            }

            files[file.name] = true;
        });

        setDuplicateFiles(duplicates);
        return duplicates.length === 0;
    };

    const onDelete = (document) => {
        onRemove(document);
        setDuplicateFiles([]);
    };

    return (
        <>
            {documents.map(doc => <DocumentDetails key={doc.name} onDelete={() => onDelete(doc)} name={substringFromLastIndexOf(doc.name, "/")} />)}

            {duplicateFiles.length > 0 && <span className="ml-4 text-danger">Cannot upload duplicate documents</span>}

            <div className="m-4 pt-4 pb-1">
                <DropZoneArea onAdd={onAdd} preDropHook={onDocumentAdded}>
                    {children}
                </DropZoneArea>
            </div>
        </>
    );
};

export { DocumentUpload };
