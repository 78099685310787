import React, { ChangeEvent } from "react";
import classnames from "classnames";
import { FormGroup, Label } from "reactstrap";

import Checkbox from "@/Components/Checkbox";
import { FlexPlanOrderStatus, PaymentRequestStatus } from "@/FlexPlan/Types/Order";
import { ToolTip } from "@/Components/ToolTip";
import { ItemCodeSearch } from "@/FlexPlan/Pages/Orders/OrderDetails/Components/ItemCodeSearch";
import Textbox from "@/Components/Textbox";
import DateTextbox from "@/Components/DateTextbox";
import NumberField from "@/Components/NumberField";
import OrderRowStatusIcon from "@/FlexPlan/Pages/Orders/OrderDetails/Components/OrderRowStatusIcon";
import { isApprovedOrder, openRows } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { OrderRow } from "@/FlexPlan/Pages/Orders/OrderDetails/Types";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { useFlexPlanProductContext } from "@/FlexPlan/Context/FlexPlanProductContext";
import { FormError } from "@/FlexPlan/Components/FormError";
import { toTwoDecimalPlaces } from "@/FlexPlan/Utils/currency";

interface Props {
    failedItem?: boolean,
    index: number,
    row: OrderRow,
    onCheckboxOnRow: (id: string) => (checked: boolean) => void,
    onChangeRow: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void,
    initialOrderStatus?: FlexPlanOrderStatus,
    onDateTextBoxChange: (field: keyof OrderRow, itemId: string) => (value: any) => void,
    onGstCheckBoxOnRow: (id: string) => (checked: boolean) => void,
}

const LineItem = ({ failedItem = false, index, row, onCheckboxOnRow, onChangeRow, initialOrderStatus, onDateTextBoxChange, onGstCheckBoxOnRow }: Props) => {
    const { formState } = useFlexPlanOrderContext();
    const { productCategoryItems } = useFlexPlanProductContext();

    const getItemCodeLabel = (itemCode: string) => {
        // Match up the item
        const matchingProduct = productCategoryItems
            .find(x => x.key === itemCode);

        if (!matchingProduct) return itemCode;

        return `${matchingProduct?.key} - ${matchingProduct?.value}`;
    };

    return (
        <div>
            <div className="tw-flex tw-flex-row tw-space-x-2 tw-mt-7">
                <Checkbox
                    checked={row.isChecked ?? false}
                    onChange={onCheckboxOnRow(row.id)}
                    id={`order-row-${row.id}`}
                    className={classnames("!tw-mr-0", index === 0 ? "!tw-mt-9" : "!tw-mt-1")}
                    disabled={!failedItem && (row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus))}
                />
                <FormGroup className="!tw-mr-2">
                    {index === 0 && <Label>Row</Label>}
                    <div className="tw-text-green-500 tw-underline tw-font-bold hover:tw-cursor-pointer tw-mt-1">
                        {row.claimNumber
                            ? (<ToolTip id={`id-${row.position}`} description={`Claim No: ${row.claimNumber}`}>00{index + 1}</ToolTip>)
                            : `00${index + 1}`}
                    </div>
                </FormGroup>
                {!failedItem && row.status === PaymentRequestStatus.NoStatus
                    ? (
                        <FormGroup className="tw-w-80">
                            {index === 0 && <Label>Item Code</Label>}
                            <ItemCodeSearch
                                item={row}
                                isDisabled={isApprovedOrder(initialOrderStatus)}
                            />
                            {row.invalidFields?.itemCode && (
                                <FormError textSizeClassName="tw-text-[0.875em]">
                                    {row.invalidFields.itemCode}
                                </FormError>
                            )}
                        </FormGroup>
                    ) : (
                        <Textbox
                            label={index === 0 ? "Item code" : ""}
                            name="itemCode"
                            value={getItemCodeLabel(row.itemCode)}
                            maxLength={255}
                            className="tw-w-80"
                            onChange={onChangeRow(row.id)}
                            disabled
                            error={row.invalidFields?.itemCode}
                        />
                    )}
                <FormGroup className="">
                    <Textbox
                        label={index === 0 ? "Description" : ""}
                        name="description"
                        value={row.description}
                        maxLength={255}
                        onChange={onChangeRow(row.id)}
                        disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                    />
                </FormGroup>
                <FormGroup className="tw-basis-[16%] 2xl:tw-basis-[12%] 3xl:tw-basis-[10%] !tw-mb-0">
                    <DateTextbox
                        currentDate={row.startDate}
                        changeDate={onDateTextBoxChange("startDate", row.id)}
                        label={index === 0 && "Start date"}
                        disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                        calendarIcon={false}
                        error={row.invalidFields?.startDate || row.invalidFields?.startDatePrecedesEndDate}
                    />
                </FormGroup>
                <FormGroup className="tw-basis-[16%] 2xl:tw-basis-[12%] 3xl:tw-basis-[10%] !tw-mb-0">
                    <DateTextbox
                        currentDate={row.endDate}
                        changeDate={onDateTextBoxChange("endDate", row.id)}
                        label={index === 0 && "End date"}
                        disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                        calendarIcon={false}
                        error={row.invalidFields?.endDate}
                    />
                </FormGroup>
                <NumberField
                    label={index === 0 ? "Qty" : ""}
                    name="quantity"
                    value={row.quantity}
                    onChange={onChangeRow(row.id)}
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-basis-20"
                    disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                    error={row.invalidFields?.quantity}
                    placeholder="0.00"
                />
                <NumberField
                    label={index === 0 ? "Rate" : ""}
                    name="rate"
                    value={row.rate}
                    onChange={onChangeRow(row.id)}
                    prefixChar="$"
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-basis-24"
                    disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                    placeholder="0.00"
                />
                <FormGroup className="!tw-mb-0">
                    {index === 0 && <Label>GST</Label>}
                    <Checkbox
                        id={row.id}
                        checked={row.isGst}
                        onChange={onGstCheckBoxOnRow(row.id)}
                        disabled={failedItem || row.status !== PaymentRequestStatus.NoStatus || isApprovedOrder(initialOrderStatus)}
                        className="!tw-mr-0 tw-pl-0.5 tw-w-2"
                    />
                </FormGroup>
                <NumberField
                    label={index === 0 ? "Total" : ""}
                    name="total"
                    value={toTwoDecimalPlaces(Number(row.rate) * Number(row.quantity))}
                    onChange={() => {
                    }}
                    disabled
                    prefixChar="$"
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-basis-28"
                    placeholder="0.00"
                />
                <NumberField
                    label={index === 0 ? "Amount Approved" : ""}
                    name="amountApproved"
                    value={toTwoDecimalPlaces(row.amountApproved)}
                    onChange={() => {
                    }}
                    disabled
                    prefixChar="$"
                    min={0}
                    maxDecimalPlaces={2}
                    className="tw-w-32 tw-whitespace-nowrap"
                    placeholder="0.00"
                />
                <FormGroup className="tw-pl-4">
                    {index === 0 && <Label>Claim Status</Label>}
                    <div className="tw-mt-2">
                        {failedItem ? (
                            <ToolTip
                                id={`id-${row.id}`}
                                description={
                                    <>
                                        Last NDIS API Response: <br />
                                        <pre>{JSON.stringify(JSON.parse(row.ndisResponse ?? "{}"), null, 2)}</pre>
                                    </>
                                }
                                placement="left-start"
                            >
                                <OrderRowStatusIcon status={row.status} />
                            </ToolTip>
                        ) : (
                            <OrderRowStatusIcon status={row.status} />
                        )}
                    </div>
                </FormGroup>
            </div>
            <hr className={`tw-mr-32 3xl:tw-w-3/4 ${index === formState.items.filter(openRows).length - 1 ? "tw-border-4" : "tw-border-2"}`} />
        </div>
    );
};
export { LineItem };
