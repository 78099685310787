/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Route, useHistory } from "react-router-dom";

import Spinner from "@/Components/Spinner";
import { FlexPlanRoleStrings } from "@/FlexPlan/Types/Roles";
import { RouteProps } from "@/FlexPlan/Types/ReactRouter";
import { useUser } from "@/FlexPlan/Hooks/useUser";
import { removeToken } from "@/Utils/authentication";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface AuthorizedRouteProps extends RouteProps {
    allowedRoles: FlexPlanRoleStrings[];
}

const AuthorizedRoute = ({ allowedRoles, ...rest }: AuthorizedRouteProps) => {
    const { userIsInRole, loggedIn } = useUser();
    const { setPopupErrorMessage } = useToastMessageContext();
    const { push } = useHistory();
    const currentlyLoggedIn = loggedIn();
    const [checkedUser, setCheckedUser] = useState(false);

    useEffect(() => {
        setCheckedUser(true);
        if (currentlyLoggedIn && !userIsInRole("FlexPlan_PlanManager")) {
            // User must a least have FlexPlan_PlanManager, if they don't (e.g. BSC user) show them the forbidden page and log them out
            removeToken();
            setPopupErrorMessage("You must have the Plan Manager role to use Flex Plan", true);
        }
        if (!currentlyLoggedIn) {
            push("/login", "You must be logged in to perform that action.");
        }
    }, [location.pathname, currentlyLoggedIn]); // version of useLocation() BSC uses is too old, hence use global instead

    const Loading = () => (
        <Row className="tw-flex-grow">
            <Col xs={12} className="tw-flex tw-items-center tw-justify-center tw-mt-5">
                <Spinner />
            </Col>
        </Row>);

    if (checkedUser && currentlyLoggedIn && !allowedRoles.every(x => userIsInRole(x))) {
        push("/forbidden");
        return (<Loading />);
    }

    if (currentlyLoggedIn && allowedRoles.some(x => userIsInRole(x))) {
        return (<Route {...rest} />);
    }

    return (<Loading />); // We will be pushing the user to an authentication screen, return loading
};

export { AuthorizedRoute };
