import { ReceiptType } from "@/Apis/Receipts/Create";

export const isEmptyOrSpaces = (str: any): boolean => (!str || /^ *$/.test(str));

export const exceedsMaxStringLength = (str: string, maxLength: number = 255): boolean => str.length > maxLength;

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isValidCompanyNumber = (companyNumber?: string) => !!companyNumber && /(^[a-zA-Z]{2}[0-9]{6}$)|(^[0|1][0-9]{7}$)/.test(companyNumber);

export const isValidPhoneNumber = (phoneNumber?: string) => !!phoneNumber && /^[0][0-9]{10}$/.test(phoneNumber);

export const isValidOrderNumber = (orderNumber?: string) => !orderNumber || /^[a-z0-9]*-[0-9]+$/.test(orderNumber);

export const isValidFileName = (fileName?: string) => !fileName || /[^\\/:*?"<>|\r\n]+$/im.test(fileName);

export const isValidCompanyRegistrationNumber = (value?: string) => !value || /^[a-zA-Z0-9]+$/.test(value);

export const isValidCompanyName = (value?: string) => !value || /^[a-zA-Z0-9 /\-&.,'()]+$/.test(value);

export const isValidBusinessNameOnCard = (value?: string) => !value || /^[a-zA-Z0-9][a-zA-Z0-9 /\-&.,'()]+$/.test(value);

export const isValidFirstOrLastName = (value?: string) => !value || /^[a-zA-Z]([a-zA-Z0-9 '-]*)$/.test(value);

export const isValidMobileNumber = (value?: string) => !value || /^07[0-9]{9}$/.test(value);

export const isValidWorkNumber = (value?: string) => !value || /^0[0-9]{10}$/.test(value);

export const isValidEmailAddress = (value?: string) => !value || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);

export const isValidPostCode = (value?: string) => !value || /^[a-zA-Z][a-zA-Z]?[0-9]([a-zA-Z]|[0-9])? ?[0-9][a-zA-Z]{2}$/.test(value);

export const isValidAddressLine = (value?: string) => !value || /^[a-zA-Z0-9 '\-,.]+$/.test(value);

export const isValidTownOrCity = (value?: string) => !value || /^([a-zA-Z0-9 -.]+)$/.test(value);

export const isValidPremise = (value?: string) => !value || /^[\w\s\\.,\\/&'\\"#\-()]+$/.test(value);

export const isValidStartsWithASingleCharacter = (value?: string) => !value || /^[a-zA-Z0-9]+$/.test(value);

export const isValidStartsWithAMobileNumber = (value?: string) => !value || /^07[0-9]{0,9}$/.test(value);

export const isValidStartsWithAWorkNumber = (value?: string) => !value || /^0[0-9]{0,10}$/.test(value);

export const isValidDate = (value?: string): boolean => {
    if (!value) { // No date, assume required
        return false;
    }

    if (Number.isNaN(Date.parse(value))) { // Date can't be parsed
        return false;
    }

    // Date does not exceed C# DateTime max date year of 9999
    return new Date(value).getFullYear() <= 9999;
};

export const receiptTypeName = (receiptType?: ReceiptType): string => {
    if (!receiptType) {
        return "";
    }
    return ReceiptType[receiptType];
};

export const isReceiptItemCodeValid = (str?: string): boolean => (!!str && /^(?!0+$)[0-9]{1,6}$/.test(str));
