import api from "@/Utils/api";

export class SupplierAdministrationUrls {
    private static suppliers = "supplier-administration";

    static getAuthorisedSuppliers = SupplierAdministrationUrls.suppliers;

    static deactivateSuppliers = SupplierAdministrationUrls.suppliers;

    static exportProfiles = `${SupplierAdministrationUrls.suppliers}/export-profiles`;

    static updateFlexiblePayments = `${SupplierAdministrationUrls.suppliers}/update-flexible-payments`;

    static updateHideDirectoryListing = `${SupplierAdministrationUrls.suppliers}/update-hide-directory-listing`;
}

const getAuthorisedSuppliers = () => api
    .get(SupplierAdministrationUrls.getAuthorisedSuppliers) as Promise<AuthorisedSuppliersType>;

const deactivateSuppliers = (supplierIds: string[]) => api
    .del(SupplierAdministrationUrls.deactivateSuppliers, {
        supplierIds,
    }) as Promise<any>;

const exportProfiles = (supplierIds: string[]) => api
    .downloadFile(
        SupplierAdministrationUrls.exportProfiles,
        JSON.stringify({ supplierIds }),
        "SupplierProfiles",
        "csv",
        document.createElement("a"),
        true,
    ) as Promise<void>;

const updateFlexiblePayments = (supplierId: string, allowFlexiblePayments: boolean) => api
    .put(SupplierAdministrationUrls.updateFlexiblePayments, {
        supplierId,
        allowFlexiblePayments,
    }) as Promise<UpdateFlexiblePaymentsResponse>;

const updateHideDirectoryListing = (supplierId: string, hideDirectoryListing: boolean) => api
    .put(SupplierAdministrationUrls.updateHideDirectoryListing, {
        supplierId,
        hideDirectoryListing,
    }) as Promise<UpdateHideDirectoryListingResponse>;

export {
    getAuthorisedSuppliers,
    deactivateSuppliers,
    exportProfiles,
    updateFlexiblePayments,
    updateHideDirectoryListing,
};

export interface AuthorisedSuppliersType {
    authorisedSuppliers: AuthorisedSupplierType[];
}

export interface AuthorisedSupplierType {
    id: string;
    userId: string;
    name: string;
    email: string;
    rating: number;
    allowFlexiblePayments: boolean;
    hideDirectoryListing: boolean;
}

export interface UpdateFlexiblePaymentsResponse {
    allowFlexiblePayments: boolean;
}

export interface UpdateHideDirectoryListingResponse {
    hideDirectoryListing: boolean;
}

export interface SupplierProfilesType {
    supplierProfiles: SupplierProfileType[];
}

export interface SupplierProfileType {
    userId: string;
    supplierId: string;
    supplierName: string;
    rating: number;
    title: string;
    forename: string;
    middleName?: string;
    surname: string;
    email: string;
    premise?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    postCode?: string;
    dateOfBirth?: Date;
    formerForename?: string;
    formerSurname?: string;
    nationality?: string;
    nationalInsuranceNumber?: string;
    occupation?: string;
    countryOfResidence?: string;
    dateUserCreated: Date;
    dateSupplierCreated: Date;
}
