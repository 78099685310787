import React, { useEffect, useState } from "react";
import classnames from "classnames";
import DatePicker from "react-date-picker";
import { FormGroup, Label } from "reactstrap";
import moment from "moment";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import { ClickAwayListener } from "@/Components/ClickAwayListener";

const FloatLabelDate = props => {
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [maxDate, setMaxDate] = useState<Date | undefined>();
    const [minDate, setMinDate] = useState<Date | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
    const [canValidate, setCanValidate] = useState(props.validateOnMount);
    const [clearPlaceholder, setClearPlaceholder] = useState(false);
    const parseDate = (value: Date | string | null | undefined): Date | undefined => {
        if (value) {
            const type = typeof value;
            if (type === "string") {
                return moment(value as string).toDate();
            }
        }

        return value as Date;
    };

    useEffect(() => {
        setMaxDate(parseDate(props.max));
        setMinDate(parseDate(props.min));
        setSelectedDate(parseDate(props.currentDate));
        if (canValidate) {
            setIsRequiredValidationFailed(props.isRequired && !props.currentDate);
        }
    }, [props.max, props.min, props.currentDate]);

    const updatePlaceholders = (clearPlaceholders) => {
        setClearPlaceholder(clearPlaceholders);
    };

    const onChange = (value) => {
        props.changeDate(value);
        setSelectedDate(value);
        setCanValidate(true);
    };

    return (
        <FormGroup onClick={() => updatePlaceholders(true)}>
            <div className={props.inline ? styles.inline : ""}>
                <div className={classnames(styles.floatLabel,
                    clearPlaceholder && styles.transparentPlaceholder,
                    props.className, props.error || isRequiredValidationFailed || props.isErrored ? styles.error : "")}
                >
                    {props.label && <Label>{props.label}</Label>}
                    <ClickAwayListener onClickAway={() => updatePlaceholders(false)}>
                        <DatePicker
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            onChange={onChange}
                            format="d/M/yyyy"
                            disabled={props.disabled}
                            value={selectedDate}
                            showLeadingZeros={false}
                            className={classnames(styles.datePicker, "d-flex align-items-center form-control w-100")}
                            calendarClassName={styles.calendar}
                            maxDate={maxDate}
                            minDate={minDate}
                            clearIcon={<i className={classnames(styles.icon, "far fa-times-circle text-muted mt-2")} />}
                            calendarIcon={<i className={classnames(styles.icon, "fas fa-calendar-alt text-muted mt-3")} />}
                        />
                    </ClickAwayListener>
                    {(props.error || isRequiredValidationFailed) && (
                        <div className={styles.errorMessage}>{props.error || props.isRequiredError}</div>
                    )}
                </div>
            </div>
        </FormGroup>
    );
};

FloatLabelDate.propTypes = {
    currentDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    changeDate: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    inline: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    error: PropTypes.string,
    isRequired: PropTypes.bool,
    isRequiredError: PropTypes.string,
    validateOnMount: PropTypes.bool,
    className: PropTypes.string,
    isErrored: PropTypes.bool,
};

FloatLabelDate.defaultProps = {
    disabled: false,
    isRequiredError: "This field is required",
    validateOnMount: false,
};

export default FloatLabelDate;
