export const encodeFileToBase64 = (file): Promise<ProgressEvent<FileReader>> => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = resolve;
    reader.readAsDataURL(file);
});

export const encodeFilesToBase64 = (files) => {
    const getBase64 = (file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (ev) => {
                resolve(ev.target?.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const promises = files.map(file => getBase64(file));
    return Promise.all(promises);
};

export interface EncodedFile {
    id?: string,
    name: string;
    data: string | null | undefined;
    disabled?: boolean;
}

const getBase64 = (file: File): Promise<EncodedFile> => {
    const reader = new FileReader();
    return new Promise<EncodedFile>((resolve) => {
        reader.onload = (ev) => {
            resolve({ name: file.name, data: ev.target?.result as string });
        };
        reader.readAsDataURL(file);
    });
};

export const encodeFilesToBase64WithFileName = (files: File[]): Promise<EncodedFile[]> => {
    const promises = files.map(file => getBase64(file));
    return Promise.all(promises);
};

export const encodeFileToBase64WithFileName = (file: File): Promise<EncodedFile> => getBase64(file);

const imageSignatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
};

const detectMimeType = (b64) => {
    let mimeType = "image/png";
    Object.keys(imageSignatures).forEach((signature) => {
        if (b64.indexOf(signature) === 0) {
            mimeType = imageSignatures[signature];
        }
    });
    return mimeType;
};

export const openB64ImageInNewTab = (data) => {
    const mimeType = detectMimeType(data);
    if (mimeType === "application/pdf") {
        const pdfWindow = window.open();
        const b64Pdf = `data:application/pdf;base64,${data}`;
        pdfWindow?.document.write(
            `<iframe width='100%' height='100%' src="${b64Pdf}"></iframe>`,
        );
    } else {
        const imageWindow = window.open();
        const b64Image = `data:image/png;base64,${data}`;
        if (imageWindow?.document.body) {
            imageWindow.document.body.innerHTML = `<img src="${b64Image}" alt={} />`;
        }
    }
};

export const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject();
    reader.onabort = () => reject();
    reader.readAsDataURL(blob);
});
