export enum FlexPlanOrderStatus {
    New = "New",
    Hold = "Hold",
    InProgress = "InProgress",
    Approved = "Approved",
    PayFileCreated = "PayFileCreated",
    PaidFull = "PaidFull",
    PaidPartial = "PaidPartial",
    Invalid = "Invalid",
}

export enum PaymentRequestStatus {
    NoStatus = "NoStatus",
    Open = "Open",
    PendingPayment = "PendingPayment",
    Paid = "Paid",
    AwaitingApproval = "AwaitingApproval",
    Rejected = "Rejected",
    Incomplete = "Incomplete",
    Cancelled = "Cancelled",
    Deleted = "Deleted",
    Error = "Error"
}
