import { joinStrings } from "./formatStringHelper";

export const acceptedFileTypes = {
    images: ".png,.jpg,.jpeg,.gif,",
    documents: ".tiff,.tif,.png,.jpg,.jpeg,.gif,.pdf",
    pdf: ".pdf",
    all: "",
    upsellImages: ".jpg,.jpeg,.png,.webp",
    miniSite: ".jpg,.webp",
};

export function fileTypesText(typesStr: string, finalSeparator: string = " or ") {
    return joinStrings((typesStr ?? "").split(",").filter(_ => (_ ?? "").trim() !== "").map(_ => _.toUpperCase()), ", ", finalSeparator);
}

export const errorConstants = {
    unauthorised: "Unauthorized",
};
