import React from "react";
import classnames from "classnames";
import { Col, Row } from "reactstrap";

import RadioButton from "@/Components/RadioButton";

export const filters = {
    read: "read",
    unread: "unread",
    all: "all",
};

interface Props {
    filterStatus: string;
    onFilterChange(filter: string);
    children?: React.ReactElement,
    className?: string,
}

const MessageFilters = ({ children, className, filterStatus, onFilterChange }: Props) => (
    <Row className={classnames(className && className, "border-bottom box-shadow-bottom")}>
        <Col className="d-flex align-items-center">
            {children && children}
            <RadioButton
                checked={filterStatus === filters.all}
                onChange={onFilterChange(filters.all)}
                label="All"
                name="filterAll"
                id={filters.all}
                className="my-3"
            />
            <RadioButton
                checked={filterStatus === filters.read}
                onChange={onFilterChange(filters.read)}
                label="Read"
                name="filterRead"
                id={filters.read}
                className="my-3"
            />
            <RadioButton
                checked={filterStatus === filters.unread}
                onChange={onFilterChange(filters.unread)}
                label="Unread"
                name="filterUnread"
                id={filters.unread}
                className="my-3"
            />
        </Col>
    </Row>
);

export default MessageFilters;
