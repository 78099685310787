import React, { useEffect, useState } from "react";
import { FormFeedback } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import Textbox from "@/Components/Textbox";
import { useAPI } from "@/Apis/useAPI";
import { ReceiptSavedItem } from "@/Apis/Receipts/Create";
import { useDebounce } from "@/Hooks/useDebounce";
import { toCurrencyWithCommas } from "@/Utils/formatStringHelper";
import { Urls } from "@/Apis/urls";
import useDidMountEffect from "@/Hooks/useDidMountEffect";
import { calculateRowTotal } from "@/Utils/numberHelper";

interface Props {
    onChange: (searchTerm: string) => void,
    onSelect: (receiptItem: ReceiptSavedItem) => void,
    label?: string,
    value?: string,
    maxLength?: number,
    className?: string,
    error?: string,
    testId?: string,
    disabled?: boolean,
}

const ReceiptItemsSearch = ({ onChange,
    onSelect,
    label,
    value,
    maxLength,
    className,
    error,
    testId = "itemCode",
    disabled }: Props) => {
    // Searching
    const { get } = useAPI({ handle500WithToastMessage: true });

    const [listingOpen, setListingOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const [receiptItems, setReceiptItems] = useState<ReceiptSavedItem[]>();
    const [activeReceiptItem, setActiveReceiptItem] = useState<ReceiptSavedItem>();

    const [searchTerm, setSearchTerm] = useState<string>();
    const debounceSearchTerm = useDebounce(searchTerm ?? "", 500);

    // Display errors for 2 seconds as we automatically remove bad data
    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorMessage(undefined);
        }, 2000);

        return () => clearTimeout(timer);
    }, [errorMessage]);

    useDidMountEffect(() => {
        if (!debounceSearchTerm || debounceSearchTerm.length === 0) { // Don't search for empty data
            setReceiptItems(undefined);
            setListingOpen(false);
            onChange(debounceSearchTerm);
            return;
        }

        setLoading(true);
        setListingOpen(true);

        get<ReceiptSavedItem[]>(Urls.receiptSavedItems.lookup(debounceSearchTerm))
            .then(result => {
                setReceiptItems(result);
            })
            .catch(() => {
                setErrorMessage("There is a problem with the user search at this time");
            })
            .finally(() => {
                onChange(debounceSearchTerm);
                setLoading(false);
            });
    }, [debounceSearchTerm]);

    const onBlur = () => setListingOpen(false);
    const onFocus = () => {
        if (receiptItems && receiptItems?.length > 0) { // Open the select list if there are some Input Search results
            setListingOpen(true);
        }
    };

    const onSelectOverride = (receiptItem: ReceiptSavedItem) => {
        setListingOpen(false);
        setActiveReceiptItem(receiptItem);
        onSelect(receiptItem);
    };

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setActiveReceiptItem(undefined);
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (receiptItems && receiptItems?.length === 1) {
                setActiveReceiptItem(receiptItems[0]);
                onSelect(receiptItems[0]);
                setListingOpen(false);
            }
        }
    };

    return (
        <div className={`${className} tw-relative`} onBlur={onBlur}>
            <Textbox
                label={label}
                maxLength={maxLength}
                value={activeReceiptItem?.itemCode?.toString() ?? searchTerm ?? value}
                onChange={onTextChange}
                onFocus={onFocus}
                data-testid={testId}
                className="tw-font-semibold"
                error={error || errorMessage}
                onKeyPress={onKeyPress}
                disabled={disabled}
            />
            {listingOpen && !loading && (
                <div className={classnames(styles.selection, `tw-absolute tw-bg-white tw-z-[100] tw-shadow-2xl 
                                    tw-border -tw-mt-2 tw-max-h-72 tw-overflow-y-auto`)}
                >
                    {receiptItems && receiptItems.map(x => (
                        <button
                            key={x.id}
                            type="button"
                            className={classnames(styles.selectionHeight, `tw-w-full tw-border-b-2 tw-flex tw-items-center tw-px-2 
                                        hover:tw-cursor-pointer hover:tw-bg-gray-200`)}
                            onMouseDown={() => onSelectOverride(x)}
                            data-testid={x.id}
                        >
                            <div className="tw-grid tw-grid-cols-5 tw-gap-4 tw-w-full tw-items-center tw-justify-center">
                                <div className="tw-col-span-1 tw-font-semibold tw-text-left">{x.itemCode}</div>
                                <div className="tw-col-span-3 tw-text-left">{x.name}</div>
                                <div className="tw-col-span-1 tw-font-semibold tw-text-right">{toCurrencyWithCommas(calculateRowTotal(x))}</div>
                            </div>
                        </button>
                    ))}
                </div>
            )}
            {listingOpen && loading && (
                <div className="tw-absolute tw-bg-white tw-z-[100] tw-w-full tw-shadow-2xl tw-border -tw-mt-2 tw-max-h-72 tw-overflow-y-auto">
                    <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-py-2 tw-h-32"><Spinner /></div>
                </div>
            )}
            <FormFeedback className="font-weight-bold">{errorMessage}</FormFeedback>
        </div>
    );
};

export default ReceiptItemsSearch;
