import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Button, Modal, ModalBody } from "reactstrap";

import TextArea from "@/Components/TextArea";
import RadioButton from "@/Components/RadioButton";
import Spinner from "@/Components/Spinner";
import CloseButton from "@/Components/CloseButton";
import { DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import { MessagingUrls } from "@/Apis/Messaging";
import { useAPI } from "@/Apis/useAPI";
import api from "@/Utils/api";
import actions from "@/Store/Global/actions";

const ReplacementOperation = 0;
const RefundOperation = 1;
const CancellationOperation = 2;

const RequestCancellationModal = ({ id, supplierId, isSubscription = false, onOrderStatusChanged }) => {
    const dispatch = useDispatch();
    const [conversationId] = useState(uuid());
    const [loading, setLoading] = useState(false);
    const { loading: attachmentUploading, post } = useAPI({ handle500WithRedirect: true });
    const [request, setRequest] = useState({
        reason: "",
        attachments: [],
        operation: isSubscription ? CancellationOperation : ReplacementOperation,
    });

    const onToggleRequest = (name, value) => () => setRequest({
        ...request,
        [name]: value,
    });
    const onInputRequest = name => event => setRequest({ ...request, [name]: event.target.value });

    const onSubmit = () => {
        setLoading(true);
        const body = {
            orderId: id,
            supplierId,
            conversationId,
            reason: request.reason,
            operation: request.operation,
            attachments: request.attachments,
        };

        api.post("services/request-cancellation", body)
            .then(() => {
                dispatch(push("/customer-orders"));
                onOrderStatusChanged(id, "RequestedCancellation");
                dispatch(actions.setToastMessage(true, "Your cancellation request has been sent."));
            })
            .catch((error) => {
                dispatch(push({ pathname: "/error", state: { error, referrer: window.location.href } }));
            });
    };

    const onSetAttachments = files => {
        files.forEach(file => {
            const url = MessagingUrls.uploadConversationAttachment(conversationId);
            const payload = { base64: file.data, conversationId, filename: file.name };

            post(url, payload)
                .then(response => {
                    setRequest({
                        ...request,
                        attachments: [...request.attachments, response],
                    });
                });
        });
    };

    const onClose = () => dispatch(push(`/customer-orders/${id}/details`));

    const removeAttachment = key => () => {
        setRequest({ ...request, attachments: [...request.attachments.filter(x => x.key !== key)] });
    };

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white">
            <ModalBody className="d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative">
                <CloseButton onClick={onClose} />
                <h3 className="font-weight-bold mb-3">Request {isSubscription ? "to Cancel" : "Refund"}</h3>

                <TextArea onChange={onInputRequest("reason")} value={request.reason} className="mb-3" maxCharacters={8000} />
                {isSubscription === false && (
                    <>
                        <RadioButton
                            checked={request.operation === ReplacementOperation}
                            onChange={onToggleRequest("operation", ReplacementOperation)}
                            id="replacement"
                            name="replacement"
                            label="Request Replacement"
                            className="mb-3"
                        />
                        <RadioButton
                            checked={request.operation === RefundOperation}
                            onChange={onToggleRequest("operation", RefundOperation)}
                            id="refund"
                            name="refund"
                            label="Request Refund"
                            className="mb-3"
                        />
                    </>
                )}

                <div>
                    <DropZoneMulti onAdd={onSetAttachments} onDelete={removeAttachment} initialFiles={request.attachments} />
                </div>

                <div className="d-flex justify-content-end mt-5">
                    <Button color="secondary" className="mr-2" onClick={onClose}>Cancel</Button>
                    <Button color="primary" onClick={onSubmit} disabled={!request.reason || attachmentUploading}>
                        {loading ? <Spinner /> : "Request"}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

RequestCancellationModal.propTypes = {
    id: PropTypes.string.isRequired,
    supplierId: PropTypes.string.isRequired,
    isSubscription: PropTypes.bool,
    onOrderStatusChanged: PropTypes.func.isRequired,
};

export { RequestCancellationModal };
