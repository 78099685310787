import { AddressType } from "../Profile";

export interface BankAccount {
    companyRegistrationNumber: string,
    companyRegisteredOfficeAddress: AddressType,
    companyName: string
    numberOfEmployees: number,
    businessNameOnCard: string,
    title: string,
    firstName : string,
    lastName: string,
    dateOfBirth: string,
    emailAddress: string,
    mobileNumber: string,
    workNumber: string,
    homeAddress: AddressType,
    sicCode: number,
    industryId?: number,
}

export interface BankAccountOrderField {
    orderFieldId: string,
    orderFieldAnswer: string,
    orderFieldType: FieldType,
    attributeDisplayName: string,
    attributeName: string,
}

export interface BankAccountStatus {
    message: string,
    referenceNumber: number,
    sortCode: string,
    accountNumber: string,
    errorCode: string,
}

export interface BankAccountOrder {
    bankAccountStatus: BankAccountStatus,
    bankAccountOrderFields: BankAccountOrderField[],
}

export const titles: string[] = [
    "Mr",
    "Mrs",
    "Miss",
    "Ms",
    "Dr",
];

export enum FieldType
{
    Text = 1,
    DOB = 2,
    AddressLookup = 3,
    TextArea = 6,
    Date = 7,
    UploadAttachment = 8,
    YesNo = 9,
    Phone = 10
}
