import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import classnames from "classnames";
import { v4 as uuid } from "uuid";

import { CustomFieldRow } from "./CustomFieldRow";
import styles from "./styles.module.scss";

import { saveSupplierOwnedCustomField, getSupplierOwnedCustomFields, deleteSupplierOwnedCustomField } from "@/Apis/Suppliers";
import { OwnedCustomFieldType } from "@/Apis/Suppliers/OwnedCustomFieldsType";
import actions from "@/Store/Global/actions";
import sortingUtil from "@/Utils/sortingUtil";

const ManageCustomFieldsPage = () => {
    document.title = "BSC - Manage Custom Fields";

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [ownedCustomFields, setOwnedCustomFields] = useState<Array<OwnedCustomFieldType>>([]);
    const fixedHeaderRef = useRef<HTMLTableElement>(null);

    const onApiError = () => {
        dispatch(actions.setErrorToastMessage(true, "Sorry something went wrong, please refresh the page and try again"));
    };

    useEffect(() => {
        setIsLoading(true);
        getSupplierOwnedCustomFields()
            .then((ownedFieldsResponse) => {
                setOwnedCustomFields(ownedFieldsResponse.customFields);
                setIsLoading(false);
            });
    }, []);

    const nextOrdinal = () => (ownedCustomFields.reduce((a, b) => (a.ordinal < b.ordinal ? a : b), {} as OwnedCustomFieldType).ordinal - 1);

    const onNewOwnedCustomField = () => setOwnedCustomFields([{
        id: uuid(),
        displayText: "",
        type: "Text",
        ordinal: nextOrdinal(),
        appliedToProducts: [],
        appliedToBespokeQuotes: [],
        appliedToOrderFormCount: 0,
        appliedToCustomerInformationFieldsCount: 0,
    }, ...ownedCustomFields] as Array<OwnedCustomFieldType>);

    const onDisplayTextChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setOwnedCustomFields(prevState => {
            const updatedOwnedCustomFields = prevState.filter(x => x.id !== id);
            const itemToUpdate = prevState?.find(x => x.id === id);
            return [...updatedOwnedCustomFields, {
                ...itemToUpdate,
                displayText: value,
            }] as Array<OwnedCustomFieldType>;
        });
    };

    const onFieldTypeChange = (id: string) => (type: string) => {
        setOwnedCustomFields(prevState => {
            const updatedOwnedCustomFields = prevState.filter(x => x.id !== id);
            const itemToUpdate = prevState?.find(x => x.id === id);
            return [...updatedOwnedCustomFields, {
                ...itemToUpdate,
                type,
            }] as Array<OwnedCustomFieldType>;
        });
    };

    const saveOwnedCustomField = (id: string) => () => {
        const updatedOwnedCustomFields = ownedCustomFields.filter(x => x.id !== id);
        const itemToUpdate = ownedCustomFields?.find(x => x.id === id);

        setOwnedCustomFields([...updatedOwnedCustomFields, { ...itemToUpdate, isUpdating: true }] as Array<OwnedCustomFieldType>);
        saveSupplierOwnedCustomField(id, itemToUpdate as OwnedCustomFieldType)
            .then(() => {
                setOwnedCustomFields([...updatedOwnedCustomFields, { ...itemToUpdate, isUpdating: false }] as Array<OwnedCustomFieldType>);
                dispatch(actions.setToastMessage(true, "Custom Field Saved"));
            })
            .catch(onApiError);
    };

    const deleteOwnedCustomField = (id: string) => () => {
        const updatedOwnedCustomFields = ownedCustomFields.filter(x => x.id !== id);
        const itemToDelete = ownedCustomFields?.find(x => x.id === id)
            ?? { appliedToProducts: [], appliedToBespokeQuotes: [], appliedToCustomerInformationFieldsCount: [], appliedToOrderFormCount: [] };
        if (itemToDelete?.appliedToProducts.length > 0) {
            dispatch(actions.setErrorToastMessage(true, "Field has been applied to services, go to the MiniSite and unapply before removing"));
            return;
        }
        if (itemToDelete?.appliedToBespokeQuotes.length > 0) {
            dispatch(actions.setErrorToastMessage(true, "Field has been applied to bespoke quotes"));
            return;
        }

        setOwnedCustomFields([...updatedOwnedCustomFields, { ...itemToDelete, isDeleting: true }] as Array<OwnedCustomFieldType>);
        deleteSupplierOwnedCustomField(id)
            .then(() => {
                setOwnedCustomFields([...updatedOwnedCustomFields] as Array<OwnedCustomFieldType>);
                dispatch(actions.setToastMessage(true, "Field Deleted"));
            })
            .catch(onApiError);
    };

    return (
        <div className={classnames(styles.container, "px-0 p-3  bg-white box-shadow border")}>
            <h3 className="mt-2 font-weight-bold">Manage Custom Fields</h3>
            <div className="mt-2 d-flex flex-column">
                <div className="d-flex align-items-end">
                    <Button color="primary" className="ml-auto" onClick={onNewOwnedCustomField} data-testid="addCustomField" disabled={isLoading}>
                        Add Custom Fields <i className="fas fa-plus ml-1 text-success" />
                    </Button>
                </div>
                <div className="d-flex">
                    <table className="d-block" ref={fixedHeaderRef}>
                        <tr>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Services
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Bespoke Quotes
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Orders Forms
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Field Type
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Name
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")} />
                        </tr>
                        <div className={classnames("overflow-y-auto", styles.content)}>
                            {ownedCustomFields
                                .sort((x, y) => sortingUtil.sort(x, y, "ordinal", true))
                                .map(x => (<CustomFieldRow
                                    ownedCustomField={x}
                                    deleteOwnedCustomField={deleteOwnedCustomField}
                                    isLoading={isLoading}
                                    onDisplayTextChange={onDisplayTextChange}
                                    onFieldTypeChange={onFieldTypeChange}
                                    saveOwnedCustomField={saveOwnedCustomField}
                                    key={x.id}
                                />))}
                        </div>
                    </table>
                </div>
            </div>
        </div>
    );
};

export { ManageCustomFieldsPage };
