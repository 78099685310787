import { useAPI } from "@/Apis/useAPI";
import { ProductResponseType, ProductSortOptions } from "@/Apis/Products/ProductType";

const useSuggestions = () => {
    const { loading, post } = useAPI({ handle500WithRedirect: true });

    const getSuggestions = async (searchTerm: string) => {
        if (searchTerm === "") {
            return [];
        }
        return (await post<ProductResponseType>("products/search", {
            searchTerm,
            categoryIdsFilter: [],
            supplierIdsFilter: [],
            sortOrder: ProductSortOptions.Alphabetical,
            pageNumber: 1,
            pageLength: 30,
        })).products.map(x => ({ label: x.name, value: x.name }));
    };

    return {
        loading,
        getSuggestions,
    };
};

export { useSuggestions };
