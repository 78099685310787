/* eslint-disable no-console */
import React, { FormEvent, useEffect, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { HubConnection } from "@microsoft/signalr";

import Textbox from "@/Components/Textbox";
import { getToken } from "@/Utils/authentication";
import { getBrowserId } from "@/Utils/localStorage";

const SignalRTestPage = () => {
    const currentUserId = useSelector<any>(state => state?.user?.userId);
    const [userIdentifier, setUserIdentifier] = useState("");
    const [messageToSend, setMessageToSend] = useState("");
    const [clientMessages, setClientMessage] = useState<Array<string>>([]);
    const hubConnectionRef = useRef<null | HubConnection>();

    useEffect(() => {
        hubConnectionRef.current = new signalR.HubConnectionBuilder()
            .withUrl(`/signalr?browserId=${getBrowserId()}`, { accessTokenFactory: () => getToken() })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const hubConnection = hubConnectionRef.current;

        hubConnection
            .start()
            .then(() => hubConnection.on("testMessage", msg => setClientMessage(prevState => [...prevState, msg])))
            .catch(reason => console.log(reason));

        return () => {
            hubConnection.stop().then().catch(reason => console.log(reason));
        };
    }, []);

    const messageTextBoxOnChange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setMessageToSend(value);
    };

    const userIdTextBoxOnChange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setUserIdentifier(value);
    };

    const onSend = () => {
        // eslint-disable-next-line no-unused-expressions
        hubConnectionRef.current?.invoke("sendTestNotificationToUser", userIdentifier, messageToSend)
            .catch(reason => console.log(reason));
    };

    const copyToClipboard = (text) => async () => {
        await navigator.clipboard.writeText(text);
    };

    return (
        <div className="w-25 ml-auto mr-auto mt-5">
            <h3 className="mt-5 mb-4">SignalR Test Harness</h3>
            <p className="font-weight-bold">
                <>
                    <Button color="primary" className="mr-3" onClick={copyToClipboard(currentUserId)}><i className="fas fa-copy" /></Button>
                    Current UserId: {currentUserId}
                </>
            </p>
            <p className="font-weight-bold">
                <Button color="primary" className="mr-3" onClick={copyToClipboard(getBrowserId())}><i className="fas fa-copy" /></Button>
                Current BrowserId: {getBrowserId()}
            </p>
            <Textbox onChange={userIdTextBoxOnChange} value={userIdentifier} placeholder="UserId or BrowserId of user" />
            <Textbox onChange={messageTextBoxOnChange} value={messageToSend} placeholder="Test message to send to user" />
            <Button onClick={onSend}>Send <i className="fa fa-envelope" /></Button>

            <h3 className="mt-5 mb-2">Messages</h3>
            {clientMessages.map(x => (<p key={x}>{x}</p>))}
        </div>);
};

export { SignalRTestPage };
