/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";

import { HeaderContent } from "./HeaderContent";
import styles from "./styles.module.scss";

import CalvinJoinUs from "@/Assets/Images/calvin-join-us.png";
import { sideBarTypes } from "@/Store/Global/constants";
import { MinisiteTemplateOptions } from "@/Pages/MiniSiteEditorPage/MiniSiteEditorSideBar/TemplateOptions";
import { useMinisiteContext } from "@/Context/MinisiteContext";

const MiniSiteEditorSideBar = () => {
    const {
        editorType,
        showSideBar,
        setShowSideBar,
    } = useMinisiteContext();

    let content;
    switch (editorType) {
        case sideBarTypes.editHeader:
            content = <HeaderContent />;
            break;
        case sideBarTypes.editDescription:
            content = <MinisiteTemplateOptions />;
            break;
        default:
            content = (
                <div className="mt-3">
                    <h3 className="mb-5 font-weight-bold">Welcome to the Shop Editor!</h3>
                    <img className={classnames(styles.welcomeImg, "w-50 d-block mx-auto")} src={CalvinJoinUs} alt="Side bar" />
                    <p className="text-center mt-4">Hover over an element on the template to edit it.</p>
                </div>
            );
    }

    return (
        <div className={classnames(styles.sideBar, showSideBar && "sticky-sidebar d-md-block", "d-none box-shadow-bottom-2 text-white px-3")}>
            <button
                type="button"
                className={classnames(styles.close, "position-absolute bg-transparent border-0 m-0 px-2")}
                onClick={() => setShowSideBar(false)}
                data-testid="close"
            >
                <i className="fa fa-times m-0" />
            </button>
            {content}
        </div>
    );
};

export { MiniSiteEditorSideBar };
