import { Filter } from "@/FlexPlan/Types/Pagination";
import { enumDisplay } from "@/Utils/formatStringHelper";

export const getFiltersFromEnum = (enumObj: object,
    defaultChecked: boolean = false,
    renderer: (value: string) => string = enumDisplay) => Object
    .keys(enumObj)
    .map((enumPropName, index) => ({
        field: enumObj[enumPropName],
        checked: defaultChecked,
        position: index + 1,
        label: renderer(enumObj[enumPropName]),
    } as Filter));
