import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const CartButton = ({ onClick, className, customText }) => (
    <button
        type="button"
        data-testid="sideCartButton"
        className={classnames(styles.container, "position-absolute box-shadow-bottom-2 text-white bg-primary border-0 pr-4 p-0", className)}
        onClick={onClick}
    >
        <i className="fa fa-angle-double-left mr-2" />
        {customText || "Cart"}
    </button>
);

CartButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    customText: PropTypes.string,
};

export { CartButton };
