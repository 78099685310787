import React from "react";

import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    errors: any,
}

const AddressSection = ({ onSave, removeErrorField, errors }: Props) => {
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();

    const onAddressSave = (address: LoqateAddress) => {
        // Immediately save the address
        onSave({
            ...supplierProfileState,
            address,
        });
        dispatch({
            type: SupplierProfileActions.Update,
            field: "address",
            value: address,
        });
    };

    const onAddressChange = () => {
        removeErrorField("address");
    };

    return (
        <FloatLabelAddressLookUp
            address={supplierProfileState.address}
            onStateChange={onAddressChange}
            onSave={onAddressSave}
            error={errors?.address && "Please enter a valid address"}
            errorClasses="font-weight-bold !tw-text-bootstrap-error-size tw-mt-2"
            disabled={supplierProfileState.isPublic}
        />
    );
};

export { AddressSection };
