import React from "react";
import { FormFeedback } from "reactstrap";
import classnames from "classnames";

interface Props {
    children: React.ReactNode | React.ReactNode[] | string,
    className?: string,
    textSizeClassName?: string,
}

// We're using BSC components for FlexPlan. These components can display error text in a variety of
// styles, or miss the error off completely. This allows us to mimic the reactstrap form error for a consistent UI
const FormError = ({ children, className, textSizeClassName = "!tw-text-bootstrap-error-size" }: Props) => (
    <FormFeedback className={classnames("font-weight-bold !tw-block", textSizeClassName, className)}>
        {children}
    </FormFeedback>
);

export { FormError };
