import React, { useEffect, useState } from "react";

import { DueToSupplierPopup } from "../DueToSupplierPopup";

import { getFrequencyBasic } from "@/Utils/formatStringHelper";

interface Props {
    services: any[]
}

interface SummaryLine {
    key: string;
    amount: number;
}

const SubscriptionSummary = ({ services } : Props) => {
    const [dueToSupplierLines, setDueToSupplierLines] = useState<any[]>([]);
    const [summaryLines, setSummaryLines] = useState<SummaryLine[]>([]);

    const groupBy = (xs, key) => xs.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

    useEffect(() => {
        const groupedServices = groupBy(services.filter(service => !service.isOffsitePayment), "paymentFrequency");
        const dueToSupplierServices = services.filter(service => service.isOffsitePayment);

        setSummaryLines(Object.keys(groupedServices).map((x) => (
            {
                key: getFrequencyBasic(x),
                amount: groupedServices[x].reduce((sum, current) => sum + current.initialChargeWithVatIfApplicable, 0.00),
            }
        )));

        setDueToSupplierLines(dueToSupplierServices);
    }, [services]);

    return (
        <>
            {summaryLines.filter(x => x.key !== "").length > 0 && (
                <div className="d-flex flex-column px-4 pt-4">
                    <h5 className="font-weight-bold mb-3">Ongoing Subscriptions</h5>
                    { summaryLines.map((x) => (
                        x.key !== "" && (<p key={x.key}>{x.key} <b>£{x.amount.toFixed(2)}</b></p>)
                    )) }
                </div>)}

            {dueToSupplierLines.length > 0 && (
                <div className="d-flex flex-column px-4 pt-4">
                    <h5 className="font-weight-bold">Due to Supplier<DueToSupplierPopup items={dueToSupplierLines} /></h5>
                </div>)}
        </>
    );
};

export { SubscriptionSummary };
