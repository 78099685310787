/* eslint-disable import/extensions */
import React from "react";
import { Link } from "react-router-dom";

import Participants from "@/FlexPlan/Assets/Images/dashboard/participants.jpg";
import Suppliers from "@/FlexPlan/Assets/Images/dashboard/suppliers.jpg";
/* import ServiceBookings from "@/FlexPlan/Assets/Images/dashboard/service-bookings.jpg"; */
import Orders from "@/FlexPlan/Assets/Images/dashboard/orders.jpg";

const Dashboard = () => {
    const blocks = [
        {
            title: "Participants",
            imgSrc: Participants,
            desc: "View, Manage and Create plan managed participant records",
            linkText: "Manage Participants",
            path: "/participants/manage",
        },
        {
            title: "Suppliers",
            imgSrc: Suppliers,
            desc: "Manage and Create supplier records",
            linkText: "Manage Suppliers",
            path: "/supplier-management",
        },
        {
            title: "Orders",
            imgSrc: Orders,
            desc: "Reserve and Adjust funding in a plan allocated to a supplier",
            linkText: "Manage Orders",
            path: "/supplier-orders",
        },
        /* {
            title: "Service Bookings",
            imgSrc: ServiceBookings,
            desc: "Process Service Bookings to reserve funding in an NDIS plan",
            linkText: "Manage Service Bookings",
            path: "/",
        }, */
    ];

    return (
        <div className="tw-bg-gray-100 tw-h-screen tw-overflow-auto">
            <div className="tw-grid tw-gap-x-8 tw-gap-y-10 tw-grid-cols-3 tw-mx-24 3xl:tw-mx-64 tw-my-6">
                {blocks.map(item => (
                    <div className="tw-bg-white tw-rounded-sm tw-shadow-md" key={item.title}>
                        <h5 className="tw-text-4xl tw-ml-5 tw-font-bold tw-my-4">{item.title}</h5>
                        <img
                            src={item.imgSrc}
                            alt=""
                        />
                        <div className="tw-text-center tw-mt-8 tw-mx-10 ">
                            <p className="tw-text-base">{item.desc}</p>
                            <p className="tw-font-semibold tw-text-lg tw-mt-8 tw-mb-12">
                                <Link key={`${item.linkText}-${item.path}`} to={item.path} className="tw-text-flexplan-secondary">{item.linkText}</Link>
                            </p>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    );
};

export { Dashboard };
