import { Button } from "reactstrap";
import React from "react";
import classnames from "classnames";

interface ActionButtonsProps {
    onCancelClick(e): void;
    onSaveClick(e?): void;
    disabled?: boolean;
    customErrorMessage?: string;
}

const ActionButtons = ({ disabled, onSaveClick, onCancelClick, customErrorMessage } : ActionButtonsProps) => (
    <div className="align-self-end">
        {!customErrorMessage
            && (<div className={classnames("text-danger", "font-weight-bold", disabled && "visible", !disabled && "invisible")}>Please amend the errors above</div>)}
        {customErrorMessage
            && (<div className={classnames("text-danger", "font-weight-bold", customErrorMessage && "visible", !customErrorMessage && "invisible")}>{customErrorMessage}</div>)}
        <Button onClick={onCancelClick} color="secondary" className="mr-1">
            Cancel
        </Button>
        <Button
            onClick={() => onSaveClick()} // Don't pass on save event
            disabled={disabled}
            color="primary"
        >
            Save
        </Button>
    </div>
);

export { ActionButtons };
