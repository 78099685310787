import { MiniSiteType } from "./MiniSiteType";

import { ColumnType } from "@/Components/ConfigureProductRow/ColumnTypes";
import api from "@/Utils/api";
import { OwnedSubCategoriesType, SubCategoryType } from "@/Apis/Suppliers/OwnedSubCategoriesType";
import { CustomFieldType, OwnedCustomFieldsType, OwnedCustomFieldType } from "@/Apis/Suppliers/OwnedCustomFieldsType";

const getSupplierMinisite = () => api.get("suppliers") as Promise<MiniSiteType>;
const saveSupplierMinisite = (request: MiniSiteType) => (api.post("suppliers", request));

export const getSupplierOwnedSubCategoriesUrl = "productCatalog/subcategories";
const getSupplierOwnedSubCategories = () => api.get("productCatalog/subcategories") as Promise<OwnedSubCategoriesType>;
const saveSupplierOwnedSubCategory = (id: string, request: SubCategoryType) => api.post(`productCatalog/subcategories/${id}`, request) as Promise<SubCategoryType>;
const deleteSupplierOwnedSubCategory = (id: string) => api.del(`productCatalog/subcategories/${id}`) as Promise<SubCategoryType>;

const getSupplierOwnedCustomFields = () => api.get("productCatalog/fields") as Promise<OwnedCustomFieldsType>;
const saveSupplierOwnedCustomField = (id: string, request: OwnedCustomFieldType) => api.post(`productCatalog/fields/${id}`, request) as Promise<OwnedCustomFieldType>;
const deleteSupplierOwnedCustomField = (id: string) => api.del(`productCatalog/fields/${id}`) as Promise<CustomFieldType>;

const getSupplierOwnedVariations = () => (api.get("productCatalog/ownedvariations").then(response => (response.variations as Promise<Array<ColumnType>>)));
const getSupplierVariations = (productId: string) => (api.get(`productCatalog/variations/${productId}`).then(response => (response.variations as Promise<Array<ColumnType>>)));
const saveSupplierOwnedVariation = (id: string, request: ColumnType) => api.post(`productCatalog/variations/${id}`, { id: request.variationId, ...request }) as Promise<ColumnType>;
const deleteSupplierOwnedVariation = (id: string) => (api.del(`productCatalog/variations/${id}`));

export {
    getSupplierMinisite,
    saveSupplierMinisite,
    getSupplierOwnedSubCategories,
    saveSupplierOwnedSubCategory,
    deleteSupplierOwnedSubCategory,
    getSupplierOwnedCustomFields,
    saveSupplierOwnedCustomField,
    deleteSupplierOwnedCustomField,
    getSupplierOwnedVariations,
    getSupplierVariations,
    saveSupplierOwnedVariation,
    deleteSupplierOwnedVariation,
};

export interface SupplierType {
    suppliers: SupplierFieldType[];
    filters: SupplierFilterType[];
    supplierMiniSite: MiniSiteType;
}

export interface SupplierFieldType {
    id: string;
    logo: string;
    primarySiteColour: string;
    name: string;
    description: string;
    rating: string;
    categoryName: string;
    categoryId: string;
    categories: string[];
}

export interface SupplierFilterType {
    id: string;
    name: string;
}
