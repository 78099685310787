import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./styles.module.scss";

import PasswordField from "@/Components/PasswordField";
import { Feature } from "@/Components/Feature";
import api from "@/Utils/api";
import { validateEmailFormat } from "@/Utils/validator";
import Modal from "@/Modals/Modal";
import TroubleLoggingInModal from "@/Modals/TroubleLoggingInModal";
import { features } from "@/Utils/features";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { GoogleSignIn } from "@/Components/GoogleSignIn";
import { GoogleSignInType } from "@/Components/GoogleSignIn/Types";

const LoginForm = ({ onSuccess, onNavigateToSignUp }) => {
    const recaptchaRef = useRef();
    const { recaptchaKey } = useConfigurationContext();
    const [state, setState] = useState({
        showForgotPasswordModal: false,
        loading: false,
        email: "",
        password: "",
        loginFailed: false,
        isLockedOut: false,
    });

    const edit = name => (e) => {
        const value = e.target.value;
        setState(prevState => ({
            ...prevState,
            [name]: value,
            emailInvalid: false,
            passwordInvalid: false,
            loginFailed: false,
        }));
    };

    const onRecaptchaExecuted = (recaptcha) => {
        setState({ ...state, loading: true });
        api.post("account/login", { username: state.email, password: state.password, recaptcha })
            .then((response) => {
                onSuccess(response);
            })
            .catch((response) => {
                if (features.isEnabled(features.recaptcha)) {
                    window.grecaptcha.reset();
                }

                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    loginFailed: true,
                    isLockedOut: response.isLockedOut,
                }));
            });
    };

    const submitForm = (e) => {
        e.preventDefault();
        const passwordInvalid = !state.password;
        const emailInvalid = !validateEmailFormat(state.email);

        if (emailInvalid || passwordInvalid) {
            setState(prevState => ({ ...prevState, emailInvalid, passwordInvalid }));
            return;
        }

        if (features.isEnabled(features.recaptcha)) {
            recaptchaRef.current.execute();
        } else {
            onRecaptchaExecuted("");
        }
    };

    return (
        <>
            <Form onSubmit={submitForm}>
                {state.loginFailed && !state.isLockedOut && (
                    <div className={classnames(styles.error, "font-weight-bold text-center mb-3 text-danger")}>
                        An incorrect email address or password was entered. Please try again.
                    </div>
                )}

                {state.isLockedOut && (
                    <div className={classnames(styles.error, "font-weight-bold text-center mb-3 text-danger")}>
                        You have been locked out of your account after failing to login successfully. Please reset your password below.
                    </div>
                )}

                {!state.isLockedOut && (
                    <>
                        <FormGroup>
                            <Label>Email address *</Label>
                            <Input
                                type="text"
                                autoFocus
                                placeholder="Your email address"
                                invalid={state.emailInvalid}
                                value={state.email || ""}
                                onChange={edit("email")}
                                data-testid="email-address-field"
                            />
                            <FormFeedback className="font-weight-bold">Please enter a valid email address.</FormFeedback>
                        </FormGroup>
                        <PasswordField
                            onChange={edit("password")}
                            invalid={state.passwordInvalid}
                            value={state.password || ""}
                        />
                        <div className="d-flex justify-content-center mb-2 mt-3 pt-4">
                            <Button color="primary" type="submit">Log in</Button>
                        </div>
                        <Feature name={features.googleSignIn}>
                            <GoogleSignIn
                                className="tw-mb-4"
                                displayOr
                                onSuccess={onSuccess}
                                type={GoogleSignInType.Login}
                            />
                        </Feature>
                    </>
                )}

                {state.isLockedOut && (
                    <div className="d-flex justify-content-center mb-5 mt-3 pt-4">
                        <Button
                            color="primary"
                            type="submit"
                            onClick={() => setState(prevState => ({ ...prevState, showForgotPasswordModal: true }))}
                        >
                            Reset my password
                        </Button>
                    </div>
                )}

                <Feature name={features.recaptcha}>
                    <ReCAPTCHA
                        size="invisible"
                        ref={recaptchaRef}
                        badge="bottomright"
                        sitekey={recaptchaKey}
                        onChange={onRecaptchaExecuted}
                    />
                </Feature>
            </Form>
            <Row className="mt-4">
                <Col xs="12" md="6" className="text-center text-md-right">
                    <button
                        className={classnames(styles.troubleLoggingIn, "font-weight-bold")}
                        type="button"
                        onClick={() => setState(prevState => ({ ...prevState, showForgotPasswordModal: true }))}
                    >
                        Trouble logging in?
                    </button>
                </Col>
                <Col xs="12" md="6" className={classnames(styles.signUpContainer, "text-center text-md-left")}>
                    <Button color="link" className={classnames(styles.signUp, "font-weight-bold")} onClick={onNavigateToSignUp}>
                        Create account
                    </Button>
                </Col>
            </Row>

            <Modal isOpen={state.loading} showSpinner title="Logging In">
                <p>Your information is being submitted. Sit back, relax, and we’ll take care of the rest.</p>
            </Modal>
            {state.showForgotPasswordModal && (
                <TroubleLoggingInModal
                    onClose={() => setState(prevState => ({ ...prevState, showForgotPasswordModal: false }))}
                    email={state.email}
                />
            )}
        </>
    );
};

LoginForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onNavigateToSignUp: PropTypes.func.isRequired,
};

export { LoginForm };
