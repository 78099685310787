import React, { Dispatch, SetStateAction, useState } from "react";
import { FormGroup } from "reactstrap";

import { Popper } from "@/Components/Popper";
import RadioButton from "@/Components/RadioButton";
import { SalesRequest, SortOrder } from "@/Apis/SalesManagement";

interface Props {
    searchQuery: SalesRequest,
    setSearchQuery: Dispatch<SetStateAction<SalesRequest>>,
}

const Sort = ({ searchQuery, setSearchQuery }: Props) => {
    const [localState, setLocalState] = useState(searchQuery);

    const onSortChanged = (option: SortOrder) => {
        setLocalState((prevState: SalesRequest) => ({
            ...prevState,
            sortOrder: option,
        }));
    };

    const cancel = () => {
        setLocalState(searchQuery);
    };

    const submit = () => {
        setSearchQuery(localState);
    };

    return (
        <>
            <Popper
                targetId="sort"
                triggerText="Sort"
                icon="fa-sort-amount-down-alt"
                onSubmit={submit}
                onCancel={cancel}
            >
                <div className="d-flex justify-content-between m-3">
                    <FormGroup>
                        <legend className="text-uppercase text-primary">Date</legend>
                        <FormGroup>
                            <RadioButton
                                name="sort-date"
                                id="sort-order-latest"
                                checked={localState.sortOrder === SortOrder.DateDesc}
                                onChange={() => onSortChanged(SortOrder.DateDesc)}
                                className="d-inline-block mr-0"
                                label="Latest"
                            />
                        </FormGroup>
                        <FormGroup>
                            <RadioButton
                                name="sort-date"
                                id="sort-order-newest"
                                checked={localState.sortOrder === SortOrder.DateAsc}
                                onChange={() => onSortChanged(SortOrder.DateAsc)}
                                className="d-inline-block mr-0"
                                label="Oldest"
                            />
                        </FormGroup>
                    </FormGroup>
                </div>
            </Popper>
        </>
    );
};

export { Sort };
