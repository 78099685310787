export class UserManagementUrls {
    private static users = "users";

    static lookupUsers = `${UserManagementUrls.users}/lookup`;
}

export interface User {
    userId: string
    firstName: string;
    surname: string;
    email: string
    supplierName: string
}

export interface UserRequest {
    page: number,
    sortOrder: SortOrder
}

export enum SortOrder {
    Alphabetical,
    FirstNameAsc,
    FirstNameDesc,
    SurnameAsc,
    SurnameDesc,
    EmailAsc,
    EmailDesc,
    SupplierAsc,
    SupplierDesc
}

export interface LookupUsersQueryResponse {
    users: User[]
}
