import React from "react";
import { Button } from "reactstrap";

import { useMinisiteContext } from "@/Context/MinisiteContext";
import { MinisiteEditState } from "@/Pages/MiniSiteEditorPage/Types";
import { Template1 } from "@/Pages/MiniSiteEditorPage/Template/Template1";
import { Template2 } from "@/Pages/MiniSiteEditorPage/Template/Template2";
import { Template3 } from "@/Pages/MiniSiteEditorPage/Template/Template3";
import { Template4 } from "@/Pages/MiniSiteEditorPage/Template/Template4";
import { setSupplierDirectory } from "@/Utils/localStorage";

const Template = () => {
    const { editorType, miniSite } = useMinisiteContext();

    if (editorType !== MinisiteEditState.editDescription) {
        return (<></>);
    }

    const onClickPreview = () => {
        setSupplierDirectory(miniSite);
        window.open("/mini-site/supplier-preview", "", "width=1000,height=650");
    };

    return (
        <>
            <h3 className="tw-pl-7 tw-font-semibold">
                Choose a template for your About section
            </h3>
            <div className="tw-flex tw-flex-row tw-space-x-6 tw-pl-7 tw-my-5">
                <div className="tw-text-gray-400">
                    <i className="fa fa-image" />
                    <span className="tw-ml-2">Image</span>
                </div>
                <div className="tw-text-gray-400">
                    <i className="fa fa-align-justify" />
                    <span className="tw-ml-2">Text</span>
                </div>
                <div className="tw-text-gray-400">
                    <i className="fa fa-star" />
                    <span className="tw-ml-2">Rating</span>
                </div>
                <div className="tw-text-gray-400">
                    <i className="fa fa-envelope-open-text" />
                    <span className="tw-ml-2">Contact form</span>
                </div>
                <div className="!tw-ml-auto !tw-mr-4">
                    <Button
                        color="primary"
                        onClick={onClickPreview}
                    >
                        Preview
                    </Button>
                </div>
            </div>
            <div
                className="tw-flex tw-flex-row tw-flex-wrap tw-px-7 tw-gap-8 tw-mb-5"
            >
                <Template1 />
                <Template2 />
                <Template3 />
                <Template4 />
            </div>
        </>
    );
};

export { Template };
