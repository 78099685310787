import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ViewBespokeQuote } from "../ViewBespokeQuote";
import { EditBespokeQuote } from "../EditBespokeQuote";

import * as api from "@/Apis/BespokeQuote";
import globalActions from "@/Store/Global/actions";
import { useMessagingContext } from "@/Context/MessagingContext";

const BespokeQuote = ({ quote, conversationId, isSupplier, supplierName, isFirstQuote }) => {
    const dispatch = useDispatch();
    const messagingContext = useMessagingContext();
    const [isReadMode, setIsReadMode] = useState(!!quote);

    const onRemoveQuote = async () => {
        await api.removeQuote(quote.quoteId);
        messagingContext.quoteRemoved(quote.quoteId);
        dispatch(globalActions.setToastMessage(true, "Quote removed"));
    };

    const switchToEditMode = () => setIsReadMode(false);
    const switchToViewMode = () => setIsReadMode(true);

    return isReadMode
        ? (
            <ViewBespokeQuote
                quote={quote}
                isSupplier={isSupplier}
                supplierName={supplierName}
                onRemoveClick={onRemoveQuote}
                onEditClick={switchToEditMode}
                isFirstQuote={isFirstQuote}
            />
        ) : <EditBespokeQuote quote={quote} conversationId={conversationId} onRemoveClick={onRemoveQuote} onSuccess={switchToViewMode} />;
};

BespokeQuote.propTypes = {
    conversationId: PropTypes.string.isRequired,
    isSupplier: PropTypes.bool.isRequired,
    supplierName: PropTypes.string.isRequired,
    quote: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rejectionReason: PropTypes.string,
        total: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        quoteId: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            price: PropTypes.number.isRequired,
            pricingUnit: PropTypes.string.isRequired,
            totalUnits: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
            applyVat: PropTypes.bool.isRequired,
        }).isRequired),
        termsDocuments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
        }).isRequired),
        customFields: PropTypes.arrayOf(PropTypes.shape({
            fieldId: PropTypes.string.isRequired,
            isRequired: PropTypes.bool.isRequired,
        }).isRequired),
    }),
    isFirstQuote: PropTypes.bool.isRequired,
};

export { BespokeQuote };
