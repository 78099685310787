import React from "react";
import PropTypes from "prop-types";
import { Form, FormGroup, Input, FormFeedback, Button } from "reactstrap";

import Spinner from "@/Components/Spinner";
import { validateEmail } from "@/Utils/validator";
import api from "@/Utils/api";
import Modal from "@/Modals/Modal";

class TroubleLoggingInModal extends React.PureComponent {
    state = {
        email: this.props.email,
        loading: false,
        emailSent: false,
    };

    onSubmit = (e) => {
        e.preventDefault();
        const email = this.state.email || this.props.email;
        const emailInvalid = !validateEmail(email);
        if (emailInvalid) {
            this.setState({ emailInvalid });
        } else {
            this.setState({ loading: true });
            api.post("account/forgotPassword", { email })
                .then(() => {
                    this.setState({
                        loading: false,
                        emailSent: true,
                    });
                })
                .catch(() => {
                    this.setState({
                        emailSent: true,
                        loading: false,
                    });
                });
        }
    };

    edit = name => (e) => {
        this.setState({
            [name]: e.target.value,
            emailInvalid: false,
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <Modal isOpen title="Trouble Logging In?">
                    <Spinner size="45" className="mx-auto d-flex my-5" />
                </Modal>
            );
        }

        if (this.state.emailSent) {
            return (
                <Modal isOpen title="Reset Password Link Sent">
                    <p>We’ve sent the reset password link to your email address. Just in case you don’t see it, please check your spam or junk folder for our email.</p>
                    <div className="text-center pt-3">
                        <Button color="primary" type="button" onClick={this.props.onClose}>Dismiss</Button>
                    </div>
                </Modal>
            );
        }

        return (
            <Modal isOpen title="Trouble Logging In?">
                <p className="pb-1">Please enter the email address that you are using with your BSC account. We will be sending a link to reset your password.</p>
                <Form onSubmit={this.onSubmit}>
                    <FormGroup>
                        <Input
                            type="text"
                            placeholder="Your email address"
                            invalid={this.state.emailInvalid}
                            value={this.state.email}
                            onChange={this.edit("email")}
                        />
                        <FormFeedback className="font-weight-bold">Please enter a valid email address.</FormFeedback>
                    </FormGroup>

                    <div className="text-center mt-4 pt-3">
                        <Button color="secondary" className="mr-2" type="button" onClick={this.props.onClose}>Cancel</Button>
                        <Button color="primary" type="submit">Send Link</Button>
                    </div>
                </Form>
            </Modal>
        );
    }
}

TroubleLoggingInModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    email: PropTypes.string,
};

TroubleLoggingInModal.defaultProps = {
    email: "",
};

export default TroubleLoggingInModal;
