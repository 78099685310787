// Flex plan will be used in Australia, default to Australian currency and locale

export const toCurrencyWithCommas = (value: number | string, currency = "AUD", locale: string = "en-AU"): string => {
    let number: number;

    if (typeof value === "string") {
        const parsedNumber = parseFloat(value);

        if (parsedNumber) {
            number = parsedNumber;
        } else {
            return value;
        }
    } else {
        number = value;
    }

    const currencyFormatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
    });

    return currencyFormatter.format(number);
};

export const toTwoDecimalPlaces = (value: number | string) => {
    let number: number;

    if (typeof value === "string") {
        number = parseFloat(value);
    } else {
        number = value;
    }

    return number ? number.toFixed(2) : "0.00";
};
