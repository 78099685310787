import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

// React custom scrollbars inserts invalid markup when used with tables
interface Props {
    children: React.ReactNode,
    tBody?: boolean;
    xOverflow?: boolean;
    yOverflow?: boolean;
    className?: string;
    style?: any;
}

const TableScrollbars = ({ children, tBody, xOverflow, yOverflow, className, style = {} }: Props) => (tBody
    ? (
        <tbody
            data-testid="table-scrollbars-tbody"
            style={style}
            className={classnames(styles.tableScrollbars, className && className, xOverflow && styles.tableScrollbarsOverflowX, yOverflow && styles.tableScrollbarsOverflowY)}
        >
            {children}
        </tbody>)
    : (
        <div
            data-testid="table-scrollbars-div"
            style={style}
            className={classnames(styles.tableScrollbars, className && className, xOverflow && styles.tableScrollbarsOverflowX, yOverflow && styles.tableScrollbarsOverflowY)}
        >
            {children}
        </div>));

export { TableScrollbars };
