import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuid } from "uuid";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { EditBespokeQuoteItem } from "@/Components/BespokeQuotes/EditBespokeQuoteItem";
import Spinner from "@/Components/Spinner";
import { DocumentUpload } from "@/Components/DocumentUpload";
import { EditBespokeQuoteFormFieldsModal } from "@/Modals/EditBespokeQuoteFormFieldsModal";
import * as api from "@/Apis/BespokeQuote";
import { getFields } from "@/Apis/Fields";
import { SupplierProvider } from "@/Context/SupplierContext";
import { useMessagingContext } from "@/Context/MessagingContext";
import * as VatHelper from "@/Utils/vatHelper";
import dateHelpers from "@/Utils/formatDateHelper";
import globalActions from "@/Store/Global/actions";

const EditBespokeQuote = ({ quote, conversationId, onSuccess, onRemoveClick }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0);
    const [fields, setFields] = useState([]);
    const messagingContext = useMessagingContext();
    const [isSaving, setIsSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [onSendClickedCallbacks, setOnSendClickedCallbacks] = useState({});
    const [bespokeQuote, setBespokeQuote] = useState({
        total: 0,
        items: [],
        customFields: [],
        termsDocuments: [],
        createdAt: new Date(),
        conversationId,
        ...quote,
    });

    const updateItems = (updatedItems) => {
        setBespokeQuote(prevState => ({
            ...prevState,
            items: updatedItems,
            total: updatedItems.reduce((sum, x) => sum + x.total, 0),
        }));
    };

    const addNewItem = useCallback(() => updateItems([...bespokeQuote.items, {
        id: uuid(),
        name: "",
        total: 0,
        price: 0,
        pricingUnit: "0",
        totalUnits: 1,
        description: "",
        applyVat: false,
    }]), [bespokeQuote.items]);

    const onItemUpdated = (item) => {
        const itemTotal = item.price && item.totalUnits
            ? parseFloat(item.price) * parseFloat(item.totalUnits)
            : 0;

        setHasChanges(true);
        updateItems(bespokeQuote.items.map(x => (x.id === item.id ? { ...item, total: itemTotal } : x)));
    };

    const onRemoveItem = (id) => {
        updateItems(bespokeQuote.items.filter(x => x.id !== id));

        setOnSendClickedCallbacks(prevState => {
            const clone = { ...prevState };
            delete clone[id];
            return clone;
        });
    };

    const registerOnSendClickCallbacks = useCallback((itemId, callback) => {
        setOnSendClickedCallbacks(prevState => ({
            ...prevState,
            [itemId]: callback,
        }));
    }, []);

    const onSendClicked = () => {
        Promise.all(Object.values(onSendClickedCallbacks).reverse().map(x => x()))
            .then(async items => {
                setIsSaving(true);
                const allFormsValid = items.every(x => x.formValid);
                if (allFormsValid) {
                    if (bespokeQuote.status) {
                        const response = await api.updateQuote(bespokeQuote);
                        messagingContext.quoteUpdated({ ...bespokeQuote, ...response, createdAt: dateHelpers.forBespokeQuote(new Date()) });
                        dispatch(globalActions.setToastMessage(true, "Quotation sent successfully!"));
                    } else {
                        const createdAt = new Date();
                        const response = await api.generateQuote({ ...bespokeQuote, createdAt });
                        messagingContext.quoteAdded({ ...bespokeQuote, ...response, createdAt });
                        dispatch(globalActions.setToastMessage(true, "Quotation sent successfully!"));
                    }
                    onSuccess();
                }
                setIsSaving(false);
            });
    };

    const onTermsDocumentAdded = (document) => {
        setBespokeQuote(prevState => ({
            ...prevState,
            termsDocuments: [...prevState.termsDocuments, ...document],
        }));
    };

    const onTermsDocumentDeleted = (document) => {
        setBespokeQuote(prevState => ({
            ...prevState,
            termsDocuments: prevState.termsDocuments.filter(x => x.name !== document.name),
        }));
    };

    const editFormFields = async () => history.push(`${history.location.pathname}/form-fields`);

    const updateCustomFields = (selectedFields) => {
        setBespokeQuote(prevState => ({
            ...prevState,
            customFields: selectedFields,
        }));
        history.push(history.location.pathname.replace("/form-fields", ""));
    };

    useEffect(() => {
        let quoteTotal = 0;
        bespokeQuote.items.forEach(x => {
            quoteTotal += x.total;
            if (x.applyVat) {
                quoteTotal += VatHelper.calculateVat(x.total);
            }
        });

        setTotal(quoteTotal);
    }, [bespokeQuote.items]);

    useEffect(() => {
        if (fields.length === 0) {
            getFields()
                .then(data => {
                    setFields(data.fields);
                });
        }
    }, [fields]);

    useEffect(() => {
        if (bespokeQuote.items.length === 0) {
            addNewItem();
        }
    }, [bespokeQuote, addNewItem]);

    return (
        <div className="rounded-lg border mx-3 mt-2 mb-4" data-testid="edit-bespoke-quote">
            <div className="border-bottom p-3">
                Created on: {dateHelpers.forBespokeQuote(new Date())}
            </div>

            {bespokeQuote.items.map((item) => (
                <EditBespokeQuoteItem
                    item={item}
                    key={item.id}
                    onItemRemoved={onRemoveItem}
                    onItemUpdated={onItemUpdated}
                    onSend={registerOnSendClickCallbacks}
                />
            ))}

            <Button color="primary" className="m-3" onClick={addNewItem} outline><i className="fas fa-plus-circle mr-1" />Add Item</Button>

            <div className="d-flex flex-column align-items-end m-3">
                <span>Total Price</span>
                <h3>£{total.toFixed(2)}</h3>
            </div>

            <DocumentUpload
                onAdd={onTermsDocumentAdded}
                onRemove={onTermsDocumentDeleted}
                documents={bespokeQuote.termsDocuments}
            >
                <p className="text-center mt-4">Drop additional Terms and Conditions documents here to send</p>
            </DocumentUpload>

            {bespokeQuote.isEnquiryOffsitePayment && bespokeQuote.termsDocuments.length === 0
                && (
                    <div className="d-flex justify-center w-100 p-3 rounded font-weight-bold text-danger">
                        <i
                            id="enquiryForm-offsitePaymentTermsError"
                            className="font-weight-bold text-danger fas fa-info-circle mx-2"
                            data-testid="enquiryForm-offsitePaymentTermsError"
                        />
                        Terms and Conditions document must be uploaded for services set up with Off-site payment option.
                    </div>
                )}

            <div className="m-4">
                <Button
                    color="primary"
                    className="mr-2"
                    onClick={onSendClicked}
                    disabled={bespokeQuote.total === 0 || isSaving || !hasChanges || (bespokeQuote.isEnquiryOffsitePayment && bespokeQuote.termsDocuments.length === 0)}
                >
                    Send {isSaving && <Spinner size={20} />}
                </Button>
                <Button color="danger" className="mr-2" outline onClick={onRemoveClick}>
                    Remove
                </Button>
                <Button color="primary" className="mr-2" outline onClick={editFormFields}>
                    Edit Form Fields
                </Button>
                <Button color="secondary" className="mr-2" outline onClick={onSuccess}>
                    Cancel
                </Button>
            </div>

            <Route
                render={({ match: { url } }) => (
                    <Switch>
                        <Route
                            path={`${url}/form-fields`}
                            render={() => (
                                <SupplierProvider value={{ onCustomFieldAdd: () => setFields([]) }}>
                                    <EditBespokeQuoteFormFieldsModal
                                        fields={fields}
                                        selectedFields={bespokeQuote.customFields}
                                        onComplete={updateCustomFields}
                                    />
                                </SupplierProvider>
                            )}
                        />
                    </Switch>
                )}
            />
        </div>
    );
};

EditBespokeQuote.propTypes = {
    conversationId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    quote: PropTypes.shape({
        total: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        quoteId: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            price: PropTypes.number.isRequired,
            pricingUnit: PropTypes.string.isRequired,
            totalUnits: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
            applyVat: PropTypes.bool.isRequired,
        }).isRequired),
        termsDocuments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
        }).isRequired),
        isEnquiryOffsitePayment: PropTypes.bool.isRequired,
        customFields: PropTypes.arrayOf(PropTypes.shape({
            fieldId: PropTypes.string.isRequired,
            isRequired: PropTypes.bool.isRequired,
        }).isRequired),
    }),
};

export { EditBespokeQuote };
