import classnames from "classnames";
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

import DropZoneButton from "@/Components/Dropzones/DropZoneButton";
import Spinner from "@/Components/Spinner";
import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import FloatLabelDate from "@/Components/FloatLabelDate";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import api from "@/Utils/api";
import actions from "@/Store/Global/actions";
import FormatDateHelper from "@/Utils/formatDateHelper";

const CompanyInfoPage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({});
    const [editFields, setEditFields] = useState({});
    const [addressLookup, setAddressLookup] = useState(null);

    const saveChanges = useCallback(() => {
        api.post("profile/company", editFields)
            .then((response) => {
                setCompanyInfo(response);
                setSections([]);
                setLoading(false);
            })
            .catch((error) => {
                dispatch(actions.setErrorToastMessage(true, error.message));
            });
    }, [dispatch, editFields]);

    useEffect(() => {
        api.get("profile/company")
            .then((response) => {
                setCompanyInfo(response);
                setEditFields(response);
                setLoading(false);
            })
            .catch((error) => {
                dispatch(actions.setErrorToastMessage(true, error.message));
            });
    }, [dispatch]);

    useEffect(() => {
        if (!addressLookup) {
            return;
        }
        saveChanges();
    }, [addressLookup, saveChanges]);

    const editSection = value => () => {
        if (sections.includes(value)) {
            setSections(prevState => prevState.filter(x => x !== value));
        } else {
            setSections(prevState => [...prevState, value]);
        }
    };

    const onEdit = (name, isAddress) => (e) => {
        if (!e) {
            return;
        }
        const value = e.target ? e.target.value : e;
        setEditFields(prevState => ({ ...prevState, [name]: value }));
        if (isAddress) {
            setAddressLookup(value);
        }
    };

    const renderReadOnlyRow = (label, value) => (
        <>
            <FormGroup className="d-flex w-75 mb-2">
                <Label className="w-50">{label}</Label>
                <Label className="font-weight-bold">{value}</Label>
            </FormGroup>
            <hr />
        </>
    );

    const renderReadOnlyOrTextBox = (section, label, placeholder, fieldName) => {
        const isReadonly = !sections.includes(section);
        return isReadonly
            ? renderReadOnlyRow(label, editFields[fieldName])
            : (<FloatLabelTextbox label={label} placeholder={placeholder} onChange={onEdit(fieldName)} value={editFields[fieldName]} data-testid={`edit-${label}`} />);
    };

    const renderReadOnlyOrDateTextBox = (section, label, placeholder, fieldName) => {
        const isReadonly = !sections.includes(section);
        return isReadonly
            ? renderReadOnlyRow(label, editFields[fieldName] != null ? FormatDateHelper.format(editFields[fieldName], "DD/MM/YYYY") : "")
            : (
                <FloatLabelDate
                    label={label}
                    changeDate={onEdit(fieldName)}
                    currentDate={!editFields[fieldName] ? "" : editFields[fieldName]}
                />
            );
    };

    const renderReadOnlyOrUploads = (section, label, fieldName) => {
        const isReadonly = !sections.includes(section);
        return isReadonly
            ? renderReadOnlyRow(label, (<div className={classnames(styles.image, "mb-3")}>{!editFields[fieldName] ? "" : <img src={editFields[fieldName]} alt="" />}</div>))
            : (<DropZoneButton onChange={onEdit(fieldName)} image={editFields[fieldName]} noImageText={<p>{label}</p>} />);
    };

    const renderEditButton = section => (
        <Row>
            <Col xs="12" className="d-flex justify-content-between mb-3">
                <h4 className="font-weight-bold">{section}</h4>
                {!sections.includes(section) && <Button onClick={editSection(section)} color="primary" className="px-3" data-testid={`edit-${section}`}>Edit</Button>}
            </Col>
        </Row>);

    const renderSaveButton = section => (
        sections.includes(section)
            ? (
                <div className="d-flex justify-content-end mt-3">
                    <Button onClick={editSection(section)} color="secondary" className="mr-1">Cancel</Button>
                    <Button onClick={saveChanges} color="primary" data-testid={`save-${section}`}>Save</Button>
                </div>
            )
            : (<></>)
    );

    const renderInfoSection = () => (
        <>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                {renderEditButton("Company Information")}
                {renderReadOnlyOrTextBox("Company Information", "Company Name", "Your Company Name", "companyName")}
                {renderReadOnlyOrTextBox("Company Information", "Trading Name", "Your Company Trading Name", "tradingName")}
                {renderReadOnlyOrTextBox("Company Information", "Registration Number", "Your Company Registration Number", "registrationNumber")}
                {renderReadOnlyOrDateTextBox("Company Information", "Incorporation Date", "Your Company Incorporation Date", "incorporationDate")}
                {renderReadOnlyOrDateTextBox("Company Information", "Company Formation Date", "Date of Company Formation", "formationDate")}
                {renderReadOnlyOrDateTextBox("Company Information", "Company Started Trading Date", "Date Company Started Trading", "startedTradingDate")}
                {renderReadOnlyOrTextBox("Company Information", "Country Of Registration", "Country where Company is Registered", "countryOfRegistration")}
                {renderReadOnlyOrTextBox("Company Information", "Company Submission Number", "Your Company Submission Number", "submissionNumber")}
                {renderSaveButton("Company Information")}
            </div>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                <Row>
                    <Col xs="12" className="d-flex justify-content-between mb-3">
                        <h4 className="font-weight-bold">Company Addresses</h4>
                    </Col>
                </Row>
                <FloatLabelAddressLookUp
                    heading="Registered Office Address"
                    address={!companyInfo.registeredOfficeAddress ? { premise: "", addressLine1: "", city: "", postCode: "" } : companyInfo.registeredOfficeAddress}
                    onSave={onEdit("registeredOfficeAddress", true)}
                />
                <FloatLabelAddressLookUp
                    heading="Correspondence Address"
                    address={!companyInfo.correspondenceAddress ? { premise: "", addressLine1: "", city: "", postCode: "" } : companyInfo.correspondenceAddress}
                    onSave={onEdit("correspondenceAddress", true)}
                />
                <FloatLabelAddressLookUp
                    heading="Trading Address"
                    address={!companyInfo.tradingAddress ? { premise: "", addressLine1: "", city: "", postCode: "" } : companyInfo.tradingAddress}
                    onSave={onEdit("tradingAddress", true)}
                />
            </div>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                {renderEditButton("Contact Information")}
                {renderReadOnlyOrTextBox("Contact Information", "Company Website", "Your Company Website", "domain")}
                {renderReadOnlyOrTextBox("Contact Information", "Company Email", "Your Company Email", "contactEmail")}
                {renderReadOnlyOrTextBox("Contact Information", "Company Telephone", "Your Company Telephone", "telephone")}
                {renderReadOnlyOrUploads("Contact Information", "Your Company Logo", "companyLogo")}
                {renderSaveButton("Contact Information")}

            </div>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                {renderEditButton("Company Tax")}
                {renderReadOnlyOrTextBox("Company Tax", "VAT Number", "Your VAT Number", "vatNumber")}
                {renderReadOnlyOrTextBox("Company Tax", "VAT Quarters", "Your VAT Quarters", "vatQuarters")}
                {renderReadOnlyOrDateTextBox("Company Tax", "VAT Applied Date", "Date VAT Applied From", "vatAppliedDate")}
                {renderReadOnlyOrTextBox("Company Tax", "VAT Acknowledgement Reference", "Your VAT Acknowledgement Reference", "vatAcknowledgementReference")}
                {renderReadOnlyOrTextBox("Company Tax", "Corporation Tax Reference", "Your Corporation Tax Reference", "corporationTaxReference")}
                {renderSaveButton("Company Tax")}
            </div>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                {renderEditButton("PAYE")}
                {renderReadOnlyOrTextBox("PAYE", "PAYE Reference Number", "Your PAYE Reference Number", "payeReferenceNumber")}
                {renderReadOnlyOrTextBox("PAYE", "Accounts Office Reference Number", "Accounts Office Reference Number", "accountsOfficeReferenceNumber")}
                {renderReadOnlyOrDateTextBox("PAYE", "PAYE Applied Date", "PAYE Applied Date", "payeAppliedDate")}
                {renderReadOnlyOrTextBox("PAYE", "PAYE Knowledgement Reference", "PAYE Knowledgement Reference", "payeKnowledgementReference")}
                {renderSaveButton("PAYE")}
            </div>
            <div className="border rounded-lg p-4 m-lg-3 mt-4 mb-4">
                {renderEditButton("Other Information")}
                {renderReadOnlyOrTextBox("Other Information", "Company Authorisation Code", "Your Company Authorisation Code", "authorisationCode")}
                {renderReadOnlyOrTextBox("Other Information", "Sector", "Sector", "sector")}
                {renderReadOnlyOrTextBox("Other Information", "SIC Code", "SIC Code", "sicCode")}
                {renderReadOnlyOrTextBox("Other Information", "SIC Sub Code", "SIC Sub Code", "sicCodeSubCode")}
                {renderSaveButton("Other Information")}
            </div>
        </>
    );

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }
    return renderInfoSection();
};

export { CompanyInfoPage };
