import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useParams } from "react-router-dom";

import { OrderHeaderSection } from "./Components/OrderHeaderSection";
import { ClaimNotesTab } from "./Tabs/ClaimNotesTab";
import { UploadDocumentsTab } from "./Tabs/UploadDocumentsTab";
import { LineItemsDataTab } from "./Tabs/LineItemsDataTab";

import { BackButton } from "@/FlexPlan/Components/BackButton";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";

const OrderDetails = () => {
    const [activeTab, setActiveTab] = useState(1);
    const {
        disableButtons,
        setContextOrder,
    } = useFlexPlanOrderContext();
    const { id } = useParams();

    useEffect(() => {
        setContextOrder(id);
    }, [id]);

    return (
        <div className="tw-p-4 xl:tw-p-5 2xl:tw-p-8">
            <BackButton displayConfirmationModal breadcrumbsBackButton className="tw-w-32" />
            <hr className="tw-mr-32 3xl:tw-w-3/4" />
            <div className="tw-mt-10">
                <OrderHeaderSection />
                <div className="tw-mt-7">
                    <div className="tw-border-b tw-border-gray-200 tw-mb-4 tw-mr-32 3xl:tw-w-3/4">
                        <ul className="tw-flex tw-flex-wrap tw--mb-px" role="tablist">
                            <li className="tw-mr-2 tw-underline" role="presentation">
                                <button
                                    disabled={disableButtons}
                                    onClick={() => setActiveTab(1)}
                                    className={classnames("tw-inline-block tw-py-4 tw-px-4 tw-text-md tw-text-center",
                                        activeTab === 1 && "tw-font-bold tw-border-b-4 tw-border-brand-primary tw-text-brand-primary")}
                                    type="button"
                                >Line Item Data
                                </button>
                            </li>
                            <li className="tw-mr-2" role="presentation">
                                <button
                                    disabled={disableButtons}
                                    onClick={() => setActiveTab(2)}
                                    className={classnames("tw-inline-block tw-py-4 tw-px-4 tw-text-md tw-text-center",
                                        activeTab === 2 && "tw-font-bold tw-border-b-4 tw-border-brand-primary tw-text-brand-primary")}
                                    type="button"
                                >
                                    Attachments
                                </button>
                            </li>
                            <li className="tw-mr-2" role="presentation">
                                <button
                                    disabled={disableButtons}
                                    onClick={() => setActiveTab(3)}
                                    className={classnames("tw-inline-block tw-py-4 tw-px-4 tw-text-md tw-text-center",
                                        activeTab === 3 && "tw-font-bold tw-border-b-4 tw-border-brand-primary tw-text-brand-primary")}
                                    type="button"
                                >
                                    Claim Notes
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div
                            className={classnames("rounded-lg", activeTab === 1 ? "tw-decoration-brand-primary" : "tw-hidden")}
                            id="line-items-tab"
                            role="tabpanel"
                        >
                            <LineItemsDataTab />
                        </div>
                        <div
                            className={classnames(activeTab !== 2 && "tw-hidden")}
                            id="attachments-tab"
                            role="tabpanel"
                        >
                            <UploadDocumentsTab />
                        </div>
                        <div
                            className={classnames("tw-mt-2", activeTab !== 3 && "tw-hidden")}
                            id="notes-tab"
                        >
                            <ClaimNotesTab />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { OrderDetails };
