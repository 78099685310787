/* eslint-disable import/extensions */
import React, { useState } from "react";
import classnames from "classnames";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, FormGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import styles from "./styles.module.scss";

import PaypalDisabled from "@/Assets/Images/paypal-disabled.png";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { useBasketContext } from "@/Context/BasketContext";
import useCheckout from "@/Hooks/useCheckout";
import Spinner from "@/Components/Spinner";
import { TermsAndConditions } from "@/Components/TermsAndConditions";
import { PayPalCheckoutButton } from "@/Components/PayPalCheckoutButton";
import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import Checkbox from "@/Components/Checkbox";
import PaymentLogos from "@/Components/Stripe/PaymentLogos";
import { DefaultCard } from "@/Components/DefaultCard";

interface Props {
    onPaymentSuccess: () => void;
    isAmendPayment: boolean,
    amendPaymentOrderId: string | undefined,
}

const Payment = (props: Props) => {
    const { onPaymentSuccess, isAmendPayment, amendPaymentOrderId } = props;
    const stripe = useStripe();
    const elements = useElements();
    const checkout = useCheckout({ stripe, elements, onPaymentSuccess, amendPaymentOrderId });
    const [postcode, setPostcode] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [defaultCard, setDefaultCard] = useState(false);
    const [offsitePaymentNoticeAccepted, setOffsitePaymentNoticeAccepted] = useState(false);
    const [cardValidation, setCardValidation] = useState({
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
        postcode: false,
    });
    const { basket } = useBasketContext();
    const user = useSelector((state: any) => state.user);
    const { paypalApiKey, policyDocumentation } = useConfigurationContext();
    const [cardNumberFocus, setCardNumberFocus] = useState(false);
    const [cardCvvFocus, setCardCvvFocus] = useState(false);
    const [cardExpiryFocus, setCardExpiryFocus] = useState(false);
    const dispatch = useDispatch();

    const postcodeUpdated = ({ target }) => {
        setPostcode(target.value);
        setCardValidation(prevState => ({
            ...prevState,
            postcode: target.value.length > 3,
        }));
    };

    // const sendGtmEventOnPayment = () => {
    //     sendGtmEvent(
    //         {
    //             event: "purchase",
    //             ecommerce: {
    //                 transaction_id: `${orderIds}`,
    //                 value: basket.dueNowTotal,
    //                 currency: "GBP",
    //                 items: [
    //                     basket.groups.flatMap(x => x.items.map(i => ({
    //                         item_id: `${i.productId}`,
    //                         item_name: `${i.serviceName}`,
    //                         currency: "GBP",
    //                         price: i.price,
    //                         quantity: i.quantity,
    //                     })))],
    //             },
    //         },
    //     );
    // };

    const stripeElementChanged = (element, name) => {
        setCardValidation(prevState => ({
            ...prevState,
            [name]: element.complete,
        }));
    };

    const goBackToCustomerOrder = () => {
        if (!checkout.loading) {
            dispatch(push("/customer-orders"));
        }
    };

    const isAboveStripeMinimum = parseFloat(`${basket.totalAmount}`) >= parseFloat("0.30");
    const onTermsAccepted = () => setTermsAccepted(!termsAccepted);
    const onOffsitePaymentNoticeAccepted = (checked) => setOffsitePaymentNoticeAccepted(checked);
    const handleCardNumberFocus = () => setCardNumberFocus(true);
    const handleCardNumberBlur = () => setCardNumberFocus(false);
    const handleCardCvvFocus = () => setCardCvvFocus(true);
    const handleCardCvvBlur = () => setCardCvvFocus(false);
    const handleCardExpiryFocus = () => setCardExpiryFocus(true);
    const handleCardExpiryBlur = () => setCardExpiryFocus(false);
    const onDefaultCardChecked = () => setDefaultCard(!defaultCard);

    if (basket.dueNowTotal === 0 && !isAmendPayment && !checkout.hasDelayedPayment) {
        return (
            <>
                {checkout.loading && (
                    <div
                        className={classnames("mt-5 d-flex flex-grow-1 justify-content-center position-fixed bg-white", styles.loadingContainer)}
                    >
                        <Spinner className="align-self-center" text="Processing, please wait..." />
                    </div>

                )}
                <div className="mx-auto d-flex flex-column">
                    <h4>No Payment Due</h4>
                    <p className="mt-4">
                        Your basket does not require payment information.
                        To confirm your order with your supplier please click “Complete Order” below.
                        By doing so you will be placing an order and bound by the relevant order terms and conditions.
                        Thanks for choosing to use Business Support Club to complete your transaction and have a
                        wonderful
                        day.
                    </p>
                    <p>
                        We accept payments through
                    </p>
                    <PaymentLogos isAmendPayment={isAmendPayment} isAboveStripeMinimum={isAboveStripeMinimum} />
                    <div className={classnames(styles.termsAndConditions, "align-self-center mb-3")}>
                        <TermsAndConditions
                            showPrivacy
                            checked={termsAccepted}
                            onToggle={onTermsAccepted}
                            thirdPartyLinks={policyDocumentation.thirdPartyHosted}
                            documentLocation={policyDocumentation.termsAndConditions}
                            privacyDocumentLocation={policyDocumentation.privacyPolicy}
                        />

                        {checkout.hasOffsitePayment && (
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="p-3 mt-4 border rounded w-100">
                                    <Checkbox
                                        testId="acceptTermsCheckbox"
                                        id="terms"
                                        checked={offsitePaymentNoticeAccepted}
                                        onChange={onOffsitePaymentNoticeAccepted}
                                        className="mr-0 d-inline-block"
                                    />
                                    <strong>
                                        In this basket you have some items where payment will be taken off site by the
                                        supplier.
                                        They have set out their payment terms to you in your contract and this should be
                                        read and understood before continuing.
                                    </strong>
                                </div>
                            </div>
                        )}
                    </div>
                    <Button
                        color="primary"
                        disabled={checkout.loading || !termsAccepted || (checkout.hasOffsitePayment && !offsitePaymentNoticeAccepted)}
                        onClick={checkout.submitFreeOrder}
                        className={classnames(styles.paymentButton, "align-self-center mb-3")}
                    >
                        Complete Order
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div className="mx-auto">
            {checkout.loading && (
                <div
                    className={classnames("mt-5 d-flex flex-grow-1 justify-content-center position-fixed bg-white", styles.loadingContainer)}
                >
                    <Spinner className="align-self-center" text="Processing, please wait..." />
                </div>)}
            <PaymentLogos
                isAmendPayment={isAmendPayment}
                isAboveStripeMinimum={isAboveStripeMinimum || checkout.hasDelayedPayment}
            />
            {(isAmendPayment || isAboveStripeMinimum || checkout.hasDelayedPayment) && (
                <h4 className="text-primary text-lg-center mt-2 mb-4 font-weight-bold d-none d-sm-block">
                    Please enter your card details or pay with PayPal
                </h4>)}
            {(!isAmendPayment && !isAboveStripeMinimum) && (
                <h4 className="text-primary text-lg-center mt-2 mb-4 font-weight-bold">Pay with PayPal</h4>)}
            {(isAmendPayment || isAboveStripeMinimum || checkout.hasDelayedPayment) && (
                <>
                    <FormGroup className="d-flex justify-content-center">
                        <div className={classnames(styles.floatLabel, cardNumberFocus ? styles.isFocus : "")}>
                            <Label className={cardNumberFocus ? styles.isFocus : ""}>Card Number</Label>
                            <CardNumberElement
                                options={{
                                    showIcon: true,
                                    style: {
                                        base: {
                                            "::placeholder": {
                                                color: "#d8d8d8",
                                            },
                                        },
                                    },
                                }}
                                className={classnames(styles.floatElement, "border rounded px-3 py-2 mb-3", cardNumberFocus ? styles.isFocus : "")}
                                onChange={(element) => stripeElementChanged(element, "cardNumber")}
                                onFocus={handleCardNumberFocus}
                                onBlur={handleCardNumberBlur}
                                id="payment-card-number"
                            />
                        </div>
                    </FormGroup>
                    <div className="flex-row d-flex justify-content-center">
                        <FormGroup className={classnames(styles.floatLabelSmall, "pr-3")}>
                            <div className={classnames(cardExpiryFocus ? styles.isFocus : "")}>
                                <Label className={cardExpiryFocus ? styles.isFocus : ""}>Expiry Date</Label>
                                <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                "::placeholder": {
                                                    color: "#d8d8d8",
                                                },
                                            },
                                        },
                                    }}
                                    className={classnames(styles.floatElement, "border rounded px-3 py-2 mb-2", cardExpiryFocus ? styles.isFocus : "")}
                                    onChange={(element) => stripeElementChanged(element, "cardExpiry")}
                                    onFocus={handleCardExpiryFocus}
                                    onBlur={handleCardExpiryBlur}
                                    id="payment-card-expiry"
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className={classnames(styles.floatLabelSmall, "pl-3")}>
                            <div className={classnames(cardCvvFocus ? styles.isFocus : "")}>
                                <Label className={cardCvvFocus ? styles.isFocus : ""}>CVC</Label>
                                <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                "::placeholder": {
                                                    color: "#d8d8d8",
                                                },
                                            },
                                        },
                                    }}
                                    className={classnames(styles.floatElement, "border rounded px-3 py-2 mb-2", cardCvvFocus ? styles.isFocus : "")}
                                    onChange={(element) => stripeElementChanged(element, "cardCvc")}
                                    onFocus={handleCardCvvFocus}
                                    onBlur={handleCardCvvBlur}
                                    id="payment-card-cvc"
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className={classnames(styles.postcodeWrapper, "d-flex justify-content-center")}>
                        <FloatLabelTextbox
                            className="w-100 mw-100"
                            label="Billing Address Post Code"
                            placeholder="Post Code"
                            onChange={postcodeUpdated}
                            value={postcode}
                            data-testid="payment-postcode"
                        />
                    </div>
                </>)}
            <div className="d-flex flex-column align-items-center">
                <div className={classnames(styles.termsAndConditions, "mb-3")}>
                    {isAmendPayment && (
                        <DefaultCard
                            checked={defaultCard}
                            onToggle={onDefaultCardChecked}
                        />
                    )}
                    <TermsAndConditions
                        showPrivacy
                        checked={termsAccepted}
                        onToggle={onTermsAccepted}
                        thirdPartyLinks={policyDocumentation.thirdPartyHosted}
                        documentLocation={policyDocumentation.termsAndConditions}
                        privacyDocumentLocation={policyDocumentation.privacyPolicy}
                    />
                    {checkout.hasOffsitePayment && (
                        <div className="d-flex align-items-center justify-content-center" data-testid="offsite-payment-terms-and-conditions">
                            <div className="p-3 mt-4 border rounded w-100">
                                <Checkbox
                                    testId="acceptTermsCheckbox"
                                    id="terms"
                                    checked={offsitePaymentNoticeAccepted}
                                    onChange={onOffsitePaymentNoticeAccepted}
                                    className="mr-0 d-inline-block"
                                />
                                <strong>
                                    In this basket you have some items where payment will be taken off site by the
                                    supplier.
                                    They have set out their payment terms to you in your contract and this should be
                                    read and understood before continuing.
                                </strong>
                            </div>
                        </div>
                    )}
                </div>

                {checkout.paymentError
                    && <span className="text-danger font-weight-bold mb-3">{checkout.paymentError}</span>}

                {(!isAmendPayment && (isAboveStripeMinimum || checkout.hasDelayedPayment)) && (
                    <Button
                        id="payment-button"
                        data-testid="payment-button"
                        color="primary"
                        onClick={checkout.onSubmit(postcode)}
                        disabled={checkout.loading
                            || !termsAccepted
                            || (checkout.hasOffsitePayment && !offsitePaymentNoticeAccepted)
                            || !cardValidation.postcode
                            || !cardValidation.cardCvc
                            || !cardValidation.cardExpiry
                            || !cardValidation.cardNumber}
                        className={classnames(styles.paymentButton, "mb-3")}
                    >
                        {checkout.loading ? <Spinner size="24" /> : "Pay now with Stripe"}
                    </Button>)}

                {!checkout.hasSubscription && termsAccepted && (!checkout.hasOffsitePayment || offsitePaymentNoticeAccepted) && !isAmendPayment && (
                    <PayPalCheckoutButton user={user} apiKey={paypalApiKey} onPaymentSuccess={onPaymentSuccess} />
                )}

                {!checkout.hasSubscription && !termsAccepted && (!checkout.hasOffsitePayment || offsitePaymentNoticeAccepted) && !isAmendPayment && (
                    <img src={PaypalDisabled} alt="paypal disabled button" className={styles.paypalDisabled} />
                )}

                {isAmendPayment && (
                    <div className="mb-4">
                        <Button
                            onClick={goBackToCustomerOrder}
                            id="cancel-button"
                            className={classnames(styles.cancelButton, "mb-2 mr-2")}
                        >
                            Cancel
                        </Button>

                        <Button
                            id="payment-button"
                            color="primary"
                            onClick={checkout.amendPaymentDetail(postcode, defaultCard)}
                            disabled={checkout.loading
                                || !termsAccepted
                                || (checkout.hasOffsitePayment && !offsitePaymentNoticeAccepted)
                                || !cardValidation.postcode
                                || !cardValidation.cardCvc
                                || !cardValidation.cardExpiry
                                || !cardValidation.cardNumber}
                            className={classnames(styles.amendPaymentButton, "mb-2")}
                        >
                            Amend Payment Details
                        </Button>
                        {checkout.errorMessage && <p className="text-danger mt-2">{checkout.errorMessage}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export { Payment };
