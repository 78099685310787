import React, { useState } from "react";
import classnames from "classnames";
import { Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import { PricingPopover } from "@/Components/PricingPopover";
import CloseButton from "@/Components/CloseButton";
import { ProductQuantity } from "@/Components/ProductQuantity";
import { ConfirmDeleteBasketItemModal } from "@/Modals/ConfirmDeleteBasketItemModal";
import { BasketItemType } from "@/Apis/Basket";

interface UpdateQuantity {
    productId: string;
    quantity: number;
}

interface Props {
    item: any,
    onClose: () => void,
    onRemove: (orderId: string) => void,
    onUpdateQuantity: (command: UpdateQuantity) => void,
    basketCount: number,
    viewCart: () => void,
}

const AddToCartToastMessage = ({ item, onClose, onRemove, basketCount = 1, viewCart, onUpdateQuantity }: Props): JSX.Element => {
    const [displayConfirmDeleteModal, setDisplayConfirmDeleteModal] = useState(false);
    const [cartItem, setCartItem] = useState(item);

    const removeItem = () => {
        onRemove(cartItem.orderId);
        setDisplayConfirmDeleteModal(false);
    };

    const showDeleteModal = () => () => {
        setDisplayConfirmDeleteModal(true);
    };

    const onViewCart = () => {
        onClose();
        viewCart();
    };

    const updateQuantity = (itemToUpdate: BasketItemType, newQuantity: number) => {
        setCartItem(prevState => {
            const newState = { ...prevState };
            newState.product.quantity = newQuantity;
            return newState;
        });

        onUpdateQuantity({ productId: itemToUpdate.productId, quantity: newQuantity });
    };

    return (
        <div data-testid="add-to-cart-toast" className={classnames(styles.container, "w-100 d-flex justify-content-center")}>
            <Alert className={classnames(styles.toast, "position-absolute d-flex align-items-center rounded box-shadow bg-white")} isOpen>
                <div className={styles.addToCart}>
                    <CloseButton onClick={onClose} className="text-white" />
                    <div className="d-flex align-items-center p-3 bg-primary rounded-top text-white">
                        <h6 className="mb-0"><i className={classnames(styles.checkButton, "fas fa-check-circle mr-1 h4")} /> Successfully added to your cart!</h6>
                    </div>
                    <div className={classnames(styles.basketItems, "p-3")}>
                        <div className="border rounded d-flex justify-content-between p-2">
                            <div>
                                <div className="h6 font-weight-bold">{cartItem.product.serviceName}</div>
                                <div className="font-size-sm text-muted">
                                    <Link
                                        to={`/marketplace/supplier-directory/${cartItem.product.supplierId}`}
                                        onClick={onClose}
                                    >
                                        {cartItem.product.supplierName}
                                    </Link> • {cartItem.product.category}
                                </div>
                                <Link
                                    to={`/product/${cartItem.product.slugId}/${cartItem.product.slug}`}
                                    className="p-0 text-uppercase text-underline font-weight-bold font-size-sm"
                                    onClick={onClose}
                                    target="_blank"
                                >View Details
                                </Link>
                                <Button
                                    color="link"
                                    className="p-0 text-uppercase text-underline font-weight-bold font-size-sm ml-3"
                                    onClick={showDeleteModal()}
                                    data-testid="remove-service-button"
                                >Remove
                                </Button>
                            </div>
                            <div>
                                <PricingPopover
                                    productId={cartItem.product.serviceId}
                                    initialChargeWithVatIfApplicable={cartItem.product.price + cartItem.product.totalVat}
                                    initialCharge={cartItem.product.price}
                                    paymentFrequency={cartItem.product.paymentFrequency}
                                    postageCharge={cartItem.product.postage}
                                    minimumQuantity={cartItem.product.quantity}
                                    isVatRequired={cartItem.product.totalVat !== 0}
                                    placement="bottom-end"
                                    className={styles.pricingPopper}
                                    componentName="toast"
                                />
                                <ProductQuantity
                                    classNames="mt-3"
                                    value={cartItem.product.quantity}
                                    onValueChanged={(val) => updateQuantity(cartItem.product, val)}
                                    minimumValue={cartItem.product.minimumQuantityValue || 1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pl-3 pr-3 pb-3">
                        <div className="d-flex">
                            <Button
                                color="outline-primary"
                                data-testid="view-cart-button"
                                block
                                className={classnames(styles.viewCartButton, "font-weight-bold h5 m-auto p-2 w-50")}
                                onClick={onViewCart}
                            >
                                View Cart ({basketCount})
                            </Button>
                            <Link to="/basket" className="w-50 ml-3" onClick={onClose}>
                                <Button
                                    color="primary"
                                    data-testid="checkout-button"
                                    block
                                    className={classnames(styles.checkoutButton, "font-weight-bold h5 m-auto p-2 w-100")}
                                >
                                    Checkout
                                </Button>
                            </Link>
                        </div>
                    </div>
                    {displayConfirmDeleteModal && (
                        <ConfirmDeleteBasketItemModal
                            itemName={cartItem.serviceName ?? ""}
                            onConfirm={removeItem}
                            onClose={() => setDisplayConfirmDeleteModal(false)}
                        />
                    )}
                </div>
            </Alert>
        </div>);
};

export { AddToCartToastMessage };
