/* eslint-disable import/extensions */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ImpersonateUser } from "./Widgets/ImpersonateUser";

import Spinner from "@/Components/Spinner";
import { Notifications } from "@/Components/Notifications";
import { Referrals } from "@/Pages/DashboardPage/Widgets/Referrals";
import { BecomeASupplier } from "@/Pages/DashboardPage/Widgets/BecomeASupplier";
import { getDashboardApi } from "@/Apis/urls";
import { useAPI } from "@/Apis/useAPI";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { DashboardBanner } from "@/Pages/DashboardPage/DashboardBanner";
import { DashboardTile } from "@/Pages/DashboardPage/DashboardTile";
import { SponsoredSuppliersCarousel } from "@/Pages/DashboardPage/SponsoredSuppliersCarousel";
import OurMarketplaceImg from "@/Assets/Images/Dashboard/Tiles/Our-Marketplace.png";
import SupplierDirectoryImg from "@/Assets/Images/Dashboard/Tiles/Supplier-Directory.png";
import BuildAPackageImg from "@/Assets/Images/Dashboard/Tiles/Build-A-Package.png";
import MyProfileImg from "@/Assets/Images/Dashboard/Tiles/My-Profile.png";
import LeaveAReviewImg from "@/Assets/Images/Dashboard/Tiles/Leave-A-Review.png";
import MessagingImg from "@/Assets/Images/Dashboard/Tiles/Messaging.png";
import MobileOurMarketplaceImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Our-Marketplace.png";
import MobileSupplierDirectoryImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Supplier-Directory.png";
import MobileBuildAPackageImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Build-A-Package.png";
import MobileMyProfileImg from "@/Assets/Images/Dashboard/Tiles/Mobile/My-Profile.png";
import MobileLeaveAReviewImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Leave-A-Review.png";
import MobileMessagingImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Messaging.png";
import { FailedPaymentsBanner } from "@/Components/FailedPaymentsBanner";

const DashboardPage = () => {
    document.title = "BSC - Dashboard";

    const configuration = useConfigurationContext();
    const isAdmin = useSelector(state => state.user.isAdmin);
    const isSupplier = useSelector(state => state.user.isSupplier);
    const isImpersonating = useSelector(state => state.user.isImpersonating);
    const notifications = useSelector(state => state.notifications);
    const { get } = useAPI({ handle500WithRedirect: true });

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [sponsoredSuppliers, setSponsoredSuppliers] = useState([]);

    useEffect(() => {
        get(getDashboardApi)
            .then((response) => {
                setUsers(response.users);
                setSponsoredSuppliers(response.sponsoredSuppliers);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }

    return (
        <div className="tw-w-full sm:tw-max-w-[786px] lg:tw-max-w-[1240px] 3xl:tw-max-w-[1920px] tw-mx-auto tw-pb-2 sm:tw-px-5 md:tw-pb-6">
            <div className="tw-pb-2">
                <FailedPaymentsBanner />
                <Notifications
                    openOrders={notifications.openOrders}
                    placedOrders={notifications.placedOrders}
                    ordersToReview={notifications.ordersToReview}
                    isSupplier={isSupplier}
                />
            </div>
            <div className="tw-pb-4">
                <DashboardBanner />
            </div>
            <div>
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-5 lg:tw-gap-4">
                    <DashboardTile
                        title="Business Support Club Marketplace"
                        link="/marketplace"
                        contentTitle="OUR MARKETPLACE"
                        contentDescription="Peek our product and services here!"
                        desktopImage={OurMarketplaceImg}
                        mobileImage={MobileOurMarketplaceImg}
                    />
                    <DashboardTile
                        title="Supplier Directory"
                        link="/marketplace/supplier-directory"
                        contentTitle="SUPPLIER DIRECTORY"
                        contentDescription="Take a look at our BSC Suppliers, view their offerings, and compare their ratings"
                        isMobileTextBackgroundRight
                        desktopImage={SupplierDirectoryImg}
                        mobileImage={MobileSupplierDirectoryImg}
                    />
                    <BecomeASupplier
                        isSupplier={isSupplier}
                        messagingRecipientId={configuration.adminConfiguration.messagingRecipientId}
                    />
                    <Referrals />
                    <DashboardTile
                        title="Create a Custom Package"
                        link="/packages"
                        contentTitle="BUILD PACKAGE"
                        contentDescription="Earn 3% commission from anybody who buys it!"
                        desktopImage={BuildAPackageImg}
                        mobileImage={MobileBuildAPackageImg}
                    />
                    <DashboardTile
                        title="My Profile"
                        link="/profile/info"
                        contentTitle="MY PROFILE"
                        contentDescription="View, update, and edit your BSC account and profile"
                        isMobileTextBackgroundRight
                        desktopImage={MyProfileImg}
                        mobileImage={MobileMyProfileImg}
                    />
                    <DashboardTile
                        title="Share Your Experience"
                        link="/rate-suppliers"
                        contentTitle="LEAVE A REVIEW"
                        contentDescription="Let others know your experience with BSC"
                        desktopImage={LeaveAReviewImg}
                        mobileImage={MobileLeaveAReviewImg}
                    />
                    <DashboardTile
                        title="Stay in Touch"
                        link="/messages"
                        contentTitle="MESSAGING"
                        contentDescription="Communicate with your customers anytime!"
                        isMobileTextBackgroundRight
                        desktopImage={MessagingImg}
                        mobileImage={MobileMessagingImg}
                    />
                </div>
            </div>

            <div className="tw-py-4">
                <div className="tw-font-semibold tw-text-brand-primary tw-text-lg tw-p-4 tw-pl-8 tw-bg-gray-100">
                    Our Sponsored Suppliers
                </div>
                <SponsoredSuppliersCarousel
                    className="tw-w-full min-[1024px]:tw-w-[calc(100vw-155px)] min-[1360px]:tw-w-full min-[1920px]:tw-w-[calc(100vw-135px)] min-[2500px]:tw-w-full"
                    itemClassName="tw-pr-2 tw-py-4"
                    sponsoredSuppliers={sponsoredSuppliers}
                />
            </div>

            <div>
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-5 lg:tw-gap-4 tw-pt-3">
                    <div className="tw-px-2 sm:tw-px-0">
                        {(isAdmin || isImpersonating) && (
                            <ImpersonateUser users={users} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DashboardPage };
