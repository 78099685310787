import React, { useState } from "react";
import { Button, Modal, ModalBody, Badge } from "reactstrap";

import { DocumentUpload, Document, AddedDocument } from "@/Components/DocumentUpload";
import CloseButton from "@/Components/CloseButton";
import Spinner from "@/Components/Spinner";
import { DocumentDetails } from "@/Components/DocumentUpload/DocumentDetails";
import { uploadProductVariantDocument } from "@/Apis/Products";

interface Props {
    rowId: string;
    readonly: boolean;
    productId: string;
    documents: Document[];
    onSuccess?: (documents: Document[]) => void;
}

const ConfigurableUpload = ({ rowId, productId, onSuccess, readonly = false, documents = [] }: Props) => {
    const [uploading, setUploading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => setModalOpen(prevState => !prevState);

    const onAddDocument = async (addedDocuments: AddedDocument[]) => {
        setUploading(true);
        const responses = await Promise.all(addedDocuments.map(x => uploadProductVariantDocument({
            rowId,
            productId,
            base64: x.data,
            documentName: x.name,
        })));
        setUploading(false);
        onSuccess!(documents.concat(responses));
    };

    const onRemoveDocument = (document: Document) => {
        onSuccess!(documents.filter(x => x.name !== document.name));
    };

    return (
        <>
            <Button color="link" onClick={toggleModal} className={readonly ? "" : "mt-4"}>
                <span className={documents.length ? "text-primary" : "text-danger"}><i className="fas fa-paperclip" /></span>
                {documents.length > 0 && <Badge color="primary" pill className="mb-1">{documents.length}</Badge>}
            </Button>

            <Modal isOpen={modalOpen} toggle={toggleModal} centered size="md">
                <ModalBody className="d-flex flex-column position-relative">
                    <CloseButton onClick={toggleModal} />
                    <h3>Upload{readonly ? "ed" : ""} files {uploading && <Spinner size="20" />}</h3>
                </ModalBody>

                {!readonly && (
                    <DocumentUpload onAdd={onAddDocument} onRemove={onRemoveDocument} documents={documents}>
                        <p className="text-center my-4">Drag files here to upload</p>
                    </DocumentUpload>
                )}

                {readonly && (
                    <div className="mb-4">
                        {documents.map(doc => <DocumentDetails key={doc.name} name={doc.name} downloadUrl={doc.location} />)}
                    </div>
                )}
            </Modal>
        </>
    );
};

export { ConfigurableUpload };
