import * as constants from "./constants";

const initialState = {
    openOrders: 0,
    placedOrders: 0,
    unreadMessages: 0,
    ordersToReview: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.NOTIFICATIONS_LOADED:
            return {
                ...action.payload,
            };
        case constants.CLEAR_NOTIFICATIONS:
            return initialState;
        case constants.REDUCE_UNREAD_COUNT: {
            return {
                ...state,
                unreadMessages: state.unreadMessages - 1,
            };
        }
        default:
            return state;
    }
};

export { initialState };
