import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useLocation, useParams } from "react-router";

import { BookingSection,
    BookingTypeNdis,
    getItemFieldName,
    getItemRowFieldName,
    ManageServiceBookingForm, PlanManagedBooking,
    PlanManagedBookingRow, StandardBookingRow,
    StatedSupportBookingRow } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Types";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useForm } from "@/Hooks/useForm";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import formatDateHelper from "@/Utils/formatDateHelper";
import { getDefaultPlanManagedBooking,
    getDefaultStandardBooking,
    GetServiceBookingResponse, getDefaultStatedSupportBooking,
    ServiceBookingResponse } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Types/api";
import { isValidDate } from "@/Utils/stringHelper";

const useManageServiceBookings = () => {
    const [formState, setFormState] = useState<ManageServiceBookingForm>({
        standard: [getDefaultStandardBooking()],
        planManaged: [getDefaultPlanManagedBooking()],
        statedSupport: [getDefaultStatedSupportBooking()],
    });
    const [participantName, setParticipantName] = useState<string>();
    const [planStartDate, setPlanStartDate] = useState<string>();
    const [planEndDate, setPlanEndDate] = useState<string>();

    const location = useLocation();
    const [isCreate, setIsCreate] = useState<boolean>(location?.state?.isCreate);

    const { planId } = useParams();
    const {
        get: getServiceBookingUpdate,
        post,
        put,
        loading: processingSubmission,
    } = useAPI({ handle500WithToastMessage: true });
    const { get, loading: loadingBookings } = useAPI({ handle500WithToastMessage: true });
    const {
        post: postMaxAmount,
        loading: processingMaxAmount,
    } = useAPI({ handle500WithToastMessage: true });

    const {
        setInvalidField,
        invalidFields,
        removeInvalidField: removeErrorField,
    } = useForm<any>();
    const {
        setSuccessMessage,
        setPopupErrorMessage,
    } = useToastMessageContext();

    const [serviceBookingsUpdated, setServiceBookingsUpdated] = useState<boolean>(false);
    const [serviceBookingsUpdatedToggle, setServiceBookingsUpdatedToggle] = useState<boolean>(false);
    const getErrorMessage = (fieldName: string) => (invalidFields ? invalidFields[fieldName] : "");

    const getErrorField = (section: BookingSection, bookingId: string, field, rowId: string = "") => `${section}-${bookingId}-${field}-${rowId}`;

    const setError = (section: BookingSection, bookingId: string, field, errorMessage: string, rowId: string = "") => {
        setInvalidField(getErrorField(section, bookingId, field, rowId), errorMessage);
    };

    const removeError = (section: BookingSection, bookingId: string, field, rowId: string = "") => {
        removeErrorField(getErrorField(section, bookingId, field, rowId));
    };

    // Change fields relating to the overall section (not rows of a section)
    const onChangeSection = (bookingId: string, section: BookingSection) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(() => {
            const bookingsList = formState[section];

            // @ts-ignore
            const booking = bookingsList.find(x => x.id === bookingId);

            booking[e.target.name] = e.target.value;

            return {
                ...formState,
                // @ts-ignore
                [section]: [...bookingsList.filter(x => x.id !== bookingId), booking],
            };
        });

        removeError(section, bookingId, e.target.name);
    };

    const onDateTextBoxChange = (bookingId: string, section: BookingSection, field: string) => (date: Date | undefined) => {
        setFormState(formState => {
            const bookingsList = formState[section];
            // @ts-ignore
            const booking = bookingsList.find(x => x.id === bookingId);

            booking[field] = date
                ? formatDateHelper.format(date, "yyyy-MM-DDTHH:mm:ss")
                : undefined;

            removeErrorField(bookingId);

            return {
                ...formState,
                // @ts-ignore
                [section]: [...bookingsList.filter(x => x.id !== bookingId), booking],
            };
        });

        removeError(section, bookingId, field);
        removeError(section, bookingId, "startDatePrecedesEndDate");
    };

    // Changing a row within a section
    const onChangeRow = (bookingId: string, section: BookingSection, rowId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => {
            const bookingsList = formState[section];

            // @ts-ignore
            const booking = bookingsList.find(x => x.id === bookingId);
            const targetRow = booking.items.find(xx => xx.id === rowId);
            const otherRows = booking.items.filter(x => x.id !== rowId);

            targetRow[e.target.name] = e.target.value;

            return {
                ...formState,
                // @ts-ignore
                [section]: [...bookingsList.filter(x => x.id !== bookingId), {
                    ...booking,
                    items: [...otherRows, targetRow],
                }],
            };
        });

        removeError(section, bookingId, e.target.name, rowId);
        if (section === "planManaged" && [getItemRowFieldName("quantity"), getItemRowFieldName("price")].includes(e.target.name)) {
            removeError(section, bookingId, "overall-plan-managed-error");
        }
    };

    const onAddRow = (bookingId: string, section: BookingSection) => () => {
        setFormState(formState => {
            const bookingsList = formState[section];
            // @ts-ignore
            const booking = bookingsList.find(x => x.id === bookingId);
            const position = booking.items.length + 1;

            booking.items.push({
                id: v4(),
                position,
            } as StandardBookingRow & PlanManagedBookingRow & StatedSupportBookingRow);

            return {
                ...formState,
                // @ts-ignore
                [section]: [...bookingsList.filter(x => x.id !== bookingId), booking],
            };
        });
    };

    const onSelect = (bookingId: string, section: BookingSection, field: string, rowId: string) => (e: any) => {
        if (field === getItemRowFieldName("category")) { // We need to reset the selected item name when the category changes
            onChangeRow(bookingId, section, rowId)({
                target: {
                    name: "itemNumber",
                    value: "",
                },
            } as React.ChangeEvent<HTMLInputElement>);
        }

        onChangeRow(bookingId, section, rowId)({
            target: {
                name: field,
                value: e.value,
            },
        } as React.ChangeEvent<HTMLInputElement>);

        removeError(section, bookingId, field, rowId);
    };

    const hasErrors = (section: BookingSection, bookingId: string): boolean => {
        let errorFound: boolean = false;

        const bookingList = formState[section];

        // @ts-ignore
        const booking = bookingList.find(x => x.id === bookingId);

        // start and end date
        if (!booking.startDate) {
            setError(section, bookingId, getItemFieldName("startDate"), "Start Date is required");
            errorFound = true;
        }

        if (booking.startDate && !isValidDate(booking.startDate)) {
            setError(section, bookingId, getItemFieldName("startDate"), "Invalid date");
            errorFound = true;
        }

        if (!booking.endDate) {
            setError(section, bookingId, getItemFieldName("endDate"), "End Date is required");
            errorFound = true;
        }

        if (booking.endDate && !isValidDate(booking.endDate)) {
            setError(section, bookingId, getItemFieldName("endDate"), "Invalid date");
            errorFound = true;
        }

        if (new Date(booking.startDate) > new Date(booking.endDate)) {
            setError(section, bookingId, "startDatePrecedesEndDate", "Start Date must precede the End Date");
            errorFound = true;
        }

        // If the submission is plan managed, we need at least one row to have price and quantity information
        if (section === "planManaged" && booking.items.every(x => !((x.quantity * x.price) > 0))) {
            setError(section, bookingId, "overall-plan-managed-error", "At least on row must have a total greater than 0");
        }

        // Iterate over the rows
        booking.items.forEach(row => {
            if (!row.category) {
                setError(section, bookingId, getItemRowFieldName("category"), "Category is required", row.id);
                errorFound = true;
            }

            // Stated support is the only booking type to require an Item Name
            // Item Name is actually not required by the NDIS API, however,
            // We would otherwise have no way to distinguish between our two types of plan managed service bookings
            // and stated support service bookings
            if (section === "statedSupport" && !row.itemNumber) {
                setError(section, bookingId, getItemRowFieldName("itemNumber"), "Item Number is required", row.id);
            }

            // We only check the quantity on every row for standard and stated support
            if (!row.quantity && (section === "standard" || section === "statedSupport")) {
                setError(section, bookingId, getItemRowFieldName("quantity"), "Quantity is required", row.id);
                errorFound = true;
            }

            if (!row.price && (section === "standard" || section === "statedSupport")) {
                setError(section, bookingId, getItemRowFieldName("price"), "Price is required", row.id);
                errorFound = true;
            }
        });

        return errorFound;
    };

    const getUpsertBody = (formStateSection, section: BookingSection) => {
        const obj = {
            ...formStateSection,
            planId,
            type: section === "standard"
                ? BookingTypeNdis.StandardBooking
                : BookingTypeNdis.PlanManaged,
        };

        if (section === "planManaged") {
            // We only send the rows back which have a value of greater than 0
            obj.items = formStateSection.items.filter(x => (x.quantity * x.price) > 0);
            // We need to map the category back to the ndis category instead of the display name
            obj.items = obj.items.map(x => ({ ...x, category: x.ndisCategoryName }));
        }

        return obj;
    };

    // This is when we need to get any updates to any of the service bookings
    // for this plan, and save them
    const onGetUpdate = () => {
        getServiceBookingUpdate(FlexPlanUrls.serviceBookings.getUpdate(planId))
            .then(() => {
                setSuccessMessage("Service bookings successfully updated.", true);
                setServiceBookingsUpdated(true);
                setServiceBookingsUpdatedToggle(prev => !prev);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    const onSubmit = (section: BookingSection, bookingId: string) => () => {
        if (hasErrors(section, bookingId)) {
            return;
        }

        const bookingList = formState[section];

        // @ts-ignore
        const booking = bookingList.find(x => x.id === bookingId);

        if (booking.isLoaded) { // Update existing booking
            put(FlexPlanUrls.serviceBookings.base, getUpsertBody(booking, section))
                .then(() => {
                    setSuccessMessage("Service booking updated. Fetching update from NDIS...", true);
                    onGetUpdate();
                })
                .catch(error => {
                    if (typeof error === "string") {
                        setPopupErrorMessage(error, true);
                    } else if (error.message) {
                        setPopupErrorMessage(error.message, true);
                    }
                });
        } else { // Create new booking
            post<{ serviceBookingNumber: number }>(FlexPlanUrls.serviceBookings.base, getUpsertBody(booking, section))
                .then(() => {
                    setSuccessMessage("Service booking created. Fetching update from NDIS...", true);
                    onGetUpdate();
                    setIsCreate(false);
                })
                .catch(error => {
                    if (typeof error === "string") {
                        setPopupErrorMessage(error, true);
                    } else if (error.message) {
                        setPopupErrorMessage(error.message, true);
                    }
                });
        }
    };

    const onMaxAmountClick = (section: BookingSection, bookingId: string, items: any[], itemId?: string) => () => {
        const bookingList = formState[section];
        const booking = (bookingList as any).find(x => x.id === bookingId);
        const newBody = getUpsertBody(booking, section);
        const newItems = itemId ? items.filter(x => x.id === itemId) : items;
        const maxAmountBody = { ...newBody, items: newItems };

        if (section === "planManaged") {
            // We need to convert the display name back to the ndis category name for submission to ndis
            maxAmountBody.items = newItems.map(x => ({ ...x, category: x.ndisCategoryName }));
        }

        if (section === "standard") {
            if ((maxAmountBody.items as StandardBookingRow[]).some(x => !x.category)) {
                setPopupErrorMessage("Category must be selected.", true);
                return;
            }
        }

        if (section === "statedSupport") {
            if ((maxAmountBody.items as StatedSupportBookingRow[]).some(x => !x.category || !x.itemNumber)) {
                setPopupErrorMessage("Category and Item Name must be selected,", true);
                return;
            }
        }

        postMaxAmount<{ success: boolean, errors: string[]}>(FlexPlanUrls.serviceBookings.maxAmountForCategory, maxAmountBody)
            .then((response) => {
                // This is what we were asked to do hit the NDIS API and parse the error :(
                if (response.errors?.[0].search("amount") > -1 && response.errors?.[0].search("exceeds") > -1) {
                    if (!itemId) {
                        setFormState(prevState => ({ ...prevState, coreCategoryMaxAmountMessage: response.errors[0] }));
                        return;
                    }
                    setFormState(prevState => (
                        {
                            ...prevState,
                            // @ts-ignore
                            [section]: [...bookingList.filter(x => x.id !== bookingId), {
                                ...booking,
                                items: items.map(x => ({ ...x, maxAmountMessage: x.id === itemId ? response.errors[0] : x.maxAmountMessage })),
                            }],
                        }
                    ));
                    return;
                }
                const error = response.errors?.length > 0 ? `ERROR: ${response.errors.join(", ")}` : "";
                setPopupErrorMessage(`Couldn't get max amount. ${error}`, true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    // Plan managed rows are based on a default template
    // We iterate over the rows of the default template and fill in the data
    // where we have it
    const seedPlanManagedRows = (serviceBookingResponse: ServiceBookingResponse[]) => serviceBookingResponse.map((serviceBookingResponse, index) => {
        const planManagedBooking: PlanManagedBooking = {
            id: serviceBookingResponse.id,
            serviceBookingNumber: serviceBookingResponse.serviceBookingNumber,
            startDate: serviceBookingResponse.startDate,
            endDate: serviceBookingResponse.endDate,
            isLoaded: true,
            items: [],
            position: index,
            status: serviceBookingResponse.status,
        };

        getDefaultPlanManagedBooking().items.forEach(defaultItem => {
            const matchingDefaultRow = serviceBookingResponse.items.find(x => x.category === defaultItem.ndisCategoryName);

            if (!matchingDefaultRow) { // We don't have a matching row
                planManagedBooking.items.push(defaultItem);
            } else {
                // We start with the default item because it has information like budgetType, position, etc
                const newItem: PlanManagedBookingRow = {
                    ...defaultItem,
                    price: matchingDefaultRow.price,
                    quantity: matchingDefaultRow.quantity,
                    spent: matchingDefaultRow.spent,
                    remaining: matchingDefaultRow.remaining,
                    id: matchingDefaultRow.id,
                };
                planManagedBooking.items.push(newItem);
            }
        });

        return planManagedBooking;
    });

    useEffect(() => {
        if (isCreate) { // We're creating a new service booking
            setFormState({
                standard: [getDefaultStandardBooking()],
                planManaged: [getDefaultPlanManagedBooking()],
                statedSupport: [getDefaultStatedSupportBooking()],
            });
        } else { // We're updating an existing plan
            get<GetServiceBookingResponse>(FlexPlanUrls.serviceBookings.get(planId))
                .then(response => {
                    setParticipantName(`${response.participantFirstName} ${response.participantSurname}`);
                    setPlanStartDate(response.planStartDate);
                    setPlanEndDate(response.planEndDate);

                    // logic for the converting the response to our form state
                    const standardBookings = response.bookings.filter(x => x.type === BookingTypeNdis.StandardBooking);

                    const planManagedBookings = response.bookings
                        .filter(x => x.type === BookingTypeNdis.PlanManaged && x.items.every(xx => !xx.itemNumber));

                    const statedSupportBookings = response.bookings
                        .filter(x => x.type === BookingTypeNdis.PlanManaged && x.items.every(xx => xx.itemNumber));

                    const planManagedBookingsSeeded = seedPlanManagedRows(planManagedBookings);

                    // Default form state is set when we initialise the state, we only update the booking state when we
                    // get data back
                    setFormState({
                        standard: [...standardBookings.map((standardBooking, index) => ({
                            ...standardBooking,
                            isLoaded: true,
                            items: standardBooking.items.map((standardBookingItem, index) => ({
                                ...standardBookingItem,
                                position: index + 1,
                                id: v4(),
                            })),
                            position: index, // Keep a consistent position
                        }))],
                        planManaged: planManagedBookingsSeeded,
                        statedSupport: [...statedSupportBookings.map((statedSupport, index) => ({
                            ...statedSupport,
                            isLoaded: true,
                            items: statedSupport.items.map((item, index) => ({
                                ...item,
                                position: index + 1,
                            })),
                            position: index,
                        }))],
                    });
                })
                .catch(error => {
                    if (typeof error === "string") {
                        setPopupErrorMessage(error, true);
                    } else if (error.message) {
                        setPopupErrorMessage(error.message);
                    }
                });
        }
    }, [serviceBookingsUpdatedToggle, isCreate]); // We reload the data whenever we call get update

    const onCreateNewServiceBooking = () => {
        setIsCreate(true);
    };

    return {
        onChangeSection,
        onChangeRow,
        onDateTextBoxChange,
        onAddRow,
        formState,
        planId,
        onSelect,
        onSubmit,
        processingSubmission,
        invalidFields,
        participantName,
        getErrorField,
        getErrorMessage,
        loadingBookings,
        planStartDate,
        planEndDate,
        onGetUpdate,
        serviceBookingsUpdated,
        onMaxAmountClick,
        processingMaxAmount,
        isCreate,
        onCreateNewServiceBooking,
    };
};

export { useManageServiceBookings };
