/* eslint-disable max-len,import/extensions */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

import styles from "./styles.module.scss";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import { getContactFieldName, ProfileTabState, PlanManagerContact, ProfileSections } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { exceedsMaxStringLength, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { validateEmail } from "@/Utils/validator";
import { isValidPhone } from "@/FlexPlan/Utils/validtor";
import { FlexPlanRoles, flexPlanRoleToLabel } from "@/FlexPlan/Types/Roles";
import { SelectItem } from "@/Apis/SelectItem";

interface ContactSectionProps {
    setState: Dispatch<SetStateAction<ProfileTabState>>;
    state: ProfileTabState;
    errors;
    handleSelectEditSection(section: ProfileSections): () => void;
    handleSaveClick(): void;
}

const ContactSection = ({ setState, state, handleSelectEditSection, handleSaveClick, errors }: ContactSectionProps) => {
    const roleOptions = ["Select Role", ...Object.keys(FlexPlanRoles)].map(x => ({ label: flexPlanRoleToLabel(FlexPlanRoles[x]), value: FlexPlanRoles[x] === "Select Role" ? undefined : FlexPlanRoles[x] } as SelectItem));
    const [submittedOnce, setSubmittedOnce] = useState(false);
    const [contactRoleErrorMessage, setContactRoleErrorMessage] = useState("");
    const validationRules: { field: keyof PlanManagerContact | "roles", rule: (contact: PlanManagerContact) => string }[] = [
        { field: "firstName", rule: (contact) => ((isEmptyOrSpaces(contact.firstName) || exceedsMaxStringLength(contact.firstName)) ? "First Name is required." : "") },
        { field: "lastName", rule: (contact) => (isEmptyOrSpaces(contact.lastName) || exceedsMaxStringLength(contact.lastName) ? "Surname is required." : "") },
        { field: "email", rule: (contact) => (!validateEmail(contact.email) ? "Email Address is required." : "") },
        { field: "phone", rule: (contact) => (!isValidPhone(contact.phone) ? "Address is required" : "") },
        { field: "address", rule: (contact) => (!contact?.address?.postCode ? "Address is required" : "") },
        { field: "role", rule: (contact) => (!contact.role ? "Role is required." : "") },
        { field: "roles", rule: () => (state.planManagerProfile?.contacts?.filter(x => x.role === "FlexPlan_PlanManager").length === 1 ? "" : "Only one contact can have the role of Plan Manager.") },
    ];

    const handleContactChange = (index: number) => (e) => {
        setState(prevState => {
            const newState = { ...prevState };
            newState.planManagerProfile.contacts[index]![e.target.name] = e.target.value;
            return { ...newState };
        });
    };

    useEffect(() => {
        if (validationRules.find(x => x.field === "roles")!.rule({} as PlanManagerContact) !== "" && submittedOnce) {
            setContactRoleErrorMessage(state.planManagerProfile.contacts?.filter(x => x.role === "FlexPlan_PlanManager").length > 1
                ? "Only one contact can have the role of Plan Manager"
                : "Exactly one Plan Manager contact is required");
        } else {
            setContactRoleErrorMessage("");
        }
    }, [state.planManagerProfile?.contacts?.filter(x => x.role === "FlexPlan_PlanManager")]);

    const handleContactAddressSave = (index: number) => (address: LoqateAddress) => handleContactChange(index)({
        target: { name: getContactFieldName("address"), value: address },
    });

    const handleAddContactClick = () => setState(prevState => ({
        ...prevState,
        planManagerProfile: {
            ...prevState.planManagerProfile,
            contacts: [...prevState.planManagerProfile?.contacts, { email: "", phone: "", firstName: "", lastName: "" } as PlanManagerContact],
        },
    }));

    const handleDeleteContactClick = (index: number) => () => {
        setSubmittedOnce(false);
        setState(prevState => ({
            ...prevState,
            planManagerProfile: {
                ...prevState.planManagerProfile,
                contacts: [...prevState.planManagerProfile?.contacts.filter((_, i) => i !== index)],
            },
        }));
    };

    const validate = () => {
        let valid = true;
        for (let iContact = 0; iContact < state.planManagerProfile.contacts.length; iContact++) {
            if (!valid) {
                break;
            }
            for (let iRule = 0; iRule < validationRules.length; iRule++) {
                valid = validationRules[iRule].rule(state.planManagerProfile.contacts[iContact]) === "";
                if (!valid) {
                    break;
                }
            }
        }
        return valid;
    };

    const handleSaveContactClick = () => {
        setSubmittedOnce(true);
        if (validate()) {
            handleSaveClick();
        }
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-end mb-3">
                    {!state.selectedSections?.includes("ContactSection")
                        && (<Button onClick={() => { setSubmittedOnce(false); handleSelectEditSection("ContactSection")(); }} className="px-3" color="primary">Edit</Button>)}
                </Col>
            </Row>
            {state.planManagerProfile?.contacts?.map((contact, index) => (
                <div key={contact.id}>
                    <h4 className="font-weight-bold mb-3">{index === 0 ? "Main Contact" : "Additional Contact"}</h4>
                    {!state.selectedSections?.includes("ContactSection") && (
                        <>
                            <FormGroup className="d-flex w-75 mb-2">
                                <Label className="w-25">First Name</Label>
                                <Label className="font-weight-bold">{contact.firstName}</Label>
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex w-75 mb-2">
                                <Label className="w-25">Surname</Label>
                                <Label className="font-weight-bold">{contact.lastName}</Label>
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex w-75 mb-2">
                                <Label className="w-25">Email Address</Label>
                                <Label className="font-weight-bold">{contact.email}</Label>
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex w-75 mb-2">
                                <Label className="w-25">Phone Number</Label>
                                <Label className="font-weight-bold">{contact.phone}</Label>
                            </FormGroup>
                            <hr />
                            <FloatLabelAddressLookUp
                                address={!contact.address ? {
                                    premise: "",
                                    addressLine1: "",
                                    city: "",
                                    postCode: "",
                                } : contact.address}
                                className="pl-3"
                                hideButtons
                            />
                            <hr />
                            <FormGroup className="d-flex w-75 mb-2">
                                <Label className="w-25">Role</Label>
                                <Label className="font-weight-bold">{contact.role}</Label>
                            </FormGroup>
                            <hr className="pb-5" />
                        </>)}

                    {state.selectedSections?.includes("ContactSection") && (
                        <>
                            <FloatLabelTextbox
                                label="First Name*"
                                placeholder="First Name"
                                name={getContactFieldName("firstName")}
                                onChange={handleContactChange(index)}
                                error={submittedOnce ? validationRules.find(x => x.field === "firstName")!.rule(contact) : ""}
                                value={contact.firstName}
                            />
                            <FloatLabelTextbox
                                label="Surname*"
                                placeholder="Surname"
                                name={getContactFieldName("lastName")}
                                onChange={handleContactChange(index)}
                                error={submittedOnce ? validationRules.find(x => x.field === "lastName")!.rule(contact) : ""}
                                value={contact.lastName}
                            />
                            <FloatLabelTextbox
                                label="Email Address*"
                                placeholder="Email Address"
                                name={getContactFieldName("email")}
                                onChange={handleContactChange(index)}
                                error={submittedOnce ? validationRules.find(x => x.field === "email")!.rule(contact) : ""}
                                value={contact.email}
                            />
                            <FloatLabelTextbox
                                label="Phone Number"
                                name={getContactFieldName("phone")}
                                onChange={handleContactChange(index)}
                                error={submittedOnce ? validationRules.find(x => x.field === "phone")!.rule(contact) : ""}
                                placeholder="Change Contact Number"
                                value={contact.phone}
                            />
                            <FloatLabelAddressLookUp
                                address={contact.address}
                                onSave={handleContactAddressSave(index)}
                                className="pl-3"
                                error={submittedOnce ? validationRules.find(x => x.field === "address")!.rule(contact) : ""}
                            />

                            <FormGroup>
                                <Label className="tw-text-base">Role *</Label>
                                <Input
                                    className={styles.roleSelect}
                                    name={getContactFieldName("role")}
                                    type="select"
                                    value={contact.role}
                                    onChange={handleContactChange(index)}
                                >
                                    {roleOptions.map(x => <option key={x.label} value={x.value}>{x.label}</option>)}
                                </Input>
                                {(submittedOnce && validationRules.find(x => x.field === "role")!.rule(contact)) && (
                                    <FormFeedback className="!tw-block !tw-text-bootstrap-error-size !tw-mt-2" style={{ fontSize: "80%!important" }}>Role is required</FormFeedback>)}
                            </FormGroup>
                            <div className="d-flex justify-content-start mt-3">
                                <Button
                                    onClick={handleDeleteContactClick(index)}
                                    color="danger"
                                >
                                    Delete Contact
                                </Button>
                            </div>
                            <hr />
                        </>)}
                </div>))}
            {state.selectedSections?.includes("ContactSection") && (
                <div className="d-flex justify-content-between mt-3">
                    <div className="align-self-start">
                        <Button
                            onClick={handleAddContactClick}
                            color="primary"
                        >
                            Add Another Contact
                        </Button>
                    </div>
                    <ActionButtons
                        onCancelClick={handleSelectEditSection("ContactSection")}
                        onSaveClick={handleSaveContactClick}
                        disabled={(submittedOnce && !validate()) || errors.length > 0}
                        customErrorMessage={contactRoleErrorMessage}
                    />
                </div>)}
        </>);
};

export { ContactSection };
