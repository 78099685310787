import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import React, { ChangeEvent } from "react";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { getProfileFieldName, ProfileTabState, ProfileSections } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { isNumericString } from "@/FlexPlan/Utils/validtor";
import { exceedsMaxStringLength } from "@/Utils/stringHelper";

interface NdisSectionProps {
    state: ProfileTabState;
    errors;
    handleErrorStatusChange(hasError: boolean, field: string): void
    handleFieldChange(e: ChangeEvent<HTMLInputElement>): void
    handleSelectEditSection(section: ProfileSections): () => void;
    handleSaveClick(): void;
}

const NdisSection = ({ handleFieldChange, state, handleSelectEditSection, handleSaveClick, errors, handleErrorStatusChange }: NdisSectionProps) => (
    <>
        <Row>
            <Col xs="12" className="d-flex justify-content-between mb-3">
                <h4 className="font-weight-bold">NDIS Information</h4>
                {!state.selectedSections?.includes("NdisSection") && <Button onClick={handleSelectEditSection("NdisSection")} className="px-3" color="primary">Edit</Button>}
            </Col>
        </Row>
        {!state.selectedSections?.includes("NdisSection") && (
            <>
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">Registration Number</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.registrationNumber}</Label>
                </FormGroup>
            </>)}
        {state.selectedSections?.includes("NdisSection") && (
            <>
                <FloatLabelTextbox
                    label="Registration Number*"
                    placeholder="Registration Number"
                    name={getProfileFieldName("registrationNumber")}
                    onChange={handleFieldChange}
                    error={(!isNumericString(state.planManagerProfile?.registrationNumber)
                                || exceedsMaxStringLength(state.planManagerProfile?.registrationNumber)) ? "The Registration Number is required and allows only digits." : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.registrationNumber}
                />

                <div className="d-flex justify-content-end mt-3">
                    <ActionButtons onCancelClick={handleSelectEditSection("NdisSection")} onSaveClick={handleSaveClick} disabled={errors.length > 0} />
                </div>
            </>)}
    </>);

export { NdisSection };
