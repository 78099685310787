import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useParams } from "react-router-dom";

import { AuditEntityType, AuditResponse, FlexPlanAuditSearchForm } from "@/FlexPlan/Components/Audits/Types";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import Spinner from "@/Components/Spinner";
import FormatDateHelper from "@/Utils/formatDateHelper";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { useForm } from "@/Hooks/useForm";
import { FilterDropdown } from "@/FlexPlan/Components/FilterDropdown";
import { enumDisplay } from "@/Utils/formatStringHelper";
import DateTextbox from "@/Components/DateTextbox";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanAudit } from "@/FlexPlan/Types/Audit";
import { Pagination } from "@/Hooks/Pagination/Types";
import { Paging } from "@/Components/Paging";
import { padNumber } from "@/FlexPlan/Utils/number";
import { Filter } from "@/FlexPlan/Types/Pagination";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { isValidDate } from "@/Utils/stringHelper";
import { isNumeric } from "@/Utils/numberHelper";

interface Props {
    modules?: Filter[],
    entityId: string,
    entityType: AuditEntityType,
    renderModuleText: (text: string) => string,
    hasReferenceColumn?: boolean;
}

const FlexPlanAudits = ({ modules = [], entityId, entityType, renderModuleText, hasReferenceColumn = false }: Props) => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const [audits, setAudits] = useState<FlexPlanAudit[]>();
    const { setPopupErrorMessage } = useToastMessageContext();
    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: 0,
        pageLength: 10,
    });
    const { id } = useParams();
    const [sort, setSort] = useState<{ column: keyof FlexPlanAudit, desc: boolean }>({
        column: "id",
        desc: true,
    });

    const {
        onDebounceSearch,
        onDateTextboxChange,
        onFilterDropdownChange,
        formState,
        setFormState,
    } = useForm<FlexPlanAuditSearchForm>({
        search: "",
        entityId: id,
        modules,
        createdBy: [],
    });

    useEffect(() => {
        get<AuditResponse>(FlexPlanUrls.audits.get({
            ...formState,
            modules: formState.modules?.filter(x => x.checked).map(x => x.field),
            createdBy: formState.createdBy?.filter(x => x.checked).map(x => x.field),
            sortColumn: sort?.column,
            sortDescending: sort?.desc ?? false,
            to: formState.to ? FormatDateHelper.format(formState.to, "YYYY-MM-DDT23:59:59") : "",
            entityId,
            pageNumber: pagination.pageNumber + 1,
            itemsPerPage: pagination.pageLength,
            entity: entityType,
        }))
            .then(response => {
                setAudits(response.data);
                setPagination(prev => ({ ...prev, totalPageCount: response.totalPages }));
                setFormState(prev => {
                    if (!!prev.createdBy && prev.createdBy.length > 0) {
                        return prev;
                    }

                    return {
                        ...prev,
                        createdBy: response.createdByOptions.map((x, i) => ({
                            field: x.id,
                            checked: true,
                            label: enumDisplay(x.fullname),
                            position: i,
                        })),
                    };
                });
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    }, [formState, pagination.pageNumber, sort]);

    const renderSortIcon = (column: keyof FlexPlanAudit) => {
        if (sort && sort.column === column) {
            if (sort.desc) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }

        return <i className="fa fa-sort" />;
    };

    const onSort = (fieldName: keyof FlexPlanAudit) => () => {
        setSort(prev => ({
            column: fieldName,
            desc: fieldName === prev?.column && !prev.desc,
        }));
    };

    const onPageNumberChange = (pageNumber: number) => setPagination(prev => ({ ...prev, pageNumber }));

    const renderValue = (value: string) => {
        if (!isNumeric(value) && isValidDate(value)) {
            return FormatDateHelper.format(value, "DD/MM/YYYY HH:mm:ss");
        }

        return value;
    };

    const colSpan = hasReferenceColumn ? 8 : 7;

    return (
        <div className="tw-p-10">
            <div className="tw-flex tw-flex-row tw-space-x-5">
                <DebounceTextbox
                    placeholder="Search"
                    callBack={onDebounceSearch("search")}
                    name="search"
                    displaySearchIcon
                    className="tw-basis-1/5"
                />
                <FilterDropdown
                    filters={formState.modules}
                    onChange={onFilterDropdownChange("modules")}
                    label="Filter Module"
                />
                <FilterDropdown
                    filters={formState.createdBy}
                    onChange={onFilterDropdownChange("createdBy")}
                    disabled={!audits}
                    label="Filter Created By"
                />
                <DateTextbox
                    currentDate={formState.from ?? ""}
                    changeDate={onDateTextboxChange("from")}
                    placeholder="From"
                />
                <DateTextbox
                    currentDate={formState.to ?? ""}
                    changeDate={onDateTextboxChange("to")}
                    placeholder="To"
                />
            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th style={{ width: "10%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("id")}
                            >
                                Audit Ref.&nbsp;
                                {renderSortIcon("id")}
                            </button>
                        </th>
                        <th>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("module")}
                            >
                                Module&nbsp;
                                {renderSortIcon("module")}
                            </button>
                        </th>
                        {hasReferenceColumn && (
                            <th>
                                Reference&nbsp;
                            </th>
                        )}
                        <th>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("field")}
                            >
                                Field&nbsp;
                                {renderSortIcon("field")}
                            </button>
                        </th>
                        <th>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("from")}
                            >
                                From&nbsp;
                                {renderSortIcon("from")}
                            </button>
                        </th>
                        <th>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("to")}
                            >
                                To&nbsp;
                                {renderSortIcon("to")}
                            </button>
                        </th>
                        <th style={{ width: "10%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("dateCreated")}
                            >
                                Created&nbsp;
                                {renderSortIcon("dateCreated")}
                            </button>
                        </th>
                        <th>
                            <button
                                type="button"
                                className="bg-transparent p-0 b-0"
                                onClick={onSort("createdBy")}
                            >
                                Created By&nbsp;
                                {renderSortIcon("createdBy")}
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={colSpan}>
                                <Spinner className="tw-mx-auto" />
                            </td>
                        </tr>
                    )}
                    {!loading && audits && audits.map(x => (
                        <tr key={x.id}>
                            <td>{padNumber(x.id, 7)}</td>
                            <td>{renderModuleText(x.module)}</td>
                            {hasReferenceColumn && (
                                <td>{x.reference}</td>
                            )}
                            <td>{x.field}</td>
                            <td>{renderValue(x.from)}</td>
                            <td>{renderValue(x.to)}</td>
                            <td>
                                <div className="tw-flex tw-flex-col">
                                    <span>{FormatDateHelper.format(x.dateCreated, "DD/MM/YYYY")}</span>
                                    <span className="tw-text-gray-400">{FormatDateHelper.format(x.dateCreated, "HH:mm:ss")}</span>
                                </div>
                            </td>
                            <td>{x.createdBy}</td>
                        </tr>
                    ))}
                    {!loading && audits?.length === 0 && (
                        <tr>
                            <td colSpan={colSpan}>
                                No audits to display.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Paging
                onChange={onPageNumberChange}
                activePage={pagination?.pageNumber}
                pageCount={pagination?.totalPageCount ?? 0}
            />
        </div>
    );
};

export { FlexPlanAudits };
