import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";
import { verifyEmail, resendVerificationEmail } from "@/Apis/Accounts";
import urlHelper from "@/Utils/urlHelper";
import * as authentication from "@/Utils/authentication";
import globalActions from "@/Store/Global/actions";

const VerifyEmailPage = () => {
    document.title = "BSC - Verify Email";

    const location = useLocation();
    const dispatch = useDispatch();
    const [token] = useState(urlHelper.getUrlParam(location.search, "token"));
    const [state, setState] = useState<any>({
        error: {
            message: "",
            info: {
                wasSuccessful: false,
                canRegenerate: false,
            },
        },
        loading: true,
        isVerified: false,
        showNewEmailSent: false,
    });

    useEffect(() => {
        verifyEmail(token)
            .then(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    isVerified: true,
                }));
                authentication.removeToken();
            })
            .catch((response) => {
                setState(prevState => ({
                    ...prevState,
                    error: response,
                    loading: false,
                }));
            });
    }, [location.search, token]);

    const resendEmail = async () => {
        try {
            await resendVerificationEmail(token);
            setState(prevState => {
                const newState = {
                    ...prevState,
                    showNewEmailSent: true,
                };
                newState.error.message = "";
                newState.error.info.canRegenerate = false;
                return newState;
            });
            dispatch(globalActions.setToastMessage(true, "Email sent successfully!"));
        } catch {
            dispatch(globalActions.setErrorToastMessage(true, "Email failed to be sent!"));
        }
    };

    if (state.loading) {
        return (
            <LoginBackgroundWrapper>
                <div className="d-flex justify-content-center align-items-center h-50">
                    <Spinner size="45" />
                </div>
            </LoginBackgroundWrapper>
        );
    }

    if (state.isVerified) {
        return (
            <LoginBackgroundWrapper>
                <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                    Your email has been verified. Please login to continue.
                    <Link to="/login" className="mt-3 mt-md-5">Login</Link>
                </div>
            </LoginBackgroundWrapper>
        );
    }

    return (
        <LoginBackgroundWrapper>
            <div className="d-flex justify-content-center align-items-center">
                <div className={styles.error}>
                    {state.error.message}

                    {(!state.error.info.wasSuccessful && state.error.info.canRegenerate) && (
                        <Button color="primary" onClick={resendEmail} className="mt-4">
                            Resend verification email
                        </Button>
                    )}
                </div>

                {state.showNewEmailSent && (
                    <span className="text">New verification email sent, please check your inbox.</span>
                )}

            </div>
        </LoginBackgroundWrapper>
    );
};

export { VerifyEmailPage };
