import React from "react";

import { ReceiptStatus } from "@/Apis/Receipts";

interface Props {
    status: ReceiptStatus
}

const getColour = (status: ReceiptStatus) => {
    switch (status) {
        case ReceiptStatus.Sent:
            return "tw-text-sky-500";
        case ReceiptStatus.Draft:
            return "tw-text-amber-600";
        case ReceiptStatus.Rejected:
            return "tw-text-red-600";
        case ReceiptStatus.Void:
            return "tw-text-black";
        default: // Accepted
            return "tw-text-green-500";
    }
};

const ReceiptStatusIcon = ({ status }: Props) => (
    <span className="tw-flex tw-items-center">
        <i className={`fa fa-circle mr-2 mb-1 [&>*]:tw-w-2 [&>svg]:tw-h-2 ${getColour(status)}`} />
        <span>{ReceiptStatus[status]}</span>
    </span>
);

export { ReceiptStatusIcon };
