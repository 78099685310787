import * as constants from "./constants";

const loadNotifications$ = () => ({
    type: constants.LOAD_NOTIFICATIONS_$,
});

const notificationsLoaded = payload => ({
    type: constants.NOTIFICATIONS_LOADED,
    payload,
});

const clearNotifications = () => ({
    type: constants.CLEAR_NOTIFICATIONS,
});

const reduceUnreadCount = () => ({
    type: constants.REDUCE_UNREAD_COUNT,
});

export default {
    loadNotifications$,
    notificationsLoaded,
    clearNotifications,
    reduceUnreadCount,
};
