import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as BespokeQuoteStatus from "@/Components/BespokeQuotes/BespokeQuoteStatues";

const CustomerActionGroup = ({ quote, supplierName, acceptQuote, rejectQuote, quoteRejectionReasonAdded, proceedToCheckout }) => {
    const [rejectionReason, setRejectionReason] = useState("");

    const addRejectionReason = () => quoteRejectionReasonAdded(rejectionReason);

    return (
        <>
            <div className="m-4 d-flex justify-content-around">Do you accept this quotation from {supplierName}?</div>

            {quote.status === BespokeQuoteStatus.open && (
                <div className="m-4 d-flex justify-content-center">
                    <Button color="primary" onClick={acceptQuote} className="mr-2 px-4">Yes</Button>
                    <Button color="danger" onClick={rejectQuote} className="px-4">No</Button>
                </div>
            )}

            {quote.status === BespokeQuoteStatus.rejected && (
                <div className="m-4 d-flex justify-content-around text-danger font-weight-bold">Quotation declined</div>
            )}

            {quote.status === BespokeQuoteStatus.rejected && quote.rejectionReason && (
                <div className={classnames("p-2 m-4 d-flex justify-content-between rounded-lg border")}>
                    <span className="p-2">{quote.rejectionReason}</span>
                    <span className="p-2 font-weight-bold text-primary"><i className="fas fa-check" /> Reason sent</span>
                </div>
            )}

            {quote.status === BespokeQuoteStatus.rejected && !quote.rejectionReason && (
                <div className="p-2 m-4 d-flex justify-content-between rounded-lg border">
                    <Input
                        type="text"
                        placeholder="Add a reason (optional)"
                        onChange={e => setRejectionReason(e.target.value)}
                        className="mr-2 border-0 w-75"
                    />
                    <Button color="primary" onClick={addRejectionReason}>Send Reason</Button>
                </div>
            )}

            {quote.status === BespokeQuoteStatus.accepted && (
                <div className="m-4 d-flex justify-content-center align-items-center text-success font-weight-bold">
                    Quotation accepted
                    <Button color="primary" onClick={proceedToCheckout} className="ml-2 px-4">Proceed to Checkout</Button>
                </div>
            )}

            {quote.status === BespokeQuoteStatus.checkedOut && (
                <div className="m-4 p-4 rounded-lg border border-success text-success font-weight-bold">Quotation proceeded to checkout</div>)}
        </>
    );
};

CustomerActionGroup.propTypes = {
    acceptQuote: PropTypes.func.isRequired,
    rejectQuote: PropTypes.func.isRequired,
    quoteRejectionReasonAdded: PropTypes.func.isRequired,
    proceedToCheckout: PropTypes.func.isRequired,
    quote: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rejectionReason: PropTypes.string,
    }).isRequired,
    supplierName: PropTypes.string.isRequired,
};

export { CustomerActionGroup };
