import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";

import { LeaveCheckoutModal } from "@/Modals/LeaveCheckoutModal";

interface Props {
    shouldPreventNavigation: boolean,
    navigate: (location) => void,
}
const CheckoutLeavingGuard = ({ shouldPreventNavigation = false, navigate } : Props) => {
    const [showNavigateModal, setShowNavigateModal] = useState(false);
    const [nextLocation, setNextLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const handleBlockedNavigation = (location) => {
        if (!confirmedNavigation && shouldPreventNavigation) { // if coming from company formations, prompt if attempting to leave checkout
            setNextLocation(location);
            setShowNavigateModal(true);
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            setConfirmedNavigation(false);
            navigate(nextLocation.pathname);
        }
    }, [confirmedNavigation, nextLocation]);

    const leaveCheckout = () => {
        setShowNavigateModal(false);
        setConfirmedNavigation(true);
    };

    return (
        <>
            <Prompt message={handleBlockedNavigation} when={shouldPreventNavigation} />
            {showNavigateModal && (
                <LeaveCheckoutModal
                    onLeave={leaveCheckout}
                    onClose={() => setShowNavigateModal(false)}
                />
            )}
        </>
    );
};

export { CheckoutLeavingGuard };
