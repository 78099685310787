export type FlexPlanRoleStrings = "FlexPlan_MasterAdmin" | "FlexPlan_PlanManager" | "FlexPlan_PlanManagerAssistant";

export enum FlexPlanRoles {
    MasterAdmin = "FlexPlan_MasterAdmin",
    PlanManager = "FlexPlan_PlanManager",
    PlanManagerAssistant = "FlexPlan_PlanManagerAssistant",
}

export const flexPlanRoleToLabel = (role: FlexPlanRoles) => {
    switch (role) {
        case FlexPlanRoles.MasterAdmin:
            return "Master Admin";
        case FlexPlanRoles.PlanManager:
            return "Plan Manager";
        case FlexPlanRoles.PlanManagerAssistant:
            return "Plan Manager Assistant";
        default:
            return "";
    }
};
