import moment from "moment";

import { getToken, removeToken } from "./authentication";

import { getBrowserId } from "@/Utils/localStorage";

const configuration = { domainUrl: "/api/", addressEndpoint: "profile" };

const GENERIC_ERROR = "Unfortunately an error occurred. Please contact us.";

class ErrorInfo extends Error {
    constructor(error, info) {
        super(error);
        (this as any).info = info;
    }
}

const getHeaders = (details) => {
    const token = getToken();
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-browserid": getBrowserId(),
    };
    if (token) {
        (headers as any).Authorization = `Bearer ${token}`;
    }
    return ({
        headers,
        ...details,
        responseType: "json",
    });
};

const buildRequestForEpic = (url, method, details) => ({
    ...getHeaders({
        url: configuration.domainUrl + url,
        method,
        ...details,
    }),
});

const parseResponse = async (response) => {
    const contentType = response.headers.get("content-type");
    if (contentType === "text/plain; charset=utf-8") {
        return response.text();
    }
    const json = await response.json();
    if (json.error) {
        throw new ErrorInfo(json.error, json);
    }
    return json;
};

const common = <T = any>(url, request): Promise<T> => (
    fetch(configuration.domainUrl + url, getHeaders(request))
        .then(async (response) => {
            if (!response.ok) {
                if (response.status >= 405) {
                    const error = await response.json();
                    throw { message: (error && (error.message || error.Message)) || GENERIC_ERROR };
                } else if (response.status === 401) {
                    removeToken();
                    throw { message: response.statusText };
                } else if (response.status === 404) {
                    throw { message: response.statusText };
                } else {
                    throw await parseResponse(response);
                }
            }

            if (response.status === 204) {
                return {};
            }
            return parseResponse(response);
        })
);

const get = <T = any>(url): Promise<T> => common(url, "GET");

const post = <T = any>(url, body?: any): Promise<T> => common(url, {
    method: "POST",
    body: JSON.stringify(body),
});

const put = <T = any>(url, body?: any): Promise<T> => common(url, {
    method: "PUT",
    body: JSON.stringify(body),
});

const del = <T = any>(url, body?: any): Promise<T> => common(url, {
    method: "DELETE",
    body: JSON.stringify(body),
});

const downloadFile = (url, body, fileName, fileType, anchorTagRef, appendDate) => (
    fetch(configuration.domainUrl + url, getHeaders({ method: "POST", body }))
        .then(async (response) => {
            if (response.ok) {
                return response.blob();
            }

            throw await "Failed to download file";
        })
        .then((file) => {
            let name = `${fileName}.${fileType}`;
            if (appendDate) {
                name = `${fileName}_${moment().format("HH.mm-DD_MM_YYYY")}.${fileType}`;
            }
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(file, name);
            } else {
                const downloadController = anchorTagRef;
                const downloadUrl = URL.createObjectURL(file);
                downloadController.href = downloadUrl;
                downloadController.download = name;
                downloadController.click();

                setTimeout(() => {
                    URL.revokeObjectURL(downloadUrl);
                }, 100);
            }
        })
);

const handleError = component => (error) => {
    component.setState(() => ({
        error: error.message || error.toString(),
        loading: false,
    }));
};

export default {
    get,
    post,
    put,
    del,
    downloadFile,
    buildRequestForEpic,
    GENERIC_ERROR,
    handleError,
    configuration,
};
