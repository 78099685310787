import React from "react";
import { Col, Row } from "reactstrap";

import styles from "./styles.module.scss";

const Footer = () => (
    <Row className="w-100 m-0">
        <Col className="p-4 p-md-5 font-weight-bold d-flex justify-content-center" xs="12">
            <span className={styles.copyright}> © Business Support Club</span>
        </Col>
    </Row>
);

export default Footer;
