import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { Folder } from "@/Apis/Messaging";

export type FolderAction = "delete" | "moveToInbox"
interface Props {
    allChecked: boolean;
    onSelectAll(value: boolean);
    onSearch(value: string);
    moveToFolder(folderAction: FolderAction): (e) => any;
    currentFolder: Folder;
    disabled?: boolean;
    className?: string;
}

const MessageSearch = ({ allChecked, currentFolder, disabled, moveToFolder, onSearch, onSelectAll, className }: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const onSearchTermChange = (e) => {
        const searchTermValue = e.target.value;
        setSearchTerm(searchTermValue);
        onSearch(searchTermValue);
    };
    const composeButton = () => (
        <Button
            tag={Link}
            to="/messages/inbox?new"
            color="primary"
            className="mt-1"
        >
            <i className="fa fa-envelope mr-2" />
            Compose
        </Button>);
    return (
        <Row className={classnames(className && className, "pt-3 pb-3 box-shadow")}>
            <Col md={6} xs={7} className={classnames(styles.search, "pr-0")}>
                <div className="d-flex align-items-center justify-content-center border rounded px-2 mr-2">
                    <input
                        value={searchTerm}
                        type="text"
                        className="flex-grow-1 my-2"
                        placeholder="Search Messages"
                        onChange={onSearchTermChange}
                        data-testid="search-box"
                    />
                    <i className="fa fa-search mt-1 mr-2" />
                </div>
            </Col>
            <Col md={6} xs={5} className="p-0">
                {composeButton()}
            </Col>

            <Col md={6} xs={7} className="mt-3">
                <Checkbox
                    onChange={onSelectAll}
                    id="selectAll"
                    checked={allChecked}
                    className="pl-1"
                    label="Select All"
                />
            </Col>
            <Col md={6} xs={5} className="mt-3 p-0">
                <button
                    type="button"
                    title="Delete messages"
                    className="px-2 bg-transparent border-0"
                    onClick={moveToFolder("delete")}
                    data-testid="sendToDeleted"
                    disabled={disabled}
                >
                    <i className="fa fa-trash-alt mr-2" />
                </button>
                <button
                    type="button"
                    title="Move to Inbox"
                    className="px-2 bg-transparent border-0"
                    onClick={moveToFolder("moveToInbox")}
                    data-testid="sendToInbox"
                    disabled={disabled || currentFolder !== "deleted"}
                >
                    <i className="fa fa-envelope-open mr-2" />
                </button>
            </Col>
        </Row>
    );
};

export default MessageSearch;
