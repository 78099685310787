import React, { ChangeEvent } from "react";
import { Button, Input } from "reactstrap";

import { FieldTypeSelect } from "../FieldTypeSelect";
import { AppliedTo } from "../../ManageSubCategoriesPage/AppliedTo";

import styles from "./styles.module.scss";

import { OwnedCustomFieldType } from "@/Apis/Suppliers/OwnedCustomFieldsType";

interface Props {
    ownedCustomField: OwnedCustomFieldType;
    onDisplayTextChange(id: string) : (e: ChangeEvent<HTMLInputElement>) => void;
    onFieldTypeChange(id: string) : (fieldType: string) => void;
    saveOwnedCustomField(id: string) : () => void;
    deleteOwnedCustomField(id: string) : () => void;
    isLoading: boolean;
}

const CustomFieldRow = ({ ownedCustomField, onDisplayTextChange, isLoading, onFieldTypeChange, saveOwnedCustomField, deleteOwnedCustomField }: Props) => (
    <tr>
        <td className={styles.column}>
            <AppliedTo appliedTo={ownedCustomField.appliedToProducts} notAppliedMessage="Not applied to any services" />
        </td>
        <td className={styles.column}>
            <AppliedTo appliedTo={ownedCustomField.appliedToBespokeQuotes} notAppliedMessage="Not applied to any bespoke quotes" />
        </td>
        <td className={styles.column}>
            {ownedCustomField.appliedToOrderFormCount}
        </td>
        <td className={styles.column}>
            <FieldTypeSelect
                selectedFieldsType={ownedCustomField.type}
                isLoading={isLoading}
                onFieldTypeChange={onFieldTypeChange(ownedCustomField.id)}
            />
        </td>
        <td className={styles.column}>
            <Input
                data-testid="customFieldNameTextBox"
                type="text"
                onChange={onDisplayTextChange(ownedCustomField.id)}
                value={ownedCustomField.displayText ?? ""}
                invalid={ownedCustomField.displayText === ""}
            />
        </td>
        <td className={styles.column}>
            <Button
                data-testid="customFieldUpdateButton"
                color="primary"
                className="mr-2"
                disabled={ownedCustomField.displayText === "" || ownedCustomField.isUpdating || ownedCustomField.isDeleting}
                onClick={saveOwnedCustomField(ownedCustomField.id)}
            >
                Update
                {ownedCustomField.isUpdating
                    ? (<i className="fas fa-circle-notch ml-2 fa-spin text-success" />)
                    : (<i className="fas fa-check ml-2 text-success" />)}
            </Button>
            <Button
                data-testid="customFieldDeleteButton"
                color="danger"
                outline
                disabled={ownedCustomField.isUpdating || ownedCustomField.isDeleting}
                onClick={deleteOwnedCustomField(ownedCustomField.id)}
            >
                Remove
                {ownedCustomField.isDeleting
                    ? (<i className="fas fa-circle-notch ml-2 fa-spin text-success" />)
                    : (<i className="fas fa-times ml-2 text-danger" />)}
            </Button>
        </td>
    </tr>
);

export { CustomFieldRow };
