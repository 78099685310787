import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { acceptedFileTypes } from "@/Utils/constants";

const MoreInfo = ({ moreInformation = "", images = [], onChange, onImageDrop }) => {
    const uniqueImages = [...new Set(images)];
    const onDeleteImage = (name) => () => {
        onChange("images")({ target: { value: uniqueImages.filter(x => x.name !== name) } });
    };
    const [editorState, setEditorState] = useState(() => createEditorState(moreInformation));

    const onEditorChange = (changedEditorState) => {
        setEditorState(() => changedEditorState);
        onChange("moreInformation")({ target: { value: getContentStateAsJson(changedEditorState) } });
    };

    const onMaxCharactersReached = () => setEditorState(() => createEditorState(getContentStateAsJson(editorState)));

    return (
        <div className="mt-4 d-flex flex-column h-100">
            <div className="tw-prose tw-max-w-full">
                <FormGroup className={styles.moreInfo}>
                    <RichTextEditor
                        label="Add more information for your customers"
                        maxCharacters={8000}
                        onMaxCharactersReached={onMaxCharactersReached}
                        placeholder="Enter more information here"
                        onChange={onEditorChange}
                        editorState={editorState}
                    />
                </FormGroup>
            </div>
            <div className="mt-4 d-flex flex-column flex-grow-1">
                {uniqueImages.map(x => (
                    <div key={x.name} className={classnames("d-flex justify-content-between mb-3", styles.image)}>
                        <div className="d-flex align-items-center">
                            <Image src={x.data || x} alt="service" className="mr-3" />
                            <span>{x.name}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button color="danger" className="px-3 py-2" onClick={onDeleteImage(x.name)}><i className="fa fa-trash-alt" /></Button>
                        </div>
                    </div>
                ))}
            </div>

            <DropZoneArea onAdd={onImageDrop} documents={images} accept={acceptedFileTypes.images}>
                <div className="d-flex justify-content-center align-items-end pt-4 pb-2 mt-5">
                    <span>Drop images here to upload</span>
                </div>
            </DropZoneArea>
        </div>
    );
};

MoreInfo.propTypes = {
    onChange: PropTypes.func.isRequired,
    onImageDrop: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, data: PropTypes.string.isRequired })),
    moreInformation: PropTypes.string,
};

export { MoreInfo };
