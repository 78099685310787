import moment, { MomentInput } from "moment";

// If you need a new custom date format, add it
// to this list, and be sure to use HH (uppercase)
// for hours to avoid displaying AM times for
// everything UNLESS you are using:
// "A" (AM/PM) or "a" (am/pm)
//
// Please also add a comment if you add a new date
// format
export type KnownDateFormat =
    // Time only
    "HH:mm:ss" |
    // Date only
    "DD/MM/YYYY" |
    // Date only short
    "YYYYMMDD" |
    // Date only no year
    "DD MMM" |
    "h:mm A" |
    "DD/MM/YYYY hh:mm A" |
    "yyyy-MM-DDTHH:mm:ss" |
    "DD MMM YYYY h:mm A" |
    // Message listing future
    "hh.mm A" |
    // Message listing past
    "DD MMM h:mm A" |
    "DD/MM/YYYY HH:mm" |
    "DD/MM/YYYY HH:mm:ss" |
    "MMM DD YYYY h:mm A" |
    "a" |
    // End of day
    "YYYY-MM-DDT23:59:59" |
    "DD/MM/YY";

const format = (date: MomentInput, format: KnownDateFormat = "DD/MM/YYYY HH:mm") => moment
    .utc(date).local().format(format);

// We only need these calculating once so keep it outside the function
const today = moment().startOf("day");

const forMessageListing = (date?: MomentInput) => {
    if (moment(date) >= today) {
        return format(date, "hh.mm A");
    }
    return format(date, "DD MMM h:mm A");
};

const forBespokeQuote = (date: MomentInput) => {
    const isDate = date instanceof Date;
    return isDate ? format(date, "MMM DD YYYY h:mm A") : date;
};

export default {
    forMessageListing,
    forBespokeQuote,
    format,
};
