import React, { useState } from "react";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useDebounce } from "@/Hooks/useDebounce";
import { User } from "@/Apis/UsersManagement";
import { useAPI } from "@/Apis/useAPI";

interface Error {
    error?: any;
}

interface Props {
    users: User[];
    onSupplierAdded?(): void;
}

const AddNewSupplier = ({ users, onSupplierAdded }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>();
    const toastMessageContext = useToastMessageContext();
    const { post } = useAPI({ handle500WithRedirect: true });

    const [apiResponse, setApiResponse] = useState<Error>({});
    const [userSearch, setUserSearch] = useState<string>("");
    const searchDebounced = useDebounce(userSearch, 400);

    const toggleModal = async () => {
        setSelectedUser(null);
        setUserSearch("");
        setModalOpen(prevState => !prevState);
    };

    const addSupplier = (userId) => {
        post("suppliers/new", { userId })
            .then(() => {
                toastMessageContext.setSuccessMessage("Supplier added successfully!", true);

                if (onSupplierAdded) {
                    onSupplierAdded();
                }

                toggleModal();
            })
            .catch(() => {
                setApiResponse({ error: "Unable to add supplier, please make sure they're not already a supplier and try again" });
            });
    };

    return (
        <>
            <Button color="warning" className="font-weight-bold" onClick={toggleModal} data-testid="addNewSupplier-openModal">
                Add New Supplier
            </Button>

            <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg" data-testid="addNewSupplier-modal">
                <CloseButton onClick={toggleModal} />
                <ModalBody className="p-5 d-flex flex-column">
                    <h4 className="font-weight-bold mb-4">Add A Supplier</h4>
                    <div className="d-flex flex-column align-items-center flex-grow-1 text-center">
                        <Input
                            placeholder="Select Supplier to add"
                            value={userSearch}
                            onChange={e => setUserSearch(e.target.value)}
                            className="p-4 mb-3"
                        />
                        <div className={classnames(styles.usersHeight, "overflow-auto w-100")}>
                            {(users || [])
                                .filter(x => x.email.indexOf(searchDebounced) > -1)
                                .map(x => (
                                    <button
                                        type="button"
                                        key={x.userId}
                                        className={classnames(
                                            x.userId === selectedUser?.userId ? styles.selectedItem : "",
                                            x.userId === selectedUser?.userId ? "selected-user" : "",
                                            "d-flex align-items-center justify-content-between p-3 w-100 text-left",
                                        )}
                                        onClick={() => setSelectedUser(x)}
                                        title="Click to select Supplier"
                                        data-testid={`addNewSupplier-user-${x.userId}`}
                                    >
                                        {x.email}
                                    </button>
                                ))}
                        </div>

                        <div className="text-right w-100 mt-4">
                            <Button
                                color="secondary"
                                className="text-white"
                                onClick={toggleModal}
                                data-testid="addNewSupplier-closeModal"
                            >
                                Close
                            </Button>
                            <Button
                                color="warning"
                                className="font-weight-bold ml-2"
                                disabled={!selectedUser}
                                onClick={() => addSupplier(selectedUser?.userId)}
                                data-testid="addNewSupplier-addSupplier"
                            >
                                Add
                            </Button>
                        </div>

                        {apiResponse.error && <p className="mt-3 text-danger">{apiResponse.error}</p>}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export { AddNewSupplier };
