class ProductVariants {
    static get quotationProduct() {
        return "QuotationProduct";
    }

    static get singleProduct() {
        return "SingleProduct";
    }

    static get enquiryFormProduct() {
        return "EnquiryFormProduct";
    }

    static get listedProduct() {
        return "ListedProduct";
    }

    static get customisableProduct() {
        return "CustomisableProduct";
    }

    static get formationsProduct() {
        return "FormationsProduct";
    }

    static get readyMadeFormationsProduct() {
        return "ReadyMadeFormationsProduct";
    }

    static get bankAccountProduct() {
        return "BankAccountProduct";
    }

    static canBeAddedToBasket(productVariant: string) {
        return productVariant === ProductVariants.singleProduct
            || productVariant === ProductVariants.quotationProduct
            || productVariant === ProductVariants.listedProduct
            || productVariant === ProductVariants.customisableProduct
            || productVariant === ProductVariants.formationsProduct
            || productVariant === ProductVariants.readyMadeFormationsProduct
            || productVariant === ProductVariants.bankAccountProduct;
    }

    static canBeCustomised(productVariant: keyof ProductVariant) {
        return productVariant === ProductVariants.listedProduct || productVariant === ProductVariants.customisableProduct;
    }
}
// Used for ts keyof
export interface ProductVariant {
    SingleProduct;
    EnquiryFormProduct;
    ListedProduct;
    CustomisableProduct;
    FormationsProduct;
    ReadyMadeFormationsProduct;
    BankAccountProduct;
}

export { ProductVariants };
