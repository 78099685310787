// eslint-disable-next-line import/no-unresolved
import imageCompression from "browser-image-compression";

import { encodeFileToBase64 } from "@/Utils/base64EncodeHelper";

export const preProcessImage = image => new Promise((resolve) => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    imageCompression(image, options)
        .then((compressedImage) => encodeFileToBase64(compressedImage).then((result) => resolve(result)));
});

export const preProcessImageThumbnail = image => new Promise((resolve) => {
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 960,
        useWebWorker: true,
    };
    imageCompression(image, options)
        .then((compressedImage) => encodeFileToBase64(compressedImage).then((result) => resolve(result)));
});
