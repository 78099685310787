import { useLocation } from "react-router";
import React, { useContext } from "react";

import { toPlural } from "@/Utils/formatStringHelper";
import urlHelper from "@/Utils/urlHelper";
import { ReceiptType } from "@/Apis/Receipts/Create";

interface ReceiptState {
    pageType: ReceiptType,
    pageTypeString: string,
    pageTypePlural: string,
    pageTypePluralLowercase: string,
    pageTypeLowercase: string,
}

const ReceiptsContext = React.createContext<ReceiptState>({} as ReceiptState);
const useReceiptsContext = () => useContext(ReceiptsContext);

interface Props {
    children: React.ReactNode,
}

const ReceiptsProvider = ({ children }: Props) => {
    const location = useLocation();
    const pageTypePlural = toPlural(ReceiptType[urlHelper.getReceiptsPageType(location)]);
    const pageTypePluralLowercase = toPlural(ReceiptType[urlHelper.getReceiptsPageType(location)]).toLowerCase();
    const pageType = urlHelper.getReceiptsPageType(location);
    const pageTypeLowercase = ReceiptType[urlHelper.getReceiptsPageType(location)].toLowerCase();
    const pageTypeString = ReceiptType[pageType];

    return (
        <ReceiptsContext.Provider value={{
            pageType,
            pageTypePlural,
            pageTypePluralLowercase,
            pageTypeLowercase,
            pageTypeString,
        }}
        >
            {children}
        </ReceiptsContext.Provider>);
};

export {
    useReceiptsContext,
    ReceiptsProvider,
};
