import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { Media, Row, Col, Collapse, Button } from "reactstrap";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import NoResultsFound from "@/Components/NoResultsFound";
import PackageServices from "@/Components/PackageServices";
import { Visible } from "@/Components/Visible";
import Spinner from "@/Components/Spinner";
import api from "@/Utils/api";
import globalActions from "@/Store/Global/actions";
import { PackageInfo } from "@/Components/PackageInfo";

const ManagePackagesPage = () => {
    document.title = "BSC - Manage Packages";

    const dispatch = useDispatch();

    const [state, setState] = useState({
        packages: [] as any[],
        currentPackage: "",
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get("packages")
            .then((response) => {
                setState(prevState => ({
                    ...prevState,
                    packages: response,
                }));
                setLoading(false);
            })
            .catch(() => dispatch(globalActions.setErrorToastMessage(true, "Failed to load packages")));
    }, []);

    const deletePackage = (packageId) => {
        api.del(`packages/${packageId}`)
            .then(() => {
                setState(prevState => ({
                    ...prevState,
                    packages: state.packages.filter(x => x.id !== packageId),
                }));
            })
            .catch(() => dispatch(globalActions.setErrorToastMessage(true, "Failed to delete package")));
    };

    const updatePackageInfo = (packageInfo) => {
        const packages = [...state.packages];
        const packageItem = packages.find(x => x.id === packageInfo.id);
        packageItem.code = packageInfo.code;
        packageItem.name = packageInfo.name;
        packageItem.description = packageInfo.description;
        setState(prevState => ({
            ...prevState,
            packages,
        }));
    };

    const togglePackage = (value) => setState(prevState => ({
        ...prevState,
        currentPackage: state.currentPackage === value ? "" : value,
    }));

    const copyName = (value) => {
        navigator.clipboard.writeText(value);
        dispatch(globalActions.setToastMessage(true, "Package code copied to clipboard"));
    };

    const calculateTotal = (services) => services.reduce((total, x) => total + (x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge, 0);

    const renderHeader = () => (
        <Row className={classnames(styles.header, "p-4 bg-primary-gradient d-flex")}>
            <Col xs="12" lg="2">
                <Media
                    object
                    src={`${(window as any).cdnUrl}misc/calvin-manage-packages.png`}
                    alt="Package Manager"
                    className="d-block mx-auto"
                />
            </Col>
            <Col
                xs="12"
                lg="10"
                className="text-center mt-4 mt-lg-0 d-flex flex-column justify-content-center"
            >
                <Visible below="Lg">
                    <h4 className="font-weight-bold mb-4 text-center">
                        Let&#39;s Manage Your Packages
                    </h4>
                </Visible>
                <Visible forAndAbove="Lg">
                    <h3 className="font-weight-bold mb-4" data-testid="manage-pages-heading">
                        Let&#39;s Manage Your Packages
                    </h3>
                </Visible>
                <p className="mt-3">
                    Manage your existing packages and referral codes here. You may also delete packages no longer in use
                    and keep informed on your most popular ones.
                </p>
            </Col>
        </Row>
    );

    const renderContent = () => (
        <>
            {
                state.packages.length === 0
                    ? <Row className="mt-4 pt-3 pt-md-5 justify-content-center"><NoResultsFound /></Row>
                    : (
                        <Row className="flex-column flex-grow-1 tw-p-2 lg:tw-p-0">
                            <Col className="flex-grow-1">
                                <Row className="mx-0 mt-2 m-lg-4">
                                    {state.packages.map((x) => (
                                        <Col
                                            xs="12"
                                            className="box-shadow rounded-lg p-0 bg-white d-flex flex-column tw-mb-4"
                                            key={x.id}
                                        >
                                            <div
                                                className="d-flex p-2 p-lg-4 flex-column flex-md-row align-content-end"
                                            >
                                                <button
                                                    type="button"
                                                    className="d-flex m-0 border-0 bg-transparent align-items-center"
                                                    onClick={() => togglePackage(x.id)}
                                                >
                                                    {state.currentPackage === x.id
                                                        ? <i className="fa fa-caret-up h4 m-0" />
                                                        : <i className="fa fa-caret-down h4 m-0" />}

                                                </button>
                                                <div className="d-flex flex-grow-1">
                                                    <PackageInfo
                                                        packageInfo={x}
                                                        updatePackageInfo={(packageInfo) => updatePackageInfo(packageInfo)}
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-1">
                                                    <div className="mx-4 px-1">
                                                        <p className="border mb-0 px-3 py-2 rounded-pill border-primary text-primary font-weight-bold">
                                                            {x.services.length} Items
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            color="primary"
                                                            type="button"
                                                            onClick={() => copyName(x.code)}
                                                            className="btn btn-primary tw-whitespace-nowrap"
                                                        >
                                                            Copy code
                                                        </Button>
                                                        <Button
                                                            color="link"
                                                            onClick={() => deletePackage(x.id)}
                                                            className={classnames(styles.deleteButton, "bg-transparent border-0 pointer")}
                                                        >
                                                            <i className="fa fa-trash-alt h4" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse isOpen={state.currentPackage === x.id}>
                                                <PackageServices services={x.services} />

                                                <div className="py-4 px-2 d-flex bg-light">
                                                    <h4 className="font-weight-bold mb-0 pl-3 flex-grow-1">Subtotal</h4>
                                                    <h4 className="font-weight-bold mb-0 pr-3">£{calculateTotal(x.services).toFixed(2)}</h4>
                                                </div>
                                            </Collapse>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    )
            }
        </>
    );

    return (
        <div>
            {renderHeader()}
            {
                loading
                    ? <Row className="h-100 flex-grow-1 align-items-center justify-content-center"><Spinner /></Row>
                    : renderContent()
            }
            <Footer />
        </div>
    );
};

export default ManagePackagesPage;
