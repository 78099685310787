import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";

import * as VatHelper from "@/Utils/vatHelper";

const ViewBespokeQuoteItemPrice = ({ item }) => {
    const [vatAmount, setVatAmount] = useState(0);

    useEffect(() => {
        if (item.applyVat) {
            setVatAmount(VatHelper.calculateVat(item.total));
        } else {
            setVatAmount(0.00);
        }
    }, [item]);

    return (
        <div className="mr-2 text-right">
            {vatAmount > 0 && <p>Item Price: £{item.total.toFixed(2)}</p>}
            {vatAmount > 0 && <p>VAT: £{vatAmount.toFixed(2)}</p>}
            <Label><strong>Total Item Price</strong></Label>
            <h5 className="">£{(vatAmount + item.total).toFixed(2)}</h5>
        </div>
    );
};

ViewBespokeQuoteItemPrice.propTypes = {
    item: PropTypes.shape({
        total: PropTypes.number.isRequired,
        applyVat: PropTypes.bool.isRequired,
    }).isRequired,
};

export { ViewBespokeQuoteItemPrice };
