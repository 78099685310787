import React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import Checkbox from "@/Components/Checkbox";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    openSections: SupplierSections[],
}

const BusinessDetailsSection = ({ onSave, errors, removeErrorField, onChangeEditableSections, openSections }: Props) => {
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();

    const onChange = (e: { target: { name: keyof FlexPlanSupplier, value: any } }) => {
        dispatch({
            type: SupplierProfileActions.Update,
            field: e.target.name,
            value: e.target.value,
        });
        removeErrorField(e.target.name);
    };

    const onCheckboxChange = (field: keyof FlexPlanSupplier) => (value: boolean) => {
        dispatch({
            type: SupplierProfileActions.Update,
            field,
            value,
        });
        removeErrorField(field);
    };

    const renderPlansAcceptedText = () => {
        const plansManaged: string[] = [];

        if (supplierProfileState.selfManaged) plansManaged.push("Self Managed");
        if (supplierProfileState.planManaged) plansManaged.push("Plan Managed");
        if (supplierProfileState.ndia) plansManaged.push("NDIA Managed");

        return plansManaged.join(", ");
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-between mb-3">
                    <h4 className="font-weight-bold">Business Details</h4>
                    {!openSections?.includes("Business Details")
                        && (
                            <Button
                                className="px-3"
                                color="primary"
                                onClick={onChangeEditableSections("Business Details")}
                                disabled={supplierProfileState.isPublic}
                            >
                                Edit
                            </Button>
                        )}
                </Col>
            </Row>
            <>
                {openSections.includes("Business Details") ? (
                    <>
                        <FormGroup>
                            <Label className="tw-text-base">Plans Accepted</Label>
                            <div className="tw-flex tw-flex-row tw-space-x-3">
                                <Checkbox
                                    label="Self Managed"
                                    labelClassName="!tw-text-base"
                                    onChange={onCheckboxChange("selfManaged")}
                                    checked={supplierProfileState.selfManaged}
                                    id="self-managed"
                                    disabled={supplierProfileState.isPublic}
                                />
                                <Checkbox
                                    label="Plan Managed"
                                    labelClassName="tw-text-base"
                                    onChange={onCheckboxChange("planManaged")}
                                    checked={supplierProfileState.planManaged}
                                    id="plan-managed"
                                    disabled={supplierProfileState.isPublic}
                                />
                                <Checkbox
                                    label="NDIA"
                                    labelClassName="tw-text-base"
                                    onChange={onCheckboxChange("ndia")}
                                    checked={supplierProfileState.ndia}
                                    id="ndia"
                                    disabled={supplierProfileState.isPublic}
                                />
                            </div>
                        </FormGroup>
                        <FloatLabelTextbox
                            label="NDIS Number"
                            value={supplierProfileState.ndisRegistrationNumber}
                            name="ndisRegistrationNumber"
                            onChange={onChange}
                            error={errors.ndisRegistrationNumber}
                            maxLength={255}
                            disabled={supplierProfileState.isPublic}
                        />
                        <div className="d-flex justify-content-end mt-3">
                            <ActionButtons
                                onCancelClick={onChangeEditableSections("Business Details")}
                                onSaveClick={onSave}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Plans Accepted</Label>
                            <Label className="font-weight-bold">{renderPlansAcceptedText()}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">NDIS Number</Label>
                            <Label className="font-weight-bold">
                                {supplierProfileState.ndisRegistrationNumber}
                            </Label>
                        </FormGroup>
                    </>
                )}
            </>
        </>
    );
};

export { BusinessDetailsSection };
