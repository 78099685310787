import { nameof } from "@/Utils/objectHelper";
import { ServiceBookingStatus } from "@/FlexPlan/Types/Ndis/ServiceBookings/serviceBookingStatus";

export interface ManageServiceBookingForm {
    standard: StandardBooking[],
    planManaged: PlanManagedBooking[],
    statedSupport: StatedSupportBooking[],
    coreCategoryMaxAmountMessage?: string;
}

// Item Level
export interface StandardBooking extends ServiceBookingBase {
    items: StandardBookingRow[],
}

export interface StandardBookingRow extends ServiceBookingRowBase {
    itemNumber?: string,
}

// Category Level
export interface PlanManagedBooking extends ServiceBookingBase {
    items: PlanManagedBookingRow[],
}

export interface PlanManagedBookingRow extends ServiceBookingRowBase {
    budgetType?: string,
    displayCoreTotals?: boolean, // We need to display totals for the core category, this is a marker
    displayViewMax?: boolean;
    ndisCategoryName?: string, // Allows us to map the NDIS value that we save to the display value
}

// Stated Level
export interface StatedSupportBooking extends ServiceBookingBase {
    items: StatedSupportBookingRow[];
}

export interface StatedSupportBookingRow extends ServiceBookingRowBase {
    itemNumber?: string,
    quantity: number,
    price: number,
}

// **** Shared fields ****
// All service bookings share these fields
export interface ServiceBookingBase {
    id: string, // We need this to keep track of the row and update the state. Does not relate to the persisted Id value.
    serviceBookingNumber?: number,
    startDate: string,
    endDate: string,
    isLoaded: boolean, // Is our service booking loading from the database? determines whether we insert or upsert
    status?: ServiceBookingStatus,
    position: number,
}

// Service booking rows share these fields
export interface ServiceBookingRowBase {
    id: string, // We need this to keep track of the row and update the state. Does not relate to the persisted Id value.
    category: string,
    spent: number,
    remaining: number,
    quantity: number,
    price: number,
    position: number, // Category rows are fixed, and have an order.
    maxAmountMessage?: string;
}

export type BookingSection = "standard" | "planManaged" | "statedSupport";

export const getBookingSection = (name: BookingSection) => name;
export const getItemFieldName = (name: keyof StandardBooking) => nameof<StandardBooking>(name);
export const getItemRowFieldName = (name: keyof StandardBookingRow) => nameof<StandardBookingRow>(name);

export enum BookingTypeNdis {
    StandardBooking = "StandardBooking",
    PlanManaged = "PlanManaged",
}
