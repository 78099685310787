/* eslint-disable max-len */
import React from "react";
import { Alert, Button, FormFeedback, FormGroup, Label } from "reactstrap";
import classnames from "classnames";

import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import Textbox from "@/Components/Textbox";
import { useManageServiceBookings } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Hooks";
import { BookingSection, getItemFieldName, getItemRowFieldName } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Types";
import DateTextbox from "@/Components/DateTextbox";
import NumberField from "@/Components/NumberField";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";
import { BackButton } from "@/FlexPlan/Components/BackButton";
import { enumDisplay, toCurrency } from "@/Utils/formatStringHelper";
import { calculateRemainingTotal, calculateSpentTotal, calculateAllocatedTotal } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Utils";
import { ReactSelect } from "@/Components/ReactSelect";
import Spinner from "@/Components/Spinner";
import formatDateHelper from "@/Utils/formatDateHelper";
import { RenderServiceBookingStatus } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Components/ServiceBookingStatus";
import { useFlexPlanProductContext } from "@/FlexPlan/Context/FlexPlanProductContext";

const ManageServiceBookings = () => {
    const {
        onChangeSection,
        onChangeRow,
        formState,
        onDateTextBoxChange,
        onAddRow,
        planId,
        onSelect,
        processingSubmission,
        onSubmit,
        getErrorField,
        getErrorMessage,
        loadingBookings,
        participantName,
        planStartDate,
        planEndDate,
        onGetUpdate,
        serviceBookingsUpdated,
        onMaxAmountClick,
        processingMaxAmount,
        isCreate,
        onCreateNewServiceBooking,
    } = useManageServiceBookings();

    const {
        groupedProducts,
    } = useFlexPlanProductContext();

    if (!formState || loadingBookings) {
        return <Spinner className="tw-mx-auto tw-my-auto" />;
    }

    const renderNoServiceBookingsMessage = (section: BookingSection) => {
        if (serviceBookingsUpdated) {
            return (
                <div className="-tw-mt-4 tw-mb-10 lg:tw-w-1/2">
                    No {enumDisplay(section)} service bookings available.
                </div>
            );
        }

        return (
            <div className="-tw-mt-4 tw-mb-10 lg:tw-w-1/2">
                <Alert color="info">
                    <i className="fa fa-info-circle tw-mr-2" />
                    No {enumDisplay(section)} service bookings found. Use the Get Update button above to retrieve any existing bookings from NDIS.
                </Alert>
            </div>
        );
    };

    return (
        <FlexPlanBasicWrapper title={isCreate ? "Create New Service Booking" : "Manage Service Bookings"}>
            <div className="tw-text-base">
                Service Bookings for
                <span className="tw-text-flexplan-secondary tw-text-base">&nbsp;{participantName}</span> Plan ID
                <span className="tw-text-flexplan-secondary tw-text-base">&nbsp;{planId}</span> Plan Start
                <span className="tw-text-flexplan-secondary tw-text-base">&nbsp;{formatDateHelper.format(planStartDate, "DD/MM/YYYY")}</span> Plan End
                <span className="tw-text-flexplan-secondary tw-text-base">&nbsp;{formatDateHelper.format(planEndDate, "DD/MM/YYYY")}</span>
            </div>
            <div className="tw-mt-10">
                <div className="tw-flex tw-flex-row tw-space-x-3 tw-mb-7 tw-items-center">
                    <span className="tw-font-semibold tw-mr-4 tw-text-base">Standard Service Booking</span>
                    {!isCreate && (
                        <>
                            <Button
                                color="primary"
                                className="tw-ml-3"
                                onClick={onGetUpdate}
                                disabled={processingSubmission}
                            >
                                {processingSubmission ? <Spinner className="tw-mx-auto" size="25" /> : "Get Update"}
                            </Button>
                            <Button
                                color="primary"
                                className="tw-ml-3"
                                onClick={onCreateNewServiceBooking}
                            >
                                Create New Service Booking
                            </Button>
                        </>
                    )}
                </div>
                {formState.standard.length === 0 && (
                    renderNoServiceBookingsMessage("standard")
                )}
                {formState.standard.sort((a, b) => a.position - b.position)
                    .map((standardBooking) => (
                        <div key={standardBooking.id}>
                            <div className="tw-flex tw-flex-row tw-space-x-5">
                                <Textbox
                                    label="Service Booking Number"
                                    maxLength={255}
                                    name={getItemFieldName("serviceBookingNumber")}
                                    onChange={onChangeSection(standardBooking.id, "standard")}
                                    value={String(standardBooking.serviceBookingNumber ?? "")}
                                    disabled
                                    className="tw-w-64"
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={standardBooking.startDate ?? ""}
                                    changeDate={onDateTextBoxChange(standardBooking.id, "standard", "startDate")}
                                    label="Start Date *"
                                    error={getErrorMessage(getErrorField("standard", standardBooking.id, getItemFieldName("startDate")))
                                        || getErrorMessage(getErrorField("standard", standardBooking.id, "startDatePrecedesEndDate"))}
                                    disabled={standardBooking.isLoaded}
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={standardBooking.endDate}
                                    changeDate={onDateTextBoxChange(standardBooking.id, "standard", "endDate")}
                                    label="End Date *"
                                    error={getErrorMessage(getErrorField("standard", standardBooking.id, getItemFieldName("endDate")))}
                                    disabled={standardBooking.isLoaded}
                                />
                                {standardBooking.isLoaded && ( // Display the status for existing service bookings
                                    <RenderServiceBookingStatus status={standardBooking.status} />
                                )}
                            </div>
                            {standardBooking.items
                                .sort((a, b) => a.position - b.position)
                                .map((row, index) => {
                                    const selectedGroup = groupedProducts?.find(x => x.key === row.category);
                                    const selectedItem = selectedGroup?.items.find(x => row.itemNumber === x.key);

                                    return (
                                        <div className="tw-flex tw-flex-row tw-space-x-5" key={row.id}>
                                            <FormGroup>
                                                {index === 0 && <Label>Category *</Label>}
                                                <ReactSelect
                                                    value={{
                                                        label: groupedProducts.find(x => x.key === row.category)?.value,
                                                        value: row.category,
                                                    }}
                                                    options={groupedProducts
                                                        ?.map(x => ({
                                                            label: x.value,
                                                            value: x.key,
                                                        }))}
                                                    isSearchable
                                                    className="tw-w-64 tw-text-black"
                                                    onChange={onSelect(standardBooking.id, "standard", getItemRowFieldName("category"), row.id)}
                                                />
                                                {getErrorMessage(getErrorField("standard", standardBooking.id, getItemRowFieldName("category"), row.id)) && (
                                                    <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                                                        <span>{getErrorMessage(getErrorField("standard", standardBooking.id, getItemRowFieldName("category"), row.id))}</span>
                                                    </FormFeedback>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                {index === 0 && <Label>Item Name</Label>}
                                                <ReactSelect
                                                    value={{
                                                        label: selectedItem?.value,
                                                        value: selectedItem?.key,
                                                    }}
                                                    options={selectedGroup?.items.map(x => ({
                                                        label: x.value,
                                                        value: x.key,
                                                    }))}
                                                    isSearchable
                                                    className="tw-w-64 tw-text-black"
                                                    onChange={onSelect(standardBooking.id, "standard", getItemRowFieldName("itemNumber"), row.id)}
                                                    noOptionsMessage={() => {
                                                        if (!selectedGroup) {
                                                            return "Please select a category first";
                                                        }

                                                        return "No options";
                                                    }}
                                                />
                                            </FormGroup>
                                            <NumberField
                                                label={index === 0 ? "Unit Price *" : ""}
                                                name="price"
                                                onChange={onChangeRow(standardBooking.id, "standard", row.id)}
                                                value={row.price}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("standard", standardBooking.id, getItemRowFieldName("price"), row.id))}
                                            />
                                            <NumberField
                                                label={index === 0 ? "Qty *" : ""}
                                                name="quantity"
                                                onChange={onChangeRow(standardBooking.id, "standard", row.id)}
                                                value={row.quantity}
                                                min={0}
                                                maxDecimalPlaces={0}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("standard", standardBooking.id, getItemRowFieldName("quantity"), row.id))}
                                            />
                                            <NumberField
                                                label={index === 0 ? "Allocated" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(((row.quantity ?? 0) * (row.price ?? 0)).toFixed(2))}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={index === 0 ? "Remaining" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(row.remaining)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={index === 0 ? "Spent" : ""}
                                                name="spent"
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(row.spent)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                disabled
                                            />
                                            <Button
                                                color="primary"
                                                className={classnames("tw-whitespace-nowrap tw-h-[35px] tw-w-[90px] !tw-mx-3", index === 0 && "tw-mt-8")}
                                                onClick={onMaxAmountClick("standard", standardBooking.id, standardBooking.items, row.id)}
                                                disabled={processingMaxAmount}
                                            >
                                                {processingMaxAmount ? <Spinner className="tw-mx-auto" size="25" /> : "View Max."}
                                            </Button>
                                            <div
                                                className={classnames("alert alert-info tw-w-[410px] !tw-mx-0 !tw-p-[10px]", !row.maxAmountMessage && "tw-hidden", index === 0 && "tw-mt-6")}
                                            >
                                                {row.maxAmountMessage}
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className="tw-flex tw-flex-row tw-space-x-5 tw-mt-10">
                                <Button
                                    color="primary"
                                    outline
                                    onClick={onAddRow(standardBooking.id, "standard")}
                                    className="tw-w-32"
                                    disabled={standardBooking.isLoaded}
                                >
                                    Add Row
                                </Button>
                                <Button
                                    color="primary"
                                    className="tw-w-32"
                                    onClick={onSubmit("standard", standardBooking.id)}
                                    // We want submission if they have not yet synchronised the service bookings with NDIS
                                    disabled={processingSubmission || (standardBooking.isLoaded && !serviceBookingsUpdated)}
                                >
                                    {processingSubmission ? <Spinner className="tw-mx-auto" size="25" /> : "Submit"}
                                </Button>
                                <BackButton displayConfirmationModal className="tw-w-32" />
                            </div>
                            <hr className="tw-my-10 tw-border-2" />
                        </div>
                    ))}
                {/* Category Level Service Bookings */}
                <div className="tw-font-semibold tw-mr-4 !tw-mb-6 tw-text-base">Plan Managed Service Bookings</div>
                {formState.planManaged.length === 0 && (
                    renderNoServiceBookingsMessage("planManaged")
                )}
                {formState.planManaged.sort((a, b) => a.position - b.position)
                    .map((planManagedBooking) => (
                        <div key={planManagedBooking.id}>
                            <div className="tw-flex tw-flex-row tw-space-x-5">
                                <Textbox
                                    label="Service Booking Number"
                                    name={getItemFieldName("serviceBookingNumber")}
                                    onChange={onChangeSection(planManagedBooking.id, "planManaged")}
                                    value={String(planManagedBooking.serviceBookingNumber ?? "")}
                                    maxLength={255}
                                    disabled
                                    className="tw-w-64"
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={planManagedBooking.startDate}
                                    changeDate={onDateTextBoxChange(planManagedBooking.id, "planManaged", "startDate")}
                                    label="Start Date *"
                                    error={getErrorMessage(getErrorField("planManaged", planManagedBooking.id, getItemFieldName("startDate")))
                                        || getErrorMessage(getErrorField("planManaged", planManagedBooking.id, "startDatePrecedesEndDate"))}
                                    disabled={planManagedBooking.isLoaded}
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={planManagedBooking.endDate}
                                    changeDate={onDateTextBoxChange(planManagedBooking.id, "planManaged", "endDate")}
                                    label="End Date *"
                                    error={getErrorMessage(getErrorField("planManaged", planManagedBooking.id, getItemFieldName("endDate")))}
                                    disabled={planManagedBooking.isLoaded}
                                />
                                {planManagedBooking.isLoaded && (
                                    <RenderServiceBookingStatus status={planManagedBooking.status} />
                                )}
                            </div>
                            {planManagedBooking.items
                                .sort((a, b) => a.position - b.position)
                                .map((row, index) => (
                                    <div key={row.id}>
                                        {!isEmptyOrSpaces(row.budgetType) && index !== 0 && ( // We are on a new budget type, we need to display an hr
                                            <hr className="tw-my-8 tw-border" />
                                        )}
                                        <div className="tw-flex tw-flex-row tw-space-x-5">
                                            <Textbox
                                                label={row.budgetType && "Budget Type"}
                                                name="budgetType"
                                                value={row.budgetType}
                                                className={classnames("tw-w-40", isEmptyOrSpaces(row.budgetType) ? "tw-invisible" : "")}
                                                maxLength={255}
                                                onChange={() => {
                                                }} // disabled
                                                disabled
                                            />
                                            <Textbox
                                                label={row.budgetType && "Category"}
                                                disabled // Preset value
                                                name="category"
                                                className="tw-w-64"
                                                value={row.category}
                                                maxLength={255}
                                                onChange={() => {
                                                }}
                                            />
                                            <NumberField
                                                label={row.budgetType ? "Unit Price *" : ""}
                                                name="price"
                                                onChange={onChangeRow(planManagedBooking.id, "planManaged", row.id)}
                                                value={row.price}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("planManaged", planManagedBooking.id, getItemRowFieldName("price"), row.id))}
                                                disabled={planManagedBooking.isLoaded && !row.price}
                                            />
                                            <NumberField
                                                label={row.budgetType ? "Qty *" : ""}
                                                name="quantity"
                                                onChange={onChangeRow(planManagedBooking.id, "planManaged", row.id)}
                                                value={row.quantity}
                                                min={0}
                                                maxDecimalPlaces={0}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("planManaged", planManagedBooking.id, getItemRowFieldName("quantity"), row.id))}
                                                disabled={planManagedBooking.isLoaded && !row.quantity}
                                            />
                                            <NumberField
                                                label={row.budgetType ? "Allocated" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(((row.quantity ?? 0) * (row.price ?? 0)).toFixed(2))}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={row.budgetType ? "Remaining" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(row.remaining)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={row.budgetType ? "Spent" : ""}
                                                name="spent"
                                                onChange={() => {
                                                }} // disabled
                                                value={row.spent && toCurrency(row.spent)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                disabled
                                            />
                                            <Button
                                                color="primary"
                                                className={classnames("tw-whitespace-nowrap tw-h-[35px] tw-w-[90px] !tw-mx-3",
                                                    !!row.budgetType && "tw-mt-8",
                                                    row.displayViewMax ? "" : "!tw-hidden")}
                                                onClick={onMaxAmountClick("planManaged", planManagedBooking.id, planManagedBooking.items, row.id)}
                                                disabled={processingMaxAmount}
                                            >
                                                {processingMaxAmount ? <Spinner className="tw-mx-auto" size="25" /> : "View Max."}
                                            </Button>
                                            {row.maxAmountMessage && (
                                                <div className={classnames("alert alert-info tw-w-[410px] !tw-mx-0 !tw-p-[10px]", index === 0 && "tw-mt-6")}>
                                                    {row.maxAmountMessage}
                                                </div>
                                            )}
                                        </div>
                                        {row.category === "Transport" && (
                                            <div className="tw-flex tw-flex-row tw-items-center">
                                                <div className="tw-flex tw-flex-row tw-space-x-5 tw-bg-gray-200 tw-py-3 tw-rounded-sm tw-flex-grow-0 tw-grow-0">
                                                    <div className="tw-w-40" />
                                                    <div className="tw-font-semibold tw-w-64">
                                                        TOTALS FOR CORE CATEGORY
                                                    </div>
                                                    <div className="tw-w-28" />
                                                    <div className="tw-w-28" />
                                                    <div className="tw-w-36 tw-pl-1">
                                                        $&nbsp;{toCurrency(calculateAllocatedTotal(planManagedBooking.items.filter(x => [1, 2, 3, 4].includes(x.position))))}
                                                    </div>
                                                    <div className="tw-w-36 tw-pl-1">
                                                        $&nbsp;{toCurrency(calculateRemainingTotal(planManagedBooking.items.filter(x => [1, 2, 3, 4].includes(x.position))))}
                                                    </div>
                                                    <div className="tw-w-36 tw-pl-1">
                                                        $&nbsp;{toCurrency(calculateSpentTotal(planManagedBooking.items.filter(x => [1, 2, 3, 4].includes(x.position))))}
                                                    </div>
                                                </div>
                                                <Button
                                                    color="primary"
                                                    className={classnames("tw-whitespace-nowrap tw-h-[35px] tw-w-[90px] !tw-mx-3")}
                                                    onClick={onMaxAmountClick("planManaged",
                                                        planManagedBooking.id,
                                                        planManagedBooking.items.filter(x => [1, 2, 3, 4].includes(x.position)),
                                                        undefined)}
                                                    disabled={processingMaxAmount}
                                                >
                                                    {processingMaxAmount ? <Spinner className="tw-mx-auto" size="25" /> : "View Max."}
                                                </Button>
                                                {formState.coreCategoryMaxAmountMessage && (
                                                    <div className="alert alert-info tw-w-[410px] !tw-mx-0 !tw-p-[10px]">
                                                        {formState.coreCategoryMaxAmountMessage}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {getErrorMessage(getErrorField("planManaged", planManagedBooking.id, "overall-plan-managed-error")) && (
                                <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                                    <span>{getErrorMessage(getErrorField("planManaged", planManagedBooking.id, "overall-plan-managed-error"))}</span>
                                </FormFeedback>
                            )}
                            <div className="tw-flex tw-flex-row tw-space-x-5 tw-mt-10">
                                <Button
                                    color="primary"
                                    className="tw-w-32"
                                    onClick={onSubmit("planManaged", planManagedBooking.id)}
                                    // We want submission if they have not yet synchronised the service bookings with NDIS
                                    disabled={processingSubmission || (planManagedBooking.isLoaded && !serviceBookingsUpdated)}
                                >
                                    {processingSubmission ? <Spinner className="tw-mx-auto" size="25" /> : "Submit"}
                                </Button>
                                <BackButton displayConfirmationModal className="tw-w-32" />
                            </div>
                            <hr className="tw-my-10 tw-border-2" />
                        </div>
                    ))}
                {/* Stated Support Service Booking */}
                <div className="tw-font-semibold tw-mr-4 !tw-mb-6 tw-text-base">Stated Support Service Booking</div>
                {formState.statedSupport.length === 0 && (
                    renderNoServiceBookingsMessage("statedSupport")
                )}
                {formState.statedSupport
                    .sort((a, b) => a.position - b.position)
                    .map((statedSupportBooking) => (
                        <div key={statedSupportBooking.id}>
                            <div className="tw-flex tw-flex-row tw-space-x-5">
                                <Textbox
                                    label="Service Booking Number"
                                    name={getItemFieldName("serviceBookingNumber")}
                                    onChange={onChangeSection(statedSupportBooking.id, "statedSupport")}
                                    value={String(statedSupportBooking.serviceBookingNumber ?? "")}
                                    maxLength={255}
                                    disabled
                                    className="tw-w-64"
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={statedSupportBooking.startDate}
                                    changeDate={onDateTextBoxChange(statedSupportBooking.id, "statedSupport", "startDate")}
                                    label="Start Date *"
                                    error={getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemFieldName("startDate")))
                                        || getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, "startDatePrecedesEndDate"))}
                                    disabled={statedSupportBooking.isLoaded}
                                />
                                <DateTextbox
                                    className="tw-w-64"
                                    currentDate={statedSupportBooking.endDate}
                                    changeDate={onDateTextBoxChange(statedSupportBooking.id, "statedSupport", "endDate")}
                                    label="End Date *"
                                    error={getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemFieldName("endDate")))}
                                    disabled={statedSupportBooking.isLoaded}
                                />
                                {statedSupportBooking.isLoaded && (
                                    <RenderServiceBookingStatus status={statedSupportBooking.status} />
                                )}
                            </div>
                            {statedSupportBooking.items
                                .sort((a, b) => a.position - b.position)
                                .map((row, index) => {
                                    const selectedGroup = groupedProducts?.find(x => x.key === row.category);
                                    const selectedItem = selectedGroup?.items.find(x => row.itemNumber === x.key);

                                    return (
                                        <div className="tw-flex tw-flex-row tw-space-x-5" key={row.id}>
                                            <FormGroup>
                                                {index === 0 && <Label>Category *</Label>}
                                                <ReactSelect
                                                    value={{
                                                        label: groupedProducts.find(x => x.key === row.category)?.value,
                                                        value: row.category,
                                                    }}
                                                    options={groupedProducts
                                                        ?.map(x => ({
                                                            label: x.value,
                                                            value: x.key,
                                                        }))}
                                                    isSearchable
                                                    className="tw-w-64 tw-text-black"
                                                    onChange={onSelect(statedSupportBooking.id, "statedSupport", getItemRowFieldName("category"), row.id)}
                                                />
                                                {getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("category"), row.id))
                                                    && (
                                                        <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                                                            <span>
                                                                {getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("category"), row.id))}
                                                            </span>
                                                        </FormFeedback>
                                                    )}
                                            </FormGroup>
                                            <FormGroup>
                                                {index === 0 && <Label>Item Name *</Label>}
                                                <ReactSelect
                                                    value={{
                                                        label: selectedItem?.value,
                                                        value: selectedItem?.key,
                                                    }}
                                                    options={selectedGroup?.items.map(x => ({
                                                        label: x.value,
                                                        value: x.key,
                                                    }))}
                                                    isSearchable
                                                    className="tw-w-64 tw-text-black"
                                                    onChange={onSelect(statedSupportBooking.id, "statedSupport", getItemRowFieldName("itemNumber"), row.id)}
                                                    noOptionsMessage={() => {
                                                        if (!selectedGroup) {
                                                            return "Please select a category first";
                                                        }

                                                        return "No options";
                                                    }}
                                                />
                                                {getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("itemNumber"), row.id))
                                                    && (
                                                        <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                                                            <span>
                                                                {getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("itemNumber"), row.id))}
                                                            </span>
                                                        </FormFeedback>
                                                    )}
                                            </FormGroup>
                                            <NumberField
                                                label={index === 0 ? "Unit Price *" : ""}
                                                name="price"
                                                onChange={onChangeRow(statedSupportBooking.id, "statedSupport", row.id)}
                                                value={row.price}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("price"), row.id))}
                                            />
                                            <NumberField
                                                label={index === 0 ? "Qty *" : ""}
                                                name="quantity"
                                                onChange={onChangeRow(statedSupportBooking.id, "statedSupport", row.id)}
                                                value={row.quantity}
                                                min={0}
                                                maxDecimalPlaces={0}
                                                className="tw-w-28"
                                                error={getErrorMessage(getErrorField("statedSupport", statedSupportBooking.id, getItemRowFieldName("quantity"), row.id))}
                                            />
                                            <NumberField
                                                label={index === 0 ? "Allocated" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(((row.quantity ?? 0) * (row.price ?? 0)).toFixed(2))}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={index === 0 ? "Remaining" : ""}
                                                disabled
                                                onChange={() => {
                                                }} // disabled
                                                value={toCurrency(row.remaining)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-36"
                                            />
                                            <NumberField
                                                label={index === 0 ? "Spent" : ""}
                                                name="spent"
                                                onChange={() => {
                                                }} // disabled
                                                value={row.spent && toCurrency(row.spent)}
                                                prefixChar="$"
                                                min={0}
                                                maxDecimalPlaces={2}
                                                className="tw-w-28"
                                                disabled
                                            />
                                            <Button
                                                color="primary"
                                                className={classnames("tw-whitespace-nowrap tw-h-[35px] tw-w-[90px] !tw-mx-3", index === 0 && "tw-mt-8")}
                                                onClick={onMaxAmountClick("statedSupport", statedSupportBooking.id, statedSupportBooking.items, row.id)}
                                                disabled={processingMaxAmount}
                                            >
                                                {processingMaxAmount ? <Spinner className="tw-mx-auto" size="25" /> : "View Max."}
                                            </Button>
                                            <div
                                                className={classnames("alert alert-info tw-w-[410px] !tw-mx-0 !tw-p-[10px]", !row.maxAmountMessage && "tw-hidden", index === 0 && "tw-mt-6")}
                                            >
                                                {row.maxAmountMessage}
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className="tw-flex tw-flex-row tw-space-x-5 tw-mt-10">
                                <Button
                                    color="primary"
                                    outline
                                    onClick={onAddRow(statedSupportBooking.id, "statedSupport")}
                                    className="tw-w-32"
                                    disabled={statedSupportBooking.isLoaded}
                                >
                                    Add Row
                                </Button>
                                <Button
                                    color="primary"
                                    className="tw-w-32"
                                    onClick={onSubmit("statedSupport", statedSupportBooking.id)}
                                    // We want submission if they have not yet synchronised the service bookings with NDIS
                                    disabled={processingSubmission || (statedSupportBooking.isLoaded && !serviceBookingsUpdated)}
                                >
                                    {processingSubmission ? <Spinner className="tw-mx-auto" size="25" /> : "Submit"}
                                </Button>
                                <BackButton displayConfirmationModal className="tw-w-32" />
                            </div>
                            <hr className="tw-my-10 tw-border-2" />
                        </div>
                    ))}
            </div>
        </FlexPlanBasicWrapper>
    );
};

export { ManageServiceBookings };
