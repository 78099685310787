/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import classnames from "classnames";
import { Button } from "reactstrap";

import { theme } from "./theme";
import styles from "./styles.module.scss";

import { useSuggestions } from "@/Pages/Marketplace/MarketplacePage/useSuggestions";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { ProductSortOptions } from "@/Apis/Products/ProductType";
import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { FeaturedCategory } from "@/Apis/Configuration";
import { SearchState } from "@/Pages/Marketplace/MarketplacePage/useProducts";

interface Props {
    onSearch(newSearchState: SearchState): void;
    onSearchTermChange(searchTerm: string): void;
    searchState: SearchState;
    selectRef
}

const MarketplaceSearchInput = ({ onSearch, onSearchTermChange, searchState, selectRef }: Props) => {
    const { featuredCategories, featuredSuppliers } = useConfigurationContext();
    const [prevKeyCode, setPrevKeyCode] = useState(0);
    const { getSuggestions, loading } = useSuggestions();

    const loadOptions = inputValue => getSuggestions(inputValue);
    const onInputChange = (value, { action }) => {
        if (action === "input-change") {
            onSearchTermChange(value);
        }
    };

    const onKeyDown = (e) => {
        if (prevKeyCode !== 38 && prevKeyCode !== 40) {
            if (e.keyCode === 13) {
                e.preventDefault();
                if (selectRef.current) {
                    selectRef.current.blur();
                }
                onSearch({ ...searchState, pageNumber: 1 });
            }
        }
        setPrevKeyCode(e.keyCode);
    };

    const onChange = (option) => {
        if (option?.label) {
            onSearch({ ...searchState, searchTerm: option.label, pageNumber: 1 });
        }
    };

    const onFocus = () => {
        selectRef.current.select.state.inputValue = searchState.searchTerm;
        selectRef.current.select.state.value = null;
    };

    const onClear = () => {
        onSearch({ ...searchState, searchTerm: "", pageNumber: 1 });
    };

    const onFeaturedCategoryClick = (category: FeaturedCategory) => () => {
        selectRef.current.blur();
        onSearch({
            searchTerm: "",
            supplierSelections: [],
            isHexagonProduct: false,
            sortOrder: ProductSortOptions.Featured,
            categorySelections: [{ display: category.name, value: category.id, checked: true }],
            pageNumber: 1,
        });
    };

    const onFeaturedSupplierClick = (supplier: MiniSiteType) => () => {
        selectRef.current.blur();
        onSearch({
            searchTerm: "",
            categorySelections: [],
            isHexagonProduct: false,
            sortOrder: ProductSortOptions.Featured,
            supplierSelections: [{ display: supplier.name as string, value: supplier.id as string, checked: true }],
            pageNumber: 1,
        });
    };

    const MenuList = (props: any) => (<components.MenuList className={styles.selectContainer} {...props}>{props.children}</components.MenuList>);
    const ClearIndicator = () => (<></>);

    const noOptionsMessage = () => (
        <div className="text-left">
            <small>RECOMMENDED KEYWORDS</small>
            <div className="mb-2">
                {featuredCategories.map(x => (
                    <Button
                        key={x.id}
                        onClick={onFeaturedCategoryClick(x)}
                        color="link"
                        className="p-0 font-weight-bold mr-2"
                    >{x.name}
                    </Button>))}
            </div>
            <small>RECOMMENDED SUPPLIERS</small>
            <div>
                {featuredSuppliers.map(x => (
                    <Button
                        key={x.id}
                        onClick={onFeaturedSupplierClick(x)}
                        color="link"
                        className="p-0 font-weight-bold mr-2"
                    >{x.name}
                    </Button>))}
            </div>
        </div>
    );

    return (
        <>
            <div className={styles.searchIcon}>
                <i className="fas fa-search h5" />
            </div>
            <div className="w-100">
                <AsyncSelect
                    ref={selectRef}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    isLoading={loading}
                    isClearable
                    placeholder="Search for service or products..."
                    styles={theme}
                    noOptionsMessage={noOptionsMessage}
                    onInputChange={onInputChange}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    components={{ MenuList, ClearIndicator }}
                    blurInputOnSelect
                    value={{ label: searchState.searchTerm, value: searchState.searchTerm }}
                    onFocus={onFocus}
                />
            </div>
            <button
                type="button"
                onClick={onClear}
                className={classnames(styles.clearIndicator, "bg-transparent text-dark p-0 border-0", searchState.searchTerm
                    ? styles.clearIndicatorShow
                    : styles.clearIndicatorHide)}
            >
                <i className="fas fa-times h5" />
            </button>
        </>);
};

export { MarketplaceSearchInput };
