import React from "react";
import { Button } from "reactstrap";

import { VariationState } from "@/Components/ConfigureProductRow/ColumnTypes";

interface Props {
    variationState: VariationState;
    productName: string;
}

const DownloadListedCsvTemplate = ({ variationState, productName }: Props) => {
    const getTemplate = () => {
        const columnHeaders = variationState.columns
            .filter(x => x.checked)
            .map(x => {
                if (x.component === "AddressLookUp") {
                    return [
                        `${x.name}__addressLine1`,
                        `${x.name}__addressLine2`,
                        `${x.name}__city`,
                        `${x.name}__postCode`,
                        `${x.name}__premise`,
                    ];
                }

                return [x.name];
            })
            .flat(1);

        return `data:text/csv;charset=utf-8,${columnHeaders.join(",")}`;
    };

    const downloadTemplate = () => {
        const encodedUri = encodeURI(getTemplate());
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${productName}.csv`);
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Button className="btn-tertiary fml-auto mt-4 mr-2" onClick={downloadTemplate}>
            Download CSV template
        </Button>
    );
};

export default DownloadListedCsvTemplate;
