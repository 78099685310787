import { useState } from "react";
import { useLocation } from "react-router";

import { FilterOption } from "@/Components/MultiSelectFilter";
import { useAPI } from "@/Apis/useAPI";
import { ProductSortOptions, ProductType, FilterType, ProductResponseType } from "@/Apis/Products/ProductType";
import urlHelper from "@/Utils/urlHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

export interface SearchState {
    categorySelections: FilterOption[];
    supplierSelections: FilterOption[];
    isHexagonProduct: boolean;
    sortOrder: ProductSortOptions;
    searchTerm: string;
    pageNumber: number;
    loadFilters?: boolean;
    basketId?: string,
    pageLength?: number,
    isPurchaseUpsell?: boolean;
    userTypeId?: string;
}

const useProducts = () => {
    const pageLengthDefault = 20;
    const location = useLocation();
    const [lastFetch, setLastFetch] = useState(0);
    const [products, setProducts] = useState([] as ProductType[]);
    const [categories, setCategories] = useState<FilterType[]>([]);
    const [suppliers, setSuppliers] = useState<FilterType[]>([]);
    const [noResults, setNoResults] = useState(false);
    const [showNoResults, setShowNoResults] = useState(true);
    const { loading, post } = useAPI({ handle500WithRedirect: true });
    const { setPopupErrorMessage } = useToastMessageContext();

    const getCategorySelections = (allCategories: FilterType[]): FilterOption[] => {
        const categoryParams = urlHelper.getUrlParam(location.search, "categorySelections") ?? "";
        if (categoryParams === "") return [];
        const categoryArray = categoryParams.split(",");
        const result = allCategories.filter(x => categoryArray.includes(x.id));
        return result.map(x => ({ value: x.id, display: x.name, checked: true } as FilterOption));
    };

    const getSupplierSelections = (allSuppliers: FilterType[]): FilterOption[] => {
        const supplierParams = urlHelper.getUrlParam(location.search, "supplierSelections") ?? "";
        if (supplierParams === "") return [];
        const supplierArray = supplierParams.split(",");
        const result = allSuppliers.filter(x => supplierArray.includes(x.id));
        return result.map(x => ({ value: x.id, display: x.name, checked: true } as FilterOption));
    };

    const featuredProductsDefault: SearchState = {
        searchTerm: "",
        categorySelections: [],
        supplierSelections: [],
        isHexagonProduct: urlHelper.getUrlParam(location.search, "isHexagonProduct")?.toLowerCase() === "true",
        sortOrder: ProductSortOptions.Featured,
        pageNumber: 1,
        pageLength: pageLengthDefault,
    };

    const [searchState, setSearchState] = useState(featuredProductsDefault);
    const toggleNoResults = () => setShowNoResults(!showNoResults);

    const searchProducts = (newSearchState: SearchState, noResults: boolean = false) => {
        setSearchState({ ...newSearchState, loadFilters: false });
        if (newSearchState.pageNumber === 1) {
            setProducts([]); // required so that we reset the InfiniteScroll before re-searching, so we see the spinner
        }
        post<ProductResponseType>("products/search", {
            ...newSearchState,
            pageLength: newSearchState.pageLength ?? pageLengthDefault,
            categoryIdsFilter: newSearchState.categorySelections.length === categories.length ? [] : newSearchState.categorySelections.map((x => x.value)),
            supplierIdsFilter: newSearchState.supplierSelections.length === suppliers.length ? [] : newSearchState.supplierSelections.map((x => x.value)),
            isHexagonProduct: newSearchState.isHexagonProduct,
        }).then(response => {
            setLastFetch(response.products.length);
            setProducts((prevProducts) => (newSearchState.pageNumber === 1 ? response.products : prevProducts.concat(response.products)));
            if (response.products.length === 0) {
                setShowNoResults(true);
                if (typeof newSearchState.isPurchaseUpsell !== "boolean") {
                    searchProducts({ ...featuredProductsDefault, pageNumber: newSearchState.pageNumber }, true); // Show featured products if there are 0 results
                    setSearchState(() => newSearchState); // Retain users search
                }
            } else {
                setNoResults(noResults);
            }
            const newSearchState2 = newSearchState;
            if (newSearchState2.loadFilters) {
                setCategories(response.categories);
                setSuppliers(response.suppliers);
                if (newSearchState2.supplierSelections.length === 0 && newSearchState2.categorySelections.length === 0) {
                    const categories = getCategorySelections(response.categories);
                    const suppliers = getSupplierSelections(response.suppliers);
                    if (suppliers.length > 0 || categories.length > 0) {
                        newSearchState2.categorySelections = categories;
                        newSearchState2.supplierSelections = suppliers;
                        setSearchState(() => newSearchState2);
                        searchProducts(newSearchState2, false);
                    }
                }
            }
        })
            .catch(error => setPopupErrorMessage(error, true));
    };

    const setSearchTerm = (newSearchTerm) => setSearchState({ ...searchState, searchTerm: newSearchTerm });

    return {
        loading,
        searchProducts,
        products,
        noResults: noResults && showNoResults,
        toggleNoResults,
        searchState,
        setSearchTerm,
        hasMoreProducts: lastFetch >= (searchState?.pageLength ?? pageLengthDefault),
        categories,
        suppliers,
    };
};

export { useProducts };
