import React from "react";
import classnames from "classnames";

import AppButtonLink from "@/Components/AppButtonLink";

interface Props {
    to?: string, // Use if this needs to be a link, link will take precedence over onClick
    onClick?: () => void,
    children: React.ReactNode | string,
    disabled?: boolean,
    className?: string,
}

const defaultButtonClasses = "!tw-bg-brand-accent-yellow !tw-text-brand-primary !tw-border-none tw-w-full md:!tw-w-1/2 \n"
    + "lg:!tw-text-base !tw-whitespace-nowrap !tw-py-2.5 !tw-font-semibold tw-text-center tw-rounded hover:tw-underline";

const PostPurchaseYellowAccentButton = ({ to, onClick, children, className, disabled = false }: Props) => (
    <AppButtonLink
        to={to}
        onClick={onClick}
        disabled={disabled}
        className={classnames(defaultButtonClasses, className)}
    >
        {children}
    </AppButtonLink>
);

export default PostPurchaseYellowAccentButton;
