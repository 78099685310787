import React, { useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { useLocation, useParams } from "react-router";
import { isNumeric } from "rxjs/internal-compatibility";

import { MoreInfo } from "../ServiceModal/MoreInfo";
import { Forms } from "../ServiceModal/Forms";

import { General } from "./General";
import styles from "./styles.module.scss";

import { AddProductBreadcrumbs } from "@/Components/AddProductBreadcrumbs";
import { ProductVariants } from "@/Utils/ProductVariants";
import sortingUtil from "@/Utils/sortingUtil";

const navigation = [
    {
        current: "general",
        next: "more-info",
    },
    {
        current: "more-info",
        next: "forms",
        previous: "general",
    },
    {
        current: "forms",
        previous: "more-info",
    },
];

const EditEnquiryFormModal = ({ metaData, selectedEnquiryForm, addEnquiry, deleteEnquiry }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const routeParams = useParams();
    const allowFlexiblePayments = useSelector(state => state.user.allowFlexiblePayments);
    const onPush = path => () => dispatch(push(path));

    const enquiryFormValidator = (enquiry) => {
        const errors = {};
        let canMoveToNextScreen = false;
        let hasEnoughToSaveEnquiry = false;

        if (!!enquiry.categoryId && !!enquiry.subCategoryName && !!enquiry.name) {
            if (enquiry.description && enquiry.image) {
                canMoveToNextScreen = true;
                hasEnoughToSaveEnquiry = true;
            }
        }
        const price = enquiry.startingPrice.toString();
        const stringAfterDecimalPoint = (value) => (value.indexOf(".") > -1 ? value.substring(value.indexOf(".") + 1, value.length) : "");
        const afterDecimalPoint = stringAfterDecimalPoint(price);
        if (!isNumeric(price) || Number(price) < 0 || afterDecimalPoint.length > 2 || afterDecimalPoint.indexOf(".") > -1) {
            errors.startingPrice = "Price is invalid";
            canMoveToNextScreen = false;
            hasEnoughToSaveEnquiry = false;
        }
        return { hasEnoughToSaveEnquiry, canMoveToNextScreen, errors };
    };

    const navigationItem = navigation.find(x => location.pathname.indexOf(x.current) >= 0);
    const isEditingEnquiry = routeParams.enquiryFormId !== "new";
    const [enquiry, editEnquiry] = useState({ ...selectedEnquiryForm, startingPrice: parseFloat(selectedEnquiryForm.startingPrice).toFixed(2) });
    const [savingTerms, setSavingTerms] = useState(false);
    const [enquiryValidation, setEnquiryValidation] = useState(enquiryFormValidator(selectedEnquiryForm));
    const [navigationValid, setNavigationValid] = useState({});

    // Map the prop sub categories and any state values into the object structure required by react-select
    const subCategories = [...(metaData.subCategories[enquiry.categoryId] || [])
        .sort((x, y) => sortingUtil.sort(x, y, "name", true))
        .map(x => ({ value: x.id, label: x.name }))];

    useEffect(() => {
        if (isEditingEnquiry) {
            const navValid = { ...navigationValid };
            navigation.forEach(x => {
                if (navValid[x.current] === undefined) {
                    navValid[x.current] = true;
                }
            });
            navValid[navigationItem.current] = enquiryValidation.canMoveToNextScreen;
            setNavigationValid(navValid);
        } else {
            setNavigationValid({ ...navigationValid, [navigationItem.current]: enquiryValidation.canMoveToNextScreen });
        }
    }, [navigationItem, enquiryValidation]);

    const onChange = key => (e) => {
        const value = e.target.value;
        const updatedEnquiry = { ...enquiry };

        switch (key) {
            case "selectedFields":
                updatedEnquiry.serviceFields = value;
                break;
            default:
                updatedEnquiry[key] = value;
        }
        setEnquiryValidation(enquiryFormValidator(updatedEnquiry));
        editEnquiry(updatedEnquiry);
    };

    const onChangeMultiple = (partialEnquiry) => {
        const updatedEnquiry = { ...enquiry, ...partialEnquiry };

        setEnquiryValidation(enquiryFormValidator(updatedEnquiry));
        editEnquiry(updatedEnquiry);
    };

    const onImageDrop = (newImages) => {
        const images = [...enquiry.images || [], ...newImages];
        editEnquiry({ ...enquiry, images });
    };

    const onDeleteEnquiry = () => {
        deleteEnquiry(enquiry.id);
    };

    const onAddEnquiry = () => {
        addEnquiry({ ...enquiry, productVariant: ProductVariants.enquiryFormProduct });
    };

    const checkValidationOnAddEnquiry = () => {
        onAddEnquiry();
    };

    return (
        <Modal isOpen centered size="xl" contentClassName="bg-white rounded-0">
            <ModalBody className={classnames(styles.container, "d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative")} data-testid="enquiry-form-modal">
                <h3 className="font-weight-bold mb-4">
                    {isEditingEnquiry ? "Update Enquiry Form" : "Add an Enquiry Form"}
                </h3>

                <AddProductBreadcrumbs navigation={navigation} navigationValid={navigationValid} />

                <div className={classnames("overflow-y-auto overflow-x-hidden", styles.content)}>
                    <Route
                        render={({ match: { url } }) => (
                            <Switch>
                                <Route
                                    path={`${url}/forms`}
                                    render={() => (
                                        <Forms
                                            fields={metaData.fields}
                                            serviceFields={enquiry.serviceFields}
                                            onChange={onChange}
                                            errors={enquiryValidation.errors}
                                            isEnquiry
                                        />
                                    )}

                                />
                                <Route
                                    path={`${url}/more-info`}
                                    render={() => (
                                        <MoreInfo
                                            moreInformation={enquiry.moreInformation}
                                            images={enquiry.images}
                                            onChange={onChange}
                                            onImageDrop={onImageDrop}
                                        />
                                    )}
                                />
                                <Route
                                    path={[`${url}/general`, url]}
                                    render={() => (
                                        <General
                                            enquiryId={enquiry.id}
                                            categories={metaData.categories.filter(x => x.name !== "Enquiry Form")}
                                            subCategories={subCategories}
                                            categoryId={enquiry.categoryId}
                                            subCategoryName={enquiry.subCategoryName}
                                            name={enquiry.name}
                                            startingPrice={enquiry.startingPrice}
                                            isOffsitePayment={enquiry.isOffsitePayment}
                                            allowFlexiblePayments={allowFlexiblePayments}
                                            description={enquiry.description}
                                            image={enquiry.image}
                                            onChange={onChange}
                                            onChangeMultiple={onChangeMultiple}
                                            errors={enquiryValidation.errors}
                                            termsDocument={enquiry.termsDocument}
                                            termsDocumentSaving={setSavingTerms}
                                        />
                                    )}
                                />
                            </Switch>
                        )}
                    />
                </div>

                <div className="d-flex justify-content-between">
                    <div className="d-flex mt-4 pt-3 justify-content-center">
                        <Button color="secondary" disabled={!navigationItem.previous} onClick={onPush(navigationItem.previous)} className="mr-2">Back</Button>
                        <Button
                            color="primary"
                            disabled={!navigationItem.next || !enquiryValidation.canMoveToNextScreen}
                            title={(!navigationItem.next || !enquiryValidation.canMoveToNextScreen) ? "Please fill out all the required fields on the general page" : ""}
                            onClick={onPush(navigationItem.next)}
                        >
                            Next
                        </Button>
                    </div>
                    <div className="d-flex mt-4 pt-3 justify-content-center">
                        {isEditingEnquiry && <Button color="danger" data-testid="delete-enquiry-form" onClick={onDeleteEnquiry}>Delete Enquiry Form</Button>}
                        <Button color="secondary" className="mx-2" data-testid="cancel-submit-enquiry" onClick={onPush("/mini-site")}>Cancel</Button>
                        <Button
                            className={!enquiryValidation.hasEnoughToSaveEnquiry ? "btn-secondary" : "btn-tertiary"}
                            disabled={!enquiryValidation.hasEnoughToSaveEnquiry || savingTerms}
                            onClick={checkValidationOnAddEnquiry}
                            data-testid="submit-enquiry"
                        >
                            {isEditingEnquiry ? "Update Enquiry Form" : "Add Enquiry Form"}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

EditEnquiryFormModal.propTypes = {
    metaData: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })).isRequired,
        subCategories: PropTypes.PropTypes.shape({}).isRequired,
        fields: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayText: PropTypes.string.isRequired,
            ordinal: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    selectedEnquiryForm: PropTypes.shape({
        id: PropTypes.string.isRequired,
        categoryId: PropTypes.string,
        subCategoryName: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        startingPrice: PropTypes.number.isRequired,
        isOffsitePayment: PropTypes.bool,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    addEnquiry: PropTypes.func.isRequired,
    deleteEnquiry: PropTypes.func.isRequired,
};

export { EditEnquiryFormModal };
