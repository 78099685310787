import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody } from "reactstrap";

const LessDetailedProductModal = props => (
    <Modal isOpen centered size="md">
        <ModalBody>
            <p className="p-3 text-center">
                <i className="fa fa-lg fa-exclamation-circle text-warning mr-2" />
                Finishing here will add a less detailed product.
                Are you happy to go ahead?
            </p>

            <div className="d-flex m-3 align-items-center justify-content-center">
                <Button color="secondary" onClick={props.onClose} className="mr-2">Cancel</Button>
                <Button color="primary" onClick={props.onAddService} data-testid="yes-submit-service">Yes Proceed</Button>
            </div>
        </ModalBody>
    </Modal>
);

LessDetailedProductModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAddService: PropTypes.func.isRequired,
};

export { LessDetailedProductModal };
