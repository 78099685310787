/* eslint-disable import/extensions */
import React from "react";

import BuildAPackageBackground from "@/Assets/Images/PostPurchase/build-a-package.png";
import BuildAPackageBackgroundMobile from "@/Assets/Images/PostPurchase/mobile/build-a-package.png";
import BuildAPackageIcon from "@/Assets/Icons/Coloured/build-a-package.svg";
import BuildPackageIcon from "@/Assets/Icons/Coloured/build-package.svg";
import PackageCodeIcon from "@/Assets/Icons/Coloured/package-code.svg";
import PackageCommissionIcon from "@/Assets/Icons/Coloured/package-commission.svg";
import BscBox from "@/Assets/Icons/Solid/box.svg";
import BscLogo from "@/Assets/Icons/Solid/logo.svg";
import PostPurchaseStep from "@/Components/PostPurchase/PostPurchaseStep";
import PostPurchaseLinkTab from "@/Components/PostPurchase/PostPurchaseLinkTab";
import StepBuilderBanner from "@/Pages/PostPurchase/StepBuilderBanner";

const BuildAPackage = () => (
    <StepBuilderBanner
        title="Build a package with us and earn a commission with every purchase!"
        stepBuilders={
            <>
                <PostPurchaseStep
                    title="1 Build"
                    icon={<BuildPackageIcon />}
                    text="Select the products and services that will make up your package. These can come from multiple or individual suppliers."
                />
                <PostPurchaseStep
                    title="2 Send"
                    icon={<PackageCodeIcon />}
                    text="Confirm your selection and generate your unique code which will link your referrals to the package you’ve created."
                />
                <PostPurchaseStep
                    title="3 Purchase"
                    icon={<BuildAPackageIcon />}
                    text="Your referrals may find the items you put together suit their needs, that they purchase the whole package."
                />
            </>
        }
        mobileImagePath={BuildAPackageBackgroundMobile}
        desktopImagePath={BuildAPackageBackground}
        childrenClassName="tw-flex tw-flex-col tw-justify-center tw-space-y-2 lg:tw-space-y-4"
        postPurchaseLinkTab={<PostPurchaseLinkTab
            url="/packages"
            text="Build a Package"
            className="tw-absolute tw-right-0 tw-bottom-0 lg:tw-rounded-br-lg"
        />}
    >
        <>
            <div
                className="tw-flex tw-flex-col tw-items-center sm:tw-items-start sm:tw-flex-row tw-mt-4 md:tw-mt-0 tw-justify-center"
            >
                <div className="tw-w-32 md:tw-pt-5 tw-pl-5 lg:tw-pl-0"><PackageCommissionIcon /></div>
                <div className="md:tw-mt-8 tw-text-center sm:tw-text-start lg:tw-text-center">
                    <div className="tw-text-brand-accent-yellow tw-text-lg lg:tw-text-2xl">4 Payday</div>
                    <div className="tw-text-brand-accent-yellow tw-w-52 sm:tw-w-72 tw-text-sm lg:tw-text-base">
                        Earn a commission from every referral purchase and everyone wins! Check out our sample
                        scheme below:
                    </div>
                </div>
            </div>
            <div className="tw-relative tw-h-48 tw-w-2/3 tw-mx-auto">
                {/* Left vertical line */}
                <div
                    className="tw-h-5 tw-bg-white tw-border-l-2 tw-absolute tw-left-0 tw-top-9"
                />
                {/* Main horizontal line */}
                <hr
                    className="tw-w-full tw-h-[1px] tw-bg-white tw-absolute tw-bottom-10 lg:tw-left-0 lg:tw-right-0 lg:tw-mx-auto tw-top-5"
                />
                {/* Center vertical line */}
                <div
                    className="tw-h-10 tw-bg-white tw-border-l-2 tw-absolute tw-bottom-[37px] tw-left-1/2 tw-top-5"
                />
                {/* Right vertical line */}
                <div
                    className="tw-h-5 tw-bg-white tw-border-l-2 tw-absolute tw-top-9 tw-right-0"
                />
                <div
                    className="tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-w-full -tw-top-1 tw-flex tw-space-x-2 tw-justify-center"
                >
                    <i className="fa fa-solid fa-box fa-2x tw-text-brand-accent-yellow tw-ml-[5px]" />
                    <div className="tw-text-white tw-text-sm lg:tw-text-base">
                        Package cost at <span className="tw-text-brand-accent-yellow tw-text-sm lg:tw-text-base">£10,000</span>
                    </div>
                </div>
                <div
                    className="tw-w-24 tw-text-white tw-top-[58px] -tw-left-[18px] tw-absolute tw-flex tw-flex-col"
                >
                    <div className="tw-w-10">
                        <BscBox />
                    </div>
                    <p className="tw-absolute -tw-left-[32px] tw-top-10 tw-text-center tw-w-24">
                        <span className="tw-font-semibold tw-block tw-text-sm lg:tw-text-base">
                            £ 8,100
                        </span> <span className="tw-text-sm lg:tw-text-base">earned by the supplier</span>
                    </p>
                </div>
                <div className="tw-text-white tw-w-24 tw-top-16 tw-left-1/2 tw-absolute">
                    <div className="tw-w-10 -tw-left-[19px] tw-absolute">
                        <BscLogo />
                    </div>
                    <p className="tw-absolute tw-top-10 -tw-left-[60px] tw-text-center">
                        <span className="tw-font-semibold tw-block tw-text-sm lg:tw-text-base">
                            £ 1,600
                        </span>  <span className="tw-text-sm lg:tw-text-base">earned by BSC</span>
                    </p>
                </div>
                <div className="tw-text-white tw-w-24 tw-top-16 -tw-right-[50px] tw-absolute">
                    <i className="fas fa-smile-beam tw-text-brand-accent-yellow tw-absolute fa-2x tw-left-[33px] tw-top-[0px]" />
                    <p className="tw-absolute tw-top-10 tw-text-center tw-text-brand-accent-yellow">
                        <span className="tw-font-semibold tw-block tw-text-sm lg:tw-text-base">
                            £300
                        </span> <span className="tw-text-sm lg:tw-text-base">earned by YOU from commissions</span>
                    </p>
                </div>
            </div>
        </>
    </StepBuilderBanner>
);

export default BuildAPackage;
