import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import React, { ChangeEvent } from "react";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { getProfileFieldName, ProfileTabState, ProfileSections } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { exceedsMaxStringLength, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { isValidABN } from "@/FlexPlan/Utils/validtor";

interface CompanySectionProps {
    state: ProfileTabState;
    errors;
    handleErrorStatusChange(hasError: boolean, field: string): void
    handleFieldChange(e: ChangeEvent<HTMLInputElement>): void
    handleSelectEditSection(section: ProfileSections): () => void;
    handleSaveClick(): void;
}

const CompanySection = ({ handleFieldChange, state, handleSelectEditSection, handleSaveClick, errors, handleErrorStatusChange }: CompanySectionProps) => (
    <>
        <Row>
            <Col xs="12" className="d-flex justify-content-between mb-3">
                <h4 className="font-weight-bold">Company Information</h4>
                {!state.selectedSections?.includes("CompanySection")
                        && <Button onClick={handleSelectEditSection("CompanySection")} className="px-3" color="primary">Edit</Button>}
            </Col>
        </Row>
        {!state.selectedSections?.includes("CompanySection") && (
            <>
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">Company Name</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.companyName}</Label>
                </FormGroup>
                <hr />
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">ABN</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.abn}</Label>
                </FormGroup>
            </>
        )}
        {state.selectedSections?.includes("CompanySection") && (
            <>
                <FloatLabelTextbox
                    label="Company Name *"
                    placeholder="Company Name"
                    name={getProfileFieldName("companyName")}
                    onChange={handleFieldChange}
                    error={isEmptyOrSpaces(state.planManagerProfile?.companyName) || exceedsMaxStringLength(state.planManagerProfile?.companyName)
                        ? "Please enter a Company Name. Must be less than 255 characters."
                        : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.companyName}
                />
                <FloatLabelTextbox
                    label="ABN*"
                    placeholder="ABN"
                    name={getProfileFieldName("abn")}
                    onChange={handleFieldChange}
                    error={!isValidABN(state.planManagerProfile?.abn) ? "Please enter a valid 11 digit ABN." : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.abn}
                />
                <div className="d-flex justify-content-end mt-3">
                    <ActionButtons onCancelClick={handleSelectEditSection("CompanySection")} onSaveClick={handleSaveClick} disabled={errors.length > 0} />
                </div>
            </>)}
    </>);

export { CompanySection };
