import React, { ChangeEvent } from "react";
import { Button, Input } from "reactstrap";

import { CategorySelect } from "../CategorySelect";
import { AppliedTo } from "../AppliedTo";

import styles from "./styles.module.scss";

import { CategoryType } from "@/Apis/Suppliers/CategoryType";
import { SubCategoryType } from "@/Apis/Suppliers/OwnedSubCategoriesType";

interface Props {
    categories: Array<CategoryType>;
    subCategory: SubCategoryType;
    onOwnedSubCategoryChange(id: string) : (e: ChangeEvent<HTMLInputElement>) => void;
    onOwnedParentCategoryChange(id: string) : (parentCategoryId: string) => void;
    saveOwnedSubCategory(id: string) : () => void;
    deleteOwnedSubCategory(id: string) : () => void;
    isLoadingCategories: boolean;
}

const Row = ({ subCategory, categories, onOwnedSubCategoryChange, isLoadingCategories, onOwnedParentCategoryChange, saveOwnedSubCategory, deleteOwnedSubCategory }: Props) => (
    <tr>
        <td className={styles.column}>
            <CategorySelect
                selectedCategoryId={categories.find(f => f.id === subCategory.parentCategoryId)?.id ?? ""}
                categories={categories}
                isLoading={isLoadingCategories}
                onCategoryChange={onOwnedParentCategoryChange(subCategory.id)}
            />
        </td>
        <td className={styles.column}>
            <AppliedTo appliedTo={subCategory.appliedTo} notAppliedMessage="Not applied to any services" />
        </td>
        <td className={styles.column}>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <Input type="text" onChange={onOwnedSubCategoryChange(subCategory.id)} value={subCategory.name ?? ""} invalid={subCategory.name === ""} />
                </div>
                <div className="ml-auto">
                    <Button
                        color="primary"
                        className="ml-3"
                        disabled={subCategory.name === "" || subCategory.isUpdating || subCategory.isDeleting}
                        onClick={saveOwnedSubCategory(subCategory.id)}
                        data-testid="updateSubCategory"
                    >
                        Update
                        {subCategory.isUpdating
                            ? (<i className="fas fa-circle-notch ml-2 fa-spin text-success" />)
                            : (<i className="fas fa-check ml-2 text-success" />)}
                    </Button>
                    <Button
                        color="danger"
                        className="ml-3"
                        outline
                        disabled={subCategory.isUpdating || subCategory.isDeleting}
                        onClick={deleteOwnedSubCategory(subCategory.id)}
                        data-testid="deleteSubCategory"
                    >
                        Remove
                        {subCategory.isDeleting
                            ? (<i className="fas fa-circle-notch ml-2 fa-spin text-success" />)
                            : (<i className="fas fa-times ml-2 text-danger" />)}
                    </Button>
                </div>
            </div>
        </td>
    </tr>
);

export { Row };
