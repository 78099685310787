import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";

import Spinner from "@/Components/Spinner";
import urlHelper from "@/Utils/urlHelper";
import { decodeCustomToken, getToken } from "@/Utils/authentication";
import { useBasketContext } from "@/Context/BasketContext";
import { useAPI } from "@/Apis/useAPI";

const CheckoutInvoicePage = () => {
    const location = useLocation();
    const { push } = useHistory();
    const { post } = useAPI({ handle500WithRedirect: true });
    const [token] = useState(urlHelper.getUrlParam(location.search, "token"));
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const { loadBasket } = useBasketContext();

    useEffect(() => {
        const tokenDecoded = decodeCustomToken(token) as { ReceiptId: string, ConversationId: string, error: string };
        const loginUri = `/login?returnUrl=/invoices/checkout?token=${token}`;

        if (tokenDecoded?.error === "Invalid token") {
            setError("Invalid token");
            setLoading(false);
            return;
        }

        const loggedIn = !!getToken(); // The react redux useSelector doesn't seem to be accurate on first load

        if (!loggedIn) {
            push(loginUri);
            return;
        }

        post(`receipts/add-to-basket/${tokenDecoded.ReceiptId}`, {}, { loginUri })
            .then(async () => {
                await loadBasket();
                push("/basket");
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [location.search, token]);

    return (
        <div className="tw-p-10">
            {loading
            && (
                <div className="tw-flex tw-items-center tw-space-x-2 tw-justify-center">
                    <Spinner size="45" />
                    <p className="tw-text-base">
                        We are checking out the invoice. You will be redirected to the payment screen.
                    </p>
                </div>
            )}
            {error && <Alert color="danger" className="tw-text-base">{error}</Alert>}
        </div>
    );
};

export default CheckoutInvoicePage;
