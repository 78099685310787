import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Link } from "react-router-dom";

import { Participant } from "@/FlexPlan/Types/Participant";
import CloseButton from "@/Components/CloseButton";
import DateTextbox from "@/Components/DateTextbox";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { ModalAction } from "@/FlexPlan/Pages/Participants/ParticipantStatements/Types";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useForm } from "@/Hooks/useForm";
import { uppercaseFirstLetter } from "@/Utils/formatStringHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useAPI } from "@/Apis/useAPI";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { isEmptyOrSpaces, isValidDate } from "@/Utils/stringHelper";
import Spinner from "@/Components/Spinner";
import { deepEqual } from "@/Utils/objectHelper";
import { FormError } from "@/FlexPlan/Components/FormError";
import { endOfStringDate } from "@/FlexPlan/Utils/date";

interface Props {
    modalOpen: boolean,
    selectedParticipants?: Participant[],
    toggleSendStatementModal: () => void,
    modalAction?: ModalAction,
}

const columns = [
    { name: "Participant" },
    { name: "NDIS No." },
];

const StatementsModal = ({
    modalOpen,
    selectedParticipants,
    toggleSendStatementModal,
    modalAction,
}: Props) => {
    const {
        setItemsForPagination,
        pageNumber,
        onPageSelected,
        totalPages,
        paginatedItems,
    } = useClientSidePagination<Participant>(undefined, undefined, 5);

    const {
        setSuccessMessage,
        setPopupErrorMessage,
    } = useToastMessageContext();

    const {
        getFile,
        post,
        loading,
    } = useAPI({ handle500WithToastMessage: true });

    useEffect(() => {
        if (modalAction === "send") {
            setItemsForPagination(selectedParticipants?.filter(x => x.sendStatements));
        } else if (modalAction === "download") {
            setItemsForPagination(selectedParticipants);
        }
    }, [selectedParticipants]);

    const {
        formState,
        onDateTextboxChange,
        setInvalidField,
        invalidFields,
        removeInvalidField: removeErrorField,
    } = useForm<{ dateFrom: string, dateTo: string }>({
        dateFrom: "",
        dateTo: "",
    });

    const formHasErrors = (): boolean => {
        let errorFound: boolean = false;

        if (formState.dateFrom && !isValidDate(formState.dateFrom)) {
            setInvalidField("dateFrom", "Invalid Date");
            errorFound = true;
        }

        if (formState.dateTo && !isValidDate(formState.dateTo)) {
            setInvalidField("dateTo", "Invalid Date");
            errorFound = true;
        }

        if (!errorFound
            && formState.dateFrom && formState.dateTo
            && new Date(formState.dateFrom) > new Date(formState.dateTo)) {
            setInvalidField("dateError", "Date From must precede Date To");
            errorFound = true;
        }

        return errorFound;
    };

    const onDateOverride = (field: "dateFrom" | "dateTo") => (value) => {
        removeErrorField("dateError");
        onDateTextboxChange(field)(value);
    };

    const onDownload = () => {
        if (!selectedParticipants || selectedParticipants.length === 0) return;

        if (formHasErrors()) {
            return;
        }

        getFile(FlexPlanUrls.statements.download({
            ...formState,
            dateTo: formState.dateTo ? endOfStringDate(formState.dateTo) : null,
            plans: selectedParticipants?.map(x => x.planId),
        }))
            .then(response => {
                setSuccessMessage("Download succeeded", true);
                const contentType = selectedParticipants.length > 0 ? "application/zip" : "application/pdf";
                downloadUseAPIFile(contentType, response);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    const onSend = () => {
        if (!selectedParticipants || selectedParticipants.length === 0) return;

        if (formHasErrors()) {
            return;
        }

        post(FlexPlanUrls.statements.send, {
            dateFrom: isEmptyOrSpaces(formState.dateFrom) ? null : formState.dateFrom,
            dateTo: isEmptyOrSpaces(formState.dateTo) ? null : formState.dateTo,
            plans: selectedParticipants?.filter(x => x.sendStatements)
                .map(x => x.planId),
        })
            .then(() => {
                setSuccessMessage("Emails sent successfully", true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    return (
        <Modal isOpen={modalOpen} toggle={toggleSendStatementModal} centered size="lg" data-testid="sendStatement_modal">
            <CloseButton onClick={toggleSendStatementModal} />
            <ModalHeader>
                {modalAction && `${uppercaseFirstLetter(modalAction)} Statements`}
            </ModalHeader>
            <ModalBody className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col">
                    <div className="tw-flex tw-flex-row tw-space-x-10">
                        <DateTextbox
                            currentDate={formState.dateFrom}
                            changeDate={onDateOverride("dateFrom")}
                            label="Transactions From"
                            calendarIcon
                            error={invalidFields?.dateFrom}
                        />
                        <DateTextbox
                            currentDate={formState.dateTo}
                            changeDate={onDateOverride("dateTo")}
                            label="Transactions To"
                            calendarIcon
                            error={invalidFields?.dateTo}
                        />
                    </div>
                    {invalidFields.dateError && (
                        <FormError className="!-tw-mt-5" textSizeClassName="tw-text[0.875rem]">
                            {invalidFields.dateError}
                        </FormError>
                    )}
                </div>
                <Table responsive hover className="tw-mt-3">
                    <thead>
                        <tr>
                            {columns.map(col => (
                                <th style={{}} key={col.name}>
                                    <button
                                        type="button"
                                        className="bg-transparent p-0 border-0"
                                    >
                                        {col.name}&nbsp;
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedItems?.map(p => (
                            <tr key={p.id}>
                                <td>
                                    <Link
                                        to={`/participants/${p.id}/personal-information`}
                                        className="!tw-text-blue-600 !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {p.firstname}&nbsp;{p.surname}
                                    </Link>
                                </td>
                                <td>
                                    {p.ndis}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={modalAction === "send" ? onSend : onDownload}
                    disabled={loading || !deepEqual(invalidFields, {})}
                    className="tw-w-24"
                >
                    {loading && <Spinner className="tw-mx-auto" size="20" />}
                    {!loading && modalAction && uppercaseFirstLetter(modalAction)}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export { StatementsModal };
