import React, { useState } from "react";
import { Button } from "reactstrap";

import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { Urls } from "@/Apis/urls";
import { useReceiptsContext } from "@/Context/ReceiptsContext";

interface Props {
    receiptId: string,
}

const ReceiptsSendToRecipient = ({ receiptId }: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const pageContext = useReceiptsContext();

    const { post, loading } = useAPI({ handle500WithToastMessage: true });

    const onToggleModal = () => setModalOpen(prevState => !prevState);

    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const onSend = () => {
        post(Urls.receipts.sendToRecipient(receiptId), {})
            .then(() => setSuccessMessage(`The ${pageContext.pageTypeLowercase} was successfully sent`, true))
            .catch(() => setPopupErrorMessage(`There was a problem when trying to send the ${pageContext.pageTypeLowercase}`, true))
            .finally(() => onToggleModal());
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onToggleModal}
                data-testid="btn-send-to-recipient"
                disabled={loading}
            >Send to Recipient
            </Button>
            <ConfirmCancelModal
                text={`Are you sure you want to send this ${pageContext.pageTypeLowercase} to the recipient?`}
                buttonConfirmText="Send"
                onClose={onToggleModal}
                onConfirm={onSend}
                isOpen={modalOpen}
                title="Confirmation Required"
                loading={loading}
            />
        </>
    );
};

export default ReceiptsSendToRecipient;
