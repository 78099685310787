// @ts-nocheck
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBrowserId } from "@/Utils/localStorage";
import { useConfigurationContext } from "@/Context/ConfigurationContext";

const RaygunLogging = () => {
    const { raygunApiKey } = useConfigurationContext();
    const history = useHistory();
    const userId = useSelector<any>(state => state?.user?.userId);
    const browserId = getBrowserId();

    useEffect(() => {
        if (raygunApiKey) {
            window.rg4js("apiKey", raygunApiKey);
            window.rg4js("enableCrashReporting", true);

            history.listen(location => window.rg4js("trackEvent", { type: "pageView", path: location.pathname }));
        }
    }, [raygunApiKey, browserId, userId]);

    return (<></>);
};

export {
    RaygunLogging,
};
