import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";

export interface SupplierProfileAction {
    type: SupplierProfileActions,
    field?: keyof FlexPlanSupplier,
    value: any,
}

export enum SupplierProfileActions {
    Update,
    Seed,
}
