import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

const BasketEmpty = ({ className }: { className?: string }) => (
    <Col className={classnames("d-flex flex-column justify-content-center align-items-center p-4", className)}>
        <div className={classnames(styles.iconWrapper, "mb-4")}>
            <i className={classnames(styles.icon, "fas fa-shopping-cart")} />
        </div>
        <h3 className="mb-3">Your basket is empty</h3>
        <p>Browse the marketplace to find items to add here.</p>
        <Link to="/marketplace" className={classnames(styles.button, "m-3 btn-primary p-3 rounded")}>
            Browse the Marketplace
        </Link>
    </Col>
);

export default BasketEmpty;
