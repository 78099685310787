import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { ErrorFields, PasswordErrorState, PasswordValidationResponse } from "@/Components/PasswordValidation/types";

export const validationFailedClassName = "tw-text-red-600";
export const errorFields: ErrorFields[] = ["mismatch", "characters", "letters"];

interface Props {
    password: string,
    confirmPassword?: string,
    onValidationCallback: (response: PasswordValidationResponse) => void,
    confirmPasswordRequired: boolean,
}

const PasswordValidation = ({ password, confirmPassword, onValidationCallback, confirmPasswordRequired = false }: Props) => {
    const [state, setState] = useState<PasswordErrorState>({
        mismatch: false,
        characters: false,
        letters: false,
    });

    const minimumPasswordLength = 8;

    useEffect(() => {
        const newState: PasswordErrorState = {} as PasswordErrorState;

        if (confirmPasswordRequired && password !== confirmPassword) {
            newState.mismatch = true;
        }

        if (password.length < minimumPasswordLength || /^[a-z0-9]+$/i.test(password) || !/\d/.test(password)) {
            newState.characters = true;
        }

        if (password.toUpperCase() === password || password.toLowerCase() === password) {
            newState.letters = true;
        }

        setState(newState);
        onValidationCallback({
            success: !Object.keys(newState).some(prop => newState[prop]),
        });
    }, [password, confirmPassword]);

    const renderValidationText = (field: ErrorFields) => {
        switch (field) {
            case "mismatch":
                return "Passwords match.";
            case "characters":
                return "At least eight characters containing letters, numbers and symbols.";
            case "letters":
                return "Includes uppercase and lowercase letters.";
            default:
                return "";
        }
    };

    if (!password) {
        onValidationCallback({ success: false });
        return <></>;
    }

    return (
        <ul data-testid="password-validation">
            {errorFields
                .map(errorField => (
                    <li
                        className={classnames(state[errorField] && validationFailedClassName)}
                        key={errorField}
                        data-testid={errorField}
                    >
                        {state[errorField] ? (
                            <i className="fa fa-exclamation-circle" />
                        ) : (
                            <i className="fa fa-check fa-check-circle tw-text-brand-primary" />
                        )}
                    &nbsp;
                        <span className={classnames(state[errorField] && "tw-font-semibold")}>{renderValidationText(errorField)}</span>
                    </li>
                ))}
        </ul>
    );
};

export { PasswordValidation };
