const theme = {
    option: (current, { isSelected, isFocused }) => ({
        ...current,
        fontFamily: "Lato",
        color: "#0e5b7c",
        fontWeight: "bold",
        cursor: "pointer",
        // Need to be different or cursor keys don't work https://stackoverflow.com/questions/53913136/why-arrow-key-navigation-doesnt-work-or-focus-in-dropdown-in-react-select-whe
        backgroundColor: (isSelected && "#addff8") || (isFocused && "#addff8") || "transparent",
        ":hover": {
            ...current[":hover"],
            backgroundColor: "#addff8",
        },
    }),
    control: (current) => ({
        ...current,
        height: "50px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundColor: "white",
        border: "1px solid #d8d8d8",
        borderRadius: "2px",
        borderColor: "hsl(0,0%,80%)",
        borderBottom: "#d8d8d8 solid 3px",
        boxShadow: "none",
        ":hover": {
            ...current[":hover"],
            background: "#white",
            border: "1px solid #d8d8d8",
            borderBottom: "#d8d8d8 solid 3px",
        },
        ":focus-within": {
            ...current[":focus-within"],
            background: "#e3f7ff",
            borderBottom: "#1696cc solid 3px",
            borderColor: "#1696cc",
        },
    }),
    dropdownIndicator: () => ({
        display: "none",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};

export { theme };
