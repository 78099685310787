import React from "react";

import { BasicInformationSection } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Components/BasicInformationSection";
import { AddressSection } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Components/AddressSection";
import { BusinessDetailsSection } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Components/BusinessDetailsSection";
import Spinner from "@/Components/Spinner";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    loading: boolean,
    openSections: SupplierSections[],
}

const PublicInformationTab = ({ onSave, removeErrorField, onChangeEditableSections, errors, loading, openSections }: Props) => {
    if (loading) return <Spinner className="tw-my-10 tw-mx-auto" size="40" />;

    return (
        <div className="mb-5">
            <div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <BasicInformationSection
                        onSave={onSave}
                        removeErrorField={removeErrorField}
                        onChangeEditableSections={onChangeEditableSections}
                        errors={errors}
                        openSections={openSections}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <AddressSection
                        onSave={onSave}
                        removeErrorField={removeErrorField}
                        errors={errors}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <BusinessDetailsSection
                        onSave={onSave}
                        removeErrorField={removeErrorField}
                        onChangeEditableSections={onChangeEditableSections}
                        errors={errors}
                        openSections={openSections}
                    />
                </div>
            </div>
        </div>
    );
};

export { PublicInformationTab };
