import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { SketchPicker } from "react-color";

import styles from "./styles.module.scss";

class ColourPicker extends React.Component {
    state = {
        displayColourPicker: false,
    };

    toggle = () => {
        this.setState(prevState => ({ displayColourPicker: !prevState.displayColourPicker }));
    };

    render() {
        return (
            <div className={classnames(this.props.className, "position-relative")}>
                <button type="button" onClick={this.toggle} className={classnames(styles.colourPicker, "bg-white border-0 rounded d-flex p-0")}>
                    <div style={{ backgroundColor: this.props.colour }} className="border-right" />
                    <span className="px-3 py-2">{this.props.colour}</span>
                </button>
                {this.state.displayColourPicker && (
                    <div className={classnames(styles.popover, this.props.pickerClassName)}>
                        <button type="button" className={styles.cover} onClick={this.toggle} />
                        <SketchPicker
                            color={this.props.colour}
                            onChangeComplete={colour => this.props.onColourChange(colour.hex.toUpperCase())}
                        />
                    </div>
                )}
            </div>
        );
    }
}

ColourPicker.propTypes = {
    colour: PropTypes.string.isRequired,
    onColourChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    pickerClassName: PropTypes.string,
};

export default ColourPicker;
