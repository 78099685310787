import React, { useState, useContext, ReactNode } from "react";

export interface FilterContextType {
    filterIsShowing: boolean,
    toggleFilters(show?:boolean): void
}

const FilterContext = React.createContext<FilterContextType>({
    toggleFilters: () => {},
    filterIsShowing: false,
});
const useFilterContext = () => useContext(FilterContext);

const FilterContextProvider = ({ children }: { children: ReactNode }) => {
    const [filterIsShowing, setFilterIsShowing] = useState(false);
    const toggleFilters = (show?: boolean) => setFilterIsShowing(show ?? !filterIsShowing);

    return (
        <FilterContext.Provider value={
            {
                toggleFilters,
                filterIsShowing,
            } as FilterContextType
        }
        >
            {children}
        </FilterContext.Provider>);
};

export { useFilterContext, FilterContextProvider };
