import React, { Dispatch, SetStateAction } from "react";
import { FormGroup } from "reactstrap";

import Checkbox from "@/Components/Checkbox";
import RadioButton from "@/Components/RadioButton";
import { Popper } from "@/Components/Popper";
import { CardholderStatus, OrderType, ReferralStatus, SalesDate, SalesOrderStatus, SalesPaymentStatus, SalesRequest } from "@/Apis/SalesManagement";

interface Props {
    searchQuery: SalesRequest,
    setSearchQuery: Dispatch<SetStateAction<SalesRequest>>,
}

const Filter = ({ searchQuery, setSearchQuery }: Props) => {
    const [localState, setLocalState] = React.useState(searchQuery);

    const onDateChanged = (option: SalesDate) => setLocalState({
        ...localState,
        date: option,
    });

    const onCardholderChanged = (option: CardholderStatus) => setLocalState({
        ...localState,
        cardholder: option,
    });

    const onReferralChanged = (option: ReferralStatus) => setLocalState({
        ...localState,
        referral: option,
    });

    const selectAllOrderTypes = () => setLocalState({
        ...localState,
        orderTypes: [
            OrderType.Product,
            OrderType.Upsell,
            OrderType.BespokeQuote,
            OrderType.Package,
            OrderType.FormationsProduct,
            OrderType.CSL,
            OrderType.Quotation,
            OrderType.Invoice,
            OrderType.OffsitePayment,
            OrderType.BankAccountProduct,
        ],
    });

    const selectAllOrderStatuses = () => setLocalState({
        ...localState,
        paymentStatuses: [
            SalesPaymentStatus.Paid,
            SalesPaymentStatus.Cancelled,
            SalesPaymentStatus.OnHold,
            SalesPaymentStatus.Unpaid,
        ],
    });

    const onPaymentStatusChanged = (option: SalesPaymentStatus) => {
        const statuses = localState.paymentStatuses.indexOf(option) > -1
            ? localState.paymentStatuses.filter(x => x !== option)
            : localState.paymentStatuses.concat(option);

        setLocalState({
            ...localState,
            paymentStatuses: statuses,
        });
    };

    const onOrderTypesChanged = (option: OrderType) => {
        const orderTypes = localState.orderTypes.indexOf(option) > -1
            ? localState.orderTypes.filter(x => x !== option)
            : localState.orderTypes.concat(option);

        setLocalState({
            ...localState,
            orderTypes,
        });
    };

    const onOrderStatusChanged = (option: SalesOrderStatus) => setLocalState({
        ...localState,
        orderStatus: option,
    });

    const cancel = () => {
        setLocalState(searchQuery);
    };

    const submit = () => {
        setSearchQuery(localState);
    };

    return (
        <Popper
            targetId="filter"
            triggerText="Filter"
            icon="fa-filter"
            onSubmit={submit}
            onCancel={cancel}
        >
            <div className="d-flex justify-content-between m-3">
                <FormGroup className="mr-4">
                    <legend className="text-uppercase text-primary">Date</legend>
                    <FormGroup>
                        <RadioButton
                            id="date-all"
                            name="date"
                            checked={localState.date === SalesDate.All}
                            onChange={() => onDateChanged(SalesDate.All)}
                            className="d-inline-block mr-0"
                            label="All"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="date-7"
                            name="date"
                            checked={localState.date === SalesDate.Last7Days}
                            onChange={() => onDateChanged(SalesDate.Last7Days)}
                            className="d-inline-block mr-0"
                            label="Last 7 Days"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="date-14"
                            name="date"
                            checked={localState.date === SalesDate.Last14Days}
                            onChange={() => onDateChanged(SalesDate.Last14Days)}
                            className="d-inline-block mr-0"
                            label="Last 14 Days"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="date-30"
                            name="date"
                            checked={localState.date === SalesDate.Last30Days}
                            onChange={() => onDateChanged(SalesDate.Last30Days)}
                            className="d-inline-block mr-0"
                            label="Last 30 Days"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="date-90"
                            name="date"
                            checked={localState.date === SalesDate.Last90Days}
                            onChange={() => onDateChanged(SalesDate.Last90Days)}
                            className="d-inline-block mr-0"
                            label="Last 90 Days"
                        />
                    </FormGroup>
                </FormGroup>

                <FormGroup className="mr-4">
                    <legend className="text-uppercase text-primary">Payment Status</legend>
                    <FormGroup>
                        <Checkbox
                            id="payment-status-all"
                            checked={localState.paymentStatuses.length === 4}
                            onChange={selectAllOrderStatuses}
                            className="d-inline-block mr-0"
                            label="Select All"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="payment-status-unpaid"
                            checked={localState.paymentStatuses.indexOf(SalesPaymentStatus.Unpaid) > -1}
                            onChange={() => onPaymentStatusChanged(SalesPaymentStatus.Unpaid)}
                            className="d-inline-block mr-0"
                            label="Unpaid"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="payment-status-on-hold"
                            checked={localState.paymentStatuses.indexOf(SalesPaymentStatus.OnHold) > -1}
                            onChange={() => onPaymentStatusChanged(SalesPaymentStatus.OnHold)}
                            className="d-inline-block mr-0"
                            label="On Hold"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="payment-status-paid"
                            checked={localState.paymentStatuses.indexOf(SalesPaymentStatus.Paid) > -1}
                            onChange={() => onPaymentStatusChanged(SalesPaymentStatus.Paid)}
                            className="d-inline-block mr-0"
                            label="Paid"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="payment-status-cancelled"
                            checked={localState.paymentStatuses.indexOf(SalesPaymentStatus.Cancelled) > -1}
                            onChange={() => onPaymentStatusChanged(SalesPaymentStatus.Cancelled)}
                            className="d-inline-block mr-0"
                            label="Cancelled"
                        />
                    </FormGroup>
                </FormGroup>

                <FormGroup className="mr-4">
                    <legend className="text-uppercase text-primary">Order Status</legend>
                    <FormGroup>
                        <RadioButton
                            name="order-status"
                            id="order-status-all"
                            checked={localState.orderStatus === SalesOrderStatus.Any}
                            onChange={() => onOrderStatusChanged(SalesOrderStatus.Any)}
                            className="d-inline-block mr-0"
                            label="Any"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            name="order-status"
                            id="order-status-completed"
                            checked={localState.orderStatus === SalesOrderStatus.Completed}
                            onChange={() => onOrderStatusChanged(SalesOrderStatus.Completed)}
                            className="d-inline-block mr-0"
                            label="Complete"
                        />
                    </FormGroup>
                </FormGroup>

                <FormGroup className="mr-4">
                    <legend className="text-uppercase text-primary">Cardholder</legend>
                    <FormGroup>
                        <RadioButton
                            id="cardholder-all"
                            name="cardholder"
                            checked={localState.cardholder === CardholderStatus.All}
                            onChange={() => onCardholderChanged(CardholderStatus.All)}
                            className="d-inline-block mr-0"
                            label="All"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="cardholder-with"
                            name="cardholder"
                            checked={localState.cardholder === CardholderStatus.WithCard}
                            onChange={() => onCardholderChanged(CardholderStatus.WithCard)}
                            className="d-inline-block mr-0"
                            label="With Card"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="cardholder-without"
                            name="cardholder"
                            checked={localState.cardholder === CardholderStatus.WithoutCard}
                            onChange={() => onCardholderChanged(CardholderStatus.WithoutCard)}
                            className="d-inline-block mr-0"
                            label="Without Card"
                        />
                    </FormGroup>
                </FormGroup>

                <FormGroup className="mr-4">
                    <legend className="text-uppercase text-primary">Referral</legend>
                    <FormGroup>
                        <RadioButton
                            id="referral-all"
                            name="referral"
                            checked={localState.referral === ReferralStatus.All}
                            onChange={() => onReferralChanged(ReferralStatus.All)}
                            className="d-inline-block mr-0"
                            label="Select All"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="referral-yes"
                            name="referral"
                            checked={localState.referral === ReferralStatus.Yes}
                            onChange={() => onReferralChanged(ReferralStatus.Yes)}
                            className="d-inline-block mr-0"
                            label="Yes"
                        />
                    </FormGroup>
                    <FormGroup>
                        <RadioButton
                            id="referral-no"
                            name="referral"
                            checked={localState.referral === ReferralStatus.No}
                            onChange={() => onReferralChanged(ReferralStatus.No)}
                            className="d-inline-block mr-0"
                            label="No"
                        />
                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <legend className="text-uppercase text-primary">Type</legend>
                    <FormGroup>
                        <Checkbox
                            id="type-all"
                            checked={localState.orderTypes.length === 7}
                            onChange={selectAllOrderTypes}
                            className="d-inline-block mr-0"
                            label="All"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-product"
                            checked={localState.orderTypes.indexOf(OrderType.Product) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.Product)}
                            className="d-inline-block mr-0"
                            label="Product"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-upsell"
                            checked={localState.orderTypes.indexOf(OrderType.Upsell) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.Upsell)}
                            className="d-inline-block mr-0"
                            label="Upsell"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-bespokeQuote"
                            checked={localState.orderTypes.indexOf(OrderType.BespokeQuote) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.BespokeQuote)}
                            className="d-inline-block mr-0"
                            label="Bespoke Quote"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-package"
                            checked={localState.orderTypes.indexOf(OrderType.Package) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.Package)}
                            className="d-inline-block mr-0"
                            label="Package"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-formationsProduct"
                            checked={localState.orderTypes.indexOf(OrderType.FormationsProduct) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.FormationsProduct)}
                            className="d-inline-block mr-0"
                            label="Formations Product"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-csl"
                            checked={localState.orderTypes.indexOf(OrderType.CSL) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.CSL)}
                            className="d-inline-block mr-0"
                            label="CSL"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-quotation"
                            checked={localState.orderTypes.indexOf(OrderType.Quotation) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.Quotation)}
                            className="d-inline-block mr-0"
                            label="Quotation"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-invoice"
                            checked={localState.orderTypes.indexOf(OrderType.Invoice) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.Invoice)}
                            className="d-inline-block mr-0"
                            label="Invoice"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-offsitePayment"
                            checked={localState.orderTypes.indexOf(OrderType.OffsitePayment) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.OffsitePayment)}
                            className="d-inline-block mr-0"
                            label="Offsite Payment"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            id="type-bankAccountProduct"
                            checked={localState.orderTypes.indexOf(OrderType.BankAccountProduct) > -1}
                            onChange={() => onOrderTypesChanged(OrderType.BankAccountProduct)}
                            className="d-inline-block mr-0"
                            label="Bank Account Product"
                        />
                    </FormGroup>
                </FormGroup>
            </div>
        </Popper>
    );
};

export { Filter };
