import React, { useState } from "react";
import { Button, Alert, Modal, ModalBody } from "reactstrap";

import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface Props {
    onSuccess: (status: string) => void;
    onClose: () => void;
    orderId: string;
}

const ConfirmFormCompanyModal = ({ onSuccess, onClose, orderId }: Props): JSX.Element => {
    const [apiError, setApiError] = useState("");
    const { setSuccessMessage } = useToastMessageContext();
    const { post, loading } = useAPI();

    const onConfirm = async () => {
        setApiError("");

        await post(`company-formations/${orderId}`, {})
            .then((status) => {
                onSuccess(status as string);
                setSuccessMessage("Company submitted successfully", true);
            })
            .catch((error) => {
                setApiError(error.message || error);
            });
    };

    return (
        <Modal isOpen centered>
            <ModalBody className="p-3">
                <h3 className="d-none d-md-flex">Form Company</h3>
                <h5 className="d-flex d-md-none">Form Company</h5>
                <p className="mt-3">Are you sure that you want to form this company?</p>

                {apiError && <Alert color="danger">{apiError}</Alert>}

                <div className="mt-4 d-flex justify-content-end">
                    <Button color="secondary" type="submit" onClick={onClose} className="mr-2">
                        Cancel
                    </Button>
                    <Button color="warning" disabled={loading} onClick={onConfirm} data-testid="submit">
                        {loading ? <Spinner size="21" /> : "Form Company"}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export { ConfirmFormCompanyModal };
