import React from "react";
import { FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import RadioButton from "@/Components/RadioButton";

const YesNoButtons = ({ error, label, value, onChange, disabled, fieldId }) => {
    const onClick = checked => () => onChange(checked);

    const yesIdentifier = `yes-${fieldId}`;
    const noIdentifier = `no-${fieldId}`;

    if (disabled) {
        return (
            <FormGroup className="d-flex flex-column">
                <Label>{label}</Label>
                <div className="d-flex">
                    {value}
                </div>
            </FormGroup>);
    }

    return (
        <FormGroup className="d-flex flex-column">
            <Label>{label}</Label>
            <div className="d-flex">
                <RadioButton
                    checked={value === "Yes"}
                    onChange={onClick("Yes")}
                    id="Yes"
                    name={yesIdentifier}
                    label="Yes"
                    className="mr-3"
                    data-testid="yesNoButton-yes-radioButton"
                />
                <RadioButton
                    checked={value === "No"}
                    onChange={onClick("No")}
                    id="No"
                    name={noIdentifier}
                    label="No"
                    className="mr-3"
                    data-testid="yesNoButton-no-radioButton"
                />
            </div>
            {error && (
                <div className={styles.errorMessage}>{error}</div>
            )}
        </FormGroup>
    );
};

YesNoButtons.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    fieldId: PropTypes.string.isRequired,
    error: PropTypes.string,
};

export { YesNoButtons };
