import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import { FolderAction } from "@/Components/Messages/MessageSearch";
import { useAPI } from "@/Apis/useAPI";
import { Folder, GetMessagesResponse, MessageType, MessagingUrls, Status } from "@/Apis/Messaging";

interface QueryState {
    currentPage: number;
    searchTerm: string;
    status: Status | "";
}

const useMessages = () => {
    const match = useRouteMatch<{ folder: Folder }>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [messages, setMessages] = useState<MessageType[] | null>(null);
    const [messageQuery, setMessageQuery] = useState<QueryState>({
        currentPage: 0,
        searchTerm: "",
        status: "all",
    });

    const { loading, post, get, del } = useAPI({ handle500WithRedirect: true });

    const getMessages = () => {
        const folder = match.params.folder;

        get<GetMessagesResponse>(MessagingUrls.getMessages(folder, {
            folder: match.params.folder,
            page: messageQuery.currentPage,
            searchQuery: messageQuery.searchTerm,
            status: messageQuery.status === "all" ? "" : messageQuery.status,

        })).then(response => {
            setMessages(response.messages);
            setTotalPages(response.totalPages);
        });
    };

    const moveDeletedMessagesToInbox = (conversationIds: string[]) => {
        post(MessagingUrls.moveDeletedMessagesToInbox, { conversationIds }).then(getMessages);
    };
    const deleteInboxMessages = (conversationIds: string[]) => {
        del(MessagingUrls.deleteInboxMessages, { conversationIds }).then(getMessages);
    };
    const deleteSentMessages = (conversationIds: string[]) => {
        del(MessagingUrls.deleteSentMessages, { conversationIds }).then(getMessages);
    };

    const actOnSelectedMessages = (folderAction: FolderAction, selectedMessages: string[]) => {
        const folder = match.params.folder;

        if (folder === "deleted" && folderAction === "moveToInbox") {
            moveDeletedMessagesToInbox(selectedMessages);
        }

        if (folder === "inbox" && folderAction === "delete") {
            deleteInboxMessages(selectedMessages);
        }

        if (folder === "deleted" && folderAction === "delete") {
            // TODO: no functionality hide button
        }

        if (folder === "drafts" && folderAction === "delete") {
            // TODO: add delete drafts api
        }

        if (folder === "sent" && folderAction === "delete") {
            deleteSentMessages(selectedMessages);
        }
    };

    useEffect(() => {
        getMessages();
    }, [messageQuery]);

    return ({
        loading,
        messages,
        totalPages,
        messageQuery,
        setMessageQuery,
        actOnSelectedMessages,
    });
};

export { useMessages };
