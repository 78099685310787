import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import { ClickAwayListener } from "@/Components/ClickAwayListener";
import { CircleButton } from "@/Components/CircleButton";

export const ContextMenu = props => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    return (
        <>
            <ClickAwayListener onClickAway={() => setMenuIsOpen(false)}>
                <CircleButton
                    testId={props.testId}
                    className={classnames(styles.optionsButton, "position-absolute bg-light text-dark bg-transparent border-0")}
                    onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                    }}
                >
                    <i className="fa fa-ellipsis-h mt-1" />
                </CircleButton>
            </ClickAwayListener>
            {menuIsOpen && (
                <div className={classnames(styles.menuButton, "position-absolute dropdown-menu show")}>
                    {props.showCopy && (
                        <button
                            type="button"
                            data-testid="copy-item"
                            className="dropdown-item"
                            onClick={props.onClickCopy}
                        >
                            {props.isPackage ? "Copy Package Code" : "Copy Product Code"}
                        </button>
                    )}
                    {props.showHide && (
                        <button
                            type="button"
                            data-testid="hide-item"
                            className="dropdown-item"
                            onClick={props.onClickHide}
                        >
                            {props.showHideText}
                        </button>
                    )}
                    {props.showDelete && (
                        <button
                            type="button"
                            data-testid="delete-item"
                            className="dropdown-item"
                            onClick={props.onClickDelete}
                        >
                            Delete
                        </button>
                    )}
                    {props.showUpsell && (
                        <button
                            type="button"
                            data-testid="upsell-item"
                            className="dropdown-item"
                            onClick={props.onClickUpsell}
                        >
                            Upsell
                        </button>
                    )}
                </div>
            )}
        </>
    );
};

ContextMenu.propTypes = {
    showCopy: PropTypes.bool,
    onClickCopy: PropTypes.func,
    showHide: PropTypes.bool,
    showHideText: PropTypes.string,
    onClickHide: PropTypes.func,
    showDelete: PropTypes.bool,
    onClickDelete: PropTypes.func,
    showUpsell: PropTypes.bool,
    onClickUpsell: PropTypes.func,
    testId: PropTypes.string,
    isPackage: PropTypes.bool,
};
