import { useEffect } from "react";
import { useLocation } from "react-router";

import { useReceiptsContext } from "@/Context/ReceiptsContext";

// Set a document title for quotations or invoices section in the format "BSC - Quotations" or "BSC - Invoices".
export const useReceiptDocumentTitle = () => {
    const location = useLocation();
    const { pageTypePlural } = useReceiptsContext();

    useEffect(() => {
        document.title = `BSC - ${pageTypePlural}`;
    }, [location]);
};
