/* eslint-disable react/no-find-dom-node */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Input } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
    onSaveOption?(previousValue: string, newValue: string): void;
    onDeleteOption?(value: string): void;
    initialValue?: string;
    mode: keyof Mode;
}

interface Mode {
    Create;
    Edit;
    Editing;
}

const ConfigurableSelectLabelEditor = ({ onSaveOption, onDeleteOption, initialValue = "", mode }: Props) => {
    const [modeState, setModeState] = useState(mode);
    const inputRef = useRef<any>(null);

    useEffect(() => {
        const input = (ReactDOM.findDOMNode(inputRef.current) as HTMLInputElement);
        input.value = initialValue;
        if (modeState === "Create") {
            (ReactDOM.findDOMNode(inputRef.current) as HTMLInputElement).focus();
        }
    }, []);

    const changeMode = (newMode: keyof Mode) => () => {
        setModeState(newMode);
    };

    const saveOption = () => {
        // Use uncontrolled inputs until the react-select focus bugs are fixed https://github.com/JedWatson/react-select/issues/2848
        if (onSaveOption) {
            onSaveOption(initialValue, (ReactDOM.findDOMNode(inputRef.current) as HTMLInputElement).value);
        }
    };

    const deleteOption = () => {
        if (onDeleteOption) {
            onDeleteOption(initialValue);
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && (modeState === "Create" || modeState === "Editing")) {
            saveOption();
        }
        if (e.keyCode === 32) {
            e.currentTarget.value = `${e.currentTarget.value} `;
        }
    };

    const onInputClick = (e) => {
        if (modeState === "Edit") {
            setModeState("Editing");
        }
        e.currentTarget.focus();
    };

    return (
        <div className={classnames(styles.editItemContainer, `d-flex ${modeState === "Create" ? "mt-4" : ""}`)}>
            <div className="flex-grow-1 mr-1">
                <Input
                    type="text"
                    ref={inputRef}
                    value={inputRef?.current?.value}
                    onClick={onInputClick}
                    onKeyUp={onKeyDown}
                    readOnly={modeState === "Edit"}
                    className={modeState === "Edit" ? styles.readOnly : ""}
                    placeholder="Add Item"
                />
            </div>
            {modeState === "Create" && (
                <div>
                    <Button className="mr-1 border-0 bg-transparent text-dark" onClick={saveOption}>
                        <i className="fa fa-plus" />
                    </Button>
                </div>)}

            {modeState === "Edit" && (
                <div>
                    <Button className="mr-1 border-0 bg-transparent text-dark" onClick={changeMode("Editing")}>
                        <i className="fa fa-pen" />
                    </Button>
                    <Button className={classnames(styles.separator, "mr-1 border-0 rounded-0 bg-transparent text-danger")} onClick={deleteOption}>
                        <i className="fa fa-times" />
                    </Button>
                </div>)}

            {modeState === "Editing" && (
                <div>
                    <Button className="mr-1 border-0 bg-transparent text-success" onClick={saveOption}>
                        <i className="fa fa-check" />
                    </Button>
                    <Button className={classnames(styles.separator, "mr-1 border-0 rounded-0 bg-transparent text-danger")} onClick={deleteOption}>
                        <i className="fa fa-times" />
                    </Button>
                </div>)}
        </div>);
};

export { ConfigurableSelectLabelEditor };
