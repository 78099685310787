import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import styles from "./styles.module.scss";

const PageBreadcrumbs = ({ navigation }) => {
    const dispatch = useDispatch();
    const onPush = path => () => dispatch(push(path));

    return (
        <div className={classnames("d-flex align-items-center font-weight-bold mt-3 mb-3 flex-wrap", styles.breadcrumb)}>
            {navigation.filter(x => !x.disabled).map((x, i) => {
                const menuItem = (
                    <button type="button" className="bg-transparent m-0 border-0 pl-0" key={x.name} onClick={onPush(x.link)}>
                        <span className={classnames("pr-2 text-primary font-weight-bold")}>
                            {x.name}
                        </span>
                        {i !== navigation.length - 1 && <i className="text-primary fa fa-chevron-right" />}
                    </button>
                );
                return menuItem;
            })}
        </div>
    );
};

PageBreadcrumbs.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { PageBreadcrumbs };
