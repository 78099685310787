const theme = {
    option: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        padding: 0,
    }),
    control: (provided) => ({
        ...provided,
        ":hover": {
            ...provided[":hover"],
            borderColor: "hsl(0,0%,80%);",
        },
        borderColor: "hsl(0,0%,80%);",
        boxShadow: "transparent",
        cursor: "pointer",
    }),
};

export { theme };
