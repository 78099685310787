import { catchError, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";

import userActions from "./actions";
import * as constants from "./constants";

import api from "@/Utils/api";
import * as authentication from "@/Utils/authentication";
import errorHelper from "@/Utils/errorHelper";

const loadUser$ = (action$, state$) => action$.pipe(
    ofType(constants.LOAD_USER_$),
    mergeMap(() => ajax(api.buildRequestForEpic("profile", "GET"))
        .pipe(
            mergeMap(result => ([
                userActions.setUser(result.response),
            ])),
            catchError((error) => {
                if (error.status === 401 || (error.response && error.response.message === "Unauthorised")) {
                    authentication.removeToken();
                    return of(push({ pathname: "/login", state: { from: state$.value.router.location.pathname } }));
                }
                if (error.status === 504) {
                    return errorHelper({ message: "A server error occurred, please contact us." });
                }
                return errorHelper(error);
            }),
        )),
);

const logout$ = action$ => action$.pipe(
    ofType(constants.LOGOUT_$),
    mergeMap(() => ajax(api.buildRequestForEpic("account/logout", "POST"))
        .pipe(
            mergeMap(() => {
                sessionStorage.clear();
                authentication.removeToken();
                return [
                    push("/"),
                    userActions.clearUser(),
                ];
            }),
            catchError(() => {
                sessionStorage.clear();
                authentication.removeToken();
                return of(...[
                    push("/"),
                    userActions.clearUser(),
                ]);
            }),
        )),
);

export default {
    loadUser$,
    logout$,
};
