import React, { useEffect } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { BasketContents } from "@/Components/BasketContents";
import { ClickAwayListener } from "@/Components/ClickAwayListener";
import { useBasketContext } from "@/Context/BasketContext";

const BasketSideCart = () => {
    const { toggleBasket, basketIsShowing } = useBasketContext();

    const onEscapeKeyDown = (e) => {
        if (e.key === "Esc" || e.key === "Escape") {
            toggleBasket();
        }
    };

    const hideBasket = () => {
        if (basketIsShowing) {
            toggleBasket();
        }
    };

    useEffect(() => {
        if (basketIsShowing) {
            document.addEventListener("keydown", onEscapeKeyDown);
        }
        return () => document.removeEventListener("keydown", onEscapeKeyDown);
    }, [basketIsShowing]);

    return (
        <>
            <div className={classnames(basketIsShowing && styles.notAModal)} />
            <div className={classnames(styles.sideCart, basketIsShowing && styles.sideCartShow)}>
                <div className="d-flex flex-column flex-grow-1 overflow-y-auto">
                    <ClickAwayListener onClickAway={hideBasket}>
                        <BasketContents onBack={() => toggleBasket(false)} showCheckoutButton />
                    </ClickAwayListener>
                </div>
            </div>
        </>);
};

export { BasketSideCart };
