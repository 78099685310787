import React, { useState } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import styles from "./styles.module.scss";

import { PricingPopover } from "@/Components/PricingPopover";
import { ProductQuantity } from "@/Components/ProductQuantity";
import { DueToSupplierPopup } from "@/Components/DueToSupplierPopup";
import { ConfirmDeleteBasketItemModal } from "@/Modals/ConfirmDeleteBasketItemModal";
import { useBasketContext } from "@/Context/BasketContext";
import { BasketGroupType, BasketItemType, BasketItemTypeWithGroup } from "@/Apis/Basket";
import { toCurrency } from "@/Utils/formatStringHelper";
import { WaitModal } from "@/Modals/WaitModal";

interface Props {
    showCheckoutButton?: boolean;
    onBack?: () => void;
    basketItemsClass?: string;
    hideMobileBacktButton?: boolean;
    hideCartButton?: boolean;
}

const BasketContents = ({ showCheckoutButton, onBack, basketItemsClass, hideMobileBacktButton, hideCartButton }: Props) => {
    const { removeItemFromBasket, removeGroupFromBasket, basket, updateQuantityInBasket, loading } = useBasketContext();
    const history = useHistory();
    const [displayConfirmDeleteItemModal, setDisplayConfirmDeleteItemModal] = useState(false);
    const [displayConfirmDeleteGroupModal, setDisplayConfirmDeleteGroupModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<BasketItemType | null>(null);
    const [groupToDelete, setGroupToDelete] = useState<BasketGroupType | null>(null);
    const sendGtmEvent = useGTMDispatch();

    const sendToGtmEventHandler = () => {
        sendGtmEvent(
            {
                event: "remove_from_cart",
                ecommerce: {
                    items: [
                        {
                            item_id: `${itemToDelete?.productId}`,
                            item_name: `${itemToDelete?.serviceName}`,
                            currency: "GBP",
                            item_brand: `${itemToDelete?.supplierName}`,
                            item_category: `${itemToDelete?.category}`,
                            price: itemToDelete?.price,
                            quantity: itemToDelete?.quantity,
                        },
                    ],
                },
            },
        );
    };
    const removeItem = () => {
        if (itemToDelete === null) {
            return;
        }
        removeItemFromBasket(itemToDelete.orderId);
        setDisplayConfirmDeleteItemModal(false);
        sendToGtmEventHandler();
    };

    const removeGroup = () => {
        if (groupToDelete === null) {
            return;
        }
        removeGroupFromBasket(groupToDelete.key!);
        setDisplayConfirmDeleteGroupModal(false);
    };

    const showDeleteItemModal = (deleteItem: BasketItemType) => () => {
        setItemToDelete(deleteItem);
        setDisplayConfirmDeleteItemModal(true);
    };

    const showDeleteGroupModal = (deleteGroup: BasketGroupType) => () => {
        setGroupToDelete(deleteGroup);
        setDisplayConfirmDeleteGroupModal(true);
    };

    const updateQuantity = (itemToUpdate: BasketItemType, newQuantity: number) => {
        updateQuantityInBasket({ productId: itemToUpdate.productId, quantity: newQuantity });
    };
    const BasketCartButton = () => {
        if (!hideCartButton) {
            return (
                <div className="d-flex align-items-center pt-2">
                    <h4><i className="fas fa-shopping-cart" />
                        Cart <span className="text-primary">({basket.totalItems} item{basket.totalItems === 1 ? "" : "s"})</span>
                    </h4>
                </div>
            );
        }
        return (
            <div className="d-flex align-items-center pt-2">
                <h4> Checkout <span className="text-primary">({basket.totalItems} item{basket.totalItems === 1 ? "" : "s"})</span></h4>
            </div>
        );
    };
    const BasketBackButton = () => {
        if (hideMobileBacktButton) {
            return (
                <div className="justify-content-between py-1 py-md-2 d-none d-sm-flex">
                    <Button onClick={onBack || history.goBack} color="link" className="p-0 font-weight-bold d-inline">
                        <i className="text-primary fa fa-chevron-left mr-1" /> Back
                    </Button>
                    <div><Link to="/marketplace" className="text-underline font-weight-bold">Visit Marketplace</Link></div>
                </div>
            );
        }
        return (
            <div className="d-flex justify-content-between py-1 py-md-2">
                <Button onClick={onBack || history.goBack} color="link" className="p-0 font-weight-bold d-inline">
                    <i className="text-primary fa fa-chevron-left mr-1" /> Back
                </Button>
                <div><Link to="/marketplace" className="text-underline font-weight-bold">Visit Marketplace</Link></div>
            </div>
        );
    };

    function allItems(groups: BasketGroupType[]): BasketItemTypeWithGroup[] {
        const items: BasketItemTypeWithGroup[] = [];
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].items.length; j++) {
                items.push({
                    ...groups[i].items[j],
                    group: groups[i],
                });
            }
        }
        return items;
    }

    function resolveBasketItemClass(group: BasketGroupType, item: BasketItemType) {
        if (group.key == null) {
            return "border rounded p-2 mb-3";
        }
        return classnames("tw-border-t-2 tw-border-gray-100",
            group.items.indexOf(item) === group.items.length - 1 ? "pt-3" : "py-3");
    }

    return (
        <div data-testid="basket-side-cart" className="d-flex flex-column h-100">
            <div className="px-3 border-bottom pb-3">
                <BasketBackButton />
                <BasketCartButton />
            </div>

            <div className="flex-grow-1 border-bottom border-top p-3 overflow-y-auto">
                <div className={classnames("overflow-y-auto", basketItemsClass)}>
                    {basket.groups.map(g => (
                        <div key={g.key ?? ""} className={(g.key == null ? "" : "border rounded p-2 mb-3")}>
                            {g.key != null && g.name != null && (
                                <div className="mb-3">
                                    <h5>{g.name}</h5>
                                    <div className="tw-text-gray-600">
                                        {g.items.length} item{g.items.length === 1 ? "" : "s"} - £{toCurrency(g.items.map(_ => _.totalPrice).reduce((a, b) => a + b))}
                                    </div>
                                    <div className="tw-text-gray-400">
                                        {g.description}
                                    </div>
                                    <Button
                                        color="link"
                                        className="p-0 text-uppercase text-underline font-weight-bold font-size-sm"
                                        onClick={showDeleteGroupModal(g)}
                                    >Remove
                                    </Button>
                                </div>
                            )}
                            {g.items.map(x => (
                                <div
                                    className={classnames(resolveBasketItemClass(g, x), "d-flex justify-content-between")}
                                    key={x.orderId}
                                >
                                    <div>
                                        <div className="h6 font-weight-bold">{x.serviceName}</div>
                                        <div className="font-size-sm text-muted">
                                            <Link to={`/marketplace/supplier-directory/${x.supplierId}`}>{x.supplierName}</Link>
                                            &nbsp;•&nbsp;
                                            {g.key != null && g.name != null ? g.name : x.category}
                                        </div>
                                        <Link
                                            to={`/product/${x.slugId}/${x.slug}`}
                                            className="p-0 text-uppercase text-underline font-weight-bold font-size-sm"
                                            target="_blank"
                                        >
                                            View Details
                                        </Link>
                                        {/*
                                    If there's no key, it's an "ungrouped" basket item and can be removed
                                    individually
                                    */}
                                        {g.key == null && (
                                            <Button
                                                color="link"
                                                className="p-0 text-uppercase text-underline font-weight-bold font-size-sm ml-3"
                                                onClick={showDeleteItemModal(x)}
                                            >Remove
                                            </Button>
                                        )}
                                    </div>
                                    <div className="d-flex align-items-end flex-column">
                                        <PricingPopover
                                            productId={x.serviceId}
                                            initialChargeWithVatIfApplicable={x.price + x.totalVat}
                                            initialCharge={x.price}
                                            paymentFrequency={x.paymentFrequency}
                                            postageCharge={x.postage}
                                            minimumQuantity={x.quantity}
                                            isVatRequired={x.totalVat !== 0}
                                            placement="bottom-end"
                                            className={styles.pricingPopper}
                                            componentName="basket"
                                            isDelayedPayment={x.isDelayedPayment}
                                            delayedPaymentFor={x.delayedPaymentFor}
                                            delayedPaymentPeriod={x.delayedPaymentPeriod}
                                            discountRate={x.discountRate}
                                        />
                                        {x.canChangeQuantity && (
                                            <ProductQuantity
                                                classNames="mt-3 justify-self-end"
                                                value={x.quantity}
                                                onValueChanged={(val) => updateQuantity(x, val)}
                                                minimumValue={x.minimumQuantityValue || 1}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            <div className="p-3 border-top">
                <div className="d-flex justify-content-between align-items-center text-uppercase my-2">
                    <h4>Total</h4>
                    <h4>£{toCurrency(basket.totalAmount)}</h4>
                </div>
                {allItems(basket.groups).some(item => item.isOffsitePayment) && (
                    <>
                        <div className="d-flex justify-content-between align-items-center my-2 text-muted font-weight-bold">
                            <h6>DUE NOW</h6>
                            <h6>£{toCurrency(basket.dueNowTotal)}</h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2 text-muted font-weight-bold">
                            <h6>DUE TO SUPPLIER</h6>
                            <h6>
                                <DueToSupplierPopup
                                    placement="left"
                                    items={allItems(basket.groups)
                                        .filter(item => item.isOffsitePayment)
                                        .map(x => ({
                                            id: x.serviceId,
                                            name: x.serviceName,
                                            categoryName: x.category,
                                            subCategoryName: x.subcategory,
                                            paymentFrequency: x.paymentFrequency,
                                            initialCharge: x.price,
                                            isDelayedPayment: x.isDelayedPayment,
                                            delayedPaymentFor: x.delayedPaymentFor,
                                            delayedPaymentPeriod: x.delayedPaymentPeriod,
                                            group: x.group,
                                        }))}
                                />
                            </h6>
                        </div>
                    </>
                )}
                {(showCheckoutButton) && (
                    <Link to="/basket">
                        <Button
                            color="primary"
                            data-testid="checkout-button"
                            block
                            className={classnames(styles.checkoutButton, "font-weight-bold m-auto mt-3")}
                            onClick={onBack || history.goBack}
                        >
                            <i className="fas fa-lock mr-1" />
                            Checkout
                        </Button>
                    </Link>)}
                <div className="mt-3 font-weight-bold">
                    <p className="small text-muted text-center">
                        We use Stripe and Paypal to process our payments. Rest assured that your payment details will be stored securely.
                    </p>
                </div>
            </div>
            {displayConfirmDeleteItemModal && (
                <ConfirmDeleteBasketItemModal
                    itemName={itemToDelete?.serviceName ?? ""}
                    onConfirm={removeItem}
                    onClose={() => setDisplayConfirmDeleteItemModal(false)}
                />
            )}
            {displayConfirmDeleteGroupModal && (
                <ConfirmDeleteBasketItemModal
                    itemName={groupToDelete?.name ?? ""}
                    onConfirm={removeGroup}
                    onClose={() => setDisplayConfirmDeleteGroupModal(false)}
                />
            )}
            <WaitModal isOpen={loading} />
        </div>
    );
};

export { BasketContents };
