import { isArray } from "rxjs/internal-compatibility";

import { isEmptyOrSpaces } from "@/Utils/stringHelper";

export const convertArrayToQueryString = (array: any[], propName: string, prefix: "?" | "&" | "") => {
    let result: string = prefix;

    array.forEach((item, index) => {
        result += `${index === 0 ? "" : "&"}${encodeURIComponent(propName)}=${encodeURIComponent(item)}`;
    });

    return result;
};

export const convertObjectToQueryString = (value: object): string => Object.keys(value)
    .filter(x => !!value[x])
    .reduce(
        (queryStringAccumulator, currentPropertyName, currentIndex) => {
            const currentPropertyValueAsString: string = String(value[currentPropertyName] ?? "");
            if (isEmptyOrSpaces(currentPropertyValueAsString)) return queryStringAccumulator;

            const prefix = currentIndex === 0 ? "" : "&"; // Set ampersand prefix

            if (isArray(value[currentPropertyName])) {
                return `${queryStringAccumulator}${convertArrayToQueryString(value[currentPropertyName], currentPropertyName, prefix)}`;
            }

            return `${queryStringAccumulator}${prefix}${encodeURIComponent(currentPropertyName)}=${encodeURIComponent(currentPropertyValueAsString)}`;
        }, "?",
    );
