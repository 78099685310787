import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import { Notifications } from "@/Components/Notifications";
import Rating from "@/Components/Rating";
import Spinner from "@/Components/Spinner";
import TextArea from "@/Components/TextArea";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import Modal from "@/Modals/Modal";
import formatDateHelper from "@/Utils/formatDateHelper";
import { useAPI } from "@/Apis/useAPI";
import { getReviews, SortColumn, Review } from "@/Apis/Reviews";

const X = Notifications as any;
interface RootState {
    user: any,
    notifications: any
}

export const RateSupplierPage = () => {
    const [reviews, setReviews] = useState<Review[]>([]);
    const [sortColumn, setSortColumn] = useState(SortColumn.ProductName);
    const [sortAsc, setSortAsc] = useState(true);
    const [apiLoading, setApiLoading] = useState(true);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [reviewToSubmit, setReviewToSubmit] = useState("");

    const userState = (state: RootState) => state.user;
    const isSupplier = useSelector(userState);

    const notificationsState = (state: RootState) => state.notifications;
    const notifications = useSelector(notificationsState);

    const { post, loading } = useAPI();
    const toastMessageContext = useToastMessageContext();

    const successMessage = "Review submitted successfully";
    const reviewUrl = "reviews";

    useEffect(() => {
        setApiLoading(true);
        getReviews({ sortColumn, IsAsc: sortAsc, currentPage: 0, searchTerm: "", pageNumber: 0 })
            .then((response) => {
                setReviews(response.reviews);
                setApiLoading(false);
            });
    }, [sortColumn, sortAsc]);

    const setSort = (col: SortColumn) => {
        if (col === sortColumn) {
            setSortAsc(!sortAsc);
        } else {
            setSortAsc(true);
            setSortColumn(col);
        }
    };

    const updateComment = (orderId: string, comment: string) => {
        const supplierReviews = reviews;

        const review = supplierReviews.find(x => x.orderId === orderId);
        // @ts-ignore
        review.comment = comment;

        setReviews([...supplierReviews]);
    };

    const updateRating = (orderId: string, rating: number) => {
        const supplierReviews = reviews;

        const review = supplierReviews.find(x => x.orderId === orderId);
        // @ts-ignore
        review.rating = rating;

        setReviews([...supplierReviews]);
    };

    const submitReview = async (orderId: string, skipConfirmation: boolean = false) => {
        const review = reviews.find(x => x.orderId === orderId);

        if (!review?.comment && !skipConfirmation) {
            setReviewToSubmit(orderId);
            setShowConfirmationModal(true);
        } else {
            const request = {
                reviewId: review?.reviewId || uuid(),
                orderId,
                rating: review?.rating,
                reviewText: review?.comment,
            };

            await post(reviewUrl, request)
                .then(() => {
                    toastMessageContext.setSuccessMessage(successMessage, true);
                })
                .catch(() => {
                    toastMessageContext.setPopupErrorMessage("Failed to save the review", true);
                });
        }
    };

    const confirmSubmission = () => {
        setShowConfirmationModal(false);
        submitReview(reviewToSubmit, true);
    };

    const renderHeader = () => (
        <Row className="d-none d-md-flex border-bottom align-items-center">
            <Col md="3">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.ProductName)}
                >
                    Product <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="2">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.SupplierName)}
                >
                    Supplier <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="2">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.Rating)}
                >
                    Rating <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="4">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                >
                    Comment
                </button>
            </Col>
            <Col md="1" className="text-right" />
        </Row>
    );

    const renderOrders = () => (
        <>
            {
                reviews.map(x => (
                    <Row
                        data-testid={x.orderRef}
                        key={x.orderRef}
                        className={classnames(styles.orderItem, "py-2 mb-3 mb-md-0 border align-items-center")}
                    >
                        <Col md="3" className="pt-2 pt-md-0">
                            <div className="d-flex flex-column">
                                {x.productName}
                                <small>Order Reference: <strong>{x.orderRef}</strong></small>
                                <span>
                                    <small className="mr-1">
                                        Purchased on: {formatDateHelper.format(x.datePaid, "DD/MM/YYYY")}
                                    </small>
                                    <small className={styles.orderTime}>{formatDateHelper.format(x.datePaid, "HH:mm:ss")}
                                    </small>
                                </span>
                            </div>
                        </Col>
                        <Col md="2">
                            <Link
                                to={`/marketplace/supplier-directory/${x.supplierId}`}
                                className="text-primary"
                            >
                                {x.supplierName}
                            </Link>
                        </Col>
                        <Col md="2">
                            <Rating rating={x.rating} onChange={(val) => updateRating(x.orderId, val)} />
                        </Col>
                        <Col md="4" className={classnames(styles.orderReference, "overflow-hidden")}>
                            <TextArea
                                value={x.comment}
                                onChange={e => updateComment(x.orderId, e.target.value)}
                                label=""
                                shortLabelStyle
                                placeholder="Enter your review here"
                                data-testid="review-comment-text-area"
                            />
                        </Col>
                        <Col md="1" className="d-flex justify-content-end">
                            <Button
                                color="primary"
                                disabled={loading || !x.comment || x.rating <= 0}
                                onClick={() => submitReview(x.orderId)}
                                data-testid="review-submit-button"
                            >
                                Submit
                            </Button>
                        </Col>

                    </Row>
                ))
            }
        </>
    );

    if (apiLoading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }

    return (
        <>
            <Modal isOpen={showConfirmationModal} toggle={() => setShowConfirmationModal(false)}>
                <div className="d-flex justify-content-center mb-4 align-items-center" data-testid="confirm-review-modal">
                    <i className="fa fa-info-circle fa-2x text-warning mr-2" />Are you sure you dont want to leave a comment
                </div>
                <div className="d-flex justify-content-center">
                    <Button className="mr-4" onClick={() => setShowConfirmationModal(false)}>No</Button>
                    <Button onClick={confirmSubmission} color="primary">
                        Yes
                    </Button>
                </div>

            </Modal>
            <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                <Col className="bg-white box-shadow border pb-3">
                    <div className="p-3 mt-2">
                        <h3 className="font-weight-bold">Rate Suppliers</h3>
                    </div>
                    <X
                        openOrders={notifications.openOrders}
                        placedOrders={notifications.placedOrders}
                        ordersToReview={notifications.ordersToReview}
                        isSupplier={isSupplier}
                    />
                </Col>
            </Row>

            <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                <Col className="bg-white box-shadow border">
                    <Col className="py-4">
                        {
                            reviews && reviews.length === 0
                                ? <p className="m-2 mt-4">No results found</p>
                                : renderHeader()
                        }
                        {
                            renderOrders()
                        }
                    </Col>
                </Col>
            </Row>
            <Footer />
        </>
    );
};
