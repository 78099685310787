import React from "react";
import PropTypes from "prop-types";

import { PricingPopover } from "@/Components/PricingPopover";

const OrderItemPrice = ({ id, price, paymentFrequency, postage, totalVat, alignLeft, className, displayOnTwoLines, isOffsitePayment, quantity }) => {
    const priceDetail = (
        <div className={alignLeft ? "" : "text-right"}>
            <PricingPopover
                productId={id}
                initialChargeWithVatIfApplicable={price + totalVat}
                initialCharge={price}
                paymentFrequency={paymentFrequency}
                postageCharge={postage}
                minimumQuantity={quantity || 1}
                isVatRequired={totalVat > 0}
                componentName="orderitem"
                displayOnTwoLines={displayOnTwoLines}
                isOffsitePayment={isOffsitePayment}
                className="align-items-end"
            />
        </div>
    );
    return (
        <div className={className}>
            {priceDetail}
        </div>
    );
};

OrderItemPrice.propTypes = {
    id: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    postage: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    totalPrice: PropTypes.number.isRequired,
    totalVat: PropTypes.number,
    alignLeft: PropTypes.bool,
    paymentFrequency: PropTypes.string,
    className: PropTypes.string,
    displayOnTwoLines: PropTypes.bool,
    isOffsitePayment: PropTypes.bool,
    quantity: PropTypes.number,
};

OrderItemPrice.defaultProps = {
    alignLeft: false,
    className: "",
    displayOnTwoLines: false,
};

export { OrderItemPrice };
