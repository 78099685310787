import React from "react";
import { Media, Collapse, Button, Row, Col } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import questions from "./questions.json";
import styles from "./styles.module.scss";

import { SearchTextBox } from "@/Components/SearchTextBox";
import Footer from "@/Components/Footer";
import Spinner from "@/Components/Spinner";
import { SubmitQuestionModal } from "@/Modals/SubmitQuestionModal";
import actions from "@/Store/Global/actions";
import api from "@/Utils/api";

class FAQPage extends React.PureComponent {
    state = {
        loading: true,
        openQuestions: [],
        pageQuestions: [],
        currentQuestions: [],
        displayQuestionModal: false,
    };

    componentDidMount() {
        document.title = "BSC - FAQs";
        const receivedQuestions = this.props.location.pathname === "/supplier-faqs" ? questions.supplier : questions.customer;
        this.setState({ pageQuestions: receivedQuestions, currentQuestions: receivedQuestions });
        this.setState({ loading: false });
    }

    searchText = (value) => {
        this.setState(prevState => ({ currentQuestions: prevState.pageQuestions.filter(x => x.question.includes(value) || x.answer.includes(value)) }));
    };

    submitQuestion = (data) => {
        api.post("FAQ", data)
            .then(() => {
                this.toggleQuestionModal();
                this.props.showToastMessage();
            })
            .catch((error) => {
                this.toggleQuestionModal();
                this.props.showErrorToastMessage(error.message);
            });
    };

    openQuestion = value => () => {
        if (this.state.openQuestions.includes(value)) {
            this.setState(prevState => ({ openQuestions: prevState.openQuestions.filter(x => x !== value) }));
        } else {
            this.setState(prevState => ({ openQuestions: prevState.openQuestions.concat(value) }));
        }
    };

    toggleQuestionModal = () => {
        this.setState(prevState => ({ displayQuestionModal: !prevState.displayQuestionModal }));
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center">
                    <Spinner className="align-self-center" />
                </div>
            );
        }
        return (
            <>
                <div className="bg-white box-shadow flex-grow-1">
                    <div className="box-shadow p-3">
                        <h3 className="py-3 font-weight-bold">Frequently Asked Questions by {this.props.location.pathname === "/supplier-faqs" ? "Suppliers" : "Customers"}</h3>
                        <SearchTextBox
                            className="p-3"
                            placeholder="Search FAQs"
                            onChange={e => this.searchText(e.target.value)}
                        />
                    </div>
                    <Row className="mt-3 mb-5 p-3">
                        <Col md="7">
                            {this.state.currentQuestions.map(x => (
                                <div key={x.question} className="m-3 py-3 border-bottom">
                                    <button type="button" className="d-flex justify-content-between border-0 bg-transparent w-100 p-0" onClick={this.openQuestion(x.question)}>
                                        <h5 className="font-weight-bold">{x.question}</h5>
                                        {this.state.openQuestions.includes(x.question) ? <i className="fa fa-minus h4" /> : <i className="fa fa-plus h4" />}
                                    </button>
                                    <Collapse isOpen={this.state.openQuestions.includes(x.question)}>
                                        {x.link
                                            ? <p className="my-3 flex-grow-1" dangerouslySetInnerHTML={{ __html: x.answer }} /> /* eslint-disable-line react/no-danger */
                                            : <p className="my-3 flex-grow-1 ">{x.answer}</p>}
                                    </Collapse>
                                </div>
                            ))}
                        </Col>

                        <Col md="5" className="d-flex flex-column flex-grow-1 align-items-center py-5">
                            <Media object src={`${window.cdnUrl}misc/calvin-search.png`} alt="Cart" className={classnames(styles.image, "align-self-center mb-3")} />
                            <p>Can&apos;t find the answer you need? Ask for more help</p>
                            <Button onClick={this.toggleQuestionModal} color="primary" className="py-2 px-3">Submit a question</Button>
                        </Col>
                    </Row>

                    {this.state.displayQuestionModal && (
                        <SubmitQuestionModal
                            onClose={this.toggleQuestionModal}
                            onConfirm={this.submitQuestion}
                        />
                    )}
                </div>
                <Footer />
            </>
        );
    }
}

FAQPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    showToastMessage: PropTypes.func.isRequired,
    showErrorToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    showToastMessage: () => dispatch(actions.setToastMessage(true, "Your question has been submitted successfully.")),
    showErrorToastMessage: error => dispatch(actions.setErrorToastMessage(true, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
