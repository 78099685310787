/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Input } from "reactstrap";
import classnames from "classnames";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import styles from "./styles.module.scss";

import CloseIcon from "@/Assets/Icons/Solid/close.svg";
import api from "@/Utils/api";

const searchRecipientsApi = term => api.get(`contacts/search?searchTerm=${term}`);
const searchRecipientsDebounce = AwesomeDebouncePromise(searchRecipientsApi, 400);

const RecipientSearchInput = ({
    onRecipientClick,
    onRecipientRemove,
    isReply,
    clearError,
    selectedRecipients,
    invalid,
}) => {
    const [state, setState] = useState({
        searchText: "",
        contacts: [],
        displaySearchError: false,
    });

    useEffect(() => {
        if (state.searchText) {
            clearError();
            searchRecipientsDebounce(state.searchText)
                .then((response) => {
                    // only show recipients not already selected
                    const contacts = response.contacts.filter(x => !selectedRecipients.find(y => y.id === x.id));
                    setState({
                        ...state,
                        contacts,
                        displaySearchError: contacts.length === 0,
                    });
                });
        }
    }, [state.searchText]);

    const onClick = recipient => () => {
        setState({
            contacts: [],
            displaySearchError: false,
            searchText: "",
        });
        onRecipientClick(recipient);
    };

    return (
        <>
            <Dropdown
                isOpen={state.contacts.length > 0}
                className="flex-grow-1"
                toggle={() => {}}
            >
                <DropdownToggle tag="div">
                    <div
                        className={classnames(
                            styles.container,
                            (state.displaySearchError || invalid) && styles.error,
                            "d-flex flex-wrap justify-content-center",
                        )}
                    >
                        {selectedRecipients.map(x => (
                            <span key={x.name} className={styles.recipients} data-testid="selected-recipients">
                                {x.name}
                                {!isReply
                                && <button type="button" onClick={onRecipientRemove(x)}><CloseIcon /></button>}
                            </span>
                        ))}
                        <Input
                            name="recipients"
                            value={state.searchText || ""}
                            onChange={e => setState({ ...state, searchText: e.target.value })}
                            disabled={isReply}
                            onBlur={() => {
                                setTimeout(() => setState({
                                    contacts: [],
                                    searchText: "",
                                    displaySearchError: false,
                                }), 1000);
                            }}
                            invalid={state.displaySearchError || invalid}
                            data-testid="search-recipients"
                        />
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {state.contacts.map(x => (
                        <DropdownItem
                            key={x.id}
                            onClick={onClick(x)}
                            data-testid={`recipients-item-${x.name}`}
                        >
                            {x.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {state.displaySearchError
            && <FormFeedback className="d-block font-weight-bold">No recipients found.</FormFeedback>}
        </>
    );
};

RecipientSearchInput.propTypes = {
    isReply: PropTypes.bool.isRequired,
    onRecipientClick: PropTypes.func.isRequired,
    onRecipientRemove: PropTypes.func.isRequired,
    selectedRecipients: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        avatarUrl: PropTypes.string,
    })).isRequired,
    invalid: PropTypes.bool,
    clearError: PropTypes.func,
};

RecipientSearchInput.defaultProps = {
    clearError: () => {
    },
    invalid: false,
};

export default RecipientSearchInput;
