import React, { useState } from "react";

import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { Receipt } from "@/Apis/Receipts";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { toPaddedNumber } from "@/Utils/formatStringHelper";
import { Urls } from "@/Apis/urls";

interface Props {
    isOpen: boolean;
    receipt: Receipt;
    onSend: (success: boolean) => void;
    onCancel: () => void;
}

const QuoteAgainModal = ({ isOpen, receipt, onSend, onCancel } : Props) => {
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();
    const { post } = useAPI({ handle500WithRedirect: true });
    const [processing, setProcessing] = useState<boolean>(false);

    const onSendAgain = async () => {
        setProcessing(true);
        post(Urls.receipts.sendToRecipient(receipt.id), {})
            .then(() => {
                setSuccessMessage(`Quotation #${toPaddedNumber(receipt.referenceNumber)} successfully sent again.`, true);
                onSend(true);
            })
            .catch(() => {
                setPopupErrorMessage(`There was a problem when trying to send quotation #${toPaddedNumber(receipt.referenceNumber)} again`, true);
                onSend(false);
            })
            .finally(() => {
                setProcessing(false);
                onCancel();
            });
    };

    return (
        <ConfirmCancelModal
            isOpen={isOpen}
            text={<p className="tw-text-base">Are you sure you want to send quotation <b>#{toPaddedNumber(receipt.referenceNumber)}</b> again?</p>}
            onConfirm={onSendAgain}
            onClose={onCancel}
            loading={processing}
        />
    );
};

export { QuoteAgainModal };
