import { useEffect, useState } from "react";

import { ColumnType, VariationState, VariationType } from "@/Components/ConfigureProductRow/ColumnTypes";
import { ProductVariations } from "@/Utils/ProductVariations";
import { getSupplierVariations } from "@/Apis/Suppliers";
import { useBasketContext } from "@/Context/BasketContext";

interface Props {
    productId: string;
}

const useConfiguredProduct = ({ productId }: Props) => {
    const [productVariations, setProductVariations] = useState<VariationState>({ allowMultipleSelection: false, columns: [] });
    const { basket } = useBasketContext();
    const variationInBasket = basket.groups.flatMap(_ => _.items)?.find(x => x.serviceId === productId);

    const createNewRow = (column: ColumnType, rowId: string, rowIndex: number): VariationType => ({
        rowId,
        rowIndex,
        variationId: column.variationId,
        variationIndex: column.variationIndex,
        name: column.name,
        component: column.component,
        value: "",
    });

    const CreateVariationState = (variationsResponse: ColumnType[]): VariationState => {
        const selectedVariations = ProductVariations.getVariationsOrDefault(variationInBasket?.variations);

        const columns = variationsResponse.map(x => {
            const hasVariation = selectedVariations?.variationItems.find(variation => variation.variationId === x.variationId) !== undefined;

            return {
                ...x,
                checked: hasVariation,
                currentVariations: hasVariation
                    ? selectedVariations.variationItems.filter(variation => variation.variationId === x.variationId)
                    : ProductVariations
                        .getDistinctRows(selectedVariations.variationItems)
                        .map(rowId => createNewRow(x, rowId, selectedVariations.variationItems.find(r => r.rowId === rowId)?.rowIndex as number)),
            };
        });

        return { allowMultipleSelection: selectedVariations.allowMultipleSelection, columns };
    };

    useEffect(() => {
        getSupplierVariations(productId)
            .then((variationsResponse) => setProductVariations(CreateVariationState(variationsResponse)));
    }, [productId]);

    return { productVariations };
};

export default useConfiguredProduct;
