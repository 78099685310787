import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Button, Table } from "reactstrap";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { YesNoButtons } from "@/Components/YesNoButtons";
import { AuthorisedSupplierType } from "@/Apis/SupplierAdministration";

interface Props {
    suppliers: AuthorisedSupplierType[],
    selectedSuppliers: AuthorisedSupplierType[],
    onFlexiblePaymentsChanged(supplier: AuthorisedSupplierType, isAuthorised: boolean): void,
    onSupplierSelectionChanged(supplier: AuthorisedSupplierType, selected: boolean): void,
    onSelectAllChanged(selected: boolean): void,
    onHideDirectoryListingChanged(supplier: AuthorisedSupplierType, isHidden: boolean): void,
}

const DataTable = ({ suppliers, selectedSuppliers, onFlexiblePaymentsChanged, onSupplierSelectionChanged, onSelectAllChanged, onHideDirectoryListingChanged }: Props) => {
    const dispatch = useDispatch();
    const onPush = path => dispatch(push(path));
    const [selectAll, setSelectAll] = useState(false);

    const onSelectAllSelectionChanged = (selected: boolean): void => {
        setSelectAll(selected);
        onSelectAllChanged(selected);
    };

    useEffect(() => {
        setSelectAll(selectedSuppliers.length === suppliers.length);
    }, [suppliers, selectedSuppliers]);

    return (
        <>
            <Table borderless>
                <thead>
                    <tr className={styles.row}>
                        <th className={styles.w5}>
                            <Checkbox
                                id="supplierManagement-selectAll"
                                checked={selectAll}
                                onChange={checked => onSelectAllSelectionChanged(checked)}
                                className="d-inline-block"
                                label=""
                                data-testid="supplierManagement-selectAll"
                            />
                        </th>
                        <th className={styles.w20}>Supplier Name</th>
                        <th className={styles.w5}>Rating</th>
                        <th className={styles.w10}>Flexible Payments Authorised?</th>
                        <th className={styles.w10}>Hide Directory Listing?</th>
                        <th className={styles.w5}>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {suppliers.length === 0 && (
                        <tr className={styles.row}>
                            <td colSpan={5} className="text-center"><p className="m-2">No results found</p></td>
                        </tr>
                    )}

                    {suppliers.length > 0 && suppliers.map(x => (
                        <tr className={styles.row} key={x.id}>
                            <td>
                                <Checkbox
                                    id={`supplierManagement-select-${x.id}`}
                                    checked={selectedSuppliers.indexOf(x) > -1}
                                    onChange={checked => onSupplierSelectionChanged(x, checked)}
                                    className="d-inline-block"
                                    label=""
                                />
                            </td>
                            <td>
                                {x.name || `[${x.email}]`}
                            </td>
                            <td>
                                {x.rating === -1 ? "-" : x.rating.toFixed(2)}
                            </td>
                            <td>
                                <YesNoButtons
                                    label=""
                                    onChange={value => onFlexiblePaymentsChanged(x, value === "Yes")}
                                    value={x.allowFlexiblePayments ? "Yes" : "No"}
                                    fieldId={`supplierAdministration-allowFlexiblePayments-${x.id}`}
                                />
                            </td>
                            <td>
                                <YesNoButtons
                                    label=""
                                    onChange={value => onHideDirectoryListingChanged(x, value === "Yes")}
                                    value={x.hideDirectoryListing ? "Yes" : "No"}
                                    fieldId={`supplierAdministration-hideDirectoryListing-${x.id}`}
                                />
                            </td>
                            <td>
                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => onPush(`/messages/inbox?new&userId=${x.userId}`)}
                                >
                                    Contact
                                </Button>
                            </td>
                        </tr>))}
                </tbody>
            </Table>
        </>
    );
};

export { DataTable };
