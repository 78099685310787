import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ComponentBuilder from "../ComponentBuilder";

import styles from "./styles.module.scss";

const CustomerInformationDetails = ({ field, onUpdated, onDeleted, saveFieldDetails, ...rest }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(rest.isEditing);

    const save = async (value) => {
        setIsUpdating(() => true);
        const response = await saveFieldDetails({ ...field, value });
        onUpdated({ ...field, id: response.id, value });
        setIsUpdating(() => false);
    };

    const deleteField = async () => {
        setIsDeleting(true);
        if (field.id) {
            await onDeleted(field.id);
        }
        onUpdated({
            ...field,
            value: "",
            isSelected: false,
        });
        setIsDeleting(false);
    };

    const onChange = () => async (e) => {
        let value = e.target ? e.target.value : e;
        if (e.postCode) {
            value = JSON.stringify(e);
            await save(value);
        } else {
            onUpdated({ ...field, value });
        }
    };

    const toggleEdit = (currentField) => async () => {
        if (isEditing) {
            await save(currentField.value);
        }
        setIsEditing(() => !isEditing);
    };

    return (
        <div className="d-flex align-items-center">
            <div className={classnames(styles.componentBuilder, "flex-grow-1")}>
                <ComponentBuilder fields={[field]} onChange={onChange} disabled={!isEditing} />
            </div>
            <div className={field.type.toUpperCase() !== "ADDRESSLOOKUP" ? styles.buttonsContainer : styles.addressButtonsContainer}>
                {field.type.toUpperCase() !== "ADDRESSLOOKUP" && (
                    <>
                        <button type="button" onClick={toggleEdit(field)} disabled={isUpdating} className="bg-transparent border-0 p-0 ml-2 mr-2 mt-4 h4 w-25">
                            {isEditing && !isUpdating && (<i className="fas fa-check text-success" />)}
                            {!isEditing && (<i className="fas fa-edit text-primary" />)}
                            {isUpdating && (<i className="fas fa-circle-notch fa-spin text-success" />)}
                        </button>
                    </>
                )}
                <button type="button" onClick={deleteField} disabled={isDeleting} className="bg-transparent border-0 p-0 ml-2 h4 w-25">
                    {!isDeleting
                        ? <i className="fas fa-times text-danger" />
                        : (<i className="fas fa-circle-notch fa-spin text-danger" />)}
                </button>
            </div>
        </div>
    );
};

CustomerInformationDetails.propTypes = {
    field: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string.isRequired,
        displayText: PropTypes.string.isRequired,
        fieldId: PropTypes.string.isRequired,
        isEditing: PropTypes.bool.isRequired,
    }),
    onUpdated: PropTypes.func.isRequired,
    onDeleted: PropTypes.func.isRequired,
    saveFieldDetails: PropTypes.func.isRequired,
};

export default CustomerInformationDetails;
