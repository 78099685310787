import * as constants from "./constants";

const logout$ = () => ({
    type: constants.LOGOUT_$,
});

const loadUser$ = () => ({
    type: constants.LOAD_USER_$,
});

const setUser = payload => ({
    type: constants.SET_USER,
    payload,
});

const clearUser = () => ({
    type: constants.CLEAR_USER,
});

const loadUserError = payload => ({
    type: constants.LOAD_USER_ERROR,
    payload,
});

const toggleImpersonating = payload => ({
    type: constants.TOGGLE_IMPERSONATING,
    payload,
});

export default {
    // epics
    logout$,
    loadUser$,
    // reducer actions
    setUser,
    clearUser,
    loadUserError,
    toggleImpersonating,
};
