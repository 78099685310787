import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const SearchTextBox = ({ placeholder, onChange, disabled }) => (
    <div className={classnames(styles.container, "rounded border d-flex align-items-center px-2 py-1")}>
        <i className="fa fa-search mr-2" />
        <input
            className="flex-grow-1 border-0 px-2 py-1"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
        />
    </div>
);

SearchTextBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

SearchTextBox.defaultProps = {
    disabled: false,
};

export { SearchTextBox };
