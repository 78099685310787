import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "reactstrap";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import { Image } from "@/Components/Image";

const editButton = (text, onChange, showEditHeaderButton) => (
    <div className={classnames(styles.editButton, { [styles.hideEditButton]: !showEditHeaderButton })}>
        <div className="bg-dark text-white">{text}</div>
        <Button type="button" onClick={onChange} data-testid="edit-header">Edit</Button>
    </div>
);

const scrollToShopButton = (onClickScrollToShop) => (
    <Button color="primary" className="tw-absolute tw-right-2 tw-bottom-2" onClick={onClickScrollToShop}>Scroll to shop</Button>
);

const SupplierHeader = ({
    showLogo, logo, showName, name, showTagline, tagline,
    headerColour, headerImage, nameAndTaglineColour, showEditHeaderButton, onClose,
    onEditHeaderClick, onMouseEnter, onMouseLeave, onClickScrollToShop,
}) => {
    if (headerImage) {
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={classnames(styles.container, "d-flex justify-content-lg-center align-items-center position-relative")}
            >
                {editButton("Header", onEditHeaderClick, showEditHeaderButton)}
                {onClose && <CloseButton onClick={onClose} />}
                {onClickScrollToShop && scrollToShopButton(onClickScrollToShop)}
                <Image src={headerImage} alt="header" className="w-100 h-100" />
            </div>
        );
    }
    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classnames(styles.container, "d-flex align-items-center position-relative")}
            style={{ backgroundColor: headerColour, color: nameAndTaglineColour }}
        >
            {onClose && <CloseButton onClick={onClose} />}
            {editButton("Header", onEditHeaderClick, showEditHeaderButton)}
            {onClickScrollToShop && scrollToShopButton(onClickScrollToShop)}
            {showLogo && logo && <Image src={logo} alt="logo" className={classnames(styles.logoImage, "mx-3 mr-lg-2")} />}
            <div className="ml-2 d-none d-lg-block">
                {showName && <h5 className="mb-0">{name || "My Brand"}</h5>}
                {showTagline && <span className="mt-2">{tagline}</span>}
            </div>
        </div>
    );
};

SupplierHeader.propTypes = {
    showLogo: PropTypes.bool.isRequired,
    showName: PropTypes.bool.isRequired,
    name: PropTypes.string,
    showTagline: PropTypes.bool.isRequired,
    headerColour: PropTypes.string.isRequired,
    nameAndTaglineColour: PropTypes.string.isRequired,
    logo: PropTypes.string,
    tagline: PropTypes.string,
    headerImage: PropTypes.string,
    showEditHeaderButton: PropTypes.bool,
    onClose: PropTypes.func,
    onEditHeaderClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClickScrollToShop: PropTypes.func,
};

SupplierHeader.defaultProps = {
    showEditHeaderButton: false,
};

export { SupplierHeader };
