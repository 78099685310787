import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";

import Modal from "@/Modals/Modal";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import TextArea from "@/Components/TextArea";
import { useForm } from "@/Hooks/useForm";
import Checkbox from "@/Components/Checkbox";
import Textbox from "@/Components/Textbox";
import { ReactSelect } from "@/Components/ReactSelect";
import Spinner from "@/Components/Spinner";
import { SendDocumentNotificationModalForm } from "@/Modals/Documents/SendDocumentNotificationModal/Types/Form";
import { NotificationTemplate, NotificationTemplateResponse } from "@/Modals/Documents/SendDocumentNotificationModal/Types/Api";

interface Props {
    isOpen: boolean,
    userId: string,
    onClose: () => void,
}

const SendDocumentNotificationModal = ({ isOpen, userId, onClose }: Props) => {
    const {
        get,
        post,
        loading,
    } = useAPI({ handle500WithToastMessage: true });

    const [templates, setTemplates] = useState<NotificationTemplate[]>();
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    useEffect(() => {
        if (!isOpen) return;

        get<NotificationTemplateResponse>(Urls.notificationTemplate)
            .then(response => {
                setTemplates(response.notificationTemplates);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    }, [isOpen]);

    const {
        onChange,
        onCheckboxChange,
        formState,
        setFormState,
        onReactSelectChange,
    } = useForm<SendDocumentNotificationModalForm>({
        message: "",
        saveTemplate: false,
        templateName: "",
        customerUserId: userId,
        selectedId: "",
    });

    const onSelectOverride = (event: any) => {
        onReactSelectChange("selectedId")(event);
        setFormState(prev => ({ ...prev, message: templates?.find(x => x.id === event.value)?.message ?? prev.message }));
    };

    const onSend = () => {
        post(Urls.notificationTemplate, formState)
            .then(() => {
                setSuccessMessage(formState.saveTemplate
                    ? "Notification sent successfully and template saved"
                    : "Notification sent successfully", true);
                onClose(); // Close the modal once sent
                setFormState({ // Reset the form state
                    message: "",
                    saveTemplate: false,
                    templateName: "",
                    customerUserId: userId,
                    selectedId: "",
                });
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    return (
        <Modal
            title="Send Notification"
            closeButton
            isOpen={isOpen}
            toggle={onClose}
        >
            {loading ? (
                <Spinner className="tw-mx-auto tw-my-10" />
            ) : (
                <div className="tw-flex tw-flex-col -tw-mt-5">
                    <FormGroup>
                        <Label>Choose a template message</Label>
                        <ReactSelect
                            value={{ label: templates?.find(x => x.id === formState.selectedId)?.name }
                                ?? ({ label: "Select a template", value: "" })}
                            options={templates?.map(x => ({
                                label: x.name,
                                value: x.id,
                            }))}
                            isSearchable
                            onChange={onSelectOverride}
                        />
                    </FormGroup>
                    <div className="tw-mb-1 tw-mt-2">Or <span className="md:tw-hidden">send a custom message:</span></div>
                    <TextArea
                        label="Send custom message"
                        onChange={onChange}
                        value={formState.message}
                        maxCharacters={400}
                        name="message"
                        rows={8}
                        className="tw-whitespace-nowrap"
                        labelClassName="tw-hidden md:tw-block"
                    />
                    <div className="tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0 tw-mt-3 md:tw-flex-row md:tw-space-x-5">
                        <Checkbox
                            onChange={onCheckboxChange("saveTemplate")}
                            checked={formState.saveTemplate}
                            id="save-template-checkbox"
                            label="Save as template"
                            className="tw-whitespace-nowrap"
                        />
                        <Textbox
                            disabled={!formState.saveTemplate}
                            placeholder="Template Name"
                            onChange={onChange}
                            name="templateName"
                            value={formState.templateName}
                            className="tw-w-full"
                            maxLength={255}
                        />
                    </div>
                    <div className="tw-mt-3 tw-flex tw-flex-col md:tw-flex-row md:tw-items-start tw-items-center md:tw-justify-start tw-mt-5 tw-space-y-3 md:tw-space-y-0">
                        <Button
                            color="primary"
                            disabled={!formState.message}
                            onClick={onSend}
                            className="md:tw-mr-2 tw-w-full md:tw-w-20"
                        >
                            Send
                        </Button>
                        <Button
                            onClick={onClose}
                            color="transparent"
                            className="!tw-text-brand-primary md:tw-mr-2 tw-w-full md:tw-w-20"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export { SendDocumentNotificationModal };
