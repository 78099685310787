import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { v4 as uuid } from "uuid";
import Select from "react-draggable-multi-select";

import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { TagItem, UserTypeItem } from "@/Apis/UserTypes";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";

const UserTypeTags = () => {
    document.title = "BSC - Tags Management";

    const { get, post } = useAPI({ handle500WithToastMessage: true });

    const [userTypeItems, setUserTypeItems] = useState<UserTypeItem[]>([]);
    const [userTypeActive, setUserTypeActive] = useState<UserTypeItem>();
    const [userTypeFormData, setUserTypeFormData] = useState<UserTypeItem>();

    const [processing, setProcessing] = useState<boolean>(false);

    const maxLengthTagName = 50;
    const [tagInputValue, setTagInputValue] = useState<string>("");
    const [allTagItems, setAllTagItems] = useState<TagItem[]>([]);
    const [selectedTagItems, setSelectedTagItems] = useState<TagItem[]>([]);

    const [saveOpenModal, setSaveOpenModal] = useState<boolean>(false);

    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();

    const getAllTagItems = () => {
        get<TagItem[]>(Urls.userUpsells.tags.base)
            .then(response => setAllTagItems(response || []))
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true));
    };

    const getAllUserTypeItems = () => {
        get<UserTypeItem[]>(Urls.userUpsells.userTypes.base)
            .then(response => setUserTypeItems(response))
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true));
    };

    useEffect(() => {
        getAllTagItems();
        getAllUserTypeItems();
    }, []);

    const handleEditRequest = (userType: UserTypeItem) => {
        setUserTypeFormData(userType);
        setUserTypeActive(userType);

        setSelectedTagItems(userType.selectedTagItems);
    };

    const handleCancelRequest = () => {
        setUserTypeFormData(undefined);
        setUserTypeActive(undefined);
        setProcessing(false);

        setSelectedTagItems([]);
        setAllTagItems([...allTagItems?.filter(x => !x.isNew)]);
    };

    const handleSaveConfirm = () => {
        if (!userTypeFormData) return;
        setProcessing(true);
        post(Urls.userUpsells.userTypes.base,
            { id: userTypeFormData.value,
                selectedTagItems,
                newTagItems: allTagItems.filter(x => x.isNew) })
            .then(() => {
                setSuccessMessage("Tags updated successfully", true);
                handleCancelRequest();
                getAllTagItems();
                getAllUserTypeItems();
            }).catch(() => {
                setPopupErrorMessage("Unfortunately an error occurred", true);
            }).finally(() => {
                setProcessing(false);
                setSaveOpenModal(false);
            });
    };

    const handleSaveRequest = () => {
        if (!userTypeFormData) return;
        if (selectedTagItems?.length === 0) {
            setPopupErrorMessage("Tags is required", true);
            return;
        }
        setSaveOpenModal(true);
    };

    const tagStyles = {
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: "#ffce2b",
        }),
    };

    const handleInputChange = (input) => {
        if (input.length > maxLengthTagName) {
            setTagInputValue(input.substr(0, maxLengthTagName));
        } else {
            setTagInputValue(input.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s\s+/g, " ").replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase()));
        }
    };

    const handleSelectChange = (selectedOptions) => {
        setSelectedTagItems(selectedOptions);
    };

    const handleSelectKeyDown = (event) => {
        if (!tagInputValue?.trim()) return;

        const newOption = {
            value: uuid(),
            label: tagInputValue,
            isNew: true,
        } as TagItem;
        switch (event.key) {
            case "Enter":
            case "Tab":
                if (allTagItems?.some(x => x.label.trim().toLowerCase() === tagInputValue.trim().toLowerCase())) {
                    return;
                }
                setAllTagItems([...allTagItems, newOption]);
                setSelectedTagItems([...selectedTagItems, newOption]);
                setTagInputValue("");
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const selectFilterOption = (option: any, inputValue: string): boolean => (option.label?.toString()?.toLowerCase().match(inputValue?.toLowerCase()) || []).length > 0;

    return (
        <div className="tw-p-6">
            <div className="tw-mb-4">
                <h4 className="tw-font-bold">Tags Management</h4>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th className="tw-w-1/4">User Type</th>
                        <th className="tw-w-2/4">Tags</th>
                        <th className="tw-w-1/4" />
                    </tr>
                </thead>
                <tbody>
                    {(userTypeItems && userTypeItems.length > 0) && userTypeItems.map(userType => (
                        <tr key={userType.value}>
                            <td>{userType.label}</td>
                            <td>
                                <Select
                                    key={`select-${userType.value}`}
                                    options={allTagItems}
                                    value={userTypeActive?.value === userType.value ? selectedTagItems : userType.selectedTagItems}
                                    onChange={handleSelectChange}
                                    onKeyDown={handleSelectKeyDown}
                                    isDisabled={userTypeActive?.value !== userType.value}
                                    inputValue={userTypeActive?.value === userType.value ? tagInputValue : ""}
                                    onInputChange={handleInputChange}
                                    isMulti
                                    isClearable
                                    placeholder="Select Tags"
                                    filterOption={selectFilterOption}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={tagStyles}
                                />
                            </td>
                            <td className="tw-text-center">
                                {userTypeActive?.value === userType.value
                                    ? (
                                        <>
                                            <Button
                                                color="primary"
                                                disabled={processing}
                                                onClick={handleSaveRequest}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                color="primary"
                                                disabled={processing}
                                                className="tw-ml-2"
                                                onClick={handleCancelRequest}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )
                                    : (
                                        <>
                                            <Button
                                                color="primary"
                                                disabled={processing}
                                                onClick={() => handleEditRequest(userType)}
                                            >
                                                Edit
                                            </Button>
                                        </>
                                    )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ConfirmCancelModal
                isOpen={saveOpenModal}
                text="Are you sure that you want to save?"
                onConfirm={handleSaveConfirm}
                onClose={() => setSaveOpenModal(false)}
                loading={processing}
            />
        </div>
    );
};

export { UserTypeTags };
