import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import styles from "./styles.module.scss";

const AddProductBreadcrumbs = ({ navigation, navigationValid = {} }) => {
    let usePrimaryColour = true;
    const dispatch = useDispatch();
    const location = useLocation();

    const onPush = path => () => dispatch(push(path));

    const navigationItem = navigation.find(x => location.pathname.indexOf(x.current) >= 0);

    const setActiveModalColour = (current) => {
        if (navigationItem.current === current) {
            usePrimaryColour = false;
        }
    };

    const navigationItemsToDisplay = navigation.filter(x => !x.disabled);

    return (
        <div className="tw-flex tw-flex-wrap align-items-center font-weight-bold border-bottom pb-3">
            {navigationItemsToDisplay.map((x, i) => {
                const menuItem = (
                    <button type="button" className="bg-transparent m-0 border-0 d-flex" key={x.current} onClick={onPush(x.current)}>
                        <span className={classnames(i === 0 ? "pr-3" : "px-3", usePrimaryColour ? "text-primary" : styles.breadcrumb, "font-weight-bold d-flex my-auto")}>
                            {navigationValid[x.current] && <i className="fas fa-check-circle text-success h5 my-auto mr-2" />}
                            <span className="my-auto">
                                {x.current.replace("-", " ").toUpperCase()}
                            </span>
                        </span>
                        {i !== navigationItemsToDisplay.length - 1 && <i className="text-primary fa fa-chevron-right my-auto" />}
                    </button>
                );
                setActiveModalColour(x.current);
                return menuItem;
            })}
        </div>
    );
};

AddProductBreadcrumbs.propTypes = {
    navigation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    navigationValid: PropTypes.shape({}),
};

export { AddProductBreadcrumbs };
