import React from "react";
import Select from "react-select";

import sortingUtil from "@/Utils/sortingUtil";
import { CategoryType } from "@/Apis/Suppliers/CategoryType";

interface Props {
    selectedCategoryId: string;
    categories: Array<CategoryType>;
    onCategoryChange: (id: string) => void;
    isLoading: boolean;
}

const CategorySelect = ({ selectedCategoryId, categories, onCategoryChange, isLoading }: Props) => {
    const sortedCategories = categories.map(x => ({
        label: x.name,
        value: x.id,
    })).sort((x, y) => sortingUtil.sort(x, y, "label", true));

    const onChange = (selectedOption: { label, value }) => {
        onCategoryChange(selectedOption.value);
    };

    return (
        <Select
            value={sortedCategories.filter(x => x.value === selectedCategoryId)}
            onChange={onChange}
            options={sortedCategories}
            isLoading={isLoading}
        />);
};

export { CategorySelect };
