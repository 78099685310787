/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { AddToCartToastMessage } from "./AddToCartToastMessage";
import { SuccessToastMessage } from "./SuccessToastMessage";
import { AlertStyle, ErrorToastMessage } from "./ErrorToastMessage";

export const initialState = {
    showToastMessage: false,
    showErrorToastMessage: false,
    message: "",
    alertStyle: AlertStyle.NavFullWidth,
    autoHideToast: false,
    setAddToCartMessage: (item, autoHideToast, removedFromToast, basketCount, viewCart, updateQuantity) => {},
    setSuccessMessage: (message, autoHideToast) => {},
    setPopupErrorMessage: (message, autoHideToast) => {},
    setErrorMessage: (message) => {},
};

const ToastMessageContext = React.createContext(initialState);
const useToastMessageContext = () => useContext(ToastMessageContext);

const ToastMessageProvider = ({ children }) => {
    const loggedIn = useSelector(state => state.user.isLoggedIn);
    const [tim, setTim] = useState();
    const [context, setContext] = useState({
        ...initialState,
        alertStyle: loggedIn ? AlertStyle.NavLoggedIn : AlertStyle.NavFullWidth,
    });

    useEffect(() => {
        if (context.autoHideToast) {
            setTim(setTimeout(() => {
                setContext({ message: "", showToastMessage: false, showErrorToastMessage: false });
            }, 5000));
        }
        return () => { clearTimeout(tim); };
    }, [context.autoHideToast]);

    const closeToast = () => {
        setContext({ message: "", showToastMessage: false, showErrorToastMessage: false });
    };

    const setPopupErrorMessage = (message, autoHideToast) => {
        setContext({
            message,
            showErrorToastMessage: true,
            alertStyle: AlertStyle.PopUp,
            autoHideToast,
        });
    };
    const setErrorMessage = (message) => {
        setContext({
            message,
            showErrorToastMessage: true,
            alertStyle: loggedIn ? AlertStyle.NavLoggedIn : AlertStyle.NavFullWidth,
        });
    };

    const setSuccessMessage = (message, autoHideToast) => {
        setContext({ message, showToastMessage: true, autoHideToast });
    };

    const setAddToCartMessage = (item, autoHideToast, removedFromToast, basketCount, viewCart, updateQuantity) => {
        setContext({ item, showAddToCartMessage: true, autoHideToast, removedFromToast, basketCount, viewCart, updateQuantity });
    };

    return (
        <ToastMessageContext.Provider
            value={{
                ...context,
                setPopupErrorMessage,
                setErrorMessage,
                setSuccessMessage,
                setAddToCartMessage,
            }}
        >
            {context.showToastMessage && <SuccessToastMessage message={context.message} />}
            {context.showAddToCartMessage
            && <AddToCartToastMessage
                item={context.item}
                onClose={closeToast}
                onRemove={context.removedFromToast}
                basketCount={context.basketCount}
                viewCart={context.viewCart}
                onUpdateQuantity={context.updateQuantity}
            />}
            {context.showErrorToastMessage && (
                <ErrorToastMessage message={context.message} alertStyle={context.alertStyle} />
            )}

            {children}
        </ToastMessageContext.Provider>
    );
};

ToastMessageProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    useToastMessageContext,
    ToastMessageProvider,
};
