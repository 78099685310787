import React from "react";
import classnames from "classnames";

interface Props {
    imageNumber: number,
    className?: string
}

const ImageWireframe = ({ imageNumber, className }: Props) => (
    <div
        className={classnames("tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-bg-brand-primary tw-bg-opacity-30 tw-flex-grow",
            className)}
    >
        <span className="tw-text-brand-primary">{imageNumber}</span>
        <i className="fa fa-image !tw-text-brand-primary" />
    </div>
);

export { ImageWireframe };
