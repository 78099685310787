import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import { Notifications } from "@/Components/Notifications";
import Rating from "@/Components/Rating";
import Spinner from "@/Components/Spinner";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import formatDateHelper from "@/Utils/formatDateHelper";
import { useAPI } from "@/Apis/useAPI";
import { Review, ReviewResponse, ReviewsRequest, ReviewStatus } from "@/Apis/Reviews";
import { YesNoButtons } from "@/Components/YesNoButtons";
import { Paging } from "@/Components/Paging";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { Urls } from "@/Apis/urls";

const X = Notifications as any;

export const ManageReviewsPage = () => {
    document.title = "BSC - Reviews Management";
    const [reviews, setReviews] = useState<Review[]>([]);
    const [pageLength, setPageLength] = useState(0);
    const toastMessageContext = useToastMessageContext();
    const { get, put } = useAPI({ handle500WithToastMessage: true });
    const [loading, setLoading] = useState<boolean>();
    const [searchQuery, setSearchQuery] = useState<ReviewsRequest>({
        pageNumber: 0,
        searchTerm: "",
        currentPage: 0,
        IsAsc: true,
    });

    const [reloadReviews, setReloadReviews] = useState<boolean>(false);
    const toggleReload = () => setReloadReviews(prev => !prev);

    useEffect(() => {
        setLoading(true);
        get<ReviewResponse>(Urls.reviews.adminGetAll(searchQuery))
            .then((response) => {
                setReviews(response.reviews);
                setPageLength(response.pages);
            })
            .catch(error => {
                if (typeof error === "string") {
                    toastMessageContext.setPopupErrorMessage(error, true);
                } else if (error.message) {
                    toastMessageContext.setPopupErrorMessage(error.message, true);
                }
            })
            .finally(() => setLoading(false));
    }, [searchQuery, reloadReviews]);

    const changeReviewFeatured = async (reviewId: string, isFeatured: boolean): Promise<void> => {
        setLoading(true);
        await put("reviews/admin/changeFeaturedStatus", { reviewId, isFeatured })
            .then(toggleReload)
            .catch(error => {
                if (typeof error === "string") {
                    toastMessageContext.setPopupErrorMessage(error, true);
                } else if (error.message) {
                    toastMessageContext.setPopupErrorMessage(error.message, true);
                }
                setLoading(false);
            });
    };

    const onChangeSearchTerm = (term: string) => setSearchQuery(prevState => ({
        ...prevState,
        searchTerm: term,
        pageNumber: 0,
    }));

    const changePage = (page: number) => {
        setSearchQuery(prevState => ({ ...prevState, pageNumber: page }));
    };

    const changeReviewStatus = (reviewId: string, status: ReviewStatus) => async () => {
        const changedReviews = [...reviews];
        const changedReview = changedReviews.find(x => x.reviewId === reviewId) ?? { status, isApproving: false, isRejecting: false };
        changedReview.isApproving = status === ReviewStatus.Approved;
        changedReview.isRejecting = status === ReviewStatus.Rejected;
        setReviews(changedReviews);
        await put("reviews/admin/changeReviewStatus", { reviewId, status })
            .then(() => {
                changedReview.status = status;
                changedReview.isApproving = false;
                changedReview.isRejecting = false;
                if (status === ReviewStatus.Approved) {
                    toastMessageContext.setSuccessMessage("Supplier Rating approved!", true);
                } else {
                    toastMessageContext.setPopupErrorMessage("Supplier Rating rejected!", true);
                }
                setReviews(changedReviews);
            });
    };

    const renderHeader = () => (
        <Row className="d-none d-md-flex border-bottom align-items-center">
            <Col md="3">
                Product
            </Col>
            <Col md="2">
                Supplier
            </Col>
            <Col md="1">
                Rating
            </Col>
            <Col md="2">
                Comment
            </Col>
            <Col md="2">
                Featured
            </Col>
            <Col md="2" className="text-right" />
        </Row>
    );

    return (
        <div className="tw-px-3">
            <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                <Col className="bg-white box-shadow border pb-3">
                    <div className="p-3 mt-2">
                        <h3 className="font-weight-bold">Rate Suppliers</h3>
                    </div>
                    <X
                        isSupplier={false}
                    />
                </Col>
            </Row>
            <div className="tw-w-72 tw-relative tw-mt-8 tw-ml-auto tw-mr-4">
                <DebounceTextbox
                    placeholder="Search Supplier or Product"
                    callBack={onChangeSearchTerm}
                    name="Search Notes"
                    inputClasses="!tw-pr-8"
                />
                <i className="fas fa-search tw-absolute tw-right-4 tw-top-2" />
            </div>
            <Row className="px-0 px-md-3 flex-shrink-0">
                <Col className="bg-white pb-2">
                    {!loading && reviews && reviews.length === 0
                        ? <p className="m-2 mt-4">No results found</p>
                        : renderHeader()}
                    {(loading || !reviews) && (
                        <Spinner className="tw-mx-auto tw-mt-10" size="40" />
                    )}
                    {!loading && reviews && reviews.length > 0 && reviews.map(x => (
                        <Row
                            data-testid={x.orderRef}
                            key={x.orderRef}
                            className={classnames(
                                x.status === ReviewStatus.Approved && styles.approvedBackground,
                                x.status === ReviewStatus.Rejected && styles.rejectedBackground,
                                styles.orderItem,
                                "py-2 mb-3 mb-md-0 border align-items-center",
                            )}
                        >
                            <Col md="3" className="pt-2 pt-md-0 d-flex flex-column">
                                {x.productName}
                                <small>Order Reference: <strong>{x.orderRef}</strong></small>
                                <span>
                                    <small className="mr-1">
                                        Purchased on: {formatDateHelper.format(x.datePaid, "DD/MM/YYYY")}
                                    </small>
                                    <small className={styles.orderTime}>{formatDateHelper.format(x.datePaid, "HH:mm:ss")}
                                    </small>
                                </span>
                            </Col>
                            <Col md="2">
                                <Link
                                    to={`/marketplace/supplier-directory/${x.supplierId}`}
                                    className="text-primary"
                                >
                                    {x.supplierName}
                                </Link>
                            </Col>
                            <Col md="1">
                                <Rating rating={x.rating} />
                            </Col>
                            <Col md="2">
                                {x.comment}
                            </Col>
                            <Col md="2">
                                <YesNoButtons
                                    label=""
                                    onChange={() => changeReviewFeatured(x.reviewId, !x.isFeatured)}
                                    value={x.isFeatured ? "Yes" : "No"}
                                    fieldId={`isFeatured-${x.reviewId}`}
                                />
                            </Col>
                            <Col md="2" className="d-flex my-3">
                                <Button
                                    className={classnames(styles.button, "mr-2")}
                                    color="primary"
                                    disabled={x.isApproving || x.status === ReviewStatus.Approved}
                                    onClick={changeReviewStatus(x.reviewId, ReviewStatus.Approved)}
                                    data-testid="approveReviewButton"
                                >
                                    {!x.isApproving && (<span>Approve</span>)}
                                    {x.isApproving && (<Spinner size="14" className="tw-mx-auto" />)}
                                </Button>
                                <Button
                                    className={styles.button}
                                    color="danger"
                                    disabled={x.isRejecting || x.status === ReviewStatus.Rejected}
                                    onClick={changeReviewStatus(x.reviewId, ReviewStatus.Rejected)}
                                    data-testid="rejectReviewButton"
                                >
                                    {!x.isRejecting && (<span>Reject</span>)}
                                    {x.isRejecting && (<Spinner size="14" className="tw-mx-auto" />)}
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </Col>
            </Row>
            <Paging onChange={changePage} activePage={searchQuery.pageNumber} pageCount={pageLength} />
            <Footer />
        </div>
    );
};
