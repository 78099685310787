import React, { useEffect, useState } from "react";
import { Col, TabContent, Row, TabPane, Badge, Button } from "reactstrap";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import classnames from "classnames";
import { useHistory, useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";

import { useMessages } from "../MessageListingPage/useMessages";

import { MessageDetailsTab } from "./MessageDetailsTab";
import { AttachmentsTab } from "./AttachmentsTab";
import styles from "./styles.module.scss";

import NoMessages from "@/Components/Messages/NoMessages";
import MessageFilters, { filters } from "@/Components/Messages/MessageFilters";
import MessagingSideBar from "@/Components/Messages/MessagingSideBar";
import MessageSearch, { FolderAction } from "@/Components/Messages/MessageSearch";
import Checkbox from "@/Components/Checkbox";
import CloseButton from "@/Components/CloseButton";
import Spinner from "@/Components/Spinner";
import Footer from "@/Components/Footer";
import { useMessagingContext } from "@/Context/MessagingContext";
import { useAPI } from "@/Apis/useAPI";
import { Folder, MessagingUrls, ReplyToConversationRequest } from "@/Apis/Messaging";
import globalActions from "@/Store/Global/actions";
import formatDateHelper from "@/Utils/formatDateHelper";

const tabs = {
    details: "details",
    attachments: "attachments",
};

const MessageDetailsPage = () => {
    const {
        messages,
        loading,
        messageQuery,
        setMessageQuery,
        actOnSelectedMessages,
    } = useMessages();
    const messagingContext = useMessagingContext();
    const { post } = useAPI({ handle500WithRedirect: true });
    const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState(tabs.details);

    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const pushToDetails = (currentFolder, id) => dispatch(push({ pathname: `/messages/${currentFolder.toLowerCase()}/${id}` }));
    const pushToFolder = newFolder => dispatch(push(`/messages/${newFolder.toLowerCase()}`));

    useEffect(() => {
        messagingContext.loadMessage(match.params.id, match.params.folder);
        messagingContext.loadEnquiryAnswers(match.params.id);
    }, [match.params.id]);

    useEffect(() => {
        // remove the search term from the url
        if (location.search.indexOf("search") > 0) {
            history.push(location.pathname);
        }
    }, [location.search]);

    const onSearch = (value: string) => {
        setMessageQuery(prevState => ({ ...prevState, currentPage: 0, searchTerm: value }));
    };

    const searchDebounce = AwesomeDebouncePromise(onSearch, 300);

    const moveToFolder = (folderAction: FolderAction) => () => {
        actOnSelectedMessages(folderAction, selectedMessages);
    };

    const onReply = (conversationId: string, data: ReplyToConversationRequest) => {
        post(MessagingUrls.replyToConversation(conversationId), data).then(() => dispatch(globalActions.setToastMessage(true, "Message Successfully Sent")));
    };

    const onFilterChange = filter => () => {
        setMessageQuery(prevState => ({ ...prevState, status: filter }));
    };

    const onMessageCheck = (id) => () => {
        if (id === filters.all) {
            // if all are already selected, empty the selected array
            if (selectedMessages.length === messages?.length) {
                setSelectedMessages([]);
            } else if (messages !== null) {
                setSelectedMessages(messages.map(x => x.conversationId));
            }
            return;
        }

        let messageList = selectedMessages;
        if (selectedMessages.indexOf(id) > -1) {
            messageList = selectedMessages.filter(x => x !== id);
        } else {
            messageList.push(id);
        }

        setSelectedMessages([...messageList]);
    };

    const onMessageSelect = id => () => {
        pushToDetails(match.params.folder || "inbox", id);
    };

    const changeTab = newActiveTab => () => {
        setActiveTab(newActiveTab);
    };

    const changeFolder = (newFolder: Folder) => {
        pushToFolder(newFolder);
        setMessageQuery(prevState => ({ ...prevState, currentPage: 0, searchTerm: "", status: "all" }));
    };

    const renderMessages = () => (
        <Col md="4" className="d-none d-lg-flex flex-column p-0">
            {messages && messages
                .map(x => (
                    <div
                        key={x.conversationId}
                        className={classnames(
                            styles.message,
                            !x.isRead && styles.unread,
                            match.params.id === x.conversationId && styles.active,
                            "border-bottom d-flex p-3",
                        )}
                        tabIndex={-1}
                        role="button"
                        onClick={onMessageSelect(x.conversationId)}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                onMessageSelect(x.conversationId);
                            }
                        }}
                    >
                        <div className="d-flex align-items-center mx-2">
                            <Checkbox
                                onChange={onMessageCheck(x.conversationId)}
                                id={x.conversationId}
                                checked={selectedMessages.some(conversationId => conversationId === x.conversationId)}
                                className="mr-0"
                            />
                        </div>
                        <div className="flex-grow-1 my-2">
                            <p className="text-primary text-underline">{x.senderRecipients.join(", ")}</p>
                            <p className="font-weight-bold">{x.subject}</p>
                            <p className="mb-0">{x.conversationReference}</p>
                        </div>

                        <div className="d-flex flex-column justify-content-end align-items-end m-2 m-md-0 m-lg-2">
                            <p className="text-warning px-0">{x.isEnquiry && <i className="fa fa-question-circle" />}</p>
                            <span className="text-right">{formatDateHelper.format(x.sentAt, "DD MMM")}</span>
                            <span className="text-right">{formatDateHelper.format(x.sentAt, "h:mm A")}</span>
                        </div>
                    </div>
                ))}
        </Col>);

    const renderDetails = () => (
        <Col className="border-left d-flex flex-column flex-grow-1 p-0 pb-4">
            <div className="border-bottom px-4 pt-3 d-flex align-items-center">
                <button
                    type="button"
                    className={classnames(
                        activeTab === tabs.details ? styles.activeTab : styles.tab,
                        activeTab === tabs.details && "text-primary font-weight-bold border-bottom",
                        "mr-4 border-0 bg-transparent pb-2",
                    )}
                    onClick={changeTab(tabs.details)}
                >
                    Details
                </button>
                <button
                    type="button"
                    className={classnames(
                        activeTab === tabs.attachments ? styles.activeTab : styles.tab,
                        activeTab === tabs.attachments && "text-primary font-weight-bold border-bottom",
                        "mr-4 border-0 bg-transparent pb-2",
                    )}
                    onClick={changeTab(tabs.attachments)}
                >
                    Attachments
                    {messagingContext.message.attachments.length > 0 && (
                        <Badge pill className="ml-2 bg-pink">
                            {messagingContext.message.attachments.length > 10 ? "10+" : messagingContext.message?.attachments.length}
                        </Badge>
                    )}
                </button>
                <CloseButton onClick={() => pushToFolder(match.params.folder)} className={styles.closeButton} />
            </div>

            <TabContent activeTab={activeTab}>
                <TabPane tabId={tabs.details}>
                    <MessageDetailsTab
                        message={messagingContext.message}
                        enquiryFormFields={messagingContext.enquiryFormFields}
                        onReply={onReply}
                    />
                </TabPane>
                <TabPane tabId={tabs.attachments}>
                    <AttachmentsTab attachments={messagingContext.message.attachments} />
                </TabPane>
            </TabContent>
        </Col>
    );

    const renderContent = () => {
        if (loading || !messagingContext.message?.conversationId) {
            return (
                <Col xs="12" className="d-flex justify-content-center align-items-center">
                    <Spinner />
                </Col>
            );
        }
        if (messages && messages.length === 0) {
            return (
                <Col xs="12" className="d-flex justify-content-center align-items-center">
                    <NoMessages currentFolder={match.params.folder || "inbox"} />
                </Col>
            );
        }

        return (
            <Col xs="12">
                <Row>
                    {renderMessages()}
                    {renderDetails()}
                </Row>
            </Col>
        );
    };

    return (
        <div>
            <Row className="bg-white border-bottom">
                <MessagingSideBar
                    folder={match.params.folder}
                    changeFolder={changeFolder}
                    loading={loading}
                    className="d-none d-lg-block border-bottom"
                />
                <Col className="d-flex flex-column flex-grow-1 box">
                    <div className="d-lg-none">
                        <Button className="mt-2 bg-transparent border-0 text-primary font-weight-bold" onClick={() => pushToFolder(match.params.folder)}>
                            <i className="fa fa-arrow-left mr-2" />
                            Back to Inbox
                        </Button>
                    </div>
                    <MessageFilters className="d-none d-lg-flex" filterStatus={messageQuery.status} onFilterChange={onFilterChange} />
                    <MessageSearch
                        disabled={selectedMessages.length === 0}
                        allChecked={messages?.length !== 0 && selectedMessages.length === messages?.length}
                        onSelectAll={onMessageCheck(filters.all)}
                        onSearch={searchDebounce}
                        moveToFolder={moveToFolder}
                        currentFolder={match.params.folder}
                        className="d-none d-lg-flex"
                    />
                    <Row className="flex-grow-1">{renderContent()}</Row>
                </Col>
            </Row>
            <Footer />
        </div>
    );
};

export { MessageDetailsPage };
