import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import CloseButton from "@/Components/CloseButton";

const FilterOrdersModal = ({ isOpen, onClose, toggleDateFilter, dateFilters, currentDateFilter, toggleStatusFilter, statusFilters, currentStatusFilters }) => (
    <div className="d-flex align-items-center">
        <Modal isOpen={isOpen} centered autoFocus>
            <ModalBody>
                <h4 className="font-weight-bold mt-0 mb-3">Filter by</h4>
                <CloseButton onClick={onClose} />
                <div className="d-flex flex-column align-items-baseline">
                    {Object.keys(dateFilters)
                        .map((x) => {
                            const filter = dateFilters[x];
                            return (
                                <Button
                                    key={filter}
                                    color="text"
                                    className={classnames(styles.dateButton, `pl-0 ${currentDateFilter === filter && "font-weight-bold"}`)}
                                    onClick={() => toggleDateFilter(filter)}
                                >
                                    {x}
                                </Button>
                            );
                        })}
                    <hr className="w-100" />
                    {Object.keys(statusFilters)
                        .map((x) => {
                            const statusFilter = statusFilters[x];
                            return (
                                <Checkbox
                                    key={statusFilter}
                                    id={statusFilter.toString()}
                                    value={statusFilter}
                                    onChange={() => toggleStatusFilter(statusFilter)}
                                    checked={currentStatusFilters.includes(statusFilter)}
                                    label={x}
                                    className="pb-2"
                                />
                            );
                        })}

                </div>
            </ModalBody>
        </Modal>
    </div>
);

FilterOrdersModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    toggleDateFilter: PropTypes.func.isRequired,
    dateFilters: PropTypes.shape({}).isRequired,
    currentDateFilter: PropTypes.number,
    toggleStatusFilter: PropTypes.func.isRequired,
    statusFilters: PropTypes.shape({}).isRequired,
    currentStatusFilters: PropTypes.arrayOf(PropTypes.number),
};

export default FilterOrdersModal;
