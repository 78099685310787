import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";

import CategoriesPage from "./CategoriesAdminPage";
import { UserListingPage } from "./UsersAdminPage/UserListingPage";
import { UserDetailsPage } from "./UsersAdminPage/UserDetailsPage";

export class Manage extends React.PureComponent {
    render() {
        if (!this.props.isAdmin) {
            return <Redirect to="/" />;
        }

        return (
            <Switch>
                {/* A User can"t go to a Private Route if they are not authenticated */}
                <Route path="/manage/categories" component={CategoriesPage} />
                <Route path="/manage/users" component={UserListingPage} />
                <Route path="/manage/user/:id" component={UserDetailsPage} />

                <Redirect to="/manage/categories" />
            </Switch>
        );
    }
}

Manage.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isAdmin: state.user.isAdmin,
});

export default connect(mapStateToProps)(Manage);
