import React, { useState } from "react";

import Spinner from "@/Components/Spinner";
import Textbox from "@/Components/Textbox";
import CreateCustomerAccountModal from "@/Modals/CreateCustomerAccountModal";
import { useAPI } from "@/Apis/useAPI";
import { CreateReceiptFormFields, ReceiptRecipient } from "@/Apis/Receipts/Create";
import useDidMountEffect from "@/Hooks/useDidMountEffect";
import { useDebounce } from "@/Hooks/useDebounce";
import { Urls } from "@/Apis/urls";

interface Props {
    onChange: (search: string) => void,
    label?: string,
    className?: string,
    testId?: string,
    validationError?: string,
    setFormValues: React.Dispatch<React.SetStateAction<CreateReceiptFormFields>>,
    formValues: CreateReceiptFormFields,
    disabled?: boolean,
}

const ReceiptsRecipientsSearch = ({ onChange,
    label,
    className,
    testId = "email",
    validationError,
    setFormValues,
    formValues,
    disabled }: Props) => {
    // Searching
    const { get } = useAPI({ handle500WithToastMessage: true });
    const [recipients, setRecipients] = useState<ReceiptRecipient[]>();
    const [listingOpen, setListingOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const debounceEmailSearch = useDebounce(formValues.recipient.email ?? "", 500);
    const [success, setSuccess] = useState<boolean>(false);

    // Sign up
    const [displayRegistrationModal, setDisplayRegistrationModal] = useState<boolean>(false);

    useDidMountEffect(() => {
        if (!debounceEmailSearch || debounceEmailSearch.length === 0) { // Don't emailSearch for empty data
            setRecipients(undefined);
            setListingOpen(false);
            return;
        }

        setLoading(true);

        get<ReceiptRecipient[]>(Urls.receipts.recipientSearch(debounceEmailSearch))
            .then(result => {
                setRecipients(result);
            })
            .catch(() => setError("There is a problem with the user email search at this time"))
            .finally(() => {
                onChange(debounceEmailSearch);
                setLoading(false);
            });
    }, [debounceEmailSearch]);

    const onBlur = () => setListingOpen(false);
    const onFocus = () => setListingOpen(true);

    const onSelectOverride = (recipient: ReceiptRecipient) => {
        setFormValues({
            ...formValues,
            recipient,
        });
        setListingOpen(false);
    };

    const onRegisterNewUser = () => setDisplayRegistrationModal(true);

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            recipient: {
                ...formValues.recipient,
                email: e.target.value,
            },
        });
    };

    const onCloseRegisterCustomerModal = () => {
        setDisplayRegistrationModal(false);
        setListingOpen(false);
    };

    return (
        <div className={`${className} tw-relative`} onBlur={onBlur}>
            <Textbox
                label={label}
                value={formValues.recipient.email ?? ""}
                onChange={onTextChange}
                disableAutoComplete
                onFocus={onFocus}
                data-testid={testId}
                error={validationError}
                disabled={disabled}
            />
            {listingOpen && !loading && !success && !displayRegistrationModal && (
                <div className="tw-absolute tw-bg-white tw-z-[100] tw-w-full tw-shadow-2xl tw-border -tw-mt-2 tw-max-h-72 tw-overflow-y-auto">
                    {recipients && recipients.map(x => (
                        <button
                            key={x.id}
                            type="button"
                            className={`tw-h-10 tw-border-b-2 tw-flex tw-items-center tw-px-4 hover:tw-cursor-pointer
                             hover:tw-bg-gray-200 tw-w-full tw-break-all tw-whitespace-nowrap`}
                            onMouseDown={() => onSelectOverride(x)}
                            data-testid={x.id}
                        >
                            {x.firstName} {x.surname} [{x.email}]
                        </button>
                    ))}
                    <button
                        className="tw-h-10 tw-border-b-2 tw-flex tw-w-full tw-items-center tw-space-x-2 tw-px-4 tw-text-sky-500 hover:tw-cursor-pointer hover:tw-bg-gray-200"
                        type="button"
                        onMouseDown={onRegisterNewUser}
                    >
                        <span className="tw-font-extrabold tw-text-base">+</span>
                        <span className="tw-font-semibold">Register as BSC User</span>
                    </button>
                </div>
            )}
            {listingOpen && loading && !success && !displayRegistrationModal && (
                <div className="tw-absolute tw-bg-white tw-z-[100] tw-w-full tw-shadow-2xl tw-border -tw-mt-2 tw-max-h-72 tw-overflow-y-auto">
                    <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-py-2 tw-h-32"><Spinner /></div>
                </div>
            )}
            <CreateCustomerAccountModal
                formValues={formValues}
                setFormValues={setFormValues}
                isOpen={displayRegistrationModal}
                onClose={onCloseRegisterCustomerModal}
                onSuccess={() => setSuccess(true)}
            />
            {error && <div className="tw-text-red-600 tw-font-semibold">{error}</div>}
        </div>
    );
};

export default ReceiptsRecipientsSearch;
