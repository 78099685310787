import React, { useEffect, useState } from "react";
import { Button, Label } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import { OrderItemPrice } from "@/Components/OrderItemPrice";
import { Image } from "@/Components/Image";
import Checkbox from "@/Components/Checkbox";
import { CompleteOrderResponse, BasketType } from "@/Apis/Basket";
import { useAPI } from "@/Apis/useAPI";

const PartnerCheckout = () => {
    const { loading, get, post } = useAPI({ handle500WithRedirect: true });
    const [bscBasket, setBscBasket] = useState<BasketType | null>(null);
    const [acceptAllTerms, setAcceptAllTerms] = useState(false);
    const history = useHistory();
    const onAcceptAllTerms = () => setAcceptAllTerms(currentState => !currentState);

    useEffect(() => {
        get<BasketType>("basket")
            .then((response) => {
                setBscBasket(response);
            });
    }, []);

    const onCheckout = async () => {
        const response = await post<CompleteOrderResponse>("basket/completeOrder", {
            source: "offlineinvoice",
            orderIds: bscBasket?.groups?.flatMap(_ => _.items)?.map(x => x.orderId),
        });
        const payload = btoa(JSON.stringify(response));
        history.push(`/partners/checkout/invoice/${response.payments[0]?.paymentId}/complete?payload=${payload}`);
    };

    return (
        <>
            <div className="d-flex flex-grow-1 justify-content-center overflow-auto">
                <div className={classnames(styles.widths, "m-3")}>
                    <div className="d-flex mb-2">
                        <button type="button" className="p-0 border-0 text-primary bg-transparent font-weight-bold" onClick={history.goBack}>
                            <i className="far fa-arrow-alt-circle-left mr-1" /> Redeem a Package
                        </button>
                    </div>
                    <h4 className="font-weight-bold mt-1 mb-4">Checkout</h4>
                    {loading && (<div className="text-center py-2"><Spinner className="my-4" /></div>)}

                    {!loading && (
                        <>
                            {bscBasket?.totalItems === 0 && (
                                <div className="font-weight-bold text-center py-2 my-4 text-danger">
                                    Basket is empty
                                </div>)}
                            {bscBasket?.groups?.flatMap(_ => _.items)?.map(product => (
                                <div className="rounded box-shadow m-1 mt-3" key={product.productId}>
                                    <div className={classnames(styles.orderItemPrice, "p-3")}>
                                        <div className="font-weight-bold h6">{product.serviceName}</div>
                                        <OrderItemPrice
                                            id={product.productId}
                                            alignLeft
                                            price={product.price}
                                            postage={product.postage}
                                            totalPrice={product.totalPrice}
                                            totalVat={product.totalVat}
                                            paymentFrequency={product.paymentFrequency}
                                        />
                                    </div>
                                    <div className={classnames(styles.border, "p-3 font-weight-bold h6 bg-white border")}>
                                        <Image src={product.serviceImage} alt="service" className={classnames(styles.productImage, "mr-2")} />
                                        {product.supplierName}
                                    </div>
                                    {product.termsDocument && (
                                        <div className="p-3 d-flex justify-content-center align-middle text-underline font-weight-bold">
                                            <div>
                                                <a href={`${window.cdnUrl}${product.termsDocument}`} rel="noopener noreferrer" target="_blank">
                                                    Terms and Conditions
                                                </a>
                                            </div>
                                        </div>)}
                                </div>))}
                        </>)}
                </div>

            </div>
            <div className="d-flex flex-grow-0 mx-4 mb-4 justify-content-center">
                <div className={styles.widths}>
                    <div className={classnames(styles.total, "d-flex font-weight-bold p-3")}>
                        <div className="flex-grow-1 h6">Total ({(bscBasket?.totalItems ?? 0) > 1 ? `${(bscBasket?.totalItems ?? 0)} items` : "1 item"})</div>
                        <div className="h6">{bscBasket?.total}</div>
                    </div>
                    <div className="d-flex mt-3 mb-1">
                        <div>
                            <Checkbox
                                testId="acceptTermsCheckbox"
                                id="terms"
                                checked={acceptAllTerms}
                                onChange={onAcceptAllTerms}
                                className="d-inline-block mr-0"
                            />
                        </div>
                        <div className="flex-grow-1">
                            <Label for="terms">I agree to all the payment terms if each product and their suppliers.</Label>
                        </div>
                    </div>
                    <Button color="primary" block className="py-3" onClick={onCheckout} disabled={!acceptAllTerms}>Complete transaction</Button>
                </div>
            </div>
        </>);
};

export { PartnerCheckout };
