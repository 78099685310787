import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import Spinner from "@/Components/Spinner";
import formatDateHelper from "@/Utils/formatDateHelper";
import { toCurrencyWithCommas } from "@/Utils/formatStringHelper";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import PopoverMenu from "@/FlexPlan/Components/PopoverMenu";
import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import { Participant, ParticipantStatus } from "@/FlexPlan/Types/Participant";
import { ManageParticipantColumns, ParticipantManagementColumnSort } from "@/FlexPlan/Types/Participant/participantColumns";
import { ListingScreenDropdownFilter } from "@/FlexPlan/Components/StatusFilterDropdown";
import ParticipantStatusIcon from "@/FlexPlan/Components/ParticipantStatusIcon";
import { getFiltersFromEnum } from "@/FlexPlan/Utils/enum";
import { renderParticipantStatusLabel } from "@/FlexPlan/Types/Participant/participantStatuses";

const columns: ManageParticipantColumns[] = [
    { name: "Participant", columnSort: ParticipantManagementColumnSort.Participant },
    { name: "NDIS No.", columnSort: ParticipantManagementColumnSort.NDIS },
    { name: "Date of Birth", columnSort: ParticipantManagementColumnSort.DateOfBirth },
    { name: "Status", columnSort: ParticipantManagementColumnSort.Status, isFilter: true },
    { name: "Balance", columnSort: ParticipantManagementColumnSort.Balance },
    { name: "Plan ID", columnSort: ParticipantManagementColumnSort.PlanId },
    { name: "Plan Start Date", columnSort: ParticipantManagementColumnSort.PlanStartDate },
    { name: "Plan End Date", columnSort: ParticipantManagementColumnSort.PlanEndDate },
];

const ParticipantManagement = () => {
    const { push } = useHistory();
    const { pathname } = useLocation();
    const [error, setError] = useState<string>();

    // Hooks
    const { get, put, loading } = useAPI({ handle500WithToastMessage: true });
    const {
        setItemsForPagination,
        onSort,
        paginatedItems,
        onSearch,
        sortColumn,
        sortDescending,
        pageNumber,
        onPageSelected,
        totalPages,
        onChangeFilter,
        filters,
        initialItems,
    } = useClientSidePagination<Participant>(["fullname", "ndis"],
        {
            field: "status",
            filters: getFiltersFromEnum(ParticipantStatus, false, renderParticipantStatusLabel),
        });
    const [updatingPlans, setUpdatingPlans] = useState(false);

    const getParticipants = () => get<Participant[]>(FlexPlanUrls.participants.base)
        .then(response => {
            setItemsForPagination(response.map(x => ({ ...x, fullname: `${x.firstname} ${x.surname}` })));
            setUpdatingPlans(false);
        })
        .catch(error => setError(error));

    useEffect(() => {
        getParticipants();
    }, [pathname]);

    const handleGetUpdateClick = () => {
        setUpdatingPlans(true);
        Promise.all((initialItems ?? []).map(x => (put(`flexplan/participant/${x.id}/plans`, { }))))
            .then(() => getParticipants());
    };

    const renderSortIcon = (column: ParticipantManagementColumnSort | undefined) => {
        if (!column) { // It's not a sortable column
            return <></>;
        }

        if (sortColumn && sortColumn === column) {
            if (sortDescending) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }

        return <i className="fa fa-sort" />;
    };

    return (
        <FlexPlanBasicWrapper title="Manage Participants" className="tw-flex tw-flex-col tw-space-y-3">
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4">
                <div className="tw-relative tw-w-full md:tw-w-1/3">
                    <DebounceTextbox
                        placeholder="Search Participant"
                        callBack={onSearch}
                        name="Search Quotations"
                    />
                    <i className="fa-lg fas fa-search tw-absolute tw-right-4 tw-top-2" />
                </div>
                <Button color="primary" className="tw-h-9" onClick={() => push("/participants/register")}>Add Participant</Button>
                <Button color="primary" className="tw-h-9 tw-mt-2 md:tw-mt-0" disabled={updatingPlans || loading} onClick={handleGetUpdateClick}>Get Update</Button>
                {filters && (
                    <ListingScreenDropdownFilter
                        filters={filters}
                        onChange={onChangeFilter}
                        className="tw-mt-2"
                        buttonName="Status"
                        titleName="Status"
                    />
                )}
            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        {columns.map(col => (
                            <th style={{ width: "11.1%" }} key={col.name}>
                                <button
                                    type="button"
                                    className="bg-transparent p-0 border-0"
                                    onClick={() => col.columnSort && onSort(col.columnSort)}
                                >
                                    {col.name}&nbsp;
                                    {renderSortIcon(col.columnSort)}&nbsp;
                                </button>
                            </th>
                        ))}
                        {/* Actions column */}
                        <th style={{ width: "11.1%" }} />
                    </tr>
                </thead>
                <tbody>
                    {(loading || updatingPlans) && (
                        <tr>
                            <td colSpan={9}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                        </tr>
                    )}
                    {error && (
                        <tr className="tw-text-red-600 tw-mx-auto tw-mt-4">
                            <td colSpan={9}>{error}</td>
                        </tr>
                    )}
                    {!loading && !updatingPlans && !error && paginatedItems?.length === 0 && (
                        <tr className="tw-mx-auto tw-mt-4">
                            <td colSpan={9}>No participants to display</td>
                        </tr>
                    )}
                    {!loading && !updatingPlans && !error && paginatedItems && (
                        paginatedItems.map(p => (
                            <tr key={p.id}>
                                <td>
                                    <Link
                                        to={`/participants/${p.id}/personal-information`}
                                        className="!tw-text-blue-600 !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {p.firstname}&nbsp;{p.surname}
                                    </Link>
                                </td>
                                <td>
                                    {p.ndis}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.dateOfBirth, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    <ParticipantStatusIcon status={p.status} />
                                </td>
                                <td>
                                    {toCurrencyWithCommas(p.balance, "AUD", "en-AU")}
                                </td>
                                <td>
                                    <Link
                                        to={`/manage-service-bookings/${p.planId}`}
                                        className="!tw-text-flexplan-secondary !tw-hover:tw-text-flexplan-primary !tw-visited:tw-text-flexplan-primary"
                                    >
                                        {p.planId}
                                    </Link>
                                </td>
                                <td>
                                    {formatDateHelper.format(p.planStartDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.planEndDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    <PopoverMenu
                                        items={[
                                            { title: "View all Plans", onClick: () => push(`/participant/${p.id}/plans`) },
                                            {
                                                title: "Create New Service Booking",
                                                onClick: () => push({
                                                    pathname: `/manage-service-bookings/${p.planId}`,
                                                    state: { isCreate: true },
                                                }),
                                            },
                                        ]}
                                        buttonBody={<i className="fa fa-ellipsis-v" />}
                                        buttonClasses="tw-p-2"
                                        menuClasses="!-tw-top-6 !-tw-left-52"
                                    />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
        </FlexPlanBasicWrapper>
    );
};

export default ParticipantManagement;
