import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { SupplierHeader } from "@/Components/SupplierHeader";
import { EnquiryFormMiniSiteTile } from "@/Components/EnquiryFormMiniSiteTile";
import { SupplierDescription } from "@/Components/SupplierDescription";
import CategoryTabs from "@/Components/CategoryTabs";
import { SearchTextBox } from "@/Components/SearchTextBox";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { PackageTile } from "@/Components/PackageTile";
import { ProductVariants } from "@/Utils/ProductVariants";
import { ProductVariations } from "@/Utils/ProductVariations";
import { UpsellModal } from "@/Modals/UpsellModal";
import { useBasketContext } from "@/Context/BasketContext";

const MiniSite = props => {
    const [upsellItems, setUpsellItems] = useState([]);
    const [showUpsell, setShowUpsell] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [subcategories, setSubcategories] = useState({});
    const [visibleServices, setVisibleServices] = useState({});
    const { basket } = useBasketContext();

    const isSubstring = (name, searchTerm) => name.toLowerCase()
        .indexOf(searchTerm.toLowerCase()) > -1;

    const restoreDefaultState = useCallback(() => {
        setIsSearching(false);
        setVisibleServices(props.servicesToDisplay);
        setSubcategories({ ...props.servicesToDisplay });
    }, [props.servicesToDisplay]);

    const onSearchChanged = (e) => {
        const searchTerm = e.target.value;

        if (searchTerm) {
            const services = props.services.filter(x => isSubstring(x.name, searchTerm) || isSubstring(x.categoryName, searchTerm) || isSubstring(x.subCategoryName, searchTerm));

            setIsSearching(true);
            setSubcategories({ __search: [] });
            setVisibleServices({ __search: services });
        } else {
            restoreDefaultState();
        }
    };

    useEffect(() => {
        restoreDefaultState();
    }, [props.servicesToDisplay, props.activeCategoryTab, restoreDefaultState]);

    const addServiceToBasket = (product, qty, variations) => {
        props.addToBasket(product, qty, variations);
        if (product.upsellItems.length && !props.isBeingUsedForPackages) {
            setShowUpsell(true);
            setUpsellItems(product.upsellItems);
        }
    };

    const closeUpsellModal = () => setShowUpsell(false);

    return (
        <div className="bg-white pb-3 box-shadow rounded-lg flex-grow-1">
            <SupplierHeader
                showLogo={props.showLogo}
                showName={props.showName}
                showTagline={props.showTagline}
                headerColour={props.headerColour}
                headerImage={props.headerImage}
                nameAndTaglineColour={props.nameAndTaglineColour}
                logo={props.logo}
                name={props.name}
                tagline={props.tagline}
                onClose={props.onClose}
            />

            <SupplierDescription
                description={props.description}
                rating={props.rating}
            />

            <div className="px-3 pb-3">
                <SearchTextBox onChange={onSearchChanged} placeholder={`Search ${props.name}`} />
            </div>

            {!isSearching && props.categoriesToDisplay.length > 0 && (
                <CategoryTabs
                    categories={props.categoriesToDisplay}
                    activeCategoryTab={props.activeCategoryTab}
                    onChange={props.changeCategoryTab}
                    headerColour={props.headerColour}
                />
            )}

            <Row className="d-flex flex-wrap mt-3">
                {Object.keys(subcategories)
                    .sort().map(subCategory => (
                        <Col xs="12" key={subCategory}>
                            {!isSearching && <div className="bg-secondary text-white my-4 mx-3 px-3 py-2 text-uppercase">{subCategory}</div>}
                            {visibleServices[subCategory].map(x => {
                                if (ProductVariants.canBeAddedToBasket(x.productVariant)) {
                                    return (<ServiceMiniSiteTile
                                        key={`${x.id}-${x.name}`}
                                        id={x.id}
                                        name={x.name}
                                        description={x.description}
                                        image={x.imageThumbnail ?? x.image}
                                        paymentFrequency={x.paymentFrequency}
                                        initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                        postageCharge={x.postageCharge}
                                        moreDetails={x.moreInformation}
                                        images={x.images}
                                        showEditButton={false}
                                        isInBasket={props.itemIdsInCart.includes(x.id.toUpperCase())}
                                        onAddService={(qty, variations) => addServiceToBasket(x, qty, variations)}
                                        addQuantity={x.addQuantity}
                                        minimumQuantity={x.minimumQuantity}
                                        isBeingUsedForPackages={props.isBeingUsedForPackages}
                                        variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
                                        productVariant={x.productVariant}
                                        categoryId={x.categoryId}
                                        categoryName={x.categoryName}
                                        subCategoryName={x.subCategoryName}
                                        itemInCart={basket?.items?.find(y => y.productId === x.id)}
                                        initialCharge={x.initialCharge}
                                        isVatRequired={x.isVatRequired}
                                        canChooseQuantity={x.addQuantity}
                                        supplierId={x.supplierId}
                                        supplierName={x.supplierName}
                                        slug={x.slug}
                                        slugId={x.slugId}
                                        hasNAProducts={x.hasNAProducts}
                                        isDelayedPayment={x.isDelayedPayment}
                                        delayedPaymentFor={x.delayedPaymentFor}
                                        delayedPaymentPeriod={x.delayedPaymentPeriod}
                                        cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                    />);
                                }
                                if (x.productVariant === ProductVariants.enquiryFormProduct) {
                                    return (<EnquiryFormMiniSiteTile
                                        key={`${x.id}-${x.name}`}
                                        id={x.id}
                                        name={x.name}
                                        description={x.description}
                                        image={x.imageThumbnail ?? x.image}
                                        startingPrice={x.startingPrice}
                                        images={x.images}
                                        moreDetails={x.moreInformation}
                                        showEditButton={false}
                                        categoryId={x.categoryId}
                                        categoryName={x.categoryName}
                                        supplierId={x.supplierId}
                                        supplierName={x.supplierName}
                                        slug={x.slug}
                                        slugId={x.slugId}
                                        cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                    />);
                                }
                                if (x.categoryName === "Packages") {
                                    return (<PackageTile
                                        key={`${x.id}-${x.name}`}
                                        id={x.id}
                                        code={x.code}
                                        name={x.name}
                                        description={x.description}
                                        images={x.images}
                                        image={x.images ? x.images[0] : null}
                                        initialCharge={x.initialCharge}
                                        initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                        postageCharge={x.postageCharge}
                                        isBeingUsedForPackages={props.isBeingUsedForPackages}
                                        supplierName={x.supplierName}
                                        productPriceSummary={x.productPrices}
                                        hasNAProducts={x.hasNAProducts}
                                        cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                        carouselItemClassName="2xl:!tw-w-[270px] 3xl:!tw-w-[298px]"
                                    />);
                                }
                                return (<></>);
                            })}
                        </Col>
                    ))}
            </Row>
            {showUpsell && <UpsellModal productIds={upsellItems} onClose={closeUpsellModal} />}
        </div>);
};

MiniSite.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        subCategoryName: PropTypes.string.isRequired,
    })),
    onClose: PropTypes.func.isRequired,
    changeCategoryTab: PropTypes.func.isRequired,
    activeCategoryTab: PropTypes.string,
    addToBasket: PropTypes.func.isRequired,
    itemIdsInCart: PropTypes.arrayOf(PropTypes.string).isRequired,
    servicesToDisplay: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))),
    showLogo: PropTypes.bool.isRequired,
    logo: PropTypes.string,
    showName: PropTypes.bool.isRequired,
    name: PropTypes.string,
    showTagline: PropTypes.bool.isRequired,
    tagline: PropTypes.string,
    headerColour: PropTypes.string,
    nameAndTaglineColour: PropTypes.string,
    description: PropTypes.string,
    headerImage: PropTypes.string,
    categoriesToDisplay: PropTypes.arrayOf(PropTypes.string),
    rating: PropTypes.number.isRequired,
    isBeingUsedForPackages: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types, react/forbid-prop-types
    selectedSupplierId: PropTypes.any,
};

export default MiniSite;
