import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

const NoResultsFound = props => (
    <Col className={classnames("d-flex flex-grow-1 flex-column justify-content-center align-items-center", props.className)}>
        <h4 className="mb-3">No results found.</h4>
        <img src={`${window.cdnUrl}misc/calvin-no-results.png`} alt="No result found" className={styles.noResultImage} />
    </Col>
);

NoResultsFound.propTypes = {
    className: PropTypes.string,
};

export default NoResultsFound;
