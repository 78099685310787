import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import classnames from "classnames";
import { Button, Col, Media, Row, Table } from "reactstrap";
import { Link, Route } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import Spinner from "@/Components/Spinner";
import { Notifications } from "@/Components/Notifications";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { OrderSearch } from "@/Components/OrderSearch";
import { SupplierOrderDetailsModal } from "@/Modals/SupplierOrderDetailsModal";
import FilterOrdersModal from "@/Modals/FilterOrdersModal";
import SortOrdersModal from "@/Modals/SortOrdersModal";
import formatDateHelper from "@/Utils/formatDateHelper";
import api from "@/Utils/api";
import { pascalCaseToTitleCase } from "@/Utils/formatStringHelper";
import { Paging } from "@/Components/Paging";

class SupplierOrdersPage extends React.PureComponent {
    static formatSubscriptionPaymentStatus(subscriptionPaymentStatus, subscriptionPaymentDate) {
        if (!subscriptionPaymentStatus) {
            return "-";
        }

        if (subscriptionPaymentStatus === "InvoicePaymentFailed" || subscriptionPaymentStatus === "InvoicePaymentActionRequired") {
            return (<span className="text-danger font-weight-bold">{pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""))}</span>);
        }

        return subscriptionPaymentStatus === "InvoicePaid"
            ? formatDateHelper.format(subscriptionPaymentDate, "DD/MM/YYYY")
            : pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""));
    }

    state = {
        loading: true,
        orders: [],
        metaData: {},
        sortBy: "orderDate",
        sortAscending: false,
        filters: [],
        dateFilter: 3,
        sortModalOpen: false,
        filterModalOpen: false,
        includeSubscriptions: true,
        searchTerm: "",
        pageNumber: 0,
        pageLength: 10,
        totalPages: 0,
    };

    componentDidMount() {
        document.title = "BSC - Supplier Orders";

        this.getOrdersFromApi();
    }

    getOrdersFromApi = () => {
        let url = `orders/supplier?dateFilter=${this.state.dateFilter}`;
        url = this.state.searchTerm ? `${url}&searchTerm=${encodeURIComponent(this.state.searchTerm)}` : url;
        url = this.state.filters ? `${url}&statusFilter=${this.state.filters.join(",")}` : url;
        url += `&includeSubscriptions=${this.state.includeSubscriptions ? "true" : "false"}`;
        url += `&pageNumber=${this.state.pageNumber}`;
        url += `&pageLength=${this.state.pageLength}`;
        url += `&sortBy=${this.state.sortBy}`;
        url += `&sortAscending=${this.state.sortAscending}`;

        api.get(url)
            .then((response) => {
                const selected = response.orders.find(x => x.id === this.props.match.params.id);
                if (!selected) {
                    this.props.pushToSupplierListing();
                }
                this.setState({ ...response,
                    selected,
                    totalPages: response.totalPages,
                    loading: false }, this.updateFilters);
            }).catch(() => {
                this.setState(prevState => ({ ...prevState, loading: false }));
            });
    };

    toggleFilter = (value) => {
        if (this.state.filters.includes(value)) {
            this.setState(prevState => ({ ...prevState, filters: prevState.filters.filter(x => x !== value), pageNumber: 0 }),
                () => this.getOrdersFromApi());
        } else {
            this.setState(prevState => ({ ...prevState, filters: [...prevState.filters, value], pageNumber: 0 }),
                () => this.getOrdersFromApi());
        }
    };

    setFilter = (value) => {
        this.setState(prevState => ({ ...prevState, dateFilter: value, pageNumber: 0 }),
            () => this.getOrdersFromApi());
    };

    toggleRadioFilter = values => () => {
        this.setState(prevState => ({ ...prevState, filters: [...values], pageNumber: 0 }),
            () => this.getOrdersFromApi());
    };

    toggleSubscriptions = () => {
        this.setState(prevState => ({ ...prevState, includeSubscriptions: !prevState.includeSubscriptions, pageNumber: 0 }),
            () => this.getOrdersFromApi());
    };

    showSortModal = (shouldShow) => (
        this.setState(prevState => ({ ...prevState, sortModalOpen: shouldShow }))
    );

    showFilterModal = (shouldShow) => (
        this.setState(prevState => ({ ...prevState, filterModalOpen: shouldShow }))
    );

    sortOrders = (key) => {
        this.sort(key)();
    };

    sort = key => () => {
        this.setState((prevState) => {
            if (prevState.sortBy === key) {
                return { sortAscending: !prevState.sortAscending };
            }
            return {
                sortBy: key,
                sortAscending: true,
            };
        }, () => this.getOrdersFromApi());
    };

    onChangePage = (page) => {
        this.setState(prevState => ({ ...prevState, pageNumber: page }), () => this.getOrdersFromApi());
    };

    onSearchTerm = (searchTerm) => {
        this.setState(prevState => ({ ...prevState, searchTerm, pageNumber: 0 }), () => this.getOrdersFromApi());
    };

    onViewDetails = order => () => {
        this.setState({ selected: order }, () => this.props.pushToDetails(order.id));
    };

    onOrderStatusChanged = (orderId, status) => {
        this.setState(prevState => ({
            ...prevState,
            orders: prevState.orders.map((x) => {
                if (x.id === orderId) {
                    return {
                        ...x,
                        status,
                    };
                }
                return x;
            }),
        }));
    };

    getSortClassName = columnName => {
        if (columnName === this.state.sortBy) {
            return `fa fa-sort-${this.state.sortAscending ? "up" : "down"} pl-1`;
        }
        return "fa fa-sort pl-1";
    };

    updateFilters() {
        const filters = this.state.metaData.statusFilters;
        const allFilters = Object.keys(filters).map(x => filters[x]);

        if (this.state.filters.length === 0) {
            this.setState(prevState => ({ ...prevState, filters: allFilters }));
        }
    }

    renderTableHeader = () => (
        <thead>
            <tr>
                <th style={{ width: "16%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("customerName")}>
                        Placed By <i className={this.getSortClassName("customerName")} />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderDate")}>
                        Order Date <i className={this.getSortClassName("orderDate")} />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderReference")}>
                        Order Number <i className={this.getSortClassName("orderReference")} />
                    </button>
                </th>
                <th style={{ width: "18%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("serviceName")}>
                        Item <i className={this.getSortClassName("serviceName")} />
                    </button>
                </th>
                <th style={{ width: "14%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("category")}>
                        Category <i className={this.getSortClassName("category")} />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderType")}>
                        Type <i className={this.getSortClassName("orderType")} />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0"
                        onClick={this.sort("subscriptionPaymentStatus")}
                    >
                        Subscription Payment Status <i className={this.getSortClassName("subscriptionPaymentStatus")} />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0"
                        onClick={this.sort("status")}
                    >
                        Status <i className={this.getSortClassName("status")} />
                    </button>
                </th>
            </tr>
        </thead>
    );

    renderNoOrders = () => (
        <Col xs="12" className="d-flex flex-column pb-3">
            <Table responsive>
                {this.renderTableHeader()}
            </Table>
            <div className="text-center mb-3 pb-3">
                <Media src={`${window.cdnUrl}misc/calvin-empty-box.png`} alt="No result found" className={classnames(styles.noResultsImage, "mx-auto mt-2")} />
                <h5>Uh-oh, there are no purchases yet.</h5>
                <h5 className="mb-3">Want to edit your site!</h5>
                <Button color="primary" tag={Link} to="/mini-site">Take me to the editor!</Button>
            </div>
        </Col>
    );

    render() {
        if (this.state.loading) {
            return (
                <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                    <Spinner className="align-self-center" />
                </div>
            );
        }
        return (
            <>
                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border pb-3">
                        <div className="p-3 mt-2">
                            <h3 className="font-weight-bold">Manage Orders</h3>
                        </div>
                        <Notifications
                            openOrders={this.props.notifications.openOrders}
                            placedOrders={this.props.notifications.placedOrders}
                            ordersToReview={this.props.notifications.ordersToReview}
                            isSupplier={this.props.isSupplier}
                            error={this.state.error}
                        />
                    </Col>
                </Row>

                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border">

                        <OrderSearch
                            toggleStatusFilter={this.toggleFilter}
                            toggleDateFilter={this.setFilter}
                            onSearch={this.onSearchTerm}
                            currentStatusFilters={this.state.filters}
                            currentDateFilter={this.state.dateFilter}
                            dateFilters={this.state.metaData.dateFilters}
                            statusFilters={this.state.metaData.statusFilters}
                            toggleStatusRadio={this.toggleRadioFilter}
                            showSortModal={this.showSortModal}
                            showFilterModal={this.showFilterModal}
                            toggleSubscriptions={this.toggleSubscriptions}
                            includeSubscriptions={this.state.includeSubscriptions}
                        />

                        <Row className="flex-grow-1 d-flex">
                            {this.state.orders?.length === 0 ? this.renderNoOrders() : (
                                <Col xs="12" className="flex-grow-0">
                                    <Table responsive hover>
                                        {this.renderTableHeader()}
                                        <tbody className="pointer">
                                            {this.state.orders?.map(x => (
                                                <tr key={x.id} className="border-bottom" onClick={this.onViewDetails(x)}>
                                                    <td className="text-primary text-underline">{x.customerName}</td>
                                                    <td className="py-2">
                                                        <span className="d-block">{formatDateHelper.format(x.orderDate, "DD/MM/YYYY")}</span>
                                                        <span className={styles.orderTime}>{formatDateHelper.format(x.orderDate, "HH:mm:ss")}</span>
                                                    </td>
                                                    <td>{x.orderReference}</td>
                                                    <td className="text-primary text-underline">{x.serviceName}</td>
                                                    <td>{x.category}</td>
                                                    <td>{x.orderType}</td>
                                                    <td>{SupplierOrdersPage.formatSubscriptionPaymentStatus(x.subscriptionPaymentStatus, x.subscriptionPaymentDate)}</td>
                                                    <td><OrderStatusIcon status={x.status} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Paging
                                        className="md:tw-pt-2"
                                        onChange={this.onChangePage}
                                        activePage={this.state.pageNumber}
                                        pageCount={this.state.totalPages}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <FilterOrdersModal
                    isOpen={this.state.filterModalOpen}
                    onClose={() => this.showFilterModal(false)}
                    toggleDateFilter={this.setFilter}
                    dateFilters={this.state.metaData.dateFilters}
                    currentDateFilter={this.state.dateFilter}
                    toggleStatusFilter={this.toggleFilter}
                    statusFilters={this.state.metaData.statusFilters}
                    currentStatusFilters={this.state.filters}
                />

                <SortOrdersModal
                    isOpen={this.state.sortModalOpen}
                    onClose={() => this.showSortModal(false)}
                    onSort={this.sortOrders}
                    selectedKey={this.state.sortBy}
                    ascending={this.state.sortAscending}
                    isSupplier
                />

                <Route
                    path="/supplier-orders/:id/details"
                    render={() => <SupplierOrderDetailsModal id={this.state.selected.id} onOrderStatusChanged={this.onOrderStatusChanged} />}
                />
                <Footer />
            </>
        );
    }
}

SupplierOrdersPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    isSupplier: PropTypes.bool.isRequired,
    pushToDetails: PropTypes.func.isRequired,
    pushToSupplierListing: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
    isSupplier: state.user.isSupplier,
    notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
    pushToDetails: id => dispatch(push(`/supplier-orders/${id}/details`)),
    pushToSupplierListing: () => dispatch(push("/supplier-orders")),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOrdersPage);
