import React, { useCallback, useState } from "react";

import Modal from "@/Modals/Modal";
import { MinisiteTemplateSectionForm } from "@/Apis/Suppliers/MiniSiteType";
import { useWindowDimensions } from "@/Hooks/useWindowDimensions";

interface Props {
    section: MinisiteTemplateSectionForm,
}

const SectionContent = ({
    section,
}: Props) => {
    const { width, height } = useWindowDimensions();
    const [readMoreModalOpen, setReadMoreModalOpen] = useState<boolean>(false);
    const [shouldTruncate, setShouldTruncate] = useState<boolean>(false);

    // Measure the element to calculate the number of lines and determine whether to truncate
    const measuredRef = useCallback(
        (node: any) => {
            // Before the component mounts the node ref will be null
            if (node?.parentElement) {
                // Calculate the number of lines based on height
                const elHeight = node.offsetHeight;
                const styles = window.getComputedStyle(node);
                const lineHeight = styles
                    .getPropertyValue("line-height")
                    .replace("px", "");
                const elLineCount = elHeight / parseInt(lineHeight, 10);

                setShouldTruncate(elLineCount >= 5);
            }
        },
        [section?.text, width, height],
    );

    return (
        <>
            <div
                ref={measuredRef}
                className={`tw-break-words ${shouldTruncate ? "tw-line-clamp-5" : "tw-line-clamp-none"}`}
            >
                {section?.text}
            </div>
            {shouldTruncate && (
                <button
                    type="button"
                    className="tw-relative tw-font-semibold tw-underline tw-text-brand-primary tw-bg-white tw-w-full tw-text-left"
                    onClick={() => setReadMoreModalOpen(true)}
                >
                    <div className="tw-text-sm">Read more</div>
                </button>
            )}
            <Modal
                isOpen={readMoreModalOpen}
                toggle={() => setReadMoreModalOpen(false)}
                closeButton
            >
                <>
                    <div
                        className="tw-font-bold tw-text-2xl tw-break-words"
                        style={{ color: section?.titleColour }}
                    >
                        {section?.title}
                    </div>
                    <div className="tw-mt-2.5 tw-break-words">
                        {section?.text}
                    </div>
                </>
            </Modal>
        </>);
};

export { SectionContent };
