import React, { createRef } from "react";
import { Button } from "reactstrap";
import { v4 as uuid } from "uuid";
import { CSVReader } from "react-papaparse";

import { ColumnType, VariationState, VariationType } from "@/Components/ConfigureProductRow/ColumnTypes";

interface Props {
    variationState: VariationState;
    updateProductVariations: (state: VariationState) => void;
    createNewRow: (column: ColumnType, rowId: string, rowIndex: number, value: any) => VariationType
}

const UploadListedCsv = ({ variationState, createNewRow, updateProductVariations }: Props) => {
    const buttonRef = createRef<any>();

    const handleOpenDialog = (e) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const setTextBoxValue = (column: ColumnType, rowIndex: number, rowId: string, value: string) => {
        const updatedColumn = { ...column };
        updatedColumn.currentVariations = [...column.currentVariations, createNewRow(column, rowId, rowIndex, value)];
        return updatedColumn.currentVariations;
    };

    const setAddressValue = (column: ColumnType, rowIndex: number, rowId: string, propertyName: string, value: string) => {
        const updatedColumn = { ...column };
        const rowExists = updatedColumn.currentVariations[rowIndex] !== undefined;

        if (rowExists) {
            updatedColumn.currentVariations[rowIndex].value.address[propertyName] = value;
        } else {
            updatedColumn.currentVariations = [...column.currentVariations, createNewRow(column, rowId, rowIndex, { address: { [propertyName]: value } })];
        }
        return updatedColumn.currentVariations;
    };

    const handleFileUpload = (rows: string[][]) => {
        const columns = variationState.columns.map(x => ({ ...x, currentVariations: [] }));

        if (rows.length >= 2) {
            const columnHeaders = rows[0];
            const dataRows = rows.slice(1);
            const rowIds = dataRows.map(() => uuid());

            columnHeaders.forEach((header, headerIndex) => {
                const headerNameBits = header.split("__");
                const isTextBoxField = headerNameBits.length === 1;
                const column = columns.find(x => x.name === headerNameBits[0]) as ColumnType;

                dataRows.forEach((dataRow, dataRowIndex) => {
                    const rowId = rowIds[dataRowIndex];
                    if (isTextBoxField) {
                        column.currentVariations = setTextBoxValue(column, dataRowIndex, rowId, dataRow[headerIndex]);
                    } else {
                        column.currentVariations = setAddressValue(column, dataRowIndex, rowId, headerNameBits[1], dataRow[headerIndex]);
                    }
                });
            });
        }

        const updatedVariationState = { ...variationState, columns };
        updateProductVariations(updatedVariationState);
    };

    const handleOnFileLoad = (data) => handleFileUpload(data.map(x => x.data));

    return (
        <div className="d-inline-block">
            <CSVReader noDrag noClick noProgressBar ref={buttonRef} onFileLoad={handleOnFileLoad}>
                {() => (
                    <Button className="btn-tertiary fml-auto mt-4 mr-2" onClick={handleOpenDialog}>
                        Upload CSV
                    </Button>
                )}
            </CSVReader>
        </div>
    );
};

export default UploadListedCsv;
