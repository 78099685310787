import React from "react";
import { Form, FormGroup, Label, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import { ViewBespokeQuoteItemPrice } from "../BespokeQuoteItemPrice";

const ViewBespokeQuoteItem = ({ item }) => {
    const formatPricingUnit = (unit) => {
        switch (unit) {
            case "0":
                return "/ ea";
            case "1":
                return "/ hr";
            case "2":
                return "/ day";
            case "3":
                return "/ mo";
            case "4":
                return "/ set";
            case "5":
                return "N/A";
            default:
                return "/ Unit";
        }
    };

    const formatPrice = () => {
        const isString = typeof item.price === "string";
        return isString ? item.price : item.price.toFixed(2);
    };

    return (
        <Form className="rounded-lg border m-3 p-3 pb-0" data-testid="view-bespoke-quote-item">
            <Row>
                <Col xs={4}>
                    <FormGroup>
                        <Label><strong>Item Name</strong></Label>
                        <p>{item.name}</p>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label><strong>Pricing</strong></Label>
                        <p>£{formatPrice()}{formatPricingUnit(item.pricingUnit)}</p>
                    </FormGroup>
                </Col>

                <Col xs={4}>
                    <FormGroup>
                        <Label><strong>Total Units</strong></Label>
                        <p>{item.totalUnits}</p>
                    </FormGroup>
                </Col>
            </Row>
            {item.description && (
                <FormGroup>
                    <Label><strong>Item Description</strong></Label>
                    <p className="text-break">{item.description}</p>
                </FormGroup>
            )}

            <ViewBespokeQuoteItemPrice item={item} />
        </Form>
    );
};

ViewBespokeQuoteItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        pricingUnit: PropTypes.string.isRequired,
        totalUnits: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        applyVat: PropTypes.bool.isRequired,
    }).isRequired,
};

export { ViewBespokeQuoteItem };
