import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import Checkbox from "@/Components/Checkbox";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";

const PostPurchase = ({ postPurchaseInformation = "",
    isPostPurchaseEnabled = false,
    postPurchaseAttachments = [],
    onChange,
    onDropPostPurchaseAttachments }) => {
    const [deleteDocumentOpenModal, setDeleteDocumentOpenModal] = useState(false);
    const [documentActive, setDocumentActive] = useState();

    const uniquePostPurchaseAttachments = Array.from(new Set(postPurchaseAttachments));

    const [editorState, setEditorState] = useState(() => createEditorState(postPurchaseInformation));
    const onEditorChange = (changedEditorState) => {
        setEditorState(() => changedEditorState);
        onChange("postPurchaseInformation")({ target: { value: getContentStateAsJson(changedEditorState) } });
    };

    const onMaxCharactersReached = () => setEditorState(() => createEditorState(getContentStateAsJson(editorState)));

    const onChangeEnablePostPurchase = (checked) => {
        onChange("isPostPurchaseEnabled")({ target: { value: checked } });
    };

    const onClickDeleteDocument = (id) => () => {
        const document = uniquePostPurchaseAttachments.find(x => x.id === id);
        if (document) {
            setDocumentActive(document);
            setDeleteDocumentOpenModal(true);
        }
    };

    const onConfirmModalDeleteDocument = () => {
        if (!documentActive) return;

        onChange("postPurchaseAttachments")({ target: { value: uniquePostPurchaseAttachments.filter(x => x.id !== documentActive.id) } });
        setDeleteDocumentOpenModal(false);
    };

    const onCloseModalDeleteDocument = () => {
        setDeleteDocumentOpenModal(false);
        setDocumentActive(undefined);
    };

    const isImageFile = (name) => acceptedFileTypes.images.split(",").some(x => x !== "" && name.indexOf(x) >= 0);

    return (
        <div className="mt-4 d-flex flex-column h-100">
            <ConfirmCancelModal
                isOpen={deleteDocumentOpenModal}
                text={
                    <>
                        <p className="tw-text-base">Are you sure you want to remove the document?</p>
                        <p className="tw-font-bold tw-text-base">{documentActive?.name}</p>
                        <p><em>You will still need to save to finalise this action.</em></p>
                    </>
                }
                onConfirm={onConfirmModalDeleteDocument}
                onClose={onCloseModalDeleteDocument}
            />

            <div className="tw-prose tw-max-w-full">
                <Checkbox
                    checked={isPostPurchaseEnabled}
                    onChange={onChangeEnablePostPurchase}
                    id="isPostPurchaseEnabled"
                    label="Enable Post Purchase"
                    testId="chk-post-purchase"
                />
            </div>
            <div className="mt-4 tw-prose tw-max-w-full">
                <FormGroup className={styles.moreInfo}>
                    <RichTextEditor
                        label="Add information for your customers about post purchase details (e.g. how to use the service, subscription guidelines, etc.)"
                        maxCharacters={6000}
                        onMaxCharactersReached={onMaxCharactersReached}
                        placeholder="Post purchase information text"
                        onChange={onEditorChange}
                        editorState={editorState}
                    />
                </FormGroup>
            </div>
            <div className="mt-4 d-flex flex-column flex-grow-1">
                {uniquePostPurchaseAttachments.map(x => (
                    <div key={x.id} className={classnames("d-flex justify-content-between mb-3", styles.image)}>
                        <div className="d-flex align-items-center tw-w-full">
                            {isImageFile(x.name) && <Image src={x.data || x} alt="service" className="mr-3" />}
                            <span>{x.name}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button color="danger" className="px-3 py-2" onClick={onClickDeleteDocument(x.id)}><i className="fa fa-trash-alt" /></Button>
                        </div>
                    </div>
                ))}
            </div>

            <DropZoneArea
                onAdd={onDropPostPurchaseAttachments}
                accept={acceptedFileTypes.documents}
                validationContent={`File must be a ${fileTypesText(acceptedFileTypes.documents)}.`}
            >
                <div className="d-flex justify-content-center align-items-end pt-4 pb-2 mt-5">
                    <span>Drop files here to upload</span>
                </div>
            </DropZoneArea>
        </div>
    );
};

PostPurchase.propTypes = {
    onChange: PropTypes.func.isRequired,
    onDropPostPurchaseAttachments: PropTypes.func.isRequired,
    postPurchaseAttachments: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string.isRequired, data: PropTypes.string.isRequired })),
    postPurchaseInformation: PropTypes.string,
    isPostPurchaseEnabled: PropTypes.bool,
};

export { PostPurchase };
