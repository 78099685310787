import React from "react";
import PropTypes from "prop-types";

const Image = ({ src, alt, className, testId }) => (
    <img
        className={className}
        src={src?.startsWith("data")
            // JC: Seeded images may use picsum links
            || src?.startsWith("https://picsum.photos/")
            || src?.startsWith("/static")
            ? src
            : `${window.cdnUrl}${src}`}
        alt={alt}
        data-testid={testId}
    />
);

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    testId: PropTypes.string,
};

export { Image };
