import React from "react";
import { useSelector } from "react-redux";

import SuccessToastMessage from "@/Components/SuccessToastMessage";
import ErrorToastMessage from "@/Components/ErrorToastMessage";

const ToastMessages = () => {
    const showToastMessage = useSelector(state => state.global.showToastMessage);
    const showErrorToastMessage = useSelector(state => state.global.showErrorToastMessage);

    return (
        <>
            {showToastMessage && <SuccessToastMessage />}
            {showErrorToastMessage ? <ErrorToastMessage /> : <div />}
        </>
    );
};

export default ToastMessages;
