import React from "react";
import PropTypes from "prop-types";
import classname from "classnames";

import styles from "./styles.module.scss";

const RadioButton = props => (
    <div className={classname(styles.container, props.className)}>
        <div className={classname(styles.radio, props.disabled ? styles.disabled : undefined)}>
            <input
                type="radio"
                checked={props.checked}
                onChange={props.onChange}
                id={props.id}
                name={props.name}
                disabled={props.disabled}
                data-testid={`radioButton-${props.name}`}
            />
            {/* eslint-disable-next-line */}
            <label
                data-testid={props.id}
                htmlFor={props.id}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!props.disabled) {
                        props.onChange(!props.checked);
                    }
                    return false;
                }}
            >
                <span>{props.label}</span>
            </label>
        </div>
    </div>
);

RadioButton.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default RadioButton;
