import React, { useEffect, useState } from "react";

import useConfiguredProduct from "./useConfiguredProducts";

import { AddressLookUp } from "@/Components/AddressLookup";
import { ConfigurableUpload } from "@/Components/ConfigurableUpload";

interface Props {
    productId: string;
}

interface Variant {
    name: string;
    component: string;
    value: any;
    rowId: string;
}

const ConfiguredProduct = ({ productId }: Props) => {
    const [variations, setVariations] = useState({});
    const { productVariations } = useConfiguredProduct({ productId });

    useEffect(() => {
        const variants = {};
        const checkedColumns = productVariations.columns.filter(x => x.checked);

        if (checkedColumns?.length) {
            checkedColumns.forEach(column => {
                column.currentVariations.forEach(variation => {
                    if (!Object.prototype.hasOwnProperty.call(variants, variation.rowId)) {
                        variants[variation.rowId] = [];
                    }

                    variants[variation.rowId].push({
                        name: variation.name,
                        value: variation.value,
                        component: column.component,
                        rowId: variation.rowId,
                    });
                });
            });
        }

        setVariations(variants);
    }, [productVariations]);

    const renderDetails = (variant: Variant) => {
        if (variant.component === "AddressLookUp") {
            return (<AddressLookUp address={variant.value.address} hideButtons heading="" displaySingleLine />);
        }

        if (variant.component === "ConfigurableUpload") {
            return (<ConfigurableUpload documents={variant.value} readonly productId={productId} rowId={variant.rowId} />);
        }

        return variant.value;
    };

    return (
        <div className="border rounded">
            {Object.keys(variations).map(rowId => (
                <div className="p-4 border-bottom" key={rowId}>
                    {variations[rowId].map(variant => (
                        <div className="mb-4">
                            <span className="text-muted d-block">{variant.name}</span>
                            {renderDetails(variant)}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export { ConfiguredProduct };
