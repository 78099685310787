import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

import { ReceiptStatusIcon } from "@/Components/ReceiptStatusIcon";
import RadioButton from "@/Components/RadioButton";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { Paging } from "@/Components/Paging";
import Spinner from "@/Components/Spinner";
import { ToolTip } from "@/Components/ToolTip";
import { toCurrencyWithCommas, toPaddedNumber } from "@/Utils/formatStringHelper";
import formatDateHelper from "@/Utils/formatDateHelper";
import { Receipt, ReceiptManageColumnSort, ReceiptResponse, ReceiptStatus, ReceiptStatusFilter } from "@/Apis/Receipts";
import { useAPI } from "@/Apis/useAPI";
import { QuoteAgainModal } from "@/Modals/QuoteAgainModal";
import { ReceiptType } from "@/Apis/Receipts/Create";
import { useReceiptDocumentTitle } from "@/Hooks/useReceiptDocumentTitle";

const ManageQuotationsPage = () => {
    useReceiptDocumentTitle();
    const { get, loading } = useAPI();
    const [statusFilter, setStatusFilter] = useState<ReceiptStatusFilter>(ReceiptStatusFilter.All);
    const [quotations, setQuotations] = useState<Receipt[]>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [columnSort, setColumnSort] = useState<ReceiptManageColumnSort>(ReceiptManageColumnSort.Date);
    const [sortDescending, setSortDescending] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const [quoteAgainOpenModal, setQuoteAgainOpenModal] = useState<boolean>(false);
    const [activeQuotation, setActiveQuotation] = useState<Receipt>();

    useEffect(() => {
        get<ReceiptResponse>(`receipts?pageNumber=${pageNumber}&status=${statusFilter}&searchTerm=${search}&columnSort=${columnSort}
        &sortDescending=${sortDescending}&receiptType=${ReceiptType.Quotation}`)
            .then(response => {
                setQuotations(response.receipts);
                setPageCount(response.pageCount);
            })
            .catch(() => {
                setError("A problem occurred trying to update the table.");
            });
    }, [statusFilter, pageNumber, search, columnSort, sortDescending]);

    const onSearch = (searchValue: string) => setSearch(searchValue);

    const onPageChange = (pageNumber: number) => setPageNumber(pageNumber);

    const onSort = (sort: ReceiptManageColumnSort) => {
        if (columnSort === sort) {
            setSortDescending(!sortDescending);
            return;
        }

        setColumnSort(sort);
    };

    const onClickQuoteAgain = (quotation: Receipt) => {
        if (quotation) {
            setActiveQuotation(quotation);
            setQuoteAgainOpenModal(true);
        }
    };

    const onSendAgain = (success: boolean, quotationId: string) => {
        if (success) {
            const newQuotations = quotations?.map(x => (x.id === quotationId ? { ...x, status: ReceiptStatus.Sent } : x));
            setQuotations(newQuotations);
        }
    };

    return (
        <div className="mt-3 tw-p-8 tw-border tw-shadow-bsc">
            <h3 className="tw-font-bold" data-testid="title">Manage Quotations</h3>
            {error && (
                <div className="alert alert-danger">{error}</div>
            )}
            <div className="md:tw-p-3 md:tw-flex tw-mt-6 md:tw-mt-0">
                <div className="tw-flex">
                    <RadioButton
                        label="All"
                        checked={statusFilter === "All"}
                        id="all"
                        onChange={() => setStatusFilter(ReceiptStatusFilter.All)}
                    />
                    <RadioButton
                        name="Open"
                        label="Open"
                        checked={statusFilter === "Open"}
                        id="open"
                        onChange={() => setStatusFilter(ReceiptStatusFilter.Open)}
                    />
                    <RadioButton
                        label="Closed"
                        checked={statusFilter === "Closed"}
                        id="closed"
                        onChange={() => setStatusFilter(ReceiptStatusFilter.Closed)}
                    />
                </div>
                <div className="tw-flex md:tw-ml-auto tw-mt-6 md:tw-mt-0">
                    <DebounceTextbox
                        placeholder="Search Quotations"
                        className="md:tw-ml-auto tw-mr-4 tw-w-96"
                        callBack={onSearch}
                        name="Search Quotations"
                    />
                    <button type="button" className="tw-relative">
                        <i className="fas fa-search h5 tw-absolute tw-right-6 tw-bottom-3" />
                    </button>
                </div>
                <Link to="/quotations/details">
                    <button type="button" className="btn btn-primary tw-mb-4 tw-whitespace-nowrap">
                        Create Quote
                    </button>
                </Link>
            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th style={{ width: "16%" }}>
                            <button
                                type="button"
                                data-testid="recipient-sort-button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort(ReceiptManageColumnSort.Recipient)}
                            >
                                Recipient <i className="fa fa-sort" />
                            </button>
                        </th>
                        <th style={{ width: "16%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort(ReceiptManageColumnSort.ReferenceNumber)}
                            >
                                Quotation Ref No. <i className="fa fa-sort" />
                            </button>
                        </th>
                        <th style={{ width: "16%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort(ReceiptManageColumnSort.Date)}
                            >
                                Quotation Date <i className="fa fa-sort" />
                            </button>
                        </th>
                        <th style={{ width: "16%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort(ReceiptManageColumnSort.Value)}
                            >
                                Quotation Value <i className="fa fa-sort" />
                            </button>
                        </th>
                        <th style={{ width: "16%" }}>
                            <button
                                type="button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort(ReceiptManageColumnSort.Status)}
                            >
                                Status <i className="fa fa-sort" />
                            </button>
                        </th>
                        <th style={{ width: "16%" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={6}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                        </tr>
                    )}
                    {!loading && quotations && quotations.map(quote => (
                        <tr key={quote.id} data-testid={quote.id}>
                            <td><a href="/">{quote.recipient}</a></td>
                            <td>
                                <Link
                                    to={`/quotations/${quote.id}/details`}
                                    className="!tw-text-blue-600 !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                >
                                    {toPaddedNumber(quote.referenceNumber)}
                                </Link>
                            </td>
                            <td>
                                <span className="tw-font-semibold tw-block">{formatDateHelper.format(quote.dateCreated, "DD/MM/YYYY")}</span>
                                <span className="tw-text-gray-600">{formatDateHelper.format(quote.dateCreated, "HH:mm:ss")}</span>
                            </td>
                            <td>{toCurrencyWithCommas(quote.value)}</td>
                            <td>
                                <div className="tw-flex tw-space-x-1">
                                    <ReceiptStatusIcon status={quote.status} />
                                    {quote.status === ReceiptStatus.Void && (
                                        <ToolTip
                                            id={`void-info-${quote.id}`}
                                            description={`This quotation was edited and subsequently overwritten with a newer version. 
                                            This quotation can no longer be accepted or rejected.`}
                                        >
                                            <i className="fas fa fa-question-circle " />
                                        </ToolTip>)}
                                </div>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => onClickQuoteAgain(quote)}
                                >Quote Again
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageChange} pageCount={pageCount} />
            {activeQuotation
                && <QuoteAgainModal
                    isOpen={quoteAgainOpenModal}
                    receipt={activeQuotation}
                    onSend={(result) => onSendAgain(result, activeQuotation.id)}
                    onCancel={() => { setQuoteAgainOpenModal(false); setActiveQuotation(undefined); }}
                />}
        </div>
    );
};

export { ManageQuotationsPage };
