import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { nameof } from "@/Utils/objectHelper";

export interface FlexPlanSupplier {
    id?: string,
    supplierType: SupplierType
    abn?: string,
    businessName: string,
    contactEmail: string,
    contactNumber: string,
    contactPerson: string,
    address: LoqateAddress | null,

    // Plans Accepted
    selfManaged: boolean,
    planManaged: boolean,
    ndia: boolean,
    ndisRegistrationNumber: string,

    // bank
    bankName: string,
    accountName: string,
    bsb: string, // Australian version of sort code. 6 numeric digits.
    accountNumber: string,

    hasPrivateFields?: boolean, // does this plan manager already have private fields?
    isPublic: boolean,
}

export enum SupplierType {
    Abn = "Abn",
    ExcludedSupply = "ExcludedSupply",
    Reimbursement = "Reimbursement",
}

export const getFieldName = (name: keyof FlexPlanSupplier) => nameof<FlexPlanSupplier>(name);
