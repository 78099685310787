import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import Modal from "@/Modals/Modal";

const ConfirmDeleteUserModal = ({ onClose, onConfirm, fullName }) => (
    <Modal
        isOpen
        objectTitle={
            <>
                <h3 className="ml-0 d-none d-md-flex">Are you sure that you want to delete this user?</h3>
                <h5 className="ml-0 d-flex d-md-none">Are you sure that you want to delete this user?</h5>
            </>
        }
    >
        <p>Are you sure you want to remove <strong>{fullName}</strong>.
            Once removed, you will no longer be able to view this user and they won&apos;t be about to access thir account.
        </p>

        <div className="text-center mt-4 d-flex flex-column flex-md-row justify-content-center">
            <Button color="danger" type="submit" onClick={onConfirm} className="mb-1 mr-0 mr-md-1">
                Yes, Remove them
            </Button>
            <Button color="secondary" type="submit" onClick={onClose} className="mb-1">
                No, Keep them
            </Button>
        </div>

    </Modal>
);

ConfirmDeleteUserModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    fullName: PropTypes.func.isRequired,
};

export { ConfirmDeleteUserModal };
