import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";
import { Link, useRouteMatch, useHistory } from "react-router-dom";

import styles from "./styles.module.scss";

import { OrderItemPrice } from "@/Components/OrderItemPrice";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { Package } from "@/Apis/Packages";
import { BasketType } from "@/Apis/Basket";

const RedeemPartnerPackagesPage = () => {
    const { loading, get, post, del } = useAPI({ handle500WithRedirect: true });
    const match = useRouteMatch<{ packageCode: string }>();
    const [bscPackage, setBscPackage] = useState<Package | null>(null);
    const [isCheckingout, setIsCheckingout] = useState(false);
    const history = useHistory();

    useEffect(() => {
        get<Package>(`packages/${encodeURI(match.params.packageCode)}`)
            .then((response) => {
                setBscPackage(response);
            });
    }, [match.params.packageCode]);

    const onCheckoutClick = async () => {
        await del("basket", null);
        await post<BasketType>("packages/apply", {
            code: match.params.packageCode,
        });
        setIsCheckingout(true);
        history.push("/partners/checkout");
    };

    return (
        <>
            <div className="d-flex flex-grow-1 justify-content-center overflow-auto">

                <div className={classnames(styles.widths, "m-3")}>
                    <h4 className="font-weight-bold mt-1">Redeem a Package</h4>
                    <p>
                        Redeem a package of BSC products and services that will be added
                        automatically to your basket for a fast and efficient checkout.
                    </p>
                    {loading && (<div className="text-center py2"><Spinner className="my-4" /></div>)}

                    {!isCheckingout && !loading && (
                        <>
                            <div className={classnames(styles.packageBorder, "font-weight-bold border text-center py-2 my-4 rounded")}>
                                {bscPackage?.code && (<>{bscPackage?.code} <i className="fas fa-check-circle ml-1 text-success h4" /></>)}
                                {!bscPackage?.code && (<span className="text-danger">Package does not exist</span>)}
                            </div>
                            {bscPackage?.code && bscPackage?.products.filter(x => x.isOfflineInvoiceable).map(product => (
                                <div className="rounded box-shadow m-1" key={product.id}>
                                    <div className={classnames(styles.packageBorder, "d-flex border-bottom")}>
                                        <div className="p-4 mt-2">
                                            <div className="font-weight-bold h6">{product.name}</div>
                                            <div>{product.categoryName} / {product.subCategoryName}</div>
                                            <div className="font-weight-bold text-primary">{product.supplierName}</div>
                                        </div>
                                        <div className="ml-auto p-4 mt-2">
                                            <div className="text-right">
                                                <OrderItemPrice
                                                    id={product.id}
                                                    className={classnames(styles.orderItemPrice, "font-weight-bold h6")}
                                                    totalVat={product.isVatRequired ? product.initialChargeWithVatIfApplicable - product.initialCharge : 0}
                                                    price={product.initialCharge}
                                                    postage={product.postageCharge}
                                                    totalPrice={(product.initialChargeWithVatIfApplicable * product.quantity) + product.postageCharge}
                                                    paymentFrequency={product.paymentFrequency}
                                                    quantity={product.quantity}
                                                />
                                                <div className="mt-4 text-underline text-right">
                                                    <Link to={`/partners/packages/${match.params.packageCode}/products/${product.id}`}>More&nbsp;Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                        </>)}
                </div>

            </div>
            <div className="d-flex flex-grow-0 m-4 justify-content-center">
                <Button color="primary" block className={classnames(styles.widths, "py-3")} onClick={onCheckoutClick} disabled={isCheckingout}>
                    Checkout {isCheckingout ? (<i className="fas fa-circle-notch fa-spin" />) : ""}
                </Button>
            </div>
        </>);
};

export { RedeemPartnerPackagesPage };
