/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";

import DesktopBannerImg from "@/Assets/Images/Dashboard/Banners/Desktop/Banner.png";
import MobileBannerImg from "@/Assets/Images/Dashboard/Banners/Mobile/Banner.png";

const DashboardBanner = () => (
    <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-flex tw-flex-col tw-space-x-36 tw-w-full tw-relative tw-max-w-[360px] sm:tw-max-w-full">
                <div>
                    <picture>
                        <source media="(max-width: 639.9px)" srcSet={MobileBannerImg} />
                        <source media="(min-width: 640px)" srcSet={DesktopBannerImg} />
                        <img
                            src={DesktopBannerImg}
                            alt="Dashboard banner"
                            className="tw-flex tw-relative tw-flex-col sm:tw-object-cover sm:tw-object-left tw-w-full tw-h-60 lg:tw-h-80 3xl:tw-h-[27rem] 4xl:tw-h-[29rem]"
                        />
                    </picture>
                    <div className={classnames("tw-absolute tw-w-1/2 tw-w-[50%] sm:tw-w-[30%] 2xl:tw-w-[24%] tw-h-[80%] md:tw-h-[90%] tw-ml-0 sm:tw-ml-24 tw-top-0",
                        "tw-flex tw-flex-col tw-justify-center tw-p-1.5 tw-bg-brand-accent-yellow")}
                    >
                        <div className="tw-text-brand-primary tw-px-2 sm:tw-px-5 tw-text-lg lg:tw-text-2xl 2xl:tw-text-3xl 4xl:tw-text-4xl tw-font-bold">
                            We empower startups to succeed with expert advice and resources.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export { DashboardBanner };
