import React from "react";
import { Button, FormFeedback, FormGroup, Label, Modal, ModalBody } from "reactstrap";
import Select from "react-select";

import useParticipantSignUpForm from "./Hooks";

import Textbox from "@/Components/Textbox";
import FloatLabelDate from "@/Components/FloatLabelDate";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import RadioButton from "@/Components/RadioButton";
import { PlanType } from "@/FlexPlan/Types/Plan";
import { DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";
import { enumDisplay } from "@/Utils/formatStringHelper";
import { Heading } from "@/FlexPlan/Components/Heading";
import { genderReactSelectOptions } from "@/FlexPlan/Types/Gender/Helpers";
import { BackButton } from "@/FlexPlan/Components/BackButton";
import { ParticipantContactRelationship } from "@/FlexPlan/Types/Participant";
import { FormError } from "@/FlexPlan/Components/FormError";
import { usePlanManagerContext } from "@/FlexPlan/Context/PlanManagerContext";

const ParticipantSignUp = () => {
    const {
        onFormSubmit,
        formState,
        invalidFields,
        onChange,
        editDob,
        onAddDocument,
        onDeleteDocument,
        processingSignUp,
        onRadioChange,
        relationshipOptions,
        onAddressSaved,
        onAddressChange,
        onNdisBlur,
        participantAlreadyExists,
        onConfirmParticipantAlreadyExists,
        onCloseParticipantAlreadyExists,
        apiError,
    } = useParticipantSignUpForm();

    const { companyName } = usePlanManagerContext();

    return (
        <>
            <Heading title="Participant Sign Up" />
            <div className="tw-p-4 md:tw-p-10 tw-max-w-3xl tw-mx-auto">
                <form onSubmit={onFormSubmit}>
                    <div>
                        <Textbox
                            label="NDIS Number *"
                            className="tw-text-base tw-w-1/5 tw-whitespace-nowrap"
                            isRequired
                            onChange={onChange("ndisNumber")}
                            value={formState?.ndisNumber}
                            error={invalidFields
                                && ((invalidFields.ndisNumber && "Please enter a valid NDIS number. Nine digits starting with \"43\".")
                                || (invalidFields.ndisNumberAlreadyExists && "A participant with this NDIS number already exists."))}
                            onBlur={onNdisBlur}
                            maxLength={9}
                        />
                        <Textbox
                            className="tw-mt-5 tw-text-base tw-w-1/2 tw-whitespace-nowrap"
                            label="Participant's First Name *"
                            isRequired
                            onChange={onChange("firstName")}
                            value={formState?.firstName}
                            error={invalidFields && invalidFields.firstName && "Please enter your first name. Must not exceed 255 characters."}
                        />
                        <Textbox
                            label="Participant's Last Name *"
                            className="tw-mt-5 tw-text-base tw-w-1/2 tw-whitespace-nowrap"
                            isRequired
                            onChange={onChange("lastName")}
                            value={formState?.lastName}
                            error={invalidFields && invalidFields.lastName && "Please enter your last name. Must not exceed 255 characters."}
                        />
                        <div>
                            <div className="tw-mt-5 tw-text-base">Participant’s Date of Birth *</div>
                            <FloatLabelDate
                                isRequired
                                changeDate={editDob}
                                currentDate={formState.dateOfBirth}
                                isErrored={invalidFields?.dateOfBirth}
                            />
                            {invalidFields?.dateOfBirth && (
                                <FormError className="!-tw-mt-3 tw-mb-4">
                                    Please enter a valid date of birth.
                                </FormError>
                            )}
                        </div>
                        <FormGroup
                            className="tw-text-base"
                        >
                            <Label className="tw-text-base">
                                Gender
                            </Label>
                            <Select
                                className="tw-text-base tw-w-1/4"
                                options={genderReactSelectOptions}
                                value={{ label: formState.gender, value: formState.gender }}
                                onChange={e => onChange("gender")({ target: { value: e.value } } as any)}
                            />
                        </FormGroup>

                        <FloatLabelAddressLookUp
                            customHeading={<div className="tw-text-base tw-pb-2 tw-mt-5">Address *</div>}
                            editButtonText="Choose Address"
                            buttonClasses="!tw-text-base"
                            errorClasses="font-weight-bold !tw-text-bootstrap-error-size tw-mt-2"
                            onSave={onAddressSaved}
                            onStateChange={onAddressChange}
                            address={formState.address}
                            error={invalidFields?.address && "Please enter a valid address"}
                        />
                        <div className="tw-border-2 tw-p-6 tw-mt-5 tw-text-base">
                            <Label>
                                Are you:
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    id="plan-type"
                                    label={enumDisplay(PlanType.ndiaManaged)}
                                    checked={formState.planType === PlanType.ndiaManaged}
                                    onChange={onRadioChange("planType", PlanType.ndiaManaged)}
                                />
                                <RadioButton
                                    id="plan-type"
                                    label={enumDisplay(PlanType.planManaged)}
                                    checked={formState.planType === PlanType.planManaged}
                                    onChange={onRadioChange("planType", PlanType.planManaged)}
                                />
                                <RadioButton
                                    id="plan-type"
                                    label={enumDisplay(PlanType.selfManaged)}
                                    checked={formState.planType === PlanType.selfManaged}
                                    onChange={onRadioChange("planType", PlanType.selfManaged)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tw-mt-8">
                        <h4 className="font-weight-bold">Contact Information</h4>
                        <Label className="tw-text-base">
                            Alternate Name (e.g. Nominee) <span className="tw-text-sm tw-text-gray-400">[Optional]</span>
                        </Label>
                        <Textbox
                            className="tw-text-base tw-w-1/2"
                            onChange={onChange("alternateName")}
                            value={formState?.alternateName}
                            error={invalidFields && invalidFields.alternateName && "Must not exceed 255 characters."}
                        />
                        <FormGroup>
                            <Label className="tw-text-base">
                                What is your relationship to the participant?
                            </Label>
                            <Select
                                className="tw-text-base tw-w-1/2"
                                options={relationshipOptions}
                                value={relationshipOptions.find(x => x.value === formState.relationshipToParticipant)}
                                onChange={e => onChange("relationshipToParticipant")({ target: { value: e.value } } as any)}
                            />
                        </FormGroup>
                        {formState.relationshipToParticipant === ParticipantContactRelationship.other && (
                            <Textbox
                                className="tw-text-base tw-w-1/2 tw-mt-5"
                                label="Please specify the relationship *"
                                onChange={onChange("otherRelationship")}
                                value={formState.otherRelationship}
                                error={invalidFields?.otherRelationship && "Please specify the relationship. Must not exceed 255 characters."}
                            />
                        )}
                        <Textbox
                            label="Phone number *"
                            className="tw-text-base tw-w-1/4 tw-whitespace-nowrap"
                            isRequired
                            onChange={onChange("phoneNumber")}
                            value={formState?.phoneNumber}
                            error={invalidFields && invalidFields.phoneNumber && "Please enter a valid phone number."}
                        />
                        <Textbox
                            label="Email *"
                            className="tw-text-base tw-w-1/2 tw-whitespace-nowrap"
                            isRequired
                            onChange={onChange("email")}
                            value={formState?.email}
                            error={invalidFields && invalidFields.email && "Please enter a valid email address. Must not exceed 255 characters."}
                        />
                    </div>

                    <div className="tw-border-2 tw-p-6 tw-mt-5 tw-text-base">
                        <h4 className="font-weight-bold">Privacy</h4>
                        <div>
                            <Label>
                                Do you authorise {companyName} to view your NDIS Plan?
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    id="ndis-visible"
                                    label="Yes"
                                    checked={formState.ndisVisible}
                                    onChange={onRadioChange("ndisVisible", true)}
                                />
                                <RadioButton
                                    id="ndis-visible"
                                    label="No"
                                    checked={!formState.ndisVisible}
                                    onChange={onRadioChange("ndisVisible", false)}
                                />
                            </div>
                        </div>
                        <div className="tw-mt-5">
                            <Label>
                                Sometimes, participants do not want Providers to know they are funded by the NDIS. Which do you prefer?
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    id="funding-visible"
                                    label="I don't mind"
                                    checked={formState.fundedVisible}
                                    onChange={onRadioChange("fundedVisible", true)}
                                />
                                <RadioButton
                                    id="funding-visible"
                                    label="I prefer that Providers don’t know"
                                    checked={!formState.fundedVisible}
                                    onChange={onRadioChange("fundedVisible", false)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="tw-border-2 tw-p-6 tw-mt-5 tw-text-base">
                        <h4 className="font-weight-bold">Invoice Management</h4>
                        <div>
                            <Label>
                                Do you want to approve invoices first before they are sent to your Plan Manager?
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    id="approve-invoices"
                                    label="Yes"
                                    checked={formState.approveInvoices}
                                    onChange={onRadioChange("approveInvoices", true)}
                                />
                                <RadioButton
                                    id="approve-invoices"
                                    label="No"
                                    checked={!formState.approveInvoices}
                                    onChange={onRadioChange("approveInvoices", false)}
                                />
                            </div>
                        </div>
                        <div className="tw-mt-5">
                            <Label>
                                Do you want a copy of the invoice?
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    id="receive-invoice"
                                    label="Yes"
                                    checked={formState.receiveInvoice}
                                    onChange={onRadioChange("receiveInvoice", true)}
                                />
                                <RadioButton
                                    id="receive-invoice"
                                    label="No"
                                    checked={!formState.receiveInvoice}
                                    onChange={onRadioChange("receiveInvoice", false)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5">
                        <h4 className="font-weight-bold">Upload copy of Participants Plan</h4>
                        <DropZoneMulti
                            onAdd={onAddDocument}
                            onDelete={onDeleteDocument}
                            validationContent={`Files must be less than 8MB and a ${fileTypesText(acceptedFileTypes.documents)}.`}
                        />
                    </div>
                    {invalidFields && Object.keys(invalidFields).length > 0 && (
                        <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                            Please amend the errors above
                        </FormFeedback>
                    )}
                    {apiError && (
                        <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                            {apiError}
                        </FormFeedback>
                    )}
                    <div className="tw-mt-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-space-y-2 md:tw-space-y-0 md:tw-space-x-5">
                        <BackButton
                            className="tw-w-full md:tw-w-72 !tw-text-base"
                            displayConfirmationModal
                        />
                        <Button
                            color="primary"
                            size="lg"
                            type="submit"
                            className="tw-w-full md:tw-w-72 !tw-text-base"
                            disabled={processingSignUp || (invalidFields && Object.keys(invalidFields).length > 0)}
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
            {/* Participant already exists modal */}
            <Modal
                isOpen={!!participantAlreadyExists}
                centered
                size="md"
                className="tw-p-10"
            >
                <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8">
                    <>
                        <div className="tw-py-3 tw-space-x-3 tw-justify-center tw-flex tw-flex-row tw-items-center ">
                            <i className="fa fa-exclamation-circle fa-2x tw-text-amber-400" />
                            <span className="tw-text-base">
                                {participantAlreadyExists?.fullname} already exists with the NDIS number {formState.ndisNumber}
                            </span>
                        </div>
                        <div className="tw-flex tw-space-x-3 tw-justify-center tw-mt-2">
                            <Button color="primary" className="tw-text-base" outline onClick={onCloseParticipantAlreadyExists}>Close</Button>
                            <Button color="primary" className="tw-text-base" onClick={onConfirmParticipantAlreadyExists}>Go to Profile</Button>
                        </div>
                    </>
                </ModalBody>
            </Modal>
        </>
    );
};

export { ParticipantSignUp };
