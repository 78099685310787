import * as constants from "./constants";

export const initialState = {
    loading: true,
    showToastMessage: false,
    showErrorToastMessage: false,
    toastMessage: "",
    error: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case constants.SET_TOAST_MESSAGE: {
            return {
                ...state,
                showToastMessage: action.payload.display,
                toastMessage: action.payload.message,
            };
        }
        case constants.SET_ERROR_TOAST_MESSAGE: {
            return {
                ...state,
                showErrorToastMessage: action.payload.display,
                toastMessage: action.payload.message,
            };
        }
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                error: "",
            };
        case constants.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
