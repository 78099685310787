import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { useDispatch } from "react-redux";

import { PartnerHeader } from "@/Components/PartnerHeader";
import NotFoundPage from "@/Pages/NotFoundPage";
import PrivateRoute from "@/Pages/PrivateRoute";
import { getToken } from "@/Utils/authentication";
import { RedeemPartnerPackagesPage } from "@/Pages/Packages/RedeemPartnerPackagesPage";
import { PartnerProductDetailsPage } from "@/Pages/PartnerProductDetailsPage";
import { PartnerCheckout } from "@/Pages/PartnerCheckout";
import { PartnersCheckoutComplete } from "@/Pages/PartnerCheckoutComplete";
import userActions from "@/Store/User/actions";
import notificationsActions from "@/Store/Notifications/actions";
import { PartnerAppGetToken } from "@/Pages/PartnerAppGetToken";

const PartnersAppRouter = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (getToken()) {
            dispatch(userActions.loadUser$());
            dispatch(notificationsActions.loadNotifications$());
        }
    }, [dispatch]);

    return (
        <div className="d-flex flex-column h-100">
            <PartnerHeader />
            <Switch>
                <PrivateRoute path="/partners/token" component={PartnerAppGetToken} />
                <PrivateRoute path="/partners/packages/redeem/:packageCode" component={RedeemPartnerPackagesPage} />
                <PrivateRoute path="/partners/packages/:packageCode/products/:productId" component={PartnerProductDetailsPage} />
                <PrivateRoute path="/partners/checkout/invoice/:paymentId/complete" component={PartnersCheckoutComplete} />
                <PrivateRoute path="/partners/checkout" component={PartnerCheckout} />
                <Route component={NotFoundPage} /> {/* Must go at the end of all routes */}
            </Switch>
        </div>
    );
};

export { PartnersAppRouter };
