import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, Label } from "reactstrap";

import styles from "./styles.module.scss";

const FloatLabelTextbox = props => {
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [isValidTextFailed, setIsValidTextFailed] = useState(false);
    const [canValidate, setCanValidate] = useState(props.validateOnMount);

    const isValid = (value: string) => {
        const regex = new RegExp(props.regex);
        return (regex.test(value));
    };

    useEffect(() => {
        if (canValidate) {
            setIsRequiredValidationFailed(props.isRequired && !props.value);
            setIsValidTextFailed(props.value && !isValid(props.value));
        } else if (isRequiredValidationFailed) {
            setIsRequiredValidationFailed(false);
        } else if (isValidTextFailed) {
            setIsValidTextFailed(false);
        }
    }, [canValidate, isRequiredValidationFailed, props.value, props.isRequired]);

    useEffect(() => {
        if (props.error || isRequiredValidationFailed || isValidTextFailed) {
            props.onErrorStatusChange?.(true, props.label ?? "FloatLabelTextBox");
        } else {
            props.onErrorStatusChange?.(false, props.label ?? "FloatLabelTextBox");
        }
    }, [props.error, isRequiredValidationFailed, isValidTextFailed]);

    const onBlur = useCallback(() => {
        setCanValidate(true);
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props]);

    function onValueChanged(e) {
        if (props.onChange) {
            props.onChange(e, !(e.target.value && !isValid(e.target.value)));
        }
    }

    return (
        <FormGroup className={classnames(props.className, "text-box tw-relative", props.isRequired && "text-box-required")}>
            <div className={classnames(styles.floatLabel, props.className, props.error || isRequiredValidationFailed || isValidTextFailed ? styles.error : "")}>
                {props.label && <Label className="tw-absolute">{props.label}</Label>}
                <input
                    key={props.disabled ? "disabled" : "enabled"}
                    onKeyUp={props.onKeyUp}
                    type={props.type}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value || ""}
                    onChange={onValueChanged}
                    disabled={props.disabled}
                    onBlur={onBlur}
                    onFocus={props.onFocus}
                    style={props.inputStyle}
                    data-testid={props["data-testid"] || ""}
                    maxLength={props.maxLength}
                />
                {!props.formFeedbackTop && (props.error || isRequiredValidationFailed) && (
                    <div className={styles.errorMessage}>{props.error || (isRequiredValidationFailed && <>{props.isRequiredError}</>)}</div>
                )}
                {!props.formFeedbackTop && isValidTextFailed && (
                    <div className={styles.errorMessage}>{(isValidTextFailed && <>{props.isInvalidError}</>)}</div>
                )}
            </div>
        </FormGroup>
    );
};

FloatLabelTextbox.propTypes = {
    onErrorStatusChange: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    isRequired: PropTypes.bool,
    isRequiredError: PropTypes.string,
    isInvalidError: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputStyle: PropTypes.object,
    "data-testid": PropTypes.string,
    validateOnMount: PropTypes.bool,
    formFeedbackTop: PropTypes.bool,
    regex: PropTypes.string,
    maxLength: PropTypes.number,
};

FloatLabelTextbox.defaultProps = {
    disabled: false,
    type: "text",
    name: "",
    isRequired: false,
    isRequiredError: "This field is required",
    isInvalidError: "Text is invalid",
    validateOnMount: false,
    formFeedbackTop: false,
    regex: ".", // place regex here to validate on
};

export default FloatLabelTextbox;
