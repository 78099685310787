import React from "react";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { SectionContent } from "@/Components/MinisiteContent/SiteContent/SectionContent";
import { SectionImage } from "@/Components/MinisiteContent/SiteContent/SectionImage";
import { RatingsAndContactUs } from "@/Components/MinisiteContent/SiteContent/RatingsAndContactUs";

interface Props {
    siteType: MiniSiteType,
}

const Template1 = ({
    siteType,
}: Props) => (
    <div data-testid="site-template-1">
        {siteType?.template?.sections?.map((section, i) => (
            <div key={section.sectionNumber}>
                <div
                    className={`tw-break-words tw-font-bold tw-text-2xl ${i % 2 === 1 && "tw-mt-5"}`}
                    style={{ color: section?.titleColour }}
                >
                    {section?.title}
                </div>
                <div className="tw-mt-2.5">
                    <SectionContent section={section} />
                </div>
                <SectionImage siteSection={section} classNameImg="tw-h-[120px] sm:tw-h-[190px]" />
            </div>
        ))}

        <RatingsAndContactUs siteType={siteType} />
    </div>
);

export { Template1 };
