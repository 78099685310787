import React, { useState } from "react";
import classnames from "classnames";
import { Input } from "reactstrap";

import { useAPI } from "@/Apis/useAPI";
import TextBox from "@/Components/Textbox";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { Package } from "@/Apis/Packages";

interface Props {
    packageInfo: Package;
    updatePackageInfo: (packageUpdate: Package) => void;
}

export const PackageInfo = ({ packageInfo, updatePackageInfo }: Props) => {
    const { put } = useAPI({ handle500WithToastMessage: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const [name, setName] = useState(packageInfo?.name || "");
    const [code, setCode] = useState(packageInfo?.code || "");
    const [description, setDescription] = useState(packageInfo.description || "");

    const startEditing = () => {
        setIsEditing(true);
    };

    const updateCode = (event) => {
        const newCode = event.target.value;
        if (newCode.length > 9) {
            setError(undefined);
        } else {
            setError("Package code must be at least 10 characters.");
        }
        setCode(newCode);
    };

    const updateName = (event) => {
        const newName = event.target.value;
        setName(newName);
    };

    const updateDescription = (event) => {
        const newDescription = event.target.value;
        setDescription(newDescription);
    };

    const confirmPackageInfo = () => {
        put(`packages/${packageInfo.id}`, { CustomCode: code, Name: name, Description: description })
            .then(() => {
                setIsEditing(false);
                updatePackageInfo({
                    ...packageInfo,
                    code,
                    name,
                    description,
                });
                setSuccessMessage("Package information updated successfully", true);
            })
            .catch((error) => {
                setPopupErrorMessage(error, true);
            });
    };

    const cancelEdit = () => {
        setName(packageInfo.name);
        setCode(packageInfo.code);
        setDescription(packageInfo.description);
        setError(undefined);
        setIsEditing(false);
    };

    return (
        <div className="tw-text-left tw-mx-4 tw-w-full">
            <div className="tw-flex">
                {
                    !isEditing && (
                        <button
                            type="button"
                            className="tw-flex"
                            onClick={startEditing}
                            data-testid="editButton"
                        >
                            <i className="fa fa-edit" />
                        </button>
                    )
                }
                {
                    isEditing && (
                        <>
                            <button
                                type="button"
                                disabled={!!error}
                                className="tw-flex"
                                onClick={confirmPackageInfo}
                                data-testid="confirmButton"
                            >
                                <i className="fa fa-check" />
                            </button>
                            <button
                                type="button"
                                className="tw-flex tw-pl-3"
                                onClick={cancelEdit}
                                data-testid="cancelButton"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </>
                    )
                }
            </div>
            <div className="tw-flex">
                { (name || isEditing) && (
                    <TextBox
                        className="!tw-w-full !tw-mb-1 lg:!tw-w-1/2"
                        inputClasses={
                            classnames("!tw-text-xl !tw-font-bold", !isEditing && "!tw-p-0 !tw-border-0 !tw-border-none !tw-bg-white !tw-mb-0")
                        }
                        value={name}
                        onChange={updateName}
                        disabled={!isEditing}
                        placeholder={isEditing ? "Package name" : ""}
                        data-testid="packageNameInput"
                    />)}
            </div>
            <div className="tw-flex">
                <TextBox
                    className="!tw-w-full !tw-mb-0 lg:!tw-w-1/2"
                    inputClasses={
                        classnames("!tw-text-sm !tw-font-bold", !isEditing && "!tw-p-0 !tw-border-0 !tw-border-none !tw-bg-white !tw-mb-0")
                    }
                    value={code}
                    onChange={updateCode}
                    error={error}
                    disabled={!isEditing}
                    placeholder={isEditing ? "Package code" : ""}
                    data-testid="packageCodeInput"
                />
            </div>
            <div className="tw-flex tw-pt-1">
                { (description || isEditing) && (
                    <Input
                        type="textarea"
                        rows="2"
                        style={{ resize: "none" }}
                        className={
                            classnames("!tw-text-xs !tw-mb-0", !isEditing && "!tw-p-0 !tw-border-0 !tw-border-none !tw-bg-white !tw-mb-0")
                        }
                        value={description}
                        onChange={updateDescription}
                        disabled={!isEditing}
                        placeholder={isEditing ? "Package description" : ""}
                        data-testid="packageDescriptionInput"
                    />)}
            </div>
            <small className="tw-text-gray-500">Date created: {packageInfo.dateCreated}</small>
        </div>
    );
};
