import React from "react";

import { TextWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/TextWireframe";
import { ImageWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/ImageWireframe";
import { RatingsWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/RatingsWireframe";
import { useMinisiteContext } from "@/Context/MinisiteContext";
import RadioButton from "@/Components/RadioButton";

const Template3 = () => {
    const {
        updateSupplierTemplateValue,
        miniSite,
    } = useMinisiteContext();

    return (
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-bg-white tw-h-[600px] tw-w-[400px]">
            <RadioButton
                checked={miniSite.template.selectedTemplate === 3}
                onChange={() => updateSupplierTemplateValue("selectedTemplate")(3)}
                id="template-radio"
                label="Template 3"
            />
            <div className="tw-flex tw-flex-row tw-space-x-2 tw-flex-grow tw-basis-4/5">
                <div className="tw-flex tw-flex-col tw-flex-grow tw-basis-1/2">
                    <ImageWireframe imageNumber={1} className="tw-basis-1/2" />
                    <TextWireframe className="tw-basis-1/2" />
                </div>
                <div className="tw-flex tw-flex-col tw-flex-grow tw-basis-1/2">
                    <ImageWireframe imageNumber={2} className="tw-basis-1/2" />
                    <TextWireframe className="tw-basis-1/2" />
                </div>
            </div>
            <RatingsWireframe className="tw-basis-1/5" />
        </div>
    );
};

export { Template3 };
