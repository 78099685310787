import React, { useEffect, useMemo, useState } from "react";

import Spinner from "@/Components/Spinner";
import Modal from "@/Modals/Modal";
import { ContextualFragment, renderOr } from "@/Utils/renderOr";

export interface WaitModalConfiguration<T = any> {
    isOpen: boolean,
    title?: ContextualFragment<T>,
    body?: ContextualFragment<T>,
    delay?: number
}

export interface WaitModalProps<T> extends WaitModalConfiguration<T> {
    context?: T | null,
}

export function WaitModal<T>({
    context,
    title,
    body,
    isOpen,
    delay,
}: WaitModalProps<T>) {
    const [isOpenInternal, setIsOpenInternal] = useState(false);

    const setIsOpen = useMemo(() => {
        let timer: any = null;
        return (open: boolean) => {
            if (timer) {
                clearTimeout(timer);
            }
            if (!open) {
                setIsOpenInternal(false);
            } else {
                timer = setTimeout(() => {
                    setIsOpenInternal(true);
                }, delay ?? 500);
            }
        };
    }, []);

    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpenInternal}
            size="sm"
            objectTitle={renderOr(context!, title, (
                <>
                    <h4 className="tw-ml-0 tw-mb-0 tw-text-center tw-w-full">{title as any ?? "Please wait"}</h4>
                </>
            ), false)}
        >
            <div>
                {renderOr(context!, body, (
                    <div className="tw-flex tw-justify-center"><Spinner /></div>
                ))}
            </div>
        </Modal>
    );
}
