import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { useParams } from "react-router";
import { Button, Spinner } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import { useAPI } from "@/Apis/useAPI";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { toCurrency } from "@/Utils/formatStringHelper";
import { Payment } from "@/Pages/CheckoutPage/Payment";

export const AmendPaymentDetailsPage = () => {
    document.title = "BSC - Amend Payment Detail";
    const dispatch = useDispatch();
    const onPush = useCallback(path => () => dispatch(push(path)), [dispatch]);
    const { stripeKey } = useConfigurationContext();
    const stripePromise = loadStripe(stripeKey);
    const routeParams: { id: string } = useParams();
    const [order, setOrder] = useState<any>({ supplierAttachments: [] });
    const { loading, get } = useAPI({ handle500WithToastMessage: true });

    useEffect(() => {
        get(`customer-orders/${routeParams.id}/subscription-service`)
            .then((response) => {
                setOrder(response);
            })
            .catch((error) => {
                onPush({
                    pathname: "/error",
                    state: {
                        error,
                        referrer: window.location.href,
                    },
                });
            });
    }, []);

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }

    return (
        <div data-testid="entire-page" className="bg-white">
            <div className="pl-4 pt-4 w-100">
                <h4 className="h4 font-weight-bold">Amend Payment Details</h4>
                <Button color="link" className="p-0 font-weight-bold d-inline">
                    <Link to="/customer-orders">
                        <i className="text-primary fa fa-chevron-left mr-1" /> Back to Order Management
                    </Link>
                </Button>
            </div>
            <hr className="border-bottom" />
            <div className="d-flex m-0 mt-lg-5 mx-lg-5 w-75 mx-auto">
                <div className={classnames(styles.fieldContainer, "mr-0 mr-md-4 px-3 pt-2 pt-md-4 d-flex flex-column")}>
                    <div>
                        <div data-testid="payment" className="m-2 mx-md-auto p-0">
                            <Elements stripe={stripePromise}>
                                <Payment onPaymentSuccess={() => { }} isAmendPayment amendPaymentOrderId={routeParams.id} />
                            </Elements>
                        </div>
                    </div>
                </div>
                <div className={classnames(styles.sideCartContainer, "d-none d-lg-block")}>
                    <div className={classnames(styles.sideCartContent, "p-3")}>
                        <div className="pl-3 pr-3">
                            <div>
                                <h4 data-testid="service-title" className="pt-1 font-weight-bold">{order.serviceName}</h4>
                                <div className={classnames(styles.categoryName, "mt-1 mb-2 text-muted font-weight-bold")}>
                                    <Link to={`/marketplace?categorySelections=${order.categoryId}`}>{order.categoryName}</Link> • {order.subCategoryName}
                                </div>
                                <div data-testid="service-description" className="pt-1">
                                    {order.serviceDescription}
                                </div>
                            </div>
                            <div data-testid="service-info" className="pt-3 pb-3 border rounded p-3 font-weight-bold">
                                <div className="w-100">
                                    <div className={classnames("border-bottom", styles.border2)}>
                                        <div className="d-flex justify-content-between">
                                            <p>Product/Service</p>
                                            <p>£{toCurrency(order.price)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Quantity</p>
                                            <p data-testid="qty-display">{order.quantity}</p>
                                        </div>
                                        <div>
                                            <p className="text-muted text-uppercase font-size-sm">Additional Fees</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Postage Price</p>
                                            <p>£{toCurrency(order.postage)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>VAT</p>
                                            <p>£{toCurrency(order.vatAmount || 0)}</p>
                                        </div>
                                    </div>
                                    <div className={classnames("d-flex justify-content-between text-primary pt-3", styles.totalPrice)}>
                                        <p className="text-uppercase">Total</p>
                                        <p>£{toCurrency(order.totalPrice)}</p>
                                    </div>
                                    <div className={classnames(styles.frequency, "text-right text-muted text-uppercase font-size-sm")}>
                                        SUBSCRIPTION
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
