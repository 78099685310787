import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Carousel, CarouselControl, CarouselItem, Modal, ModalBody } from "reactstrap";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState } from "@/Components/RichTextEditor/Utils";
import EscapeOnCloseWrapper from "@/Wrappers/EscapeOnCloseWrapper";

const ServiceMoreDetailsModal = ({ name, images, isEnquiry, moreDetails, onClose, onAddService, pricingComponent, disableAddService }) => {
    const imagesIndexLength = images.length - 1;
    const [showExpanded, setExpanded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleExpanded = () => { setExpanded(!showExpanded); };
    const next = () => { setActiveIndex(activeIndex === imagesIndexLength ? 0 : activeIndex + 1); };
    const previous = () => { setActiveIndex(activeIndex === 0 ? imagesIndexLength : activeIndex - 1); };

    if (showExpanded) {
        return (
            <Modal isOpen centered className={classnames(styles.fullScreen, "h-100 w-100 m-0 ")} contentClassName="h-100 rounded-0">
                <ModalBody className="pr-0">
                    <div className="d-flex align-items-center justify-content-between m-4">
                        <h3 className="font-weight-bold">{name}</h3>
                        <div className="d-flex align-items-center font-weight-bold">
                            <h5>{activeIndex + 1} of {images.length}</h5>
                            <button type="button" onClick={toggleExpanded} className="mr-4 btn">
                                <h3 className="font-weight-bold"><i className="fa fa-times" /></h3>
                            </button>
                        </div>
                    </div>

                    <div className="text-center container">
                        <Carousel className="h-75" activeIndex={activeIndex} next={next} previous={previous}>
                            {images.map(item => <CarouselItem key={item.name}> <Image src={item.data} alt={item.name} className="w-100" /> </CarouselItem>)}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    return (
        <Modal isOpen centered size="xl">
            <ModalBody>
                <div className="d-flex flex-column" data-testid="service-details-modal">

                    <div className="m-2 m-lg-4  d-flex flex-column flex-md-row justify-content-between">
                        <h3 className="font-weight-bold">{name}</h3>

                        {images.length > 0 && (
                            <Button onClick={toggleExpanded} className={classnames(styles.expand, "text-white border-0")}>
                                <span>{activeIndex + 1} of {images.length}</span>
                                <i className="fa fa-expand-arrows-alt ml-2" />
                            </Button>
                        )}
                    </div>

                    <div className="m-2 m-lg-4 d-flex flex-column flex-lg-row justify-content-between">
                        <div className="mr-lg-4">
                            {images.length > 0 && (
                                <Carousel className={classnames(styles.imageContainer, "mb-4")} activeIndex={activeIndex} next={next} previous={previous}>
                                    {images.map(item => (
                                        <CarouselItem key={item.name}>
                                            <Image src={item.data} alt={item.name} className="w-100 w-lg-auto my-auto" />
                                        </CarouselItem>
                                    ))}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                                </Carousel>
                            )}
                            {moreDetails && <RichTextEditor editorState={createEditorState(moreDetails)} readonly showToolbar={false} />}
                        </div>
                        <div>
                            <div className="my-4 mt-lg-0">
                                {pricingComponent}
                            </div>

                            <div className="d-flex">
                                <Button
                                    block
                                    className="mr-2"
                                    color="secondary"
                                    onClick={onClose}
                                >
                                    Back
                                </Button>
                                <Button
                                    block
                                    onClick={onAddService}
                                    disabled={disableAddService}
                                    className={classnames(styles.basketButton, "mt-0 d-flex flex-column align-items-center")}
                                >
                                    {isEnquiry ? "Enquire now" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

ServiceMoreDetailsModal.propTypes = {
    isEnquiry: PropTypes.bool,
    name: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, data: PropTypes.string.isRequired })),
    moreDetails: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onAddService: PropTypes.func.isRequired,
    disableAddService: PropTypes.bool,
    pricingComponent: PropTypes.node.isRequired,
};

ServiceMoreDetailsModal.defaultProps = {
    isEnquiry: false,
};

const EscapedServiceMoreDetailsModal = EscapeOnCloseWrapper(ServiceMoreDetailsModal, true);
export { EscapedServiceMoreDetailsModal as ServiceMoreDetailsModal };
export { ServiceMoreDetailsModal as PureServiceMoreDetailsModal }; // used in tests only
