import { useState } from "react";

import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { Urls } from "@/Apis/urls";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { CompanyDocumentType, Document } from "@/Apis/Documents";

const useDocuments = (toggleReloadDocuments?, showPreviousPage?) => {
    const {
        getFile,
        del,
        post,
        put,
        loading: processingAction,
    } = useAPI({ handle500WithToastMessage: true });

    const {
        setSuccessMessage,
        setPopupErrorMessage,
    } = useToastMessageContext();

    const [documentForDelete, setDocumentForDelete] = useState<Document>(); // Id
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);

    const [documentsAdded, setDocumentsAdded] = useState<string[]>([]); // We need to track whether there are any new documents

    const toggleDeleteConfirmationModal = (document?: Document) => {
        setDocumentForDelete(document);
        setConfirmDeleteModalOpen(prev => !prev);
    };

    const onDownload = (documentId: string | undefined, isChaiDocument: boolean = false) => {
        if (!documentId) {
            setPopupErrorMessage("No document id, download not possible", true);
            return;
        }

        getFile(Urls.profile.document.downloadDocument(documentId, isChaiDocument))
            .then(response => {
                downloadUseAPIFile("application/octet-stream", response);
                setSuccessMessage(`${response.name} downloaded`, true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    const onDelete = async () => {
        if (!documentForDelete || !documentForDelete.id) {
            setPopupErrorMessage("No document primed for delete");
            return undefined;
        }

        try {
            await del(Urls.profile.document.delete(documentForDelete.id), {});
            setSuccessMessage("File successfully deleted", true);
            if (showPreviousPage) {
                showPreviousPage();
            }
            if (toggleReloadDocuments) {
                toggleReloadDocuments();
            }
            setDocumentsAdded(prev => {
                if (prev.includes(documentForDelete.name)) {
                    return [...prev.filter(x => x !== documentForDelete.name)];
                }

                return prev;
            });
            return documentForDelete;
        } catch (error: any) {
            if (typeof error === "string") {
                setPopupErrorMessage(error, true);
            } else if (error.message) {
                setPopupErrorMessage(error.message, true);
            }
            return undefined;
        } finally {
            setConfirmDeleteModalOpen(false);
        }
    };

    // We save the documents immediately on drop
    const onSave = (documents: Document[],
        targetUserId?: string,
        companyDocument?: CompanyDocumentType,
        isNotification?: boolean) => post(Urls.profile.document.save,
        {
            documents,
            targetUserId,
            companyDocument,
            isNotification,
        })
        .then((response) => {
            setSuccessMessage("Saved Successfully", true);
            if (toggleReloadDocuments) {
                toggleReloadDocuments();
            }
            setDocumentsAdded(prev => [...prev, ...documents.map(x => x.name)]);
            return response?.documents;
        })
        .catch(error => {
            if (typeof error === "string") {
                setPopupErrorMessage(error, true);
            } else if (error.message) {
                setPopupErrorMessage(error.message, true);
            }
            return [];
        });

    const onUpdateCompanyDocument = (targetUserId: string,
        companyDocument: CompanyDocumentType,
        documentsAdded: boolean,
        documentIds: string[]) => put(Urls.documents.updateCompanyDocument(targetUserId),
        {
            companyDocument,
            documentsAdded,
            documentIds,
        })
        .then(() => {
            setSuccessMessage("Saved Successfully", true);
            if (toggleReloadDocuments) {
                toggleReloadDocuments();
            }
            setDocumentsAdded([]);
            return true;
        })
        .catch(error => {
            if (typeof error === "string") {
                setPopupErrorMessage(error, true);
            } else if (error.message) {
                setPopupErrorMessage(error.message, true);
            }
            return false;
        });

    return {
        onSave,
        onDelete,
        onDownload,
        toggleDeleteConfirmationModal,
        confirmDeleteModalOpen,
        processingAction,
        documentForDelete,
        onUpdateCompanyDocument,
        documentsAdded: documentsAdded.length > 0,
    };
};

export { useDocuments };
