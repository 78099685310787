import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import styles from "./styles.module.scss";

interface Props {
    term: string;
    onChange: (term: string) => void;
}

const SearchBox = ({ term, onChange }: Props) => {
    const [localValue, setLocalValue] = useState(term);
    const [searchSubject] = useState(new Subject<string>());

    useEffect(() => {
        const subscription = searchSubject
            .pipe(debounceTime(500))
            .subscribe(newValue => onChange(newValue));
        return () => subscription.unsubscribe();
    }, [onChange, searchSubject]);

    const onTextChanged = (e) => {
        const { value } = e.target;
        setLocalValue(value);
        searchSubject.next(value);
    };

    return (
        <div className="w-25 border rounded d-flex justify-content-between">
            <input
                type="text"
                value={localValue}
                className="m-2"
                placeholder="Search Suppliers"
                onChange={onTextChanged}
            />
            <i className={classnames(styles.icon, "fa fa-search mt-1 mr-2 pr-2")} />
        </div>
    );
};

export { SearchBox };
