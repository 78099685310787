import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Label } from "reactstrap";

import style from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import api from "@/Utils/api";
import { encodeFileToBase64 } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes } from "@/Utils/constants";
import { substringFromLastIndexOf } from "@/Utils/formatStringHelper";

const AutoSaveFileUpload = ({ heading, autoSaveRoute, onFileAdded, onFileUploaded, accept, disabled, documentName = "" }) => {
    const uniqueId = uuid();
    const [errorMessage, setErrorMessage] = useState("");
    const [savingTerms, setSavingTerms] = useState(false);
    const [fileName, setFileName] = useState(substringFromLastIndexOf(documentName, "/"));

    const onButtonClick = () => document.getElementById(uniqueId).click();

    const onAddFile = async (files) => {
        const file = files[0];

        try {
            const filenameArr = file.name.split(".");
            if (acceptedFileTypes.documents.indexOf(filenameArr[filenameArr.length - 1]) < 0) {
                throw new Error(`Unsupported file type. Accepted types: ${acceptedFileTypes.documents}`);
            }
            const encodingResult = await encodeFileToBase64(file);

            setSavingTerms(true);
            setErrorMessage("");

            const response = await api.post(autoSaveRoute, {
                fileName: file.name,
                base64Document: encodingResult.target.result,
            });

            if (onFileAdded) {
                onFileAdded();
            }
            setFileName(file.name);
            onFileUploaded(response.fileLocation);
        } catch (error) {
            setErrorMessage(error.message || "File upload failed.");
        }

        setSavingTerms(false);
    };

    if (disabled) {
        return (
            <FormGroup>
                <Label>{heading}</Label>
                <a href={`${window.cdnUrl}${documentName}`} target="_blank" rel="noopener noreferrer" className="d-block mb-3 font-weight-bold">
                    Download
                    <i className="ml-2 fa fa-download" />
                </a>
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            <Label>{heading}</Label>
            <InputGroup>
                <Input type="text" disabled className={classnames(style.input, errorMessage && "border-danger")} value={fileName} />
                <InputGroupAddon addonType="append">
                    <InputGroupText className={classnames(style.button, "bg-primary text-white", errorMessage && "border-danger")} onClick={onButtonClick}>
                        Browse Files {savingTerms && <Spinner size="15" className="ml-1" />}
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
            {errorMessage && <span className="text-danger">{errorMessage}</span>}
            <input type="file" id={uniqueId} className="d-none" accept={accept} onChange={e => onAddFile([...e.target.files])} />
        </FormGroup>
    );
};

AutoSaveFileUpload.propTypes = {
    accept: PropTypes.string,
    documentName: PropTypes.string,
    heading: PropTypes.string.isRequired,
    onFileUploaded: PropTypes.func.isRequired,
    autoSaveRoute: PropTypes.string.isRequired,
    onFileAdded: PropTypes.func,
    disabled: PropTypes.bool,
};

AutoSaveFileUpload.defaultProps = {
    accept: acceptedFileTypes.documents,
};

export { AutoSaveFileUpload };
