import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Button, Modal, ModalBody } from "reactstrap";
import { Route } from "react-router-dom";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import { Image } from "@/Components/Image";
import Spinner from "@/Components/Spinner";
import { OrderDetailsModalHeader } from "@/Components/OrderDetailsModalHeader";
import { DownloadInvoice } from "@/Components/DownloadInvoice";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import api from "@/Utils/api";
import { CustomerOrderAttachmentsModal } from "@/Modals/CustomerOrderAttachmentsModal";
import EscapeOnCloseWrapper from "@/Wrappers/EscapeOnCloseWrapper";
import { PaymentHistoryModal } from "@/Modals/PaymentHistoryModal";

const oneTimePayment = "One Time Payment";

const CustomerOrderDetailsModal = ({ id, isFromUserOrder, userId }) => {
    const dispatch = useDispatch();
    const onPush = useCallback(path => () => dispatch(push(path)), [dispatch]);

    const [order, setOrder] = useState({ supplierAttachments: [] });
    const [loading, setLoading] = useState(true);
    const [subscriptionIdLoading, setSubscriptionIdLoading] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState("");
    const [subscriptionIdMessage, setSubscriptionIdMessage] = useState(undefined);
    const isAdmin = useSelector(state => state.user.isAdmin);
    const isDeveloper = useSelector(state => state.user.isDeveloper);

    const viewPaymentHistory = () => {
        setShowPaymentHistory(true);
    };

    useEffect(() => {
        setLoading(true);
        const getOrderDetailsUrl = isFromUserOrder ? `customer-orders/${id}/developer` : `customer-orders/${id}`;
        api.get(getOrderDetailsUrl)
            .then((response) => {
                setOrder(response);
                setSubscriptionId(response.subscriptionId);
                setLoading(false);
            })
            .catch((error) => {
                onPush({
                    pathname: "/error",
                    state: {
                        error,
                        referrer: window.location.href,
                    },
                });
            });
    }, [id, onPush]);

    const validateSubscriptionId = (subscriptionId) => {
        const regex = new RegExp("^sub_-*");
        if (!regex.test(subscriptionId)) {
            setSubscriptionIdMessage({ type: "Error", message: "Subscription ID is invalid" });
            return false;
        }
        setSubscriptionIdMessage(undefined);
        return true;
    };

    const onChangeSubscriptionId = (e) => {
        setSubscriptionId(e.target.value);
        validateSubscriptionId(e.target.value);
    };

    const saveSubscriptionId = () => {
        if (!validateSubscriptionId(subscriptionId)) {
            return;
        }
        setSubscriptionIdLoading(true);
        api.post("customer-orders/save-subscription-id", {
            orderId: id,
            subscriptionId,
        }).then(() => {
            setSubscriptionIdLoading(false);
            setSubscriptionIdMessage({ type: "Success", message: "Subscription ID updated successfully!" });
            setTimeout(() => {
                setSubscriptionIdMessage(undefined);
            }, 2000);
        })
            .catch((error) => {
                onPush({
                    pathname: "/error",
                    state: {
                        error,
                        referrer: window.location.href,
                    },
                });
            });
    };

    const onClose = () => {
        if (isFromUserOrder) {
            return onPush(`/user-orders/${userId}`);
        }
        return onPush("/customer-orders");
    };

    const modalContent = () => (
        <>
            {
                order.supplierLogo
                    ? <div className={classnames(styles.imageContainer, "d-flex justify-content-center mb-2")}><Image src={order.supplierLogo} alt="logo" /></div>
                    : null
            }

            <OrderDetailsModalHeader {...order} />

            <OrderStatusIcon status={order.status} />

            {order.serviceDescription && <p>{order.serviceDescription}</p>}
            {order.serviceImage && (<div className={classnames(styles.imageContainer, "mb-2")}><Image src={order.serviceImage} alt="logo" /></div>)}

            {(isAdmin || isFromUserOrder) && order.productIsSubscription && (
                <div className="d-flex mb-3">
                    <div className="w-50">
                        <FloatLabelTextbox
                            label="Subscription ID"
                            className="w-100 mb-0"
                            onChange={onChangeSubscriptionId}
                            value={subscriptionId}
                        />
                    </div>
                    <Button
                        className="my-auto ml-2 w-25"
                        block
                        color="primary"
                        disabled={(isFromUserOrder && !isDeveloper) || (subscriptionIdLoading || (subscriptionIdMessage && subscriptionIdMessage.type === "Error"))}
                        onClick={saveSubscriptionId}
                    >
                        {subscriptionIdLoading ? <Spinner size="24" /> : "Save Subscription ID"}
                    </Button>

                    {subscriptionIdMessage
                        && (
                            <div className={classnames("w-25 my-auto pl-2", subscriptionIdMessage.type === "Error" ? "text-danger" : "text-success")}>
                                <span>
                                    {subscriptionIdMessage.message}
                                </span>
                            </div>)}
                </div>)}

            {order.paymentFrequency !== oneTimePayment && (
                <Button className="mb-3 mt-1" block color="primary" disabled={isFromUserOrder || loading} onClick={viewPaymentHistory}>
                    View Payment History
                </Button>)}

            <DownloadInvoice disabled={isFromUserOrder} orderId={order.id} />

            <Button
                className="mb-3 mt-1"
                color="primary"
                block
                disabled={isFromUserOrder || loading || order.supplierAttachments.length === 0}
                title={order.supplierAttachments.length === 0 ? "There are no attachments for this order." : ""}
                onClick={onPush(`/customer-orders/${id}/details/attachments`)}
            >
                View Supplier Attachments
            </Button>

            <Button
                className="mb-3 mt-1"
                color="secondary"
                block
                disabled={isFromUserOrder || loading}
                onClick={onPush(`/messages/inbox?new&userId=${order.supplierUserId}&orderId=${order.id}`)}
            >
                Contact Seller
            </Button>

            <Button className={classnames(styles.reviewButton, "mb-3 mt-1")} color="primary" block disabled outline onClick={onPush("")}>Add a Review</Button>

            <Button
                className={classnames(styles.cancelButton, "mt-1")}
                color="danger"
                block
                disabled={isFromUserOrder || loading || order.status.toLowerCase() === "cancelled" || order.status.toLowerCase() === "requestedcancellation"}
                onClick={onPush(`/customer-orders/${id}/details/cancellation`)}
            >
                {order.paymentFrequency !== oneTimePayment ? "Request Cancellation" : "Request Refund"}
            </Button>
        </>
    );

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white">
            <ModalBody className="d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative">
                <CloseButton onClick={onClose()} />
                {loading ? <Spinner /> : modalContent()}
            </ModalBody>
            <Route
                path="/customer-orders/:id/details/attachments"
                render={() => <CustomerOrderAttachmentsModal id={id} attachments={order.supplierAttachments} onClose={onPush(`/customer-orders/${id}/details`)} />}
            />
            {showPaymentHistory && (<PaymentHistoryModal orderId={order.id} onClose={() => setShowPaymentHistory(false)} isCustomer />)}
        </Modal>
    );
};

CustomerOrderDetailsModal.propTypes = {
    id: PropTypes.string.isRequired,
    isFromUserOrder: PropTypes.bool,
    userId: PropTypes.string,
};

const EscapedOrderDetailsModal = EscapeOnCloseWrapper(CustomerOrderDetailsModal);
export { EscapedOrderDetailsModal as CustomerOrderDetailsModal };
