import React from "react";
import { Button } from "reactstrap";

import { deepEqual } from "@/Utils/objectHelper";
import Spinner from "@/Components/Spinner";
import { BackButton } from "@/FlexPlan/Components/BackButton";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";

const LineItemsTabButtons = () => {
    const {
        disableButtons,
        formState,
        onSaveOrder,
        savingOrder,
        onSubmitClaim,
        submittingPayment,
        invalidFields,
        onDeleteRows,
        initialOrderStatus,
    } = useFlexPlanOrderContext();

    return (
        <div className="tw-flex tw-flex-row tw-justify-center tw-space-x-5 tw-mt-10">
            <Button
                color="primary"
                className="tw-w-32"
                disabled={disableButtons
                    || !formState.supplierId
                    || !formState.participantId
                    || !formState.ndisServiceBookingNumber
                    || !deepEqual(invalidFields, {})
                    || formState.items.some(item => !deepEqual(item.invalidFields, {}))}
                onClick={onSubmitClaim}
            >
                {submittingPayment ? <Spinner className="tw-mx-auto" size="25" /> : "Submit Claim"}
            </Button>
            <Button
                color="primary"
                onClick={() => onSaveOrder(true)}
                disabled={disableButtons
                    || !deepEqual(invalidFields, {})
                    || !formState.supplierId
                    || formState.items.some(item => !deepEqual(item.invalidFields, {}))}
                className="tw-w-32"
            >
                {(savingOrder && (!submittingPayment)) ? <Spinner className="tw-mx-auto" size="25" /> : "Save"}
            </Button>
            <BackButton
                className="tw-w-32"
                displayConfirmationModal
                buttonText="Cancel"
                disabled={disableButtons}
            />
            <Button
                onClick={onDeleteRows}
                color="danger"
                disabled={disableButtons || initialOrderStatus === FlexPlanOrderStatus.Approved}
                className="tw-w-32"
            >
                Delete
            </Button>
        </div>
    );
};

export { LineItemsTabButtons };
