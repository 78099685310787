import React from "react";
import { v4 as uuid } from "uuid";

import { DropZoneFile, DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import { ClickTap } from "@/Components/ClickTap";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";
import { CreateReceiptFormFields, ReceiptType } from "@/Apis/Receipts/Create";
import { EncodedFile } from "@/Utils/base64EncodeHelper";

interface Props {
    formValues: CreateReceiptFormFields,
    setFormValues: React.Dispatch<React.SetStateAction<CreateReceiptFormFields>>,
}

const ReceiptsAttachmentsPage = ({ formValues, setFormValues }: Props) => {
    const isInvoiceInputDisabled = !!formValues?.id && formValues?.receiptType === ReceiptType.Invoice;

    const addOtherDocumentAttachments = async (files: EncodedFile[]) => {
        setFormValues({
            ...formValues,
            attachments: formValues.attachments.concat(files.map(file => ({ ...file, isTermsAndConditions: false, id: uuid() }))),
        });
    };

    const addTermsAndConditionsAttachment = async (files: EncodedFile[]) => {
        if (files?.length > 0) {
            setFormValues({
                ...formValues,
                attachments: formValues.attachments.concat({ ...files[0], isTermsAndConditions: true, id: uuid() }),
            });
        }
    };

    const deleteOtherDocumentAttachments = async (file: DropZoneFile, key: number) => {
        const fileDelete = formValues.attachments?.filter(x => !x.isTermsAndConditions)?.find((x, i) => x.name === file.fileName && i === key);
        setFormValues({
            ...formValues,
            attachments: formValues.attachments?.filter(x => x.id !== fileDelete?.id),
        });
    };

    const deleteTermsAndConditionsAttachment = async (file: DropZoneFile, key: number) => {
        const fileDelete = formValues.attachments?.filter(x => x.isTermsAndConditions)?.find((x, i) => x.name === file.fileName && i === key);
        setFormValues({
            ...formValues,
            attachments: formValues.attachments?.filter(x => x.id !== fileDelete?.id),
        });
    };

    const confirmDeleteBody = (file: DropZoneFile | undefined | null, message: React.ReactElement) => (
        <>
            {message}
            <p className="tw-font-bold tw-text-base">{file?.fileName}</p>
            <p><em>You will still need to save to finalise this action.</em></p>
        </>
    );

    const validTAndCFileTypes = acceptedFileTypes.pdf;
    const validOtherDocsFileTypes = acceptedFileTypes.documents;

    const formatFileTypeError = (fileTypes: string): string => `File must be a ${fileTypesText(fileTypes)}.`;

    return (
        <div className="tw-flex tw-flex-col tw-gap-8 tw-mt-8">
            <div>
                <h3>Terms and Conditions</h3>
                <div className="flex-column">
                    <DropZoneMulti
                        onAdd={addTermsAndConditionsAttachment}
                        onDelete={deleteTermsAndConditionsAttachment}
                        accept={validTAndCFileTypes}
                        showBrowseButton={false}
                        showEmptyFile={false}
                        confirmDelete
                        confirmDeleteConfiguration={{
                            title: <>Confirm removal</>,
                            body: _ => confirmDeleteBody(_, <p className="tw-text-base">Are you sure you want to remove the attached terms and conditions document?</p>),
                            yes: <>Proceed</>,
                            no: <>Cancel</>,
                        }}
                        max={1}
                        initialFiles={formValues.attachments.filter(x => x.isTermsAndConditions).length > 0
                            ? [{
                                fileName: formValues.attachments.find(x => x.isTermsAndConditions)?.name ?? "",
                                disabled: false,
                                isUpdating: false,
                                id: formValues.attachments.find(x => x.isTermsAndConditions)?.id,
                            }] : []}
                        showHeader={false}
                        validationContent={formatFileTypeError(validTAndCFileTypes)}
                        dropAreaContent={(
                            <span className="tw-font-normal">
                                <ClickTap /> to select or drag and drop&nbsp;
                                <span className="tw-font-bold">
                                    Terms and Conditions
                                </span>
                                &nbsp;document to upload here. (<span className="tw-font-bold">Note:</span>
                                &nbsp;If you have more than one document, please collate into one file for upload.)
                            </span>
                        )}
                        disabled={isInvoiceInputDisabled}
                    />
                </div>
            </div>
            <div>
                <h3>Other Documents</h3>
                <div className="flex-column">
                    <DropZoneMulti
                        onAdd={addOtherDocumentAttachments}
                        onDelete={deleteOtherDocumentAttachments}
                        accept={validOtherDocsFileTypes}
                        showBrowseButton={false}
                        showEmptyFile={false}
                        confirmDelete
                        confirmDeleteConfiguration={{
                            title: <>Confirm removal of attachment</>,
                            body: _ => confirmDeleteBody(_, <p className="tw-text-base">Are you sure you want to remove the attachment?</p>),
                            yes: <>Proceed</>,
                            no: <>Cancel</>,
                        }}
                        validationContent={formatFileTypeError(validOtherDocsFileTypes)}
                        initialFiles={formValues.attachments?.filter(x => !x.isTermsAndConditions).map(x => ({ fileName: x.name, disabled: false, isUpdating: false, id: x.id }))}
                        showHeader={false}
                        dropAreaContent={(
                            <span className="tw-font-normal">
                                <ClickTap /> to select or drag and drop documents to upload here.
                            </span>)}
                        disabled={isInvoiceInputDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export { ReceiptsAttachmentsPage };
