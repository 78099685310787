import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";

import CloseButton from "@/Components/CloseButton";

const CustomerOrderAttachmentsModal = ({ attachments, onClose }) => (
    <Modal isOpen centered size="lg" contentClassName="bg-white mx-5">
        <ModalBody className="d-flex flex-column p-3 p-sm-5 position-relative">
            <CloseButton onClick={onClose} />
            <h3 className="font-weight-bold my-3">Supplier Attachments</h3>

            {attachments.map(x => (
                <div key={x.url} className="d-flex">
                    <span className="flex-grow-1 px-3 py-2 mb-2 mr-3 border border-primary rounded">{x.name}</span>
                    <a href={`${window.cdnUrl}${x.url}`} target="_blank" rel="noopener noreferrer">
                        <Button color="primary"><i className="fa fa-download" /></Button>
                    </a>
                </div>
            ))}
        </ModalBody>
    </Modal>
);

CustomerOrderAttachmentsModal.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired,
    onClose: PropTypes.func.isRequired,
};

export { CustomerOrderAttachmentsModal };
