import React from "react";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { Ratings } from "@/Components/MinisiteContent/SiteContent/Ratings";
import { ContactUs } from "@/Components/MinisiteContent/SiteContent/ContactUs";
import { FeaturedReview } from "@/Components/MinisiteContent/SiteContent/FeaturedReview";

interface Props {
    siteType: MiniSiteType,
}

const RatingsAndContactUs = ({
    siteType,
}: Props) => (
    <div className="tw-mt-5">
        {siteType?.template?.showContactUs && siteType?.template?.showRatings
            && (
                <div className="tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-x-3 sm:tw-space-y-0">
                    <div className="tw-w-full sm:tw-w-1/2">
                        <Ratings rating={siteType?.rating} />
                        {siteType?.featuredLatestReview
                            && <FeaturedReview
                                featuredLatestReview={siteType.featuredLatestReview}
                                supplierId={siteType.id}
                                averageRating={siteType.rating}
                            />}
                    </div>
                    <div className="tw-w-full sm:tw-w-1/2">
                        <ContactUs userId={siteType.userId} />
                    </div>
                </div>
            )}
        {siteType?.template?.showRatings && !siteType?.template?.showContactUs && (
            <>
                <Ratings rating={siteType?.rating} />
                {siteType?.featuredLatestReview
                    && <FeaturedReview
                        featuredLatestReview={siteType.featuredLatestReview}
                        supplierId={siteType.id}
                        averageRating={siteType.rating}
                    />}
            </>
        )}
        {siteType?.template?.showContactUs && !siteType?.template?.showRatings && <ContactUs userId={siteType.userId} />}
    </div>
);

export { RatingsAndContactUs };
