/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { ClickAwayListener } from "@/Components/ClickAwayListener";
import styles from "@/Components/HelpList/styles.module.scss";
import CloseButton from "@/Components/CloseButton";
import { OnboardingSteps } from "@/Context/OnboardingContext/onBoardingSteps";
import { useOnboardingContext } from "@/Context/OnboardingContext";

interface Props {
    isShowing: boolean;
    onHide: () => {} | void;
}

const HelpList = ({ isShowing, onHide } : Props) => {
    const { showOnBoardingStep } = useOnboardingContext();

    const showOnboarding = (key) => {
        showOnBoardingStep(key);
        onHide();
    };

    return (
        <>
            {isShowing && (
                <ClickAwayListener onClickAway={onHide}>
                    <div
                        data-testid="help-list-popup"
                        className={classnames(styles.helpPopup, "position-absolute mt-3 rounded box-shadow bg-white")}
                    >
                        <CloseButton onClick={onHide} className="text-white" />
                        <div className="d-flex align-items-center p-3 bg-primary rounded-top text-white">
                            <h6 className="mb-0">Help</h6>
                        </div>
                        <div className="p-3 h6">
                            {Object.keys(OnboardingSteps).map(key => (
                                <div key={key}>
                                    <Link to={`/marketplace?onBoardingStep=${OnboardingSteps[key]}`}>
                                        <Button
                                            color="link"
                                            block
                                            className="font-weight-bold text-left"
                                            onClick={() => showOnboarding(OnboardingSteps[key])}
                                        >
                                            {OnboardingSteps[key]}
                                        </Button>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </ClickAwayListener>)}
        </>);
};

export { HelpList };
