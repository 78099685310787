import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import styles from "./styles.module.scss";

import { ClickAwayListener } from "@/Components/ClickAwayListener";
import Checkbox from "@/Components/Checkbox";
import { Visible } from "@/Components/Visible";
import { FloatLabelSearchTextBox } from "@/Components/FloatLabelSearchTextBox";
import sortingUtil from "@/Utils/sortingUtil";

export interface FilterOption {
    value: string;
    display: string;
    checked: boolean;
}

interface Props {
    label: string;
    onApply: (selections: FilterOption[]) => void;
    options: FilterOption[];
    testId?: string;
    autoComplete?: boolean;
    selections: FilterOption[];
}

export const MultiSelectFilter = ({ label, onApply, options, testId, selections, autoComplete = false } : Props) => {
    const [optionsState, setOptionsState] = useState([] as FilterOption[]);
    const [showModal, setShowModal] = useState(false);
    const [filterOptionValue, setFilterOptionValue] = useState("");

    const filterOptions = (newValue) => {
        setFilterOptionValue(newValue);
        setOptionsState(options.filter(x => x.display.toLowerCase().indexOf(newValue.toLowerCase()) !== -1)
            .map(x => ({ ...x, checked: selections.some(s => s.value === x.value) })));
    };

    useEffect(() => {
        setOptionsState(options);
        filterOptions(filterOptionValue);
    }, [options]);

    const onShowModal = () => {
        setOptionsState(optionsState.map(x => ({ ...x, checked: selections.some(s => s.value === x.value) })));
        setShowModal(true);
    };

    const onHideModal = () => setShowModal(false);

    const onClickApply = () => {
        onHideModal();
        onApply(optionsState.filter(x => x.checked));
    };

    const onSelectAll = () => setOptionsState(optionsState.map(x => ({ ...x, checked: true })));

    const onClearAll = () => {
        setOptionsState(options.map(x => ({ ...x, checked: false })));
    };

    const onChange = (value: string) => () => {
        const newState = [...optionsState];
        const option = newState.find(x => x.value === value) as FilterOption;
        option.checked = !option.checked;
        setOptionsState(newState);
    };

    const filterButtons = () => (
        <>
            <Button onClick={onHideModal} color="secondary" className="mr-2" data-testid={`${testId}-cancel`}>Cancel</Button>
            <Button onClick={onClickApply} color="primary" data-testid={`${testId}-apply`}>Apply</Button>
        </>
    );

    const filterContent = () => (
        <>
            { autoComplete && (
                <FloatLabelSearchTextBox
                    onChange={filterOptions}
                    value={filterOptionValue}
                />
            )}
            <button
                type="button"
                onClick={onSelectAll}
                disabled={false}
                id={`${testId}-select-all`}
                className={classnames(styles.link, "ml-3 pt-3")}
                data-testid={`${testId}-select-all`}
            >
                SELECT ALL
            </button>
            <button
                type="button"
                onClick={onClearAll}
                disabled={false}
                id={`${testId}-clear`}
                className={classnames(styles.link, "tw-px-3")}
                data-testid={`${testId}-clear`}
            >
                CLEAR
            </button>
            <div className={classnames(styles.filter)}>
                <Row className={classnames(styles.filterContent, "d-flex flex-grow-1 flex-wrap pt-2")}>
                    {optionsState.sort((x, y) => sortingUtil.sort(x, y, "display", true)).map(x => (
                        <Col xs="12" sm="8" md="6" className="mb-3" key={x.value}>
                            <Checkbox
                                onChange={onChange(x.value)}
                                id={x.value}
                                checked={x.checked}
                                label={x.display}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );

    const renderDesktopFilter = () => (
        <div className={classnames(styles.modal, "position-absolute dropdown-menu show shadow bg-white rounded !tw-z-[1001]")}>
            {filterContent()}
            <hr />
            <div className="d-flex justify-content-end pr-3 pb-2">
                {filterButtons()}
            </div>
        </div>
    );

    const renderMobileFilter = () => (
        <Modal isOpen={showModal} centered size="xl">
            <ModalHeader>{label}</ModalHeader>
            <ModalBody>
                {filterContent()}
            </ModalBody>
            <ModalFooter>
                {filterButtons()}
            </ModalFooter>
        </Modal>
    );

    return (
        <div className={styles.multiSelectFilter}>
            <ClickAwayListener onClickAway={onHideModal}>
                <span>
                    <span className={classnames(styles.floatLabel)}>
                        <label className={classnames(showModal && styles.active)}>{label}</label>
                        <button
                            type="button"
                            onClick={onShowModal}
                            className={classnames(showModal && styles.active)}
                            data-testid={`${testId}-component`}
                        >
                            <div className="d-flex flex-row w-100">
                                <label className={styles.selectionLabel}>
                                    {selections.length === 0 && "No Selection"}
                                    {(selections.length === options.length && options.length > 0) && "All"}
                                    {(selections.length > 0 && selections.length !== options.length) && selections.map((x => x.display)).join(", ")}
                                </label>
                                <label className={styles.selectionCount}>({selections.length})</label>
                            </div>
                        </button>
                        <i className={classnames(styles.caret, showModal ? "fa fa-angle-up" : "fa fa-angle-down")} />
                    </span>
                    {showModal && (
                        <div>
                            <Visible below="Sm">
                                {renderMobileFilter()}
                            </Visible>
                            <Visible forAndAbove="Sm">
                                {renderDesktopFilter()}
                            </Visible>
                        </div>
                    )}
                </span>
            </ClickAwayListener>
        </div>
    );
};
