import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { ProductVariants } from "@/Utils/ProductVariants";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { EnquiryFormMiniSiteTile } from "@/Components/EnquiryFormMiniSiteTile";
import { PackageTile } from "@/Components/PackageTile";
import { ProductVariations } from "@/Utils/ProductVariations";
import CategoryTabs from "@/Components/CategoryTabs";
import { SupplierHeader } from "@/Components/SupplierHeader";
import { SiteTemplate } from "@/Components/MinisiteContent/SiteTemplate";
import { SearchTextBox } from "@/Components/SearchTextBox";
import { useBasketContext } from "@/Context/BasketContext";
import { UpsellModal } from "@/Modals/UpsellModal";
import { SupplierDescription } from "@/Components/SupplierDescription";
import { ProductType } from "@/Apis/Products/ProductType";

interface Props {
    minisiteType: MiniSiteType,
    servicesToDisplay: Array<ProductType>,
    activeCategoryTab: string,
    isBeingUsedForPackages?: boolean,
    addToBasket?: any,
    changeCategoryTab(tab: string): void,
    itemIdsInCart?: Array<string>,
    onClose?(): void,
    isPreview?: boolean
}

const MinisiteContent = ({
    minisiteType,
    servicesToDisplay,
    activeCategoryTab,
    isBeingUsedForPackages,
    addToBasket,
    changeCategoryTab,
    itemIdsInCart,
    onClose,
    isPreview,
}: Props) => {
    const shopRef = useRef<any>(null);

    const [upsellItems, setUpsellItems] = useState([]);
    const [showUpsell, setShowUpsell] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [subcategories, setSubcategories] = useState({});
    const [visibleServices, setVisibleServices] = useState({});
    const { basket } = useBasketContext();

    const isSubstring = (name, searchTerm) => name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

    const executeScrollToShop = () => shopRef.current.scrollIntoView();

    const restoreDefaultState = useCallback(() => {
        setIsSearching(false);
        setVisibleServices(servicesToDisplay);
        setSubcategories({ ...servicesToDisplay });
    }, [servicesToDisplay]);

    const onSearchChanged = (e) => {
        const searchTerm = e.target.value;

        if (searchTerm) {
            const services = minisiteType.services?.filter(x => isSubstring(x.name, searchTerm)
                                                                    || isSubstring(x.categoryName, searchTerm)
                                                                    || isSubstring(x.subCategoryName, searchTerm));

            setIsSearching(true);
            setSubcategories({ __search: [] });
            setVisibleServices({ __search: services });
        } else {
            restoreDefaultState();
        }
    };

    useEffect(() => {
        restoreDefaultState();
    }, [servicesToDisplay, activeCategoryTab, restoreDefaultState]);

    const addServiceToBasket = (product, qty, variations) => {
        addToBasket(product, qty, variations);
        if (product.upsellItems.length && !isBeingUsedForPackages) {
            setShowUpsell(true);
            setUpsellItems(product.upsellItems);
        }
    };

    const closeUpsellModal = () => setShowUpsell(false);

    return (
        <div className="tw-w-full lg:tw-max-w-[1330px] 4xl:tw-max-w-[1400px] tw-mx-auto tw-p-2">
            <SupplierHeader
                showLogo={minisiteType.showLogo}
                showName={minisiteType.showName}
                showTagline={minisiteType.showTagline ?? false}
                headerColour={minisiteType.headerColour ?? ""}
                headerImage={minisiteType.headerImage}
                nameAndTaglineColour={minisiteType.nameAndTaglineColour ?? ""}
                logo={minisiteType.logo}
                name={minisiteType.name}
                tagline={minisiteType.tagline}
                showEditHeaderButton={false}
                onClickScrollToShop={executeScrollToShop}
                onClose={onClose}
            />

            { minisiteType?.template?.selectedTemplate === 0 && <SupplierDescription
                description={minisiteType.description}
                rating={minisiteType.rating}
            />}

            { minisiteType?.template?.selectedTemplate !== 0 && (
                <div className="tw-shadow-2xl tw-my-5 tw-px-4 sm:tw-px-8 tw-py-4">
                    <SiteTemplate siteType={minisiteType} />
                </div>)}

            <div className="tw-shadow-2xl tw-my-5 tw-px-4 md:tw-px-8 tw-py-4 tw-bg-white" ref={shopRef}>
                <div className="px-3 pb-3">
                    <SearchTextBox onChange={onSearchChanged} placeholder={`Search ${minisiteType.name}`} />
                </div>

                {!isSearching && minisiteType.categoriesToDisplay && minisiteType.categoriesToDisplay?.length > 0 && (
                    <CategoryTabs
                        categories={minisiteType.categoriesToDisplay}
                        activeCategoryTab={activeCategoryTab}
                        onChange={changeCategoryTab}
                        headerColour={minisiteType.headerColour ?? ""}
                    />
                )}

                <Row className="d-flex flex-wrap mt-3">
                    {Object.keys(subcategories)
                        .sort().map(subCategory => (
                            <Col xs="12" key={subCategory} className="!tw-px-0">
                                {!isSearching && <div className="bg-secondary text-white my-4 mx-3 px-3 py-2 text-uppercase">{subCategory}</div>}
                                {visibleServices[subCategory].map(x => {
                                    if (ProductVariants.canBeAddedToBasket(x.productVariant)) {
                                        return (<ServiceMiniSiteTile
                                            key={`${x.id}-${x.name}`}
                                            id={x.id}
                                            name={x.name}
                                            description={x.description}
                                            image={x.imageThumbnail ?? x.image}
                                            paymentFrequency={x.paymentFrequency}
                                            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                            postageCharge={x.postageCharge}
                                            moreDetails={x.moreInformation}
                                            images={x.images}
                                            isInBasket={itemIdsInCart?.includes(x.id.toUpperCase())}
                                            disableAddService={isPreview}
                                            onAddService={(qty, variations) => addServiceToBasket(x, qty, variations)}
                                            addQuantity={x.addQuantity}
                                            minimumQuantity={x.minimumQuantity}
                                            isBeingUsedForPackages={isBeingUsedForPackages}
                                            variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
                                            productVariant={x.productVariant}
                                            categoryId={x.categoryId}
                                            categoryName={x.categoryName}
                                            subCategoryName={x.subCategoryName}
                                            itemInCart={basket?.groups?.flatMap(_ => _.items)?.find(y => y.productId === x.id)}
                                            initialCharge={x.initialCharge}
                                            isVatRequired={x.isVatRequired}
                                            canChooseQuantity={x.addQuantity}
                                            supplierId={x.supplierId}
                                            supplierName={x.supplierName}
                                            slug={x.slug}
                                            slugId={x.slugId}
                                            hasNAProducts={x.hasNAProducts}
                                            isDelayedPayment={x.isDelayedPayment}
                                            delayedPaymentFor={x.delayedPaymentFor}
                                            delayedPaymentPeriod={x.delayedPaymentPeriod}
                                            cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                        />);
                                    }
                                    if (x.productVariant === ProductVariants.enquiryFormProduct) {
                                        return (<EnquiryFormMiniSiteTile
                                            key={`${x.id}-${x.name}`}
                                            id={x.id}
                                            name={x.name}
                                            description={x.description}
                                            image={x.imageThumbnail ?? x.image}
                                            startingPrice={x.startingPrice}
                                            images={x.images}
                                            moreDetails={x.moreInformation}
                                            categoryId={x.categoryId}
                                            categoryName={x.categoryName}
                                            supplierId={x.supplierId}
                                            supplierName={x.supplierName}
                                            slug={x.slug}
                                            slugId={x.slugId}
                                            disableOpenEnquiryForm={isPreview}
                                            cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                        />);
                                    }
                                    if (x.categoryName === "Packages") {
                                        return (<PackageTile
                                            key={`${x.id}-${x.name}`}
                                            id={x.id}
                                            code={x.code}
                                            name={x.name}
                                            description={x.description}
                                            images={x.images}
                                            initialCharge={x.initialCharge}
                                            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                            postageCharge={x.postageCharge}
                                            supplierName={x.supplierName}
                                            productPriceSummary={x.productPrices}
                                            hasNAProducts={x.hasNAProducts}
                                            cardClassName="sm:!tw-w-[47%] lg:!tw-w-[31.5%] 3xl:!tw-w-[23.5%]"
                                            carouselItemClassName="2xl:!tw-w-[270px] 3xl:!tw-w-[298px]"
                                        />);
                                    }
                                    return (<></>);
                                })}
                            </Col>
                        ))}
                </Row>
            </div>
            {showUpsell && <UpsellModal productIds={upsellItems} onClose={closeUpsellModal} />}
        </div>
    );
};

export { MinisiteContent };
