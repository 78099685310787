// Reference data comes from our backend as a dictionary, for most use cases
// we want to convert this to an array
import { ReferenceData } from "@/FlexPlan/Hooks/Ndis/useProductCategories/Types";

export const convertReferenceDataToArray = (response: { data: any }) => {
    const data: ReferenceData[] = Object.keys(response.data)
        .map(x => ({
            key: x,
            value: response.data[x],
        }));

    return data;
};
