import React from "react";
import { Link } from "react-router-dom";

interface Props {
    to?: string,
    onClick?: () => void,
    className?: string,
    children: React.ReactNode | string,
    disabled?: boolean
}

const AppButtonLink = ({ to, onClick, className, children, disabled = false }: Props) => (
    to
        ? ( // Render link if a URL has been passed
            <Link
                to={to}
                className={className}
            >
                {children}
            </Link>
        )
        : ( // If not, render a button
            <button
                type="button"
                onClick={onClick}
                className={className}
                disabled={disabled}
            >
                {children}
            </button>
        )
);

export default AppButtonLink;
