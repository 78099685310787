import React from "react";

import { MinisiteTemplateSectionForm } from "@/Apis/Suppliers/MiniSiteType";

interface Props {
    siteSection?: MinisiteTemplateSectionForm,
    classNameImg: string,
}

const SectionImage = ({
    siteSection,
    classNameImg,
}: Props) => {
    const mobileImg = siteSection?.mobileImage?.startsWith("data:") ? siteSection?.mobileImage : `${window.cdnUrl}${siteSection?.mobileImage}`;
    const desktopImg = siteSection?.desktopImage?.startsWith("data:") ? siteSection?.desktopImage : `${window.cdnUrl}${siteSection?.desktopImage}`;
    return (
        <picture>
            <source media="(max-width: 639.9px)" srcSet={mobileImg} />
            <source media="(min-width: 640px)" srcSet={desktopImg} />
            <img
                src={desktopImg}
                alt="Section title"
                className={`tw-relative tw-flex tw-flex-col tw-object-cover tw-w-full tw-rounded tw-mt-2.5 ${classNameImg}`}
            />
        </picture>
    );
};

export { SectionImage };
