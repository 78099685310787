import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import { Image } from "@/Components/Image";
import { CircleButton } from "@/Components/CircleButton";
import { toCurrency } from "@/Utils/formatStringHelper";
import { EnquiryFormModal } from "@/Modals/EnquiryFormModal";
import { ServiceMoreDetailsModal } from "@/Modals/ServiceMoreDetailsModal";

const EnquiryFormMiniSiteTile = ({
    showEditButton,
    id,
    name,
    description,
    image,
    startingPrice,
    images,
    moreDetails,
    disableOpenEnquiryForm,
    onEditEnquiryForm,
    categoryName,
    categoryId,
    supplierId,
    supplierName,
    expandedMobile,
    slug,
    slugId,
    cardClassName,
}) => {
    const history = useHistory();
    const loggedIn = useSelector(state => state.user.isLoggedIn);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showEnquiryFormModal, setShowEnquiryFormModal] = useState(false);
    const dispatch = useDispatch();

    const toggleShowMoreDetails = () => {
        setShowMoreDetails(prevState => !prevState);
    };

    const viewMoreDetail = () => {
        if (!slugId || !slug) {
            toggleShowMoreDetails();
        } else {
            dispatch(push(`/product/${slugId}/${slug}`));
        }
    };

    const onShowEnquiryFormModal = value => () => {
        if (!loggedIn) {
            history.push(`/login?returnUrl=${encodeURIComponent(history.location.pathname + history.location.search)}`);
        } else {
            setShowEnquiryFormModal(value);
        }
    };

    const openEnquiryFormMoreDetails = () => {
        setShowMoreDetails(false);
        setShowEnquiryFormModal(true);
        toggleShowMoreDetails();
    };

    return (
        <>
            <Card
                data-testid={name}
                className={classnames(styles.cardContainer, cardClassName, "d-inline-flex flex-column mx-2 bg-white mt-3 position-relative rounded-lg")}
            >
                <div className="text-left p-0">
                    <div className="d-flex align-items-center">
                        <h5 data-testid="product-name" className={classnames(styles.name, "flex-grow-1 mb-0")}><strong>{name}</strong></h5>
                    </div>
                    <div className={classnames(styles.categoryName, "mt-1 mb-2 text-muted")}>
                        <Link to={`/marketplace/supplier-directory/${supplierId}`}>{supplierName}</Link>
                        <span> • </span>
                        <Link to={`/marketplace?categorySelections=${categoryId}`}>{categoryName}</Link>
                    </div>
                    <h5 className="font-weight-bold mb-0 text-primary">
                        From £{Number(startingPrice) === 0 ? "-" : toCurrency(startingPrice)}
                    </h5>
                </div>
                <div className={classnames(expandedMobile ? "flex-column" : "flex-sm-column", "d-flex justify-content-between")}>
                    <p
                        className={classnames(styles.description, expandedMobile ? styles.expanded : styles.condensed, "mt-lg-2 mb-0")}
                        data-testid="product-tile-description"
                    >
                        {description}
                    </p>
                    <div className={classnames(styles.supplierImageContainer, expandedMobile ? styles.expanded : styles.condensed,
                        "mt-sm-1 ml-1 bg-light d-flex justify-content-center align-items-center")}
                    >
                        {
                            image && <Image className={styles.productImage} src={image} alt="Product Image" testId="product-tile-image" />
                        }
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                    <div>
                        <Button
                            onClick={viewMoreDetail}
                            color="primary"
                            outline
                            data-testid="enquiry-tile-more-details-button"
                        >
                            View Details
                        </Button>
                    </div>
                    {
                        showEditButton ? (
                            <Button data-testid="edit-enquiry-form" onClick={onEditEnquiryForm} className={classnames(styles.editButton, "text-dark")}>
                                <i className={classnames(styles.enquiryButtonIcon, "fas fa-pencil-alt")} /> <small>Edit Enquiry Form</small>
                            </Button>
                        ) : (
                            <CircleButton
                                className={classnames(styles.enquiryButton, "bg-primary text-white border")}
                                disabled={disableOpenEnquiryForm}
                                onClick={onShowEnquiryFormModal(true)}
                            >
                                <i className={classnames(styles.enquiryButtonIcon, "fas fa-paper-plane")} />
                            </CircleButton>
                        )
                    }

                </div>
            </Card>

            {showEnquiryFormModal && (
                <EnquiryFormModal
                    onClose={onShowEnquiryFormModal(false)}
                    enquiryFormId={id}
                    name={name}
                    description={description}
                    startingPrice={startingPrice}
                />
            )}

            {showMoreDetails && (
                <ServiceMoreDetailsModal
                    isEnquiry
                    onClose={toggleShowMoreDetails}
                    onAddService={openEnquiryFormMoreDetails}
                    disableAddService={disableOpenEnquiryForm}
                    moreDetails={moreDetails}
                    images={images}
                    name={name}
                    pricingComponent={<ServiceTilePriceSection
                        addQuantity={false}
                        initialChargeWithVatIfApplicable={startingPrice}
                    />}
                />
            )}
        </>
    );
};

EnquiryFormMiniSiteTile.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    startingPrice: PropTypes.number.isRequired,
    onEditEnquiryForm: PropTypes.func,
    disableOpenEnquiryForm: PropTypes.bool,
    showEditButton: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
    })),
    moreDetails: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    supplierId: PropTypes.string,
    supplierName: PropTypes.string,
    expandedMobile: PropTypes.bool,
    slug: PropTypes.string,
    slugId: PropTypes.number,
    cardClassName: PropTypes.string,
};

EnquiryFormMiniSiteTile.defaultProps = {
    showEditButton: false,
    disableOpenEnquiryForm: false,
    images: [],
    expandedMobile: false,
};

export { EnquiryFormMiniSiteTile };
