import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { UncontrolledPopover, PopoverBody } from "reactstrap";

import styles from "./styles.module.scss";

import { ProductQuantity } from "@/Components/ProductQuantity";
import { getFrequency } from "@/Utils/formatStringHelper";

const ServiceTilePriceSection = (
    {
        initialChargeWithVatIfApplicable,
        paymentFrequency,
        postageCharge,
        disableAddService,
        addQuantity,
        minimumQuantity,
        quantity,
        onQtyChanged,
        containerClassNames,
        priceFontClassNames,
    },
) => {
    const postageFormat = parseFloat(`${Number((!postageCharge ? 0 : postageCharge))}`).toFixed(2);
    const initialChargeFormat = parseFloat(`${Number(initialChargeWithVatIfApplicable)}`).toFixed(2);

    return (
        <div className={containerClassNames}>
            <div>
                {addQuantity && (
                    <ProductQuantity
                        value={quantity}
                        onValueChanged={onQtyChanged}
                        disabled={disableAddService}
                        minimumValue={minimumQuantity}
                    />)}
            </div>

            <div>
                <span className={classnames(styles.nowrap)}>
                    <h4 className={classnames(priceFontClassNames, "mb-0 d-inline")}>£{initialChargeFormat}</h4>
                    <p className="d-inline">{getFrequency(paymentFrequency)}</p>
                </span>
                {Number(postageFormat) !== 0 && (
                    <>
                        <p className="font-size-sm text-right pointer" id="add-fees">
                            + additional fees <i className="fas fa-info-circle text-primary" />
                        </p>

                        <UncontrolledPopover trigger="hover" placement="top-end" target="add-fees">
                            <PopoverBody className={classnames(styles.popper, "shadow-lg p-3")}>
                                <h5 className="pb-3">Additional Fees</h5>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                        <p>Postage Fee</p>
                                        <p>£{postageFormat}</p>
                                    </div>
                                    <hr className="p-0 m-0 pb-3" />
                                    <div className="d-flex justify-content-between font-weight-bold">
                                        <p>Total</p>
                                        <p>£{postageFormat}</p>
                                    </div>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </>)}
            </div>
        </div>);
};

ServiceTilePriceSection.propTypes = {
    initialChargeWithVatIfApplicable: PropTypes.number.isRequired,
    paymentFrequency: PropTypes.string,
    postageCharge: PropTypes.number,
    disableAddService: PropTypes.bool,
    addQuantity: PropTypes.bool.isRequired,
    minimumQuantity: PropTypes.number,
    quantity: PropTypes.number,
    onQtyChanged: PropTypes.func,
    containerClassNames: PropTypes.string,
    priceFontClassNames: PropTypes.string,
};

ServiceTilePriceSection.defaultProps = {
    postageCharge: 0,
    containerClassNames: classnames(styles.container, "w-100 bg-light p-3 d-flex justify-content-between"),
    priceFontClassNames: "font-weight-bold",
};

export { ServiceTilePriceSection };
