import React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import RadioButton from "@/Components/RadioButton";
import { FlexPlanSupplier, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    openSections: SupplierSections[],
}

const BasicInformationSection = ({ onSave, removeErrorField, onChangeEditableSections, errors, openSections }: Props) => {
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();

    const onChange = (e: { target: { name: keyof FlexPlanSupplier, value: any } }) => {
        dispatch({
            type: SupplierProfileActions.Update,
            field: e.target.name,
            value: e.target.value,
        });
        removeErrorField(e.target.name);
    };

    const onRadioChange = (field: keyof FlexPlanSupplier, value: SupplierType) => () => {
        dispatch({
            type: SupplierProfileActions.Update,
            field,
            value,
        });

        removeErrorField(field);
        if (field === "supplierType" && (value === SupplierType.ExcludedSupply || value === SupplierType.Reimbursement)) {
            // We no longer have an ABN, remove the error
            removeErrorField("abn");
        }
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-between mb-3">
                    <h4 className="font-weight-bold">Basic Information</h4>
                    {!openSections.includes("Basic Information")
                        && (
                            <Button
                                className="px-3"
                                color="primary"
                                onClick={onChangeEditableSections("Basic Information")}
                                disabled={supplierProfileState.isPublic}
                            >
                                Edit
                            </Button>
                        )}
                </Col>
            </Row>
            <>
                {openSections.includes("Basic Information") ? (
                    <>
                        <Label>Supplier Type</Label>
                        <FormGroup className="tw-flex tw-flex-row tw-space-x-5">
                            <RadioButton
                                onChange={onRadioChange("supplierType", SupplierType.Abn)}
                                checked={supplierProfileState?.supplierType === SupplierType.Abn}
                                id="supplier-type"
                                label="ABN"
                                disabled={supplierProfileState.isPublic}
                            />
                            <RadioButton
                                onChange={onRadioChange("supplierType", SupplierType.ExcludedSupply)}
                                checked={supplierProfileState?.supplierType === SupplierType.ExcludedSupply}
                                id="supplier-type"
                                label="Excluded Supply"
                                disabled={supplierProfileState.isPublic}
                            />
                            <RadioButton
                                onChange={onRadioChange("supplierType", SupplierType.Reimbursement)}
                                checked={supplierProfileState?.supplierType === SupplierType.Reimbursement}
                                id="supplier-type"
                                label="Reimbursement"
                                disabled={supplierProfileState.isPublic}
                            />
                        </FormGroup>
                        {supplierProfileState.supplierType === SupplierType.Abn && (
                            <FloatLabelTextbox
                                label="ABN *"
                                value={supplierProfileState.abn}
                                name="abn"
                                onChange={onChange}
                                error={errors.abn}
                                disabled={supplierProfileState.isPublic}
                                maxLength={11}
                            />
                        )}
                        <FloatLabelTextbox
                            label="Business Name *"
                            value={supplierProfileState.businessName}
                            name="businessName"
                            onChange={onChange}
                            isRequired
                            isRequiredError="Company Name cannot be empty."
                            error={errors.businessName}
                            disabled={supplierProfileState.isPublic}
                            maxLength={255}
                        />
                        <div className="d-flex justify-content-end mt-3">
                            <ActionButtons
                                onCancelClick={onChangeEditableSections("Basic Information")}
                                onSaveClick={onSave}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Supplier Type</Label>
                            <Label className="font-weight-bold">{supplierProfileState.supplierType}</Label>
                        </FormGroup>
                        <hr />
                        {supplierProfileState.supplierType === SupplierType.Abn && (
                            <>
                                <FormGroup className="d-flex w-75 mb-2">
                                    <Label className="w-25">ABN</Label>
                                    <Label className="font-weight-bold">{supplierProfileState.abn}</Label>
                                </FormGroup>
                                <hr />
                            </>
                        )}
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Business Name</Label>
                            <Label className="font-weight-bold">{supplierProfileState.businessName}</Label>
                        </FormGroup>
                    </>
                )}
            </>
        </>
    );
};

export { BasicInformationSection };
