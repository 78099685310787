import React from "react";
import classnames from "classnames";

interface Props {
    className?: string,
}

const TextWireframe = ({ className }: Props) => (
    <div
        className={classnames("tw-flex tw-flex-col tw-justify-center tw-bg-gray-200 tw-px-10 tw-flex-grow", className)}
    >
        <div className="tw-px-4">
            <hr className="tw-border-2 tw-border-gray-400" />
            <hr className="tw-border-2 tw-border-gray-400" />
            <hr className="tw-border-2 tw-border-gray-400" />
        </div>
    </div>
);

export { TextWireframe };
