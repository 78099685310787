import React, { useState } from "react";
import { Button } from "reactstrap";

import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";

interface Props {
    receiptId: string,
    print?: boolean,
}

const ReceiptDownloadPdf = ({ receiptId, print }: Props) => {
    const { getFile } = useAPI({ handle500WithToastMessage: true });

    const [processing, setProcessing] = useState<boolean>(false);

    const onDownload = () => {
        setProcessing(true);
        getFile(Urls.receipts.downloadPdf(receiptId))
            .then(_ => {
                const newBlob = new Blob([_.contents], { type: "application/pdf" });
                const blobUrl = window.URL.createObjectURL(newBlob);
                if (print) {
                    const pdfWindow = window.open(blobUrl);
                    pdfWindow?.print();
                } else {
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute("download", _.name);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                    // clean up Url
                    window.URL.revokeObjectURL(blobUrl);
                }
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onDownload}
                data-testid="btn-download-pdf"
                disabled={processing}
            >{print ? "Print" : "Download"} PDF
            </Button>
        </>
    );
};

export default ReceiptDownloadPdf;
