import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import classnames from "classnames";

interface Props {
    showPages?: number;
    pageCount: number;
    activePage: number;
    onChange: (page: number) => void;
    className?: string,
}

const Paging = ({ onChange, activePage, pageCount, showPages = 6, className }: Props) => {
    const bufferPages = Math.ceil(showPages / 2);
    const [displayPages, setDisplayPages] = useState<number[]>([]);

    const pageClicked = (page: number) => {
        if (page !== activePage) {
            onChange(page);
        }
    };

    useEffect(() => {
        const pages: number[] = [];

        for (let i = 0; i < pageCount; i++) {
            pages.push(i);
        }

        if (pageCount < showPages) {
            return setDisplayPages(pages);
        }

        if (activePage < bufferPages) {
            return setDisplayPages(pages.slice(0, showPages));
        }

        if (activePage > (pageCount - bufferPages)) {
            const start = Math.min(pageCount - showPages, activePage - bufferPages);
            return setDisplayPages(pages.slice(start, pageCount));
        }

        return setDisplayPages(pages.slice(activePage - bufferPages, activePage + bufferPages));
    }, [activePage, pageCount, bufferPages, showPages]);

    return pageCount === 0 ? null : (
        <div className={classnames("d-flex justify-content-end", className)}>
            <Pagination aria-label="Page navigation example" className="right">
                {activePage > 0 && (
                    <>
                        <PaginationItem>
                            <PaginationLink first onClick={() => pageClicked(0)} />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink previous onClick={() => pageClicked(activePage - 1)} />
                        </PaginationItem>
                    </>)}

                {displayPages.map(page => (
                    <PaginationItem active={page === activePage} key={page}>
                        <PaginationLink onClick={() => pageClicked(page)}>
                            {page + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                {activePage < (pageCount - 1) && (
                    <>
                        <PaginationItem>
                            <PaginationLink next onClick={() => pageClicked(activePage + 1)} />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink last onClick={() => pageClicked(pageCount - 1)} />
                        </PaginationItem>
                    </>)}
            </Pagination>
        </div>
    );
};

export { Paging };
