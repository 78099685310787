import React, { ReactElement, ReactNode, useState } from "react";
import classnames from "classnames";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Placement } from "popper.js";
import { Button, Popover, PopoverBody } from "reactstrap";

import { ClickAwayListener } from "@/Components/ClickAwayListener";

interface Props {
    icon: ReactElement | string;
    triggerText?: string;
    targetId: string;
    cancelButtonText?: string;
    children?: ReactNode;
    onSubmit?: () => void;
    onCancel?: () => void;
    placement?: Placement;
    popoverBodyClassName?: string;
    popoverClassName?: string;
    hideArrow?: boolean;
}

const Popper = (
    {
        icon,
        triggerText,
        targetId,
        cancelButtonText = "Cancel",
        onSubmit,
        onCancel,
        children,
        placement = "bottom-start",
        popoverBodyClassName,
        popoverClassName,
        hideArrow,
    }: Props,
) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    const cancel = () => {
        setPopoverOpen(false);
        if (onCancel) {
            onCancel();
        }
    };

    const submit = () => {
        setPopoverOpen(false);
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <>
            {(icon as ReactElement).props && icon}
            {!(icon as ReactElement).props && (
                <Button color="link" id={targetId} className="text-dark" onClick={toggle}>
                    <i className={`fas ${icon} mr-2`} />
                    {triggerText}
                </Button>
            )}
            <Popover
                placement={placement}
                isOpen={popoverOpen}
                target={targetId}
                toggle={toggle}
                fade={false}
                className={classnames(popoverClassName && popoverClassName)}
                hideArrow={hideArrow}
            >
                <PopoverBody className={classnames(popoverBodyClassName || "shadow-lg")}>
                    <ClickAwayListener onClickAway={toggle}>
                        {children}
                        {onSubmit && (
                            <div className="d-flex justify-content-end m-2">
                                <Button color="secondary" className="mr-2" onClick={cancel}>{cancelButtonText}</Button>
                                <Button color="primary" onClick={submit}>Done</Button>
                            </div>)}
                    </ClickAwayListener>
                </PopoverBody>
            </Popover>
        </>
    );
};

export { Popper };
