import React from "react";
import { Col, Row } from "reactstrap";

import { AttachmentType } from "@/Apis/Messaging";

interface Props {
    attachments: AttachmentType[]
}

const AttachmentsTab = ({ attachments }: Props) => (
    <Row className="mx-4">
        <Col className="pt-3">
            {attachments.map(x => (
                <a href={`${window.cdnUrl}${x.location}`} target="_blank" rel="noopener noreferrer" className="text-dark">
                    <div className="d-flex justify-content-between py-3 border-bottom" key={x.location}>
                        {x.filename}
                        <i className="fa fa-download" />
                    </div>
                </a>
            ))}

            {attachments.length === 0 && <span>There are no attachments for this conversation</span>}
        </Col>
    </Row>
);

export { AttachmentsTab };
