import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { OrderItemPrice } from "../OrderItemPrice";

const CartItem = (
    {
        id,
        price,
        totalVat,
        totalPrice,
        postage,
        serviceName,
        category,
        subcategory,
        supplierId,
        supplierName,
        onRemove,
        quantity,
        noBorder,
        showPriceAsMinimum,
        paymentFrequency,
        isOffsitePayment,
    },
) => (
    <div className={classnames(noBorder === true ? "" : "border-bottom", "d-flex flex-column pb-3 mb-3")}>
        <h5 className="font-weight-bold mt-1">{serviceName}</h5>
        <div className="mb-2">{category} / {subcategory}</div>
        <div className="mb-2"><Link to={`/marketplace/supplier-directory/${supplierId}`}>{supplierName}</Link></div>

        <div className="d-flex justify-content-between mb-1">
            <div>
                <OrderItemPrice
                    id={id}
                    price={price}
                    postage={postage}
                    totalPrice={totalPrice}
                    totalVat={totalVat}
                    quantity={quantity}
                    alignLeft
                    showPriceAsMinimum={showPriceAsMinimum}
                    paymentFrequency={paymentFrequency}
                    isOffsitePayment={isOffsitePayment}
                />
            </div>
            <button type="button" className="text-primary bg-white border-0 p-0 font-weight-bold align-self-end" onClick={onRemove}>remove</button>
        </div>
    </div>
);

CartItem.propTypes = {
    id: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    totalVat: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    postage: PropTypes.number.isRequired,
    serviceName: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    supplierId: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    quantity: PropTypes.number,
    noBorder: PropTypes.bool,
    showPriceAsMinimum: PropTypes.bool,
    paymentFrequency: PropTypes.string.isRequired,
    isOffsitePayment: PropTypes.bool,
};

CartItem.defaultProps = {
    showPriceAsMinimum: false,
    isOffsitePayment: false,
};

export { CartItem };
