import { ChangeEvent, useEffect, useState } from "react";

import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { PlanManagerProfile, ProfileSections,
    ProfileTabState } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";

export const usePlanManagerProfile = () => {
    const { setSuccessMessage } = useToastMessageContext();
    const { loading, get, put } = useAPI({ handle500WithToastMessage: true });
    const [profileTabState, setProfileTabProfileTabState] = useState({ } as ProfileTabState);
    const [originalPlanManagerProfileState, setOriginalPlanManagerProfileState] = useState({ } as PlanManagerProfile);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        get<PlanManagerProfile>("flexplan/planmanager")
            .then((response) => {
                const planManagerProfile = { ...response,
                    supportingDocuments: [...response.supportingDocuments.map(x => ({
                        name: x.name,
                        data: x.data,
                        id: x.id,
                    }))] };
                setOriginalPlanManagerProfileState(() => (planManagerProfile));
                setProfileTabProfileTabState(() => ({ planManagerProfile, selectedSections: [] }));
            });
    }, []);

    const handleSelectEditSection = (section: ProfileSections) => () => setProfileTabProfileTabState(prevState => {
        if (prevState.selectedSections?.includes(section)) {
            return { ...prevState, planManagerProfile: { ...originalPlanManagerProfileState }, selectedSections: [...prevState.selectedSections.filter(x => x !== section)] };
        }
        return { ...prevState, selectedSections: [...prevState.selectedSections, section] };
    });

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case "bsb":
                setProfileTabProfileTabState((prevState) => {
                    const currentBsb = prevState.planManagerProfile.bsb ?? "";
                    let inputBsbValue = e.target?.value ?? "";
                    if (/^\d{0,3}-?\d{0,3}$/.test(inputBsbValue) || !inputBsbValue) {
                        if (inputBsbValue.length === 3) {
                            if (/^\d{3}-$/.test(currentBsb)) {
                                inputBsbValue = inputBsbValue.slice(0, 2);
                            } else {
                                inputBsbValue += "-";
                            }
                        }
                        if (/^\d{3}-\d{3}$/.test(currentBsb) && /^\d{6}$/.test(inputBsbValue)) {
                            return { ...prevState };
                        }
                        return {
                            ...prevState,
                            planManagerProfile: { ...prevState.planManagerProfile, [e.target.name]: (inputBsbValue) },
                        };
                    }
                    return { ...prevState };
                });
                break;
            case "accountNumber":
                setProfileTabProfileTabState((prevState) => {
                    const inputAccountNumberValue = e.target?.value ?? "";
                    if ((/^\d{0,9}$/.test(inputAccountNumberValue))
                        || !inputAccountNumberValue) {
                        return {
                            ...prevState,
                            planManagerProfile: { ...prevState.planManagerProfile, [e.target.name]: (inputAccountNumberValue) },
                        };
                    }
                    return { ...prevState };
                });
                break;
            default:
                setProfileTabProfileTabState(prevState => ({
                    ...prevState,
                    planManagerProfile: { ...prevState.planManagerProfile, [e.target.name]: e.target.value },
                }));
                break;
        }
    };

    const handleSaveClick = () => {
        put<PlanManagerProfile>("flexplan/planmanager", {
            ...profileTabState.planManagerProfile,
            supportingDocuments: profileTabState.planManagerProfile.supportingDocuments.filter(x => x.id && x.name && x.data),
        } as PlanManagerProfile)
            .then(() => {
                setOriginalPlanManagerProfileState(profileTabState.planManagerProfile);
                setProfileTabProfileTabState(prevState => ({ ...prevState, selectedSections: [] }));
                setSuccessMessage("Profile successfully saved", true);
            })
            .catch((error) => {
                if (error.validationFailed) {
                    setErrors(["Bad Request response"]);
                }
            });
    };

    const handleErrorStatusChange = (hasError: boolean, field: string) => setErrors(prevState => [...(!hasError ? prevState.filter(x => x !== field) : [...prevState, field])]);

    return { loading, errors, handleSelectEditSection, handleFieldChange, handleSaveClick, handleErrorStatusChange, profileTabState, setProfileTabProfileTabState };
};
