import { v4 as uuid } from "uuid";
import store from "store";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";

const browserIdKey = "__tbsc.browserid";
const cookiesAcceptedKey = "__tbsc.cookies.accepted";
const supplierDirectoryKey = "__tbsc.supplierdirectory";

const getBrowserId = (): string => store.get(browserIdKey);
const createBrowserId = (): void => store.set(browserIdKey, uuid());
const setBrowserId = (browserId): void => store.set(browserIdKey, browserId);

const getCookiesAccepted = (): string => store.get(cookiesAcceptedKey);
const setCookiesAccepted = (): void => store.set(cookiesAcceptedKey, true);
const removeCookiesAccepted = (): void => store.remove(cookiesAcceptedKey);

const getSupplierDirectory = (): MiniSiteType => store.get(supplierDirectoryKey);
const setSupplierDirectory = (supplierDirectory: MiniSiteType): void => store.set(supplierDirectoryKey, supplierDirectory);

export {
    getBrowserId,
    setBrowserId,
    createBrowserId,
    getCookiesAccepted,
    setCookiesAccepted,
    removeCookiesAccepted,
    getSupplierDirectory,
    setSupplierDirectory,
};
