import { PlanType } from "@/FlexPlan/Types/Plan";
import { ParticipantContactRelationship } from "@/FlexPlan/Types/Participant";
import { ParticipantProfileType } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Types";

export const convertAPIPlanTypeEnumToClientSideEnum = (value: string): PlanType => {
    switch (value.toLowerCase()) {
        case "ndiamanaged":
            return PlanType.ndiaManaged;
        case "planmanaged":
            return PlanType.planManaged;
        default:
            return PlanType.selfManaged;
    }
};

export const seedParticipantRelationships = (response: ParticipantProfileType): [ParticipantContactRelationship | "", string] => {
    let standardRelationship: ParticipantContactRelationship | "";
    let otherRelationship: string = "";

    if (!response.relationshipToParticipant) {
        return [ParticipantContactRelationship.none, ""];
    }

    const isStandardRelationshipToParticipant: boolean = !!response.relationshipToParticipant && Object.keys(ParticipantContactRelationship)
        .map(x => ParticipantContactRelationship[x])
        .includes(response.relationshipToParticipant);

    if (isStandardRelationshipToParticipant) {
        standardRelationship = response.relationshipToParticipant;
    } else {
        standardRelationship = ParticipantContactRelationship.other;
        otherRelationship = response.relationshipToParticipant;
    }

    return [standardRelationship, otherRelationship];
};
