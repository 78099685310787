import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal as BootStrapModal, ModalBody } from "reactstrap";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner/index";
import CloseButton from "@/Components/CloseButton";

const Modal = props => (
    <BootStrapModal
        isOpen={props.isOpen}
        centered
        size={props.size}
        modalClassName={props.modalClassName}
        contentClassName={props.className}
        wrapClassName={props.wrapClassName}
        backdrop
        toggle={props.toggle}
    >
        <ModalBody className="m-2 m-sm-3 p-3 p-sm-4">
            {props.closeButton && <CloseButton onClick={props.toggle} />}
            <div className={classnames((props.objectTitle || props.title) && styles.modalTitle, "d-flex align-items-center")}>
                {props.showSpinner && <Spinner className="mr-3" />}
                {props.objectTitle ? props.objectTitle : props.title && <h3 className="m-0">{props.title}</h3>}
            </div>
            {props.children}
        </ModalBody>
    </BootStrapModal>
);

Modal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    objectTitle: PropTypes.node,
    showSpinner: PropTypes.bool,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
    wrapClassName: PropTypes.string,
    size: PropTypes.string,
    closeButton: PropTypes.bool,
};

Modal.defaultProps = {
    title: "",
    showSpinner: false,
    size: "lg",
};

export default Modal;
