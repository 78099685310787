import { useEffect, useState } from "react";

import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { getFiltersFromEnum } from "@/FlexPlan/Utils/enum";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { FlexPlanOrder } from "@/FlexPlan/Pages/Orders/OrderManagement/Types";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";

const useSupplierOrders = () => {
    const {
        onSort,
        sortColumn,
        sortDescending,
        setItemsForPagination,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
        onSearch,
        onChangeFilter,
        filters,
        initialItems,
    } = useClientSidePagination<FlexPlanOrder>(
        ["participantName", "supplierBusinessName"],
        {
            field: "orderStatus",
            filters: getFiltersFromEnum(FlexPlanOrderStatus),
        },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const { put: updatePaymentStatus } = useAPI({ handle500WithToastMessage: true });
    const [updatingPaymentStatuses, setUpdatingPaymentStatuses] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [refreshOrders, setRefreshOrders] = useState<boolean>(false);
    const refreshOrdersToggle = () => setRefreshOrders(prev => !prev);

    // Get Supplier orders list for the table
    useEffect(() => {
        get<FlexPlanOrder[]>(FlexPlanUrls.suppliers.supplierOrders.base)
            .then(setItemsForPagination)
            .catch(error => setError(error));
    }, [refreshOrders]);

    const getTailwindStatusColour = (status: FlexPlanOrderStatus) => {
        switch (status) {
            case FlexPlanOrderStatus.New:
                return "tw-text-violet-600";
            case FlexPlanOrderStatus.Hold:
                return "tw-text-red-600";
            case FlexPlanOrderStatus.InProgress:
                return "tw-text-sky-600";
            case FlexPlanOrderStatus.Approved:
                return "tw-text-emerald-600";
            case FlexPlanOrderStatus.PayFileCreated:
                return "tw-text-pink-300";
            case FlexPlanOrderStatus.PaidFull:
                return "tw-text-green-400";
            case FlexPlanOrderStatus.PaidPartial:
                return "tw-text-yellow-500";
            case FlexPlanOrderStatus.Invalid:
                return "tw-text-red-600";
            default:
                return "";
        }
    };

    const [checkedRows, setCheckedRows] = useState<string[]>([]);

    const onCheckboxChange = (rowId: string) => (checked: boolean) => {
        if (checked) {
            setCheckedRows(prev => [...prev, rowId]);
        } else {
            setCheckedRows(prev => [...prev.filter(x => x !== rowId)]);
        }
    };

    const onGetPaymentStatusForOrders = () => {
        const allOrderItemIds = (paginatedItems ?? []).reduce((prev, current) => [...prev, ...current.orderItemIds], [] as string[]);
        setUpdatingPaymentStatuses(() => true);
        Promise.all(allOrderItemIds.map(orderItemId => (updatePaymentStatus(FlexPlanUrls.orders.updatePaymentStatus(orderItemId), {}))))
            .then(() => {
                get<FlexPlanOrder[]>(FlexPlanUrls.suppliers.supplierOrders.base)
                    .then((response) => {
                        setItemsForPagination(response);
                        setSuccessMessage("Payment Request Statuses updated.", true);
                        setUpdatingPaymentStatuses(() => false);
                    })
                    .catch(error => setError(error));
            })
            .catch(error => {
                if (error.validationFailed) {
                    setPopupErrorMessage(Object.keys(error.errors).map(x => error.errors[x]).join(", "), true);
                    setUpdatingPaymentStatuses(false);
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                    setUpdatingPaymentStatuses(() => false);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                    setUpdatingPaymentStatuses(() => false);
                }
            });
    };

    return {
        onSort,
        sortColumn,
        sortDescending,
        loading,
        error,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
        onSearch,
        onChangeFilter,
        filters,
        getTailwindStatusColour,
        onCheckboxChange,
        checkedRows,
        initialItems,
        refreshOrders: refreshOrdersToggle,
        onGetPaymentStatusForOrders,
        updatingOrderPaymentStatuses: updatingPaymentStatuses,
    };
};

export { useSupplierOrders };
