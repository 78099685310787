/* eslint-disable import/extensions */
import React, { useState } from "react";

import ReferAFriendBackground from "@/Assets/Images/PostPurchase/refer-a-friend.png";
import ReferAFriendBackgroundMobile from "@/Assets/Images/PostPurchase/mobile/refer-a-friend.png";
import PackageCodeIcon from "@/Assets/Icons/Coloured/package-code.svg";
import BuildPackageIcon from "@/Assets/Icons/Coloured/build-package.svg";
import MessagingIcon from "@/Assets/Icons/Coloured/referral.svg";
import PostPurchaseLinkTab from "@/Components/PostPurchase/PostPurchaseLinkTab";
import PostPurchaseStep from "@/Components/PostPurchase/PostPurchaseStep";
import * as ReferralApi from "@/Apis/Referrals";
import ReferralLinkModal from "@/Modals/ReferralLinkModal";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import StepBuilderBanner from "@/Pages/PostPurchase/StepBuilderBanner";

const ReferAFriend = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [referralLink, setReferralLink] = useState("Generating..");
    const { setSuccessMessage } = useToastMessageContext();

    const createReferral = async () => {
        const { link } = await ReferralApi.createReferral();
        setReferralLink(link);
    };

    const toggleModal = async () => {
        if (!modalOpen) {
            await createReferral();
        }

        setModalOpen(prevState => !prevState);
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(referralLink);
        setSuccessMessage("Referral Link Copied!", true);
    };

    return (
        <StepBuilderBanner
            title="Invite your friends to join the club and earn something back!"
            stepBuilders={
                <>
                    <PostPurchaseStep
                        title="1 Create and invite"
                        icon={<BuildPackageIcon />}
                        text="Create a referral link from the “Invite New Members” widget on your Homepage and share this with your friends
                        to invite them on Business Support Club."
                    />
                    <PostPurchaseStep
                        title="2 Join and purchase"
                        icon={<PackageCodeIcon />}
                        text="Have your friends join Business Support Club through your link where they can purchase high-quality
                    products and services from thousands of our suppliers."
                    />
                    <PostPurchaseStep
                        title="3 Earn from purchases"
                        icon={<MessagingIcon />}
                        text="Guaranteed lifetime earnings from all of your referral’s BSC purchases."
                    />
                </>
            }
            mobileImagePath={ReferAFriendBackgroundMobile}
            desktopImagePath={ReferAFriendBackground}
            childrenClassName="tw-justify-center tw-flex tw-flex-col tw-py-5 lg:tw-py-0"
            imageFirst
            postPurchaseLinkTab={<PostPurchaseLinkTab
                onClick={toggleModal}
                text="Create a Referral Link"
                className="tw-absolute tw-right-0 tw-bottom-0 lg:tw-rounded-br-lg"
                iconHeading="h2"
            />}
        >
            <div
                className="tw-pt-3 tw-mx-auto md:tw-mx-0 tw-flex tw-flex-col tw-space-y-2 tw-px-6"
            >
                <div className="tw-text-brand-accent-yellow tw-text-lg lg:tw-text-2xl">Guaranteed lifetime earnings from your
                    referral’s BSC purchases
                </div>
                <div className="tw-text-white tw-text-sm lg:tw-text-base">
                    If a user signs up using your referral link, you will{" "}
                    <span className="tw-text-brand-accent-yellow tw-text-sm lg:tw-text-base">earn 3% commission</span>{" "}
                    on all their BSC purchases for life.
                </div>
            </div>
            <div
                className="tw-w-full tw-px-8 tw-pb-5 md:tw-pb-0 !tw-py-4"
            >
                <div className="tw-flex tw-flex-row tw-space-x-3 tw-items-center">
                    <div className="tw-w-1/3 tw-flex tw-justify-end">
                        <div
                            className="tw-w-16 tw-h-16 sm:tw-w-24 sm:tw-h-24 lg:tw-w-32 lg:tw-h-32 tw-rounded-full tw-rotate-90"
                            style={{ backgroundImage: "conic-gradient(white 95%, #fccf3c 5%, #fccf3c)" }}
                        />
                    </div>
                    <hr
                        className="tw-w-24 tw-h-[1px] tw-bg-white -tw-mt-1"
                    />
                    <i className="fas fa-smile-beam tw-text-brand-accent-yellow fa-2x -tw-mt-2" />
                    <div className="tw-text-white tw-text-sm lg:tw-text-base">
                        You’re guaranteed to earn this cut from all of your referral’s BSC purchases…{" "}
                        <span className="tw-text-brand-accent-yellow tw-block tw-text-sm lg:tw-text-base">FOR LIFE</span>
                    </div>
                </div>
                <ReferralLinkModal
                    version="v2"
                    isOpen={modalOpen}
                    toggle={toggleModal}
                    referralLink={referralLink}
                    createReferral={createReferral}
                    copyToClipboard={copyToClipboard}
                />
            </div>
        </StepBuilderBanner>
    );
};

export default ReferAFriend;
