/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { push } from "connected-react-router";
import { withRouter } from "react-router";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";

import CustomerNav from "./customer";
import SupplierNav from "./supplier";
import { SubMenu } from "./SubMenu";
import styles from "./styles.module.scss";

import Logo from "@/Assets/Icons/Solid/logo.svg";
import { features } from "@/Utils/features";
import { navSideBarTypes } from "@/Store/Global/constants";
import actions from "@/Store/User/actions";

export class NavigationSideBar extends React.Component {
    state = {
        secondLevel: false,
    };

    adminMenu = {
        Manage: {
            icon: "tasks",
            path: "manage",
            subMenu: {
                Users: { icon: "server", pushTo: "/manage/users", hidden: !this.props.isDeveloper || !features.isEnabled(features.userManagement) },
                Categories: { icon: "server", pushTo: "/manage/categories", hidden: !this.props.isAdmin },
            },
        },
    };

    menu = {
        Home: {
            icon: "home",
            path: "/dashboard",
            pushTo: "/dashboard",
        },
        "My Profile": {
            icon: "user",
            path: "profile",
            subMenu: {
                "My Information": { icon: "user-edit", pushTo: "/profile/info" },
                "My Account": { icon: "user-cog", pushTo: "/profile/account" },
                "My Referrals": { icon: "envelope-open-text", pushTo: "/profile/referrals" },
                "My Documents": { icon: "file", pushTo: "/profile/documents" },
            },
        },
        Marketplace: {
            icon: "store",
            path: "marketplace",
            subMenu: {
                Marketplace: { icon: "search", pushTo: "/marketplace" },
                "Supplier Directory": { icon: "search", pushTo: "/marketplace/supplier-directory" },
            },
        },
        Dashboards: {
            icon: "th-large",
            subMenu: {
                "Supplier Dashboard": { icon: "box-open", supplierOnly: true, onClick: () => this.setState({ secondLevel: navSideBarTypes.supplierDashboard }) },
                "Customer Dashboard": { icon: "user-tie", onClick: () => this.setState({ secondLevel: navSideBarTypes.customerDashboard }) },
            },
        },
        Messages: { icon: "envelope", path: "messages", pushTo: "/messages", showIndicator: props => props.messageCount },
        "Order Audits": {
            icon: "clipboard-list",
            path: "admin-orders",
            pushTo: "/admin/orders",
            hidden: !this.props.isAdmin,
        },
        "Admin Tools": {
            icon: "users-cog",
            path: "admin",
            hidden: !this.props.isAdmin,
            subMenu: {
                "Tags Management": {
                    icon: "list",
                    path: "user-type-tags",
                    pushTo: "/admin/user-type-tags",
                    hidden: !this.props.isAdmin,
                },
                "Sales Management": {
                    icon: "tag",
                    path: "sales-management",
                    pushTo: "/admin/sales-management",
                    hidden: !this.props.isAdmin,
                },
                "Company Formations Upsells": {
                    icon: "landmark",
                    path: "company-formations-upsells",
                    pushTo: "/admin/company-formations-upsells",
                    hidden: !this.props.isAdmin,
                },
                "Reviews Management": {
                    icon: "star",
                    path: "reviews-management",
                    pushTo: "/admin/reviews-management",
                    hidden: !this.props.isAdmin,
                },
                "Supplier Administration": {
                    icon: "store",
                    path: "supplier-administration",
                    pushTo: "/admin/supplier-administration",
                    hidden: !this.props.isAdmin,
                },
                "RMC Management": {
                    icon: "list-alt",
                    hidden: !this.props.isAdmin,
                    path: "rmcs-management",
                    pushTo: "/admin/rmcs-management",
                },
                "Document Management": {
                    icon: "file-import",
                    hidden: !this.props.isAdmin,
                    path: "document-management",
                    pushTo: "/admin/document-management",
                },
            },
        },
        Packages: {
            icon: "box",
            path: "package",
            subMenu: {
                "Build Package": { icon: "cogs", secondIcon: "box", pushTo: "/packages" },
                "Manage Packages": { icon: "boxes", pushTo: "/packages/manage" },
                "Redeem Package": { icon: "box", secondIcon: "hand-holding", pushTo: "/packages/redeem" },
            },
        },
        "Log Out": { icon: "door-open", onClick: this.props.logout },
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.openSideBarRef && !this.openSideBarRef.contains(event.target)) {
            this.props.toggle();
        }
    };

    onMenuClick = (name, item) => () => {
        // if the on click exists, close the menu and execute the click
        if (item.onClick) {
            this.props.toggle();
            item.onClick();
        } else {
            // otherwise there is a submenu so toggle the submenu to the state to be displayed
            this.setState((prevState) => ({ subMenu: prevState.subMenu !== name && name }));
        }
    };

    renderChevron = name => (
        !this.state[name] ? <i className="fa fa-chevron-down mr-3" /> : <i className="fa fa-chevron-up mr-3" />
    );

    // check if the window location contains the path, unless its the home page which is empty so check the window location is empty
    isActive = item => (this.props.location.pathname.indexOf(item.path) > -1 && item.icon !== "home") || this.props.location.pathname === item.path;

    renderMenuItem = (name, item) => (
        <React.Fragment key={name}>
            {(item.pushTo && !item.hidden) ? (
                <Link
                    data-testid={`menu-button-${name}`}
                    onClick={this.props.toggle}
                    disabled={this.state.secondLevel}
                    className={classnames("bg-white text-dark text-decoration-none border-0 p-0 text-left d-flex align-items-center", this.isActive(item) && styles.active)}
                    to={item.pushTo}
                >
                    <div className={styles.icon}><i className={`fa fa-lg fa-${item.icon}`} /></div>
                    <span className="flex-grow-1">
                        {name}
                        {item.showIndicator && item.showIndicator(this.props) > 0 && (
                            <Badge pill className="ml-2 bg-pink">
                                {item.showIndicator(this.props) > 10 ? "10+" : item.showIndicator(this.props)}
                            </Badge>
                        )}
                    </span>
                </Link>
            ) : (
                <>
                    <button
                        type="button"
                        data-testid={`menu-button-${name}`}
                        onClick={this.onMenuClick(name, item)}
                        disabled={this.state.secondLevel}
                        className={classnames("bg-white border-0 p-0 text-left d-flex align-items-center", this.isActive(item) && styles.active)}
                    >
                        <div className={styles.icon}><i className={`fa fa-lg fa-${item.icon}`} /></div>
                        <span className="flex-grow-1">
                            {name}
                            {item.showIndicator && item.showIndicator(this.props) > 0 && (
                                <Badge pill className="ml-2 bg-pink">
                                    {item.showIndicator(this.props) > 10 ? "10+" : item.showIndicator(this.props)}
                                </Badge>
                            )}
                        </span>
                        {!item.onClick && this.renderChevron(name)}
                    </button>
                    {item.subMenu && (
                        <SubMenu
                            disabled={!!this.state.secondLevel}
                            openMenu={this.state.subMenu || ""}
                            menuName={name}
                            subMenuItems={item.subMenu}
                            toggleMenu={this.props.toggle}
                        />
                    )}
                </>
            )}

        </React.Fragment>
    );

    renderSecondLevelNav = () => {
        if (!this.props.isOpen) {
            return false;
        }
        switch (this.state.secondLevel) {
            case navSideBarTypes.customerDashboard:
                return (
                    <div ref={(x) => { this.openSideBarRef = x; /* using this DOM element to detect if the click is outside or not */ }}>
                        <CustomerNav
                            toggle={this.props.toggle}
                            closeSecondLevel={() => this.setState({ secondLevel: undefined })}
                            push={(path) => {
                                this.props.toggle();
                                this.props.push(path);
                            }}
                        />
                    </div>
                );
            case navSideBarTypes.supplierDashboard:
                return (
                    <div ref={(x) => { this.openSideBarRef = x; /* using this DOM element to detect if the click is outside or not */ }}>
                        <SupplierNav
                            toggle={this.props.toggle}
                            closeSecondLevel={() => this.setState({ secondLevel: undefined })}
                            push={(path) => {
                                this.props.toggle();
                                this.props.push({
                                    pathname: path,
                                    state: {
                                        from: this.props.location.pathname,
                                    },
                                });
                            }}
                        />
                    </div>
                );
            default:
                return false;
        }
    };

    renderCollapsedNav = () => {
        switch (this.state.secondLevel) {
            case navSideBarTypes.customerDashboard:
                return (
                    <>
                        <div className={classnames(styles.backButton, "bg-dark text-white w-100 mb-3")}>
                            <i className="fa fa-arrow-circle-left" />
                        </div>

                        {["dolly-flatbed", "list", "star", "question"].map(x => (
                            <div key={x} className="mx-3 my-2 py-1 d-inline-block">
                                <i className={`fa fa-lg fa-${x}`} />
                            </div>
                        ))}
                    </>
                );
            case navSideBarTypes.supplierDashboard:
                return (
                    <>
                        <div className={classnames(styles.backButton, "bg-dark text-white w-100 mb-3")}>
                            <i className="fa fa-arrow-circle-left my-1" />
                        </div>
                        {["globe", "file", "file-invoice", "dolly-flatbed", "list", "columns", "question"].map(x => (
                            <div key={x} className="mx-3 my-2 py-1 d-inline-block">
                                <i className={`fa fa-lg fa-${x}`} />
                            </div>
                        ))}
                    </>
                );
            default:
                return (
                    Object.keys(this.menu).filter(x => this.menu[x].hidden !== true).map(x => (
                        <div key={x} className={classnames("mx-3 my-2 py-1 d-inline-block", this.isActive(this.menu[x]) && styles.active)}>
                            <i className={`fa fa-lg fa-${this.menu[x].icon}`} />
                        </div>
                    ))
                );
        }
    };

    render() {
        if (this.props.isError || this.props.location.pathname === "/error") {
            return (
                <div className={classnames(styles.container, "h-100 bg-white border-0 p-0 d-none d-md-block")}>
                    <div className={classnames(styles.logoContainer, "my-4 mx-3")}>
                        <Logo />
                    </div>
                </div>
            );
        }

        return (
            <>
                <button
                    type="button"
                    data-testid="collapsed-nav"
                    onClick={() => this.props.toggle(true)}
                    className={classnames(styles.container, "h-100 bg-white border-0 p-0 d-none d-lg-block")}
                >
                    <div className="h-100 d-flex flex-column">
                        <div className={classnames(styles.logoContainer, "my-4 mx-3")}>
                            <Logo />
                        </div>
                        <div className="flex-grow-1">
                            {this.renderCollapsedNav()}
                        </div>

                        {!this.state.secondLevel
                        && (this.props.isAdmin || this.props.isDeveloper)
                        && Object.keys(this.adminMenu).map(x => (
                            <div key={x} className={classnames("mx-3 my-2 py-1", this.props.location.pathname.indexOf(this.adminMenu[x].path) > -1 && styles.active)}>
                                <i className={`fa fa-lg fa-${this.adminMenu[x].icon}`} />
                            </div>
                        ))}
                    </div>
                </button>

                {this.props.isOpen && (
                    <div
                        className={classnames(styles.containerExpanded, "bg-white")}
                        ref={(x) => { this.openSideBarRef = x; /* using this DOM element to detect if the click is outside or not */ }}
                    >
                        <div className="d-flex flex-column h-100 overflow-y-auto">
                            <button
                                type="button"
                                onClick={() => this.props.toggle(false)}
                                className={classnames(styles.logoContainer, "my-4 mx-3 bg-white border-0 p-0 text-left")}
                                data-testid="toggleNav"
                            >
                                <Logo />
                            </button>
                            <div className="d-flex flex-column flex-grow-1">
                                {/* Loop over menu object and render each menu item */}
                                {Object.keys(this.menu).filter(x => this.menu[x].hidden !== true).map(x => this.renderMenuItem(x, this.menu[x]))}
                            </div>

                            <div className="d-flex flex-column mt-5">
                                {/* if the user is an admin, loop over the admin menu object and render each menu item */}
                                {this.props.isAdmin && Object.keys(this.adminMenu).map(x => this.renderMenuItem(x, this.adminMenu[x]))}
                            </div>
                        </div>
                    </div>
                )}

                {this.renderSecondLevelNav()}
            </>
        );
    }
}

NavigationSideBar.propTypes = {
    push: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    // need to disable this here as the prop is retrieved using the menu object
    messageCount: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    isAdmin: PropTypes.bool.isRequired,
    isDeveloper: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    messageCount: state.notifications.unreadMessages,
    isAdmin: state.user.isAdmin,
    isDeveloper: state.user.isDeveloper,
    isError: !!state.global.error,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(actions.logout$()),
    push: path => dispatch(push(path)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationSideBar));
