import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import queryString from "query-string";

import { LoginForm } from "@/Components/LoginForm";
import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { features } from "@/Utils/features";
import { useAuthentication } from "@/Hooks/useAuthentication";

const LoginPage = ({ title = "BSC" }: { title?: string }) => {
    document.title = features.isEnabled(features.FlexPlan) ? title : `${title} - Login`;

    const location = useLocation();
    const dispatch = useDispatch();
    const toastMessageContext = useToastMessageContext();

    useEffect(() => {
        if (location.state && !location.state.from) {
            toastMessageContext.setPopupErrorMessage(location.state, true);
        }
    }, [location.state]);

    const query = queryString.parse(location.search);

    const {
        onLoginSuccess: onSuccess,
    } = useAuthentication();

    const onNavigateToSignUp = () => {
        if (query.returnUrl) {
            dispatch(push(`/sign-up?returnUrl=${query.returnUrl}`));
        } else {
            dispatch(push("/sign-up"));
        }
    };

    return (
        <LoginBackgroundWrapper>
            <LoginForm onSuccess={onSuccess} onNavigateToSignUp={onNavigateToSignUp} />
        </LoginBackgroundWrapper>
    );
};

export { LoginPage };
