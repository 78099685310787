import React from "react";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { Route } from "react-router-dom";
import { push } from "connected-react-router";
import { useHistory } from "react-router";

import { MiniSiteEditorSideBar } from "./MiniSiteEditorSideBar";
import { Presentation } from "./presentation";
import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import { SupplierProvider } from "@/Context/SupplierContext";
import { ServiceModal } from "@/Modals/ServiceModal";
import { EditEnquiryFormModal } from "@/Modals/EditEnquiryFormModal";
import { PackageSpecificsModal } from "@/Modals/PackageSpecificsModal";
import { ProductVariants } from "@/Utils/ProductVariants";
import { useMinisiteContext } from "@/Context/MinisiteContext";

const MiniSiteEditorPage = () => {
    document.title = "BSC - Edit Minisite";
    const dispatch = useDispatch();
    const onPush = path => () => dispatch(push(path));
    const {
        miniSite,
        loadMiniSite,
        updateProduct,
        deleteProduct,
        loading,
        apiError,
        validationErrors,
        updatePackage,
    } = useMinisiteContext();

    const history = useHistory();

    const onProductChange = (product) => {
        updateProduct(product);
        history.push("/mini-site");
    };

    const onPackageChange = (packageService) => {
        updatePackage(packageService);
        history.push("/mini-site");
    };

    const onProductDelete = (id) => {
        deleteProduct(id);
        history.push("/mini-site");
    };

    const openAddServiceModal = () => {
        history.push("/mini-site/service/new/general");
    };

    const openEditServiceModal = (service) => {
        history.push(`/mini-site/service/${service.id}/general`);
    };

    const openEditPackageModal = (packageService) => {
        history.push(`/mini-site/package/${packageService.id}/general`);
    };

    const openAddEnquiryFormModal = () => {
        history.push("/mini-site/enquiryforms/new/general");
    };

    const openEditEnquiryFormModal = (enquiryForm) => {
        history.push(`/mini-site/enquiryforms/${enquiryForm.id}/general`);
    };

    const renderErrorBanner = displayError => (
        <Row>
            <Col className="bg-danger py-3 font-weight-bold text-center text-white">
                {displayError}
            </Col>
        </Row>
    );

    const renderEditEnquiryFormModal = () => {
        if (loading) {
            return false;
        }

        return (
            <Route
                path="/mini-site/enquiryforms/:enquiryFormId"
                render={routerProps => {
                    const selectedEnquiry = miniSite.services.find(x => x.id === routerProps.match.params.enquiryFormId) || {
                        id: uuid(),
                        startingPrice: 0,
                        isOffsitePayment: false,
                    };
                    return (
                        <SupplierProvider
                            value={{
                                onCustomFieldAdd: (f) => {
                                    loadMiniSite({
                                        ...miniSite,
                                        metaData: {
                                            ...({ ...miniSite.metaData }),
                                            fields: [...miniSite.metaData.fields, f],
                                        },
                                    });
                                },
                            }}
                        >
                            <EditEnquiryFormModal
                                {...routerProps}
                                metaData={miniSite.metaData}
                                selectedEnquiryForm={{
                                    serviceFields: [],
                                    ...selectedEnquiry,
                                }}
                                addEnquiry={onProductChange}
                                deleteEnquiry={onProductDelete}
                            />
                        </SupplierProvider>
                    );
                }}
            />
        );
    };

    const renderAddServiceModal = () => {
        if (loading) {
            return false;
        }

        return (
            <Route
                path="/mini-site/service/:serviceId"
                render={routerProps => {
                    const selectedService = miniSite.services.find(x => x.id === routerProps.match.params.serviceId) || {
                        id: uuid(),
                        initialCharge: 0,
                        postageCharge: 0,
                        upsellItems: [],
                        productVariant: ProductVariants.singleProduct,
                    };
                    return (
                        <SupplierProvider
                            value={{
                                onCustomFieldAdd: (f) => {
                                    loadMiniSite({
                                        ...miniSite,
                                        metaData: {
                                            ...({ ...miniSite.metaData }),
                                            fields: [...miniSite.metaData.fields, f],
                                        },
                                    });
                                },
                            }}
                        >
                            <ServiceModal
                                {...routerProps}
                                metaData={miniSite.metaData}
                                selectedService={{
                                    serviceFields: [],
                                    ...selectedService,
                                }}
                                addService={onProductChange}
                                deleteService={onProductDelete}
                            />
                        </SupplierProvider>
                    );
                }}
            />
        );
    };

    const renderEditPackageFormModal = () => {
        if (loading) {
            return false;
        }

        return (
            <Route
                path="/mini-site/package/:packageId"
                render={routerProps => {
                    const selectedPackage = miniSite.services.find(x => x.id === routerProps.match.params.packageId) || {
                        id: uuid(),
                        startingPrice: 0,
                    };
                    return (
                        <SupplierProvider
                            value={{
                                onCustomFieldAdd: (f) => {
                                    loadMiniSite({
                                        ...miniSite,
                                        metaData: {
                                            ...({ ...miniSite.metaData }),
                                            fields: [...miniSite.metaData.fields, f],
                                        },
                                    });
                                },
                            }}
                        >
                            <PackageSpecificsModal
                                onClose={onPush("/mini-site")}
                                onAddProducts={onPackageChange}
                                isSupplier
                                selectedPackage={{
                                    packageFields: [],
                                    ...selectedPackage,
                                }}
                            />
                        </SupplierProvider>
                    );
                }}
            />
        );
    };

    return (
        <>
            {apiError && renderErrorBanner("Unfortunately, an error has occurred. Copy any text entered just in case there are save issues then refresh this page.")}
            {Object.keys(validationErrors).length > 0 && renderErrorBanner(Object.values(validationErrors).join(" "))}

            <div className="d-flex flex-grow-1 position-relative">
                <MiniSiteEditorSideBar />
                {loading ? (
                    <div className={classnames(styles.background, "d-flex flex-grow-1 flex-column p-0 justify-content-center")}>
                        <Spinner size="40" className="align-self-center" />
                    </div>
                ) : (
                    <Presentation
                        onAddService={openAddServiceModal}
                        onEditService={openEditServiceModal}
                        onEditPackage={openEditPackageModal}
                        onAddEnquiry={openAddEnquiryFormModal}
                        onEditEnquiry={openEditEnquiryFormModal}
                    />
                )}
            </div>
            {renderAddServiceModal()}
            {renderEditEnquiryFormModal()}
            {renderEditPackageFormModal()}
        </>
    );
};

export { MiniSiteEditorPage };
