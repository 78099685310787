import { FieldType } from "@/Apis/Suppliers/MiniSiteType";

// A simple helper to apply obvious sorting when listing out fields
const fieldSortOrder: string[] = [
    "Title",
    "First Name",
    "Middle Name",
    "Surname",
    "Date of Birth",
    "Email Address",
    "Mobile Number",
    "Address",
    "Address Country",
    "Nationality",
    "Country of Residence",
    "Shares",
    "Right to Appoint and Remove Directors",
    "Person of Significant Control",
    "Company Title",
    "Company Name",
    "Company Registered Office Address",
    "Company Country Of Registration",
    "Office Address Country",
    "SIC Code",
    "Security Question 1",
    "Security Question 1 Answer",
    "Security Question 2",
    "Security Question 2 Answer",
    "Security Question 3",
    "Security Question 3 Answer",
    "Former First Name",
    "Former Surname",
    "Previous Address",
    "Previous Address Country",
];

export const customSort = (x: FieldType, y: FieldType) => {
    const sortOrderObj = {};
    fieldSortOrder.forEach((item, index) => {
        sortOrderObj[item] = index + 1; // Have to add 1 otherwise 0 index will return false in below conditionals
    });

    const xSortValue = sortOrderObj[x.displayText];
    const ySortValue = sortOrderObj[y.displayText];

    if ((xSortValue && !ySortValue) || (xSortValue < ySortValue)) {
        return -1;
    }

    if ((!xSortValue && ySortValue) || (xSortValue > ySortValue)) {
        return 1;
    }

    return 0;
};
