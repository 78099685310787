import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";

import rootReducer from "./rootReducer";
import rootEpic from "./rootEpic";

const configureStore = (history) => {
    const epicMiddleware = createEpicMiddleware();
    const middleware = [
        thunk,
        epicMiddleware,
        routerMiddleware(history),
    ];

    const composedEnhancers = compose(composeWithDevTools(applyMiddleware(...middleware)));

    const store = createStore(
        rootReducer(history),
        {},
        composedEnhancers,
    );
    epicMiddleware.run(rootEpic);
    return store;
};

export default configureStore;
