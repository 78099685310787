import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { PlanManagerProfile } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface PlanManagerContextState {
    companyName: string,
    loading: boolean,
}

export const initialState: PlanManagerContextState = {
    companyName: "",
    loading: false,
};

const PlanManagerContext = React.createContext<PlanManagerContextState>(initialState);

export const usePlanManagerContext = () => useContext(PlanManagerContext);

interface Props {
    children: React.ReactNode,
}

export const PlanManagerProvider = ({ children }: Props) => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage } = useToastMessageContext();
    const [state, setState] = useState<PlanManagerContextState>(initialState);
    const loggedIn = useSelector<any>(state => state.user.isLoggedIn);

    useEffect(() => {
        if (!loggedIn) return;

        get<PlanManagerProfile>(FlexPlanUrls.planManager.get)
            .then(response => {
                setState({
                    companyName: response.companyName,
                    loading: false,
                });
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    }, [loggedIn]);

    return (
        <PlanManagerContext.Provider value={{
            ...state,
            loading,
        }}
        >
            {children}
        </PlanManagerContext.Provider>
    );
};
