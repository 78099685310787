import React from "react";
import { FormGroup, Label } from "reactstrap";

interface Props {
    label: string,
    value: any
}

// This is displayed when the profile section is uneditable
const ReadonlyProfileField = ({ label, value }: Props) => (
    <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
        <Label className="tw-min-w-[25%]">{label}</Label>
        <Label className="font-weight-bold">{value}</Label>
    </FormGroup>
);

export default ReadonlyProfileField;
