import React from "react";
import classnames from "classnames";
import { Alert } from "reactstrap";

import styles from "./styles.module.scss";

interface Props {
    message: string,
}

const SuccessToastMessage = ({ message }: Props): JSX.Element => (
    <div className={classnames(styles.container, "w-100 d-flex justify-content-center")}>
        <Alert className={classnames(styles.toast, "text-primary border-0 position-absolute d-flex align-items-center")} isOpen>
            <i className="fa fa-check-circle mt-1" />
            <span>{message}</span>
        </Alert>
    </div>
);

export { SuccessToastMessage };
