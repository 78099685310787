import { useEffect, useState } from "react";

import sortingUtil from "@/Utils/sortingUtil";
import { Filter } from "@/FlexPlan/Types/Pagination";

interface FilterObj {
    filters: Filter[],
    field: string, // Which field are we filtering on
}

const useClientSidePagination = <TModel>(searchFields?: (keyof TModel)[], searchFilters?: FilterObj, pageSize: number = 10) => {
    // Pagination
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSizeState, setPageSizeState] = useState<number>(pageSize);
    const onPageSelected = (pageNumberSelected: number) => {
        setPageNumber(pageNumberSelected);
    };

    // Sorting
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortDescending, setSortDescending] = useState<boolean>(false);
    const toggleSortDescending = () => setSortDescending(sortDescending => !sortDescending);
    const onSort = (sort: string) => {
        if (!sort) return;

        if (sortColumn === sort) {
            toggleSortDescending();
        }

        setSortColumn(sort);
    };

    // Filtering
    const [filters, setFilters] = useState<Filter[] | undefined>(searchFilters?.filters);

    // Searching
    const [searchTerm, setSearchTerm] = useState<string>(); // Individual search across multiple fields
    const [searchObj, setSearchObj] = useState<TModel>(); // Multiple searches, each search for one field
    const onSearch = (value: string) => {
        setSearchTerm(value);
        setPageNumber(0);
    };

    const onSearchObj = (value: TModel) => {
        setSearchObj(value);
    };

    // Initial items loaded from API
    const [initialItems, setItemsForPagination] = useState<TModel[]>();
    // This would be the 10 displaying on the page
    const [paginatedItems, setPaginatedItems] = useState<TModel[]>();
    // Total number of pages we need to display in the pagination icon
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectAll, setSelectAll] = useState(false);
    const [syncPaginatedItems, setSyncPaginatedItems] = useState<boolean>(false);
    const togglePaginatedItems = () => setSyncPaginatedItems(prev => !prev);

    useEffect(() => {
        if (!initialItems || initialItems?.length === 0) {
            setPaginatedItems([]);
            return;
        }

        let items: TModel[] = initialItems;

        if (sortColumn) {
            items = items.sort((x, y) => sortingUtil.sort(x, y, sortColumn, !sortDescending));
        }

        if (searchTerm && searchFields) {
            items = items.filter(item => searchFields
                .some(searchField => !!Object.keys(item as {}).find(property => property === searchField)
                    && typeof item[searchField] === "string"
                    // @ts-ignore
                    && item[searchField].toLowerCase().includes(searchTerm.toLowerCase())));
        }

        if (searchObj) {
            Object.keys(searchObj).forEach(key => {
                if (typeof searchObj[key] === "string" && searchObj[key]) {
                    items = items.filter(item => item[key]?.toLowerCase().includes(searchObj[key]));
                }
            });
        }

        if (searchFilters && filters && filters.filter(x => x.checked).length > 0) {
            items = items.filter(item => filters.filter(x => x.checked).some(x => item[searchFilters.field] === x.field));
        }

        setTotalPages(Math.ceil(items.length / pageSizeState));

        items = items?.slice(pageNumber * pageSizeState, pageNumber * pageSizeState + pageSizeState);

        setPaginatedItems(items);
    }, [JSON.stringify(initialItems), sortColumn, sortDescending, pageNumber, pageSizeState, searchTerm, filters, searchObj, syncPaginatedItems]);

    const onChangeFilter = (filterName: string) => (checked: boolean) => {
        if (!filters || !searchFilters) return;

        setFilters(prev => {
            if (!prev) return prev;

            const position = prev.find(x => x.field === filterName)?.position;

            if (!position) return prev;

            return [
                ...prev.filter(x => x.field !== filterName),
                { field: filterName, checked, position },
            ];
        });

        setPageNumber(0);
    };

    const onCheckItem = (itemId: string, idPropName: keyof TModel, checkedPropName: keyof TModel) => (checked: any) => {
        setItemsForPagination((prev) => {
            if (!prev) {
                return prev;
            }

            const items = prev;

            const itemIndex = items.findIndex(x => String(x[idPropName]) === itemId);

            items[itemIndex][checkedPropName] = checked;

            return items;
        });
        togglePaginatedItems();
    };

    const onSelectAll = (checkedPropName: keyof TModel) => (checked: any) => {
        setSelectAll(prev => !prev);
        setItemsForPagination((prev) => prev?.map(item => ({ ...item, [checkedPropName]: checked })));
    };

    const onRemoveItem = (itemId: string, idPropName: keyof TModel) => () => {
        setItemsForPagination(prev => prev?.filter(x => x[idPropName as string] !== itemId));
    };

    return {
        pageNumber,
        pageSize: pageSizeState,
        onPageSelected,
        setItemsForPagination,
        onSort,
        setPageSize: setPageSizeState,
        paginatedItems,
        onSearch,
        searchTerm,
        sortColumn,
        sortDescending,
        totalPages,
        onChangeFilter,
        filters,
        initialItems,
        onSearchObj,
        onCheckItem,
        onSelectAll,
        selectAll,
        onRemoveItem,
    };
};

export default useClientSidePagination;
