import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import animations from "./animations.module.scss";

const SubMenu = ({ menuName, subMenuItems, openMenu, disabled, toggleMenu }) => {
    const isSupplier = useSelector(state => state.user.isSupplier);

    const subItems = Object.keys(subMenuItems).map((xx) => {
        const subItem = subMenuItems[xx];
        if ((!isSupplier && subItem.supplierOnly) || subItem.hidden) {
            return false;
        }
        return { name: xx, ...subItem };
    }).filter(Boolean);

    const lengthClass = `subNavSlide${subItems.length}Items`;
    return (
        <CSSTransition
            in={openMenu === menuName}
            timeout={300}
            classNames={{
                enter: `${animations["subNavSlide-enter"]} ${animations[`${lengthClass}-enter`]}`,
                enterActive: `${animations["subNavSlide-enter-active"]} ${animations[`${lengthClass}-enter-active`]}`,
                enterDone: `${animations["subNavSlide-enter-done"]} ${animations[`${lengthClass}-enter-done`]}`,
                exit: `${animations["subNavSlide-exit"]} ${animations[`${lengthClass}-exit`]}`,
                exitActive: `${animations["subNavSlide-exit-active"]} ${animations[`${lengthClass}-exit-active`]}`,
            }}
            unmountOnExit
        >
            <div className={styles.subMenu}>
                {subItems.map((subItem) => {
                    if (subItem.pushTo) {
                        return (
                            <Link
                                key={subItem.name}
                                data-testid={`submenu-button-${subItem.name}`}
                                onClick={toggleMenu}
                                disabled={disabled}
                                className={classnames(styles.subMenuButton, "mx-3 pl-3 pr-0 bg-white text-left d-flex align-items-center text-dark text-decoration-none")}
                                to={subItem.pushTo}
                            >
                                <div className={classnames(styles.icon, subItem.secondIcon && styles.stacked)}>
                                    <i className={`fa fa-lg fa-${subItem.icon}`} />
                                    {subItem.secondIcon && <i className={`fa fa-lg fa-${subItem.secondIcon}`} />}
                                </div>
                                <span className="flex-grow-1">{subItem.name}</span>
                                <i className="fa fa-chevron-right mr-3" />
                            </Link>
                        );
                    }
                    return (
                        <button
                            key={subItem.name}
                            type="button"
                            data-testid={`submenu-button-${subItem.name}`}
                            onClick={subItem.onClick}
                            disabled={disabled}
                            className={classnames(styles.subMenuButton, "mx-3 pl-3 pr-0 bg-white text-left d-flex align-items-center")}
                        >
                            <div className={classnames(styles.icon, subItem.secondIcon && styles.stacked)}>
                                <i className={`fa fa-lg fa-${subItem.icon}`} />
                                {subItem.secondIcon && <i className={`fa fa-lg fa-${subItem.secondIcon}`} />}
                            </div>
                            <span className="flex-grow-1">{subItem.name}</span>
                            <i className="fa fa-chevron-right mr-3" />
                        </button>
                    );
                })}
            </div>
        </CSSTransition>
    );
};

SubMenu.propTypes = {
    openMenu: PropTypes.string,
    menuName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    subMenuItems: PropTypes.shape().isRequired,
};

export { SubMenu };
