import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Button, Label } from "reactstrap";
import classnames from "classnames";
import { v4 as uuid } from "uuid";

import { CategorySelect } from "./CategorySelect";
import { Row } from "./Row";
import styles from "./styles.module.scss";

import { useAPI } from "@/Apis/useAPI";
import { saveSupplierOwnedSubCategory, deleteSupplierOwnedSubCategory, getSupplierOwnedSubCategoriesUrl } from "@/Apis/Suppliers";
import { getCategoriesApi } from "@/Apis/urls";
import { OwnedSubCategoriesType, SubCategoryType } from "@/Apis/Suppliers/OwnedSubCategoriesType";
import { CategoryType } from "@/Apis/Suppliers/CategoryType";
import sortingUtil from "@/Utils/sortingUtil";
import actions from "@/Store/Global/actions";

const ManageSubCategoriesPage = () => {
    document.title = "BSC - Manage Subcategories";

    const dispatch = useDispatch();
    const { loading, get } = useAPI();
    const [categories, setCategories] = useState<Array<CategoryType>>([]);
    const [ownedSubCategories, setOwnedSubCategories] = useState<Array<SubCategoryType>>([]);
    const [categoryIdFilter, setCategoryIdFilter] = useState<string>("");
    const fixedHeaderRef = useRef<HTMLTableElement>(null);

    const onApiError = () => {
        dispatch(actions.setErrorToastMessage(true, "Sorry something went wrong, please refresh the page and try again"));
    };

    useEffect(() => {
        get<OwnedSubCategoriesType>(getSupplierOwnedSubCategoriesUrl)
            .then((ownedSubCategoriesResponse) => {
                setOwnedSubCategories(ownedSubCategoriesResponse.subCategories);
                get<Array<CategoryType>>(getCategoriesApi)
                    .then((categoriesResponse) => {
                        setCategories(categoriesResponse);
                        setCategoryIdFilter(categoriesResponse.find(x => x.id === ownedSubCategoriesResponse.subCategories[0]?.parentCategoryId)?.id
                            ?? categoriesResponse[0]?.id);
                    });
            });
    }, []);

    const nextOrdinal = () => (ownedSubCategories.reduce((a, b) => (a.ordinal < b.ordinal ? a : b), {} as SubCategoryType).ordinal - 1);

    const onNewOwnedSubCategory = () => setOwnedSubCategories([{
        id: uuid(),
        ordinal: nextOrdinal(),
        name: "",
        parentCategoryId: categoryIdFilter,
        appliedTo: [],
    }, ...ownedSubCategories] as Array<SubCategoryType>);

    const onOwnedSubCategoryChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setOwnedSubCategories(prevState => {
            const updatedOwnedSubCategories = prevState.filter(x => x.id !== id);
            const itemToUpdate = prevState?.find(x => x.id === id);
            return [...updatedOwnedSubCategories, {
                ...itemToUpdate,
                name: value,
            }] as Array<SubCategoryType>;
        });
    };

    const onOwnedParentCategoryChange = (id: string) => (parentCategoryId: string) => {
        setOwnedSubCategories(prevState => {
            const updatedOwnedSubCategories = prevState.filter(x => x.id !== id);
            const itemToUpdate = prevState?.find(x => x.id === id);
            setCategoryIdFilter(parentCategoryId);
            return [...updatedOwnedSubCategories, {
                ...itemToUpdate,
                ordinal: nextOrdinal(),
                parentCategoryId,
            }] as Array<SubCategoryType>;
        });
    };

    const saveOwnedSubCategory = (id: string) => () => {
        const updatedOwnedSubCategories = ownedSubCategories.filter(x => x.id !== id);
        const itemToUpdate = ownedSubCategories?.find(x => x.id === id);

        setOwnedSubCategories([...updatedOwnedSubCategories, { ...itemToUpdate, isUpdating: true }] as Array<SubCategoryType>);
        saveSupplierOwnedSubCategory(id, itemToUpdate as SubCategoryType)
            .then(() => {
                setOwnedSubCategories([...updatedOwnedSubCategories, { ...itemToUpdate, isUpdating: false }] as Array<SubCategoryType>);
                dispatch(actions.setToastMessage(true, "Sub Category Saved"));
            })
            .catch(onApiError);
    };

    const deleteOwnedSubCategory = (id: string) => () => {
        const updatedOwnedSubCategories = ownedSubCategories.filter(x => x.id !== id);
        const itemToDelete = ownedSubCategories?.find(x => x.id === id) ?? { appliedTo: [] };
        if (itemToDelete?.appliedTo.length > 0) {
            dispatch(actions.setErrorToastMessage(true, "Sub Category has been applied to services, go to the MiniSite and unapply before removing"));
            return;
        }

        setOwnedSubCategories([...updatedOwnedSubCategories, { ...itemToDelete, isDeleting: true }] as Array<SubCategoryType>);
        deleteSupplierOwnedSubCategory(id)
            .then(() => {
                setOwnedSubCategories([...updatedOwnedSubCategories] as Array<SubCategoryType>);
                dispatch(actions.setToastMessage(true, "Sub Category Deleted"));
            })
            .catch(onApiError);
    };

    return (
        <div className={classnames(styles.container, "px-0 p-3  bg-white box-shadow border")}>
            <h3 className="mt-2 font-weight-bold">Manage Sub Categories</h3>
            <div className="mt-2 d-flex flex-column">
                <div className="d-flex align-items-end">
                    <div className="w-25">
                        <FormGroup className="mb-0">
                            <Label>Categories</Label>
                            <CategorySelect
                                selectedCategoryId={categoryIdFilter}
                                categories={categories}
                                isLoading={loading}
                                onCategoryChange={(id: string) => setCategoryIdFilter(id)}
                            />
                        </FormGroup>
                    </div>
                    <div>
                        <Button color="primary" className="ml-3" onClick={onNewOwnedSubCategory}>
                            Add Sub Category <i className="fas fa-plus ml-1 text-success" />
                        </Button>
                    </div>
                </div>
                <div className="d-flex">
                    <table className="d-block" ref={fixedHeaderRef}>
                        <tr>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Category
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Applied To
                            </td>
                            <td className={classnames(styles.headerColumn, "font-weight-bold")}>
                                Sub Category
                            </td>
                        </tr>
                        <div className={classnames("overflow-y-auto", styles.content)}>
                            {ownedSubCategories.filter(x => x.parentCategoryId === categoryIdFilter)
                                .sort((x, y) => sortingUtil.sort(x, y, "ordinal", true))
                                .map(x => (<Row
                                    subCategory={x}
                                    categories={categories}
                                    deleteOwnedSubCategory={deleteOwnedSubCategory}
                                    isLoadingCategories={loading}
                                    onOwnedParentCategoryChange={onOwnedParentCategoryChange}
                                    onOwnedSubCategoryChange={onOwnedSubCategoryChange}
                                    saveOwnedSubCategory={saveOwnedSubCategory}
                                    key={x.id}
                                />))}
                        </div>
                    </table>
                </div>
            </div>
        </div>
    );
};

export { ManageSubCategoriesPage };
