import React from "react";
import classnames from "classnames";

interface Props {
    className?: string,
}

const RatingsWireframe = ({ className }: Props) => (
    <div
        className={classnames("tw-flex tw-flex-row tw-items-center tw-justify-center tw-space-x-5 tw-bg-gray-200 tw-flex-grow",
            className)}
    >
        <i className="fa fa-star fa-2x tw-text-gray-500" />
        <span className="tw-text-3xl tw-text-gray-500">/</span>
        <i className="fa fa-envelope-open-text fa-2x tw-text-gray-500" />
    </div>
);

export { RatingsWireframe };
