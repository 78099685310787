import { SelectItem } from "@/Apis/General/select";

export interface TagItem extends SelectItem {
    priority: number,
    isNew?: boolean,
}

export interface UserTypeItem extends SelectItem {
    selectedTagItems: TagItem[],
}

export interface Upsell {
    userTypeId: string,
    tagId: string,
}

export interface UserUpsell {
    productId: string,
    productName: string,
    productSupplierName: string,
    savedUserTypeId?: string,
    savedTagId?: string,
    savedUpsells: Upsell[],
}

export enum TagStatus {
    Need = 1,
    Have = 2,
    NotInterested = 3,
}

export interface UserTagStatus {
    userId: string,
    userTypeId: string,
    tagId: string,
    tagName: string,
    status: TagStatus,
}

export interface TagCross {
    id: string,
    content: string,
}

export interface UserUpsellTagCrossArea {
    haveTags: TagCross[],
    needTags: TagCross[],
    notInterestedTags: TagCross[],
}

export interface PurchaseUpsell {
    userTypeId?: string,
    userTagStatuses: UserTagStatus[],
    hasNewUserTagStatus?: boolean,
}

export const TagFieldStatuses = {
    haveTags: "haveTags",
    needTags: "needTags",
    notInterestedTags: "notInterestedTags",
};
