import { OrderType } from "@/Apis/SalesManagement";

export interface ProductType {
    id: string;
    name: string;
    description?: string;
    image: string;
    imageThumbnail: string;
    supplierId: string;
    categoryId: string;
    categoryName: string;
    subCategoryId?: string;
    subCategoryName: string;
    paymentFrequency?: string;
    startingPrice: number;
    initialCharge: number;
    initialChargeWithVatIfApplicable: number;
    recurringCharge: number;
    recurringChargeWithVatIfApplicable: number;
    postageCharge: number;
    isVatRequired: boolean;
    addQuantity: boolean;
    minimumQuantity: number;
    moreInformation?: string;
    moreInformationHtml: string;
    images?: Array<ProductGallery>;
    ordinal: number;
    termsDocument?: string;
    productVariant: string;
    serviceFields: Array<ProductFieldType>;
    supplierName?: string;
    supplierCanOfflineInvoice: boolean;
    isOfflineInvoiceable: boolean;
    isOffsitePayment: boolean;
    isFreeProduct: boolean;
    hideInMarketplace: boolean;
    slug: string;
    slugId: number;
    code: string;
    showOnMinisite: boolean;
    productPrices: Array<ProductPriceSummary>;
    hasNAProducts: boolean;
    isDelayedPayment: boolean;
    delayedPaymentFor: number;
    delayedPaymentPeriod: number;
    upsellItems?: Array<string>;
    isPostPurchaseEnabled: boolean;
    postPurchaseInformation: string;
    postPurchaseInformationHtml: string;
    postPurchaseAttachments: Array<PostPurchaseAttachment>;
    orderReference: string;
    orderId: string;
    orderType: OrderType;
    quantityOrdered: number;
    isCompanyFormationsProduct: boolean;
    isProductTileHidden: boolean;
    productType?: string;
    discountRate?: number;
}

export interface ProductFieldType {
    fieldId: string;
    isRequired: boolean;
}

export interface ProductGallery {
    name: string;
    data: string;
}

export interface ProductPriceSummary {
    initialCharge: number;
    paymentFrequency: string;
    isOffsitePayment?: boolean;
}

export interface ProductResponseType {
    products: ProductType[];
    categories: FilterType[];
    suppliers: FilterType[];
    isHexagonProduct: boolean;
}

export interface FilterType {
    id: string;
    name: string;
}

export enum ProductSortOptions {
    Featured = "Featured",
    Alphabetical = "Alphabetical",
    PriceAsc = "Price (lowest to highest)",
    PriceDesc = "Price (highest to lowest)",
    RatingDesc = "Rating (highest to lowest)"
}

export interface PostPurchaseAttachment extends ProductGallery {
    id: string;
}
