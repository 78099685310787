import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import Modal from "@/Modals/Modal";
import { useAPI } from "@/Apis/useAPI";
import { Review } from "@/Apis/Reviews";
import Rating from "@/Components/Rating";
import formatDateHelper from "@/Utils/formatDateHelper";
import { FeaturedLatestReview } from "@/Apis/Suppliers/MiniSiteType";
import { Paging } from "@/Components/Paging";
import { Urls } from "@/Apis/urls";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface Props {
    isOpen: boolean,
    toggle: () => void,
    supplierId?: string,
    averageRating?: number,
    featuredLatestReview?: FeaturedLatestReview,
}

const SupplierReviewsModal = ({ isOpen,
    toggle,
    supplierId,
    averageRating,
    featuredLatestReview }: Props) => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage } = useToastMessageContext();

    const [reviews, setReviews] = useState<Review[]>([]);
    const [pageLength, setPageLength] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if (isOpen && supplierId) {
            get<any>(Urls.reviews.get({ supplierId, pageNumber }))
                .then((response) => {
                    setReviews(response.reviews);
                    setPageLength(response.pages);
                })
                .catch(() => {
                    setPopupErrorMessage("Unfortunately an error occurred.", true);
                });
        }
    }, [isOpen, supplierId, pageNumber]);

    const toggleModal = () => {
        toggle();
        setPageNumber(0);
    };

    const onChangePage = (page: number) => {
        setPageNumber(page);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            closeButton
        >
            <>
                <div className="tw-font-bold tw-text-2xl">
                    Ratings
                </div>
                {averageRating && (averageRating > 0) && (<Rating rating={averageRating} className="tw-w-full" />)}
                {averageRating && (averageRating < 1) && (<div className="tw-w-full">Not rated</div>)}
                {featuredLatestReview && featuredLatestReview?.isFeatured && (
                    <>
                        <div className="tw-font-bold tw-text-base tw-mt-1.5">Featured Review</div>
                        <div className="tw-text-xs tw-text-gray-500">{formatDateHelper.format(featuredLatestReview?.date, "DD/MM/YYYY")}</div>
                        <div className="tw-mt-1.5">
                            <div className="tw-line-clamp-3 tw-break-words">
                                {featuredLatestReview?.content}
                            </div>
                            {featuredLatestReview?.rating > 0 && (
                                <Rating rating={featuredLatestReview?.rating} className="tw-w-full" classNameStar="tw-text-[0.6rem]" />)}
                        </div>
                    </>
                )}
                <hr />
                <div className="tw-space-y-5">
                    {loading && <Spinner className="tw-mx-auto" size="25" /> }
                    {!loading && reviews.map((review) => (
                        <div key={review.reviewId}>
                            <div className="tw-text-xs tw-text-gray-500">{formatDateHelper.format(review?.datePaid, "DD/MM/YYYY")}</div>
                            <div className="tw-mt-1.5">
                                <div className="tw-break-words">
                                    {review?.comment}
                                </div>
                                {review?.rating > 0 && (
                                    <Rating rating={review?.rating} className="tw-w-full" classNameStar="tw-text-[0.6rem]" />)}
                            </div>
                        </div>))}
                </div>
                <Paging
                    className="md:tw-pt-2"
                    onChange={onChangePage}
                    activePage={pageNumber}
                    pageCount={pageLength}
                />
            </>
        </Modal>
    );
};

export default SupplierReviewsModal;
