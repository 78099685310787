import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const Widget = ({ children, title, link, to, onClick, testId }) => {
    if (link) {
        return (
            <Link
                to={to}
                data-testid={testId || to}
                className={classnames(
                    styles.container,
                    link && styles.hover,
                    "box-shadow bg-white position-relative overflow-hidden text-decoration-none text-dark d-flex flex-column",
                )}
                onClick={onClick}
            >
                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2">
                    <h5 className="d-inline font-weight-bold">{title}</h5>
                </div>
                {children}
            </Link>
        );
    }
    return (
        <div className={classnames(styles.container, "box-shadow bg-white position-relative overflow-hidden")}>
            <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2">
                <h5 className="d-inline font-weight-bold">{title}</h5>
            </div>
            {children}
        </div>
    );
};

Widget.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    testId: PropTypes.string,
};

export { Widget };
