import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";

import * as constants from "./constants";
import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";

const SortOrdersModal = ({ isOpen, onClose, onSort, selectedKey, ascending, isSupplier }) => (
    <div className="d-flex align-items-center">
        <Modal isOpen={isOpen} centered autoFocus>
            <ModalBody>
                <h4 className="font-weight-bold mt-0 mb-3">Sort by</h4>
                <CloseButton onClick={onClose} />
                <div className="d-flex flex-column align-items-baseline">
                    {isSupplier && (
                        <Button
                            color="text"
                            className={classnames(styles.sortButton, `pl-0 ${selectedKey === constants.customerName ? "font-weight-bold" : ""}`)}
                            onClick={() => onSort(constants.customerName)}
                        >
                            By Customer Name {selectedKey === constants.customerName ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                        </Button>
                    )}
                    <Button
                        color="text"
                        className={classnames(styles.sortButton, `pl-0 ${selectedKey === constants.serviceName ? "font-weight-bold" : ""}`)}
                        onClick={() => onSort(constants.serviceName)}
                    >
                        By Item Name {selectedKey === constants.serviceName ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                    </Button>
                    <Button
                        color="text"
                        className={classnames(styles.sortButton, `pl-0 ${selectedKey === constants.orderDate ? "font-weight-bold" : ""}`)}
                        onClick={() => onSort(constants.orderDate)}
                    >
                        By Order Date {selectedKey === constants.orderDate ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                    </Button>
                    <Button
                        color="text"
                        className={classnames(styles.sortButton, `pl-0 ${selectedKey === constants.orderReference ? "font-weight-bold" : ""}`)}
                        onClick={() => onSort(constants.orderReference)}
                    >
                        By Order Number {selectedKey === constants.orderReference ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                    </Button>
                    {!isSupplier && (
                        <Button
                            color="text"
                            className={classnames(styles.sortButton, `${isSupplier ? "d-none" : ""} pl-0 ${selectedKey === constants.supplierName ? "font-weight-bold" : ""}`)}
                            onClick={() => onSort(constants.supplierName)}
                        >
                            By Supplier {selectedKey === constants.supplierName ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                        </Button>
                    )}
                    {isSupplier && (
                        <Button
                            color="text"
                            className={classnames(styles.sortButton, `${!isSupplier ? "d-none" : ""} pl-0 ${selectedKey === constants.category ? "font-weight-bold" : ""}`)}
                            onClick={() => onSort(constants.category)}
                        >
                            By Category {selectedKey === constants.category ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                        </Button>
                    )}
                    <Button
                        color="text"
                        className={classnames(styles.sortButton, `pl-0 ${selectedKey === constants.status ? "font-weight-bold" : ""}`)}
                        onClick={() => onSort(constants.status)}
                    >
                        By Status {selectedKey === constants.status ? <i className={`fa fa-sort-${ascending ? "up" : "down"} pl-2`} /> : null}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    </div>
);

SortOrdersModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    selectedKey: PropTypes.string,
    ascending: PropTypes.bool,
    isSupplier: PropTypes.bool,
};

SortOrdersModal.defaultProps = {
    ascending: true,
    isSupplier: false,
};

export default SortOrdersModal;
