import { ReceiptAttachment, ReceiptType } from "@/Apis/Receipts/Create";
import { ReceiptStatus } from "@/Apis/Receipts";

export class MessagingUrls {
    private static messaging = "messaging";

    static getMessages = (folder: Folder = "inbox", getMessagesRequest: GetMessagesRequest = { status: "all", searchQuery: "", page: 0, folder: "inbox" }) => {
        const { page = 0, searchQuery = "", status = "" } = getMessagesRequest;
        // @ts-ignore
        const queryString = new URLSearchParams({ page, searchQuery, status }).toString();
        return `${MessagingUrls.messaging}/${folder}?${queryString.toString()}`;
    };

    static getConversation = (conversationId) => (`${MessagingUrls.messaging}/${conversationId}`);

    static getDraftConversation = (conversationId) => (`${MessagingUrls.messaging}/drafts/${conversationId}`);

    static moveDeletedMessagesToInbox = `${MessagingUrls.messaging}/inbox`;

    static deleteSentMessages = `${MessagingUrls.messaging}/sent`;

    static deleteInboxMessages = `${MessagingUrls.messaging}`;

    static replyToConversation = (conversationId) => (`${MessagingUrls.messaging}/${conversationId}`);

    static uploadConversationAttachment = (conversationId) => (`${MessagingUrls.messaging}/${conversationId}/attachment`);

    static startConversation = `${MessagingUrls.messaging}`;

    static saveDraftConversation = `${MessagingUrls.messaging}/draft`;
}

export interface GetMessagesRequest {
    page: number;
    searchQuery: string;
    status: Status | "";
    folder: Folder;
}

export type Status = "read" | "unread" | "all";
export type Folder = "inbox" | "drafts" | "deleted" | "sent";

export interface GetMessagesResponse
{
    totalPages: number;
    messages: Array<MessageType>;
}

export interface MessageType
{
    messageId: string;
    conversationId: string;
    conversationReference: string;
    isRead: boolean;
    subject: string;
    sentAt: Date
    isUrgent: boolean;
    isEnquiry: boolean;
    attachmentsCount: number;
    senderRecipients: Array<string>;
}

export interface GetConversationResponse {
    conversationId: string;
    subject: string;
    conversationReference: string;
    conversation: GetConversationMessageResponse[];
    isEnquiry: boolean;
    isUrgent: boolean;
    totalAttachments: number;
    attachments: AttachmentType[];
    bespokeQuotes: GetConversationBespokeQuotationResponse[];
    enquirySupplierName: string;
    isEnquirySupplier: boolean;
    isEnquiryOffsitePayment: boolean;
    enquiryCustomFields: GetConversationCustomFieldResponse[];
    // Receipts
    receiptId: string,
    receiptType: ReceiptType,
    receiptRefNo: string,
    isSupplierReceipt: boolean,
    receiptAttachments: ReceiptAttachmentResponse[],
    receiptDateCreated: Date,
    receiptStatus: ReceiptStatus,
    receiptRejectReason: string,
}

export interface GetConversationMessageResponse {
    from: string;
    sentAt: string;
    senderId: string;
    messageId: string;
    body: string;
}

export interface GetConversationBespokeQuotationResponse {
    ordinal: number;
    status: string;
    rejectionReason: string;
    createdAt: string;
    total: number;
    quoteId: string;
    items: GetConversationBespokeQuotationItemResponse[];
    termsDocuments: GetConversationBespokeQuotationTermsDocumentResponse[];
    customFields: GetConversationCustomFieldResponse[];
}

export interface ReceiptAttachmentResponse extends AttachmentType {
    isTermsAndConditions: boolean,
    id: string,
}

export interface GetConversationBespokeQuotationTermsDocumentResponse {
    id: string;
    name: string;
    location: string;
}

export interface GetConversationCustomFieldResponse {
    fieldId: string;
    isRequired: boolean;
}

export interface GetConversationBespokeQuotationItemResponse {
    id: string;
    name: string;
    total: number;
    price: number;
    pricingUnit: string;
    applyVat: boolean;
    totalUnits: number;
    description: string;
}

export interface ReplyToConversationRequest {
    body: string;
    conversationId: string;
    attachments: AttachmentType[];
}

export interface AttachmentType {
    filename: string;
    location: string;
}

export interface StartConversationRequest {
    conversationId: string;
    subject: string;
    body: string;
    isUrgent: boolean;
    recipients: string[];
    attachments: AttachmentType[];
}

export interface StartReceiptConversationRequest extends StartConversationRequest {
    receiptReferenceNumber: number,
    receiptAttachments: ReceiptAttachment[] | undefined
}

export interface UploadConversationAttachmentRequest {
    filename: string;
    base64: string;
    conversationId: string;
}
