import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";

const SupplierMissingBankDetailsModal = () => {
    const { state: locationState } = useLocation();
    const { push } = useHistory();

    const [updateBankDetailsModalOpen, setUpdateBankDetailsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (locationState?.supplierMissingBankDetails) {
            setUpdateBankDetailsModalOpen(true);
        }
    }, [locationState]);

    const onConfirm = () => push(`/suppliers/${locationState.supplierId}/private-information`);
    const onClose = () => setUpdateBankDetailsModalOpen(false);

    return (
        <ConfirmCancelModal
            isOpen={updateBankDetailsModalOpen}
            text="Your order has been saved. The supplier does not have bank details, do you want to update them?"
            onConfirm={onConfirm}
            onClose={onClose}
            buttonConfirmText="Go to Profile"
            buttonCancelText="Close"
        />
    );
};

export { SupplierMissingBankDetailsModal };
