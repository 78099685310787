import { CheckoutResponsePayment } from "@/Apis/Orders";
import api from "@/Utils/api";

export interface SubscriptionDetails {
    orderIds: string[];
    paymentMethodId: string;
}
export interface UpdateSubscription {
    orderId?: string;
    paymentMethodId?: string;
    setDefault: boolean;
}
export interface LogPaypalRequest {
    uniqueReference: string;
    orderIds: string[];
    payload: any;
}

const initialisePayment = (orderIds: string[]) => api.post("payments/initialise", { orderIds });

const createSubscription = (details: SubscriptionDetails) => api.post<CheckoutResponsePayment>("payments/subscription", details);
const updateSubscription = (details: UpdateSubscription) => api.post("payments/update-subscription", details);

const logPaypalRequest = (details: LogPaypalRequest) => api.post("payments/log-paypal-request", details);

export {
    initialisePayment,
    createSubscription,
    updateSubscription,
    logPaypalRequest,
};
