import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import CloseButton from "@/Components/CloseButton";
import { ContextualFragment, renderOr } from "@/Utils/renderOr";

export interface ConfirmModalConfiguration<T = any> {
    title?: ContextualFragment<T>,
    body?: ContextualFragment<T>,
    yes?: ContextualFragment<T>,
    no?: ContextualFragment<T>,
}

export interface ConfirmModalProps<T> extends ConfirmModalConfiguration<T> {
    onClose: (confirmed: boolean) => void,
    context?: T | null,
    size?: "lg" | "md" | "sm",
}

export function ConfirmModal<T>({
    onClose,
    context,
    size = "lg",
    title,
    body,
    yes,
    no,
}: ConfirmModalProps<T>) {
    return (
        <Modal
            isOpen
            centered
            size={size}
            className="tw-p-10"
            objectTitle={
                <>
                    <h4 className="tw-ml-0">{title as any || "Confirm"}</h4>
                </>
            }
        >
            <ModalHeader className="tw-space-x-1">
                <>
                    <i className="fa fa-exclamation-triangle tw-text-amber-400" />&nbsp;{title}
                    <CloseButton onClick={() => onClose(false)} />
                </>
            </ModalHeader>
            <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8">
                <div className="tw-py-3 tw-text-base" data-testid="modal-text">
                    {renderOr(context!, body, <>Are you sure?</>)}
                </div>
                <div className="tw-flex tw-space-x-3 tw-justify-center tw-mt-2">
                    <Button color="primary" className="tw-text-base" outline type="submit" onClick={() => onClose(false)}>
                        {renderOr(context!, no, <>No</>)}
                    </Button>
                    <Button color="primary" className="tw-text-base" type="submit" onClick={() => onClose(true)} data-testid="modal-submit-button">
                        {renderOr(context!, yes, <>Yes</>)}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}
