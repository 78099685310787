import React from "react";
import PropTypes from "prop-types";
// import queryString from "query-string";
// import { useLocation } from "react-router";

import SupplierTile from "@/Components/SupplierTile";
import NoResultsFound from "@/Components/NoResultsFound";

const Suppliers = (props) => {
    // const location = useLocation();
    // const query = queryString.parse(location.search);
    const onSupplierClick = id => () => props.onSupplierClick(id);

    return (
        <div className="tw-grid tw-gap-3 tw-gap tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-4 3xl:tw-grid-cols-6">
            {props.suppliers.length === 0 ? <NoResultsFound /> : (
                props.suppliers.map(x => (
                    <SupplierTile
                        key={x.id}
                        id={x.id}
                        name={x.name}
                        description={x.description}
                        categoryName={(x.categories || []).join(", ")}
                        primarySiteColour={x.primarySiteColour}
                        logo={x.logo}
                        rating={x.rating}
                        onSupplierClick={onSupplierClick(x.id)}
                    />
                )))}
        </div>
    );
};

Suppliers.propTypes = {
    onSupplierClick: PropTypes.func.isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
        primarySiteColour: PropTypes.string.isRequired,
        logo: PropTypes.string,
        rating: PropTypes.number.isRequired,
    })),
};

Suppliers.defaultProps = {
    suppliers: [],
};

export default Suppliers;
