import { ENQUIRY_FORM } from "@/Store/Global/constants";

const getServicesToDisplay = (services, activeCategoryTab) => (
    services.filter(x => x.categoryName === activeCategoryTab))
    .reduce((acc, curValue) => {
        if (!acc[curValue.subCategoryName]) {
            acc[curValue.subCategoryName] = [curValue];
        } else {
            acc[curValue.subCategoryName].push(curValue);
        }
        return acc;
    }, {});

const getActiveCategory = (state, categories) => {
    if (state.activeCategoryTab) {
        return state.activeCategoryTab;
    }
    return categories.length === 1
        ? categories[0]
        : categories.filter(x => x !== ENQUIRY_FORM)[0];
};

export {
    getServicesToDisplay,
    getActiveCategory,
};
