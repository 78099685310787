// Numbers of slides to show at each breakpoint
export const sponsoredSuppliersResponsive = {
    superdesktop: {
        breakpoint: { max: 3500, min: 2500 },
        items: 5,
        partialVisibilityGutter: 40,
    },
    desktop: {
        breakpoint: { max: 2500, min: 1024 },
        items: 4,
        partialVisibilityGutter: 40,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 40,
    },
};
