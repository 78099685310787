import { pdf } from "@react-pdf/renderer";
import { useEffect } from "react";

import { Invoice } from "@/Components/Invoice";
import { OrderInvoiceResponse } from "@/Apis/Orders";
import { blobToBase64 } from "@/Utils/base64EncodeHelper";
import { Urls } from "@/Apis/urls";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { useAPI } from "@/Apis/useAPI";
import { ProductType } from "@/Apis/Products/ProductType";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface Props {
    downloadOnFirstRender?: boolean,
    product: ProductType
}

interface Result {
    loading: boolean,
    downloadAttachments: () => void
}

// Download the post purchase attachments combined the order invoice, all within a zip file
const useDownloadPostPurchaseAttachments = ({ downloadOnFirstRender = false, product } : Props): Result => {
    const { get, postFile, loading } = useAPI();
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const onDownloadPostPurchaseAttachments = () => {
        // get the invoice data for th epurchase
        get<OrderInvoiceResponse>(`invoice/${product.orderId}`)
            .then(invoiceDetails => {
                // Convert the invoice data to a pdf
                pdf(Invoice({ ...invoiceDetails })).toBlob()
                    .then(blobToBase64)
                    .then(base64InvoicePdf => {
                        // Download the post purchase attachments, sending the invoice pdf in the request
                        // So it can be added to the zip file rsult
                        postFile(Urls.postPurchase.getAttachments, {
                            productId: product.id,
                            invoicePdfBase64: base64InvoicePdf,
                            invoicePdfFileName: `Invoice-${product.orderReference}.pdf`,
                        })
                            .then(response => {
                                // Download the zip file in the client's browser
                                downloadUseAPIFile("application/zip", response);
                                if (downloadOnFirstRender) {
                                    // If the user has come directly from an email, let's notify them that the download has taken place
                                    setSuccessMessage("Attachments downloaded", true);
                                }
                            })
                            .catch(error => setPopupErrorMessage(error, true));
                    });
            });
    };

    useEffect(() => {
        if (downloadOnFirstRender) onDownloadPostPurchaseAttachments();
    }, [downloadOnFirstRender]);

    return {
        loading,
        downloadAttachments: onDownloadPostPurchaseAttachments,
    };
};

export default useDownloadPostPurchaseAttachments;
