import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { OrderItemPrice } from "../OrderItemPrice";

const OrderDetailsModalHeader = ({ id, categoryName, serviceName, supplierId, supplierName, quantity, price, postage, totalPrice, totalVat, paymentFrequency }) => (
    <div className="mb-2">
        <h3 className="font-weight-bold mt-3">{serviceName}</h3>
        <div className="font-weight-bold ">{categoryName}</div>
        <div className="text-grey mb-3">
            Fulfilled by <Link to={`/marketplace/supplier-directory/${supplierId}`}>{supplierName}</Link>
        </div>

        <OrderItemPrice
            id={id}
            price={price}
            postage={postage}
            quantity={quantity}
            totalPrice={totalPrice}
            totalVat={totalVat}
            alignLeft
            paymentFrequency={paymentFrequency}
        />
    </div>
);

OrderDetailsModalHeader.propTypes = {
    id: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    supplierId: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    postage: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    totalVat: PropTypes.number,
    paymentFrequency: PropTypes.string.isRequired,
};

export { OrderDetailsModalHeader };
