import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { Filter } from "@/FlexPlan/Types/Pagination";
import { orderStatusToDisplay } from "@/FlexPlan/Pages/Orders/OrderManagement/Types";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";

interface Props {
    filters: Filter[],
    onChange: (value) => any,
}

const FilterOrdersDropdown = ({ filters, onChange }: Props) => (
    <UncontrolledDropdown direction="down" className="pl-2">
        <DropdownToggle className={classnames(styles.filterButton, "bg-transparent border-0 px-3 py-2 text-dark")}>
            <i className="fa fa-filter" />
        </DropdownToggle>
        <DropdownMenu>
            <div className="tw-text-base tw-font-semibold tw-pl-6 tw-p-2">Order Status</div>
            {filters
                .sort((a, b) => a.position - b.position)
                .map(filter => (
                    <DropdownItem className="tw-flex tw-flex-row" key={filter.field}>
                        <Checkbox
                            onChange={onChange(filter.field)}
                            checked={filter.checked}
                            id={filter.field}
                            label={orderStatusToDisplay(filter.field as FlexPlanOrderStatus)}
                        />
                    </DropdownItem>
                ))}
        </DropdownMenu>
    </UncontrolledDropdown>
);

export { FilterOrdersDropdown };
