import React from "react";
import classnames from "classnames";

import { ServiceBookingStatus } from "@/FlexPlan/Types/Ndis/ServiceBookings/serviceBookingStatus";

interface Props {
    status: ServiceBookingStatus | undefined,
}

const getColour = (status: ServiceBookingStatus | undefined): string => {
    switch (status) {
        case "In process":
            return "tw-text-sky-300";
        case "Awaiting Participant Approval":
            return "tw-text-amber-200-300";
        case "Awaiting Provider Approval":
            return "tw-text-orange-600";
        case "Change awaiting participant":
            return "tw-text-orange-200";
        case "Change awaiting provider":
            return "tw-text-amber-600";
        case "Rejected":
            return "tw-text-red-600";
        case "Approved":
            return "tw-text-green-300";
        case "Completed":
            return "tw-text-Green-600";
        case "Deleted":
            return "tw-text-gray-400-600";
        default: // No status
            return "tw-text-black";
    }
};
const RenderServiceBookingStatus = ({ status }: Props) => (
    <span className="tw-flex tw-items-center tw-mt-3">
        <i className={classnames("fa fa-circle mr-2 mb-1 [&>*]:tw-w-2 [&>svg]:tw-h-2", getColour(status))} />
        <span>{status ?? "No Status"}</span>
    </span>
);

export { RenderServiceBookingStatus };
