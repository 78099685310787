import React from "react";
import classnames from "classnames";

interface Props {
    title: string,
    children: React.ReactNode | string,
    stepBuilders: React.ReactNode,
    mobileImagePath: string,
    desktopImagePath: string,
    postPurchaseLinkTab: React.ReactNode,
    childrenClassName?: string,
    imageFirst?: boolean
}

const StepBuilderBanner = ({ title, children, stepBuilders, mobileImagePath, desktopImagePath, postPurchaseLinkTab, childrenClassName, imageFirst = false }: Props) => (
    <div className="tw-mt-8 xl:tw-px-1">
        <div className="tw-text-brand-primary tw-font-semibold lg:tw-mb-5 tw-text-2xl">
            {title}
        </div>
        <div className="tw-grid tw-grid-cols-1 xl:tw-grid-cols-3 lg:tw-space-x-3 lg:tw-gap-3">
            <div className={classnames("tw-flex tw-flex-col tw-justify-between tw-my-4 tw-col-span-1 xl:tw-col-span-1 tw-gap-3", imageFirst && "lg:tw-order-2")}>
                {stepBuilders}
            </div>
            <div className={classnames("tw-col-span-1 xl:tw-col-span-2 tw-rounded-lg tw-relative", imageFirst && "lg:tw-order-1")}>
                <div className="tw-w-full tw-h-full tw-relative tw-w-full tw-h-52 lg:tw-h-full lg:tw-w-full">
                    <picture>
                        <source media="(max-width: 700px)" srcSet={mobileImagePath} />
                        <source media="(min-width: 701px)" srcSet={desktopImagePath} />
                        <img
                            src={desktopImagePath}
                            alt={"\"{title}\"'s step builder"}
                            className="lg:tw-rounded-b-lg tw-rounded-t-lg tw-w-full tw-h-full"
                        />
                    </picture>
                    {postPurchaseLinkTab}
                </div>
                <div
                    className={classnames("lg:tw-absolute tw-bg-brand-primary tw-rounded-b-lg lg:tw-rounded-b-none lg:!tw-rounded-l-lg tw-w-full "
                        + "lg:tw-w-3/5 lg:tw-h-full tw-bottom-0 tw-bg-opacity-95", childrenClassName)}
                >
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default StepBuilderBanner;
