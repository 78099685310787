import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import CreatableSelect from "react-select/creatable";
import { Button, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { Popper } from "@/Components/Popper";
import TextArea from "@/Components/TextArea";
import { AutoSaveFileUpload } from "@/Components/AutoSaveFileUpload";
import { Image } from "@/Components/Image";
import { encodeFileToBase64 } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes } from "@/Utils/constants";
import sortingUtil from "@/Utils/sortingUtil";

const General = ({
    enquiryId,
    categories,
    subCategories = [],
    categoryId = "",
    subCategoryName = "",
    name = "",
    startingPrice = 0,
    isOffsitePayment = false,
    allowFlexiblePayments = false,
    description = "",
    image,
    termsDocument,
    termsDocumentSaving,
    errors = {},
    onChange,
    onChangeMultiple,
}) => {
    const [localSubCategories, changeSubCategories] = useState({ categories: [], name: subCategoryName });

    const onDrop = (files) => {
        files.forEach((x) => {
            encodeFileToBase64(x)
                .then((encodedFile) => {
                    // generate an event like object and pass in the base 64 encoded value
                    onChangeMultiple({ image: encodedFile.target.result });
                });
        });
    };

    const onChangeSubcategory = (event) => {
        if (!event) {
            changeSubCategories({ ...localSubCategories, name: "" });
            return;
        }

        changeSubCategories({ ...localSubCategories, name: event.label });
        onChange("subCategoryName")({ target: { value: event.label } });
    };

    const toggleOffsitePayment = (checked) => {
        onChange("isOffsitePayment")({ target: { value: checked } });
    };

    const onHandleCreateSubcategory = (inputValue) => {
        // push the new value into the state so we can display what the user has typed
        changeSubCategories({
            categories: [...localSubCategories.categories, { label: inputValue, value: inputValue }],
            name: inputValue,
        });
        onChange("subCategoryName")({ target: { value: inputValue } });
    };

    const onTermsDocumentAdded = () => {
        termsDocumentSaving(true);
    };

    const onTermsDocumentUploaded = (fileName) => {
        termsDocumentSaving(false);
        onChangeMultiple({ termsDocument: fileName });
    };

    return (
        <div>
            <p className="my-4">Please fill up all needed information below to add a service.</p>

            <FormGroup>
                <Label>Category *</Label>
                <Input
                    type="select"
                    placeholder="Pick Category"
                    value={categoryId || "none"}
                    invalid={!!errors.categoryId}
                    onChange={onChange("categoryId")}
                    data-testid="enquiry-category"
                >
                    <option disabled value="none">Pick Category</option>
                    {categories
                        .sort((x, y) => sortingUtil.sort(x, y, "name", true))
                        .map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                </Input>
                <FormFeedback className="font-weight-bold">{errors.categoryId}</FormFeedback>
            </FormGroup>

            <FormGroup>
                <Label>Sub Category *</Label>
                <CreatableSelect
                    placeholder="Enter a sub category where this service will belong to"
                    styles={errors.subCategoryName && { control: x => ({ ...x, border: "1px solid #b73847" }) }}
                    value={[...subCategories, ...localSubCategories.categories].find(x => x.label === localSubCategories.name) || {}}
                    isClearable
                    isSearchable
                    isDisabled={!categoryId}
                    onChange={onChangeSubcategory}
                    onCreateOption={onHandleCreateSubcategory}
                    options={[...subCategories, ...localSubCategories.categories]}
                />
                <FormFeedback className={classnames(errors.subCategoryName && "d-block font-weight-bold")}>{errors.subCategoryName}</FormFeedback>
            </FormGroup>

            <FormGroup className="pb-3">
                <Label>Service Name *</Label>
                <Input
                    type="text"
                    placeholder="Service Name"
                    value={name || ""}
                    invalid={!!errors.name}
                    onChange={onChange("name")}
                    data-testid="enquiry-name"
                />
                <FormFeedback className="font-weight-bold">{errors.serviceName}</FormFeedback>

            </FormGroup>

            <TextArea
                label="Service Description *"
                type="textarea"
                placeholder="Service Description"
                value={description || ""}
                error={errors.description}
                onChange={onChange("description")}
                maxCharacters={140}
                data-testid="enquiry-description"
            />

            <FormGroup className={classnames("mt-3 border rounded-lg p-3", errors.image && "border-danger")}>
                <Dropzone onDrop={onDrop} maxSize={8000000} accept={acceptedFileTypes.images}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="d-flex justify-content-between align-items-center">
                            {image
                                ? <Image src={image} alt="service" className={styles.image} testId="enquiry-image" />
                                : <span>Please select an image file for your service *</span>}
                            <Button type="button" color="primary" {...getRootProps()}>
                                <input {...getInputProps()} data-testid="enquiry-image-input" />
                                {image ? "Change File" : "Pick File"}
                            </Button>
                        </div>
                    )}
                </Dropzone>
                <FormFeedback className={classnames(errors.image && "d-block font-weight-bold")}>{errors.image}</FormFeedback>
            </FormGroup>

            <FormGroup>
                <Label>Starting Price</Label>
                <InputGroup className="mb-3 w-50">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>&pound;</InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="text"
                        value={startingPrice}
                        onChange={onChange("startingPrice")}
                        invalid={!!errors.startingPrice}
                        data-testid="enquiry-starting-price"
                    />
                </InputGroup>
                <FormFeedback className="font-weight-bold">{errors.startingPrice}</FormFeedback>
            </FormGroup>

            {allowFlexiblePayments && (
                <FormGroup>
                    <InputGroup className="mb-3 w-50">
                        <Checkbox
                            checked={isOffsitePayment}
                            id="isOffsitePayment"
                            label="Off-site Payment"
                            onChange={toggleOffsitePayment}
                            testId="isOffsitePayment"
                            className="d-inline-block mr-0"
                        />
                        <Popper
                            placement="right"
                            targetId="addAService-offsitePayment"
                            popoverClassName={classnames(styles.popper)}
                            popoverBodyClassName={classnames(styles.popperBody, "shadow-lg p-3")}
                            icon={<i
                                id="addAService-offsitePayment"
                                className="font-weight-bold mb-0 pointer text-primary fas fa-info-circle"
                                data-testid="addAService-offsitePayment"
                            />}
                        >
                            Payment terms are defined within the Supplier Terms and Conditions document uploaded.
                            BSC will generate invoice or order sheet depending on price selection.
                        </Popper>
                        <FormFeedback className="font-weight-bold">{errors.isOffsitePayment}</FormFeedback>
                    </InputGroup>
                </FormGroup>
            )}

            <AutoSaveFileUpload
                documentName={termsDocument}
                onFileAdded={onTermsDocumentAdded}
                onFileUploaded={onTermsDocumentUploaded}
                heading="Terms and Conditions Document"
                autoSaveRoute={`services/${enquiryId}/terms`}
            />
        </div>
    );
};

General.propTypes = {
    enquiryId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeMultiple: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    categoryId: PropTypes.string,
    subCategoryName: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    startingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isOffsitePayment: PropTypes.bool,
    allowFlexiblePayments: PropTypes.bool,
    image: PropTypes.string,
    termsDocument: PropTypes.string,
    termsDocumentSaving: PropTypes.func.isRequired,
    errors: PropTypes.shape(),
    useDescription: PropTypes.bool,
};

export { General };
