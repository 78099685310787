import React from "react";
import Select from "react-select";

import sortingUtil from "@/Utils/sortingUtil";
import { CustomFieldType } from "@/Apis/Suppliers/OwnedCustomFieldsType";

interface Props {
    selectedFieldsType: keyof CustomFieldType;
    onFieldTypeChange: (id: string) => void;
    isLoading: boolean;
}

const FieldTypeSelect = ({ selectedFieldsType, onFieldTypeChange, isLoading }: Props) => {
    const fieldTypes = ["Text", "TextArea", "AddressLookUp", "Date", "YesNo", "UploadAttachment"];
    const sortedFieldTypes = fieldTypes.map(x => ({
        label: x,
        value: x,
    })).sort((x, y) => sortingUtil.sort(x, y, "label", true));

    const onChange = (selectedOption: { label, value }) => {
        onFieldTypeChange(selectedOption.value);
    };

    return (
        <Select
            value={sortedFieldTypes.filter(x => x.value === selectedFieldsType)}
            onChange={onChange}
            options={sortedFieldTypes}
            isLoading={isLoading}
        />);
};

export { FieldTypeSelect };
