import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const Notifications = ({
    openOrders,
    placedOrders,
    ordersToReview,
    isSupplier,
    error,
    unreadMessages,
    isUserOrder,
    isNotView,
}) => {
    const notificationBodyBuilder = (
        recordLink: string,
        recordText: string,
        recordNumber: number,
        isLastItem: boolean = false,
    ) => (
        <>
            <div className={classnames(`${isLastItem ? null : "tw-border-r-2 tw-h-12"} tw-flex tw-flex-col tw-hover:tw-opacity-70 tw-items-center cursor-pointer tw-px-0.5`,
                `${isSupplier ? "tw-w-1/4" : "tw-w-1/3"} lg:tw-w-1/6`)}
            >
                <div className="tw-flex tw-flex-col">
                    <span className="tw-flex tw-text-zinc-500 tw-text-center tw-text-xs lg:tw-text-sm 3xl:tw-text-lg">{recordText}</span>
                    <Link
                        to={recordLink}
                        onClick={e => isNotView && e.preventDefault()}
                    >
                        <div className="tw-flex tw-flex-col tw-items-center">
                            <div
                                className="tw-mb-0 tw-text-primary tw-font-bold tw-text-lg lg:tw-text-2xl 3xl:tw-text-3xl"
                                data-testid={`${recordText.replace(/ /g, "-").toLowerCase()}-notification`}
                            >
                                {recordNumber}
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );

    const renderNewNotification = () => (
        <>
            <div className="tw-bg-white tw-flex tw-flex-row tw-justify-center tw-pt-4 tw-pb-2 tw-border tw-border-b tw-border-b-transparent tw-shadow-md">
                {(isSupplier || isUserOrder) && notificationBodyBuilder("/supplier-orders", "Open Orders", openOrders)}
                {notificationBodyBuilder("/customer-orders", "Placed Orders", placedOrders)}
                {!isUserOrder && notificationBodyBuilder("/messages", isSupplier ? "Messages" : "Unread Messages", Math.max(0, unreadMessages))}
                {!isUserOrder && notificationBodyBuilder("/rate-suppliers", isSupplier ? "Reviews" : "Orders To Review", ordersToReview, true)}
            </div>
        </>
    );

    return (
        <>
            {error && <div className={classnames(styles.error, "bg-danger text-center text-white py-2")}>{error}</div>}
            {renderNewNotification()}
        </>
    );
};

Notifications.propTypes = {
    isSupplier: PropTypes.bool.isRequired,
    placedOrders: PropTypes.number,
    ordersToReview: PropTypes.number,
    unreadMessages: PropTypes.number,
    openOrders: PropTypes.number,
    error: PropTypes.string,
    isUserOrder: PropTypes.bool,
    isNotView: PropTypes.bool,
};

Notifications.defaultProps = {
    placedOrders: 0,
    ordersToReview: 0,
    unreadMessages: 0,
    openOrders: 0,
    isNotView: false,
};

const mapStateToProps = state => ({
    placedOrders: state.notifications.placedOrders,
    openOrders: state.notifications.openOrders,
    unreadMessages: state.notifications.unreadMessages,
    isSupplier: state.user.isSupplier,
});

const connectedNotifications = connect(mapStateToProps)(Notifications);
export {
    connectedNotifications as Notifications,
    Notifications as PureNotifications,
};
