import { getToken, tokenExpired } from "@/Utils/authentication";
import { User } from "@/FlexPlan/Types/User";
import { FlexPlanRoleStrings } from "@/FlexPlan/Types/Roles";

export const getParsedJwt = <T extends object = { [k: string]: string | number }>(token: string): T => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch {
        return { } as T;
    }
};

export const useUser = () => {
    const userFromJwt = (token) => getParsedJwt<{
            ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]: string,
            hasLoggedInAtLeastOnce: string,
            internalUserId: string,
            ["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]: string[],

        }>(token);

    const getUser = (): User | null => {
        const token = getToken();
        if (token !== null) {
            const jwtUser = userFromJwt(token);
            return {
                hasLoggedInAtLeastOnce: jwtUser.hasLoggedInAtLeastOnce?.toLowerCase() === "true",
                userId: jwtUser.internalUserId,
                email: jwtUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                roles: jwtUser["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
            };
        }
        return null;
    };

    const userIsInRole = (role: FlexPlanRoleStrings) => (getUser()?.roles?.includes(role));

    const loggedIn = () => (!!getUser());

    return { user: getUser, tokenExpired, loggedIn, userIsInRole };
};
