import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import classnames from "classnames";
import { Col, Media, Row, Table, Button } from "reactstrap";
import { Link, Route } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import Spinner from "@/Components/Spinner";
import { PureNotifications } from "@/Components/Notifications";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { OrderSearch } from "@/Components/OrderSearch";
import FilterOrdersModal from "@/Modals/FilterOrdersModal";
import { CustomerOrderDetailsModal } from "@/Modals/CustomerOrderDetailsModal";
import SortOrdersModal from "@/Modals/SortOrdersModal";
import formatDateHelper from "@/Utils/formatDateHelper";
import sortingUtil from "@/Utils/sortingUtil";
import api from "@/Utils/api";
import { pascalCaseToTitleCase } from "@/Utils/formatStringHelper";

class UserOrdersPage extends React.PureComponent {
    static formatSubscriptionPaymentStatus(subscriptionPaymentStatus, subscriptionPaymentDate) {
        if (!subscriptionPaymentStatus) {
            return "-";
        }

        if (subscriptionPaymentStatus === "InvoicePaymentFailed" || subscriptionPaymentStatus === "InvoicePaymentActionRequired") {
            return (<span className="text-danger font-weight-bold">{pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""))}</span>);
        }

        return subscriptionPaymentStatus === "InvoicePaid"
            ? formatDateHelper.format(subscriptionPaymentDate, "DD/MM/YYYY")
            : pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""));
    }

    state = {
        loading: true,
        userName: "",
        orders: [],
        metaData: {},
        sortBy: "orderDate",
        filters: [],
        dateFilter: 3,
        sortModalOpen: false,
        filterModalOpen: false,
        includeSubscriptions: true,
        openOrderCount: 0,
        placedOrderCount: 0,
    };

    componentDidMount() {
        document.title = "BSC - User Orders";
        this.getOrdersFromApi();
    }

    getOrdersFromApi = (search) => {
        const userId = this.props.match.params.id;
        let url = `orders/user?dateFilter=${this.state.dateFilter}`;
        url = search ? `${url}&searchTerm=${search}` : url;
        url = this.state.filters ? `${url}&statusFilter=${this.state.filters.join(",")}` : url;
        url += `&includeSubscriptions=${this.state.includeSubscriptions ? "true" : "false"}`;
        url = userId === undefined ? url : `${url}&userId=${userId}`;

        api.get(url)
            .then((response) => {
                window.response = response;
                this.setState({
                    ...response,
                    selected: response.orders.find(x => x.id === this.props.match.params.orderId),
                    loading: false,
                    userName: response.userName,
                    openOrderCount: response.openOrderCount,
                    placedOrderCount: response.placedOrderCount,
                }, this.updateFilters);
            })
            .catch((error) => {
                this.props.pushToError(error);
            });
    };

    toggleFilter = (value) => {
        if (this.state.filters.includes(value)) {
            this.setState(prevState => ({ filters: prevState.filters.filter(x => x !== value) }), () => {
                this.getOrdersFromApi();
            });
        } else {
            this.setState(prevState => ({ filters: [...prevState.filters, value] }), () => {
                this.getOrdersFromApi();
            });
        }
    };

    setFilter = (value) => {
        this.setState({ dateFilter: value }, () => this.getOrdersFromApi());
    };

    toggleRadioFilter = values => () => {
        this.setState({ filters: [...values] }, () => this.getOrdersFromApi());
    };

    toggleSubscriptions = () => {
        this.setState(prevState => ({ includeSubscriptions: !prevState.includeSubscriptions }), () => this.getOrdersFromApi());
    };

    showSortModal = (shouldShow) => (
        this.setState({
            sortModalOpen: shouldShow,
        })
    );

    showFilterModal = (shouldShow) => (
        this.setState({
            filterModalOpen: shouldShow,
        })
    );

    sortOrders = (key) => {
        this.sort(key)();
    };

    sort = key => () => {
        this.setState((prevState) => {
            if (prevState.sortBy === key) {
                return { sortAscending: !prevState.sortAscending };
            }
            return {
                sortBy: key,
                sortAscending: true,
            };
        });
    };

    onViewDetails = order => () => {
        this.setState({ selected: order }, () => this.props.pushToDetails(this.props.match.params.id, order.id));
    };

    onOrderStatusChanged = (orderId, status) => {
        this.setState(prevState => ({
            ...prevState,
            orders: prevState.orders.map((x) => {
                if (x.id === orderId) {
                    return {
                        ...x,
                        status,
                    };
                }
                return x;
            }),
        }));
    };

    updateFilters() {
        const filters = this.state.metaData.statusFilters;
        const allFilters = Object.keys(filters).map(x => filters[x]);

        if (this.state.filters.length === 0) {
            this.setState({ filters: allFilters });
        }
    }

    renderTableHeader = () => (
        <thead>
            <tr>
                <th style={{ width: "16%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("customerName")}>Placed By <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderDate")}>Order Date <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderReference")}>Order Number <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "18%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("serviceName")}>Item <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "14%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("category")}>Category <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "10%" }}>
                    <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderType")}>Type <i className="fa fa-sort" /></button>
                </th>
                <th style={{ width: "10%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0"
                        onClick={this.sort("subscriptionPaymentStatus")}
                    >
                        Subscription Payment Status <i className="fa fa-sort" />
                    </button>
                </th>
                <th style={{ width: "10%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0"
                        onClick={this.sort("status")}
                    >
                        Status <i className="fa fa-sort" />
                    </button>
                </th>
            </tr>
        </thead>
    );

    renderNoOrders = () => (
        <Col xs="12" className="d-flex flex-column pb-3">
            <Table responsive>
                {this.renderTableHeader()}
            </Table>
            <div className="text-center mb-3 pb-3">
                <Media src={`${window.cdnUrl}misc/calvin-empty-box.png`} alt="No result found" className={classnames(styles.noResultsImage, "mx-auto mt-2")} />
                <h5>Uh-oh, there are no purchases yet.</h5>
                <h5 className="mb-3">Want to edit your site!</h5>
                <Button color="primary" tag={Link} to="/mini-site">Take me to the editor!</Button>
            </div>
        </Col>
    );

    render() {
        if (this.state.loading) {
            return (
                <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                    <Spinner className="align-self-center" />
                </div>
            );
        }
        const orders = this.state.orders.sort((x, y) => sortingUtil.sort(x, y, this.state.sortBy, this.state.sortAscending));
        return (
            <>
                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border pb-3">
                        <div className="p-3 mt-2">
                            {!this.state.userName
                                ? <h3 className="font-weight-bold">User Orders</h3>
                                : <h3 className="font-weight-bold">User Orders - {this.state.userName}</h3>}
                        </div>
                        <PureNotifications
                            openOrders={this.state.openOrderCount}
                            placedOrders={this.state.placedOrderCount}
                            ordersToReview
                            isSupplier={this.props.isSupplier}
                            error={this.state.error}
                            isUserOrder
                            isNotView
                        />
                    </Col>
                </Row>

                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border">

                        <OrderSearch
                            toggleStatusFilter={this.toggleFilter}
                            toggleDateFilter={this.setFilter}
                            onSearch={this.getOrdersFromApi}
                            currentStatusFilters={this.state.filters}
                            currentDateFilter={this.state.dateFilter}
                            dateFilters={this.state.metaData.dateFilters}
                            statusFilters={this.state.metaData.statusFilters}
                            toggleStatusRadio={this.toggleRadioFilter}
                            showSortModal={this.showSortModal}
                            showFilterModal={this.showFilterModal}
                            toggleSubscriptions={this.toggleSubscriptions}
                            includeSubscriptions={this.state.includeSubscriptions}
                        />

                        <Row className="flex-grow-1 d-flex">
                            {orders.length === 0 ? this.renderNoOrders() : (
                                <Col xs="12" className="flex-grow-0">
                                    <Table responsive hover>
                                        {this.renderTableHeader()}
                                        <tbody className="pointer">
                                            {orders.map(x => (
                                                <tr key={x.id} onClick={this.onViewDetails(x)} className="border-bottom">
                                                    <td className="text-primary">{x.customerName}</td>
                                                    <td className="py-2">
                                                        <span className="d-block">{formatDateHelper.format(x.orderDate, "DD/MM/YYYY")}</span>
                                                        <span>{formatDateHelper.format(x.orderDate, "HH:mm:ss")}</span>
                                                    </td>
                                                    <td>{x.orderReference}</td>
                                                    <td className="text-primary">{x.serviceName}</td>
                                                    <td>{x.category}</td>
                                                    <td>{x.orderType}</td>
                                                    <td>{UserOrdersPage.formatSubscriptionPaymentStatus(x.subscriptionPaymentStatus, x.subscriptionPaymentDate)}</td>
                                                    <td><OrderStatusIcon status={x.status} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <FilterOrdersModal
                    isOpen={this.state.filterModalOpen}
                    onClose={() => this.showFilterModal(false)}
                    toggleDateFilter={this.setFilter}
                    dateFilters={this.state.metaData.dateFilters}
                    currentDateFilter={this.state.dateFilter}
                    toggleStatusFilter={this.toggleFilter}
                    statusFilters={this.state.metaData.statusFilters}
                    currentStatusFilters={this.state.filters}
                />

                <SortOrdersModal
                    isOpen={this.state.sortModalOpen}
                    onClose={() => this.showSortModal(false)}
                    onSort={this.sortOrders}
                    selectedKey={this.state.sortBy}
                    ascending={this.state.sortAscending}
                    isSupplier
                    isUserOrder
                />

                <Route
                    path="/user-orders/:id/:orderId/details"
                    render={() => <CustomerOrderDetailsModal id={this.state.selected.id} isFromUserOrder userId={this.props.match.params.id} />}
                />

                <Footer />
            </>
        );
    }
}

UserOrdersPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            orderId: PropTypes.string,
        }).isRequired,
    }).isRequired,
    pushToError: PropTypes.func.isRequired,
    pushToDetails: PropTypes.func.isRequired,
    isSupplier: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    isSupplier: state.user.isSupplier,
    notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
    pushToError: error => dispatch(push({ pathname: "/error", state: { error, referrer: window.location.href } })),
    pushToDetails: (userId, orderId) => dispatch(push(`/user-orders/${userId}/${orderId}/details`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOrdersPage);
