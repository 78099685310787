import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useRouteMatch, Route } from "react-router-dom";

import styles from "./styles.module.scss";

import { Popper } from "@/Components/Popper";
import Checkbox from "@/Components/Checkbox";
import { SearchTextBox } from "@/Components/SearchTextBox";
import { AccordionHeading } from "@/Components/AccordionHeading";
import { AddFieldModal } from "@/Modals/AddFieldModal";

const FieldTransferSelectorService = ({ fields, selectedFields = [], onChange, isEnquiry }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [search, onSearch] = useState();
    const [openSection, setOpenSection] = useState();
    const [popularFieldSection, setPopularFieldSection] = useState();

    const [allFieldsRequired, setAllFieldsRequired] = useState(selectedFields.length > 0 && selectedFields.filter(x => x.isRequired === true).length === selectedFields.length);

    const onSearchChange = (e) => {
        onSearch(e.target.value);
    };

    useEffect(() => {
        const items = fields.filter(x => ["First Name", "Company Name", "Email Address", "Date of Birth"].find(y => y === x.displayText));
        setPopularFieldSection({
            description: "Popular Fields",
            items,
        });
    }, []);

    const toggleOpenSection = (description) => () => {
        if (openSection === description) {
            setOpenSection("");
        } else {
            setOpenSection(description);
        }
    };

    const mapFieldsToStructure = (array, description, item) => {
        const result = array;
        // Create new group
        if (!result[description]) {
            // eslint-disable-next-line no-param-reassign
            result[description] = {
                description,
                items: [],
            };
        }

        // Append to group
        result[description].items.push(item);
        return result;
    };

    // Reduce the fields into the correct structure
    const formattedFields = Object.values(fields
        .filter(x => (search ? x.displayText.toLowerCase().indexOf(search.toLowerCase()) > -1 : true))
        .reduce((result, { description, id, displayText, ordinal }) => mapFieldsToStructure(result, description, { displayText, ordinal, id }), {}));

    // Reduce the fields that have been added to this service into the correct structure
    const formattedSelectedFields = Object.values(fields
        .filter(x => selectedFields.some(s => s.fieldId === x.id))
        .reduce((result, { description, id, displayText, ordinal }) => mapFieldsToStructure(result, description, { displayText, ordinal, id }), {}));

    const renderServices = (list, alwaysOpen, hideHeading, popularField, innerContent) => (
        <div className="d-flex flex-column w-100">
            {popularField
                && (
                    <AccordionHeading
                        key={popularField.description}
                        heading={popularField.description}
                        hideHeading={hideHeading}
                        open={alwaysOpen || (openSection ? openSection === popularField.description : true)}
                        disabled={alwaysOpen}
                        onToggle={toggleOpenSection(popularField.description)}
                        className="border-0 px-3 py-2 m-0 shadow-none"
                        headerClassName="font-weight-bold"
                    >
                        {popularField.items.map(y => innerContent(popularField, y))}
                    </AccordionHeading>)}
            {list.map((x) => (
                <AccordionHeading
                    key={x.description}
                    heading={x.description}
                    hideHeading={hideHeading}
                    open={alwaysOpen || (openSection ? openSection === x.description : false)}
                    disabled={alwaysOpen}
                    onToggle={toggleOpenSection(x.description)}
                    className="border-0 px-3 py-2 m-0 shadow-none"
                    headerClassName="font-weight-bold"
                >
                    {x.items.map(y => innerContent(x, y))}
                </AccordionHeading>
            ))}
        </div>
    );

    const hasSelectedFields = Object.keys(selectedFields).length > 0;

    const addSelectedField = id => () => {
        onChange("selectedFields")({
            target: {
                value: [
                    ...selectedFields,
                    { fieldId: id, isRequired: allFieldsRequired },
                ],
            },
        });
    };

    const removeSelectedField = id => () => {
        const value = selectedFields.filter(x => x.fieldId !== id);
        onChange("selectedFields")({ target: { value } });
    };

    const onAllFieldsRequiredChange = value => () => {
        const selectedFieldsToChange = selectedFields.map(x => ({ ...x, isRequired: value }));
        setAllFieldsRequired(value);
        onChange("selectedFields")({ target: { value: selectedFieldsToChange } });
    };

    const onRequiredChange = id => (value) => {
        const field = selectedFields.find(x => x.fieldId === id);
        field.isRequired = value;
        onChange("selectedFields")({ target: { value: [...selectedFields.filter(x => x.fieldId !== id), field] } });
    };

    const onPreQuoteChange = id => (value) => {
        const field = selectedFields.find(x => x.fieldId === id);
        field.preQuote = value;
        onChange("selectedFields")({ target: { value: [...selectedFields.filter(x => x.fieldId !== id), field] } });
    };

    const closeAddFieldModal = () => dispatch(push(match.url));
    const openAddFieldModal = () => dispatch(push(`${match.url}/newField`));

    return (
        <>
            <Row className="mt-3">
                <Col sm="6">
                    <SearchTextBox onChange={onSearchChange} placeholder="Search for fields to add" />
                </Col>

                <Col sm="6" className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 font-weight-bold">Added Fields</h5>

                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    {/* FIELDS */}
                    <div className={classnames("border rounded-lg pt-2 mt-3 flex-grow-1 overflow-y-auto", styles.fieldsContent)}>
                        {formattedFields.length > 0 ? renderServices(formattedFields, false, false, popularFieldSection, (x, y) => (
                            <div key={y.id} className="d-flex align-items-center mb-3 px-3">
                                <span className="flex-grow-1">{y.displayText}</span>
                                {selectedFields.some(selected => selected.fieldId === y.id)
                                    ? <span className="text-grey">added</span>
                                    : (
                                        <button
                                            type="button"
                                            data-testid={`field-${y.displayText}`}
                                            onClick={addSelectedField(y.id)}
                                            className={classnames(styles.iconBtn, "d-flex justify-content-center align-items-center border-0 bg-primary rounded-circle text-white")}
                                        >
                                            <i className="fa fa-angle-double-right" />
                                        </button>
                                    )}
                            </div>
                        )) : <div className="font-weight-bold mb-3">No fields found. Please contact us.</div>}
                    </div>
                    <div className="mt-3">
                        <Button color="primary" onClick={openAddFieldModal}>Add Custom Field</Button>
                    </div>
                </Col>

                <Col sm="6">
                    {/* SELECTED FIELDS */}
                    <div className={classnames("border rounded-lg pt-2 mt-3 flex-grow-1 overflow-y-auto", styles.fieldsContent)}>
                        {hasSelectedFields && (
                            <div className="container mb-2">
                                <div className="row">
                                    <div className="col-md-1" />
                                    <div className="col-md-1">
                                        <Checkbox
                                            checked={allFieldsRequired}
                                            onChange={onAllFieldsRequiredChange(!allFieldsRequired)}
                                            id="allFieldsRequired"
                                            className="ml-2"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <span>Required All</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {hasSelectedFields ? renderServices(formattedSelectedFields, true, true, undefined, (x, y) => {
                            const selectedField = selectedFields.find(selected => selected.fieldId === y.id) || {};
                            return (
                                <div key={y.id} className="container mb-3">
                                    <div className="row">
                                        <div className="col-md-1">
                                            <button
                                                type="button"
                                                onClick={removeSelectedField(y.id)}
                                                className={classnames(styles.iconBtn,
                                                    "d-flex justify-content-center align-items-center border-0 bg-danger rounded-circle text-white")}
                                            >
                                                <i className="fa fa-angle-double-left" />
                                            </button>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="row">
                                                <span className="mb-1 ml-2">{y.displayText}</span>
                                            </div>
                                            <div className="row">
                                                <span className="text-grey mb-1 ml-2">{x.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1" />
                                        <div className="col-md-1">
                                            <Checkbox
                                                checked={selectedField.isRequired}
                                                onChange={onRequiredChange(y.id)}
                                                id={`${y.id}-required`}
                                                className="ml-2"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <span>Required</span>
                                        </div>
                                        {isEnquiry && (
                                            <>
                                                <div className="col-md-1">
                                                    <Checkbox
                                                        checked={selectedField.preQuote}
                                                        onChange={onPreQuoteChange(y.id)}
                                                        id={`${y.id}-prequote`}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Popper
                                                        placement="bottom-end"
                                                        targetId={`preQuoteInfo${y.id}`}
                                                        popoverClassName={classnames(styles.popper)}
                                                        popoverBodyClassName={classnames(styles.popperBody, "shadow-lg p-3")}
                                                        icon={(
                                                            <div id={`preQuoteInfo${y.id}`} data-testid={`preQuoteInfo${y.id}`}>
                                                                <span>Pre-Quote</span> <i className="h5 ml-2 fas fa-info-circle text-grey" />
                                                            </div>)}
                                                    >
                                                        <div>
                                                            If selected, user is to fill in this field before sending an equiry.
                                                            Otherwise, the field will be filled in during the checkout process.
                                                        </div>
                                                    </Popper>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                </div>
                            );
                        }) : <div className="font-weight-bold mb-3 px-3">Pick a field on the left to add.</div>}
                    </div>
                </Col>
            </Row>
            <Route
                path={`${match.url}/newField`}
                render={() => <AddFieldModal onClose={closeAddFieldModal} />}
            />
        </>
    );
};

FieldTransferSelectorService.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayText: PropTypes.string.isRequired,
        ordinal: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    selectedFields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    })),
    onChange: PropTypes.func.isRequired,
    isEnquiry: PropTypes.bool.isRequired,
};

export { FieldTransferSelectorService };
