import React, { useState } from "react";
import classnames from "classnames";
import { Button, Modal, ModalBody } from "reactstrap";
import Select from "react-select";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import NumberField from "@/Components/NumberField";
import { ToolTip } from "@/Components/ToolTip";
import RadioButton from "@/Components/RadioButton";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { SelectItem } from "@/Apis/SelectItem";
import { ReceiptLineItem, ReceiptRecurrenceFrequency } from "@/Apis/Receipts/Create";

interface Props {
    isOpen: boolean;
    lineItem?: ReceiptLineItem;
    setLineItem: React.Dispatch<React.SetStateAction<ReceiptLineItem | undefined>>;
    onCancel: () => void,
    onUpdate: (submitData?: ReceiptLineItem | undefined) => void;
}

const ReceiptMoreOptionsModal = (
    {
        isOpen,
        lineItem,
        setLineItem,
        onCancel,
        onUpdate,
    }
        : Props,
) => {
    const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
    const [cancelOpenModal, setCancelOpenModal] = useState<boolean>(false);

    const receiptDelayPayments = [
        {
            label: "Weeks",
            value: 1,
        },
        {
            label: "Months",
            value: 2,
        },
        {
            label: "Years",
            value: 3,
        },
    ] as SelectItem[];

    const onTextInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsFormChanged(true);
        setLineItem(
            prevState => ({
                ...prevState,
                [field]: e.target.value,
            } as ReceiptLineItem),
        );
    };

    const onControlInput = (field: string, value: any) => {
        setIsFormChanged(true);
        switch (field) {
            case "changeVatPercentage":
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                        vatPercentage: 0,
                    } as ReceiptLineItem),
                );
                break;
            case "setRecurrence":
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                        recurrenceFrequency: 0,
                        changeDelayPayment: false,
                        delayPaymentValue: 0,
                        receiptDelayPayment: 1,
                    } as ReceiptLineItem),
                );
                break;
            case "changeDelayPayment":
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                        delayPaymentValue: 0,
                        receiptDelayPayment: 1,
                    } as ReceiptLineItem),
                );
                break;
            case "changeHexagonRewardPercentage":
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                        hexagonRewardPercentage: 0,
                    } as ReceiptLineItem),
                );
                break;
            case "receiptDelayPayment":
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                    } as ReceiptLineItem),
                );
                break;
            default:
                setLineItem(
                    prevState => ({
                        ...prevState,
                        [field]: value,
                    } as ReceiptLineItem),
                );
                break;
        }
    };

    const onConfirmCancel = () => {
        setIsFormChanged(false);
        setCancelOpenModal(false);
        onCancel();
    };

    const onClickCancel = () => {
        if (isFormChanged) {
            setCancelOpenModal(true);
        } else {
            onConfirmCancel();
        }
    };

    const onClickUpdate = () => {
        setIsFormChanged(false);
        setCancelOpenModal(false);
        onUpdate(lineItem);
    };

    return (
        <Modal isOpen={isOpen} centered size="md" contentClassName={classnames(styles.modalContainer, "bg-white")}>
            <ModalBody
                data-testid="receipt-more-options-modal"
                className="d-flex flex-column p-3 p-sm-4 flex-grow-1 position-relative"
            >
                <div>
                    <h4 className="font-weight-bold mt-2">
                        Line Item Options
                    </h4>
                    <div>
                        <div className="tw-flex tw-flex-row tw-mt-6">
                            <Checkbox
                                checked={lineItem?.changeVatPercentage ?? false}
                                onChange={checked => onControlInput("changeVatPercentage", checked)}
                                id="change-vat-percentage"
                                label="Change VAT Percentage"
                                className={classnames(styles.w60, "tw-font-semibold")}
                                testId="chk-change-vat-percentage"
                            />
                            <NumberField
                                min={0}
                                max={99.99}
                                label=""
                                onChange={onTextInput("vatPercentage")}
                                value={lineItem?.vatPercentage}
                                suffixChar="%"
                                className={classnames(styles.w30, "tw-font-semibold")}
                                maxDecimalPlaces={2}
                                testId="vat-percentage"
                                disabled={!lineItem?.changeVatPercentage}
                            />
                            <div className={classnames(styles.w10)}>
                                <ToolTip
                                    description=""
                                    id="ttip-change-vat-percentage"
                                    className="float-right"
                                >
                                    <i className="fas fa-info-circle tw-text-gray-400" data-testid="ttip-change-vat-percentage-icon" />
                                </ToolTip>
                            </div>
                        </div>
                        <hr />

                        <div className="tw-flex tw-flex-row tw-mt-4">
                            <Checkbox
                                checked={lineItem?.setRecurrence ?? false}
                                onChange={checked => onControlInput("setRecurrence", checked)}
                                id="set-recurrence"
                                label="Set Recurrence"
                                className={classnames(styles.w90, "tw-font-semibold")}
                                testId="chk-set-recurrence"
                            />
                            <div className={classnames(styles.w10)}>
                                <ToolTip
                                    description=""
                                    id="ttip-set-recurrence"
                                    className="float-right"
                                >
                                    <i className="fas fa-info-circle tw-text-gray-400" data-testid="ttip-set-recurrence-icon" />
                                </ToolTip>
                            </div>
                        </div>
                        <div className="tw-flex tw-space-x-3 tw-mt-4">
                            <RadioButton
                                id="1"
                                label="Weekly"
                                checked={lineItem?.receiptRecurrenceFrequency === ReceiptRecurrenceFrequency.Weekly}
                                onChange={() => onControlInput("receiptRecurrenceFrequency", ReceiptRecurrenceFrequency.Weekly)}
                                name="recurrence-weekly"
                                disabled={!lineItem?.setRecurrence}
                            />
                            <RadioButton
                                id="2"
                                label="Monthly"
                                checked={lineItem?.receiptRecurrenceFrequency === ReceiptRecurrenceFrequency.Monthly}
                                onChange={() => onControlInput("receiptRecurrenceFrequency", ReceiptRecurrenceFrequency.Monthly)}
                                name="recurrence-monthly"
                                disabled={!lineItem?.setRecurrence}
                            />
                            <RadioButton
                                id="3"
                                label="Yearly"
                                checked={lineItem?.receiptRecurrenceFrequency === ReceiptRecurrenceFrequency.Yearly}
                                onChange={() => onControlInput("receiptRecurrenceFrequency", ReceiptRecurrenceFrequency.Yearly)}
                                name="recurrence-yearly"
                                disabled={!lineItem?.setRecurrence}
                            />
                        </div>
                        <div className="tw-flex tw-space-x-3 tw-mt-4 md:tw-ml-6">
                            <Checkbox
                                checked={lineItem?.changeDelayPayment ?? false}
                                onChange={checked => onControlInput("changeDelayPayment", checked)}
                                id="delay-payment"
                                label="Delay Payment"
                                className="tw-font-semibold"
                                testId="chk-delay-payment"
                                disabled={!lineItem?.setRecurrence}
                            />
                        </div>
                        <div className="tw-flex tw-space-x-3 tw-mt-4 md:tw-ml-6">
                            <NumberField
                                min={0}
                                max={99999}
                                label=""
                                onChange={onTextInput("delayPaymentValue")}
                                value={lineItem?.delayPaymentValue}
                                className={classnames(styles.w30, "tw-font-semibold")}
                                maxDecimalPlaces={0}
                                testId="delay-payment-value"
                                disabled={!lineItem?.changeDelayPayment}
                            />
                            <div className={classnames(styles.w30, "tw-font-semibold")} data-testid="receipt-delay-payment">
                                <Select
                                    value={receiptDelayPayments?.find(x => x.value === lineItem?.receiptDelayPayment)}
                                    onChange={e => onControlInput("receiptDelayPayment", e?.value)}
                                    options={receiptDelayPayments}
                                    isDisabled={!lineItem?.changeDelayPayment}
                                />
                            </div>
                        </div>
                        <hr />

                        <div className="tw-flex tw-flex-row tw-mt-4">
                            <Checkbox
                                checked={lineItem?.changeHexagonRewardPercentage ?? false}
                                onChange={checked => onControlInput("changeHexagonRewardPercentage", checked)}
                                id="change-hexagon-reward-percentage"
                                label="Hexagon Reward Percentage"
                                className={classnames(styles.w60, "tw-font-semibold")}
                                testId="chk-change-hexagon-reward-percentage"
                            />
                            <NumberField
                                min={0}
                                max={99.99}
                                label=""
                                onChange={onTextInput("hexagonRewardPercentage")}
                                value={lineItem?.hexagonRewardPercentage}
                                suffixChar="%"
                                className={classnames(styles.w30, "tw-font-semibold")}
                                maxDecimalPlaces={2}
                                testId="hexagon-reward-percentage"
                                disabled={!lineItem?.changeHexagonRewardPercentage}
                            />
                            <div className={classnames(styles.w10)}>
                                <ToolTip
                                    description=""
                                    id="ttip-change-hexagon-reward-percentage"
                                    className="float-right"
                                >
                                    <i className="fas fa-info-circle tw-text-gray-400" data-testid="ttip-change-hexagon-reward-percentage-icon" />
                                </ToolTip>
                            </div>
                        </div>

                        <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-12">
                            <Button
                                color="primary"
                                outline
                                onClick={onClickCancel}
                                data-testid="btn-cancel"
                            >Cancel
                            </Button>
                            <Button
                                color="primary"
                                onClick={onClickUpdate}
                                data-testid="btn-done"
                            >Done
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ConfirmCancelModal
                isOpen={cancelOpenModal}
                text="Are you sure you want to leave without saving?"
                onConfirm={onConfirmCancel}
                onClose={() => setCancelOpenModal(false)}
            />
        </Modal>
    );
};

export { ReceiptMoreOptionsModal };
