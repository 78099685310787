/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { PostForwardingAddressType } from "@/Apis/PostForwardingAddresses";
import RadioButton from "@/Components/RadioButton";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import { AddressLookupState } from "@/Components/AddressLookup/address.instance";
import { AddressType } from "@/Apis/Addresses";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";
import Spinner from "@/Components/Spinner";

interface Props {
    userId: string,
    isOpen: boolean,
    onClose: () => void,
}

const PrintLabelModal = ({ userId, isOpen, onClose }: Props) => {
    const { get, post, put, postFile, loading } = useAPI({ handle500WithRedirect: true });

    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [postForwardingAddresses, setPostForwardingAddresses] = useState<PostForwardingAddressType[]>([]);
    const [reloadList, setReloadList] = useState(false);

    const [isOnlyTradingAddress, setIsOnlyTradingAddress] = useState<boolean>(false);
    const [postForwardingAddressSelected, setPostForwardingAddressSelected] = useState<PostForwardingAddressType>();
    const [isPostForwardingAddressEdit, setIsPostForwardingAddressEdit] = useState(false);
    const [isPostForwardingAddressNew, setIsPostForwardingAddressNew] = useState(false);
    const [postForwardingAddressNew, setPostForwardingAddressNew] = useState<PostForwardingAddressType>();

    const getPostForwardingAddresses = async () => {
        try {
            const response = await get<PostForwardingAddressType[]>(Urls.postForwardingAddresses.get({ userId }));
            setPostForwardingAddresses(response);
            setIsOnlyTradingAddress(response?.length === 1 && response.some(x => x.isTradingAddress));
            setPostForwardingAddressSelected((response?.length === 1 || !postForwardingAddressSelected)
                ? response[0] : response?.find(x => x.id === postForwardingAddressSelected.id));
        } catch {
            setPopupErrorMessage("Unfortunately an error occurred.", true);
        }
    };

    const onPrintLabel = async () => {
        if (!postForwardingAddressSelected || !postForwardingAddressSelected?.address) {
            setPopupErrorMessage("Please select the address", true);
            return;
        }
        try {
            const response = await postFile(Urls.postForwardingAddresses.getPdf, { address: postForwardingAddressSelected.address });
            const newBlob = new Blob([response.contents], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(newBlob);
            const pdfWindow = window.open(blobUrl);
            pdfWindow?.print();
            setSuccessMessage("Address printed successfully", true);
        } catch {
            setPopupErrorMessage("Unfortunately an error occurred.", true);
        }
    };

    const onCancelEditPostForwardingAddress = () => {
        setIsPostForwardingAddressEdit(false);
    };

    const onUpdatePostForwardingAddress = async (id: string, address: AddressType) => {
        if (!address
            || (isEmptyOrSpaces(address?.premise)
                    && isEmptyOrSpaces(address.addressLine1)
                    && isEmptyOrSpaces(address.postCode))) {
            setPopupErrorMessage("Please input the address", true);
            onCancelEditPostForwardingAddress();
            return;
        }
        try {
            await put(Urls.postForwardingAddresses.update(id), { userId, address });
            setSuccessMessage("Address updated successfully", true);
            setReloadList(!reloadList);
        } catch {
            setPopupErrorMessage("Unfortunately an error occurred.", true);
        } finally {
            onCancelEditPostForwardingAddress();
        }
    };

    const onCancelNewPostForwardingAddress = () => {
        setPostForwardingAddressNew(undefined);
        setIsPostForwardingAddressNew(false);
    };

    const onSaveNewPostForwardingAddress = async (address: AddressType) => {
        if (!address
                || (isEmptyOrSpaces(address?.premise)
                        && isEmptyOrSpaces(address.addressLine1)
                        && isEmptyOrSpaces(address.postCode))) {
            setPopupErrorMessage("Please input the address", true);
            onCancelNewPostForwardingAddress();
            return;
        }
        try {
            await post(Urls.postForwardingAddresses.base, { userId, address });
            setSuccessMessage("Address addded successfully", true);
            setReloadList(!reloadList);
        } catch {
            setPopupErrorMessage("Unfortunately an error occurred.", true);
        } finally {
            onCancelNewPostForwardingAddress();
        }
    };

    const onCloseModal = () => {
        onCancelNewPostForwardingAddress();
        setPostForwardingAddressSelected(undefined);
        setIsPostForwardingAddressEdit(false);
        onClose();
    };

    const onSelectRadio = (data: PostForwardingAddressType) => {
        setPostForwardingAddressSelected(data);
        setIsPostForwardingAddressEdit(false);
    };

    const onClickEdit = () => {
        onCancelNewPostForwardingAddress();
        setIsPostForwardingAddressEdit(true);
    };

    const onClickAddAnother = () => {
        setIsPostForwardingAddressEdit(false);
        setIsPostForwardingAddressNew(true);
    };

    useEffect(() => {
        if (!isOpen) return;
        getPostForwardingAddresses();
        setPostForwardingAddressSelected(undefined);
    }, [reloadList, isOpen]);

    return (
        <Modal
            size="lg"
            centered
            isOpen={isOpen}
        >
            <ModalBody>
                <div className="tw-flex tw-flex-row tw-space-x-3">
                    <div className="tw-w-4/5 tw-text-xl md:tw-text-2xl tw-font-bold">Post Forwarding Address</div>
                    <div className="tw-w-1/5 tw-pb-5 tw-text-right">
                        <Button
                            color="primary"
                            onClick={onClickEdit}
                            className="!tw-font-semibold"
                            disabled={postForwardingAddressSelected?.isTradingAddress
                                || !postForwardingAddressSelected
                                || loading}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
                <div className="tw-overflow-y-auto">
                    {loading && (
                        <Spinner className="tw-mx-auto tw-my-5" />
                    )}
                    { !loading && isOnlyTradingAddress && (
                        <div className="tw-flex tw-flex-row">
                            <FloatLabelAddressLookUp
                                address={postForwardingAddresses[0].address}
                                state={AddressLookupState.ReadOnly}
                                isShowHeading={false}
                            />
                        </div>
                    )}
                    { !loading && !isOnlyTradingAddress && postForwardingAddresses?.map(x => (
                        <div className="tw-flex tw-flex-row tw-space-x-3">
                            { postForwardingAddresses?.length > 1 && (
                                <RadioButton
                                    key={x.id}
                                    id={x.id}
                                    checked={postForwardingAddressSelected?.id === x.id}
                                    onChange={() => onSelectRadio(x)}
                                    label=""
                                    className="!tw-mr-0"
                                />
                            )}
                            <FloatLabelAddressLookUp
                                address={x.address}
                                onSave={(address) => onUpdatePostForwardingAddress(x.id, address)}
                                state={(postForwardingAddressSelected?.id === x.id && isPostForwardingAddressEdit)
                                    ? AddressLookupState.Search
                                    : AddressLookupState.ReadOnly}
                                onCancel={onCancelEditPostForwardingAddress}
                                isShowHeading={false}
                            />
                        </div>
                    ))}
                    { !loading && (
                        <>
                            { !isPostForwardingAddressNew && (
                                <Button
                                    color="transparent"
                                    onClick={onClickAddAnother}
                                    className="!tw-text-brand-primary tw-w-28 !tw-border-solid !tw-border-brand-primary !tw-font-semibold"
                                >
                                    Add another
                                </Button>
                            )}
                            { isPostForwardingAddressNew && (
                                <FloatLabelAddressLookUp
                                    address={postForwardingAddressNew?.address}
                                    onSave={onSaveNewPostForwardingAddress}
                                    state={AddressLookupState.Search}
                                    onCancel={onCancelNewPostForwardingAddress}
                                    isShowHeading={false}
                                />
                            )}
                        </>
                    )}
                </div>
            </ModalBody>
            <ModalFooter
                className="!tw-justify-center md:!tw-justify-start"
            >
                <div className="tw-flex tw-flex-col tw-space-y-3 tw-mx-auto tw-w-2/3 md:tw-flex-row md:tw-space-x-3 md:tw-w-full md:tw-space-y-0">
                    <Button
                        color="primary"
                        onClick={onPrintLabel}
                        className="tw-w-full md:tw-w-28 !tw-font-semibold"
                        disabled={!postForwardingAddressSelected?.id || loading}
                    >
                        Print label
                    </Button>
                    <Button
                        color="transparent"
                        onClick={onCloseModal}
                        className="!tw-text-brand-primary tw-w-full md:tw-w-28 !tw-font-semibold"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default PrintLabelModal;
