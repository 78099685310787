import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { ToolTip } from "@/Components/ToolTip";
import { AccordionHeading } from "@/Components/AccordionHeading";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { FailedPayment } from "@/Apis/Orders/FailedOrders";
import { enumDisplay } from "@/Utils/formatStringHelper";
import Spinner from "@/Components/Spinner";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import formatDateHelper from "@/Utils/formatDateHelper";

const FailedPaymentsBanner = () => {
    const {
        get,
        loading,
    } = useAPI({ handle500WithRedirect: true });
    const {
        put,
        loading: loadingResolvedIssue,
    } = useAPI({ handle500WithRedirect: true });

    const [latestFailedOrder, setLatestFailedOrder] = useState<FailedPayment>();
    const { setPopupErrorMessage } = useToastMessageContext();
    const [reloadFailedPaymentToggle, setReloadFailedPaymentToggle] = useState<boolean>();

    const toggleReloadFailedPayment = () => setReloadFailedPaymentToggle(prev => !prev);

    const forename: string = useSelector<{ user: { forename: string } }>(state => state.user.forename) as string;

    useEffect(() => {
        get<FailedPayment[]>(Urls.orders.failedSubscriptionPayment)
            .then(response => {
                setLatestFailedOrder(response[0]); // The first in the response is most precedent
            });
    }, [reloadFailedPaymentToggle]);

    const { push } = useHistory();

    // Mobile only
    const [moreInformationExpanded, setMoreInformationExpanded] = useState<boolean>(false);

    const toggleMoreInformationExpanded = () => setMoreInformationExpanded(prev => !prev);

    const onResolveIssue = () => {
        if (!latestFailedOrder || !latestFailedOrder?.orderId) {
            setPopupErrorMessage("We cannot mark this order as resolved as we don't have the order id", true);
            return;
        }

        put(Urls.orders.failedSubscriptionOrderResolved, {
            orderId: latestFailedOrder.orderId,
        })
            .then(() => {
                toggleReloadFailedPayment();
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    if (!latestFailedOrder) {
        return <></>;
    }

    return (
        <div className="tw-flex tw-justify-center tw-mb-4">
            {/* Display on large screens */}
            <div
                className="tw-hidden lg:tw-flex tw-flex-row tw-p-4 tw-space-x-5 2xl:tw-space-x-10 tw-justify-center tw-border
                tw-border-red-600 tw-rounded-sm tw-bg-red-100 tw-mt-4"
            >
                <div className="tw-flex tw-flex-col tw-text-base tw-border-r tw-border-r-gray-400 tw-pr-3 tw-justify-center tw-w-1/3">
                    <div className="tw-text-base tw-text-red-600 tw-font-semibold">
                        <i className="fa fa-exclamation-circle tw-text-red-600 tw-text-lg tw-pr-1.5 tw-pt-5" />
                        Payment failed
                    </div>
                    <div>
                        Hi, {forename}! We are unable to process your payment for this billing cycle.
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-pr-3 tw-justify-center">
                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                        <span className="tw-pr-1">REASON</span>
                        <ToolTip
                            description="This is the reason your bank has given our payment processor for rejection.
                            For example ‘insufficient funds’ means there is not enough money in your account to cover the subscription or
                            ‘card declined’ can mean that your card has expired or been blocked. Top up your account or update card details to fix this."
                            id="reason"
                        >
                            <i className="fas fa-info-circle" />
                        </ToolTip>
                    </div>
                    <div className="tw-font-semibold">
                        {latestFailedOrder?.reasonCode ? (
                            enumDisplay(latestFailedOrder.reasonCode)
                                .replaceAll("_", " ")
                        ) : "N/A"}
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-pr-3 tw-justify-center">
                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                        <span className="tw-pr-1">NEXT RETRY ON</span>
                        <ToolTip
                            description="This is the date we will next try to take your payment.
                            Please ensure you have resolved the issue before this date to resolve any issues."
                            id="next-retry-on-info"
                        >
                            <i className="fas fa-info-circle" />
                        </ToolTip>
                    </div>
                    <div className="tw-font-semibold">
                        {latestFailedOrder.nextAttemptDate
                            ? formatDateHelper.format(latestFailedOrder.nextAttemptDate, "DD/MM/YYYY")
                            : "N/A"}
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-pr-3 tw-justify-center">
                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                        <span className="tw-pr-1">RETRIES REMANING</span>
                        <ToolTip
                            description="This is how many times we will retry to take the payment.
                            If you run out of retries your order will be cancelled and you may incur cancellation charges from your supplier.
                            Remember to always tell your supplier if you wish to cancel, repeatedly letting your payment fail can lead to charges. "
                            id="retries-remaining"
                        >
                            <i className="fas fa-info-circle" />
                        </ToolTip>
                    </div>
                    <div className="tw-font-semibold">{latestFailedOrder.attemptsRemaining}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-space-y-2 tw-justify-center">
                    <Button color="primary" onClick={() => push(`/amend-payment-details/${latestFailedOrder?.orderId}`)}>Update payment details</Button>
                    <Button color="transparent" className="!tw-text-brand-primary !tw-font-semibold" onClick={onResolveIssue} disabled={loadingResolvedIssue || loading}>
                        {loadingResolvedIssue || loading ? <Spinner className="tw-mx-auto" size="25" /> : <>I&apos;ve resolved this issue</>}
                    </Button>
                </div>
            </div>
            {/* Display on small screens */}
            <div className="tw-flex lg:tw-hidden tw-border tw-border-red-600 tw-rounded-sm tw-bg-red-100 tw-mt-3 tw-mx-4">
                <div className="tw-flex tw-flex-col tw-text-base tw-p-3">
                    <div className="tw-text-base tw-text-red-600 tw-font-semibold tw-mt-2">
                        <i className="fa fa-exclamation-circle tw-text-red-600 tw-text-lg tw-pr-2 tw-pt-5" />
                        Payment failed
                    </div>
                    <div className="tw-my-2">
                        Hi, {forename}! We are unable to process your payment for this billing cycle.
                        <AccordionHeading
                            onToggle={toggleMoreInformationExpanded}
                            heading="MORE INFORMATION"
                            open={moreInformationExpanded}
                            hideHeading={false}
                            headerClassName="tw-text-red-600 tw-underline tw-text-xs"
                            hideIcon
                        >
                            <div className="tw-flex tw-flex-col tw-space-y-2 tw-justify-around tw-mt-3">
                                <div className="tw-flex tw-flex-row tw-space-x-3">
                                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                                        <span className="tw-pr-1 !tw-text-3xs">REASON</span>
                                        <ToolTip
                                            description="This is the reason your bank has given our payment processor for rejection.
                                            For example ‘insufficient funds’ means there is not enough money in your account to cover the
                                            subscription or ‘card declined’ can mean that your card has expired or been blocked.
                                            Top up your account or update card details to fix this."
                                            id="reason-mobile"
                                        >
                                            <i className="fas fa-info-circle" />
                                        </ToolTip>
                                    </div>
                                    <div className="tw-font-semibold">
                                        {latestFailedOrder?.reasonCode ? (
                                            enumDisplay(latestFailedOrder.reasonCode)
                                                .replaceAll("_", " ")
                                        ) : "N/A"}
                                    </div>
                                </div>
                                <div className="tw-flex tw-flex-row tw-space-x-3">
                                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                                        <span className="tw-pr-1 !tw-text-3xs">NEXT RETRY ON</span>
                                        <ToolTip
                                            description="This is the date we will next try to take your payment.
                            Please ensure you have resolved the issue before this date to resolve any issues."
                                            id="next-retry-on-info-mobile"
                                        >
                                            <i className="fas fa-info-circle" />
                                        </ToolTip>
                                    </div>
                                    <div className="tw-font-semibold">
                                        {latestFailedOrder.nextAttemptDate
                                            ? formatDateHelper.format(latestFailedOrder.nextAttemptDate, "DD/MM/YYYY")
                                            : "N/A"}
                                    </div>
                                </div>
                                <div className="tw-flex tw-flex-row tw-space-x-3">
                                    <div className="tw-text-gray-400 tw-flex tw-flex-row tw-items-center">
                                        <span className="tw-pr-1 !tw-text-3xs">RETRIES REMANING</span>
                                        <ToolTip
                                            description="This is how many times we will retry to take the payment.
                            If you run out of retries your order will be cancelled and you may incur cancellation charges from your supplier.
                            Remember to always tell your supplier if you wish to cancel, repeatedly letting your payment fail can lead to charges. "
                                            id="retries-remaining-mobile"
                                        >
                                            <i className="fas fa-info-circle" />
                                        </ToolTip>
                                    </div>
                                    <div className="tw-font-semibold">{latestFailedOrder.attemptsRemaining}</div>
                                </div>
                            </div>
                        </AccordionHeading>
                    </div>
                    <Button
                        color="primary"
                        onClick={() => push(`/amend-payment-details/${latestFailedOrder?.orderId}`)}
                    >
                        Update payment details
                    </Button>
                    {moreInformationExpanded && (
                        <Button color="transparent" className="!tw-text-brand-primary !tw-font-semibold" onClick={onResolveIssue} disabled={loadingResolvedIssue || loading}>
                            {loadingResolvedIssue || loading ? <Spinner className="tw-mx-auto" size="25" /> : <>I&apos;ve resolved this issue</>}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export { FailedPaymentsBanner };
