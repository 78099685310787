import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Col } from "reactstrap";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";

const CategoryTile = props => (
    <Col sm="12" md="6" lg="4" className="p-2">
        <button type="button" onClick={props.onClick} className={classnames(styles.category, "w-100 h-100 bg-transparent border-0 p-0")} data-testid={props.name}>
            <div className="d-flex h-100 border bg-white box-shadow rounded p-3">
                <div className={styles.imageContainer}>
                    {props.image && <Image src={props.image} className={styles.image} alt="category" />}
                </div>
                <div className="d-flex flex-column flex-grow-1 pl-3">
                    <h4 className="text-left">{props.name}</h4>
                    <p className="text-left">{props.description}</p>
                </div>
            </div>
            <div className={classnames(styles.borderBottom, "rounded-bottom")} />
        </button>
    </Col>
);

CategoryTile.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CategoryTile;
