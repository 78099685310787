export interface ManageParticipantColumns {
    name: string,
    columnSort?: ParticipantManagementColumnSort,
    isFilter?: boolean,
}

export enum ParticipantManagementColumnSort {
    Participant = "fullname",
    NDIS = "ndis",
    DateOfBirth = "dateOfBirth",
    Balance = "balance",
    PlanId = "planId",
    PlanStartDate = "planStartDate",
    PlanEndDate = "planEndDate",
    Status = "status",
    LastStatementSentDate = "dateLastStatementSent",
}
