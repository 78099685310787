import { of } from "rxjs";
import { push } from "connected-react-router";

import globalActions from "@/Store/Global/actions";

// set an error with a referrer. Setting the referrer so on refresh, we can try the url again
export default ({ response, message }) => {
    let error = "";
    if (response && Array.isArray(response.errors)) {
        error = response.errors.join(", ");
    } else if (response && response.errors) {
        error = Object.keys(response.errors).map(x => `${x}: ${response.errors[x]}`);
    } else {
        error = message;
    }

    return of(...[
        push({ pathname: "/error", state: { referrer: window.location.href } }),
        globalActions.setError(error),
    ]);
};
