import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Col, Row, Button } from "reactstrap";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { CSSTransition } from "react-transition-group";

import { useMessages } from "./useMessages";
import styles from "./styles.module.scss";
import animations from "./animations.module.scss";

import NoMessages from "@/Components/Messages/NoMessages";
import MessagingSideBar from "@/Components/Messages/MessagingSideBar";
import MessageFilters, { filters } from "@/Components/Messages/MessageFilters";
import MessageSearch, { FolderAction } from "@/Components/Messages/MessageSearch";
import Checkbox from "@/Components/Checkbox";
import { ClickAwayListener } from "@/Components/ClickAwayListener";
import Spinner from "@/Components/Spinner";
import Footer from "@/Components/Footer";
import { Paging } from "@/Components/Paging";
import { ComposeMessageModal } from "@/Modals/ComposeMessageModal";
import { Folder } from "@/Apis/Messaging";
import formatDateHelper from "@/Utils/formatDateHelper";
import notificationActions from "@/Store/Notifications/actions";

const MessageListingPage = () => {
    const {
        messages,
        loading,
        totalPages,
        messageQuery,
        setMessageQuery,
        actOnSelectedMessages,
    } = useMessages();
    const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const pushToDetails = (currentFolder, id) => dispatch(push({ pathname: `/messages/${currentFolder.toLowerCase()}/${id}` }));
    const pushToFolder = newFolder => dispatch(push(`/messages/${newFolder.toLowerCase()}`));
    const reduceUnreadCount = () => dispatch(notificationActions.reduceUnreadCount());

    useEffect(() => {
        document.title = "BSC - Message Listing";
    }, []);

    useEffect(() => {
        // remove the search term from the url
        if (location.search.indexOf("search") > 0) {
            history.push(location.pathname);
        }
    }, [location.search]);

    const onSearch = (value: string) => {
        setMessageQuery(prevState => ({ ...prevState, currentPage: 0, searchTerm: value }));
    };

    const onPageChanged = (page: number) => {
        setMessageQuery(prevState => ({ ...prevState, currentPage: page }));
    };

    const changeFolder = (newFolder: Folder) => {
        pushToFolder(newFolder);
        setMessageQuery(prevState => ({ ...prevState, currentPage: 0, searchTerm: "", status: "all" }));
    };

    const searchDebounce = AwesomeDebouncePromise(onSearch, 300);

    const moveToFolder = (folderAction: FolderAction) => () => {
        actOnSelectedMessages(folderAction, selectedMessages);
    };

    const onFilterChange = filter => () => {
        setMessageQuery(prevState => ({ ...prevState, status: filter }));
    };

    const onMessageSelect = (id) => () => {
        if (id === filters.all) {
            // if all are already selected, empty the selected array
            if (selectedMessages.length === messages?.length) {
                setSelectedMessages([]);
            } else if (messages !== null) {
                setSelectedMessages(messages.map(x => x.conversationId));
            }
            return;
        }

        let messageList = selectedMessages;
        if (selectedMessages.indexOf(id) > -1) {
            messageList = selectedMessages.filter(x => x !== id);
        } else {
            messageList.push(id);
        }

        setSelectedMessages([...messageList]);
    };

    const displayMenu = (e) => {
        e.stopPropagation();
        setMenuOpen(true);
    };

    const hideMenu = (e) => {
        e.stopPropagation();
        setMenuOpen(false);
    };

    const renderHeader = () => (
        // Do not change the ordering of these columns
        <Row className={classnames(styles.messagesHeader, "d-none d-md-flex py-2 border-bottom align-items-center font-weight-bold")}>
            <Col md="8" className="offset-md-2">
                <Row className="align-items-center">
                    <Col md="3">From</Col>
                    <Col md="3">Enquiry Number</Col>
                    <Col md="4">Subject</Col>
                    <Col md="2" />
                </Row>
            </Col>
        </Row>
    );

    const renderMessages = () => {
        if (loading) {
            return (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <Spinner />
                </div>
            );
        }

        if (messages?.length === 0) {
            return (
                <div className="d-flex justify-content-center align-items-center p-3 p-lg-5">
                    <NoMessages currentFolder={match.params.folder || "inbox"} />
                </div>
            );
        }

        return (
            <div className={classnames(styles.messages, "px-0")} data-testid="message-table">
                {
                    renderHeader()
                }
                {
                    messages && messages
                        .map(x => (
                            <Row
                                key={`${x.conversationId}`}
                                className={classnames(
                                    styles.message,
                                    !x.isRead && styles.unread,
                                    "py-2 py-md-3 border-bottom cursor-pointer flex-nowrap align-items-md-center",
                                )}
                                onClick={() => {
                                    if (!x.isRead) {
                                        reduceUnreadCount();
                                    }
                                    pushToDetails(match.params.folder || "inbox", x.conversationId);
                                }}
                            >
                                <Col xs="1" className="d-flex align-items-center">
                                    <Checkbox
                                        onChange={onMessageSelect(x.conversationId)}
                                        id={x.conversationId}
                                        checked={selectedMessages.includes(x.conversationId)}
                                    />
                                </Col>
                                <Col xs="1" className="d-flex align-items-center">
                                    {x.isEnquiry && <i className="mr-2 text-warning fa fa-question-circle" />}
                                    {x.isUrgent && <i className="text-pink fa fa-info-circle" />}
                                </Col>
                                <Col xs="7" md="8">
                                    <Row className="flex-column flex-md-row">
                                        <Col md="3" className="order-md-1 text-primary">{x.senderRecipients.join(", ")}</Col>
                                        <Col md="4" className={classnames(styles.subject, "order-md-3 py-2 py-md-0 font-weight-bold")}>{x.subject}</Col>
                                        <Col md="3" className={classnames(styles.reference, "order-md-2 overflow-hidden")}>{x.conversationReference}</Col>
                                        <Col md="2" className="order-md-4">{x.attachmentsCount > 0 && <><i className="fa fa-paperclip mr-2" />{x.attachmentsCount}</>}</Col>
                                    </Row>
                                </Col>
                                <Col xs="3" md="2" className={classnames(styles.date, "text-right")}>
                                    {x.sentAt && formatDateHelper.forMessageListing(x.sentAt)}
                                </Col>
                            </Row>
                        ))
                }
            </div>
        );
    };

    return (
        <Row className="flex-column flex-grow-1 flex-md-row bg-white border-bottom mx-0">
            <MessagingSideBar
                folder={match.params.folder || "inbox"}
                changeFolder={changeFolder}
                loading={loading}
                className="d-none d-md-block border-bottom"
            />
            <CSSTransition
                in={menuOpen}
                timeout={300}
                classNames={{
                    enter: `${animations["slide-enter"]}`,
                    enterActive: `${animations["slide-enter-active"]}`,
                    enterDone: `${animations["slide-enter-done"]}`,
                    exit: `${animations["slide-exit"]}`,
                    exitActive: `${animations["slide-exit-active"]}`,
                }}
                unmountOnExit
            >
                <ClickAwayListener onClickAway={hideMenu} className={styles.animations}>
                    <MessagingSideBar
                        folder={match.params.folder || "inbox"}
                        changeFolder={changeFolder}
                        loading={loading}
                        className="d-md-none position-fixed h-100 bg-white"
                    />
                </ClickAwayListener>
            </CSSTransition>

            <Col className="d-flex flex-column flex-shrink-0 border-bottom">
                <div className={classnames(menuOpen && styles.disabled)}>
                    <MessageFilters filterStatus={messageQuery.status} onFilterChange={onFilterChange}>
                        <Button color="text" className="my-md-3 mr-3 d-md-none" onClick={displayMenu}><i className="fas fa-bars" /></Button>
                    </MessageFilters>
                    <MessageSearch
                        allChecked={messages?.length !== 0 && selectedMessages.length === messages?.length}
                        onSelectAll={onMessageSelect(filters.all)}
                        onSearch={searchDebounce}
                        moveToFolder={moveToFolder}
                        currentFolder={match.params.folder || "inbox"}
                        disabled={selectedMessages.length === 0}
                    />

                    {
                        renderMessages()
                    }

                    <div className="d-flex justify-content-center">
                        <Paging onChange={onPageChanged} activePage={messageQuery.currentPage} pageCount={totalPages} />
                    </div>

                    {
                        location.search.indexOf("new") > -1 && (
                            <ComposeMessageModal
                                onClose={() => pushToFolder(match.params.folder)}
                                location={location}
                            />)
                    }
                </div>
            </Col>
            <Footer />
        </Row>
    );
};

export { MessageListingPage };
