import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";

// eslint-disable-next-line import/extensions
import Logo from "@/Assets/Images/invoice-logo.png";

const Footer = () => (
    <>
        <View style={{ backgroundColor: "#0e5a7b",
            width: "100%",
            height: 35,
            position: "absolute",
            bottom: 0,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center" }}
        >
            <Text style={{ color: "#fff", padding: "0, 5" }}>Fulfilled by</Text>
            <Image src={Logo} style={{ width: "auto", height: 20, position: "relative", top: 2.5 }} />
        </View>
    </>
);

export { Footer };
