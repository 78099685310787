import React from "react";
import AsyncSelect from "react-select/async";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { DocumentUser } from "@/Apis/Documents";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";

interface Props {
    value: any,
    onChange: (selectedOption: any) => void,
}

const UsersSearch = ({ value, onChange }: Props) => {
    const { get } = useAPI({ handle500WithRedirect: true });

    const headerOption = {
        isDisabled: true,
        value: undefined,
        label: "Email Address",
        companyName: "Company Name",
    };

    const getUsers = async (searchTerm: string) => {
        if (isEmptyOrSpaces(searchTerm)) {
            return [headerOption];
        }
        try {
            const response = await get<DocumentUser[]>(Urls.documents.getDocumentUsers(searchTerm));
            return [headerOption, ...response?.map(x => ({ value: x.userId, label: x.userEmail, companyName: x.companyName }))];
        } catch (e: any) {
            return [];
        }
    };

    const getUsersDebounced = AwesomeDebouncePromise(getUsers, 500);
    const loadOptions = (searchTerm: string) => getUsersDebounced(searchTerm);

    const formatOptionLabel = (selectOption) => (
        <div className={`tw-grid tw-grid-cols-3 tw-gap-4 ${selectOption.isDisabled && "tw-text-black tw-font-semibold"}`}>
            <div className="tw-col-span-2 tw-break-all">{selectOption.label}</div>
            <div className="tw-col-span-1 tw-break-all">{selectOption.companyName}</div>
        </div>
    );

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            isClearable
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            value={value}
        />
    );
};

export default UsersSearch;
