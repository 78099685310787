import { ReceiptLineItem } from "@/Apis/Receipts/Create";

export enum ReceiptStatus {
    Draft = 1,
    Sent = 2,
    Rejected = 3,
    Accepted = 4,
    Void = 5,
}

export interface Receipt {
    id: string,
    recipient: string,
    referenceNumber: number,
    dateCreated: string,
    value: number,
    status: ReceiptStatus
}

export enum ReceiptStatusFilter {
    All = "All",
    Open = "Open",
    Closed = "Closed",
}

export enum ReceiptManageColumnSort {
    Recipient = "Recipient",
    ReferenceNumber = "ReferenceNumber",
    Date = "Date",
    Value = "Value",
    Status = "Status"
}

export interface ReceiptResponse {
    receipts: Receipt[],
    pageCount: number
}

export interface PreviousPackageResponse {
    lineItems: ReceiptLineItem[]
}

export declare type AcceptRejectPageType = "accept" | "reject";
