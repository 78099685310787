import api from "@/Utils/api";

export interface Referral {
    id: string
    firstName: string
    lastName: string
    dateJoined: string
    referralLink: string
}

export type ReferralTokenStatus = "Valid" | "InvalidToken" | "HasBeenRedeemed";

const getReferrals = () => api.get("referrals") as Promise<{ results: Referral[] }>;

const createReferral = () => api.post("referrals") as Promise<{ link: string }>;

const getReferralDetails = (token: string) => api.post("referrals/details", { token }) as Promise<{ status: ReferralTokenStatus, referredBy: string }>;

export {
    getReferrals,
    createReferral,
    getReferralDetails,
};
