import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import classnames from "classnames";
import { Col, Media, Row, Table } from "reactstrap";
import { Route } from "react-router-dom";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import Spinner from "@/Components/Spinner";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { OrderSearch } from "@/Components/OrderSearch";
import { AdminOrderAuditsModal } from "@/Modals/AdminOrderAuditsModal";
import FilterOrdersModal from "@/Modals/FilterOrdersModal";
import SortOrdersModal from "@/Modals/SortOrdersModal";
import formatDateHelper from "@/Utils/formatDateHelper";
import sortingUtil from "@/Utils/sortingUtil";
import api from "@/Utils/api";
import { pascalCaseToTitleCase } from "@/Utils/formatStringHelper";

class AdminOrdersPage extends React.PureComponent {
    static formatSubscriptionPaymentStatus(subscriptionPaymentStatus, subscriptionPaymentDate) {
        if (!subscriptionPaymentStatus) {
            return "-";
        }

        if (subscriptionPaymentStatus === "InvoicePaymentFailed" || subscriptionPaymentStatus === "InvoicePaymentActionRequired") {
            return (<span className="text-danger font-weight-bold">{pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""))}</span>);
        }

        return subscriptionPaymentStatus === "InvoicePaid"
            ? formatDateHelper.format(subscriptionPaymentDate, "DD/MM/YYYY")
            : pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""));
    }

    state = {
        loading: true,
        orders: [],
        metaData: {},
        sortBy: "orderDate",
        filters: [],
        dateFilter: 3,
        sortModalOpen: false,
        filterModalOpen: false,
        includeSubscriptions: true,
        selected: {},
    };

    componentDidMount() {
        document.title = "BSC - Orders";

        this.getOrdersFromApi();
    }

    getOrdersFromApi = (search) => {
        let url = `admin-orders?dateFilter=${this.state.dateFilter}`;
        url = search ? `${url}&searchTerm=${search}` : url;
        url = this.state.filters ? `${url}&statusFilter=${this.state.filters.join(",")}` : url;
        url += `&includeSubscriptions=${this.state.includeSubscriptions ? "true" : "false"}`;

        api.get(url)
            .then((response) => {
                const selected = response.orders.find(x => x.id === this.props.match.params.id);
                if (!selected) {
                    this.props.pushToAdminListing();
                }
                this.setState({ ...response, selected, loading: false }, this.updateFilters);
            });
    };

    toggleFilter = (value) => {
        if (this.state.filters.includes(value)) {
            this.setState(prevState => ({ filters: prevState.filters.filter(x => x !== value) }), () => {
                this.getOrdersFromApi();
            });
        } else {
            this.setState(prevState => ({ filters: [...prevState.filters, value] }), () => {
                this.getOrdersFromApi();
            });
        }
    };

    setFilter = (value) => {
        this.setState({ dateFilter: value }, () => this.getOrdersFromApi());
    };

    toggleRadioFilter = values => () => {
        this.setState({ filters: [...values] }, () => this.getOrdersFromApi());
    };

    toggleSubscriptions = () => {
        this.setState(prevState => ({ includeSubscriptions: !prevState.includeSubscriptions }), () => this.getOrdersFromApi());
    };

    showSortModal = (shouldShow) => (
        this.setState({
            sortModalOpen: shouldShow,
        })
    );

    showFilterModal = (shouldShow) => (
        this.setState({
            filterModalOpen: shouldShow,
        })
    );

    sortOrders = (key) => {
        this.sort(key)();
    };

    sort = key => () => {
        this.setState((prevState) => {
            if (prevState.sortBy === key) {
                return { sortAscending: !prevState.sortAscending };
            }
            return {
                sortBy: key,
                sortAscending: true,
            };
        });
    };

    onViewDetails = order => () => {
        this.setState({ selected: order }, () => this.props.pushToDetails(order.id));
    };

    updateFilters() {
        const filters = this.state.metaData.statusFilters;
        const allFilters = Object.keys(filters).map(x => filters[x]);

        if (this.state.filters.length === 0) {
            this.setState({ filters: allFilters });
        }
    }

    renderTableHeader = () => (
        <thead>
            <tr>
                <th style={{ width: "20%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0 font-weight-bold"
                        onClick={this.sort("orderReference")}
                    >
                        Order Reference <i className="fa fa-sort" />
                    </button>
                </th>
                <th style={{ width: "20%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0 font-weight-bold"
                        onClick={this.sort("orderDate")}
                    >
                        Order Fulfilled By <i className="fa fa-sort" />
                    </button>
                </th>
                <th style={{ width: "20%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0 font-weight-bold"
                        onClick={this.sort("status")}
                    >
                        Status <i className="fa fa-sort" />
                    </button>
                </th>
                <th style={{ width: "20%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0 font-weight-bold"
                        onClick={this.sort("lastUpdated")}
                    >
                        Last Update <i className="fa fa-sort" />
                    </button>
                </th>
                <th style={{ width: "20%" }}>
                    <button
                        type="button"
                        className="bg-transparent p-0 border-0 font-weight-bold"
                        onClick={this.sort("lastUpdatedBy")}
                    >
                        Last Update By <i className="fa fa-sort" />
                    </button>
                </th>
            </tr>
        </thead>
    );

    renderNoOrders = () => (
        <Col xs="12" className="d-flex flex-column pb-3">
            <Table responsive>
                {this.renderTableHeader()}
            </Table>
            <div className="text-center mb-3 pb-3">
                <Media src={`${window.cdnUrl}misc/calvin-empty-box.png`} alt="No result found" className={classnames(styles.noResultsImage, "mx-auto mt-2")} />
                <h5>Uh-oh, there are no purchases yet.</h5>
            </div>
        </Col>
    );

    render() {
        if (this.state.loading) {
            return (
                <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                    <Spinner className="align-self-center" />
                </div>
            );
        }
        const orders = this.state.orders.sort((x, y) => sortingUtil.sort(x, y, this.state.sortBy, this.state.sortAscending));
        return (
            <>
                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border">
                        <div className="mx-4 my-5">
                            <h4 className="font-weight-bold">Order Audits</h4>
                        </div>
                        <OrderSearch
                            toggleStatusFilter={this.toggleFilter}
                            toggleDateFilter={this.setFilter}
                            onSearch={this.getOrdersFromApi}
                            currentStatusFilters={this.state.filters}
                            currentDateFilter={this.state.dateFilter}
                            dateFilters={this.state.metaData.dateFilters}
                            statusFilters={this.state.metaData.statusFilters}
                            toggleStatusRadio={this.toggleRadioFilter}
                            showSortModal={this.showSortModal}
                            showFilterModal={this.showFilterModal}
                            toggleSubscriptions={this.toggleSubscriptions}
                            includeSubscriptions={this.state.includeSubscriptions}
                        />

                        <Row className="flex-grow-1 d-flex">
                            {orders.length === 0 ? this.renderNoOrders() : (
                                <Col xs="12" className="flex-grow-0">
                                    <Table responsive hover>
                                        {this.renderTableHeader()}
                                        <tbody className="pointer">
                                            {orders.map(x => (
                                                <tr key={x.id} className="border-bottom" onClick={this.onViewDetails(x)}>
                                                    <td className="text-primary text-underline">{x.orderReference}</td>
                                                    <td className="py-2">
                                                        <span className="d-block">{formatDateHelper.format(x.orderDate, "DD/MM/YYYY")}</span>
                                                        <span className={styles.orderTime}>{formatDateHelper.format(x.orderDate, "HH:mm:ss")}</span>
                                                    </td>
                                                    <td><OrderStatusIcon status={x.status} /></td>
                                                    <td className="py-2">
                                                        <span className="d-block">{x.lastUpdated && formatDateHelper.format(x.lastUpdated, "DD/MM/YYYY")}</span>
                                                        <span className={styles.orderTime}>{x.lastUpdated && formatDateHelper.format(x.lastUpdated, "HH:mm:ss")}</span>
                                                    </td>
                                                    <td>{x.lastUpdatedBy}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <FilterOrdersModal
                    isOpen={this.state.filterModalOpen}
                    onClose={() => this.showFilterModal(false)}
                    toggleDateFilter={this.setFilter}
                    dateFilters={this.state.metaData.dateFilters}
                    currentDateFilter={this.state.dateFilter}
                    toggleStatusFilter={this.toggleFilter}
                    statusFilters={this.state.metaData.statusFilters}
                    currentStatusFilters={this.state.filters}
                />

                <SortOrdersModal
                    isOpen={this.state.sortModalOpen}
                    onClose={() => this.showSortModal(false)}
                    onSort={this.sortOrders}
                    selectedKey={this.state.sortBy}
                    ascending={this.state.sortAscending}
                />

                <Route
                    path="/admin/orders/:id/details"
                    render={() => (<AdminOrderAuditsModal
                        id={this.state.selected.id}
                        orderReference={this.state.selected.orderReference}
                        orderFulfilledBy={this.state.selected.supplierName}
                        currentStatus={this.state.selected.status}
                        lastUpdated={this.state.selected.lastUpdated}
                        lastUpdatedBy={this.state.selected.lastUpdatedBy}
                    />)}
                />
                <Footer />
            </>
        );
    }
}

AdminOrdersPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    pushToDetails: PropTypes.func.isRequired,
    pushToAdminListing: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    orderReference: (state.selected || {}).orderReference,
    orderFulfilledBy: (state.selected || {}).supplierName,
    currentStatus: (state.selected || {}).status,
    lastUpdated: (state.selected || {}).lastUpdated,
    lastUpdatedBy: (state.selected || {}).lastUpdatedBy,
});

const mapDispatchToProps = dispatch => ({
    pushToDetails: id => dispatch(push(`/admin/orders/${id}/details`)),
    pushToAdminListing: () => dispatch(push("/admin/orders")),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrdersPage);
