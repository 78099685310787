import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";

import Spinner from "@/Components/Spinner";
import DropZoneButton from "@/Components/Dropzones/DropZoneButton";
import CategoryTile from "@/Components/CategoryTile";
import Modal from "@/Modals/Modal";
import actions from "@/Store/Global/actions";
import * as globalConstants from "@/Store/Global/constants";
import api from "@/Utils/api";
import sortingUtil from "@/Utils/sortingUtil";
import { acceptedFileTypes } from "@/Utils/constants";

export class CategoriesAdminPage extends React.PureComponent {
    state = {
        loading: false,
        showCategoriesModal: false,
        categories: [],
    };

    componentDidMount() {
        document.title = "BSC - Manage Categories";

        api.get("categories")
            .then((response) => {
                this.setState({
                    categories: response,
                });
            });
    }

    addCategory = (e) => {
        e.preventDefault();
        this.setState({ loading: false });
        const url = this.state.id ? `categories/${this.state.id}` : "categories";
        api.post(url, {
            name: this.state.name,
            description: this.state.description,
            image: this.state.image,
        })
            .then((response) => {
                this.setState(prevState => ({
                    loading: false,
                    showCategoriesModal: false,
                    categories: [...prevState.categories.filter(x => x.id !== response.id), response],
                }));
                this.props.showToastMessage();
            })
            .catch((error) => {
                this.setState({ loading: false, error: error.message });
            });
    };

    edit = name => (e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    onNewCategory = () => {
        this.setState({
            showCategoriesModal: true,
            description: "",
            id: "",
            image: "",
            name: "",
        });
    };

    onCategorySelect = category => () => {
        this.setState({
            showCategoriesModal: true,
            ...category,
        });
    };

    render() {
        return (
            <Row className="mt-3 mt-sm-5">
                <Col sm="10" className="offset-sm-1">
                    <div className="d-flex justify-content-between mb-3">
                        <h3>Categories</h3>
                        <Button color="primary" onClick={this.onNewCategory}>Add New</Button>
                    </div>
                    <Row className="px-2">
                        {this.state.categories
                            .filter(x => x.name !== globalConstants.ENQUIRY_FORM)
                            .sort((x, y) => sortingUtil.sort(x, y, "name", true))
                            .map(x => (
                                <CategoryTile
                                    description={x.description}
                                    image={x.image}
                                    name={x.name}
                                    onClick={this.onCategorySelect(x)}
                                />
                            ))}
                    </Row>
                </Col>

                <Modal isOpen={this.state.showCategoriesModal} title="Create Category">
                    <Form onSubmit={this.addCategory}>
                        <FormGroup>
                            <Label>Name *</Label>
                            <Input
                                type="text"
                                placeholder="Name"
                                value={this.state.name || ""}
                                onChange={this.edit("name")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description *</Label>
                            <Input
                                type="textarea"
                                placeholder="Description"
                                value={this.state.description || ""}
                                onChange={this.edit("description")}
                            />
                        </FormGroup>

                        <DropZoneButton
                            onChange={this.edit("image")}
                            showRemoveButton
                            image={this.state.image}
                            accept={acceptedFileTypes.images}
                            noImageText={<p>Please select an image for the category.</p>}
                        />

                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

                        <div className="d-flex justify-content-center mb-5 mt-3 pt-4">
                            <Button color="danger" className="mr-2" onClick={() => { this.setState({ showCategoriesModal: false }); }}>Cancel</Button>
                            {
                                this.state.loading
                                    ? <Spinner />
                                    : <Button color="primary" type="submit">Save</Button>
                            }
                        </div>
                    </Form>
                </Modal>
            </Row>
        );
    }
}

CategoriesAdminPage.propTypes = {
    showToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    showToastMessage: () => dispatch(actions.setToastMessage(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesAdminPage);
