/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

import MarketPlace from "@/Assets/Icons/Coloured/marketplace.svg";
import MessagesIcon from "@/Assets/Icons/Coloured/messaging.svg";
import PackageIcon from "@/Assets/Icons/Coloured/package-code.svg";
import DataIcon from "@/Assets/Icons/Coloured/build-a-package.svg";
import CloseButton from "@/Components/CloseButton";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { useOnboardingContext } from "@/Context/OnboardingContext";
import userActions from "@/Store/User/actions";

const welcomeDesc = "Find small business help, support and products or services on Business Support Club.";
const marketplaceDesc = "Search our marketplace to find accountants, branding partners, legal specialists and much more.";
const messagesDesc = "Contact your suppliers directly, network and forge new relationships online.";
const packagesDesc = "Monetise your network. Simply build a package, pass it on and earn referral commissions.";
const dataDesc = "Keep your company data to hand, in one place and connect with share with new suppliers easily and quickly.";

const WelcomeModal = ({ close }) => {
    const { put, loading } = useAPI({ handle500WithRedirect: true });
    const { loadOnBoarding } = useOnboardingContext();
    const dispatch = useDispatch();
    useEffect(() => {
        put("profile/onboard", {})
            .then(() => dispatch(userActions.loadUser$()));
    }, []);

    const onVisitMarketplace = () => {
        close();
        loadOnBoarding();
    };
    const [currentStep, setCurrentStep] = useState(0);
    const nextPage = () => {
        setCurrentStep(currentStep + 1);
    };

    const renderCardContent = (Image, title, description) => (
        <div className="d-flex flex-lg-column justify-content-center align-items-center text-lg-center pb-4">
            {
                Image && (
                    <div className={classnames(styles.imageContainer, "rounded-circle bg-light mb-2")}>
                        <Image />
                    </div>
                )
            }
            <div className="pl-3 pb-lg-0 pl-lg-0 w-100">
                <h5 className="font-weight-bold">{title}</h5>
                <div>{description}</div>
            </div>
        </div>
    );

    const renderLeaveButtons = () => (
        <div className={classnames(`${currentStep === 1 && "d-flex w-sm-75"}`, "d-none d-lg-flex flex-column justify-content-around my-4 mx-lg-auto mx-4")}>
            <Button color="primary" onClick={onVisitMarketplace}>
                Visit the Marketplace
            </Button>
            <Link to="/dashboard" className="text-center text-dark mt-3"><strong><small>GO TO THE HOMEPAGE</small></strong></Link>
        </div>
    );

    const renderCards = () => (
        <>
            <div className="d-flex flex-lg-column align-items-center text-lg-center pt-4 h-100 m-3 m-lg-0">
                <div className={classnames(currentStep === 0 && "d-flex", "d-none d-lg-flex flex-column text-center justify-content-center align-items-center mt-5 mt-lg-0 mb-3")}>
                    <div className="d-lg-none rounded-circle bg-light mb-2 d-flex justify-content-center align-items-center">
                        <img src={`${window.cdnUrl}misc/logo.jpg`} alt="logo" className={classnames(styles.logoContainer)} />
                    </div>
                    <h4 className="d-none d-lg-block text-primary mt-4 mb-1 mx-5"><strong>Welcome to Business Support Club</strong></h4>
                    <h4 className="d-lg-none text-primary mt-4 mb-1 mx-5"><strong>Welcome to</strong></h4>
                    <h4 className="d-lg-none text-primary mx-5"><strong>Business Support Club</strong></h4>
                    <div className="mx-5 px-2">{welcomeDesc}</div>
                </div>
                <div className={classnames(currentStep === 1 && "d-block", "d-none d-lg-flex justify-content-around flex-grow-0")}>
                    <div className={classnames(styles.cardContainer, "px-lg-4 py-lg-2 m-lg-1 ml-lg-3 bg-white")}>
                        {renderCardContent(MarketPlace, "Marketplace", marketplaceDesc)}
                    </div>
                    <div className={classnames(styles.cardContainer, "px-lg-4 py-lg-2 m-lg-1 bg-white")}>
                        {renderCardContent(MessagesIcon, "Messages", messagesDesc)}
                    </div>
                    <div className={classnames(styles.cardContainer, "px-lg-4 py-lg-2 m-lg-1 bg-white")}>
                        {renderCardContent(PackageIcon, "Packages", packagesDesc)}
                    </div>
                    <div className={classnames(styles.cardContainer, "px-lg-4 py-lg-2 m-lg-1 mr-lg-3 bg-white")}>
                        {renderCardContent(DataIcon, "Data", dataDesc)}
                    </div>
                </div>
            </div>

            <Button color="primary" onClick={nextPage} className={classnames(currentStep === 1 && "d-none", "d-lg-none mx-4 my-5")}>
                Continue
            </Button>
            {renderLeaveButtons()}
        </>
    );

    return (
        <Modal
            isOpen
            centered
            size="lg"
            modalClassName={styles.modalClassName}
            className="my-0"
            contentClassName={classnames(styles.fullscreenModal)}
        >
            <CloseButton className="d-lg-none font-weight-bold" onClick={close}><strong className="d-lg-none">CLOSE</strong></CloseButton>
            <CloseButton className="d-none d-lg-block" onClick={close} />
            {loading && (<div className="d-flex pt-4 justify-content-center align-items-center"><Spinner /></div>)}
            {!loading && renderCards()}
        </Modal>
    );
};

WelcomeModal.propTypes = {
    close: PropTypes.func.isRequired,
};

export default WelcomeModal;
