import React from "react";
import PropTypes from "prop-types";

import { FieldTransferSelectorService } from "@/Components/FieldTransferSelectorService";

const Forms = ({ fields, serviceFields = [], onChange, isEnquiry }) => (
    <div className="d-flex flex-column flex-grow-1 h-100">
        <div>
            <h5 className="font-weight-bold pt-4 mb-3">Required fields from the user</h5>
            <p>
                Please select fields that you will require from the user when purchasing this service.
                If you would like a custom field, please add one using the button below.
            </p>
        </div>

        <FieldTransferSelectorService fields={fields} selectedFields={serviceFields} onChange={onChange} isEnquiry={isEnquiry} />
    </div>
);

Forms.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayText: PropTypes.string.isRequired,
        ordinal: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    serviceFields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    })),
    onChange: PropTypes.func.isRequired,
    isEnquiry: PropTypes.bool.isRequired,
};

export { Forms };
