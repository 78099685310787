import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useHistory, useParams } from "react-router";

import { ParticipantProfileType, Plan } from "../ParticipantProfile/Types";

import { Paging } from "@/Components/Paging";
import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import formatDateHelper from "@/Utils/formatDateHelper";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import Spinner from "@/Components/Spinner";

const ParticipantPlans = () => {
    const [participantState, setParticipantState] = useState<ParticipantProfileType>();
    const { id } = useParams();
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const {
        setItemsForPagination,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
    } = useClientSidePagination<Plan>();

    useEffect(() => {
        get<ParticipantProfileType>(FlexPlanUrls.participants.getSingle(id))
            .then(response => {
                setParticipantState({
                    ...response,
                    fullname: `${response.firstName} ${response.lastName}`,
                });
                setItemsForPagination(response.plans);
            });
    }, [id]);

    const { push } = useHistory();

    const onViewServiceBookings = (planId: number) => () => {
        push(`/manage-service-bookings/${planId}`);
    };

    const onCreateServiceBooking = (planId: number) => () => {
        push({
            pathname: `/manage-service-bookings/${planId}`,
            state: { isCreate: true },
        });
    };

    return (
        <FlexPlanBasicWrapper title={`Plans for ${participantState?.fullname}`} className="tw-flex tw-flex-col tw-space-y-3">
            <Table responsive hover>
                <thead>
                    <tr>
                        <th style={{ width: "11.1%" }}>
                            PlanID&nbsp;
                        </th>
                        <th style={{ width: "11.1%" }}>
                            Start Date&nbsp;
                        </th>
                        <th style={{ width: "11.1%" }}>
                            End Date&nbsp;
                        </th>
                        <th style={{ width: "11.1%" }}>
                            &nbsp;
                        </th>
                        <th style={{ width: "11.1%" }}>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={9}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                        </tr>
                    )}
                    {paginatedItems && (
                        paginatedItems.map(p => (
                            <tr key={p.id}>
                                <td>
                                    {p.ndisParticipantPlanId}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.planStartDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.planEndDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="!tw-text-flexplan-secondary hover:tw-underline hover:!tw-text-brand-primary"
                                        onClick={onViewServiceBookings(p.ndisParticipantPlanId)}
                                    >
                                        View/Edit Service Booking
                                    </button>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="!tw-text-flexplan-secondary hover:tw-underline hover:!tw-text-brand-primary"
                                        onClick={onCreateServiceBooking(p.ndisParticipantPlanId)}
                                        color="transparent"
                                    >
                                        Create New Service Booking
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
        </FlexPlanBasicWrapper>
    );
};

export default ParticipantPlans;
