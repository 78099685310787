import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import { enumDisplay } from "@/Utils/formatStringHelper";
import { DropZoneFile } from "@/Components/Dropzones/DropZoneMulti";
import { FlexPlanOrderStatus, PaymentRequestStatus } from "@/FlexPlan/Types/Order";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import FormatDateHelper from "@/Utils/formatDateHelper";
import { PreviousOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Types/previousOrder";
import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { FlexPlanSupplierDropdownType, Order, OrderRow } from "@/FlexPlan/Pages/Orders/OrderDetails/Types";
import { failedRows, isSubmittedOrder, openRows } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { newOrderItem } from "@/FlexPlan/Pages/Orders/OrderDetails/Hooks/newOrderHelper";
import { useForm } from "@/Hooks/useForm";
import { deepEqual } from "@/Utils/objectHelper";
import { isValidDate } from "@/Utils/stringHelper";
import { toTwoDecimalPlaces } from "@/FlexPlan/Utils/currency";

export interface OrderDetailsContextState {
    onAddRow: () => void,
    formState: Order,
    onChange: (field: keyof Order) => (e: ChangeEvent<HTMLInputElement>) => void,
    orderStatus: { label: string, value: any }[],
    onChangeRow: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void,
    onSelectAll: (checked: boolean) => void,
    onAddDocument: (files: EncodedFile[]) => Promise<void>,
    onDeleteDocument: (file: DropZoneFile) => Promise<void>,
    selectAllChecked: boolean,
    onCheckBoxOnRow: (id: string) => (checked: boolean) => void,
    onGstCheckBoxOnRow: (id: string) => (checked: boolean) => void,
    onDeleteRows: () => void,
    onSelectItemCode: (id: string, description: string, startDate: string, endDate: string, rate: number, quantity: number, gst: boolean, total: number) => void,
    onSaveOrder: (redirect: boolean) => Promise<{ success: boolean }>;
    onDateTextBoxChange: (field: keyof OrderRow, itemId: string) => (value: any) => void;
    onPreviousOrderSelect(prevOrder: PreviousOrder): void
    onSubmitClaim: () => void,
    onGetPaymentStatusForOrder: () => void,
    setFormState: Dispatch<SetStateAction<Order>>,
    orderLoaded?: boolean,
    allowOrderTemplate?: boolean,
    updatingPaymentStatus: boolean,
    savingOrder: boolean,
    submittingPayment: boolean,
    disableButtons: boolean,
    setContextOrder: (id: string) => void,
    initialOrderStatus: FlexPlanOrderStatus | undefined,
    invalidFields: any,
    onReactSelectChange: (field: keyof Order) => (e: { value: any; }) => void,
    removeRowErrorField: (rowId: string, field: string) => void,
    setSupplierList: Dispatch<SetStateAction<FlexPlanSupplierDropdownType[]>>,
    supplierList: FlexPlanSupplierDropdownType[],
    newOrder: boolean,
}

export const initialState: OrderDetailsContextState = {
    formState: {} as Order,
    orderStatus: [{ label: "", value: {} }],
    selectAllChecked: false,
    orderLoaded: false,
    allowOrderTemplate: false,
    updatingPaymentStatus: false,
    savingOrder: false,
    submittingPayment: false,
    disableButtons: false,
    invalidFields: {},
} as OrderDetailsContextState;

const FlexPlanOrderContext = React.createContext<OrderDetailsContextState>(initialState);

export const useFlexPlanOrderContext = () => useContext(FlexPlanOrderContext);

interface Props {
    children: React.ReactNode,
}

const FlexPlanOrderDetailsProvider = ({ children }: Props) => {
    const {
        state: newOrder,
        pathname,
    } = useLocation<Order | undefined>();

    const {
        setInvalidField,
        invalidFields,
        removeInvalidField: removeErrorField,
        setApiValidationErrors,
        setFormState,
        formState,
        onReactSelectChange,
    } = useForm<Order>({ items: [] as OrderRow[] } as Order);

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const history = useHistory();
    const {
        post: saveOrderApi,
        loading: savingOrder,
    } = useAPI({ handle500WithToastMessage: true });
    const { get: getOrderApi } = useAPI({ handle500WithToastMessage: true });
    const {
        post: submitClaimApi,
        put: updatePaymentStatusApi,
    } = useAPI({ handle500WithToastMessage: true });
    const [updatingPaymentStatus, setUpdatingPaymentStatus] = useState<boolean>(false);
    const [submittingPayment, setSubmittingPayment] = useState<boolean>(false);
    const {
        setSuccessMessage,
        setPopupErrorMessage,
    } = useToastMessageContext();
    const [orderId, setOrderId] = useState<string>();
    const [supplierList, setSupplierList] = useState<FlexPlanSupplierDropdownType[]>([]);

    const setContextOrder = (id: string) => setOrderId(id); // We need to set the order from the page component as we useParam() returns null for the provider

    const getOrder = (id: string, onOrderLoaded: (response: Order) => void) => {
        getOrderApi<Order>(FlexPlanUrls.orders.get(id))
            .then((response) => {
                const responseData: Order = {
                    ...response,
                    orderLoaded: true,
                    allowOrderTemplate: false,
                    items: response.items?.map((x, i) => ({
                        ...x,
                        position: i + 1,
                        invalidFields: {},
                    })) ?? [],
                };
                onOrderLoaded(responseData);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    const [initialOrderStatus, setInitialOrderStatus] = useState<FlexPlanOrderStatus>();

    useEffect(() => {
        if (!newOrder && orderId) { // "edit mode"
            getOrder(orderId, response => {
                setFormState({
                    ...response,
                    invoiceTotal: toTwoDecimalPlaces(response.invoiceTotal),
                    items: response.items.map(item => ({
                        ...item,
                        quantity: toTwoDecimalPlaces(item.quantity),
                        rate: toTwoDecimalPlaces(item.rate),
                        total: toTwoDecimalPlaces(item.total),
                        amountApproved: toTwoDecimalPlaces(item.amountApproved),
                    })),
                });
                setInitialOrderStatus(response.orderStatus);
            });
        } else if (newOrder) {
            setFormState(newOrder);
        }
    }, [pathname, orderId]);

    const onChange = (field: keyof Order) => (e: ChangeEvent<HTMLInputElement> | any) => {
        setFormState(formState => ({
            ...formState, // eslint-disable-next-line no-nested-ternary
            [field]: e.target.value,
        }));

        if (field === "orderStatus" && e.target.value as FlexPlanOrderStatus !== "New" && invalidFields.orderStatus) {
            removeErrorField("orderStatus");
        }

        if (field !== "orderStatus") {
            removeErrorField(field);
        }
    };

    const onAddRow = () => {
        setFormState(prevFormState => {
            const position = prevFormState.items.length + 1;
            const newRows = [...prevFormState.items, newOrderItem(position) as OrderRow];
            return {
                ...prevFormState,
                items: newRows,
            };
        });
    };

    const onSelectItemCode = (id: string, description: string, startDate: string, endDate: string, rate: number, quantity: number, gst: boolean, total: number) => {
        setFormState(prevFormState => {
            const targetRow = prevFormState.items.find(xx => xx.id === id);
            const otherRows = prevFormState.items.filter(x => x.id !== id);

            if (!targetRow || !otherRows) return prevFormState;

            return {
                ...prevFormState,
                items: [
                    ...otherRows,
                    {
                        ...targetRow,
                        itemDescription: description,
                        startDate,
                        endDate,
                        quantity,
                        rate,
                        gst,
                        total,
                    },
                ],
            };
        });
    };

    const orderStatus = Object.keys(FlexPlanOrderStatus)
        .map(x => ({
            label: enumDisplay(FlexPlanOrderStatus[x]),
            value: FlexPlanOrderStatus[x],
        }));

    const removeRowErrorField = (rowId: string, field: string) => setFormState(formState => {
        const item = formState.items.find(x => x.id === rowId);

        if (!item) return formState;

        delete item?.invalidFields[field];

        return {
            ...formState,
            items: [...formState.items.filter(item => item.id !== rowId), item],
        };
    });

    const onDateTextBoxChange = (field: keyof OrderRow, itemId: string) => (value) => {
        const newDate = value ? FormatDateHelper.format(value, "yyyy-MM-DDTHH:mm:ss") : "";

        setFormState(prevState => ({
            ...prevState,
            items: [...prevState.items.filter(x => x.id !== itemId),
                {
                    ...prevState.items.find(x => x.id === itemId)!,
                    [field]: newDate,
                },
            ],
        }));
        removeRowErrorField(itemId, field);
        removeRowErrorField(itemId, "startDatePrecedesEndDate");
    };

    const onGstCheckBoxOnRow = (id: string) => (checked: boolean) => {
        setFormState(prevFormState => {
            const targetRow = prevFormState.items.find(x => x.id === id);
            const otherRows = prevFormState.items.filter(x => x.id !== id);

            if (!targetRow || !otherRows) return prevFormState;

            return {
                ...prevFormState,
                items: [
                    ...otherRows,
                    {
                        ...targetRow,
                        isGst: checked,
                    },
                ],
            };
        });

        removeRowErrorField(id, "isGst");
    };

    const onCheckBoxOnRow = (id: string) => (checked: boolean) => {
        setFormState(prevFormState => {
            const newState = { ...prevFormState };
            newState.items.find(x => id === x.id)!.isChecked = checked;
            return newState;
        });
    };

    const onChangeRow = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
        setFormState(prevFormState => {
            const targetRow = prevFormState.items.find(x => x.id === id);
            const otherRows = prevFormState.items.filter(x => x.id !== id);

            if (!targetRow || !otherRows) {
                return prevFormState;
            }

            return {
                ...prevFormState,
                items: [
                    ...otherRows,
                    {
                        ...targetRow,
                        [e.target.name]: e.target.value,
                    },
                ],
            };
        });

        removeRowErrorField(id, e.target.name);
    };

    const onSelectAll = (checked: boolean) => {
        setFormState(prev => ({
            ...prev,
            items: prev.items.map(item => ({
                ...item,
                isChecked: (item.status === PaymentRequestStatus.NoStatus || failedRows(item)) && checked,
            })),
        }));

        setSelectAllChecked(checked);
    };

    const onAddDocument = async (files: EncodedFile[]) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.concat(files),
        }));
    };

    const onDeleteDocument = async (file: DropZoneFile) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.filter(x => x.name !== file.fileName),
        }));
    };

    const onDeleteRows = () => {
        setFormState(formState => ({
            ...formState,
            items: formState.items.filter(x => !x.isChecked),
        }));
    };

    const onSaveOrderValidation = (): boolean => {
        let failed: boolean = false;

        if (formState.orderStatus as FlexPlanOrderStatus === "New" && isSubmittedOrder(formState)) {
            setInvalidField("orderStatus", "Submitted orders cannot be changed to status New");
            failed = true;
        }

        const itemRows: OrderRow[] = [];

        formState.items.forEach(item => {
            const invalidFields: any = { ...item.invalidFields };

            if (item.startDate && !isValidDate(item.startDate)) {
                invalidFields.startDate = "Invalid date";
            }

            if (item.endDate && !isValidDate(item.endDate)) {
                invalidFields.endDate = "Invalid date";
            }

            // Validate start date precedes end date
            if (item.startDate && item.endDate
                && !invalidFields.startDate && !invalidFields.endDate
                && new Date(item.startDate) > new Date(item.endDate)) {
                invalidFields.startDatePrecedesEndDate = "Start date must precede end date";
            }

            itemRows.push({
                ...item,
                invalidFields,
            });

            if (!deepEqual(invalidFields, {})) {
                failed = true;
            }
        });

        setFormState(formState => ({
            ...formState,
            items: itemRows,
        }));

        return failed;
    };

    const onSubmitPaymentValidation = (): boolean => {
        let failed: boolean = false;
        const itemRows: OrderRow[] = [];

        formState.items.forEach(item => {
            const invalidFields: any = { ...item.invalidFields };

            if (!item.itemCode) {
                invalidFields.itemCode = "Item code is required";
            }

            if (item.quantity === 0) {
                invalidFields.quantity = "Quantity cannot be 0";
            }

            itemRows.push({
                ...item,
                invalidFields,
            });

            if (!deepEqual(invalidFields, {})) {
                failed = true;
            }
        });

        setFormState(formState => ({
            ...formState,
            items: itemRows,
        }));

        return failed;
    };

    const onSaveOrder = (redirect: boolean): Promise<{ success: boolean }> => {
        if (onSaveOrderValidation()) { // Error
            return Promise.resolve({ success: false });
        }

        return saveOrderApi(FlexPlanUrls.orders.createOrder, {
            ...formState,
            items: formState.items.map(x => ({
                ...x,
                startDate: x.startDate ? x.startDate : null,
                endDate: x.endDate ? x.endDate : null,
            })),
        })
            .then(() => {
                if (redirect) {
                    setSuccessMessage("Order saved successfully.", true);
                    const matchingSupplier = supplierList.find(x => x.id === formState.supplierId);
                    const supplierHasBankDetails = matchingSupplier
                        && matchingSupplier.bankName
                        && matchingSupplier.accountName
                        && matchingSupplier.bsb
                        && matchingSupplier.accountNumber;

                    history.push("/supplier-orders", { supplierMissingBankDetails: !supplierHasBankDetails, supplierId: matchingSupplier?.id });
                }
                return { success: true };
            })
            .catch(error => {
                if (error.validationFailed) {
                    setApiValidationErrors(error);
                }

                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
                return { success: false };
            });
    };

    const onSubmitClaim = () => {
        const saveOrderValidationError: boolean = onSaveOrderValidation();

        if (saveOrderValidationError) {
            return;
        }

        const paymentValidationError: boolean = onSubmitPaymentValidation();

        if (paymentValidationError) {
            return; // We will be displaying error messages
        }

        setSubmittingPayment(true);

        // first save changes
        onSaveOrder(false)
            .then(response => {
                if (!response.success) {
                    // Saving failed, the errors should have been displayed
                    setSubmittingPayment(false);
                    return;
                }

                // Then submit claim for each order item
                Promise.all(formState.items.map(orderItem => (submitClaimApi(FlexPlanUrls.orders.submitClaim(orderItem.id), {}))))
                    .then(() => {
                        setSuccessMessage("Claim submitted successfully.", true);
                        history.push("/supplier-orders");
                    })
                    .catch(error => {
                        if (error.validationFailed) {
                            setApiValidationErrors(error);
                        } else if (typeof error === "string") {
                            setPopupErrorMessage(error, true);
                        } else if (error.message) {
                            setPopupErrorMessage(error.message, true);
                        }
                    })
                    .finally(() => setSubmittingPayment(false));
            });
    };

    const onGetPaymentStatusForOrder = () => {
        setUpdatingPaymentStatus(() => true);
        Promise.all(formState.items.map(orderItem => (updatePaymentStatusApi(FlexPlanUrls.orders.updatePaymentStatus(orderItem.id), {}))))
            .then(() => {
                getOrder(formState.id, response => {
                    setFormState(response);
                    setSuccessMessage("Payment Request Statuses updated.", true);
                    setUpdatingPaymentStatus(() => false);
                });
            })
            .catch(error => {
                if (error.validationFailed) {
                    setPopupErrorMessage(Object.keys(error.errors)
                        .map(x => error.errors[x])
                        .join(", "), true);
                    setUpdatingPaymentStatus(() => false);
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                    setUpdatingPaymentStatus(() => false);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                    setUpdatingPaymentStatus(() => false);
                }
            });
    };

    // We need to load all of the details for the previous order
    const onPreviousOrderSelect = (prevOrder: PreviousOrder) => {
        setUpdatingPaymentStatus(true);
        getOrder(prevOrder.id, response => {
            const newLineItems = response?.items?.filter(openRows)?.length > 0
                ? response?.items?.filter(openRows)
                    ?.map((x, i) => ({
                        ...x,
                        id: v4(),
                        claimNumber: null,
                        itemCode: x.itemCode,
                        description: x.description,
                        startDate: "",
                        endDate: "",
                        quantity: toTwoDecimalPlaces(x.quantity),
                        rate: toTwoDecimalPlaces(x.rate),
                        isGst: x.isGst,
                        amountApproved: toTwoDecimalPlaces(0),
                        status: PaymentRequestStatus.NoStatus,
                        isChecked: false,
                        position: i + 1,
                        dateCreated: null,
                        refDocNo: v4(),
                    } as OrderRow)) : [newOrderItem(1)];
            setFormState(() => ({
                ...response,
                allowOrderTemplate: true,
                id: v4(),
                orderStatus: FlexPlanOrderStatus.New,
                orderNumber: undefined,
                invoiceNumber: undefined,
                invoiceTotal: toTwoDecimalPlaces(0),
                runningTotal: toTwoDecimalPlaces(0),
                approvedRunningTotal: toTwoDecimalPlaces(0),
                claimNotes: "",
                supportingDocuments: [],
                previousOrderSelected: prevOrder,
                dateCreated: null,
                items: newLineItems,
            }));
            setUpdatingPaymentStatus(() => false);
        });
    };

    return (
        <FlexPlanOrderContext.Provider value={{
            onAddRow,
            formState,
            orderLoaded: formState.orderLoaded,
            allowOrderTemplate: formState.allowOrderTemplate,
            onChange,
            orderStatus,
            onChangeRow,
            onSelectAll,
            onAddDocument,
            onDeleteDocument,
            selectAllChecked,
            onCheckBoxOnRow,
            onGstCheckBoxOnRow,
            onDeleteRows,
            onSelectItemCode,
            onSaveOrder,
            onDateTextBoxChange,
            onPreviousOrderSelect, // When we select a previous order
            onSubmitClaim,
            onGetPaymentStatusForOrder,
            updatingPaymentStatus,
            setFormState,
            savingOrder,
            submittingPayment,
            disableButtons: !formState.orderLoaded || updatingPaymentStatus || savingOrder || submittingPayment,
            setContextOrder,
            initialOrderStatus,
            invalidFields,
            onReactSelectChange,
            removeRowErrorField,
            setSupplierList,
            supplierList,
            newOrder,
        }}
        >
            {children}
        </FlexPlanOrderContext.Provider>
    );
};

export { FlexPlanOrderDetailsProvider };
