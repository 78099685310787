import React from "react";
import { createInlineStyleButton, createBlockStyleButton } from "draft-js-buttons";

import styles from "./styles.module.scss";

// Custom icon in theme https://github.com/draft-js-plugins/draft-js-plugins/issues/1216

const BoldButton = createInlineStyleButton({
    style: "BOLD",
    children: (<i className="fa fa-bold" />),
});

const ItalicButton = createInlineStyleButton({
    style: "ITALIC",
    children: (<i className="fa fa-italic" />),
});

const UnderlineButton = createInlineStyleButton({
    style: "UNDERLINE",
    children: (<i className="fa fa-underline" />),
});

const StrikeThroughButton = createInlineStyleButton({
    style: "STRIKETHROUGH",
    children: (<i className="fa fa-strikethrough" />),
});

const ParagraphButton = createBlockStyleButton({
    blockType: "paragraph",
    children: (<i className="fa fa-paragraph" />),
});

const HeadingOneButton = createBlockStyleButton({
    blockType: "header-one",
    children: (<span className="font-weight-bold h6">H1</span>),
});

const HeadingTwoButton = createBlockStyleButton({
    blockType: "header-two",
    children: (<span className="font-weight-bold h6">H2</span>),
});

const HeadingThreeButton = createBlockStyleButton({
    blockType: "header-three",
    children: (<span className="font-weight-bold h6">H3</span>),
});

const UnorderedListButton = createBlockStyleButton({
    blockType: "unordered-list-item",
    children: (<i className="fa fa-list-ul" />),
});

const OrderedListButton = createBlockStyleButton({
    blockType: "ordered-list-item",
    children: (<i className="fa fa-list-ol" />),
});

const BlockquoteButton = createBlockStyleButton({
    blockType: "blockquote",
    children: (<i className="fa fa-quote-right" />),
});

const Separator = () => (<div className={styles.separator} />);

export {
    BoldButton,
    ItalicButton,
    UnderlineButton,
    StrikeThroughButton,
    ParagraphButton,
    Separator,
    HeadingOneButton,
    HeadingTwoButton,
    HeadingThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
};
