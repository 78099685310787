import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import classnames from "classnames";
import { Carousel, CarouselControl, CarouselItem } from "reactstrap";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState } from "@/Components/RichTextEditor/Utils";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { ProductType } from "@/Apis/Products/ProductType";

export const PartnerProductDetailsPage = () => {
    const [product, setProduct] = useState<ProductType | null>(null);
    const { loading, get } = useAPI({ handle500WithRedirect: true });
    const match = useRouteMatch<{ packageCode: string, productId: string }>();
    const [activeIndex, setActiveIndex] = useState(0);
    const imagesIndexLength = product?.images && product?.images.length > 0 ? product.images.length - 1 : 0;

    const next = () => {
        setActiveIndex(activeIndex === imagesIndexLength ? 0 : activeIndex + 1);
    };

    const previous = () => {
        setActiveIndex(activeIndex === 0 ? imagesIndexLength : activeIndex - 1);
    };

    useEffect(() => {
        get<ProductType>(`products/${encodeURI(match.params.productId)}`)
            .then((response) => setProduct(response));
    }, [match.params.productId]);

    const renderDetails = () => (
        <>
            <div className="d-flex">
                <div className="mr-2 flex-grow-1">
                    <h5><strong>{product?.name}</strong></h5>
                    <div>
                        <small><strong>{product?.categoryName} / {product?.subCategoryName}</strong></small>
                    </div>
                    <div>
                        <small>
                            <strong className="text-primary">
                                {product?.supplierName}
                            </strong>
                        </small>
                    </div>
                </div>
                <div>
                    <ServiceTilePriceSection
                        initialChargeWithVatIfApplicable={product?.initialChargeWithVatIfApplicable || 0}
                        paymentFrequency={product?.paymentFrequency || ""}
                        postageCharge={product?.postageCharge}
                        disableAddService
                        addQuantity={false}
                        containerClassNames="bg-transparent"
                    />
                </div>
            </div>
            <hr />
            {product?.description && <p className="mt-4">{product?.description}</p>}
        </>
    );

    const renderCarousel = () => (
        <>
            {product?.image && (<Image src={product?.image} alt={product?.name} className="w-100 w-lg-auto my-auto" />)}
            {
                product?.images && product?.images.length > 0 && (
                    <Carousel
                        className={classnames(styles.imageContainer, "mb-4")}
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        {
                            product.images.map(item => (
                                <CarouselItem key={item.name}>
                                    <Image
                                        src={item.data}
                                        alt={item.name}
                                        className="w-100 w-lg-auto my-auto"
                                    />
                                </CarouselItem>
                            ))
                        }
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                        />
                    </Carousel>
                )
            }
            {product?.moreInformation && (<RichTextEditor editorState={createEditorState(product.moreInformation)} readonly showToolbar={false} />)}
        </>
    );

    return (
        <>
            {
                loading
                    ? <div className="h-100 d-flex align-items-center justify-content-center"><Spinner /></div>
                    : (
                        <div className={classnames(styles.widths, "p-3 ml-auto mr-auto")}>
                            <div className="d-flex text-primary">
                                <Link to={`/partners/packages/redeem/${match.params.packageCode}`}>
                                    <strong>
                                        <i className="far fa-arrow-alt-circle-left mr-2" /> Redeem a Package
                                    </strong>
                                </Link>
                            </div>
                            <div className="flex-grow-1 mt-4">
                                {renderDetails()}
                                {renderCarousel()}
                            </div>
                        </div>
                    )
            }
        </>
    );
};
