/* eslint-disable import/extensions */
import React, { ChangeEvent, useEffect, useRef } from "react";
import { Button, FormFeedback } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

import Textbox from "@/Components/Textbox";
import { DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";
import Checkbox from "@/Components/Checkbox";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { features } from "@/Utils/features";
import { Feature } from "@/Components/Feature";
import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";
import useSignUpForm from "@/FlexPlan/Pages/PlanManagers/PlanManagerSignUp/Hooks/SignUpForm";
import { ContactForm } from "@/FlexPlan/Pages/PlanManagers/PlanManagerSignUp/Components/ContactForm";
import { Heading } from "@/FlexPlan/Components/Heading";
import { BackButton } from "@/FlexPlan/Components/BackButton";

const SignUp = () => {
    // Hooks
    const recaptchaRef = useRef();
    const { recaptchaKey } = useConfigurationContext();
    const {
        onFormSubmit,
        formState,
        invalidFields,
        onChange,
        onChangeContact,
        onAddressSave,
        onAddressChange,
        onAddDocument,
        onAddNewContact,
        onDeleteDocument,
        processingSignUp,
        onRecaptcha,
        onChangeTermsAndConditions,
        signUpSuccess,
        apiError,
        validationErrors,
        changedFields,
    } = useSignUpForm();

    useEffect(() => {
        if (!formState.email && !changedFields.includes("email") && formState.contacts.find(x => x.role === "FlexPlan_PlanManager")?.email) {
            onChange("email")({ target: { value: formState.contacts.find(x => x.role === "FlexPlan_PlanManager")!.email } } as ChangeEvent<HTMLInputElement>);
        }
    }, [formState.contacts.find(x => x.role === "FlexPlan_PlanManager")?.email]);

    const renderSignUpForm = () => (
        <>
            {/* Heading */}
            <Heading title="Plan Manager Sign Up" />
            {/* Form */}
            <form
                onSubmit={onFormSubmit}
                className="tw-p-4 md:tw-p-10 tw-max-w-4xl tw-mx-auto"
            >
                <Textbox
                    label="Company Name *"
                    className="tw-text-base"
                    onChange={onChange("companyName")}
                    value={formState?.companyName}
                    error={invalidFields && invalidFields.companyName && "Please enter a Company Name. Must be less than 255 characters."}
                />
                <Textbox
                    label="ABN *"
                    className="tw-text-base"
                    isRequired
                    onChange={onChange("abn")}
                    value={formState?.abn}
                    error={invalidFields && invalidFields.abn && "Please enter a valid 11 digit ABN"}
                    maxLength={11}
                />
                {formState.contacts && formState.contacts.map((x, i) => (
                    <ContactForm
                        className="tw-mt-5"
                        key={x.id}
                        id={x.id}
                        areaTitle={i === 0 ? "MAIN CONTACT" : `ADDITIONAL CONTACT ${i}`}
                        firstName={x.firstName}
                        lastName={x.lastName}
                        email={x.email}
                        phone={x.phone}
                        address={x.address}
                        role={x.role}
                        onChange={onChangeContact}
                        displayAddAnotherContactButton={i === formState.contacts.length - 1}
                        onAddNewContact={onAddNewContact}
                        invalidFields={invalidFields}
                        onAddressSaved={onAddressSave}
                        onAddressChanged={onAddressChange}
                    />
                ))}
                <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5">
                    <div className="tw-text-lg tw-font-semibold">NDIS INFORMATION</div>
                    <Textbox
                        className="tw-mt-5 tw-text-base"
                        label="Registration Number *"
                        isRequired
                        onChange={onChange("registrationNumber")}
                        value={formState.registrationNumber}
                        error={invalidFields?.registrationNumber && "The Registration Number is required and allows only digits."}
                    />
                </div>
                <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5">
                    <div className="tw-text-lg tw-font-semibold tw-mb-5">SUPPORTING DOCUMENTS</div>
                    <DropZoneMulti
                        onAdd={onAddDocument}
                        onDelete={onDeleteDocument}
                        accept={acceptedFileTypes.documents}
                        showBrowseButton
                        showEmptyFile
                        showHeader={false}
                        dropAreaContent={(
                            <span className="tw-font-normal">
                                You can also drag-and-drop multiple files here.
                            </span>)}
                        validationContent={`Files must be less than 8MB and of type ${fileTypesText(acceptedFileTypes.documents)}.`}
                        buttonTextClasses="tw-text-base"
                    />
                </div>
                <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5">
                    <div className="tw-text-lg tw-font-semibold">FLEXPLAN LOGIN</div>
                    <Textbox
                        className="tw-mt-5 tw-text-base"
                        label="E-mail Address *"
                        onChange={onChange("email")}
                        value={!formState.email && !changedFields.includes("email")
                            ? formState.contacts.find(x => x.role === "FlexPlan_PlanManager")?.email
                            : formState.email}
                        error={invalidFields?.email && "Please enter a valid E-mail Address"}
                    />
                </div>
                {/* TODO Flexplan: are we actually putting the green text in here, or is it for the developers info? Seems addressed to the user */}
                <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5 tw-flex">
                    <Checkbox
                        onChange={checked => onChangeTermsAndConditions(checked)}
                        checked={formState.termsAndConditions}
                        id="terms-and-conditions-checkbox"
                        label={(
                            <div className="tw-text-base tw-ml-1">
                                I agree to the <a href="/" className="tw-underline">Terms and Conditions</a> and
                                <a href="/" className="tw-underline"> Privacy Policy</a> of the FlexPlan
                            </div>
                        )}
                        error={invalidFields?.termsAndConditions && "You must accept the Terms and Conditions"}
                    />
                </div>
                <div className="tw-mt-5">
                    <Feature name={features.recaptcha}>
                        <ReCAPTCHA
                            size="normal"
                            ref={recaptchaRef}
                            sitekey={recaptchaKey}
                            onChange={onRecaptcha}
                        />
                    </Feature>
                    {invalidFields?.recaptcha
                        && (
                            <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                                Please perform the Recaptcha
                            </FormFeedback>
                        )}
                </div>
                {apiError && (
                    <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                        {apiError}
                    </FormFeedback>
                )}
                {invalidFields && Object.keys(invalidFields).length > 0 && (
                    <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                        Please amend the errors above
                    </FormFeedback>
                )}
                {validationErrors && (
                    <ul className="font-weight-bold !tw-mt-2">
                        {validationErrors.map(error => (
                            <li>
                                <FormFeedback
                                    className="font-weight-bold !tw-block !tw-text-bootstrap-error-size"
                                >
                                    {error}
                                </FormFeedback>
                            </li>
                        ))}
                    </ul>
                )}
                <div className="tw-mt-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-space-y-2 md:tw-space-y-0 md:tw-space-x-5">
                    <BackButton
                        className="tw-w-full md:tw-w-72 !tw-text-base"
                        displayConfirmationModal
                    />
                    <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        className="tw-w-full md:tw-w-72 !tw-text-base"
                        disabled={processingSignUp || (invalidFields && Object.keys(invalidFields).length > 0)}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </>
    );

    const renderSignUpSuccess = () => (
        <LoginBackgroundWrapper>
            <div className="tw-text-base">
                Successfully registered the plan manager. An email has been sent to the plan manager to set their password.
            </div>
        </LoginBackgroundWrapper>
    );

    return (
        signUpSuccess ? renderSignUpSuccess() : renderSignUpForm()
    );
};

export { SignUp };
