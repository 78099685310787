import React from "react";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

import ShowHideIconLabel from "@/Components/ShowHideIconLabel";

class PasswordField extends React.PureComponent {
    state = {
        showPassword: false,
    };

    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        return (
            <FormGroup>
                <div className="d-flex justify-content-between">
                    <Label>{this.props.label}</Label>
                    <ShowHideIconLabel
                        show={this.state.showPassword}
                        label="password"
                        onToggle={this.toggleShowPassword}
                    />
                </div>
                <Input
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder={this.props.placeholder}
                    invalid={this.props.invalid}
                    onChange={this.props.onChange}
                    value={this.props.value || ""}
                    data-testid="password-field"
                    name={this.props.name}
                    autoComplete={this.props.autoComplete}
                />
                <FormFeedback className="font-weight-bold">This field is required.</FormFeedback>
            </FormGroup>
        );
    }
}

PasswordField.propTypes = {
    invalid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    autoComplete: PropTypes.string,
};

PasswordField.defaultProps = {
    label: "Password *",
    placeholder: "Your password",
};

export default PasswordField;
