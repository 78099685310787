export interface BasketItemType {
    showLogo: boolean;
    requiredFieldsCompleted: boolean;
    invalidFields: boolean;
    fields: BasketFieldType[];
    orderId: string;
    serviceName: string;
    category: string;
    subcategory: string;
    supplierId: string;
    supplierName: string;
    showName: boolean;
    showTagline: boolean;
    headerColour: string;
    nameAndTaglineColour: string;
    tagline: string;
    headerImage: string;
    price: number;
    postage: number;
    quantity: number;
    totalPrice: number;
    totalVat: number;
    serviceImage: string;
    description: string;
    serviceId: string;
    productId: string;
    logo: string;
    termsDocument?: string;
    termsAccepted?: boolean;
    variations;
    paymentFrequency: string;
    isSubscription: boolean;
    slugId: number;
    slug: string;
    isOffsitePayment: boolean;
    hasNAProducts: boolean;
    isDelayedPayment?: boolean;
    delayedPaymentFor?: number;
    delayedPaymentPeriod?: number;
    minimumQuantityValue?: number;
    canChangeQuantity: boolean;
    discountRate: number;
}

export interface BasketItemTypeWithGroup extends BasketItemType {
    group: BasketGroupType;
}
export interface BasketType {
    total: string;
    totalAmount: number;
    totalPostage: number;
    totalVat: number;
    totalItems: number;
    totalItemsGrouped: number;
    dueNowTotal: number;
    groups: BasketGroupType[];
    totalAmountExcludingVat: number;
}

export interface BasketGroupType {
    key?: string;
    name?: string;
    description?: string;
    items: BasketItemType[];
}

export interface BasketFieldType {
    group: string;
    serviceFieldId: string;
    fieldId: string;
    isRequired: boolean;
    type: string;
    displayText: string;
    description: string;
    value;
    isValid: boolean;
    isIdentityDocument: boolean;
}

export interface ReducedBasketFieldsType {
    orderIds: string[];
    serviceFieldIds: string[];
    supplierIds: string[];
    supplierNames: string[];
    supplierLogos: string[];
    productNames: string[];
    value;
    fieldId: string;
    isRequired: boolean;
    type: string;
    displayText: string;
    description: string;
    serviceFieldId: string;
    orderId: string;
    isIdentityDocument: boolean;
}

export interface SaveBasketFormRequest {
    saveBasketOrders: SaveBasketOrder[];
}

export interface SaveBasketOrder {
    orderId: string;
    serviceFieldsAnswers: ProductFieldAnswer[];
}

export interface ProductFieldAnswer {
    serviceFieldId: string;
    value: string;
}

export interface CompleteOrderResponse {
    payments: PaymentResponse[];
}

export interface PaymentResponse {
    paymentId: string;
    orders: PaymentOrderItems[];
    paymentSource: string;
}

export interface PaymentOrderItems {
    supplierId: string;
    supplierName: string;
    productId: string;
    productName: string;
}

export enum GlobalFieldDescriptions {
    PersonalInformation = "Personal Information",
    AdditionalInformation = "Additional Information",
    BusinessInformation = "Business Information"
}

export enum SupplierFieldDescriptions {
    CustomFields = "Custom Fields",
}

export enum OrderType {
    Product = "Product",
    Upsell = "Upsell",
    BespokeQuote = "BespokeQuote",
    Package = "Package",
    FormationsProduct = "FormationsProduct",
    Csl = "Csl"
}
