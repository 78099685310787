/* eslint-disable implicit-arrow-linebreak */
import { CalculateLineItemTotal } from "@/Apis/Receipts/Create";

export const countDecimals = (value: string) => {
    if (value && value.indexOf(".") !== -1) {
        return value.split(".")[1].length || 0;
    }
    return 0;
};

export const countDots = (value: string) => {
    if (value) {
        return (value.match(/\./g) || []).length;
    }
    return 0;
};

export const isPositiveZero = (value: number) => 1 / value === +Infinity;

export const isNegativeZero = (value: number) => 1 / value === -Infinity;

export const isNumeric = (value: any): boolean => !Number.isNaN(value - parseFloat(value));

export const isInteger = (value: any): boolean => /^\d+$/.test(value);

export const calculateRowVat = (row: CalculateLineItemTotal): number =>
    ((row.quantity * row.pricing)) * ((row.hasVat ? row.vatPercentage : 0) / 100);

export const calculateVatTotal = (rows: CalculateLineItemTotal[]): number =>
    rows.reduce((sum, row) =>
        sum + ((row.quantity * row.pricing) - ((row.quantity * row.pricing) * (row.discountPercentage / 100))) * ((row.hasVat ? row.vatPercentage : 0) / 100), 0);

const calculateDiscount = (row: CalculateLineItemTotal): number => ((row.quantity * row.pricing) + calculateRowVat(row)) * (row.discountPercentage / 100);

export const calculateRowTotal = (row: CalculateLineItemTotal): number =>
    ((row.quantity * row.pricing) + calculateRowVat(row)) - calculateDiscount(row);

export const calculateSubTotal = (rows: CalculateLineItemTotal[]): number =>
    rows.reduce((sum, row) => sum + (row.quantity * row.pricing) - ((row.quantity * row.pricing) * (row.discountPercentage / 100)), 0);

export const calculateTotalWithVatAndDiscount = (rows: CalculateLineItemTotal[]): number => calculateSubTotal(rows) + calculateVatTotal(rows);
