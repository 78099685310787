import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Card, Carousel, CarouselControl, CarouselItem } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import styles from "./styles.module.scss";

import { ContextMenu } from "@/Components/ContextMenu";
import { Image } from "@/Components/Image";
import { PricingPopover } from "@/Components/PricingPopover";
import globalActions from "@/Store/Global/actions";
import { ProductGallery, ProductPriceSummary } from "@/Apis/Products/ProductType";

interface Props {
    id: string,
    code: string,
    name: string,
    description?: string,
    initialCharge: number,
    initialChargeWithVatIfApplicable: number,
    postageCharge: number,
    images?: Array<ProductGallery>,
    showEditButton?: boolean,
    onEditPackage?: any,
    supplierName: string,
    productPriceSummary: Array<ProductPriceSummary>,
    hasNAProducts: boolean,
    cardClassName: string,
    carouselItemClassName: string,
}

const PackageTile = ({ id, code, name, description, initialCharge, initialChargeWithVatIfApplicable, images = [],
    productPriceSummary = [], showEditButton = false, onEditPackage, supplierName, postageCharge, hasNAProducts = false, cardClassName, carouselItemClassName }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const imagesLength = () => (images ? images.length : 0);
    const next = () => { setActiveIndex(activeIndex === imagesLength() - 1 ? 0 : activeIndex + 1); };
    const previous = () => { setActiveIndex(activeIndex === 0 ? imagesLength() - 1 : activeIndex - 1); };
    const [subscriptionSummary, setSubscriptionSummary] = useState<ProductPriceSummary[]>([]);

    const groupBy = (xs, key) => xs.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

    useEffect(() => {
        const groupedServices = groupBy(productPriceSummary, "paymentFrequency");
        const summary = Object.keys(groupedServices).map((x) => (
            {
                paymentFrequency: x,
                initialCharge: groupedServices[x].reduce((sum, current) => sum + current.initialCharge, 0.00),
            }
        ));
        setSubscriptionSummary(summary.filter(x => x.paymentFrequency !== "One Time Payment" && x.paymentFrequency !== "N/A"));
    }, [productPriceSummary]);

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(code);
        dispatch(globalActions.setToastMessage(true, "Package code copied to clipboard"));
    };

    return (
        <>
            <Card
                data-testid={name}
                className={classnames(styles.cardContainer, cardClassName, "d-inline-flex flex-column mx-2 bg-white mt-3 position-relative rounded-lg")}
            >
                <div className="text-left p-0">
                    <div className={classnames("d-flex align-items-center", styles.nameContainer)}>
                        <h5 data-testid="product-name" className={classnames(styles.name, "flex-grow-1 mb-0 font-weight-bold")}>{name}</h5>
                        <ContextMenu
                            testId="context-menu"
                            showCopy
                            onClickCopy={copyToClipboard}
                            isPackage
                        />
                    </div>
                    <div className={classnames(styles.categoryName, "mt-1 mb-2 text-muted")}>
                        <span>Referrer: {supplierName}</span>
                    </div>
                    <PricingPopover
                        productId={id}
                        initialChargeWithVatIfApplicable={initialChargeWithVatIfApplicable}
                        initialCharge={initialCharge}
                        paymentFrequency="ONE TIME PAYMENT"
                        postageCharge={postageCharge}
                        minimumQuantity={0}
                        isVatRequired={initialChargeWithVatIfApplicable > initialCharge}
                        componentName="tile"
                        subscriptionSummary={subscriptionSummary}
                        hasNAProducts={hasNAProducts}
                        packageDisplay
                    />
                </div>
                <div className={classnames("flex-column d-flex justify-content-between")}>
                    <p
                        className={classnames(styles.description, "mt-lg-2 mb-0")}
                        data-testid="product-tile-description"
                    >
                        {description}
                    </p>
                    <div className={classnames(styles.supplierImageContainer, "mt-sm-3 ml-1 bg-light d-flex justify-content-center align-items-center")}>
                        {
                            images && (
                                <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
                                    {images?.map((item) => (
                                        <CarouselItem className={classnames(styles.carouselitem, carouselItemClassName)} key={item.name}>
                                            <Image testId={`image-expanded-${item.data}`} src={item.data} alt={item.name} />
                                        </CarouselItem>
                                    ))}
                                    {imagesLength() > 1 && (
                                        <>
                                            <CarouselControl className={styles.previousCarousel} direction="prev" directionText="Previous" onClickHandler={previous} />
                                            <CarouselControl className={styles.nextCarousel} direction="next" directionText="Next" onClickHandler={next} />
                                        </>)}
                                </Carousel>
                            )
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <Button
                        onClick={() => history.push(`/packages/redeem?code=${code}`)}
                        color="primary"
                        outline
                        data-testid="view-details-button"
                    >
                        View Details
                    </Button>
                    {showEditButton && (
                        <Button onClick={onEditPackage} className={classnames(styles.editButton, "text-dark")} data-testid="product-tile-edit-button">
                            <i className="fas fa-pencil-alt mt-1" /> <small>Edit Package Tile</small>
                        </Button>
                    )}
                </div>
            </Card>
        </>
    );
};

export { PackageTile };
