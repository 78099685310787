import React from "react";
import PropTypes from "prop-types";
import { PayPalButton } from "react-paypal-button-v2";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

import { toCurrency } from "@/Utils/formatStringHelper";
import { completeOrder, onOrderComplete } from "@/Apis/Orders";
import { logPaypalRequest } from "@/Apis/Payments";
import { PAYMENTS_FAILED_TO_COMPLETE_BASKET, PAYMENTS_PAYPAL_TRANSACTION_FAILED, useLoggingContext } from "@/Context/LoggingContext";
import { useBasketContext } from "@/Context/BasketContext";

const PayPalCheckoutButton = ({ user, apiKey, onPaymentSuccess }) => {
    const applicationInsights = useLoggingContext();
    const { basket } = useBasketContext();
    const dispatch = useDispatch();
    const onError = (error) => {
        const errorDetails = { userId: user.email, items: basket.groups.flatMap(_ => _.items).map(x => x.orderId) };
        applicationInsights.trackException({
            exception: new Error(PAYMENTS_PAYPAL_TRANSACTION_FAILED),
            properties: { details: errorDetails, error: { code: error.code, message: error.message, stack: error.stack, name: error.name } },
        });
    };

    const onSuccess = (details) => {
        const paymentDetails = {
            source: "paypal",
            orderIds: basket.groups.flatMap(_ => _.items).map(x => x.orderId),
            paypalOrderId: details.id,
            paypalResponse: details,
        };

        completeOrder(paymentDetails as any)
            .then(_ => onOrderComplete(dispatch, onPaymentSuccess)(_.payments))
            .catch((error) => {
                const errorDetails = { userId: user.email, items: details.orderIds };
                applicationInsights.trackException({
                    exception: new Error(PAYMENTS_FAILED_TO_COMPLETE_BASKET),
                    properties: { details: errorDetails, error: { code: error.code, message: error.message, stack: error.stack, name: error.name } },
                });
            });
    };

    const getItems = () => basket.groups.flatMap(_ => _.items).map(item => ({
        name: item.serviceName.substring(0, 127),
        quantity: item.quantity,
        sku: item.serviceId,
        unit_amount: {
            currency_code: "GBP",
            value: toCurrency(item.price.toString()),
        },
        tax: {
            currency_code: "GBP",
            value: toCurrency(item.totalVat.toString()),
        },
    }));

    const getOrder = () => ({
        intent: "CAPTURE",
        payer: {
            name: {
                given_name: user.forename.substring(0, 140),
                surname: user.surname.substring(0, 140),
            },
            email_address: user.email.substring(0, 254),
        },
        purchase_units: [{
            reference_id: uuid(),
            description: "BSC",
            amount: {
                currency_code: "GBP",
                value: toCurrency(basket.totalAmount.toString()),
                breakdown: {
                    item_total: {
                        currency_code: "GBP",
                        value: toCurrency((basket.totalAmountExcludingVat).toString()),
                    },
                    shipping: {
                        currency_code: "GBP",
                        value: toCurrency(basket.totalPostage.toString()),
                    },
                    tax_total: {
                        currency_code: "GBP",
                        value: toCurrency(basket.totalVat.toString()),
                    },
                },
            },
            items: getItems(),
        }],
    });

    const createOrder = (data, actions) => {
        const order = getOrder();

        logPaypalRequest({
            uniqueReference: order.purchase_units[0].reference_id,
            orderIds: basket.groups.flatMap(_ => _.items).map(x => x.orderId),
            payload: order,
        });

        return actions.order.create(order);
    };

    return (
        <div className={styles.paypalButton}>
            <PayPalButton
                options={{
                    clientId: apiKey,
                    disableFunding: "card",
                    currency: "GBP",
                    vault: true,
                }}
                style={{
                    color: "gold",
                    size: "responsive",
                    label: "paypal",
                }}
                onError={onError}
                catchError={onError}
                onSuccess={onSuccess}
                createOrder={createOrder}
            />
        </div>
    );
};

PayPalCheckoutButton.propTypes = {
    user: PropTypes.shape({
        forename: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }),
    apiKey: PropTypes.string.isRequired,
    onPaymentSuccess: PropTypes.func.isRequired,
};

export { PayPalCheckoutButton };
