import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { DropZoneFile } from "@/Components/Dropzones/DropZoneMulti";
import { exceedsMaxStringLength, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { isValidPhone, isValidABN, isNumericString } from "@/FlexPlan/Utils/validtor";
import { validateEmail } from "@/Utils/validator";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { features } from "@/Utils/features";
import { FlexPlanRoles } from "@/FlexPlan/Types/Roles";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { SignUpFormState } from "@/FlexPlan/Pages/PlanManagers/PlanManagerSignUp/Types/SignUpFormStateTypes";

const useSignUpForm = () => {
    const { post, loading: processingSignUp } = useAPI({ handle500WithToastMessage: true });

    const [invalidFields, setInvalidFields] = useState<any>();
    const [changedFields, setChangedFields] = useState<string[]>([]);
    const [apiError, setApiError] = useState<string>();
    const [validationErrors, setValidationErrors] = useState<string[]>(); // Validation errors from the backend e.g. user already exists.
    const [signUpSuccess, setSignUpSuccess] = useState<boolean>(false);

    const [formState, setFormState] = useState<SignUpFormState>({
        recaptcha: "",
        abn: "",
        companyName: "",
        email: "",
        registrationNumber: "",
        contacts: [
            {
                id: uuid(),
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                address: null,
                role: "",
            },
        ],
        termsAndConditions: false,
        supportingDocuments: [] as EncodedFile[],
    });

    const removeErrorField = (field: string) => setInvalidFields(invalidFields => {
        if (!invalidFields) return invalidFields;
        const updatedInvalidFields = invalidFields;
        delete updatedInvalidFields[field];
        return updatedInvalidFields;
    });

    const removeContactErrorField = (id: string, field: string) => setInvalidFields(invalidFields => {
        if (!invalidFields) return invalidFields;
        const updateInvalidFields = invalidFields;
        delete updateInvalidFields[`${id}-${field}`];
        return updateInvalidFields;
    });

    const onRecaptcha = (value: string) => {
        setFormState(formState => ({
            ...formState,
            recaptcha: value,
        }));
        removeErrorField("recaptcha");
    };

    const onChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setChangedFields(prevState => ((prevState.some(x => x === field)) ? [...prevState] : [...prevState, field]));
        setFormState(formState => ({
            ...formState,
            [field]: e.target.value,
        }));
        removeErrorField(field);
    };

    const onChangeContact = (id: string, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(formState => ({
            ...formState,
            contacts: formState.contacts.map(x => (x.id === id ? ({
                ...x,
                [field]: e.target.value,
            }) : x)),
        }));
        removeContactErrorField(id, field);
        if (field === "role" && e.target.value === FlexPlanRoles.PlanManager) {
            removeErrorField("planManagerRequired");
        }
    };

    const onAddressChange = (contactId: string) => removeContactErrorField(contactId, "address");

    const onAddressSave = (contactId: string, address: LoqateAddress) => {
        setFormState(formState => ({
            ...formState,
            contacts: formState.contacts.map(x => ((x.id === contactId) ? ({
                ...x,
                address,
            }) : x)),
        }));
        removeContactErrorField(contactId, "address");
    };

    const onChangeTermsAndConditions = (checked: boolean) => {
        setFormState(formState => ({ ...formState, termsAndConditions: checked }));
        removeErrorField("termsAndConditions");
    };

    const onAddNewContact = () => {
        setFormState(formState => ({
            ...formState,
            contacts: formState.contacts.concat({
                id: uuid(),
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                address: {} as LoqateAddress,
                role: "",
            }),
        }));
    };

    const onAddDocument = async (files: EncodedFile[]) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.concat(files),
        }));
    };

    const onDeleteDocument = async (file: DropZoneFile) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.filter(x => x.name !== file.fileName),
        }));
    };

    const setInvalidField = (fieldName: string) => setInvalidFields(invalidFields => ({
        ...invalidFields,
        [fieldName]: true,
    }));

    const setInvalidContactField = (id: string, fieldName: string) => setInvalidFields(invalidFields => ({
        ...invalidFields,
        [`${id}-${fieldName}`]: true,
    }));

    const hasErrors = (): boolean => {
        // Set any errors
        let errorFound: boolean = false;

        if (isEmptyOrSpaces(formState.companyName) || exceedsMaxStringLength(formState.companyName)) {
            setInvalidField("companyName");
            errorFound = true;
        }

        if (!isValidABN(formState.abn)) {
            setInvalidField("abn");
            errorFound = true;
        }

        // Return whether the form is valid or not
        formState.contacts.forEach(contact => {
            if (isEmptyOrSpaces(contact.firstName) || exceedsMaxStringLength(contact.firstName)) {
                setInvalidContactField(contact.id, "firstName");
                errorFound = true;
            }

            if (isEmptyOrSpaces(contact.lastName) || exceedsMaxStringLength(contact.lastName)) {
                setInvalidContactField(contact.id, "lastName");
                errorFound = true;
            }

            if (!validateEmail(contact.email)) {
                setInvalidContactField(contact.id, "email");
                errorFound = true;
            }

            if (!isValidPhone(contact.phone)) {
                setInvalidContactField(contact.id, "phone");
                errorFound = true;
            }

            if (isEmptyOrSpaces(contact.address)) {
                setInvalidContactField(contact.id, "address");
                errorFound = true;
            }

            if (isEmptyOrSpaces(contact.role)) {
                setInvalidContactField(contact.id, "role");
                errorFound = true;
            }
        });

        if (!formState.contacts.some(x => x.role === FlexPlanRoles.PlanManager)) {
            setInvalidField("planManagerRequired");
            errorFound = true;
        }

        if (!isNumericString(formState.registrationNumber) || exceedsMaxStringLength(formState.registrationNumber)) {
            setInvalidField("registrationNumber");
            errorFound = true;
        }

        if (!validateEmail(formState.email)) {
            setInvalidField("email");
            errorFound = true;
        }

        if (!formState.termsAndConditions) {
            setInvalidField("termsAndConditions");
            errorFound = true;
        }

        if (features.isEnabled(features.recaptcha) && !formState.recaptcha) {
            setInvalidField("recaptcha");
            errorFound = true;
        }

        return errorFound;
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (hasErrors()) {
            return;
        }

        post(FlexPlanUrls.planManager.register, formState)
            .then(() => setSignUpSuccess(true))
            .catch(error => {
                // Generic API error
                if (typeof error === "string") {
                    setApiError(error);
                } else if (error && error.errors) { // We've got some validation issues with the user
                    const validationErrors = Object.values<string>(error.errors);
                    setValidationErrors(validationErrors);
                }
            });
    };

    return {
        processingSignUp,
        onChange,
        onFormSubmit,
        onDeleteDocument,
        onAddDocument,
        onAddNewContact,
        onAddressSave,
        onAddressChange,
        onChangeContact,
        onChangeTermsAndConditions,
        onRecaptcha,
        invalidFields,
        formState,
        setFormState,
        apiError,
        signUpSuccess,
        validationErrors,
        changedFields,
    };
};

export default useSignUpForm;
