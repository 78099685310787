/* eslint-disable max-len */
import { useEffect } from "react";

import { getToken } from "@/Utils/authentication";
import { useAPI } from "@/Apis/useAPI";

const PartnerAppGetToken = () => {
    const { post } = useAPI({ handle500WithRedirect: true });

    useEffect(() => {
        post<{ encryptedToken: string }>("account/bsc-partners/encrypt-token", { token: getToken() }).then(response => {
            // This the deep link in the CSL app given to us by Jonell NOT BSC if the app is installed this stays on the client
            // If not the url loads a page on the CSL website directing users to download the app, just a safe fallback.
            window.location.href = `https://bsctempdev.page.link/?link=https://bsc.itdev.app/bsc-auth-callback?encryptedToken=${response.encryptedToken}`;
        });
    }, []);

    return null;
};
export { PartnerAppGetToken };
