import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Rating from "@/Components/Rating";

const SupplierDescription = ({ description, hideDescription, rating, showEditButton, onEditClick, onMouseEnter, onMouseLeave }) => (
    <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="d-flex flex-column bg-white px-3 pb-2 position-relative"
    >
        <div className={classnames(styles.editButton, { [styles.hideEditButton]: !showEditButton })}>
            <div className="bg-dark text-white">About</div>
            <Button type="button" onClick={onEditClick} data-testid="edit-description">Edit</Button>
        </div>
        {rating > 0 && (<Rating rating={rating} className="w-100 text-right my-3 mt-md-4" />)}
        {rating < 1 && (<div className="w-100 text-right my-3 mt-md-4">Not rated</div>)}
        {!hideDescription && (
            <p className="text-break">{description || "Feel free to edit this placeholder text for your shop description. Add more text here as needed."}</p>
        )}
    </div>
);

SupplierDescription.propTypes = {
    description: PropTypes.string,
    hideDescription: PropTypes.bool,
    rating: PropTypes.number,
    showEditButton: PropTypes.bool,
    onEditClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

SupplierDescription.defaultProps = {
    showEditButton: false,
    hideDescription: false,
};

export { SupplierDescription };
