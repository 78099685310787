import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

const formatFolderName = (name) => {
    if (name.toLowerCase() === "sent") {
        return "sent items";
    }
    return name === "Deleted" ? "deleted items" : name.toLowerCase();
};

const NoMessages = props => (
    <div className={classnames(styles.container, "flex-shrink-1 mt-3")} data-testid="no-messages">
        <img src={`${window.cdnUrl}misc/calvin-no-results.png`} alt="No messages" />
        <p className="my-3 pb-4 text-center w-75">
            Uh-oh, your {formatFolderName(props.currentFolder)} is empty. Looks like you’ve not made any made enquiries nor engaged with our community yet.
            Let’s start you off by browsing around our marketplace!
        </p>
        <Button color="primary" tag={Link} to="/marketplace">Take me to the marketplace!</Button>
    </div>
);

NoMessages.propTypes = {
    currentFolder: PropTypes.string.isRequired,
};

export default NoMessages;
