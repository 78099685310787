// reducer
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SET_ERROR_TOAST_MESSAGE = "SET_ERROR_TOAST_MESSAGE";

// global consts
export const ENQUIRY_FORM = "Enquiry Form";
export const ENQUIRY_FORM_SUB_CATEGORY = "Enquiry Form Sub Cat";

export const sideBarTypes = {
    editHeader: "EDIT_HEADER",
    editDescription: "EDIT_DESC",
};

export const navSideBarTypes = {
    customerDashboard: "CUSTOMER_DASHBOARD",
    supplierDashboard: "SUPPLIER_DASHBOARD",
};
