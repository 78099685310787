import React, { useEffect, useState } from "react";

import { ItemCode, OrderRow } from "../Types";

import { ReactSelect } from "@/Components/ReactSelect";
import { useFlexPlanProductContext } from "@/FlexPlan/Context/FlexPlanProductContext";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { BookingTypeNdis } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Types";

interface Props {
    item: OrderRow,
    isDisabled:boolean
}

interface ServiceBookingItemsResponse {
    items: ServiceBookingItemRow[],
    serviceBookingType: BookingTypeNdis,
}

interface ServiceBookingItemRow {
    category: string,
    itemCode: string,
}

const ItemCodeSearch = ({ item, isDisabled }: Props) => {
    // Context
    const { formState, setFormState, disableButtons, removeRowErrorField } = useFlexPlanOrderContext();
    const { productCategoryItems, groupedProducts } = useFlexPlanProductContext();
    const { setPopupErrorMessage } = useToastMessageContext();

    // Local state
    const [selectedItemCode, setSelectedItemCode] = useState<ItemCode>();
    const [itemOptions, setItemOptions] = useState<{ label: string, value: string }[]>();
    // Selected service booking
    const [serviceBooking, setServiceBooking] = useState<ServiceBookingItemsResponse>();

    // Hooks
    const { get, loading } = useAPI({ handle500WithToastMessage: true });

    const handleSelect = (itemCode) => {
        setSelectedItemCode(itemCode);
        setFormState(prevState => ({
            ...prevState,
            items: [...prevState.items.filter(x => x.id !== item.id),
                { ...prevState.items.find(x => x.id === item.id)!, itemCode: itemCode.value },
            ],
        }));
        removeRowErrorField(item.id, "itemCode");
    };

    useEffect(() => { // If we have a service booking number, pull the relevant items
        if (!formState.ndisServiceBookingNumber) return;

        get<ServiceBookingItemsResponse>(FlexPlanUrls.serviceBookingItems.get(formState.ndisServiceBookingNumber))
            .then(setServiceBooking)
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    }, [formState.ndisServiceBookingNumber]);

    useEffect(() => {
        if (formState.ndisServiceBookingNumber && serviceBooking) {
            if (serviceBooking.serviceBookingType === BookingTypeNdis.StandardBooking
                || (serviceBooking.serviceBookingType === BookingTypeNdis.PlanManaged
                    && serviceBooking.items.every(x => !!x.itemCode))) {
                // Standard booking or stated support, we have the item codes so we use them

                // We only have the item code, we need to find the name
                const serviceBookingItemCodes: string[] = serviceBooking
                    .items
                    .map(x => x.itemCode);

                const itemsForServiceBooking = productCategoryItems
                    .filter(x => serviceBookingItemCodes.includes(x.key))
                    .map(x => ({
                        label: `${x.key} - ${x.value}`,
                        value: x.key,
                    }));

                setItemOptions(itemsForServiceBooking);
            } else {
                // Plan managed service bookings don't have item codes
                // We therefore display the items for the selected categories
                const categories: string[] = serviceBooking.items.map(x => x.category);

                const itemsForCategories = groupedProducts
                    .filter(x => categories.includes(x.key))
                    .flatMap(x => x.items)
                    .map(x => ({
                        label: `${x.key} - ${x.value}`,
                        value: x.key,
                    }));

                setItemOptions(itemsForCategories);
            }
        } else if (productCategoryItems) {
            // If there's no service booking selected display all item codes
            setItemOptions(productCategoryItems.map(x => ({ label: `${x.key} - ${x.value}`, value: x.key })));
        }
    }, [serviceBooking, productCategoryItems, formState.ndisServiceBookingNumber]);

    useEffect(() => { // We only get the product category item, so we need to match it up
        // Match up the item
        const matchingProduct = productCategoryItems
            .find(x => x.key === item.itemCode);

        if (!matchingProduct) return;

        setSelectedItemCode({
            key: matchingProduct.key,
            value: matchingProduct.value,
            label: `${matchingProduct?.key} - ${matchingProduct?.value}`,
        });
    }, [item, productCategoryItems]);

    return (
        <ReactSelect
            options={itemOptions}
            value={selectedItemCode}
            isSearchable
            className="tw-text-black"
            unstyled
            isDisabled={isDisabled || disableButtons || loading}
            onChange={handleSelect}
            noOptionsMessage={() => {
                if (formState.ndisServiceBookingNumber) {
                    return "No item codes for service booking";
                }

                return "No options";
            }}
        />);
};

export { ItemCodeSearch };
