import * as constants from "./constants";

const setLoading = payload => ({
    type: constants.SET_LOADING,
    payload,
});

const setError = payload => ({
    type: constants.SET_ERROR,
    payload,
});

const setToastMessage = (display, message = "Saved successfully") => ({
    type: constants.SET_TOAST_MESSAGE,
    payload: { display, message },
});

const setErrorToastMessage = (display, message = "Something went wrong. Please try again.") => ({
    type: constants.SET_ERROR_TOAST_MESSAGE,
    payload: { display, message },
});

export default {
    setLoading,
    setError,
    setToastMessage,
    setErrorToastMessage,
};
