import React, { useState } from "react";
import { Alert, Button, Label } from "reactstrap";
import { v4 as uuid } from "uuid";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import Textbox from "@/Components/Textbox";
import RadioButton from "@/Components/RadioButton";
import Checkbox from "@/Components/Checkbox";
import NumberField from "@/Components/NumberField";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import Spinner from "@/Components/Spinner";
import ReceiptsRecipientsSearch from "@/Pages/Receipts/Create/DetailsTab/ReceiptsRecipientsSearch";
import ReceiptPreviousPackagesDropdown from "@/Pages/Receipts/Create/DetailsTab/ReceiptsPreviousDropdown";
import ReceiptsSendToRecipient from "@/Pages/Receipts/Create/DetailsTab/ReceiptsSendToRecipient";
import ReceiptDownloadPdf from "@/Pages/Receipts/Create/DetailsTab/ReceiptsDownloadPdf";
import ReceiptItemsSearch from "@/Pages/Receipts/Create/DetailsTab/ReceiptsItemsSearch";
import { CreateReceiptFormFields,
    CreateReceiptResponse,
    ReceiptLineItem,
    ReceiptPaymentType,
    ReceiptRecurrenceFrequency,
    ReceiptSavedItem,
    ReceiptType } from "@/Apis/Receipts/Create";
import { toCurrencyWithCommas, toPaddedNumber } from "@/Utils/formatStringHelper";
import { SelectItem } from "@/Apis/SelectItem";
import { isEmptyOrSpaces, isReceiptItemCodeValid } from "@/Utils/stringHelper";
import { calculateRowTotal,
    calculateSubTotal,
    calculateTotalWithVatAndDiscount,
    calculateVatTotal } from "@/Utils/numberHelper";
import { useAPI } from "@/Apis/useAPI";
import { ReceiptMoreOptionsModal } from "@/Modals/ReceiptMoreOptionsModal";
import { useReceiptsContext } from "@/Context/ReceiptsContext";
import { Urls } from "@/Apis/urls";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { areDuplicatesPresent, isDuplicated } from "@/Utils/arrayHelper";

interface Props {
    setFormValues: React.Dispatch<React.SetStateAction<CreateReceiptFormFields>>,
    formValues: CreateReceiptFormFields,
    isEditMode?: boolean,
}

interface LocationState {
    displayCreatedSuccessMessage?: boolean,
    displayUpdatedSuccessMessage?: boolean
}

const ReceiptsDetailsTab = ({ setFormValues, formValues, isEditMode }: Props) => {
    // Hooks
    const history = useHistory();
    const location = useLocation<LocationState>();
    // Context
    const { pageType, pageTypeLowercase, pageTypePluralLowercase, pageTypeString } = useReceiptsContext();
    // State
    const [loadingLineItems, setLoadingLineItems] = useState<boolean>(false);
    const [deleteItemOpenModal, setDeleteItemOpenModal] = useState<boolean>(false);
    const [lineItemActive, setLineItemActive] = useState<ReceiptLineItem>();
    const [moreOptionsItemOpenModal, setMoreOptionsItemOpenModal] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    // Edit
    const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = useState<boolean>(false);
    const toggleConfirmUpdateModalOpen = () => setConfirmUpdateModalOpen(prevState => !prevState);

    const { setPopupErrorMessage } = useToastMessageContext();

    const receiptUnits = [
        {
            label: "/hr",
            value: 1,
        },
        {
            label: "/ea",
            value: 2,
        },
        {
            label: "/set",
            value: 3,
        },
        {
            label: "N/A",
            value: 4,
        },
    ] as SelectItem[];

    const { post } = useAPI({ handle500WithToastMessage: true });

    const isInvoiceInputDisabled = !!formValues?.id && formValues?.receiptType === ReceiptType.Invoice;

    const onUserInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [field]: e.target.value,
        });
    };

    const onPaymentTypeRadioInput = (paymentType: ReceiptPaymentType) => {
        setFormValues({
            ...formValues,
            paymentType,
        });
    };

    const onLineItemInput = (field: string, rowId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newLines = formValues?.lines?.map(x => (x.id === rowId ? { ...x, [field]: e.target.value } : x));
        setFormValues({
            ...formValues,
            lines: newLines,
        });
    };

    const onLineItemCheckboxInput = (field: string, rowId: string, checked: boolean) => {
        const newLines = formValues?.lines?.map(x => (x.id === rowId ? { ...x, [field]: checked } : x));
        setFormValues({
            ...formValues,
            lines: newLines,
        });
    };

    const onCheckboxInput = (field: string, checked: boolean) => {
        setFormValues({
            ...formValues,
            [field]: checked,
        });
    };

    const onRecipientSearchInput = (field: string) => (search: string) => {
        setFormValues({
            ...formValues,
            [field]: search,
        });
    };

    const onChangeItemCode = (value: any, rowId: string) => {
        const newLines = formValues?.lines?.map(x => (x.id === rowId
            ? { ...x,
                itemCode: value || undefined }
            : x));
        setFormValues({
            ...formValues,
            lines: newLines,
        });
    };

    const onSelectReceiptItem = (obj: ReceiptSavedItem, rowId: string) => {
        const newLines: ReceiptLineItem[] = formValues.lines.map(x => (x.id === rowId
            ? { ...obj, id: rowId, receiptType: pageType, originalId: x.originalId, isSaved: false } as ReceiptLineItem
            : x));

        setFormValues({
            ...formValues,
            lines: newLines,
            savePackage: false,
        });
    };

    const onChangeReceiptUnit = (e: any, rowId: string) => {
        const newLines = formValues?.lines?.map(x => (x.id === rowId
            ? { ...x,
                receiptUnit: e.value || undefined }
            : x));
        setFormValues({
            ...formValues,
            lines: newLines,
        });
    };

    const onClickAddItem = () => {
        const addNewLine = {
            id: uuid(),
            itemCode: "",
            name: "",
            description: "",
            pricing: 0,
            discountPercentage: 0,
            receiptUnit: 1,
            quantity: 0,
            hasVat: true,
            isSaved: false,
            isAddNew: true,
            changeVatPercentage: false,
            vatPercentage: 20,
            setRecurrence: false,
            receiptRecurrenceFrequency: ReceiptRecurrenceFrequency.EmptySelection,
            changeDelayPayment: false,
            delayPaymentValue: 0,
            receiptDelayPayment: 0,
            changeHexagonRewardPercentage: false,
            hexagonRewardPercentage: 0,
            isPackageItem: false,
            receiptType: pageType,
        } as ReceiptLineItem;

        setFormValues({
            ...formValues,
            lines: [...formValues?.lines, addNewLine],
        });
    };

    const onClickDeleteItem = (rowId: string) => {
        const line = formValues?.lines?.find(x => x.id === rowId);
        if (!line) return;

        setDeleteItemOpenModal(true);
        setLineItemActive(line);
    };

    const onConfirmModalDeleteItem = () => {
        if (!lineItemActive) return;

        setFormValues({
            ...formValues,
            lines: [...formValues?.lines?.filter(x => x.id !== lineItemActive.id)],
        });
        setDeleteItemOpenModal(false);
    };

    const onCloseModalDeleteItem = () => {
        setDeleteItemOpenModal(false);
        setLineItemActive(undefined);
    };

    const getLineItemsValid = () => {
        if (!!formValues?.lines && formValues?.lines?.length > 0) {
            let isInvalid = formValues?.lines.some(e => isEmptyOrSpaces(e.name)
                                                        || isEmptyOrSpaces(e.description)
                                                        || isEmptyOrSpaces(e.itemCode) || !isReceiptItemCodeValid(e.itemCode)
                                                        || isEmptyOrSpaces(e.pricing) || e.pricing === 0 || e.pricing?.toString() === "0"
                                                        || isEmptyOrSpaces(e.quantity) || e.quantity === 0 || e.quantity?.toString() === "0");

            if (!isInvalid) { // valid so far, check for duplicate item codes
                isInvalid = areDuplicatesPresent(formValues.lines.filter(x => formValues.savePackage || x.isSaved).map(x => x.itemCode));
            }

            return !isInvalid;
        }
        return false;
    };

    const getReceiptsValid = () => formValues.recipient && !isEmptyOrSpaces(formValues.recipient.id);

    const getReceiptPackagesValid = () => (formValues.savePackage ? !isEmptyOrSpaces(formValues.packageName) : true);

    const isValid = () => getLineItemsValid() && getReceiptsValid() && getReceiptPackagesValid();

    const OnSave = (overrideEditMode: boolean = false) => () => {
        setProcessing(true);
        if (!isValid()) {
            setShowValidation(true);
            setProcessing(false);
            return;
        }

        if (isEditMode && !overrideEditMode) { // We need to open a confirmation modal in edit mode
            toggleConfirmUpdateModalOpen();
            setProcessing(false);
            return;
        }

        const submitFormData = {
            ...formValues,
            isOffsitePayment: formValues.paymentType === ReceiptPaymentType.Offsite,
            lines: formValues?.lines?.map(x => ({ ...x, itemCode: toPaddedNumber(x.itemCode) })),
        };

        post<CreateReceiptResponse>(Urls.receipts.create, submitFormData).then(response => {
            history.push(`/${pageTypePluralLowercase}/${response.id}/details`, { displayCreatedSuccessMessage: !isEditMode, displayUpdatedSuccessMessage: isEditMode });
            if (isEditMode) { // Refresh the page and display the success status at the top
                window.location.reload();
            }
        }).catch(error => {
            setPopupErrorMessage(error, true);
        }).finally(() => {
            setProcessing(false);
            if (isEditMode) toggleConfirmUpdateModalOpen();
        });
    };

    const getItemCodeMessageError = (itemCode: string) => {
        let msg: string = "";
        if (isEmptyOrSpaces(itemCode) && showValidation) {
            msg = "This field is required";
        } else if (!isReceiptItemCodeValid(itemCode) && showValidation) {
            msg = "This field must be a number with a maximum of six digits";
        } else if (showValidation && formValues.lines && isDuplicated(itemCode, formValues.lines.filter(x => formValues.savePackage || x.isSaved).map(x => x.itemCode))) {
            msg = "Item codes must be unique when saving items";
        }
        return msg;
    };

    const onClickMoreOptionsItem = (rowId: string) => {
        const line = formValues?.lines?.find(x => x.id === rowId);
        if (!line) return;

        setMoreOptionsItemOpenModal(true);
        setLineItemActive({
            ...line,
            changeVatPercentage: line.vatPercentage !== 0,
            setRecurrence: line.receiptRecurrenceFrequency !== ReceiptRecurrenceFrequency.EmptySelection,
            changeDelayPayment: line.delayPaymentValue !== 0,
            changeHexagonRewardPercentage: line.hexagonRewardPercentage !== 0,
        });
    };

    const onUpdateMoreOptionsItem = (itemLine: any) => {
        const newLines = formValues?.lines?.map(x => (x.id === itemLine.id
            ? itemLine
            : x));
        setFormValues({
            ...formValues,
            lines: newLines,
        });

        setMoreOptionsItemOpenModal(false);
        setLineItemActive(undefined);
    };

    const toggleLoadingLineItems = () => setLoadingLineItems(prevState => !prevState);

    return (
        <div className="tw-mt-12" id="form-start">
            {isEditMode && !location.state && ( // We ought to display the download PDF buttons when we're editing
                <div className="tw-space-x-2">
                    <ReceiptDownloadPdf receiptId={formValues.id} />
                    <ReceiptDownloadPdf receiptId={formValues.id} print />
                </div>
            )}
            {isEditMode && location.state && (location.state.displayCreatedSuccessMessage || location.state.displayUpdatedSuccessMessage) && formValues.referenceNumber && (
                <Alert color="success" className="tw-space-y-2">
                    <div className="tw-text-base">
                        {pageTypeString} <b>#{toPaddedNumber(formValues.referenceNumber)}</b> {location.state.displayCreatedSuccessMessage ? "created" : "updated"} successfully.
                    </div>
                    <div className="tw-space-x-2">
                        <ReceiptDownloadPdf receiptId={formValues.id} />
                        <ReceiptDownloadPdf receiptId={formValues.id} print />
                        <ReceiptsSendToRecipient receiptId={formValues.id} />
                    </div>
                </Alert>
            )}
            <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-3 tw-mt-4">
                <ReceiptsRecipientsSearch
                    onChange={onRecipientSearchInput("email")}
                    label="Recipient E-mail Address"
                    className="tw-flex-grow-[1.3]"
                    formValues={formValues}
                    setFormValues={setFormValues}
                    data-testid="email"
                    validationError={showValidation && isEmptyOrSpaces(formValues.recipient?.id) ? "Please select a recipient" : ""}
                    disabled={isInvoiceInputDisabled}
                />
                <Textbox
                    className="tw-flex-grow-[1.3]"
                    label="Recipient"
                    disabled
                    data-testid="recipient"
                    value={`${formValues.recipient.firstName} ${formValues.recipient.surname}`}
                />
                <ReceiptPreviousPackagesDropdown
                    formValues={formValues}
                    setFormValues={setFormValues}
                    className="tw-flex-grow-2"
                    toggleLoading={toggleLoadingLineItems}
                    disabled={isInvoiceInputDisabled}
                />
                <Textbox
                    className={`tw-flex-grow-[0.5] ${!formValues.referenceNumber && "tw-invisible"}`}
                    label={`${pageTypeString} Reference No.`}
                    disabled
                    data-testid="receipt-ref-no"
                    value={formValues.referenceNumber ? toPaddedNumber(formValues.referenceNumber) : ""}
                />
            </div>
            <Label>Payment Term</Label>
            <div className="tw-flex tw-space-x-3 tw-mt-4">
                <RadioButton
                    id="1"
                    label="Online Payment"
                    checked={formValues.paymentType === ReceiptPaymentType.Online}
                    onChange={() => onPaymentTypeRadioInput(ReceiptPaymentType.Online)}
                    name="online-payment"
                    disabled={isInvoiceInputDisabled}
                />
                <RadioButton
                    id="2"
                    label="Offsite Payment"
                    checked={formValues.paymentType === ReceiptPaymentType.Offsite}
                    onChange={() => onPaymentTypeRadioInput(ReceiptPaymentType.Offsite)}
                    name="offline-payment"
                    disabled={isInvoiceInputDisabled}
                />
            </div>
            <Label className="tw-font-semibold tw-mt-8">Line Items</Label>
            <hr />
            {loadingLineItems
                ? (
                    <div className="tw-flex tw-justify-center tw-items-center tw-space-x-2 tw-py-1">
                        <Spinner />
                        <span className="">Loading line items...</span>
                    </div>
                )
                : formValues.lines.map((row, index) => (
                    <div key={row.id}>
                        <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-3">
                            <ReceiptItemsSearch
                                onChange={e => onChangeItemCode(e, row.id)}
                                label={index === 0 ? "Item Code" : ""}
                                className="lg:tw-w-1/6"
                                maxLength={20}
                                value={row.itemCode}
                                testId={`item-code-${index}`}
                                onSelect={e => onSelectReceiptItem(e, row.id)}
                                error={getItemCodeMessageError(row.itemCode)}
                                disabled={isInvoiceInputDisabled}
                            />
                            <Textbox
                                label={index === 0 ? "Item Name" : ""}
                                maxLength={50}
                                className="lg:tw-w-1/6 tw-font-semibold"
                                onChange={onLineItemInput("name", row.id)}
                                value={row.name}
                                data-testid={`item-name-${index}`}
                                error={isEmptyOrSpaces(row.name) && showValidation ? "This field is required" : ""}
                                disabled={isInvoiceInputDisabled}
                            />
                            <Textbox
                                className="lg:tw-w-1/5 tw-font-semibold"
                                label={index === 0 ? "Item Description" : ""}
                                maxLength={50}
                                onChange={onLineItemInput("description", row.id)}
                                value={row.description}
                                data-testid={`item-description-${index}`}
                                error={isEmptyOrSpaces(row.description) && showValidation ? "This field is required" : ""}
                                disabled={isInvoiceInputDisabled}
                            />
                            <div className="tw-flex tw-space-x-3">
                                <NumberField
                                    min={0}
                                    max={100}
                                    onChange={onLineItemInput("discountPercentage", row.id)}
                                    value={row.discountPercentage}
                                    label={index === 0 ? "Discount" : ""}
                                    suffixChar="%"
                                    className="tw-w-1/6 tw-font-semibold"
                                    maxDecimalPlaces={2}
                                    testId={`discount-${index}`}
                                    disabled={isInvoiceInputDisabled}
                                />
                                <NumberField
                                    min={0}
                                    max={9999999}
                                    onChange={onLineItemInput("pricing", row.id)}
                                    value={row.pricing}
                                    label={index === 0 ? "Pricing" : ""}
                                    prefixChar="£"
                                    className="tw-w-1/5 tw-font-semibold"
                                    maxDecimalPlaces={2}
                                    testId={`pricing-${index}`}
                                    maxLength={10}
                                    error={(isEmptyOrSpaces(row.pricing) || row.pricing === 0 || row.pricing?.toString() === "0")
                                            && showValidation ? "This field is required, greater than 0" : ""}
                                    disabled={isInvoiceInputDisabled}
                                />
                                <div className="tw-w-1/6 tw-font-semibold">
                                    {index === 0 ? (<Label>Unit</Label>) : (<></>)}
                                    <Select
                                        value={receiptUnits.find(x => x.value === row.receiptUnit)}
                                        onChange={(e) => onChangeReceiptUnit(e, row.id)}
                                        options={receiptUnits}
                                        data-testid={`item-unit-${index}`}
                                        isDisabled={isInvoiceInputDisabled}
                                    />
                                </div>
                                <NumberField
                                    min={0}
                                    max={9999999}
                                    onChange={onLineItemInput("quantity", row.id)}
                                    value={row.quantity}
                                    label={index === 0 ? "Quantity" : ""}
                                    className="tw-w-1/6 tw-font-semibold"
                                    maxDecimalPlaces={0}
                                    testId={`quantity-${index}`}
                                    maxLength={10}
                                    error={(isEmptyOrSpaces(row.quantity) || row.quantity === 0 || row.quantity?.toString() === "0")
                                            && showValidation ? "This field is required, greater than 0" : ""}
                                    disabled={isInvoiceInputDisabled}
                                />
                                <div className="tw-flex-col tw-relative tw-w-1/5 tw-font-semibold">
                                    {index === 0 ? (<Label>Item Price</Label>) : (<></>)}
                                    <div className="mt-2" data-testid={`item-total-${index}`}>
                                        {toCurrencyWithCommas(calculateRowTotal(row))}
                                    </div>
                                </div>
                                <div className="tw-flex-col tw-font-semibold">
                                    {index === 0 ? (<Label>&nbsp;</Label>) : (<></>)}
                                    <div className="mt-2">
                                        <button
                                            type="button"
                                            data-testid={`btn-close-item-${index}`}
                                            onClick={() => onClickDeleteItem(row.id)}
                                            disabled={isInvoiceInputDisabled}
                                        >
                                            <i className="fa fa-times" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-flex tw-space-x-3 tw-mt-4 lg:tw-mt-0">
                            <Checkbox
                                checked={row.hasVat}
                                onChange={checked => onLineItemCheckboxInput("hasVat", row.id, checked)}
                                id={`${row.id}-vat-check`}
                                label="Add VAT"
                                className="tw-font-semibold"
                                testId={`chk-addVat-${index}`}
                                disabled={isInvoiceInputDisabled}
                            />
                            <Checkbox
                                checked={row.isSaved}
                                onChange={checked => onLineItemCheckboxInput("isSaved", row.id, checked)}
                                id={`${row.id}-save-item-check`}
                                label="Save this item"
                                className="tw-font-semibold"
                                testId={`chk-save-${index}`}
                                disabled={isInvoiceInputDisabled}
                            />
                            <button
                                type="button"
                                className="tw-flex tw-font-semibold"
                                onClick={() => onClickMoreOptionsItem(row.id)}
                                id={`more-options-button-${row.id}`}
                                disabled={isInvoiceInputDisabled}
                            >
                                <i className="fa fa-ellipsis-h " />
                            </button>
                            <Label
                                for={`more-options-button-${row.id}`}
                                className="hover:tw-cursor-pointer"
                            >
                                MORE OPTIONS
                            </Label>
                        </div>
                        <hr />
                    </div>
                ))}
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-6 tw-justify-between tw-mt-2 tw-mb-6 lg:tw-mb-0">
                <div>
                    <button
                        type="button"
                        className="btn btn-outline-primary tw-grow-0"
                        onClick={onClickAddItem}
                        disabled={isInvoiceInputDisabled}
                    >
                        <i className="fa fa-plus-circle mr-2" />
                        Add Item
                    </button>
                </div>
                <div className="tw-flex-col items-center lg:tw-w-1/5 tw-space-y-4">
                    <div className="tw-flex tw-justify-between tw-items-end">
                        <span>SUBTOTAL</span>
                        <span>{toCurrencyWithCommas(calculateSubTotal(formValues.lines))}</span>
                    </div>
                    <div className="tw-flex tw-justify-between tw-items-end">
                        <span>VAT</span>
                        <span>{toCurrencyWithCommas(calculateVatTotal(formValues.lines))}</span>
                    </div>
                    <div className="tw-flex tw-justify-between tw-items-end tw-space-x-3">
                        <span className="tw-whitespace-nowrap tw-flex-grow">Total {pageTypeString} Price</span>
                        <span className="tw-text-lg tw-font-bold">{toCurrencyWithCommas(calculateTotalWithVatAndDiscount(formValues.lines))}</span>
                    </div>
                </div>
            </div>
            <div className="tw-flex-col tw-space-y-6">
                <Checkbox
                    checked={formValues.savePackage}
                    onChange={checked => onCheckboxInput("savePackage", checked)}
                    id="save-package-checkbox"
                    label={
                        <div className="tw-flex">
                            Save this {pageTypeLowercase} as a package
                        </div>
                    }
                    testId="save-package"
                    disabled={isInvoiceInputDisabled}
                />
                <Textbox
                    label="Enter Package Name *"
                    disabled={!formValues.savePackage || isInvoiceInputDisabled}
                    onChange={onUserInput("packageName")}
                    value={formValues.packageName}
                    className="md:tw-w-1/4"
                    data-testid="package-name"
                    isRequired={formValues.savePackage}
                    error={showValidation && formValues.savePackage && isEmptyOrSpaces(formValues.packageName) ? "This field is required" : ""}
                />
                <Textbox
                    className="md:tw-w-1/2"
                    disabled={!formValues.savePackage || isInvoiceInputDisabled}
                    onChange={onUserInput("packageDescription")}
                    value={formValues.packageDescription}
                    label="Package Description"
                    data-testid="package-description"
                />
            </div>
            <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-12">
                <Button
                    color="primary"
                    onClick={OnSave()}
                    disabled={processing || (pageType === ReceiptType.Invoice && isEditMode)} // Disable the editing of an invoice
                    data-testid="btn-save"
                    size="lg"
                >
                    {processing ? (<Spinner className="mx-auto" />) : "Save"}
                </Button>
            </div>
            <ConfirmCancelModal
                isOpen={deleteItemOpenModal}
                text="Are you sure that you want to remove this item?"
                onConfirm={onConfirmModalDeleteItem}
                onClose={onCloseModalDeleteItem}
            />
            {isEditMode && <ConfirmCancelModal
                isOpen={confirmUpdateModalOpen}
                text={processing ? (<Spinner className="tw-mx-auto" />) : (<div>Are you sure you want to update this {pageTypeLowercase}?</div>)}
                onConfirm={OnSave(true)}
                onClose={toggleConfirmUpdateModalOpen}
            />}
            <ReceiptMoreOptionsModal
                isOpen={moreOptionsItemOpenModal}
                lineItem={lineItemActive}
                setLineItem={setLineItemActive}
                onCancel={() => setMoreOptionsItemOpenModal(false)}
                onUpdate={e => onUpdateMoreOptionsItem(e)}
            />
        </div>
    );
};

export { ReceiptsDetailsTab };
