import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Row, Col, Label, Modal, ModalBody } from "reactstrap";
import { v4 as uuid } from "uuid";

import Textbox from "@/Components/Textbox";
import RadioButton from "@/Components/RadioButton";
import { useSupplierContext } from "@/Context/SupplierContext";
import { saveCustomField } from "@/Apis/Fields";

export const types = {
    text: "Text",
    attachment: "UploadAttachment",
    yesNoSelection: "YesNo",
};

const AddFieldModal = ({ onClose }) => {
    const supplier = useSupplierContext();

    const [displayText, setDisplayText] = useState("");
    const [type, setType] = useState(types.text);
    const [error, setError] = useState("");

    const onSetFieldName = event => {
        setError("");
        setDisplayText(event.target.value);
    };
    const onSetType = fieldType => () => {
        setError("");
        setType(fieldType);
    };

    const save = () => {
        const id = uuid();
        saveCustomField(id, { id, displayText, type })
            .then((response) => {
                onClose();
                supplier.onCustomFieldAdd(response);
            })
            .catch((err) => {
                setError(err.message);
            });
    };

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white">
            <ModalBody className="d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative">
                <h3 className="font-weight-bold mb-4">Add Custom Field</h3>

                <Textbox
                    label="Enter the label for a custom field."
                    placeholder="Custom Field Label"
                    value={displayText}
                    onChange={onSetFieldName}
                />

                <Row>
                    <Col xs="12"><Label>Select custom input</Label></Col>
                    <Col xs="12" lg="3" className="mb-2">
                        <RadioButton
                            checked={type === types.text}
                            onChange={onSetType(types.text)}
                            label="Text Input"
                            name="text"
                            id={types.text}
                        />
                    </Col>
                    <Col xs="12" lg="3" className="mb-2">
                        <RadioButton
                            checked={type === types.attachment}
                            onChange={onSetType(types.attachment)}
                            label="Attachment"
                            name="attachment"
                            id={types.attachment}
                        />
                    </Col>
                    <Col xs="12" lg="6" className="mb-2">
                        <RadioButton
                            checked={type === types.yesNoSelection}
                            onChange={onSetType(types.yesNoSelection)}
                            label="Yes/No Selection"
                            name="yesNoSelection"
                            id={types.yesNoSelection}
                        />
                    </Col>
                </Row>
                {error && <Alert color="danger">{error}</Alert>}

                <div className="d-flex mt-4 pt-3 justify-content-center">
                    <Button color="secondary" className="mr-2" onClick={onClose}>Cancel</Button>
                    <Button color="primary" disabled={!displayText} onClick={save}>Add</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

AddFieldModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export { AddFieldModal };
