/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { Collapse } from "reactstrap";

import OrderConfirmedImage from "@/Assets/Images/PostPurchase/order-confirmed-celebration.png";
import CompanyFormationImage from "@/Assets/Images/PostPurchase/company-formation.png";
import OrderConfirmedBanner from "@/Components/OrderConfirmedBanner";
import { defaultDesktopHeaderItems,
    defaultMobileHeaderItems,
    MarketplaceCarousel } from "@/Components/MarketplaceCarousel";
import Spinner from "@/Components/Spinner";
import PostPurchaseProduct from "@/Components/PostPurchase/PostPurchaseProduct";
import BuildAPackage from "@/Pages/PostPurchase/BuildAPackage";
import ReferAFriend from "@/Pages/PostPurchase/ReferAFriend";
import { useProducts } from "@/Pages/Marketplace/MarketplacePage/useProducts";
import urlHelper from "@/Utils/urlHelper";
import { decodeCustomToken, getToken } from "@/Utils/authentication";
import { PostPurchaseToken } from "@/Apis/PostPurchase";
import { convertToken } from "@/Utils/objectHelper";
import { OrderType } from "@/Apis/SalesManagement";
import { PostPurchaseCheckoutLocationState } from "@/Apis/Orders";
import { ProductType } from "@/Apis/Products/ProductType";

const PostPurchase = () => {
    // Hooks
    const location = useLocation<PostPurchaseCheckoutLocationState>();
    const { push } = useHistory();
    // Context
    const { products, searchProducts, searchState, loading } = useProducts();
    // State
    const [tokenData, setTokenData] = useState<PostPurchaseToken>();
    const [productsExpanded, setProductsExpanded] = useState<boolean>(false);
    const token = urlHelper.getUrlParam(location.search, "token");

    // Functions
    const toggleProductsExpanded = () => setProductsExpanded(prevState => !prevState);
    const extractTokenData = () => decodeCustomToken(token);

    // Seed our products
    useEffect(() => {
        const basketId = location.state?.basketId ?? tokenData?.BasketId;
        if (basketId) {
            searchProducts({
                ...searchState,
                basketId,
            });
            // Scroll to top
            const element = document.getElementById("mainScrollableDiv");
            if (element) {
                element.scrollTop = 0;
            }
        }
    }, [location, tokenData]);

    // Check for a token and set it to state
    useEffect(() => {
        // We must be logged in
        const loginUri = `/login?returnUrl=/post-purchase-checkout?token=${token}`;
        const loggedIn = !!getToken(); // The react redux useSelector doesn't seem to be accurate on first load

        if (!loggedIn) {
            push(loginUri);
            return;
        }

        // We're logged in, extract the token and set it in state
        const tokenData = extractTokenData();

        if (!tokenData.error) {
            setTokenData(convertToken<PostPurchaseToken>(tokenData));
        }
    }, [location]);

    useEffect(() => {
        // Check whether we need to expand for all products
        setProductsExpanded(tokenData?.ViewAllProducts ?? false);
    }, [tokenData]);

    document.title = "BSC - Post Purchase";

    if (!location.state?.basketId && !tokenData?.BasketId) { //  We don't have a basket id in either the location's state or a token, we cannot display the page
        return (
            <div className="tw-h-screen tw-bg-gray-100 tw-p-5">
                <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center">
                    <i className="fa fa-exclamation-triangle tw-text-amber-400 tw-text-lg lg:tw-text-2xl" />
                    <div className="tw-text-brand-primary tw-text-lg lg:tw-text-xl">No basket information to display</div>
                </div>
                <div
                    className="tw-mt-4 tw-text-sm lg:tw-text-base"
                >
                    If you have submitted an order, please click the link in your confirmation email to retrieve the post purchase information.
                </div>
            </div>
        );
    }

    const firstProduct: ProductType | undefined = products?.length > 0 ? products.sort((a, b) => b.initialCharge - a.initialCharge)[0] : undefined;

    return (
        <div className="tw-bg-gray-100">
            <div className="tw-ml-auto tw-mr-auto tw-w-full tw-max-w-[1240px]">
                <div className="tw-p-1 sm:tw-p-5 tw-space-y-5 tw-flex-grow">
                    {/* Order Confirmation */}
                    {
                        !loading && products && products.length > 0 && (
                            <>
                                {
                                    products && products.find(x => x.orderType === OrderType.FormationsProduct) ? (
                                        <OrderConfirmedBanner
                                            logoUrl={CompanyFormationImage}
                                            title="Congratulations on forming your company!"
                                            fontAwesomeTitleLogoString="fa fa-check-circle"
                                            body={(
                                                <>
                                                    Your order reference numbers can be found below.{" "}
                                                    We are working on completing your company formation and{" "}
                                                    will keep you up to date with how things will progress from here.{" "}
                                                    You can check the progress in your <Link to="/customer-orders" className="tw-underline">Order Management</Link>.
                                                </>
                                            )}
                                        />
                                    )
                                        : (
                                            <OrderConfirmedBanner
                                                logoUrl={OrderConfirmedImage}
                                                title="Your order is confirmed!"
                                                fontAwesomeTitleLogoString="fa fa-check-circle"
                                                body={(
                                                    <>
                                                        Your order reference numbers can be found below.{" "}
                                                        Your supplier has received your order and will be in touch{" "}
                                                        shortly to keep you up to date with how things will progress from here. You can check the{" "}
                                                        progress in your <Link to="/customer-orders" className="tw-underline">Order Management</Link>.
                                                    </>
                                                )}
                                            />
                                        )
                                }
                            </>
                        )
                    }
                    {/* Product Items */}
                    {loading && <Spinner className="mx-auto my-3" />}
                    {products && products.length > 0 && (
                        <>
                            {/* Display the first product, the remaining products are hidden in an accordion */}
                            {firstProduct && (
                                <PostPurchaseProduct
                                    product={firstProduct}
                                    downloadAttachmentsOnFirstRender={tokenData?.ProductId === firstProduct.id && tokenData.DownloadAttachments}
                                    modalOpen={tokenData?.ProductId === firstProduct.id && tokenData?.OpenModal}
                                />
                            )}
                            {products.length > 1 && (
                                <>
                                    <Collapse isOpen={productsExpanded} className="tw-flex-col !tw-space-y-2">
                                        {products
                                            .sort((a, b) => b.initialCharge - a.initialCharge) // Order by highest price first
                                            .slice(1) // Skip the first item as we are displaying it outside the accordion
                                            .map(product => {
                                                const isProductInToken = tokenData?.ProductId === product.id;

                                                return (
                                                    <PostPurchaseProduct
                                                        product={product}
                                                        downloadAttachmentsOnFirstRender={isProductInToken && tokenData?.DownloadAttachments}
                                                        modalOpen={isProductInToken && tokenData?.OpenModal}
                                                        key={product.id}
                                                    />);
                                            })}
                                    </Collapse>
                                    <button
                                        className="tw-w-full tw-border-0 tw-text-brand-primary tw-text-center tw-text-base hover:tw-underline tw-font-semibold"
                                        type="button"
                                        onClick={toggleProductsExpanded}
                                    >
                                        <span
                                            className="tw-text-sm lg:tw-text-base"
                                        >
                                            {productsExpanded ? "Collapse other items" : `Load ${products.length - 1} more items`}
                                        </span>
                                    </button>
                                </>
                            )}
                        </>
                    )}
                    <div className="tw-rounded-lg tw-bg-white tw-p-1 sm:tw-p-3 tw-border">
                        {/* Marketplace */}
                        <div className="tw-font-semibold tw-text-brand-primary tw-text-lg lg:tw-text-2xl">
                            Come check out hundreds of Suppliers in our Marketplace!
                        </div>
                        <MarketplaceCarousel
                            desktopSideBarClassName="!tw-mx-auto"
                            desktopNoSideBarClassName="tw-w-[calc(100vw-76px)] !tw-mx-auto"
                            desktopItemClassName="pt-3 px-2 pb-2 image-item"
                            mobileItemClassName="pt-3 px-2 pb-2 image-item tw-w-full"
                            mobileClassName="tw-w-100"
                            desktopHeaderItems={defaultDesktopHeaderItems.map(x => ({ ...x, link: `/marketplace${x.link}` }))}
                            mobileHeaderItems={defaultMobileHeaderItems.map(x => ({ ...x, link: `/marketplace${x.link}` }))}
                            linkClassName="tw-relative"
                            displayMarketplaceLink
                        />
                        {/* Build a Package */}
                        <BuildAPackage />
                        {/* Refer a Friend */}
                        <ReferAFriend />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPurchase;
