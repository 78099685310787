import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";

import styles from "./styles.module.scss";

import * as globalConstants from "@/Store/Global/constants";

const CategoryTabs = (props) => {
    const categoriesToDisplay = props.categories;
    if (props.categories) {
        // if enquiry form exists, this will push it to the end of the list.
        categoriesToDisplay.push(categoriesToDisplay.splice(categoriesToDisplay.indexOf(globalConstants.ENQUIRY_FORM), 1)[0]);
    }
    return (
        <Nav tabs className="d-flex justify-content-center mb-4">
            {props.categories.map(x => (
                <NavItem key={x}>
                    <NavLink
                        className={classnames(props.activeCategoryTab === x ? styles.activeTab : styles.tab, "font-weight-bold")}
                        style={props.activeCategoryTab === x ? { borderBottomColor: props.headerColour } : {}}
                        onClick={() => { props.onChange(x); }}
                    >
                        {x}
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

CategoryTabs.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeCategoryTab: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    headerColour: PropTypes.string.isRequired,
};

export default CategoryTabs;
