import React, { useState } from "react";
import { Table, Input, Button } from "reactstrap";

import styles from "./styles.module.scss";

import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { ToolTip } from "@/Components/ToolTip";
import { Sale, SalesPaymentStatus } from "@/Apis/SalesManagement";
import { NotesColumn } from "@/Pages/SalesManagement/NotesColumn";
import { PaymentHistoryModal } from "@/Modals/PaymentHistoryModal";

interface Props {
    sales: Sale[],
    onStatusChanged: (saleId: string, status: SalesPaymentStatus) => void
    submitNotes: (saleId: string, notes: string) => void;
}

const DataTable = ({ sales, onStatusChanged, submitNotes }: Props) => {
    const [selectedOrderId, setSelectedOrderId] = useState("");

    const onPaymentStatusUpdated = saleId => e => {
        onStatusChanged(saleId, parseInt(e.target.value, 10));
    };

    const formatType = (type: string) => type.split(", ").map((char) => {
        switch (char) {
            case "P":
                return "Product";
            case "BQ":
                return "Bespoke Quotation";
            case "U":
                return "Upsell";
            case "PP":
                return "Product from Package";
            case "S":
                return "Subscription";
            case "OSP":
                return "Offsite Payment";
            default:
                return "NA";
        }
    }).join(", ");

    const orderSelected = (orderId) => setSelectedOrderId(orderId);

    return (
        <>
            <Table borderless>
                <thead>
                    <tr className={styles.row}>
                        <th className={styles.w10}>Order Number</th>
                        <th className={styles.w5}>Order Date</th>
                        <th className={styles.w5}>Type</th>
                        <th className={styles.w20}>Item</th>
                        <th className={styles.w10}>Supplier</th>
                        <th className={styles.w5}>Referrer</th>
                        <th className={styles.w10}>Order Status</th>
                        <th className={styles.w5}>Price</th>
                        <th className={styles.w10}>Payment Status</th>
                        <th className={styles.w5}>Cardholder</th>
                        <th className={styles.w10}>Payment Date & Payment History</th>
                        <th className={styles.w5}>Notes</th>
                    </tr>
                </thead>

                <tbody>
                    {sales.length === 0 && <p className="m-2 mt-4">No results found</p>}

                    {sales.length > 0 && sales.map(x => (
                        <tr className={styles.row} key={x.orderId}>
                            <td className="text-center">
                                {x.orderNumber}
                            </td>
                            <td>
                                {x.orderDate}
                            </td>
                            <td className="pointer">
                                <ToolTip id={`pop-orderType-${x.orderId}`} description={formatType(x.orderType)}>
                                    {x.orderType}
                                </ToolTip>
                            </td>
                            <td className="pointer">
                                <ToolTip id={`pop-itemName-${x.orderId}`} description={x.orderItem.description}>
                                    <a href={`/marketplace/supplier-directory/${x.supplier.id}`} target="_blank" className="text-primary" rel="noopener noreferrer">
                                        {x.orderItem.name}
                                    </a>
                                </ToolTip>
                            </td>
                            <td>
                                <span className="text-muted text-uppercase d-block font-size-sm">{x.supplier.reference}</span>
                                {x.supplier.name}
                            </td>
                            <td>
                                <span className="text-muted text-uppercase d-block font-size-sm">{x.referrer.reference}</span>
                                {x.referrer.name || "-"}
                            </td>
                            <td>
                                <OrderStatusIcon status={x.orderStatus} />
                            </td>
                            <td>
                                {x.isSubscription && <span className="text-muted text-uppercase d-block font-size-sm">Subscription</span>}
                                £{x.price.toFixed(2)}
                            </td>
                            <td>
                                <Input type="select" value={x.paymentStatus} onChange={onPaymentStatusUpdated(x.saleId)}>
                                    <option value={2}>Paid</option>
                                    <option value={0}>Unpaid</option>
                                    <option value={1}>On Hold</option>
                                    <option value={3}>Cancelled</option>
                                </Input>
                            </td>
                            <td>
                                {x.cardholderStatus}
                            </td>
                            <td>
                                <Button color="text" onClick={() => orderSelected(x.orderId)}>
                                    <span className="text-primary">{x.paymentDate}</span>
                                </Button>
                            </td>
                            <NotesColumn sale={x} submitNotes={submitNotes} />
                        </tr>))}
                </tbody>
            </Table>
            {selectedOrderId && <PaymentHistoryModal orderId={selectedOrderId} onClose={() => setSelectedOrderId("")} />}
        </>
    );
};

export { DataTable };
