import { useEffect } from "react";
import * as ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { hotjar } from "react-hotjar";

import { useConfigurationContext } from "@/Context/ConfigurationContext";

const Analytics = () => {
    const history = useHistory();
    const { analytics: { gaKey, hotjarId, hotjarSnippetVersion } } = useConfigurationContext();

    useEffect(() => {
        if (gaKey) {
            ReactGA.initialize(gaKey);
            history.listen(location => ReactGA.pageview(location.pathname));
        }
    }, [gaKey]);

    useEffect(() => {
        if (hotjarId && hotjarSnippetVersion) {
            hotjar.initialize(hotjarId, hotjarSnippetVersion);
        }
    }, [hotjarId, hotjarSnippetVersion]);

    return null;
};

export { Analytics };
