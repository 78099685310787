import React, { useContext, createContext, useState, useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { useConfigurationContext } from "@/Context/ConfigurationContext";

const LoggingContext = createContext<ApplicationInsights>(null as any);
const LoggingConsumer = LoggingContext.Consumer;
const useLoggingContext = () => useContext<ApplicationInsights>(LoggingContext);

const PAYMENTS_FAILED_TO_COMPLETE_BASKET = "PAYMENTS_FAILED_TO_COMPLETE_BASKET";
const PAYMENTS_PAYPAL_TRANSACTION_FAILED = "PAYMENTS_PAYPAL_TRANSACTION_FAILED";

interface Props {
    children: React.ReactNode
}

const LoggingProvider = ({ children }: Props) => {
    const [ready, setReady] = useState(false);
    const { instrumentationKey } = useConfigurationContext();
    const [appInsights, setAppInsights] = useState<ApplicationInsights>(new ApplicationInsights({
        config: { disableTelemetry: true },
    }));

    useEffect(() => {
        const enable = Boolean(instrumentationKey);

        if (enable) {
            const reactPlugin = new ReactPlugin();
            const browserHistory = createBrowserHistory({ basename: "" });
            const applicationInsights = new ApplicationInsights({
                config: {
                    instrumentationKey,
                    disableTelemetry: false,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory },
                    },
                    disableAjaxTracking: false,
                    disableCorrelationHeaders: false,
                },
            });
            applicationInsights.loadAppInsights();

            setReady(true);
            setAppInsights(applicationInsights);
        }
    }, [instrumentationKey]);

    return ready ? <LoggingContext.Provider value={appInsights}>{children}</LoggingContext.Provider> : <>{children}</>;
};

export {
    useLoggingContext,
    LoggingProvider,
    LoggingConsumer,
    LoggingContext,

    PAYMENTS_FAILED_TO_COMPLETE_BASKET,
    PAYMENTS_PAYPAL_TRANSACTION_FAILED,
};
