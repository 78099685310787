import React, { useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useHistory } from "react-router";

import styles from "../ServiceModal/styles.module.scss";

import { FieldTransferSelector } from "@/Components/FieldTransferSelector";

const EditBespokeQuoteFormFieldsModal = ({ fields, selectedFields, onComplete }) => {
    const history = useHistory();
    const [selected, setSelected] = useState(selectedFields);

    const onUpdated = key => (e) => {
        const value = e.target.value;

        switch (key) {
            case "selectedFields":
                setSelected(value);
                break;
            default:
                throw new Error(`Can't handle change for event ${key}`);
        }
    };

    const onUpdateClicked = () => onComplete(selected);

    const goBack = () => history.push(history.location.pathname.replace("/form-fields", ""));

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white rounded-0">
            <ModalBody className={classnames(styles.container, "d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative")}>
                <div>
                    <h5 className="font-weight-bold mb-3">Required fields from the quote</h5>
                    <p>
                        Please select fields that you will require from the user when proceeding with the quote.
                        If you would like a custom field, please add one using the button below.
                    </p>
                </div>

                <FieldTransferSelector fields={fields} selectedFields={selected} onChange={onUpdated} />

                <div className="d-flex justify-content-end">
                    <Button color="secondary" className="mx-2" onClick={goBack}>Cancel</Button>
                    <Button className="btn-tertiary" onClick={onUpdateClicked}>Update the quote</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

EditBespokeQuoteFormFieldsModal.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayText: PropTypes.string.isRequired,
        ordinal: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    selectedFields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    })),
    onComplete: PropTypes.func.isRequired,
};

export { EditBespokeQuoteFormFieldsModal };
