import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

const Header = ({
    supplierLogo,
    supplierName,
    supplierAddressLine1,
    supplierAddressLine2,
    supplierCity,
    supplierPostCode,
    supplierVatNumber,
    supplierRegistrationNumber,
    customerName,
    customerAddressLine1,
    customerAddressLine2,
    customerCity,
    customerPostCode,
    customerRegistrationNumber,
    customerVatNumber,
    orderReference,
    datePaid,
}) => {
    const HeaderSupplierDetails = () => (
        <View style={{ flexDirection: "row", justifyContent: "space-between", minHeight: 155, padding: "30", backgroundColor: "#f7f7f7" }}>
            <View style={{ flexDirection: "column" }}>
                <Image source={supplierLogo} style={{ width: "auto", height: "45" }} />
            </View>

            <View style={{ flexDirection: "column", color: "#999", borderLeft: "1 solid #e0e0e0", paddingLeft: 20 }}>
                <Text>{supplierName}</Text>
                <Text>{supplierAddressLine1}</Text>
                <Text>{supplierAddressLine2}</Text>
                <Text>{supplierCity}</Text>
                <Text>{supplierPostCode}</Text>
                <Text style={{ marginTop: 12 }}>{supplierVatNumber}</Text>
                <Text>{supplierRegistrationNumber}</Text>
            </View>
        </View>
    );

    const HeaderCustomerDetailsAndOrderRef = () => (
        <View style={{ flexDirection: "row", justifyContent: "space-between", minHeight: 135, padding: "0 30 0 30", backgroundColor: "#f7f7f7" }}>
            <View style={{ flexDirection: "column" }}>
                <Text style={{ fontFamily: "LatoBold", color: "#333333" }}>INVOICE TO:</Text>
                <Text style={{ marginTop: 12 }}>{customerName}</Text>
                <Text>{customerVatNumber}</Text>
                <Text>{customerRegistrationNumber}</Text>
                <Text>{customerAddressLine1}</Text>
                <Text>{customerAddressLine2}</Text>
                <Text>{customerCity}</Text>
                <Text>{customerPostCode}</Text>
            </View>

            <View style={{ flexDirection: "column" }}>
                <Text style={{ fontFamily: "LatoBold", color: "#333" }}>INVOICE #{orderReference.toUpperCase()}</Text>
                <Text style={{ color: "#999", marginLeft: 72 }}>DATE: {datePaid}</Text>
            </View>
        </View>
    );

    return (
        <>
            <HeaderSupplierDetails />
            <HeaderCustomerDetailsAndOrderRef />
        </>);
};

Header.propTypes = {
    customerName: PropTypes.string.isRequired,
    customerAddressLine1: PropTypes.string,
    customerAddressLine2: PropTypes.string,
    customerCity: PropTypes.string,
    customerPostCode: PropTypes.string,
    customerRegistrationNumber: PropTypes.string,
    customerVatNumber: PropTypes.string,
    supplierName: PropTypes.string.isRequired,
    supplierLogo: PropTypes.string.isRequired,
    supplierAddressLine1: PropTypes.string,
    supplierAddressLine2: PropTypes.string,
    supplierCity: PropTypes.string,
    supplierPostCode: PropTypes.string,
    supplierRegistrationNumber: PropTypes.string,
    supplierVatNumber: PropTypes.string,
    orderReference: PropTypes.string.isRequired,
    datePaid: PropTypes.string.isRequired,
};

export { Header };
