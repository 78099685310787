import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, Label } from "reactstrap";
import classnames from "classnames";

import ShowHideIconLabel from "@/Components/ShowHideIconLabel";

class PasswordWithConfirmField extends React.PureComponent {
    state = {
        showPassword: false,
        showConfirmPassword: false,
    };

    render() {
        return (
            <>
                <div className="d-flex justify-content-end">
                    <ShowHideIconLabel
                        className={classnames(this.props.isReskin2022 ? "tw-hidden" : "")}
                        show={this.state.showPassword}
                        label="password"
                        onToggle={() => this.setState(prev => ({ showPassword: !prev.showPassword }))}
                    />
                </div>
                <FormGroup className={classnames(this.props.isReskin2022 ? "tw-w-full tw-relative" : "")}>
                    <Label className={classnames(
                        this.props.isReskin2022 && !this.props.displayLabels
                            ? "tw-sr-only"
                            : "tw-text-brand-primary tw-text-bold",
                    )}
                    >
                        Create Password *
                    </Label>
                    {this.props.isReskin2022 && (
                        <ShowHideIconLabel
                            show={this.state.showPassword}
                            hideLabel
                            className="tw-absolute tw-right-1"
                            onToggle={() => this.setState(prev => ({ showPassword: !prev.showPassword }))}
                        />
                    )}
                    <Input
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder={this.props.isReskin2022 ? "Enter your password" : "Your password"}
                        invalid={this.props.passwordInvalid}
                        onChange={this.props.onChange("password")}
                        value={this.props.password || ""}
                    />
                </FormGroup>
                <FormGroup className={classnames(this.props.isReskin2022 ? "tw-w-full tw-relative" : "")}>
                    <Label className={classnames(
                        this.props.isReskin2022 && !this.props.displayLabels
                            ? "tw-sr-only"
                            : "tw-text-brand-primary tw-text-bold",
                    )}
                    >
                        Confirm Password *
                    </Label>
                    {this.props.isReskin2022 && (
                        <ShowHideIconLabel
                            show={this.state.showConfirmPassword}
                            hideLabel
                            className="tw-absolute tw-right-1"
                            onToggle={() => this.setState(prev => ({ showConfirmPassword: !prev.showConfirmPassword }))}
                        />
                    )}
                    <Input
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        placeholder={this.props.isReskin2022 ? "Confirm your password" : "Confirm password"}
                        invalid={this.props.confirmPasswordInvalid}
                        onChange={this.props.onChange("confirmPassword")}
                        value={this.props.confirmPassword || ""}
                    />
                </FormGroup>
            </>
        );
    }
}

PasswordWithConfirmField.propTypes = {
    passwordInvalid: PropTypes.bool.isRequired,
    confirmPasswordInvalid: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    isReskin2022: PropTypes.bool,
    displayLabels: PropTypes.bool,
};

export default PasswordWithConfirmField;
