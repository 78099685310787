/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import Logo from "@/Assets/Icons/Solid/logo.svg";

const classes = "bg-white border-0 p-0 text-left d-flex align-items-center";

const SupplierNav = (props) => {
    const pushToArea = path => () => { props.push(path); };
    const toggleDash = () => { props.toggle(false); };
    return (
        <div className={classnames(styles.secondLevelContainer, "bg-white")}>
            <button
                type="button"
                onClick={toggleDash}
                className={classnames(styles.logoContainer, "my-4 mx-3 bg-white border-0 p-0")}
            >
                <Logo />
            </button>
            <button
                type="button"
                onClick={props.closeSecondLevel}
                className="bg-dark text-white border-0 w-100 p-0 text-left mb-3"
            >
                <div className={styles.icon}><i className="fa fa-arrow-circle-left" /></div>
                <span>Supplier Dashboard</span>
            </button>
            <button
                data-testid="/mini-site"
                type="button"
                onClick={pushToArea("/mini-site")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-globe" /></div>
                <span className="flex-grow-1">MiniSite</span>
            </button>
            <button
                data-testid="/quotations"
                type="button"
                onClick={pushToArea("/quotations/manage")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-file" /></div>
                <span className="flex-grow-1">Quotations</span>
            </button>
            <button
                data-testid="/invoices/details"
                type="button"
                onClick={pushToArea("/invoices/details")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-file-invoice" /></div>
                <span className="flex-grow-1">Create Invoice</span>
            </button>
            <button
                type="button"
                data-testid="menu-button-order-management"
                onClick={pushToArea("/supplier-orders")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-dolly-flatbed" /></div>
                <span className="flex-grow-1">Order Management</span>
            </button>
            <button
                type="button"
                data-testid="/supplier-subcategories"
                onClick={pushToArea("/supplier-subcategories")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-list" /></div>
                <span className="flex-grow-1">Sub Categories Management</span>
            </button>
            <button
                type="button"
                data-testid="/supplier-customfields"
                onClick={pushToArea("/supplier-customfields")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-columns" /></div>
                <span className="flex-grow-1">Custom Fields Management</span>
            </button>
            <button
                type="button"
                onClick={pushToArea("/supplier-faqs")}
                className={classes}
            >
                <div className={styles.icon}><i className="fa fa-lg fa-question" /></div>
                <span className="flex-grow-1">Help and FAQs</span>
            </button>
        </div>
    );
};

SupplierNav.propTypes = {
    toggle: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    closeSecondLevel: PropTypes.func.isRequired,
};

export default SupplierNav;
