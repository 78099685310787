/* eslint-disable import/extensions */
import React, { useState } from "react";

import * as ReferralApi from "@/Apis/Referrals";
import ReferralLinkModal from "@/Modals/ReferralLinkModal";
import { DashboardTile } from "@/Pages/DashboardPage/DashboardTile";
import InviteNewMembersImg from "@/Assets/Images/Dashboard/Tiles/Invite-New-Members.png";
import MobileInviteNewMembersImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Invite-New-Members.png";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

const Referrals = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [referralLink, setReferralLink] = useState("Generating...");
    const { setSuccessMessage } = useToastMessageContext();

    const createReferral = async () => {
        const { link } = await ReferralApi.createReferral();
        setReferralLink(link);
    };

    const toggleModal = async () => {
        if (!modalOpen) {
            await createReferral();
        }
        setModalOpen(prevState => !prevState);
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(referralLink);
        setSuccessMessage("Referral Link Copied!", true);
    };

    return (
        <>
            <DashboardTile
                title="Get a Referral Link"
                contentTitle="INVITE NEW MEMBERS"
                contentDescription="If a user redeems your referral link, you will earn 3% commission on all their BSC purchases for life"
                isMobileTextBackgroundRight
                desktopImage={InviteNewMembersImg}
                mobileImage={MobileInviteNewMembersImg}
                link="#"
                onClick={toggleModal}
            />
            <ReferralLinkModal
                version="v2"
                isOpen={modalOpen}
                toggle={toggleModal}
                referralLink={referralLink}
                createReferral={createReferral}
                copyToClipboard={copyToClipboard}
            />
        </>
    );
};

export { Referrals };
