import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Modal, ModalBody } from "reactstrap";
import { v4 as uuid } from "uuid";

import styles from "./styles.module.scss";

import { ColumnType, Variations, VariationState, VariationType } from "@/Components/ConfigureProductRow/ColumnTypes";
import { ConfigureProductRow } from "@/Components/ConfigureProductRow";
import Spinner from "@/Components/Spinner";
import { TableScrollbars } from "@/Components/TableScrollbars";
import { useBasketContext } from "@/Context/BasketContext";
import { getSupplierOwnedVariations, getSupplierVariations } from "@/Apis/Suppliers";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";
import { toCurrency } from "@/Utils/formatStringHelper";

interface Props {
    id: string;
    name: string;
    minimumQuantity?: number;

    onAddToBasket(qty: number, variationData: any): void;

    variations: Variations;
    serviceVariations?: Variations;
    priceElement;

    toggle(): void;

    postageCharge: number;
    initialChargeWithVatIfApplicable: number;
    preview?: boolean;
    productVariant: keyof ProductVariant;
}

const ConfigureBasketModal = (
    {
        id,
        name,
        minimumQuantity = 0,
        onAddToBasket,
        variations,
        serviceVariations,
        priceElement,
        toggle,
        postageCharge,
        initialChargeWithVatIfApplicable,
        preview,
        productVariant,
    }
        : Props,
) => {
    const [loading, setLoading] = useState(true);
    const [addCustomisableProduct, setAddCustomisableProduct] = useState<Array<VariationType>>([]);
    const [variationState, setVariationState] = useState<VariationState>({ allowMultipleSelection: variations.allowMultipleSelection, columns: [] });
    const { basket, removeItemFromBasket } = useBasketContext();
    const variationInBasket = { ...basket?.groups?.flatMap(_ => _.items)?.find(x => x.serviceId === id) } as any;
    const selectedServiceVariations = serviceVariations ? { variations: serviceVariations } : variationInBasket;
    const [selectedVariations, setSelectedVariations] = useState(ProductVariations.getVariationsOrDefault(selectedServiceVariations?.variations));

    const createNewRow = (column: ColumnType, rowId: string, rowIndex: number): VariationType => ({
        rowId,
        rowIndex,
        variationId: column.variationId,
        variationIndex: column.variationIndex,
        name: column.name,
        component: column.component,
        value: "",
    });

    const CreateColumnsAndRows = (variationsResponse: ColumnType[]) => {
        const columnsAndRows = variationsResponse.map(x => {
            if (variations.variationItems.length === 0) {
                return x;
            }

            const hasVariation = variations.variationItems.find(variation => variation.variationId === x.variationId) !== undefined;

            return {
                ...x,
                checked: hasVariation,
                currentVariations: hasVariation
                    ? variations.variationItems.filter(variation => variation.variationId === x.variationId)
                    : ProductVariations
                        .getDistinctRows(variations.variationItems)
                        .map(rowId => createNewRow(x, rowId, variations.variationItems.find(r => r.rowId === rowId)?.rowIndex as number)),
            };
        });

        if (productVariant === ProductVariants.listedProduct) {
            return columnsAndRows;
        }

        if (productVariant === ProductVariants.customisableProduct) {
            // customisableVariations are grouped into one row by product name
            const customisableColumnsAndRows = columnsAndRows.map(x => ({
                ...x,
                component: x.variationId === "948d3eb7-ac4d-4516-8afb-f6f8900afa30" ? "ConfigurableSelect" : x.component,
                currentVariations: columnsAndRows.filter(c => c.variationId === x.variationId).map(toBeFlattened => {
                    const flattenVariation: VariationType = toBeFlattened.currentVariations[0];

                    if (toBeFlattened.variationId === "948d3eb7-ac4d-4516-8afb-f6f8900afa30") {
                        flattenVariation.options = flattenVariation?.options ?? toBeFlattened.currentVariations.map(variation => variation.value);
                        flattenVariation.value = "";
                    }
                    if (toBeFlattened.component === "ConfigurableSelect" && toBeFlattened.variationId !== "948d3eb7-ac4d-4516-8afb-f6f8900afa30") {
                        flattenVariation.options = flattenVariation?.options
                            ?? toBeFlattened.currentVariations.reduce((current, next) => ([...current, ...next.value]), [] as string[]);
                        flattenVariation.options = flattenVariation.options.filter((value, index, self) => self.indexOf(value) === index);
                        flattenVariation.value = "";
                    }
                    return toBeFlattened.currentVariations[0];
                }),
            }));

            setAddCustomisableProduct(customisableColumnsAndRows.reduce((current, next) => ([...current, ...next.currentVariations]), [] as VariationType[]));

            return customisableColumnsAndRows.map(x => ({ ...x, currentVariations: selectedVariations.variationItems.filter(item => item.variationId === x.variationId) }));
        }

        throw Error("Invalid product type");
    };

    useEffect(() => {
        (preview ? getSupplierOwnedVariations() : getSupplierVariations(id))
            .then((variationsResponse) => {
                setVariationState({
                    allowMultipleSelection: variations.allowMultipleSelection,
                    columns: CreateColumnsAndRows(variationsResponse),
                });
                setLoading(false);
            });
    }, []);

    const hideOverflow = () => (variationState.columns.filter(x => x.checked).length >= 2 ? "auto" : "hidden");

    const onAddListedItemToBasket = (rowId: string) => () => {
        const updatedSelectedVariations = [...selectedVariations.variationItems, ...variations.variationItems.filter(x => x.rowId === rowId)];
        setSelectedVariations({ ...selectedVariations, variationItems: updatedSelectedVariations });
    };

    const onUnSelectItemFromBasket = (rowId: string) => () => {
        const updatedSelectedVariations = [...selectedVariations.variationItems.filter(x => x.rowId !== rowId)];
        setSelectedVariations({ ...selectedVariations, variationItems: updatedSelectedVariations });
        if (productVariant === ProductVariants.customisableProduct) {
            setVariationState({
                ...variationState,
                columns: variationState.columns.map(x => ({ ...x, currentVariations: [...x.currentVariations.filter(variation => variation.rowId !== rowId)] })),
            });
        }
    };

    const removeVariationFromBasket = () => {
        if (selectedServiceVariations?.orderId) {
            removeItemFromBasket(selectedServiceVariations.orderId);
        }
    };
    const getQty = () => {
        if (preview) {
            return 0;
        }

        return ProductVariations.getDistinctRows(selectedVariations.variationItems).reduce((total, rowId) => {
            const qtyVariation = selectedVariations.variationItems.filter(x => x.rowId === rowId && x.variationId === "42682c75-da44-4a1f-a46e-a544b2dd488f");
            const qty = qtyVariation.length > 0 ? qtyVariation[0].value ?? 0 : 1;
            return Number(total.toString()) + (Number(qty.toString()) === 0 ? 0 : Number(qty.toString()));
        }, 0);
    };

    const getTotal = () => (getQty() === 0
        ? "-"
        : toCurrency((getQty() * initialChargeWithVatIfApplicable) + postageCharge));

    const onProceed = () => {
        const variationData = {
            variationData: {
                variationItems: selectedVariations.variationItems,
            },
        };

        if (getQty() > 0) {
            onAddToBasket(getQty(), variationData);
            toggle();
        } else {
            removeVariationFromBasket();
        }
    };

    const findColumn = (columns: Array<ColumnType>, columnId: string) => columns.find(x => x.variationId === columnId) as ColumnType;
    const findRow = (column: ColumnType, rowId: string) => column.currentVariations.find(x => x.rowId === rowId) as VariationType;

    const onComponentChange = (rowId: string, columnId: string) => (e: any) => {
        if (productVariant === ProductVariants.listedProduct) {
            return;
        }
        const updatedVariationState = { ...variationState };
        const column = findColumn(updatedVariationState.columns, columnId);
        findRow(column, rowId).value = e.target.value;

        const updatedSelectedVariations = [...updatedVariationState.columns.filter(x => x.checked)
            .reduce((current, next) => ([...current, ...next.currentVariations]), [] as VariationType[])] as VariationType[];

        setSelectedVariations({ ...selectedVariations, variationItems: updatedSelectedVariations });
        setVariationState(updatedVariationState);
    };

    const addToCartButton = (rowId: string, allowMultipleSelection: boolean, firstRow: boolean) => {
        if (productVariant === ProductVariants.customisableProduct) {
            const disable = preview || firstRow;

            return (
                <button
                    type="button"
                    disabled={disable}
                    onClick={onUnSelectItemFromBasket(rowId)}
                    className={classnames("p-0 border-0 bg-transparent  mt-2", disable ? "text-muted" : "text-danger")}
                >
                    <i className="fa fa-trash" />
                </button>);
        }

        if (selectedVariations.variationItems.some(x => x.rowId === rowId)) {
            return (
                <>
                    <div className="text-success mr-3 pt-2"><i className="fas fa-check mr-1" /> Added</div>
                    <Button outline color="danger" className="font-weight-bold" data-testid="unselect" onClick={onUnSelectItemFromBasket(rowId)} disabled={preview}>
                        Unselect
                    </Button>
                </>);
        }
        return (
            <Button
                outline
                color="primary"
                className="font-weight-bold"
                data-testid="add-to-cart"
                onClick={onAddListedItemToBasket(rowId)}
                disabled={preview || (!allowMultipleSelection && getQty() > 0)}
            >
                <i className="fas fa-plus-circle mr-1" />
                Add to Cart
            </Button>);
    };

    const addNewItem = () => {
        const newRowId = uuid();
        const newVariationState: VariationState = {
            ...variationState,
            columns: variationState.columns.map(x => {
                const variationsToAdd = addCustomisableProduct.filter(f => f.variationId === x.variationId).map(variation => ({ ...variation, rowId: newRowId }));
                return { ...x, currentVariations: [...x.currentVariations, ...variationsToAdd] };
            }),
        };

        const updatedSelectedVariations = [...newVariationState.columns.filter(x => x.checked)
            .reduce((current, next) => ([...current, ...next.currentVariations]), [] as VariationType[])] as VariationType[];

        setSelectedVariations({ ...selectedVariations, variationItems: updatedSelectedVariations });
        setVariationState(newVariationState);
    };

    useEffect(() => {
        if (variationState.columns.length && variationState.columns.every(x => x.currentVariations.length === 0)) {
            addNewItem();
        }
    }, [variationState]);

    const proceedDisabled = () => {
        if ((getQty() === 0 || preview) && !(productVariant === ProductVariants.customisableProduct && variationState.columns[0]?.currentVariations?.length > 0)) {
            return true;
        }

        return minimumQuantity > 0 && getQty() < minimumQuantity;
    };

    if (loading) {
        return (
            <Modal isOpen centered size="xl" contentClassName={classnames(styles.modalContainer, "bg-white")}>
                <ModalBody
                    data-testid="configure-basket-modal"
                    className={classnames(styles.bodyContainer, "d-flex flex-column p-3 p-sm-4 flex-grow-1 position-relative justify-content-center")}
                >
                    <Spinner className="mx-auto" />
                </ModalBody>
            </Modal>
        );
    }
    return (
        <Modal isOpen centered size="xl" contentClassName={classnames(styles.modalContainer, "bg-white")}>
            <ModalBody
                data-testid="configure-basket-modal"
                className={classnames(styles.bodyContainer, "d-flex flex-column p-3 p-sm-4 flex-grow-1 position-relative")}
            >

                {!loading && (
                    <div>
                        <h3 className="font-weight-bold mt-2">
                            {name}
                        </h3>

                        <div>
                            <div className="d-flex">

                                <div className="mr-auto tw-w-1/2">
                                    {priceElement}

                                    {minimumQuantity > 0 && <p className="tw-pt-6">Minimum Quantity <span className="font-weight-bold">{minimumQuantity}</span></p>}
                                </div>
                                <div className="ml-auto tw-w-1/2 tw-text-right">
                                    <span className="h6 mr-1">PRICE</span> <h3 className="d-inline">£ {getTotal()}</h3>

                                    {minimumQuantity > 0 && <p><span className="font-weight-bold">{getQty()}</span> added</p>}
                                </div>

                            </div>
                            <div className={classnames(styles.boxShadowHeader, "d-flex")} />
                            <TableScrollbars className="mt-4 table-responsive" xOverflow style={{ overflowX: hideOverflow() }}>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr className="d-block mb-4">
                                            {variationState.columns
                                                .map(customisation => customisation.checked && (
                                                    <th scope="col" key={customisation.variationId}>
                                                        <div className="d-flex" style={{ width: ProductVariations.getComponentMeta(customisation.component).width }}>
                                                            <div className="d-flex w-100">
                                                                {ProductVariations.getComponentMeta(customisation.component).showHeader && customisation.name}
                                                            </div>
                                                        </div>
                                                    </th>))}
                                            <th scope="col" key="select">
                                                <div className={classnames(styles.selectColumn, "d-flex")}>
                                                    <div className="d-flex w-100">
                                                        {productVariant === ProductVariants.listedProduct && "Select"}
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <TableScrollbars style={{ height: "330px" }} yOverflow tBody>
                                        {variationState.columns[0]?.currentVariations?.map((row, index) => (
                                            <ConfigureProductRow
                                                serviceId={id}
                                                addToCartButton={addToCartButton(row.rowId, variations.allowMultipleSelection, index === 0)}
                                                columns={variationState.columns}
                                                rowId={row.rowId}
                                                key={row.rowId}
                                                isEditor={false}
                                                onComponentChange={onComponentChange}
                                            />))}
                                    </TableScrollbars>
                                </table>
                            </TableScrollbars>
                            <div className={classnames(styles.boxShadowFooter, "d-flex")} />
                            <div className="d-flex flex-column flex-md-row">
                                {productVariant === ProductVariants.customisableProduct && (
                                    <Button outline color="primary" className="font-weight-bold mr-md-auto mt-4" onClick={addNewItem} data-testid="addItem">
                                        <i className="fas fa-plus-circle mr-1" />
                                        Add Item
                                    </Button>)}
                                <div className="ml-md-auto mt-4 d-flex flex-column flex-md-row">
                                    <Button className="font-weight-bold mr-md-4 mb-3 mb-md-0" data-testid="close" onClick={toggle}>
                                        Close
                                    </Button>
                                    {!preview && (
                                        <Button
                                            color="primary"
                                            className="font-weight-bold"
                                            data-testid="proceed"
                                            onClick={onProceed}
                                            disabled={proceedDisabled()}
                                        >
                                            Proceed
                                        </Button>)}
                                </div>
                            </div>
                        </div>
                    </div>)}
            </ModalBody>
        </Modal>
    );
};

export { ConfigureBasketModal };
