export interface Participant {
    id: string,
    firstname: string,
    surname: string,
    ndis: string,
    dateOfBirth: string,
    status: ParticipantStatus,
    balance: number,
    planId: string,
    planStartDate: string,
    planEndDate: string,
    fullname: string,
    dateLastStatementSent?: string,
    isChecked: boolean,
    sendStatements: boolean,
    statementEmails: string[],
    position: number,
}

export enum ParticipantStatus {
    StandardServiceBooking = "StandardServiceBooking",
    PlanManagedServiceBooking = "PlanManagedServiceBooking",
    Active = "Active",
    Inactive = "Inactive",
    ExpiringOneMonth = "ExpiringOneMonth",
    ExpiringThreeMonths = "ExpiringThreeMonths",
    Expired = "Expired",
}

export enum ParticipantContactRelationship {
    familyMember = "FamilyMember",
    partner = "Partner",
    other = "Other",
    none = "None",
}
