import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

interface Props {
    url?: string,
    className?: string,
    text: string,
    iconHeading?: "h6" | "h5" | "h4" | "h3" | "h2" | "h1",
    onClick?: () => void, // onClick takes precedence over URL
}

const PostPurchaseLinkTab = ({ url, className, text, iconHeading = "h3", onClick }: Props) => {
    const renderButtonDetail = () => (
        <>
            <span className="tw-text-base tw-text-brand-primary tw-font-semibold hover:tw-underline">{text}</span>
            <i className={`fa fa-arrow-circle-right tw-text-brand-primary ${iconHeading}`} />
        </>
    );

    const commonClassNames = classnames("tw-bg-brand-accent-yellow tw-px-4 tw-pt-4 tw-pb-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-space-y-2",
        className);

    if (onClick) { // Return a button to handle the click
        return (
            <button
                type="button"
                onClick={onClick}
                className={commonClassNames}
            >
                {renderButtonDetail()}
            </button>
        );
    }

    return ( // Return a link to redirect to a page
        <Link
            to={url}
            className={commonClassNames}
        >
            {renderButtonDetail()}
        </Link>
    );
};

export default PostPurchaseLinkTab;
