import { enumDisplay } from "@/Utils/formatStringHelper";
import { Gender } from "@/FlexPlan/Types/Gender";

export const genderReactSelectOptions = Object.keys(Gender)
    .map(x => ({
        label: enumDisplay(Gender[x]),
        value: Gender[x],
    }));

export const genderFloatLabelDropdown = Object.keys(Gender)
    .map(x => ({
        display: enumDisplay(Gender[x]),
        value: Gender[x],
    }));
