import React from "react";

import { ContactInformationSection } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Components/ContactInformationSection";
import { BankDetailsSection } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Components/BankDetailsSection";
import Spinner from "@/Components/Spinner";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    loading: boolean,
    openSections: SupplierSections[],
}

const PrivateInformationTab = ({ onSave, removeErrorField, onChangeEditableSections, errors, loading, openSections }: Props) => {
    if (loading) return <Spinner className="tw-my-10 tw-mx-auto" size="40" />;

    return (
        <div className="mb-5">
            <div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <ContactInformationSection
                        onSave={onSave}
                        removeErrorField={removeErrorField}
                        onChangeEditableSections={onChangeEditableSections}
                        errors={errors}
                        openSections={openSections}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <BankDetailsSection
                        onSave={onSave}
                        removeErrorField={removeErrorField}
                        onChangeEditableSections={onChangeEditableSections}
                        errors={errors}
                        openSections={openSections}
                    />
                </div>
            </div>
        </div>
    );
};

export { PrivateInformationTab };
