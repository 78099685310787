import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

import CloseButton from "@/Components/CloseButton";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import Spinner from "@/Components/Spinner";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { StatementEmail } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Types";
import Checkbox from "@/Components/Checkbox";
import Textbox from "@/Components/Textbox";
import { useForm } from "@/Hooks/useForm";
import { isEmail } from "@/Utils/stringHelper";
import { deepEqual } from "@/Utils/objectHelper";
import { GetStatementEmailsResponse } from "@/FlexPlan/Pages/Participants/ParticipantStatements/Modals/ManageStatementEmailsModal/Types";
import sortingUtil from "@/Utils/sortingUtil";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";

interface Props {
    onEmailAdded: () => void,
}

const ManageStatementEmailsModal = ({ onEmailAdded }: Props) => {
    const { get, post, loading } = useAPI({ handle500WithToastMessage: true });
    const [participant, setParticipant] = useState<GetStatementEmailsResponse>();
    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();

    const { participantId } = useParams();
    const { push } = useHistory();

    const [reloadEmails, setReloadEmails] = useState<boolean>(false);
    const toggleReloadEmails = () => setReloadEmails(prev => !prev);

    const [emailInitialState, setEmailInitialState] = useState<StatementEmail[]>();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!participantId) {
            return () => {};
        }

        get<GetStatementEmailsResponse>(FlexPlanUrls.participants.statementsEmails.base(participantId))
            .then(response => {
                const emails = response.emails.map(email => {
                    if (email.isContactEmail) {
                        return {
                            ...email,
                            id: v4(), // Contact emails do not existing the statements email table and therefore don't have an Id
                        };
                    }

                    return email;
                });
                setParticipant({
                    ...response,
                    emails,
                });
                setEmailInitialState(emails);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error);
                } else if (error.message) {
                    setPopupErrorMessage(error.message);
                }
            });

        return () => setParticipant(undefined);
    }, [participantId, reloadEmails]);

    const [displayEmailInput, setDisplayEmailInput] = useState<boolean>(false);

    const onDisplayEmailInput = () => {
        setDisplayEmailInput(true);
    };

    const {
        onChange,
        formState,
        setInvalidField,
        invalidFields,
        clearField,
        removeInvalidField: removeErrorField,
    } = useForm<{ email: string }>({ email: "" });

    const onAddEmail = () => {
        // Validate
        if (!isEmail(formState.email)) {
            setInvalidField("email", "Invalid email");
            return;
        }

        if (participant?.emails.map(x => x.email.toLowerCase()).includes(formState.email.toLowerCase())) {
            setInvalidField("email", "Please enter a unique statement email");
            return;
        }

        // Seed the new email
        const newEmail: StatementEmail = { id: v4(), email: formState.email, isContactEmail: false, isActive: true };

        // Set the participant
        setParticipant(prev => {
            if (!prev) return prev;

            return {
                ...prev,
                emails: [...prev.emails, newEmail],
            };
        });

        setDisplayEmailInput(false);
        clearField("email");
    };

    const onClose = (forceClose: boolean = false) => () => {
        // Check whether we've changed the participants without saving
        if (!forceClose && !deepEqual(emailInitialState, participant?.emails)) {
            // There's unsaved changes
            setConfirmationModalOpen(true);
            return;
        }

        setConfirmationModalOpen(false);
        setParticipant(undefined);
        setDisplayEmailInput(false);
        clearField("email");
        removeErrorField("email");
        push("/participants/statements");
    };

    const onSave = () => {
        post(FlexPlanUrls.participants.statementsEmails.base(participantId), {
            emails: participant?.emails,
        })
            .then(() => {
                setSuccessMessage("Saved successfully", true);
                // Remove the contact email if it was disabled
                setParticipant(prev => {
                    if (!prev) return prev;

                    return {
                        ...prev,
                        emails: prev?.emails.filter(x => !(x.isContactEmail && !x.isActive)),
                    };
                });
                toggleReloadEmails();
                onEmailAdded();
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message);
                }
            });
    };

    const onCheckItem = (itemId: string) => (checked: boolean) => {
        setParticipant(prev => {
            if (!prev) return prev;

            const updatedEmails = prev.emails.map(email => {
                if (email.id === itemId) {
                    return {
                        ...email,
                        isActive: checked,
                    };
                }

                return email;
            });

            return {
                ...prev,
                emails: updatedEmails,
            };
        });
    };

    const onRemoveItem = (itemId: string) => () => {
        setParticipant(prev => {
            if (!prev) return prev;

            return {
                ...prev,
                emails: prev?.emails.filter(x => x.id !== itemId),
            };
        });
    };

    const isOpen: boolean = !!participantId; // If we have a participant Id, we open the modal

    return (
        <>
            <Modal isOpen={isOpen} toggle={onClose()} size="lg" centered>
                <ModalHeader className="!tw-border-0 font-bold !tw-px-10 !tw-font-semibold">
                    Manage Statement  Email Addresses for {participant?.participantFullName}
                    <CloseButton onClick={onClose()} />
                </ModalHeader>
                <ModalBody className="!tw-px-10 !tw-pt-1">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }} />{/* checkbox */}
                                <th style={{ width: "60%" }}>EMAIL ADDRESS</th>
                                <th style={{ width: "20%" }} />{/* Action button */}
                            </tr>
                        </thead>
                        <tbody>
                            {participant && participant.emails && participant.emails.length > 0 && participant.emails
                                .sort((x, y) => sortingUtil.sort(x, y, "isContactEmail", false))
                                .map(item => (
                                    <tr key={item.id + String(item.isContactEmail)} data-testid={item.id}>
                                        <td style={{ width: "20%" }}>
                                            <Checkbox
                                                onChange={onCheckItem(item.id!)}
                                                checked={item.isActive ?? false}
                                                id={item.id!}
                                                className="tw-flex tw-justify-center"
                                            />
                                        </td>
                                        <td style={{ width: "60%" }}>{item.email}</td>
                                        <td style={{ width: "20%" }}>
                                            {!item.isContactEmail && (
                                                <Button color="danger" data-testid={`remove-button-${item.id}`} onClick={onRemoveItem(item.id!)}>
                                                    Remove
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            {participant && participant.emails && participant.emails.length === 0 && (
                                <tr>
                                    <td colSpan={3}>
                                        No statement emails to display
                                    </td>
                                </tr>
                            )}
                            {displayEmailInput && (
                                <tr>
                                    <td style={{ width: "20%" }} className="!tw-pb-0" />
                                    <td style={{ width: "60%" }} className="!tw-pb-0">
                                        <Textbox
                                            placeholder="Add new email here"
                                            onChange={onChange}
                                            value={formState.email}
                                            name="email"
                                            error={invalidFields.email}
                                            data-testid="new-email-input"
                                        />
                                    </td>
                                    <td style={{ width: "20%" }} className="!tw-pb-0">
                                        <Button
                                            color="primary"
                                            className="-tw-mt-4"
                                            onClick={onAddEmail}
                                            disabled={!!invalidFields.email}
                                        >
                                            Add
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <div className="tw-flex tw-flex-row tw-space-x-3 tw-justify-center tw-my-3">
                        <Button
                            color="primary"
                            onClick={onSave}
                            className="tw-w-32"
                            disabled={loading || !deepEqual({}, invalidFields)}
                        >
                            {loading ? <Spinner className="tw-mx-auto" size="20" /> : "Save Changes"}
                        </Button>
                        <Button
                            color="primary"
                            onClick={onDisplayEmailInput}
                            className="tw-w-32"
                            disabled={displayEmailInput}
                        >
                            Add New Email
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            <ConfirmCancelModal
                isOpen={confirmationModalOpen}
                text="Are you sure you want to close without saving changes?"
                onConfirm={onClose(true)}
                onClose={() => setConfirmationModalOpen(false)}
            />
        </>
    );
};

export { ManageStatementEmailsModal };
