import React, { useState } from "react";
import { Form, FormGroup, Input, Label, Modal } from "reactstrap";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { Package } from "@/Apis/Packages";

interface Props {
    onClose: () => void,
    onAddProducts: ({ name, description, showOnMinisite }) => void,
    isSupplier?: boolean | unknown,
    selectedPackage?: Package,
}

const PackageSpecificsModal = ({ onClose, onAddProducts, isSupplier = false, selectedPackage }: Props) => {
    const [name, setName] = useState(selectedPackage ? selectedPackage.name : "");
    const [description, setDescription] = useState(selectedPackage ? selectedPackage.description : "");
    const [showOnMinisite, setShowOnMinisite] = useState(selectedPackage ? selectedPackage.showOnMinisite : false);

    return (
        <Modal isOpen centered size="lg" className={styles.modal}>
            <div className="d-flex align-items-center p-3 bg-primary rounded-top text-white">
                <h6 className="mb-0">Package Specifics</h6>
            </div>

            <div className="d-flex flex-column align-items-center p-3 p-md-4">
                <Form className="w-100">
                    <FormGroup>
                        <Label>Package Name</Label>
                        <Input
                            type="text"
                            name="name"
                            className=""
                            onChange={e => setName(e.target.value)}
                            value={name}
                            data-testid="package-name"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input
                            type="textarea"
                            name="description"
                            className=""
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                            data-testid="package-description"
                        />
                    </FormGroup>
                    {
                        !!isSupplier && (
                            <Checkbox
                                className="mt-2"
                                id="cbMinisite"
                                label="Add to Minisite"
                                onChange={checked => setShowOnMinisite(checked)}
                                checked={showOnMinisite}
                                disabled={!isSupplier}
                            />
                        )
                    }
                </Form>
            </div>

            <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                { !selectedPackage && (
                    <button type="button" className="btn btn-primary" onClick={() => onAddProducts({ name, description, showOnMinisite })}>
                        Add Products
                    </button>
                )}
                { selectedPackage && (
                    <button type="button" className="btn btn-primary" onClick={() => onAddProducts({ ...selectedPackage, name, description, showOnMinisite })}>
                        OK
                    </button>
                )}
            </div>
        </Modal>);
};

export { PackageSpecificsModal };
