import { Subject } from "rxjs";

export const isValidAddress = (address: Address) => !!address?.premise && !!address?.addressLine1 && !!address?.postCode;

export enum AddressLookupState {
    Search = "search",
    Manual = "manual",
    ReadOnly = "read-only"
}

export interface Address {
    addressId: string;
    addressCount: number;
    premise: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
}

export class AddressEvents {
    public saveEvent = new Subject();

    public validManualAddressChange = new Subject<boolean>();

    public save() {
        this.saveEvent.next();
    }
}
