import React from "react";

const FreeTrialLabel = () => (
    <label
        className="tw-rounded-md
        tw-bg-brand-accent-yellow
        tw-border
        tw-text-brand-twilight-blue
        tw-uppercase
        tw-py-1
        tw-px-3
        tw-font-bold
        tw-text-xs
        tw-inline-block"
        data-testid="free-trial-label"
    >
        free trial
    </label>
);

export default FreeTrialLabel;
