import React, { useContext, useEffect, useState } from "react";

import { ParticipantProfileSections, ParticipantProfileType } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Types";
import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { exceedsMaxStringLength, isEmail, isEmptyOrSpaces } from "@/Utils/stringHelper";
import { isNumericString, isValidPhone } from "@/FlexPlan/Utils/validtor";
import { ParticipantContactRelationship } from "@/FlexPlan/Types/Participant";
import { validateEmail } from "@/Utils/validator";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { PlanType } from "@/FlexPlan/Types/Plan";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { convertAPIPlanTypeEnumToClientSideEnum, seedParticipantRelationships } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Helpers/API";
import { downloadBase64File } from "@/Utils/dowloadHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

export interface FlexPlanParticipantContextState {
    saveChanges: () => void,
    formState: ParticipantProfileType,
    handleFormChange: (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void,
    editDob: (date: any) => void,
    editSection: (value: ParticipantProfileSections) => void,
    editableSections: ParticipantProfileSections[],
    errors: any,
    onFloatingLabelDropdownSelect: (field: string) => (value: string) => void,
    onAddressChange: () => void,
    onSaveAddress: (address: LoqateAddress) => void,
    onRadioChange: (field: string, value: any) => () => void,

    // Plan Information
    onPlanTypeSelect: (planType: string) => void,
    setParticipantId: (id: string) => void,
    onMultipleEmailsSelect: (emails: { value?: string, label: string }[]) => void,
    onMultipleEmailInput: (input: string) => void,
    onNewStatementEmail: (event: any) => void,
    multipleEmailInput: string,
    loading: boolean,
    onMultipleEmailSelectBlur: () => void,
    onChangeDocument: (files: EncodedFile[]) => void,
    onDeleteSupportingDocument: (fileId: string) => void,
    onFileDownload: (fileId: string) => () => void,
}

export const initialState: FlexPlanParticipantContextState = {
    editDob: (): void => {},
    editSection: (): void => {},
    editableSections: [],
    errors: undefined,
    handleFormChange: () => () => {},
    onAddressChange: () => {},
    onFloatingLabelDropdownSelect: () => () => {},
    onPlanTypeSelect: (): void => {},
    onRadioChange: () => () => {},
    onSaveAddress: () => {},
    saveChanges: () => {},
    formState: {
        sendStatements: false,
        useContactEmailForSendingStatements: false,
    } as ParticipantProfileType,
    setParticipantId: () => {},
    onMultipleEmailsSelect: () => {},
    onMultipleEmailInput: () => {},
    onNewStatementEmail: () => {},
    multipleEmailInput: "",
    loading: false,
    onMultipleEmailSelectBlur: () => {},
    onChangeDocument: () => {},
    onDeleteSupportingDocument: () => {},
    onFileDownload: () => () => {},
};

const FlexPlanParticipantContext = React.createContext<FlexPlanParticipantContextState>(initialState);

export const useFlexPlanParticipantContext = () => useContext(FlexPlanParticipantContext);

interface Props {
    children: React.ReactNode;
}
const FlexPlanParticipantProvider = ({ children }: Props) => {
    const [formState, setFormState] = useState<ParticipantProfileType>(initialState.formState);
    const [editableSections, setEditableSections] = useState<ParticipantProfileSections[]>([]);
    const [errors, setErrors] = useState<any>();
    const { setSuccessMessage } = useToastMessageContext();
    const setErrorField = (field: string, message?: string) => setErrors(errors => ({ ...errors, [field]: message ?? true }));
    const { put, get, loading } = useAPI({ handle500WithToastMessage: true });

    const [id, setId] = useState<string>("");
    const setParticipantId = (id: string) => setId(id);

    useEffect(() => {
        if (!id) return;

        get<ParticipantProfileType>(FlexPlanUrls.participants.getSingle(id))
            .then(response => {
                const [standard, other] = seedParticipantRelationships(response);

                setFormState({
                    ...response,
                    fullname: `${response.firstName} ${response.lastName}`,
                    planType: convertAPIPlanTypeEnumToClientSideEnum(response.planType),
                    relationshipToParticipant: standard,
                    otherRelationship: other,
                });
            });
    }, [id]);

    const removeErrorField = (field: string) => setErrors(invalidFields => {
        if (!invalidFields) return invalidFields;
        const updatedInvalidFields = invalidFields;
        delete updatedInvalidFields[field];
        return updatedInvalidFields;
    });

    const hasErrors = (): boolean => {
        let errorFound: boolean = false;

        if (isEmptyOrSpaces(formState.firstName) || exceedsMaxStringLength(formState.firstName)) {
            setErrorField("firstName");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.lastName) || exceedsMaxStringLength(formState.lastName)) {
            setErrorField("lastName");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.dateOfBirth)) {
            setErrorField("dateOfBirth");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.ndisNumber) || !isNumericString(formState.ndisNumber) || exceedsMaxStringLength(formState.ndisNumber)) {
            setErrorField("ndisNumber");
            errorFound = true;
        }

        if (formState.gender && exceedsMaxStringLength(formState.gender)) {
            setErrorField("gender");
            errorFound = true;
        }

        if (isEmptyOrSpaces(formState.address.addressLine1) || isEmptyOrSpaces(formState.address.city) || isEmptyOrSpaces(formState.address.postCode)) {
            setErrorField("address");
            errorFound = true;
        }

        if (formState.alternateName && exceedsMaxStringLength(formState.alternateName)) {
            setErrorField("alternateName");
            errorFound = true;
        }

        if (formState.relationshipToParticipant && exceedsMaxStringLength(formState.relationshipToParticipant)) {
            setErrorField("relationshipToParticipant");
            errorFound = true;
        }

        if (formState.relationshipToParticipant === ParticipantContactRelationship.other
            && (isEmptyOrSpaces(formState.otherRelationship) || exceedsMaxStringLength(formState.otherRelationship))) {
            setErrorField("otherRelationship");
            errorFound = true;
        }

        if (!validateEmail(formState.email) || exceedsMaxStringLength(formState.email)) {
            setErrorField("email");
            errorFound = true;
        }

        if (!isValidPhone(formState.phoneNumber) || exceedsMaxStringLength(formState.phoneNumber)) {
            setErrorField("phoneNumber");
            errorFound = true;
        }

        if (formState.sendStatements && !formState.useContactEmailForSendingStatements && (!formState.statementEmails || formState.statementEmails.length === 0)) {
            // The user wants to send statement emails but hasn't selected a statement email to send
            setErrorField("statementEmailRequired", "No email selected. Please either use the participant's contact email or add an additional email.");
            errorFound = true;
        }

        return errorFound;
    };

    const save = (form?: ParticipantProfileType) => {
        if (hasErrors()) {
            return;
        }

        // We need to send the whole profile state back each time
        put(FlexPlanUrls.participants.base, {
            ...form,
            supportingDocuments: form?.supportingDocuments?.filter(x => x.id && x.name && x.data),
        } as ParticipantProfileType)
            .then(() => {
                setEditableSections([]);
                setSuccessMessage("Saved successfully", true);
            })
            .catch((error) => {
                if (error.validationFailed) {
                    setErrors(error.errors);
                }
            });
    };

    const saveChanges = () => {
        if (hasErrors()) {
            return;
        }

        save(formState);
    };

    const handleFormChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState(prevFormState => ({ ...prevFormState, [field]: e.target.value }));
        removeErrorField(field);
    };

    const editDob = (date) => {
        setFormState(prevState => ({ ...prevState, dateOfBirth: date }));
        removeErrorField("dateOfBirth");
    };

    const editSection = (value: ParticipantProfileSections) => {
        if (editableSections.includes(value)) {
            setEditableSections(prevState => prevState?.filter(x => x !== value));
        } else {
            setEditableSections(prevState => [...prevState, value]);
        }
    };

    const onFloatingLabelDropdownSelect = (field: string) => (value: string) => {
        setFormState(formState => ({ ...formState, [field]: value as ParticipantContactRelationship }));
    };

    const onAddressChange = () => removeErrorField("address");

    const onSaveAddress = (address: LoqateAddress) => {
        const newFormState = {
            ...formState,
            address,
        };
        setFormState(newFormState);
        save(newFormState); // We automatically save changes when the address is saved
    };

    const onRadioChange = (field: string, value: any) => () => {
        setFormState(formState => ({ ...formState, [field]: value }));

        if (field === "sendStatements" && !value) {
            // If we are not longer sending statements we can clear any error messages
            removeErrorField("statementEmailRequired");
        }

        if (field === "useContactEmailForSendingStatements" && value) {
            // If we're using the contact email, no problem
            removeErrorField("statementEmailRequired");
        }
    };

    const onChangeDocument = async (files: EncodedFile[]) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: files,
        }));
    };

    const onDeleteSupportingDocument = (fileId: string) => {
        setFormState(formState => ({
            ...formState,
            supportingDocuments: formState.supportingDocuments.filter(x => x.id !== fileId),
        }));
    };

    const onFileDownload = (fileId: string) => () => {
        const file = formState.supportingDocuments.find(x => x.id === fileId);

        if (!file) return;

        downloadBase64File(file.name, file.data);
    };

    const onPlanTypeSelect = (planType: string) => {
        setFormState(formState => ({ ...formState, planType: planType as PlanType }));
    };

    const onMultipleEmailsSelect = (emails: { value?: string, label: string, isActive?: boolean }[]) => {
        setFormState(formState => ({
            ...formState,
            statementEmails: emails.map(x => ({ id: x.value, email: x.label, isActive: x.isActive })),
        }));
        removeErrorField("statementEmails");
    };

    const [multipleEmailInput, setMultipleEmailInput] = useState<string>("");

    const onMultipleEmailInput = (input: string) => setMultipleEmailInput(input);

    const onNewStatementEmail = (event: any) => {
        switch (event.key) {
            case "Enter":
            case "Tab": {
                if (!isEmail(event.target.value)) {
                    setErrorField("invalidStatementEmail", "Please enter a valid email address");
                    return;
                }

                if (formState.statementEmails?.some(x => x.email.trim()
                    .toLowerCase() === event.target.value.trim()
                    .toLowerCase())
                    || formState.email === event.target.value) {
                    setErrorField("uniqueStatementEmail", "Please enter a unique email address");
                    return;
                }

                removeErrorField("uniqueStatementEmail");
                removeErrorField("invalidStatementEmail");
                removeErrorField("statementEmailRequired");

                const existingStatementEmails = [...formState.statementEmails
                    .map(x => ({
                        value: x.id,
                        label: x.email,
                        isActive: x.isActive,
                    }))];

                onMultipleEmailsSelect([...existingStatementEmails, {
                    label: event.target.value,
                    isActive: true,
                }]);

                setMultipleEmailInput("");
                event.preventDefault();
                break;
            }
            default:
                break;
        }
    };

    const onMultipleEmailBlur = () => {
        removeErrorField("uniqueStatementEmail");
        removeErrorField("invalidStatementEmail");
    };

    return (
        <FlexPlanParticipantContext.Provider value={{
            editDob,
            editSection,
            editableSections,
            errors,
            handleFormChange,
            onAddressChange,
            onFloatingLabelDropdownSelect,
            onPlanTypeSelect,
            onRadioChange,
            onSaveAddress,
            saveChanges,
            formState,
            setParticipantId,
            onMultipleEmailsSelect,
            onMultipleEmailInput,
            multipleEmailInput,
            onNewStatementEmail,
            loading,
            onMultipleEmailSelectBlur: onMultipleEmailBlur,
            onChangeDocument,
            onDeleteSupportingDocument,
            onFileDownload,
        }}
        >
            {children}
        </FlexPlanParticipantContext.Provider>);
};

export { FlexPlanParticipantProvider };
