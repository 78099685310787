// This shows how use the plugin infrastructure so we don't end up with one unmanageable blockStyleFn, blockRenderMap, styleMap. It allows us to do anything easily with Draft.js

const createBlockQuotePlugin = () => {
    const blockStyleFn = (contentBlock) => {
        if (contentBlock.getType() === "blockquote") {
            return "bg-light";
        }

        return undefined;
    };

    return {
        blockStyleFn,
    };
};

export { createBlockQuotePlugin };
