import React, { useEffect, useState } from "react";
import { Button, Input, Table } from "reactstrap";

import { useAPI } from "@/Apis/useAPI";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import Spinner from "@/Components/Spinner";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { RmcListingType } from "@/Apis/Rmcs/RmcListingType";
import { Urls } from "@/Apis/urls";
import Textbox from "@/Components/Textbox";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";

const RmcsManagement = () => {
    document.title = "BSC - RMCs Management";
    const {
        get,
        post,
        put,
        loading,
    } = useAPI({ handle500WithToastMessage: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();
    const {
        onSearch,
        setItemsForPagination,
        onSort,
        sortColumn,
        sortDescending,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
    } = useClientSidePagination<RmcListingType>(["name"]);

    const [reloadList, setReloadList] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [companyEdit, setCompanyEdit] = useState<RmcListingType>();
    const [confirmUpdateRmcOpenModal, setConfirmUpdateRmcOpenModal] = useState<boolean>(false);

    useEffect(() => { // fetch the rmcs
        get<RmcListingType[]>(Urls.rmcs.base)
            .then(setItemsForPagination)
            .catch(error => {
                setPopupErrorMessage(error, true);
            });
    }, [reloadList]);

    const renderSortIcon = (column: keyof RmcListingType) => {
        if (sortColumn && sortColumn === column) {
            if (sortDescending) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }

        return <i className="fa fa-sort" />;
    };

    const onClickAddRmc = () => {
        if (isEmptyOrSpaces(companyName)) {
            setPopupErrorMessage("Company name is required", true);
            return;
        }

        post(Urls.rmcs.base, { name: companyName })
            .then(() => {
                setCompanyName("");
                setReloadList(!reloadList);
                setSuccessMessage("Company name added successfully", true);
            })
            .catch(error => {
                setPopupErrorMessage(error, true);
            });
    };

    const onConfirmUpdateRmcModal = () => {
        if (!companyEdit) {
            return;
        }

        setConfirmUpdateRmcOpenModal(false);
        put(Urls.rmcs.update(companyEdit.id), { name: companyEdit.name })
            .then(() => {
                setReloadList(!reloadList);
                setCompanyEdit(undefined);
                setSuccessMessage("Company name updated successfully", true);
            })
            .catch(error => {
                setPopupErrorMessage(error, true);
            });
    };

    return (
        <div className="tw-p-4 tw-w-full lg:tw-max-w-[1240px] 3xl:tw-max-w-[1920px] tw-mx-auto">
            <div className="tw-text-lg lg:tw-text-2xl 2xl:tw-text-3xl 4xl:tw-text-4xl tw-font-bold tw-py-4">RMC Management</div>
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-3">
                <div className="tw-flex tw-flex-row tw-space-x-3 tw-w-full md:tw-w-1/2">
                    <div className="tw-w-full">
                        <Textbox
                            label=""
                            placeholder="Company Name"
                            data-testid="rmc-name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            maxLength={255}
                        />
                    </div>
                    <Button color="primary" onClick={onClickAddRmc} className="tw-h-9">
                        Add
                    </Button>
                </div>
                <div className="tw-relative tw-w-full md:tw-w-1/2 tw-flex tw-justify-end">
                    <DebounceTextbox
                        className="tw-w-2/3 md:tw-w-1/2"
                        placeholder="Search RMC"
                        callBack={onSearch}
                    />
                    <i className="fa-lg fas fa-search tw-absolute tw-right-4 tw-top-2" />
                </div>
            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th style={{ width: "80%" }} key="th-name">
                            <button
                                type="button"
                                className="bg-transparent p-0 border-0"
                                onClick={() => onSort("name")}
                            >
                                Company Name&nbsp;&nbsp;
                                {renderSortIcon("name")}&nbsp;
                            </button>
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={4}>
                                <Spinner className="tw-mx-auto tw-mt-4" />
                            </td>
                        </tr>
                    )}
                    {!loading && paginatedItems?.length === 0 && (
                        <tr>
                            <td colSpan={4}>
                                No RMCs to display
                            </td>
                        </tr>
                    )}
                    {!loading && paginatedItems && paginatedItems?.length > 0 && (
                        paginatedItems?.map(row => (
                            <tr key={row.id}>
                                <td>
                                    {companyEdit?.id === row.id
                                        ? (
                                            <Input
                                                placeholder="Company Name"
                                                value={companyEdit.name}
                                                onChange={(e) => setCompanyEdit({
                                                    ...companyEdit,
                                                    name: e.target.value,
                                                })}
                                                maxLength={255}
                                            />) : row.name}
                                </td>
                                <td>
                                    {companyEdit?.id === row.id
                                        ? (
                                            <>
                                                <button
                                                    type="button"
                                                    className="text-success"
                                                    disabled={isEmptyOrSpaces(companyEdit?.name)}
                                                    onClick={() => setConfirmUpdateRmcOpenModal(true)}
                                                >
                                                    <i className="fa fa-check fa-lg" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="tw-pl-3 text-danger"
                                                    onClick={() => setCompanyEdit(undefined)}
                                                >
                                                    <i className="fa fa-times fa-lg" />
                                                </button>
                                            </>)
                                        : (
                                            <button
                                                type="button"
                                                className="text-primary"
                                                onClick={() => setCompanyEdit(row)}
                                            >
                                                <i className="fa fa-edit fa-lg" />
                                            </button>)}
                                </td>
                            </tr>
                        )))}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
            <ConfirmCancelModal
                isOpen={confirmUpdateRmcOpenModal}
                text="Are you sure that you want to update?"
                onConfirm={onConfirmUpdateRmcModal}
                onClose={() => setConfirmUpdateRmcOpenModal(false)}
            />
        </div>
    );
};

export { RmcsManagement };
