import React, { useState } from "react";

import { FeaturedLatestReview } from "@/Apis/Suppliers/MiniSiteType";
import formatDateHelper from "@/Utils/formatDateHelper";
import Rating from "@/Components/Rating";
import SupplierReviewsModal from "@/Modals/SupplierReviewsModal";

interface Props {
    featuredLatestReview: FeaturedLatestReview,
    supplierId?: string,
    averageRating?: number,
}

const FeaturedReview = ({
    featuredLatestReview,
    supplierId,
    averageRating,
}: Props) => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = async () => {
        setModalOpen(prevState => !prevState);
    };

    return (
        <>
            <div className="tw-font-bold tw-text-base tw-mt-1.5">{featuredLatestReview?.isFeatured ? "Featured Review" : "Latest Review"}</div>
            <div className="tw-text-xs tw-text-gray-500">{formatDateHelper.format(featuredLatestReview?.date, "DD/MM/YYYY")}</div>
            <div className="tw-mt-1.5">
                <div className="tw-line-clamp-3 tw-break-words">
                    {featuredLatestReview?.content}
                </div>
                {featuredLatestReview?.rating > 0 && (
                    <Rating rating={featuredLatestReview?.rating} className="tw-w-full" classNameStar="tw-text-[0.6rem]" />)}
                {featuredLatestReview?.rating < 1 && (<div className="tw-w-full tw-text-xs">Not rated</div>)}
                <button
                    type="button"
                    className="tw-relative tw-font-semibold tw-underline tw-text-brand-primary tw-bg-white tw-w-full tw-text-left"
                    onClick={toggleModal}
                >
                    <div className="tw-text-sm">Read more</div>
                </button>
            </div>

            <SupplierReviewsModal
                isOpen={modalOpen}
                toggle={toggleModal}
                supplierId={supplierId}
                averageRating={averageRating}
                featuredLatestReview={featuredLatestReview}
            />
        </>
    );
};

export { FeaturedReview };
