import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import global from "@/Store/Global/reducer";
import user from "@/Store/User/reducer";
import notifications from "@/Store/Notifications/reducer";

const rootReducer = history => combineReducers({
    global,
    user,
    notifications,
    router: connectRouter(history),
});

export default rootReducer;
