import React, { useEffect, useState } from "react";
import classnames from "classnames";
import DatePicker from "react-date-picker";
import { FormGroup, Label } from "reactstrap";
import moment from "moment";

import styles from "./styles.module.scss";

import { isValidDate } from "@/Utils/stringHelper";

interface Props {
    currentDate: Date | string;
    changeDate: (date: Date) => void;
    placeholder?: string;
    label?: React.ReactNode | string;
    inline?: boolean;
    disabled?: boolean;
    min?: Date | string | null;
    max?: Date | string | null;
    error?: string,
    className?: string,
    isClearable?: boolean,
    calendarIcon?: boolean,
}

const DateTextbox = ({
    currentDate,
    changeDate,
    placeholder,
    label,
    inline,
    disabled = false,
    min,
    max,
    error,
    className,
    isClearable = true,
    calendarIcon = true,
}: Props) => {
    const [maxDate, setMaxDate] = useState<Date | undefined>();
    const [minDate, setMinDate] = useState<Date | undefined>();
    const [dayPlaceholder, setDayPlaceholder] = useState("DD");
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

    const parseDate = (value: Date | string | null | undefined): Date | undefined => {
        if (value) {
            const type = typeof value;

            if (type === "string") {
                return moment(value as string).toDate();
            }
        }

        return value as Date;
    };

    useEffect(() => {
        setMaxDate(parseDate(max));
    }, [max]);

    useEffect(() => {
        setMinDate(parseDate(min));
    }, [min]);

    useEffect(() => {
        if (typeof currentDate === "string" && !isValidDate(currentDate)) {
            // Invalid string dates is causing the component to crash
            // The consuming component needs to be perform validation
            return;
        }
        setSelectedDate(parseDate(currentDate));
    }, [currentDate]);

    useEffect(() => {
        if (placeholder) {
            setDayPlaceholder(`${placeholder} DD`);
        }
    }, [placeholder]);

    const onChange = (value) => {
        changeDate(value);
        setSelectedDate(value);
    };

    return (
        <FormGroup className={className}>
            {label && <Label>{label}</Label>}
            <div className={inline ? styles.inline : ""}>
                <div className={classnames(styles.calendarWrapper, "")}>
                    <DatePicker
                        dayPlaceholder={dayPlaceholder}
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        onChange={onChange}
                        format="dd/MM/yyyy"
                        disabled={disabled}
                        value={selectedDate}
                        showLeadingZeros={false}
                        className={classnames("d-flex align-items-center mb-2 form-control w-100")}
                        calendarClassName={styles.calendar}
                        maxDate={maxDate}
                        minDate={minDate}
                        clearIcon={isClearable ? <i className={classnames(styles.icon, "far fa-times-circle text-muted mt-2")} /> : null}
                        calendarIcon={calendarIcon ? <i className={classnames(styles.icon, "far fa-calendar text-muted mt-3")} /> : null}
                    />
                </div>
            </div>
            {error && (
                <div className="!tw-font-bold !tw-text-[#bb5360] !tw-text-[0.875em] !tw-block">
                    {error}
                </div>
            )}
        </FormGroup>
    );
};

export default DateTextbox;
