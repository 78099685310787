import React from "react";

const CookiePolicyPage = () => {
    document.title = "BSC - Cookie Policy";

    return (
        <div className="container my-3">
            <h1 className="mt-3">Cookie Policy</h1>
            <h4 className="text-secondary">Last updated: July 2020</h4>

            <p>
                This Cookie Policy explains how Business Support Club LLC (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, and &quot;our&quot;) uses cookies and similar
                technologies to recognise you when you visit our websites at https://app.bsc.com, (&quot;Websites&quot;).
                It explains what these technologies are and why we use them, as well as your rights to control our use of them.
            </p>

            <p>
                In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.
            </p>

            <h4 className="font-weight-bold mt-3">What are cookies?</h4>
            <p>
                Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make
                their websites work, or to work more efficiently, as well as to provide reporting information.
            </p>

            <p>
                Cookies set by the website owner (in this case, Business Support Club LLC) are called &quot;first party cookies&quot;. Cookies set by parties other than the website
                owner are called &quot;third party cookies&quot;. Third party cookies enable third party features or functionality to be provided on or through the website
                (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits
                the website in question and also when it visits certain other websites.
            </p>

            <h4 className="font-weight-bold mt-3">Why do we use cookies?</h4>
            <p>
                We use first and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to
                these as
                &quot;essential&quot; or &quot;strictly necessary&quot; cookies. Other cookies also enable us to track and target the interests of our users to enhance the
                experience on our Online Properties.
                Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below.
            </p>

            <p>The specific types of first and third-party cookies served through our Websites and the purposes they perform are described below.</p>

            <h4 className="font-weight-bold mt-3">How can I control cookies?</h4>
            <p>
                You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences. This allows you to select which
                categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
            </p>

            <p>
                Preferences can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some
                functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you
                can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser&#39;s help menu for more information.
            </p>

            <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described below.</p>

            <h4 className="font-weight-bold mt-3">Essential website cookies:</h4>
            <p>These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</p>

            <h4 className="font-weight-bold mt-3">Do you serve targeted advertising?</h4>
            <p>
                We do not have target advertising. We do not pass your data on to any unauthorised third parties. Any information collected through this process does not enable us
                or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.
            </p>

            <h4 className="font-weight-bold mt-3">How often will you update this Cookie Policy?</h4>
            <p>
                We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory
                reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
            </p>

            <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

            <h4 className="font-weight-bold mt-3">Where can I get further information?</h4>
            <p className="mb-5">
                If you have any questions about our use of cookies or other technologies, please email us at
                <a href="mailto:info@businesssupportclub.com"> info@businesssupportclub.com.</a>
            </p>
        </div>
    );
};

export { CookiePolicyPage };
