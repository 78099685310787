import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";

const PackageCreatedModal = ({ code, onClose }) => {
    const [showCopied, setCopied] = useState(false);

    const onClick = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
    };

    return (
        <Modal isOpen centered size="lg" className={styles.modal}>
            <div className="d-flex align-items-center p-3 bg-primary rounded-top text-white">
                <h6 className="mb-0">Package Information</h6>
                <CloseButton onClick={onClose} className="text-white" />
            </div>

            <div className="d-flex flex-column align-items-center p-3 p-md-4">
                <h4 className="mb-4 mt-3">Package code generated successfully! </h4>
                <p className="text-center">Your code is</p>
                <div className="border rounded mb-4 p-3 w-75 d-flex font-weight-bold">
                    <h4 className="text-center flex-grow-1">{code}</h4>
                    <button type="button" className="border-0 text-dark bg-transparent m-0" onClick={onClick}>
                        <i className="fa fa-copy h4" />
                    </button>
                </div>
                <p className="text-center">
                    The package code can be edited from the package management
                    page <Link to="/packages/manage">here</Link>
                </p>

                {showCopied && <p className="font-weight-bold text-success">Code copied to your clipboard</p>}
                <p className="font-weight-bold">Share this code to earn commissions when a customer successfully
                    purchases it!
                </p>
            </div>
        </Modal>
    );
};

PackageCreatedModal.propTypes = {
    code: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PackageCreatedModal;
