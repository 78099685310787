import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

import * as BespokeQuoteStatus from "@/Components/BespokeQuotes/BespokeQuoteStatues";

const SupplierActionGroup = ({ quote, onRemoveClick, onEditClick }) => (
    <>
        {(quote.status === BespokeQuoteStatus.open || quote.status === BespokeQuoteStatus.rejected) && (
            <div className="m-4">
                <Button color="danger" className="mr-2" outline onClick={onRemoveClick}>
                    Remove
                </Button>
                <Button color="primary" outline onClick={onEditClick}>
                    Edit Quotation
                </Button>
            </div>)}
    </>
);

SupplierActionGroup.propTypes = {
    onRemoveClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    quote: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rejectionReason: PropTypes.string,
    }).isRequired,
};

export { SupplierActionGroup };
