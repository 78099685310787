import React from "react";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { SectionContent } from "@/Components/MinisiteContent/SiteContent/SectionContent";
import { SectionImage } from "@/Components/MinisiteContent/SiteContent/SectionImage";
import { RatingsAndContactUs } from "@/Components/MinisiteContent/SiteContent/RatingsAndContactUs";

interface Props {
    siteType: MiniSiteType,
}

const Template3 = ({
    siteType,
}: Props) => (
    <div data-testid="site-template-3">
        <div className="tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-x-3 sm:tw-space-y-0">
            {siteType?.template?.sections?.map((section) => (
                <div key={section.sectionNumber} className="tw-w-full sm:tw-w-1/2">
                    <SectionImage siteSection={section} classNameImg="tw-h-[190px]" />
                    <>
                        <div
                            className="tw-break-words tw-font-bold tw-text-2xl tw-mt-2"
                            style={{ color: section?.titleColour }}
                        >
                            {section?.title}
                        </div>
                        <div className="tw-mt-2.5">
                            <SectionContent section={section} />
                        </div>
                    </>
                </div>
            ))}
        </div>

        <RatingsAndContactUs siteType={siteType} />
    </div>
);

export { Template3 };
