import React, { ReactNode, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import classnames from "classnames";
import { Placement } from "popper.js";

interface Props {
    id: string;
    description: ReactNode | string;
    emptyDescription?: string;
    children?: ReactNode,
    className?: string;
    placement?: Placement;
}

const ToolTip = ({ id, description, emptyDescription = "", children, className, placement }: Props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = (visible?: boolean) => () => setPopoverOpen(visible === undefined ? !popoverOpen : visible);

    return (
        <>
            <div className={classnames(className, "pointer")}>
                <span id={id} onFocus={toggle(true)} onMouseOver={toggle(true)} onMouseOut={toggle(false)} onBlur={toggle(false)}>
                    {children}
                </span>
            </div>
            <Popover
                target={id}
                toggle={toggle}
                placement={placement || "top"}
                isOpen={popoverOpen}
                popperClassName="shadow p-2"
            >
                <PopoverBody className={description ? "" : "text-muted"}>
                    {description || emptyDescription}
                </PopoverBody>
            </Popover>
        </>
    );
};

export { ToolTip };
