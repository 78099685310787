import { FileResponse } from "@/Apis/useAPI";

export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

export const downloadFile = (fileName: string, fileContent, isBlob = false) => {
    const dataResult = isBlob ? fileContent : base64ToArrayBuffer(fileContent);
    const url = window.URL.createObjectURL(new Blob([dataResult], { type: "application/octet-stream" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
};

export const downloadUseAPIFile = (contentType: string = "application/octet-stream", file: FileResponse) => {
    const newBlob = new Blob([file.contents], { type: contentType });
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
};

export const downloadBase64File = (fileName: string, base64Data) => {
    const link = document.createElement("a");
    link.href = base64Data;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
};
