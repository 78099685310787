import { ReceiptType } from "@/Apis/Receipts/Create";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";

const getUrlParam = (location, key) => {
    const params = new URLSearchParams(location);
    return params.get(key);
};

const getReturnUrlToken = (locationSearch) => {
    const returnUrl = getUrlParam(locationSearch, "returnUrl");
    if (!returnUrl) return "";
    const queryString = `?${returnUrl.split("?")[1]}`;
    return getUrlParam(queryString, "token");
};

const urlContains = (location, target) => location.pathname.indexOf(target) !== -1 || location.search.indexOf(target) !== -1;

const getReceiptsPageType = (location) => (urlContains(location, "quotation")
    ? ReceiptType.Quotation
    : ReceiptType.Invoice);

const generateObjectToQueryString = (value) => Object.keys(value).reduce(
    (queryStringAccumulator, currentPropertyName, currentIndex) => {
        const currentPropertyValueAsString = String(value[currentPropertyName]);
        if (isEmptyOrSpaces(currentPropertyValueAsString)
            || value[currentPropertyName] === undefined) {
            return queryStringAccumulator;
        }
        const prefix = currentIndex === 0 ? "" : "&"; // Set ampersand prefix
        return `${queryStringAccumulator}${prefix}${encodeURIComponent(currentPropertyName)}=${encodeURIComponent(currentPropertyValueAsString)}`;
    }, "?",
);

export default {
    getUrlParam,
    urlContains,
    getReturnUrlToken,
    getReceiptsPageType,
    generateObjectToQueryString,
};
