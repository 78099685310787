/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./styles.module.scss";

import Logo from "@/Assets/Icons/Solid/logo.svg";
import PasswordWithConfirmField from "@/Components/PasswordWithConfirmField";
import { TermsAndConditions } from "@/Components/TermsAndConditions";
import { Feature } from "@/Components/Feature";
import Modal from "@/Modals/Modal";
import api from "@/Utils/api";
import * as ReferralApi from "@/Apis/Referrals";
import { features } from "@/Utils/features";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { GoogleSignIn } from "@/Components/GoogleSignIn";
import { GoogleSignInType } from "@/Components/GoogleSignIn/Types";
import { PasswordValidation } from "@/Components/PasswordValidation";

const SignUpForm = ({ onSuccess, onNavigateToLogin, referralToken, displayLabels }) => {
    const recaptchaRef = useRef();
    const errorRef = React.createRef();
    const [referredBy, setReferredBy] = useState("");
    const [referralStatus, setReferralStatus] = useState("");
    const { recaptchaKey, policyDocumentation } = useConfigurationContext();
    const [state, setState] = useState({
        loading: false,
        errors: [],
        showPassword: false,
        termsAgreed: false,
        password: "",
        confirmPassword: "",
    });
    const [passwordError, setPasswordError] = useState(false);

    useEffect(() => {
        if (referralToken) {
            ReferralApi.getReferralDetails(referralToken)
                .then(response => {
                    setReferredBy(response.referredBy);
                    setReferralStatus(response.status);
                });
        }
    }, [referralToken]);

    const edit = name => (e) => {
        const value = e.target.value;
        setState((prevState) => {
            const errors = prevState.errors;
            // Removes the "name" error from the list if it exists
            Object.keys(errors).filter(x => x === name).forEach(key => delete errors[key]);
            return ({
                ...prevState,
                [name]: value,
                error: "",
                errors,
            });
        });
    };

    const onRecaptchaExecuted = (recaptcha) => {
        setState(prevState => ({ ...prevState, loading: true, errors: [], error: "" }));
        api.post("account/register", { ...state, referralToken, recaptcha })
            .then(response => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    displayRegisterSuccess: true,
                    password: "",
                    confirmPassword: "",
                }));
                onSuccess(response);
            })
            .catch((error) => {
                if (features.isEnabled(features.recaptcha)) {
                    window.grecaptcha.reset();
                }

                if (error.validationFailed) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        ...error,
                    }));
                } else if (error === "DuplicateUserName") {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        displayAccountExistsError: true,
                        error: "It seems that the account already exists. Would you like to log in instead?",
                    }));
                } else {
                    setState(prevState => ({ ...prevState, loading: false, error: error.message || api.GENERIC_ERROR }));
                }
                if (errorRef.scrollIntoView) { // checking this here for unit tests only
                    errorRef.scrollIntoView({ block: "start", behavior: "smooth" });
                }
            });
    };

    const onRegister = (e) => {
        e.preventDefault();

        if (features.isEnabled(features.recaptcha)) {
            recaptchaRef.current.execute();
        } else {
            onRecaptchaExecuted("");
        }
    };

    const onTermsClicked = () => setState(prevState => ({ ...prevState, termsAgreed: !prevState.termsAgreed }));

    const getInvalidTokenReason = () => {
        switch (referralStatus) {
            case "InvalidToken":
                return "You have provided an invalid referral token. Continuing to create an account will prevent your referrer from being rewarded.";
            case "HasBeenRedeemed":
                return "You have provided an expired referral token. Continuing to create an account will prevent your referrer from being rewarded.";
            default:
                return "Referral token has failed to be validated.";
        }
    };

    const onPasswordValidationCallback = (validationResponse) => {
        setPasswordError(!validationResponse.success);
    };

    const renderForm = () => {
        const errors = state.errors;
        return (
            <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-col md:tw-px-16">
                <Logo className="tw-text-brand-primary tw-h-24 tw-w-24" />
                <span className="tw-text-brand-primary tw-my-6 md:tw-my-5 tw-text-2xl tw-font-bold">Create Your BSC Account</span>
                {referralStatus && referralStatus !== "Valid" && (
                    <Alert color="danger" className="border-danger">
                        <div className="flex-row d-flex ">
                            <span className="ml-2 mr-3 d-flex align-items-center">
                                <i className="mr-2 fa fa-exclamation-circle text-danger h4" />
                            </span>
                            <div>
                                <h4 className="py-2 alert-heading text-dark">Invalid referral token</h4>
                                <p className="text-dark">{getInvalidTokenReason()}</p>
                            </div>
                        </div>
                    </Alert>
                )}

                {referralStatus && referralStatus === "Valid" && (
                    <Alert color="info" className="my-4 text-center">
                        <h4 className="p-3 alert-heading">You’ve been referred by <strong>{referredBy}</strong> to join the Club!</h4>
                    </Alert>)}

                <Feature name={features.googleSignIn}>
                    <GoogleSignIn
                        displayOr
                        displayOrPosition="below"
                        type={GoogleSignInType.SignUp}
                    />
                </Feature>
                <FormGroup className="tw-w-full">
                    <Label className={displayLabels ? "tw-text-brand-primary" : "tw-sr-only"}>First Name *</Label>
                    <Input
                        type="text"
                        autoFocus
                        placeholder="First Name"
                        value={state.firstName || ""}
                        invalid={!!errors.firstName}
                        onChange={edit("firstName")}
                    />
                    <FormFeedback className="font-weight-bold">{errors.firstName}</FormFeedback>
                </FormGroup>
                <FormGroup className="tw-w-full">
                    <Label className={displayLabels ? "tw-text-brand-primary" : "tw-sr-only"}>Last Name *</Label>
                    <Input
                        type="text"
                        placeholder="Last Name"
                        value={state.lastName || ""}
                        invalid={!!errors.lastName}
                        onChange={edit("lastName")}
                    />
                    <FormFeedback className="font-weight-bold">{errors.lastName}</FormFeedback>
                </FormGroup>
                <FormGroup className="tw-w-full">
                    <Label className={displayLabels ? "tw-text-brand-primary" : "tw-sr-only"}>E-mail address *</Label>
                    <Input
                        type="text"
                        placeholder="E-mail Address"
                        value={state.email || ""}
                        invalid={!!errors.email}
                        onChange={edit("email")}
                    />
                    <FormFeedback className="font-weight-bold">{errors.email}</FormFeedback>
                </FormGroup>
                <PasswordWithConfirmField
                    onChange={edit}
                    password={state.password}
                    confirmPassword={state.confirmPassword}
                    passwordInvalid={!!errors.password || !!errors.passwordUpperLower}
                    confirmPasswordInvalid={!!errors.confirmPassword || !!errors.password}
                    displayLabels={displayLabels}
                    isReskin2022
                />
                <PasswordValidation
                    password={state.password}
                    confirmPassword={state.confirmPassword}
                    onValidationCallback={onPasswordValidationCallback}
                    confirmPasswordRequired
                />
                <TermsAndConditions
                    showPrivacy
                    checked={state.termsAgreed}
                    onToggle={onTermsClicked}
                    thirdPartyLinks={policyDocumentation.thirdPartyHosted}
                    documentLocation={policyDocumentation.termsAndConditions}
                    privacyDocumentLocation={policyDocumentation.privacyPolicy}
                    signup
                />
                <FormGroup>
                    <button
                        type="submit"
                        disabled={state.displayRegisterSuccess || !state.termsAgreed || passwordError}
                        className="
                            tw-bg-brand-primary tw-rounded !tw-text-white tw-cursor-pointer tw-border tw-border-brand-primary
                            focus:tw-outline-none disabled:tw-opacity-25 disabled:tw-cursor-default
                            hover:tw-border-brand-primary hover:tw-bg-white hover:!tw-text-brand-primary
                            tw-basis-full md:tw-basis-auto tw-py-3 tw-px-8"
                    >
                        Proceed
                    </button>
                    <button
                        data-testid="login-instead"
                        color="link"
                        className={classnames(styles.login, "tw-text-center tw-font-bold tw-basis-full md:tw-basis-auto tw-py-3 tw-px-8")}
                        onClick={onNavigateToLogin}
                        type="button"
                    >
                        Log In
                    </button>
                </FormGroup>

                <Feature name={features.recaptcha}>
                    <ReCAPTCHA
                        size="invisible"
                        ref={recaptchaRef}
                        badge="bottomright"
                        sitekey={recaptchaKey}
                        onChange={onRecaptchaExecuted}
                    />
                </Feature>
            </div>
        );
    };

    return (
        <>
            <Form onSubmit={onRegister}>
                {state.error && (
                    <div className={classnames(styles.error, "font-weight-bold text-center mb-2 text-danger")}>
                        {state.error}
                        {state.displayAccountExistsError && (
                            <Button tag={Link} className="ml-3" to="/login" color="primary">Log in</Button>
                        )}
                    </div>
                )}

                {!state.displayRegisterSuccess ? renderForm() : (
                    <Alert color="success" data-testid="success-message">
                        <span className="d-block">Thanks for signing up!</span>
                        <span className="d-block">In order to secure your account, we&#39;ve sent a verification email to you.</span>
                        <span className="d-block">Click the link to access your account.</span>
                    </Alert>
                )}
            </Form>

            <Modal isOpen={state.loading} showSpinner title="Submitting Form">
                <p>Your information is being submitted. Sit back, relax, and we’ll take care of the rest.</p>
            </Modal>
        </>
    );
};

SignUpForm.propTypes = {
    referralToken: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onNavigateToLogin: PropTypes.func.isRequired,
    displayLabels: PropTypes.bool,
};

export { SignUpForm };
