import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { SearchBox } from "./SearchBox";
import { DataTable } from "./DataTable";
import { Sort } from "./Sort";
import { Filter } from "./Filter";
import styles from "./styles.module.scss";

import { Paging } from "@/Components/Paging";
import { CardholderStatus,
    getSales,
    OrderType,
    ReferralStatus,
    Sale,
    SalesDate,
    SalesOrderStatus,
    SalesPaymentStatus,
    SalesRequest,
    SortOrder,
    updateNotes,
    updatePaymentStatus } from "@/Apis/SalesManagement";
import actions from "@/Store/Global/actions";

const SalesManagement = () => {
    document.title = "BSC - Sales Management";
    const dispatch = useDispatch();
    const [sales, setSales] = useState<Sale[]>([]);
    const [pageLength, setPageLength] = useState(0);
    const [searchQuery, setSearchQuery] = useState<SalesRequest>({
        date: SalesDate.All,
        pageNumber: 0,
        paymentStatuses: [
            SalesPaymentStatus.Paid,
            SalesPaymentStatus.Cancelled,
            SalesPaymentStatus.OnHold,
            SalesPaymentStatus.Unpaid,
        ],
        orderStatus: SalesOrderStatus.Any,
        cardholder: CardholderStatus.All,
        referral: ReferralStatus.All,
        searchTerm: "",
        sortOrder: SortOrder.DateDesc,
        orderTypes: [
            OrderType.Product,
            OrderType.Upsell,
            OrderType.BespokeQuote,
            OrderType.Package,
            OrderType.FormationsProduct,
            OrderType.CSL,
            OrderType.Quotation,
            OrderType.Invoice,
            OrderType.OffsitePayment,
            OrderType.BankAccountProduct,
        ],
    });

    useEffect(() => {
        setSales([]);
        getSales(searchQuery)
            .then(({ results, pages }) => {
                setSales(results);
                setPageLength(pages);
            });
    }, [searchQuery]);

    const onChangeSearchTerm = (term: string) => setSearchQuery(prevState => ({
        ...prevState,
        searchTerm: term,
        pageNumber: 0,
    }));

    const changePage = (page: number) => {
        setSearchQuery(prevState => ({ ...prevState, pageNumber: page }));
    };

    const onChangePaymentStatus = (saleId: string, status: SalesPaymentStatus) => {
        updatePaymentStatus({ salesId: saleId, paymentStatus: status })
            .then(() => {
                setSales(prevState => prevState.map(sale => {
                    if (sale.saleId === saleId) {
                        return { ...sale, paymentStatus: status };
                    }

                    return sale;
                }));

                dispatch(actions.setToastMessage(true, "Successfully saved payment status"));
            });
    };

    const onSubmitNotes = (saleId: string, notes: string) => {
        updateNotes({ salesId: saleId, notes })
            .then(() => {
                setSales(prevState => prevState.map(sale => {
                    if (sale.saleId === saleId) {
                        return { ...sale, notes };
                    }

                    return sale;
                }));

                dispatch(actions.setToastMessage(true, "Successfully saved notes"));
            });
    };

    return (
        <div className="box-shadow bg-white m-3 mb-5 mx-md-1 p-4">
            <div className="m-3">
                <h4 className="font-weight-bold">Sales Management</h4>
            </div>

            <div className="d-flex justify-content-between mt-5">
                <div>
                    <Filter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    <Sort searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                </div>
                <SearchBox term={searchQuery.searchTerm} onChange={onChangeSearchTerm} />
            </div>

            <hr className={styles.hr} />
            <DataTable sales={sales} onStatusChanged={onChangePaymentStatus} submitNotes={onSubmitNotes} />
            <Paging onChange={changePage} activePage={searchQuery.pageNumber} pageCount={pageLength} />
        </div>
    );
};

export { SalesManagement };
