import React, { useState, useEffect } from "react";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import FloatLabelDate from "@/Components/FloatLabelDate";
import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import Spinner from "@/Components/Spinner";
import { openB64ImageInNewTab } from "@/Utils/base64EncodeHelper";
import FormatDateHelper from "@/Utils/formatDateHelper";
import { ProfileType } from "@/Apis/Profile";
import { useAPI } from "@/Apis/useAPI";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";

const ProfileInfoPage = () => {
    const { loading, get, post } = useAPI({ handle500WithToastMessage: true });
    const [editingTabs, setEditingTabs] = useState<string[]>([]);
    const [editFields, setEditFields] = useState<ProfileType>({ } as ProfileType);
    const [errors, setErrors] = useState({});

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
    const [identityDocumentType, setIdentityDocumentType] = useState<string>();

    useEffect(() => {
        get<ProfileType>("profile")
            .then((response) => {
                setEditFields(() => response);
            });
    }, []);

    const editSection = (value) => {
        if (editingTabs.includes(value)) {
            setEditingTabs(prevState => prevState.filter(x => x !== value));
        } else {
            setEditingTabs(prevState => [...prevState, value]);
        }
    };

    const editText = (value, e) => {
        setEditFields({ ...editFields, [value]: e.target.value });
    };

    const saveChanges = (changes: ProfileType) => () => {
        if (Object.keys(errors).length > 0) {
            return;
        }

        post<ProfileType>("profile", changes)
            .then((response) => {
                setEditFields(() => response);
                setEditingTabs([]);
            })
            .catch((error) => {
                if (error.validationFailed) {
                    setErrors(error.errors);
                }
            });
    };

    useEffect(() => {
        if (editFields.proofOfIdentity?.indexOf("data:") > -1 || editFields.proofOfAddress?.indexOf("data:") > -1) {
            saveChanges(editFields)();
        }

        if (editFields.proofOfIdentity === "delete:") {
            saveChanges({ ...editFields, proofOfIdentity: "" })();
        }

        if (editFields.proofOfAddress === "delete:") {
            saveChanges({ ...editFields, proofOfAddress: "" })();
        }
    }, [editFields.proofOfIdentity, editFields.proofOfAddress]);

    const getIdentityDocument = (path) => async () => {
        const document = (await post<{ image: string}>("company-formations/getIdentityDocument", { IdentityPath: path })).image;
        openB64ImageInNewTab(document);
    };

    const saveAddress = (address) => saveChanges({ ...editFields, address })();
    const identityDocumentUpload = (key) => (encodedFile) => setEditFields(currentState => ({ ...currentState, [key]: encodedFile[0].data }));
    const deleteIdentityDocumentUpload = (key) => setEditFields(currentState => ({ ...currentState, [key]: "delete:" }));

    const editDob = (date) => {
        setEditFields(prevState => ({ ...prevState, dateOfBirth: date }));
    };

    const onClickDeleteIdentityDocument = (type: string) => {
        setIdentityDocumentType(type);
        setConfirmDeleteModalOpen(true);
    };

    const onConfirmDeleteIdentityDocument = () => {
        deleteIdentityDocumentUpload(identityDocumentType);
        setConfirmDeleteModalOpen(false);
    };

    const renderInfoSection = () => {
        const editForm = (
            <>
                <FloatLabelTextbox
                    label="First Name*"
                    placeholder="First Name"
                    onChange={e => editText("forename", e)}
                    error={editFields.forename?.trim() === "" ? "First Name is required." : ""}
                    value={editFields.forename}
                />
                <FloatLabelTextbox
                    label="Last Name*"
                    placeholder="Surname"
                    onChange={e => editText("surname", e)}
                    error={editFields.surname?.trim() === "" ? "Surname is required." : ""}
                    value={editFields.surname}
                />
                <FloatLabelDate
                    label="Date of Birth"
                    currentDate={editFields.dateOfBirth == null ? "" : editFields.dateOfBirth}
                    changeDate={editDob}
                />
                <FloatLabelTextbox
                    label="Contact Number"
                    onChange={e => editText("phoneNumber", e)}
                    error={editFields.phoneNumber && !/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/.test(editFields.phoneNumber) ? "Invalid phone number" : ""}
                    placeholder="Change your Contact Number"
                    value={editFields.phoneNumber}
                />

                <div className="d-flex justify-content-end mt-3">
                    <Button onClick={() => editSection("Information")} color="secondary" className="mr-1">
                        Cancel
                    </Button>
                    <Button onClick={saveChanges(editFields)} color="primary">Save</Button>
                </div>
            </>
        );

        return (
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                <Row>
                    <Col xs="12" className="d-flex justify-content-between mb-3">
                        <h4 className="font-weight-bold">Basic Information</h4>
                        {!editingTabs.includes("Information") && <Button onClick={() => editSection("Information")} className="px-3" color="primary">Edit</Button>}
                    </Col>
                </Row>
                {editingTabs.includes("Information") ? editForm : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">First Name</Label>
                            <Label className="font-weight-bold">{editFields.forename}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Last Name</Label>
                            <Label className="font-weight-bold">{editFields.surname}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Date of Birth</Label>
                            <Label className="font-weight-bold">{editFields.dateOfBirth != null ? FormatDateHelper.format(editFields.dateOfBirth, "DD/MM/YYYY") : ""}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Phone Number</Label>
                            <Label className="font-weight-bold">{editFields.phoneNumber}</Label>
                        </FormGroup>
                    </>
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }
    return (
        <div className="mb-5">
            {renderInfoSection()}

            <div className="d-flex flex-column border rounded-lg p-4 m-lg-3 mb-4 mt-4">
                <FloatLabelAddressLookUp
                    address={editFields.address}
                    onSave={saveAddress}
                    className="pl-3"
                    reset={() => setErrors({})}
                />
            </div>

            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                <h4 className="font-weight-bold mb-2">Proof of Identity Documents</h4>
                <div className="d-flex">
                    <Button
                        block
                        outline
                        color="secondary"
                        className={classnames(styles.identityDocumentButton, "d-flex mb-3 align-items-center bg-transparent border-0")}
                        onClick={getIdentityDocument(editFields.proofOfIdentity)}
                        data-testid="profileProofOfIdButton"
                        disabled={!editFields.proofOfIdentity}
                    >
                        <i className="far fa-id-card fa-5x" />
                        <div className="overflow-hidden text-left flex-grow-1">
                            <strong className="m-0 ml-4">Proof of ID</strong>
                        </div>
                    </Button>
                    <div>
                        <DropZoneArea onAdd={identityDocumentUpload("proofOfIdentity")} className="border-0">
                            <Button block color="primary">Upload</Button>
                        </DropZoneArea>
                    </div>
                    <Button
                        block
                        color="danger"
                        className={classnames("ml-3", styles.deleteButton)}
                        disabled={!editFields.proofOfIdentity}
                        onClick={() => onClickDeleteIdentityDocument("proofOfIdentity")}
                    >
                        Delete
                    </Button>
                </div>
                <div className="d-flex">
                    <Button
                        block
                        outline
                        color="secondary"
                        className={classnames(styles.identityDocumentButton, "d-flex mb-3 align-items-center bg-transparent border-0")}
                        onClick={getIdentityDocument(editFields.proofOfAddress)}
                        data-testid="addressProofOfIdButton"
                        disabled={!editFields.proofOfAddress}
                    >
                        <i className="far fa-file-alt fa-5x" />
                        <div className="overflow-hidden text-left flex-grow-1">
                            <strong className="m-0 ml-5">Proof of Address</strong>
                        </div>
                    </Button>
                    <div>
                        <DropZoneArea onAdd={identityDocumentUpload("proofOfAddress")} className="border-0">
                            <Button block color="primary">Upload</Button>
                        </DropZoneArea>
                    </div>
                    <Button
                        block
                        color="danger"
                        className={classnames("ml-3", styles.deleteButton)}
                        disabled={!editFields.proofOfAddress}
                        onClick={() => onClickDeleteIdentityDocument("proofOfAddress")}
                    >
                        Delete
                    </Button>
                </div>
                <ConfirmCancelModal
                    isOpen={confirmDeleteModalOpen}
                    text={`Are you sure you want to delete the ${identityDocumentType === "proofOfIdentity" ? "Proof of ID" : "Proof of Address"}?`}
                    onConfirm={onConfirmDeleteIdentityDocument}
                    onClose={() => setConfirmDeleteModalOpen(false)}
                    buttonConfirmText="Yes"
                />
            </div>
        </div>
    );
};

export { ProfileInfoPage };
