import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

export type ClickTapProps = {
    lowerCase?: boolean
}
export const ClickTap = ({ lowerCase }: ClickTapProps) => (
    <>
        <BrowserView className="tw-inline-block">
            {lowerCase ? "click" : "Click"}
        </BrowserView>
        <MobileView className="tw-inline-block">
            {lowerCase ? "tap" : "Tap"}
        </MobileView>
    </>
);
