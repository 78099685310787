import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { ManageParticipantColumns, ParticipantManagementColumnSort } from "@/FlexPlan/Types/Participant/participantColumns";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import Spinner from "@/Components/Spinner";
import formatDateHelper from "@/Utils/formatDateHelper";
import { toCurrencyWithCommas } from "@/Utils/formatStringHelper";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import { Participant, ParticipantStatus } from "@/FlexPlan/Types/Participant";
import { getFiltersFromEnum } from "@/FlexPlan/Utils/enum";
import Checkbox from "@/Components/Checkbox";
import RadioButton from "@/Components/RadioButton";
import ParticipantStatusIcon from "@/FlexPlan/Components/ParticipantStatusIcon";
import { ManageStatementEmailsModal } from "@/FlexPlan/Pages/Participants/ParticipantStatements/Modals/ManageStatementEmailsModal";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { StatementsModal } from "@/FlexPlan/Pages/Participants/ParticipantStatements/Modals/StatementsModal";
import { ModalAction } from "@/FlexPlan/Pages/Participants/ParticipantStatements/Types";

const columns: ManageParticipantColumns[] = [
    { name: "Participant", columnSort: ParticipantManagementColumnSort.Participant },
    { name: "NDIS No.", columnSort: ParticipantManagementColumnSort.NDIS },
    { name: "Date of Birth", columnSort: ParticipantManagementColumnSort.DateOfBirth },
    { name: "Status", columnSort: ParticipantManagementColumnSort.Status, isFilter: true },
    { name: "Balance", columnSort: ParticipantManagementColumnSort.Balance },
    { name: "Plan End Date", columnSort: ParticipantManagementColumnSort.PlanEndDate },
    { name: "Last Statement Sent Date", columnSort: ParticipantManagementColumnSort.LastStatementSentDate },
    { name: "Statement Settings" },
];

const ParticipantStatements = () => {
    const [error, setError] = useState<string>();
    const [statementModalOpen, setStatementModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState<ModalAction>();

    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const { push } = useHistory();
    const [reloadParticipants, setReloadParticipants] = useState<boolean>(false);

    const {
        setItemsForPagination,
        onSort,
        paginatedItems,
        onSearch,
        sortColumn,
        sortDescending,
        pageNumber,
        onPageSelected,
        totalPages,
        onCheckItem,
        onSelectAll,
        selectAll,
        initialItems,
    } = useClientSidePagination<Participant>(["fullname", "ndis"],
        {
            field: "status",
            filters: getFiltersFromEnum(ParticipantStatus),
        });

    useEffect(() => {
        get<Participant[]>(FlexPlanUrls.statements.getStatements)
            .then(response => {
                setItemsForPagination(response.map(x => ({ ...x,
                    fullname: `${x.firstname} ${x.surname}`,
                    isChecked: false })));
            })
            .catch(error => setError(error));
    }, [reloadParticipants]);

    const toggleDownloadStatementModal = () => {
        setModalAction("download");
        setStatementModalOpen(prev => !prev);
    };
    const toggleSendStatementModal = () => {
        setModalAction("send");
        setStatementModalOpen(prev => !prev);
    };

    const onToggleParticipantEmail = (participantId: string) => () => {
        push(`/participants/statements/${participantId}`);
    };

    const renderSortIcon = (column: ParticipantManagementColumnSort | undefined) => {
        if (!column) { // It's not a sortable column
            return <></>;
        }

        if (sortColumn && sortColumn === column) {
            if (sortDescending) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }
        return <i className="fa fa-sort" />;
    };

    const onEmailAdded = () => {
        setReloadParticipants(prev => !prev);
    };

    const displayItems = sortColumn
        ? paginatedItems // We have already sorted
        : paginatedItems?.sort((a, b) => a.position - b.position); // We need to do a sort to maintain position

    const sendButtonEnabled = initialItems?.some(x => x.isChecked && x.sendStatements);
    const downloadButtonEnabled = initialItems?.some(x => x.isChecked);

    return (
        <FlexPlanBasicWrapper title="Manage Participant Statements" className="tw-flex tw-flex-col tw-space-y-3">
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4">
                <div className="tw-relative tw-w-full md:tw-w-1/3">
                    <DebounceTextbox
                        placeholder="Search Participant"
                        callBack={onSearch}
                        name="Search Quotations"
                    />
                    <i className="fa-lg fas fa-search tw-absolute tw-right-4 tw-top-2" />
                </div>
                <Button color="primary" className="tw-h-9" onClick={toggleSendStatementModal} disabled={!sendButtonEnabled}>Send Statements</Button>
                <Button color="primary" className="tw-h-9 tw-mt-2 md:tw-mt-0" onClick={toggleDownloadStatementModal} disabled={!downloadButtonEnabled}>Download Statements</Button>
            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>
                            <Checkbox
                                checked={selectAll}
                                onChange={onSelectAll("isChecked")}
                                id="selectAll"
                                className="tw-mt-3 tw-flex tw-justify-center"
                            />
                        </th>
                        {columns.map(col => (
                            <th style={{ width: "11.1%" }} key={col.name}>
                                <button
                                    type="button"
                                    className="bg-transparent p-0 border-0"
                                    onClick={() => col.columnSort && onSort(col.columnSort)}
                                >
                                    {col.name}&nbsp;
                                    {renderSortIcon(col.columnSort)}&nbsp;
                                </button>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {(loading) && (
                        <tr>
                            <td colSpan={9}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                        </tr>
                    )}
                    {error && (
                        <tr className="tw-text-red-600 tw-mx-auto tw-mt-4">
                            <td colSpan={9}>{error}</td>
                        </tr>
                    )}
                    {!loading && !error && displayItems?.length === 0 && (
                        <tr className="tw-mx-auto tw-mt-4">
                            <td colSpan={9}>No participants to display</td>
                        </tr>
                    )}
                    {!loading && !error && displayItems && (
                        displayItems.map(p => (
                            <tr key={p.id}>
                                <td>
                                    <Checkbox
                                        checked={p.isChecked}
                                        onChange={onCheckItem(p.id, "id", "isChecked")}
                                        id={`select-${p.ndis}`}
                                        className="tw-flex tw-justify-center"
                                    />
                                </td>
                                <td>
                                    <Link
                                        to={`/participants/${p.id}/personal-information`}
                                        className="!tw-text-blue-600 !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {p.firstname}&nbsp;{p.surname}
                                    </Link>
                                </td>
                                <td>
                                    {p.ndis}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.dateOfBirth, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    <ParticipantStatusIcon status={p.status} />
                                </td>
                                <td>
                                    {toCurrencyWithCommas(p.balance, "AUD", "en-AU")}
                                </td>
                                <td>
                                    {formatDateHelper.format(p.planEndDate, "DD/MM/YYYY")}
                                </td>
                                <td>
                                    {p.dateLastStatementSent ? formatDateHelper.format(p.dateLastStatementSent, "DD/MM/YYYY") : "-"}
                                </td>
                                <td>
                                    {p.statementEmails && p.statementEmails.length > 0 && (
                                        <ul className="tw-mb-2">
                                            {p.statementEmails.map(email => (
                                                <li key={email}>
                                                    {email}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    <button
                                        type="button"
                                        color="transparent"
                                        className="tw-text-brand-primary tw-font-semibold"
                                        onClick={onToggleParticipantEmail(p.id)}
                                    >
                                        Manage Addresses
                                    </button>
                                    <RadioButton
                                        checked={!p.sendStatements}
                                        onChange={() => {
                                        }}
                                        id="authorise-plan-manager"
                                        label="Do Not Send"
                                        disabled
                                    />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
            <StatementsModal
                modalOpen={statementModalOpen}
                selectedParticipants={initialItems?.filter(x => x.isChecked)}
                toggleSendStatementModal={toggleSendStatementModal}
                modalAction={modalAction}
            />
            <ManageStatementEmailsModal onEmailAdded={onEmailAdded} />
        </FlexPlanBasicWrapper>
    );
};

export default ParticipantStatements;
