import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { Button, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import Dropzone from "react-dropzone";
import { useLocation } from "react-router";

import styles from "./styles.module.scss";

import Tag from "@/Components/Tag/index";
import Checkbox from "@/Components/Checkbox";
import Spinner from "@/Components/Spinner";
import RecipientSearchInput from "@/Components/Messages/RecipientSearchInput";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { useAPI } from "@/Apis/useAPI";
import { AttachmentType,
    MessagingUrls,
    StartConversationRequest,
    UploadConversationAttachmentRequest } from "@/Apis/Messaging";
import { DiscardMessageModal } from "@/Modals/ComposeMessageModal/DiscardMessageModal";
import { encodeFilesToBase64WithFileName } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes } from "@/Utils/constants";
import actions from "@/Store/Global/actions";
import urlHelper from "@/Utils/urlHelper";
import api from "@/Utils/api";

interface Props {
    location: any;
    onClose(): void;
    initialMessage?: string;
}

const getRecipientApi = id => api.post("contacts/recipients", { UserIds: [id] });

const ComposeMessageModal = ({ onClose, initialMessage = "" }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { loading, post } = useAPI({ handle500WithRedirect: true });
    const [conversationId] = useState(uuid());
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState(createEditorState(initialMessage));
    const [attachments, setAttachments] = useState<Array<AttachmentType>>([]);
    const [isDirty, setIsDirty] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);
    const [displayDiscard, setDisplayDiscard] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [recipients, setRecipients] = useState<any[]>([]);

    useEffect(() => {
        const supplierUserId = location.search ? urlHelper.getUrlParam(location.search, "userId") : "";
        if (supplierUserId) {
            getRecipientApi(supplierUserId).then((response) => {
                setRecipients(response);
            });
        }
    }, []);

    const onSubjectChange = (e) => {
        setSubject(e.target.value);
        setIsDirty(true);
    };

    const onEditorChange = (changedEditorState) => {
        setBody(changedEditorState);
        if (body.getCurrentContent().getPlainText()) {
            setIsDirty(true);
        }
    };

    const onDrop = (files) => {
        files.forEach((x) => {
            post<AttachmentType>(MessagingUrls.uploadConversationAttachment(conversationId),
                { base64: x.data, conversationId, filename: x.name } as UploadConversationAttachmentRequest)
                .then(response => {
                    setAttachments(prevState => ([...prevState, response]));
                    setIsDirty(true);
                });
        });
    };

    const onAddFiles = useCallback(async acceptedFiles => {
        const encodedFiles = await encodeFilesToBase64WithFileName(acceptedFiles);
        onDrop(encodedFiles);
    }, []);

    const removeAttachment = (attachment) => setAttachments(prevState => (prevState.filter(x => x !== attachment)));

    const onSend = () => {
        if (recipients?.length === 0 || !subject || !body.getCurrentContent().getPlainText()) {
            setShowValidation(true);
            return;
        }

        const data = {
            conversationId,
            recipients: recipients.map(x => x.id) as Array<string>,
            subject,
            body: getContentStateAsJson(body),
            attachments,
            isUrgent,
            relatedOrderId: location.search ? urlHelper.getUrlParam(location.search, "orderId") : null,
        } as StartConversationRequest;

        post(MessagingUrls.startConversation, data).then(() => {
            dispatch(actions.setToastMessage(true, "Message Successfully Sent"));
            onClose();
        });
    };

    const onSaveAsDraft = () => {
        const data = {
            conversationId,
            recipients: recipients.map(x => x.id) as Array<string>,
            subject,
            body: getContentStateAsJson(body),
            attachments,
            isUrgent,
            relatedOrderId: location.search ? urlHelper.getUrlParam(location.search, "orderId") : null,
        } as StartConversationRequest;

        post(MessagingUrls.saveDraftConversation, data).then(() => {
            dispatch(actions.setToastMessage(true, "Message Saved To Drafts Folder"));
            onClose();
        });
    };

    const onRecipientClick = (x) => {
        setRecipients(prevState => ([...prevState, x]));
        setIsDirty(true);
    };

    const onRecipientRemove = recipient => () => {
        setRecipients(prevState => (prevState.filter(x => x !== recipient)));
    };

    const handleOnClose = () => {
        if (isDirty) {
            setDisplayDiscard(true);
        } else {
            onClose();
        }
    };

    return (
        <Modal isOpen centered size="lg" data-testid="compose-message-modal">
            <ModalBody>
                <h4 className="font-weight-bold mb-4">Compose General Enquiry</h4>
                <button
                    type="button"
                    onClick={handleOnClose}
                    className={classnames(styles.close, "position-absolute")}
                >
                    <i className="fa fa-times" />
                </button>
                <FormGroup>
                    <Label>To *</Label>
                    <RecipientSearchInput
                        onRecipientClick={onRecipientClick}
                        isReply={false}
                        onRecipientRemove={onRecipientRemove}
                        selectedRecipients={recipients}
                        invalid={recipients.length === 0 && showValidation}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Subject *</Label>
                    <Input
                        type="text"
                        data-testid="compose-message-modal-subject"
                        value={subject || ""}
                        onChange={onSubjectChange}
                        invalid={!subject && showValidation}
                    />
                </FormGroup>
                <FormGroup>
                    <Checkbox
                        checked={isUrgent || false}
                        onChange={() => setIsUrgent(!isUrgent)}
                        label="This message is urgent"
                        id="urgent"
                    />
                </FormGroup>
                <DropZoneArea
                    onAdd={onDrop}
                    noClick
                    dragActiveHook={(isActive => setIsDragActive(isActive))}
                    className={classnames(styles.dropzone, "mb-2 d-flex")}
                    accept={acceptedFileTypes.documents}
                >
                    {isDragActive && (
                        <div className={styles.overlay}>
                            <h4>Drop files here to attach</h4>
                        </div>)}
                    <div
                        className={classnames(styles.richTextEditor, !body.getCurrentContent().getPlainText() && showValidation && styles.invalidRichTextEditor, "w-100 mb-3")}
                        data-testid="compose-message-modal-body"
                    >
                        <RichTextEditor
                            placeholder="Your message"
                            editorState={body}
                            onChange={onEditorChange}
                        />
                    </div>
                </DropZoneArea>

                {attachments.length > 0 && (
                    <div className={classnames(styles.attachments, "d-flex flex-wrap mb-3")}>
                        {attachments.map(x => (
                            <Tag
                                key={x.filename}
                                classnames="border-dark text-dark"
                                name={x.filename}
                                useAttachmentIcon
                                displayClose
                                onClose={() => removeAttachment(x)}
                            />
                        ))}
                    </div>
                )}
                <div className="d-flex align-items-center">
                    <div className="flex-column flex-grow-1 flex-lg-grow-0">
                        {loading ? <Spinner size="18" /> : (
                            <Button
                                color="primary"
                                className="w-100"
                                onClick={onSend}
                                data-testid="compose-message-modal-send-button"
                            >
                                Send
                            </Button>)}
                    </div>
                    <div className="flex-column">
                        <Dropzone onDrop={onAddFiles} accept={acceptedFileTypes.documents} multiple maxSize={8000000}>
                            {({ getRootProps, getInputProps }) => (
                                <button
                                    type="button"
                                    className={classnames(styles.icon, "p-0 border-0 bg-transparent text-dark h6 mr-3 ml-3")}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <i className="fa fa-paperclip mr-2" />
                                </button>
                            )}
                        </Dropzone>
                    </div>

                </div>
            </ModalBody>
            {displayDiscard && (
                <DiscardMessageModal
                    discardMessage={onClose}
                    continueWithoutSaving={() => setDisplayDiscard(false)}
                    saveAsDraft={onSaveAsDraft}
                />
            )}
        </Modal>
    );
};

export { ComposeMessageModal };
