/* eslint-disable react/no-find-dom-node */
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { Button } from "reactstrap";
import classnames from "classnames";

import { ConfigurableSelectLabelEditor } from "./ConfigurableSelectLabelEditor";
import { theme } from "./theme";
import styles from "./styles.module.scss";

import { ClickAwayListener } from "@/Components/ClickAwayListener";

interface Props {
    value?: Array<string>;
    setMenuIsOpen(dispatch) : void;
    menuIsOpen: boolean;
    onValidate(errorMessage: string): void;
    onChange(options: SelectOption[]): void;
}

export interface SelectOption {
    label: string;
    isSelected: boolean;
}

const ConfigurableSelect = ({ value = [], setMenuIsOpen, menuIsOpen, onValidate, onChange }: Props) => {
    const createOptions = (): SelectOption[] => [...value.map(x => ({ label: x, isSelected: false }))];
    const [options, setOptions] = useState(createOptions);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (options.length === 0) {
            onValidate("Add at least one item");
            setErrorMessage("Add at least one item");
        }
        onChange(options);
    }, [options]);

    const openMenuOnClick = () => {
        if (!menuIsOpen) {
            setMenuIsOpen(true);
        }
    };

    const validate = (label: string) => {
        let valid = true;
        let newErrorMessage = "";

        if (options.some(x => x.label === label)) {
            newErrorMessage = "Item already exists";
            valid = false;
        }
        if (label.trim() === "") {
            newErrorMessage = "Item is empty";
            valid = false;
        }

        onValidate(newErrorMessage);
        setErrorMessage(newErrorMessage);
        return valid;
    };

    const onAddOption = (previousValue: string, newValue: string) => {
        setErrorMessage("");
        if (!validate(newValue)) {
            return;
        }
        setOptions([...options.map(x => ({ ...x, isSelected: false })), { label: newValue, isSelected: true }]);
    };

    const onEditOption = (previousValue: string, newValue: string) => {
        setErrorMessage("");
        if (!validate(newValue)) {
            return;
        }
        const updatedOptions = options.map(x => ({ ...x, isSelected: false }));
        const updatedOption = updatedOptions.find(x => x.label === previousValue) as SelectOption;
        updatedOption.isSelected = true;
        updatedOption.label = newValue;
        setOptions(updatedOptions);
    };

    const onDeleteOption = (label: string) => {
        setErrorMessage("");
        setOptions(currentState => currentState.filter(x => x.label !== label));
        onChange(options);
    };

    const toggleMenu = () => setMenuIsOpen(!menuIsOpen);

    const DropdownIndicator = () => (
        <Button type="button" className="p-0 border-0 bg-transparent text-dark" onClick={toggleMenu}>
            <i className={`fa ${menuIsOpen ? "fa-caret-up" : "fa-caret-down"} mr-3 ml-3`} />
        </Button>);

    const Menu = (props: any) => (
        <components.Menu {...props}>
            <ConfigurableSelectLabelEditor onSaveOption={onAddOption} mode="Create" />
            {props.children}
        </components.Menu>);

    const MenuList = (props: any) => (<components.MenuList className={styles.selectContainer} {...props}>{props.children}</components.MenuList>);

    const Option = (props: any) => {
        const ref = useRef<any>();

        useEffect(() => {
            // eslint-disable-next-line no-unused-expressions
            props.isSelected && ref.current.scrollIntoView();
        }, [props.isSelected]);

        return (
            <components.Option {...props} innerRef={ref} />
        );
    };

    const formatOptionLabel = (option: SelectOption) => (
        <ConfigurableSelectLabelEditor
            onSaveOption={onEditOption}
            mode="Edit"
            initialValue={option.label}
            onDeleteOption={onDeleteOption}
        />);

    return (
        <div onClick={openMenuOnClick} className="w-100" role="button" tabIndex={0} onKeyDown={() => {}}>
            <ClickAwayListener onClickAway={() => setMenuIsOpen(false)} />
            <div className={classnames(`${styles.errorMessage} ${errorMessage === "" ? "invisible" : "visible"}`, "text-danger font-weight-bolder")}>
                {errorMessage}
            </div>
            <Select
                backspaceRemovesValue={false}
                placeholder={menuIsOpen ? "Enter Items ⇩" : "Configure List ⇨"}
                value=""
                menuIsOpen={menuIsOpen}
                options={options}
                isSearchable={false}
                formatOptionLabel={(inputValue) => formatOptionLabel(inputValue)}
                closeMenuOnSelect={false}
                components={{ DropdownIndicator, Menu, MenuList, Option }}
                isOptionDisabled={() => true}
                styles={theme}
                maxMenuHeight={150}
                tabSelectsValue={false}
                isOptionSelected={(option) => (option.isSelected)}
            />
        </div>);
};

export { ConfigurableSelect };
