/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Logo from "@/Assets/Icons/Solid/logo.svg";

export const PartnerHeader = () => (
    <header className="d-flex justify-content-center flex-grow-0 bg-primary box-shadow-bottom-2">
        <div className={classnames(styles.partnerBscLogo, "my-4")}>
            <Logo />
        </div>
    </header>);
