import React from "react";

import { failedRows, openRows } from "../Utils";

import Checkbox from "@/Components/Checkbox";
import Spinner from "@/Components/Spinner";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { LineItem } from "@/FlexPlan/Pages/Orders/OrderDetails/Components/LineItem";
import { LineItemsTabButtons } from "@/FlexPlan/Pages/Orders/OrderDetails/Components/LineItemsTabButtons";
import { FlexPlanOrderStatus } from "@/FlexPlan/Types/Order";

const LineItemsDataTab = () => {
    const {
        orderLoaded,
        onSelectAll,
        onCheckBoxOnRow,
        onChangeRow,
        selectAllChecked,
        formState,
        disableButtons,
        onDateTextBoxChange,
        onAddRow,
        onGstCheckBoxOnRow,
        initialOrderStatus,
    } = useFlexPlanOrderContext();

    if (!orderLoaded) {
        return (
            <Spinner className="tw-my-10 tw-mx-auto" />
        );
    }

    return (
        <div className="tw-mt-7">
            <Checkbox
                checked={selectAllChecked}
                label="SELECT ALL"
                onChange={onSelectAll}
                id="selectAll"
                className="tw-mt-3"
                disabled={initialOrderStatus === FlexPlanOrderStatus.Approved}
            />
            {formState.items?.length > 0 && formState.items.filter(openRows)
                .sort((a, b) => a.position - b.position)
                .map((row, index) => (
                    <LineItem
                        key={row.id}
                        index={index}
                        row={row}
                        onCheckboxOnRow={onCheckBoxOnRow}
                        onChangeRow={onChangeRow}
                        initialOrderStatus={initialOrderStatus}
                        onDateTextBoxChange={onDateTextBoxChange}
                        onGstCheckBoxOnRow={onGstCheckBoxOnRow}
                    />
                ))}
            <div className="tw-mr-32 3xl:tw-w-3/4">
                <button
                    type="button"
                    disabled={disableButtons || initialOrderStatus === FlexPlanOrderStatus.Approved}
                    onClick={onAddRow}
                    className="tw-font-semibold tw-outline-0 tw-text-brand-primary tw-w-32 tw-float-right"
                >
                    <i className="fa fa-plus" />&nbsp; &nbsp;Add row
                </button>
            </div>
            <div className="tw-mt-16">
                <h6><span className="tw-font-bold">FAILED ITEMS</span> <i>(Items do not contribute to Running Totals)</i></h6>
                {formState.items.filter(failedRows)
                    .map((row, index) => (
                        <LineItem
                            failedItem
                            index={index}
                            row={row}
                            onCheckboxOnRow={onCheckBoxOnRow}
                            onChangeRow={onChangeRow}
                            initialOrderStatus={initialOrderStatus}
                            onDateTextBoxChange={onDateTextBoxChange}
                            onGstCheckBoxOnRow={onGstCheckBoxOnRow}
                            key={row.id}
                        />
                    ))}
            </div>
            <LineItemsTabButtons />
        </div>
    );
};

export { LineItemsDataTab };
