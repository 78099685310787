import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "reactstrap";

import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";
import { useForm } from "@/Hooks/useForm";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import Spinner from "@/Components/Spinner";
import PasswordField from "@/Components/PasswordField";
import Textbox from "@/Components/Textbox";
import { useAuthentication } from "@/Hooks/useAuthentication";
import { Urls } from "@/Apis/urls";
import { GoogleSignInType } from "@/Components/GoogleSignIn/Types";

const VerifyPasswordPage = () => {
    document.title = "BSC - Verify Password";
    const history = useHistory();
    const { onChange, formState } = useForm<{ password: string }>({ password: "" });
    const { post, loading } = useAPI({ handle500WithRedirect: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [error, setError] = useState<string>();
    const [errors, setErrors] = useState<string[]>();

    useEffect(() => {
        if (!history.location?.state?.verifySubmitEndpoint) {
            setError("No endpoint to send request supplied in the push state.");
        }
    }, [history.location.state]);

    const {
        onLoginSuccess,
        onSignUpSuccess,
    } = useAuthentication();

    const onProceed = e => {
        e.preventDefault();

        post(history.location?.state?.verifySubmitEndpoint, {
            ...history.location.state.body,
            password: formState.password,
        })
            .then(() => {
                setSuccessMessage("Password verified", true);
                // The password is correct, sign the user in
                post(Urls.account.externalSignIn, {
                    ...history.location.state.body,
                    overrideLinkedProviderCheck: true,
                })
                    .then(async response => {
                        if (history.location?.state.type === GoogleSignInType.Login) {
                            await onLoginSuccess(response);
                        } else if (history.location?.state.type === GoogleSignInType.SignUp) {
                            await onSignUpSuccess(response);
                        }
                    })
                    .catch(error => {
                        if (typeof error === "string") {
                            setPopupErrorMessage(error, true);
                        } else if (error.message) {
                            setPopupErrorMessage(error.message, true);
                        }
                    });
            })
            .catch(error => {
                if (error.validationFailed) {
                    setErrors(Object.keys(error.errors).map(err => error.errors[err]));
                } else if (error.incorrectPassword) {
                    setPopupErrorMessage("Incorrect password.", true);
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    return (
        <LoginBackgroundWrapper>
            {error ? (
                <Alert color="danger">
                    {error}
                    <Button color="transparent" onClick={history.push("/login")}>Back to Login</Button>
                </Alert>
            ) : (
                <Form className="tw-flex tw-flex-col tw-space-y-5" onSubmit={onProceed}>
                    <Alert color="info" className="!tw-mb-0 !tw-py-2">
                        <div><i className="fa fa-info-circle tw-mr-2" />We have detected an existing BSC account for your email.</div>
                        <div>Please enter your password to link your BSC account with Google.</div>
                    </Alert>
                    <Textbox
                        label="Email"
                        value={history.location?.state?.body?.email ?? ""}
                        disabled
                        onChange={() => {}}
                    />
                    <PasswordField
                        label="Confirm Password *"
                        isRequired
                        placeholder="Confirm Password"
                        onChange={onChange}
                        name="password"
                        value={formState.password}
                        autoComplete="current-password"
                    />
                    {errors && (
                        <ul className="!tw-mt-1">
                            {errors?.map(errorMessage => (
                                <li className="text-danger font-weight-bold">
                                    {errorMessage}
                                </li>
                            ))}
                        </ul>
                    )}
                    <Button
                        type="submit"
                        color="primary"
                        className="tw-w-24 tw-mx-auto"
                        disabled={loading}
                    >
                        {loading ? (<Spinner size="20" className="tw-mx-auto" />) : "Proceed"}
                    </Button>
                    <Button
                        color="transparent"
                        className="tw-w-24 tw-mx-auto"
                        onClick={() => history.push("/login")}
                        disabled={loading}
                    >
                        Login
                    </Button>
                </Form>
            )}
        </LoginBackgroundWrapper>
    );
};

export { VerifyPasswordPage };
