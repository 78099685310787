import React, { useContext } from "react";

const SupplierContext = React.createContext({});

const useSupplierContext = () => useContext(SupplierContext);
const SupplierProvider = SupplierContext.Provider;

export {
    useSupplierContext,
    SupplierProvider,
};
