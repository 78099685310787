// A Draft.js RichText editor built with draft-js-plugins so we can completely own the styling.
// Draft.js best practice followed by using convertFromRaw and convertToRaw (sparingly) to store state on the server

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import createEmojiPlugin from "draft-js-emoji-plugin";
import "draft-js-emoji-plugin/lib/plugin.css";
import { Label } from "reactstrap";

import { createBlockQuotePlugin } from "./Plugins/createBlockQuotePlugin";
import * as Buttons from "./Buttons";
import toolbarStyles from "./Toolbar/styles.module.scss";
import buttonStyles from "./Buttons/styles.module.scss";
import styles from "./styles.module.scss";

const RichTextEditor = ({
    placeholder,
    onChange,
    readonly,
    editorState,
    showToolbar,
    editorClassName,
    editorReadonlyClassName,
    label,
    maxCharacters,
    onMaxCharactersReached,
}) => {
    const [{ plugins, Toolbar }] = useState(() => {
        const toolbarPlugin = createToolbarPlugin({ theme: { toolbarStyles, buttonStyles } });

        const emojiPlugin = createEmojiPlugin(); // Coming soon

        return {
            plugins: [toolbarPlugin, createBlockQuotePlugin(), emojiPlugin],
            Toolbar: toolbarPlugin.Toolbar,
        };
    });

    const [placeholderState, setPlaceholderState] = useState(() => (placeholder || ""));
    const editorRef = useRef(null);

    // Handle Draft.js placeholder hack by calling focus as soon as the user clicks on our wrapped Editor.
    // See Draft.js their own example https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
    const focus = () => {
        setPlaceholderState("");
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const onEditorChange = (editorStateValue) => {
        if (maxCharacters && editorStateValue.getCurrentContent().getPlainText("\u0001").length > maxCharacters) {
            onMaxCharactersReached();
            return;
        }
        onChange(editorStateValue);
        if (placeholder && editorStateValue.getCurrentContent()
            .getPlainText() === "") {
            setPlaceholderState(placeholder);
        }
    };

    return (
        <>
            {label && (
                <div className="d-flex justify-content-between align-items-center">
                    <Label>{label}</Label>
                    {maxCharacters && (
                        <span className="text-primary align-self-end mb-2">
                            {`${maxCharacters - editorState.getCurrentContent().getPlainText("\u0001").length} characters remaining`}
                        </span>)}
                </div>)}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <div className={readonly ? editorReadonlyClassName : editorClassName} onClick={focus} role="textbox">
                <Editor
                    editorState={editorState}
                    placeholder={placeholderState}
                    onChange={onEditorChange}
                    plugins={plugins}
                    readonly={readonly}
                    ref={editorRef}
                    spellCheck={!readonly}
                />

                {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                <div className={showToolbar ? "" : "d-none"} onMouseOver={focus}>
                    <Toolbar>
                        {
                            (externalProps) => (
                                <>
                                    <Buttons.BoldButton {...externalProps} />
                                    <Buttons.ItalicButton {...externalProps} />
                                    <Buttons.UnderlineButton {...externalProps} />
                                    <Buttons.StrikeThroughButton {...externalProps} />
                                    <Buttons.Separator {...externalProps} />
                                    <Buttons.ParagraphButton {...externalProps} />
                                    <Buttons.HeadingOneButton {...externalProps} />
                                    <Buttons.HeadingTwoButton {...externalProps} />
                                    <Buttons.HeadingThreeButton {...externalProps} />
                                    <Buttons.Separator {...externalProps} />
                                    <Buttons.UnorderedListButton {...externalProps} />
                                    <Buttons.OrderedListButton {...externalProps} />
                                    <Buttons.Separator {...externalProps} />
                                    <Buttons.BlockquoteButton {...externalProps} />
                                </>
                            )
                        }
                    </Toolbar>
                </div>
            </div>

        </>
    );
};

RichTextEditor.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    editorState: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    showToolbar: PropTypes.bool,
    editorClassName: PropTypes.string,
    editorReadonlyClassName: PropTypes.string,
    label: PropTypes.string,
    maxCharacters: PropTypes.number,
    onMaxCharactersReached: PropTypes.func,
};

RichTextEditor.defaultProps = {
    onChange: () => { },
    placeholder: "",
    readonly: false,
    showToolbar: true,
    editorClassName: styles.editor,
    editorReadonlyClassName: styles.editorReadonly,
    label: "",
    maxCharacters: null,
    onMaxCharactersReached: () => { },
};

export { RichTextEditor };
