/* eslint-disable import/extensions */
import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import TextBackgroundImg from "@/Assets/Images/Dashboard/Tiles/Text-Background.png";

interface Props {
    title: string,
    contentTitle: string,
    contentDescription: string,
    isMobileTextBackgroundRight?: boolean,
    desktopImage: string,
    mobileImage: string,
    link?: string,
    onClick?: () => void,
}

const DashboardTile = ({ title, contentTitle, contentDescription, isMobileTextBackgroundRight, desktopImage, mobileImage, link, onClick }: Props) => (
    <div className="tw-flex tw-flex-col tw-justify-center">
        <Link to={link} onClick={onClick} data-testid={link}>
            <div className="tw-flex tw-flex-col tw-items-center tw-text-cyan-800">
                <div className="tw-hidden sm:tw-block tw-flex tw-flex-row tw-justify-between tw-border tw-shadow tw-rounded tw-py-6 tw-w-full">
                    <div className="tw-pl-4 tw-text-cyan-800 tw-text-sm 4xl:tw-text-base tw-font-bold">{title}</div>
                </div>
                <div className="tw-relative tw-flex tw-flex-col tw-space-x-36 tw-w-full tw-max-w-[360px] sm:tw-max-w-full">
                    <div>
                        <picture>
                            <source media="(max-width: 639.9px)" srcSet={mobileImage} />
                            <source media="(min-width: 640px)" srcSet={desktopImage} />
                            <img
                                src={desktopImage}
                                alt={title}
                                className="tw-relative tw-flex tw-flex-col tw-object-cover tw-w-full tw-h-48 sm:tw-h-56 lg:tw-h-60 3xl:tw-h-[22rem] 4xl:tw-h-[24rem]"
                            />
                        </picture>
                        <div className={classnames("tw-absolute tw-w-[45%] tw-h-full tw-bottom-0", !isMobileTextBackgroundRight ? "tw-left-0" : "tw-right-0 sm:tw-left-0")}>
                            <img
                                src={TextBackgroundImg}
                                alt={`${title}-bg`}
                                className="tw-flex tw-flex-col tw-w-full tw-h-full tw-object-cover"
                            />
                        </div>
                        <div className={classnames("tw-absolute tw-w-[45%] tw-h-full tw-bottom-0 tw-flex tw-flex-col tw-space-y-1.5",
                            "tw-pt-8 lg:tw-pt-14 3xl:tw-pt-28 tw-p-2.5 3xl:tw-px-3",
                            !isMobileTextBackgroundRight ? "tw-left-0" : "tw-right-0 sm:tw-left-0")}
                        >
                            <div className="tw-text-white tw-text-base 4xl:tw-text-lg tw-font-bold tw-uppercase">
                                {contentTitle}
                            </div>
                            <div className="tw-text-white tw-text-xs 4xl:tw-text-sm">
                                {contentDescription}
                            </div>
                        </div>
                    </div>
                    <div className="tw-relative">
                        <div className="tw-hidden sm:tw-block tw-px-4 tw-pb-6 tw-flex tw-flex-col tw-absolute tw-bottom-0 tw-right-0">
                            <div className="tw-flex tw-flex-col tw-rounded-full tw-bg-white tw-w-7 tw-h-7 hover:tw-opacity-90">
                                <i className="fas fa-angle-right tw-text-2xl tw-pl-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-w-full tw-max-w-[360px] sm:tw-max-w-full">
                    <div className="tw-block sm:tw-hidden tw-flex tw-flex-row tw-justify-between tw-border tw-shadow tw-rounded tw-py-1">
                        <div className="tw-py-4 tw-pl-4 tw-text-cyan-800 tw-text-sm 4xl:tw-text-base tw-font-bold">{title}</div>
                        <div className="tw-pt-3 tw-text-cyan-800 tw-text-lg tw-justify-end tw-pr-5 tw-font-bold">
                            <i className="fas fa-angle-right tw-text-2xl" />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </div>
);

export { DashboardTile };
