import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";

import Spinner from "@/Components/Spinner";
import urlHelper from "@/Utils/urlHelper";
import { useAPI } from "@/Apis/useAPI";
import { decodeCustomToken, getToken } from "@/Utils/authentication";
import { AcceptRejectPageType } from "@/Apis/Receipts";
import { Urls } from "@/Apis/urls";

const AcceptRejectQuotation = () => {
    const location = useLocation();
    const { push } = useHistory();
    const [token] = useState(urlHelper.getUrlParam(location.search, "token"));
    const { post } = useAPI({ handle500WithToastMessage: true });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const pageType: AcceptRejectPageType = urlHelper.urlContains(location, "accept") ? "accept" : "reject";

    useEffect(() => {
        const tokenDecoded = decodeCustomToken(token) as { ReceiptId: string, ConversationId: string };
        const loginUri = `/login?returnUrl=/quotations/${pageType}?token=${token}`;
        const loggedIn = !!getToken(); // The react redux useSelector doesn't seem to be accurate on first load

        if (!loggedIn) {
            push(loginUri);
            return;
        }

        post(Urls.receipts.acceptReject(pageType, tokenDecoded.ReceiptId), {}, { loginUri })
            .then(() => push(`/messages/inbox/${tokenDecoded.ConversationId}`, { directToReceiptSection: true }))
            .catch(error => {
                setError(error);
            })
            .finally(() => setLoading(false));
    }, [location.search, token]);

    return (
        <div className="tw-p-10">
            {loading
            && (
                <div className="tw-flex tw-items-center tw-space-x-2 tw-justify-center">
                    <Spinner size="45" />
                    <p className="tw-text-base">
                        We are {`${pageType}ing`} the quotation. You will be redirected to the messaging screen where you will be able to proceed to checkout.
                    </p>
                </div>
            )}
            {error && <Alert color="danger" className="tw-text-base">{error}</Alert>}
        </div>
    );
};

export default AcceptRejectQuotation;
