import React from "react";
import classnames from "classnames";
import { Alert } from "reactstrap";

import styles from "./styles.module.scss";

import { ErrorResponse } from "@/Apis/useAPI";

export const AlertStyle = {
    PopUp: "POPUP",
    NavLoggedIn: "NAVLOGGEDIN",
    NavFullWidth: "NAVFULLWIDTH",
};

declare type ErrorTypes = string | string[] | ErrorResponse;
interface Props {
    message: ErrorTypes,
    alertStyle: string,
}

const ErrorToastMessage = ({ message, alertStyle }: Props): JSX.Element => {
    let style: string;
    switch (alertStyle) {
        case AlertStyle.PopUp:
            style = styles.popupToast;
            break;
        case AlertStyle.NavFullWidth:
            style = styles.fullWidthToast;
            break;
        default:
            style = styles.toast;
    }

    function buildErrorMessages(errors: ErrorTypes) {
        let err = errors;
        if (typeof err === "string") {
            return <>{err}</>;
        }
        if ((err as ErrorResponse).validationFailed === true) {
            /*
            JC:
            `response.errors` will look like:
                {
                    "key1": "message1",
                    "key2": "message2"
                }
            */
            err = Object.keys((err as ErrorResponse).errors).map(key => (err as ErrorResponse).errors[key]);
        }
        if (Array.isArray(err)) {
            return (
                <>
                    {err.map((msg) => (<div key={msg}>{msg}</div>))}
                </>
            );
        }
        return "Unknown error";
    }

    return (
        <div className={classnames(styles.container, "w-100 d-flex justify-content-center position-fixed")}>
            <Alert color="danger" className={classnames(style, "position-absolute d-flex align-items-center")} isOpen>
                <i className="fa fa-exclamation-triangle mt-1" />
                <span data-testid="error-message">
                    {buildErrorMessages(message)}
                </span>
            </Alert>
        </div>
    );
};

export { ErrorToastMessage };
