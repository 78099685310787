/* eslint-disable import/extensions */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SpinnerGif from "@/Assets/Images/loading.gif";

const Spinner = props => {
    const spinnerRef = useRef(null);

    useEffect(() => {
        if (spinnerRef.current == null) {
            const image = new Image();
            image.src = SpinnerGif;
        }
    }, []);

    if (props.text) {
        return (
            <div className={classnames(props.className, "tw-flex tw-flex-row tw-justify-center tw-items-center tw-space-x-2")}>
                <img
                    ref={spinnerRef}
                    className={classnames(props.className)}
                    style={{ height: `${props.size}px`, width: `${props.size}px` }}
                    src={SpinnerGif}
                    alt="loading..."
                    data-testid="spinner"
                />
                <span>{props.text}</span>
            </div>
        );
    }

    return (<img
        ref={spinnerRef}
        className={classnames(props.className)}
        style={{ height: `${props.size}px`, width: `${props.size}px` }}
        src={SpinnerGif}
        alt="loading..."
        data-testid="spinner"
    />);
};

Spinner.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
};

Spinner.defaultProps = {
    size: "32",
};

export default Spinner;
