import React from "react";
import { Button, Col, Row } from "reactstrap";

import { ParticipantProfileSections } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Types";
import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";

interface Props {
    section: ParticipantProfileSections,
    editEnabledMarkup: React.ReactNode,
    editingDisableMarkup: React.ReactNode,
    displayHeading?: boolean,
    buttonText?: string,
}

const ProfileSection = ({ section, buttonText = "Edit", editEnabledMarkup, editingDisableMarkup, displayHeading = true }: Props) => {
    const {
        editableSections,
        editSection,
        saveChanges,
    } = useFlexPlanParticipantContext();

    return (
        <div className="border rounded-lg p-4 m-lg-3 mt-4">
            <Row>
                <Col xs="12" className="tw-flex tw-justify-between tw-mb-3">
                    {displayHeading && <h4 className="font-weight-bold">{section}</h4>}
                    {!editableSections?.includes(section) && <Button onClick={() => editSection(section)} className="px-3" color="primary">{buttonText}</Button>}
                </Col>
            </Row>
            {editableSections.includes(section) ? editEnabledMarkup : editingDisableMarkup}
            {editableSections.includes(section) && (
                <div className="tw-flex tw-flex-row tw-space-x-3 tw-justify-end tw-mt-3">
                    <Button onClick={() => editSection(section)} color="secondary">
                        Cancel
                    </Button>
                    {/* save changes */}
                    <Button type="button" color="primary" onClick={saveChanges}>Save</Button>
                </div>
            )}
        </div>
    );
};

export default ProfileSection;
