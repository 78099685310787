import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";

import styles from "./styles.module.scss";

import Textbox from "@/Components/Textbox";
import { validateEmptyGuid } from "@/Utils/validator";

const DragHandle = SortableHandle(() => <i className={classnames("fas fa-bars mx-3 mt-1 text-muted h6", styles.sortableGrab)} />);

const Product = SortableElement(({ value, valid, position, update, remove }) => (
    <div className={classnames("d-flex justify-content-between", styles.sortableProduct)}>
        <Textbox className="w-100" onChange={e => update(position, e)} value={value} error={valid ? "" : "Invalid product identifier"} />
        <DragHandle />
        <Button color="link" className={classnames(styles.deletebutton, "p-0 pointer")} onClick={() => remove(position)}>
            <i className="fa fa-trash-alt text-danger h6" />
        </Button>
    </div>
)) as any;

const Container = SortableContainer(({ children }) => <div>{children}</div>) as any;

interface Props {
    upsellItems: string[];
    onItemsUpdated: (field: string) => ({ target: { value } }) => void;
}

const Upsell = ({ upsellItems, onItemsUpdated }: Props) => {
    const maxProducts = 3;

    const [products, setProducts] = useState(upsellItems.length === 0 ? [""] : upsellItems);

    useEffect(() => {
        onItemsUpdated("upsellItems")({ target: { value: products } });
    }, [products]);

    const addAnotherProduct = () => {
        if (products.length < maxProducts) {
            setProducts(prevState => prevState.concat([""]));
        }
    };

    const remove = (index: number) => {
        const filtered = products.filter((product, i) => i !== index);

        if (filtered.length === 0) {
            setProducts([""]);
        } else {
            setProducts(filtered);
        }
    };

    const update = (index: number, e) => {
        const value = e.target.value.trim();
        setProducts(prevState => prevState.map((product, i) => (i === index ? value : product)));
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProducts(prevState => arrayMove(prevState, oldIndex, newIndex));
    };

    return (
        <div className="m-4">
            <div className="w-75 mx-auto">
                <p className="text-center">
                    Upsells are optional. Open the Marketplace to search for products/services which complement your product/service and you would want to upsell.
                </p>
            </div>

            <div className="d-flex justify-content-between">
                <a className="mx-auto mt-2 mb-4 text-center btn btn-outline-primary" href="/marketplace" target="_blank">
                    Open Marketplace
                </a>
            </div>

            <p className="text-muted">
                Note: Order of entry below will also be the priority order of display as an upsell. You can rearrange the priority by dragging
                the <i className="fas fa-bars" /> control.
            </p>

            <Container
                lockAxis="y"
                useDragHandle
                onSortEnd={onSortEnd}
                transitionDuration={500}
            >
                {products.map((product, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Product key={i.toString()} index={i} position={i} value={product} valid={validateEmptyGuid(product)} update={update} remove={remove} />
                ))}
            </Container>

            {products.length === maxProducts && <p>Maximum products reached.</p>}

            <Button color="primary" onClick={addAnotherProduct} disabled={products.length === maxProducts}>
                Add another
            </Button>
        </div>
    );
};

export { Upsell };
