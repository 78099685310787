import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import classnames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

import { Filter } from "@/FlexPlan/Types/Pagination";
import Checkbox from "@/Components/Checkbox";

interface Props {
    filters?: Filter[],
    onChange: (value) => any,
    description?: string,
    disabled?: boolean,
    label?: string,
    className?: string,
}

const FilterDropdown = ({
    filters,
    onChange,
    description,
    disabled = false,
    label,
    className,
}: Props) => (
    <div className={classnames("tw-flex tw-flex-row tw-items-center -tw-mt-5", className)}>
        <UncontrolledDropdown direction="down" disabled={disabled}>
            <DropdownToggle className={classnames(styles.filterButton, "bg-transparent border-0 text-dark")}>
                <div className="tw-flex tw-flex-row tw-space-x-1">
                    {label && <div className="tw-whitespace-nowrap">{label}</div>}
                    <i className="fa fa-filter " />
                </div>
            </DropdownToggle>
            <DropdownMenu>
                {description && (
                    <div className="tw-text-base tw-font-semibold tw-pl-6 tw-p-2">
                        {description}
                    </div>
                )}
                {filters
                    ?.sort((a, b) => a.position - b.position)
                    .map(filter => (
                        <DropdownItem className="tw-flex tw-flex-row" key={filter.field}>
                            <Checkbox
                                onChange={onChange(filter.field)}
                                checked={filter.checked}
                                id={filter.field}
                                label={filter.label}
                            />
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
);

export { FilterDropdown };
