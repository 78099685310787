import React, { useContext, useState } from "react";

import { Folder, GetConversationResponse, MessagingUrls } from "@/Apis/Messaging";
import { useAPI } from "@/Apis/useAPI";
import { ReceiptStatus } from "@/Apis/Receipts";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface MessagingState {
    message: GetConversationResponse | any
    enquiryFormFields: any[]
    loadMessage: (conversationId: string, folder: Folder) => void;
    loadEnquiryAnswers: (conversationId: string) => void;
    quoteAdded: (quote) => void;
    quoteRemoved: (quoteId) => void;
    quoteUpdated: (quote) => void;
    quoteAccepted: (quoteId) => void;
    quoteRejected: (quoteId) => void;
    quoteReasonAdded: (quote) => void;
    quoteProceeded: (quoteId) => void;
    supplierQuoteDecision: (decision: ReceiptStatus) => void;
    confirmReceiptActionModalOpen: boolean;
    supplierReceiptLoading: boolean;
    toggleReceiptConfirmModal: () => void;
}

const MessagingContext = React.createContext<MessagingState>({} as MessagingState);
const useMessagingContext = () => useContext(MessagingContext);

interface Props {
    children: React.ReactNode,
}

const MessagingProvider = ({ children }: Props) => {
    const { get, post } = useAPI({ handle500WithRedirect: true });
    const [message, setMessage] = useState<GetConversationResponse>();
    const [enquiryFormFields, setEnquiryFormFields] = useState<any[]>([]);
    // Supplier receipt state
    const [supplierReceiptLoading, setSupplierReceiptLoading] = useState<boolean>(false);
    const [confirmReceiptActionModalOpen, setConfirmReceiptActionModalOpen] = useState<boolean>(false);
    const toggleReceiptConfirmModal = () => setConfirmReceiptActionModalOpen(prevState => !prevState);

    const { setPopupErrorMessage } = useToastMessageContext();

    const loadMessage = (conversationId: string, folder: Folder) => {
        const isDraft = folder === "drafts";
        const getConversationUrl = isDraft ? MessagingUrls.getDraftConversation(conversationId) : MessagingUrls.getConversation(conversationId);
        get<GetConversationResponse>(getConversationUrl)
            .then(response => setMessage(response));
    };

    const loadEnquiryAnswers = (conversationId: string) => {
        get(`enquiryform/get-enquiry-answers/${conversationId}`)
            .then((response: any) => {
                setEnquiryFormFields(response.enquiryAnswers);
            });
    };

    const quoteAdded = (quote) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.concat([quote]),
            });
        }
    };

    const quoteRemoved = (quoteId) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.filter(x => x.quoteId !== quoteId),
            });
        }
    };

    const quoteUpdated = (quote) => {
        if (message) {
            const nextOrder = Math.max(...message.bespokeQuotes.map(x => x.ordinal));
            const isUpdateAndNotClone = message.bespokeQuotes.some(x => x.quoteId === quote.quoteId);

            setMessage({
                ...message,
                bespokeQuotes: isUpdateAndNotClone
                    ? message.bespokeQuotes.map(x => (x.quoteId === quote.quoteId ? quote : x))
                    : message.bespokeQuotes.concat([{
                        ...quote,
                        ordinal: nextOrder ? nextOrder + 1 : 1,
                    }]),
            });
        }
    };

    const quoteAccepted = (quoteId) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.map(x => (x.quoteId === quoteId ? { ...x, status: "Accepted" } : x)),
            });
        }
    };

    const quoteRejected = (quoteId) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.map(x => (x.quoteId === quoteId ? { ...x, status: "Rejected" } : x)),
            });
        }
    };

    const quoteReasonAdded = (quote) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.map(x => (x.quoteId === quote.quoteId ? {
                    ...x,
                    rejectionReason: quote.reason,
                } : x)),
            });
        }
    };

    const quoteProceeded = (quoteId) => {
        if (message) {
            setMessage({
                ...message,
                bespokeQuotes: message.bespokeQuotes.map(x => (x.quoteId === quoteId ? { ...x, status: "CheckedOut" } : x)),
            });
        }
    };

    const supplierQuoteDecision = (decision: ReceiptStatus) => {
        setSupplierReceiptLoading(true);
        if (message) {
            const url = `receipts/${decision === ReceiptStatus.Accepted ? "accept" : "reject"}/${message.receiptId}`;

            post(url, {})
                .then(() => {
                    setMessage({
                        ...message,
                        receiptStatus: decision,
                    });
                })
                .catch((err) => {
                    setPopupErrorMessage(err, true);
                })
                .finally(() => {
                    setSupplierReceiptLoading(false);
                    toggleReceiptConfirmModal();
                });
        }
    };

    return (
        <MessagingContext.Provider value={{
            message,
            enquiryFormFields,
            loadMessage,
            loadEnquiryAnswers,
            quoteAdded,
            quoteRemoved,
            quoteUpdated,
            quoteAccepted,
            quoteRejected,
            quoteReasonAdded,
            quoteProceeded,
            supplierQuoteDecision,
            supplierReceiptLoading,
            toggleReceiptConfirmModal,
            confirmReceiptActionModalOpen,
        }}
        >
            {children}
        </MessagingContext.Provider>);
};

export {
    useMessagingContext,
    MessagingProvider,
};
