import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router";

import { decodeCustomToken } from "@/Utils/authentication";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import Spinner from "@/Components/Spinner";
import { useAuthentication } from "@/Hooks/useAuthentication";
import { ExternalSignInRequest, GoogleSignInType } from "@/Components/GoogleSignIn/Types";

interface Props {
    className?: string,
    type: GoogleSignInType,
    displayOr?: boolean,
    displayOrPosition?: "above" | "below",
    shape?: "rectangular" | "pill",
}

const GoogleSignIn = ({ className, type, displayOr = false, shape = "rectangular", displayOrPosition = "above" }: Props) => {
    const { googleSignInConfiguration } = useConfigurationContext();
    const { setPopupErrorMessage } = useToastMessageContext();

    const { onLoginSuccess, onSignUpSuccess } = useAuthentication();
    const { post, loading } = useAPI({ handle500WithRedirect: true });
    const { push } = useHistory();

    const [rerender, setRerender] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>();

    // Has to be a window level function
    window.handleCredentialResponse = (response) => {
        const responsePayload = decodeCustomToken(response.credential);

        const request: ExternalSignInRequest = {
            provider: googleSignInConfiguration.providerName,
            providerKey: responsePayload.sub,
            email: responsePayload.email,
            firstName: responsePayload.given_name,
            surname: responsePayload.family_name,
        };

        post(Urls.account.externalSignIn, request)
            .then(async response => {
                if (type === GoogleSignInType.Login) {
                    await onLoginSuccess(response);
                } else if (GoogleSignInType.SignUp) {
                    await onSignUpSuccess(response);
                }
            })
            .catch(error => { // We might need to use a context to set the login error state from the parent component
                if (error && error.linkAccountsRequired) {
                    push("/verify-password", {
                        verifySubmitEndpoint: Urls.account.linkExternalProvider,
                        body: request,
                        type,
                    });
                } else if (error.validationFailed) {
                    setErrors(Object.keys(error.errors).map(err => error.errors[err]));
                } else if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            })
            .finally(() => {
                setRerender(prev => !prev);
            });
    };

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [rerender]);

    const renderOrBlock = () => (
        <div className="tw-flex tw-justify-center tw-mt-3 tw-mb-2">
            <span className="tw-text-brand-primary tw-font-semibold">Or</span>
        </div>
    );

    return (
        <>
            {displayOr && displayOrPosition === "above" && (
                renderOrBlock()
            )}
            {loading ? (
                <Spinner className="tw-mx-auto tw-my-5" />
            ) : (
                <div className={classnames("tw-flex tw-justify-center", className)}>
                    <div
                        id="g_id_onload"
                        data-client_id={googleSignInConfiguration.clientId}
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleCredentialResponse"
                        data-auto_prompt="false"
                    />
                    <div
                        className="g_id_signin tw-mx-auto"
                        data-type="standard"
                        data-shape={shape}
                        data-theme="outline"
                        data-text="signin_with"
                        data-size="large"
                        data-logo_alignment="left"
                    />
                </div>
            )}
            {displayOr && displayOrPosition === "below" && (
                renderOrBlock()
            )}
            {errors && (
                <ul className="tw-mx-10">
                    {errors?.map(errorMessage => (
                        <li className="text-danger font-weight-bold">
                            {errorMessage}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export { GoogleSignIn };
