import React from "react";
import { Alert, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class ErrorWrapper extends React.Component {
    state = { error: null, info: null };

    componentDidCatch(error, info) {
        this.setState({ error, info });
    }

    render() {
        if (this.state.error) {
            return (
                <Col xs="10" className="mx-auto">
                    <Alert color="danger" className="m-3 mt-md-5 pt-3">
                        <p>Unfortunately an error occurred.</p>
                        <p>If this problem persists, please contact us.</p>
                        <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.info && this.state.info.componentStack}
                        </details>
                    </Alert>
                    <div className="m-3 my-md-5">
                        <Button className="mr-2" color="primary" tag={Link} to="/">Home</Button>
                    </div>
                </Col>
            );
        }

        return this.props.children;
    }
}

ErrorWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorWrapper;
