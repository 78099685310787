import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { Referral } from "@/Apis/Referrals";
import * as ReferralApi from "@/Apis/Referrals";

const ReferralsPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [visibleReferrals, setVisibleReferrals] = useState<Referral[]>([]);

    useEffect(() => {
        ReferralApi.getReferrals()
            .then(response => {
                setReferrals(response.results);
                setVisibleReferrals(response.results);
            });
    }, []);

    const onSearch = (query: string) => {
        setSearchQuery(query);
        let filteredReferrals = referrals;

        if (query) {
            filteredReferrals = referrals.filter(x => JSON.stringify(x).toLowerCase().indexOf(query.toLowerCase()) > -1);
        }

        setVisibleReferrals(filteredReferrals);
    };

    return (
        <div className="border rounded-lg p-4 m-lg-3 mb-4 mt-2">
            <h4 className="font-weight-bold mb-4">My Referrals</h4>

            <div className={classnames(styles.search, "border rounded mb-4")}>
                <input
                    type="text"
                    value={searchQuery}
                    className="m-2"
                    placeholder="Search Referrals"
                    onChange={e => onSearch(e.target.value)}
                />
                <i className={classnames(styles.icon, "fa fa-search mt-1 mr-2")} />
            </div>

            <Table borderless>
                <thead>
                    <tr className={styles.row}>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Date Joined</th>
                        <th className="d-none d-lg-block">Referral Link</th>
                    </tr>
                </thead>
                <tbody>
                    {visibleReferrals.map((x) => (
                        <tr key={x.id} className={classnames(styles.row, "m-4")}>
                            <td>{x.firstName}</td>
                            <td>{x.lastName}</td>
                            <td>{x.dateJoined}</td>
                            <td className="d-none d-lg-block">{x.referralLink}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {visibleReferrals.length === 0 && (
                <p>No referrals to date</p>
            )}

        </div>
    );
};

export { ReferralsPage };
