/* eslint-disable import/extensions */
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import WarningIcon from "@/Assets/Icons/Coloured/warning.svg";

interface Props {
    saveAsDraft(): void;
    continueWithoutSaving(): void;
    discardMessage(): void;
}

const DiscardMessageModal = ({ continueWithoutSaving, discardMessage, saveAsDraft }: Props) => (
    <Modal isOpen size="lg" centered className={classnames(styles.modal, "p-2")} data-testid="discard-message-modal">
        <ModalBody>
            <button
                type="button"
                onClick={continueWithoutSaving}
                className={classnames(styles.close, "position-absolute")}
            >
                <i className="fa fa-times" />
            </button>
            <div className="d-flex mt-3">
                <div className="align-self-center"><WarningIcon /></div>
                <div className="ml-3">
                    <p className="font-weight-bold mb-2 align-self-center">This Message Has Not yet Been Sent</p>
                    You can save the message and find it in your Drafts folder by clicking on “Save as Draft”.
                </div>
            </div>
        </ModalBody>
        <ModalFooter className="w-100 justify-content-center">
            <Button color="primary" type="button" className="mr-2" onClick={saveAsDraft} data-testid="save-as-draft-button">
                Save as Draft
            </Button>
            <Button color="secondary" type="button" className="mr-2" onClick={continueWithoutSaving} data-testid="continue-button">
                Continue
            </Button>
            <Button color="danger" className="bg-transparent text-danger" type="button" onClick={discardMessage} data-testid="discard-button">
                Discard
            </Button>
        </ModalFooter>
    </Modal>);

export { DiscardMessageModal };
