/* eslint-disable import/extensions */
import React from "react";

import FlexPlanLogo from "@/FlexPlan/Assets/Images/flex-plan-logo.svg";

interface Props {
    title: string
}
// This heading is commonly used in FlexPlan Sign Up forms
const Heading = ({ title }: Props) => (
    <div
        className="tw-bg-flexplan-primary tw-text-white tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0 md:tw-flex-row
                tw-space-x-3 tw-justify-center tw-items-center tw-py-10"
    >
        <div className="tw-w-52 -tw-mt-3">
            <FlexPlanLogo />
        </div>
        <div className="tw-text-3xl">{title}</div>
    </div>
);

export { Heading };
