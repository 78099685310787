import api from "@/Utils/api";

export interface Review {
    reviewId: string,
    orderId: string,
    orderRef: string,
    productId: string,
    productName: string,
    supplierId: string,
    supplierName: string,
    datePaid: string,
    rating: number,
    comment: string,
    status: ReviewStatus,
    isApproving: boolean,
    isRejecting: boolean,
    isFeatured: boolean,
}

export interface ReviewResponse {
    reviews: Review[];
    pages: number;
}

export interface ReviewsRequest {
    sortColumn?: SortColumn,
    IsAsc: boolean,
    statusFilter?: ReviewStatus;
    currentPage: number;
    searchTerm: string;
    pageNumber: number;
}

export enum SortColumn {
    ProductName,
    SupplierName,
    Rating,
}

export enum ReviewStatus {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
}

const getReviews = (request: ReviewsRequest) => api.post("reviews/all", request) as Promise<{ reviews: Review[] }>;

export {
    getReviews,
};
