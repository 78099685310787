import { BasketContextType } from "@/Context/BasketContext";

export interface NotificationActions {
    testMessage: string;
    removeItemFromBasketNotification: string;
}

export interface Subscription {
    actionName: keyof NotificationActions;
    action: (basketContext: BasketContextType, payload: string) => void;
}

export const subscriptions: Array<Subscription> = [
    {
        actionName: "testMessage",
        action: (basketContext, payload) => {
            // eslint-disable-next-line no-console
            console.log(payload);
        },
    },
    {
        actionName: "removeItemFromBasketNotification",
        action: (basketContext, payload) => {
            basketContext.removeItemFromBasket(payload);
        },
    }];
