import React from "react";
import { Button, FormGroup, Label } from "reactstrap";

import ProfileSection from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/Section";
import { DropDownOption, FloatLabelDropdown } from "@/Components/FloatLabelDropdown";
import RadioButton from "@/Components/RadioButton";
import ReadonlyProfileField from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/ReadonlyField";
import { enumDisplay } from "@/Utils/formatStringHelper";
import { PlanType } from "@/FlexPlan/Types/Plan";
import { usePlanManagerContext } from "@/FlexPlan/Context/PlanManagerContext";
import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";
import Spinner from "@/Components/Spinner";
import { DropZoneMultiDownload } from "@/FlexPlan/Components/DropZoneMultiDownload";

const PlanInformationTab = () => {
    const {
        onPlanTypeSelect,
        formState,
        onRadioChange,
        loading,
        onChangeDocument,
        onDeleteSupportingDocument,
        onFileDownload,
    } = useFlexPlanParticipantContext();

    const {
        companyName,
    } = usePlanManagerContext();

    if (loading) {
        return (
            <Spinner className="tw-my-10 tw-mx-auto" size="40" />
        );
    }

    return (
        <>
            <ProfileSection
                section="Plan Details"
                editEnabledMarkup={(
                    <FloatLabelDropdown
                        className="!tw-w-72"
                        label="Plan Type"
                        onApply={onPlanTypeSelect}
                        options={Object.keys(PlanType).map(x => ({
                            display: enumDisplay(PlanType[x]),
                            value: PlanType[x],
                        } as DropDownOption))}
                        selectedValue={formState.planType}
                    />
                )}
                editingDisableMarkup={(
                    <ReadonlyProfileField
                        label="Plan Type"
                        value={formState?.planType && enumDisplay(formState.planType)}
                    />
                )}
            />
            <ProfileSection
                section="Privacy"
                editEnabledMarkup={(
                    <>
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label>Do you authorise {companyName} to view your NDIS Plan?</Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.ndisVisible}
                                    onChange={onRadioChange("ndisVisible", true)}
                                    id="authorise-plan-manager"
                                    label="Yes"
                                />
                                <RadioButton
                                    checked={!formState.ndisVisible}
                                    onChange={onRadioChange("ndisVisible", false)}
                                    id="authorise-plan-manager"
                                    label="No"
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label>Sometimes, participants do not want Providers to know they are funded by the NDIS. Which do you prefer?</Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.fundedVisible}
                                    onChange={onRadioChange("fundedVisible", true)}
                                    id="provider-authorised"
                                    label="I don't mind"
                                />
                                <RadioButton
                                    checked={!formState.fundedVisible}
                                    onChange={onRadioChange("fundedVisible", false)}
                                    id="provider-authorised"
                                    label="I prefer that Providers don’t know"
                                />
                            </div>
                        </FormGroup>
                    </>
                )}
                editingDisableMarkup={(
                    <>
                        <ReadonlyProfileField
                            label={`Do you authorise ${companyName} to view your NDIS Plan?`}
                            value={formState.ndisVisible ? "Yes" : "No"}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Sometimes, participants do not want Providers to know they are funded by the NDIS. Which do you prefer?"
                            value={formState.fundedVisible ? "I don't mind" : "I prefer that Providers don’t know"}
                        />
                    </>
                )}
            />
            <ProfileSection
                section="Invoice Management"
                editEnabledMarkup={(
                    <>
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label>Do you want to approve invoices first before they are sent to your Plan Manager?</Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.approveInvoices}
                                    onChange={onRadioChange("approveInvoices", true)}
                                    id="approve-invoices"
                                    label="Yes"
                                />
                                <RadioButton
                                    checked={!formState.approveInvoices}
                                    onChange={onRadioChange("approveInvoices", false)}
                                    id="approve-invoices"
                                    label="No"
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label>Do you want a copy of the invoice?</Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.receiveInvoice}
                                    onChange={onRadioChange("receiveInvoice", true)}
                                    id="copy-invoices"
                                    label="I don't mind"
                                />
                                <RadioButton
                                    checked={!formState.receiveInvoice}
                                    onChange={onRadioChange("receiveInvoice", false)}
                                    id="copy-invoices"
                                    label="I prefer that Providers don’t know"
                                />
                            </div>

                        </FormGroup>
                    </>
                )}
                editingDisableMarkup={(
                    <>
                        <ReadonlyProfileField
                            label="Do you want to approve invoices first before they are sent to your Plan Manager?"
                            value={formState.approveInvoices ? "Yes" : "No"}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Do you want a copy of the invoice?"
                            value={formState.receiveInvoice ? "Yes" : "No"}
                        />
                    </>
                )}
            />
            <ProfileSection
                section="Copy of Participant Plan"
                editEnabledMarkup={(
                    <DropZoneMultiDownload
                        onFilesUpdated={onChangeDocument}
                        onDelete={onDeleteSupportingDocument}
                        onDownload={onFileDownload}
                        files={formState.supportingDocuments}
                    />
                )}
                editingDisableMarkup={(
                    <div className="tw-flex tw-flex-col">
                        {formState.supportingDocuments?.filter(x => x.name).map(document => (
                            <Button
                                key={`${document.id}-${document.name}`}
                                className="font-weight-bold !tw-text-left"
                                color="transparent"
                                onClick={onFileDownload(document.id ?? "")}
                                disabled={!document.id}
                            >
                                {document.name}
                            </Button>
                        ))}
                    </div>
                )}
            />
        </>
    );
};

export default PlanInformationTab;
