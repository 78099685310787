const formatStatusRegEx = new RegExp(/([A-Z])/g);

const formatStatus = status => status.replace(formatStatusRegEx, " $1").trim();

const closeFilterValues = ["3", "4"]; // complete and cancelled

export {
    formatStatus,
    closeFilterValues,
};
