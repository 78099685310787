import React from "react";
import classnames from "classnames";

import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";

interface Props {
    className?: string,
}

const ProgressBar = ({ className }: Props) => {
    const {
        tab,
    } = useFlexPlanSupplierSignUpContext();

    const renderCircle = (className?: string, center?: boolean) => (
        <div className={classnames(className,
            "tw-inline-block  tw-text-center tw-rounded-[17px]",
            center && "tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2")}
        />
    );

    const renderLine = (colourClassName?: string) => (
        <div className="tw-w-20 md:tw-w-40 tw-flex tw-items-center">
            <div className={classnames("tw-w-96 tw-h-1", colourClassName)} />
        </div>
    );

    const renderText = (text: string, colourClassName?: string) => (
        <span className={classnames(colourClassName, "tw-absolute tw-top-10 -tw-left-16 tw-w-40 tw-font-semibold")}>
            {text}
        </span>
    );

    switch (tab) {
        case "public":
            return (
                <div className={classnames(className, "tw-h-16")}>
                    <div className="tw-relative">
                        {renderCircle("tw-w-[28px] tw-h-[28px] tw-bg-flexplan-secondary", true)}
                        {renderCircle("tw-w-[12px] tw-h-[12px] tw-bg-white", true)}
                        {renderText("PUBLIC INFORMATION", "tw-text-flexplan-secondary")}
                    </div>
                    {renderLine("tw-bg-flexplan-secondary")}
                    {renderLine("tw-bg-gray-400")}
                    <div className="tw-relative">
                        {renderCircle("tw-bg-white tw-w-[28px] tw-h-[28px] tw-border tw-border-4 tw-border-gray-400", true)}
                        {renderText("PRIVATE INFORMATION", "tw-text-gray-400")}
                    </div>
                </div>
            );
        case "private":
            return (
                <div className={classnames(className, "tw-h-16")}>
                    <div className="tw-relative">
                        <i className="fa fa-check tw-text-white tw-z-10 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-[1px]" />
                        {renderCircle("tw-w-[28px] tw-h-[28px] tw-bg-flexplan-primary", true)}
                        {renderText("PUBLIC INFORMATION", "tw-text-flexplan-primary")}
                    </div>
                    {renderLine("tw-bg-flexplan-primary")}
                    {renderLine("tw-bg-flexplan-primary")}
                    <div className="tw-relative">
                        {renderCircle("tw-w-[28px] tw-h-[28px] tw-bg-flexplan-secondary", true)}
                        {renderCircle("tw-w-[12px] tw-h-[12px] tw-bg-white", true)}
                        {renderText("PRIVATE INFORMATION", "tw-text-flexplan-secondary")}
                    </div>
                </div>
            );
        default: return <></>;
    }
};

export { ProgressBar };
