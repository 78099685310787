import React, { useState } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { BlobProvider } from "@react-pdf/renderer";

import { Invoice } from "@/Components/Invoice";

const DownloadSubscriptionInvoice = ({ invoiceDetails, linkText }) => {
    const [loading, setLoading] = useState(false);
    const [pdfDocumentUrl, setPdfDocumentUrl] = useState(null);

    const downloadPdf = (downloadUrl) => {
        if (downloadUrl) {
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `Invoice-${invoiceDetails.orderReference}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        }
    };

    const downloadInvoice = () => {
        setLoading(true);
        downloadPdf(pdfDocumentUrl);
    };

    return (
        <>
            {loading && !pdfDocumentUrl && (
                <BlobProvider document={<Invoice {...invoiceDetails} />}>
                    {({ url }) => {
                        downloadPdf(url);
                        setPdfDocumentUrl(url);
                        return null;
                    }}
                </BlobProvider>
            )}
            <Button color="primary" className="bg-transparent text-primary" disabled={loading} onClick={downloadInvoice}>
                <span className="d-md-none">Invoice</span>
                <span>{linkText} </span>
                <i className="fa fa-download" />
            </Button>
        </>
    );
};

DownloadSubscriptionInvoice.propTypes = {
    invoiceDetails: PropTypes.shape({ orderReference: PropTypes.string.isRequired }).isRequired,
    linkText: PropTypes.string.isRequired,
};

export { DownloadSubscriptionInvoice };
