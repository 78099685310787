import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, Carousel, CarouselItem } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { Visible } from "@/Components/Visible";
import { Next, Prev } from "@/Components/CarouselControls";
import { useBasketContext } from "@/Context/BasketContext";
import { ProductVariations } from "@/Utils/ProductVariations";
import { getProducts } from "@/Apis/Products";

interface Props {
    productIds: string[];
    onClose: () => void;
}

const UpsellModal = ({ productIds, onClose }: Props) => {
    const { addToBasket, basket } = useBasketContext();
    const [products, setProducts] = useState<any[]>([]);

    const [animating, setAnimating] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const itemIdsInCart = basket.groups.flatMap(_ => _.items).map(x => x.serviceId.toUpperCase());

    useEffect(() => {
        getProducts(productIds)
            .then((response) => {
                setProducts(response.products);
            });
    }, [productIds]);

    const addUpsellToBasket = (product, qty, variations) => addToBasket({
        productId: product.id,
        quantity: qty,
        variations,
    });

    const next = () => {
        if (!animating) {
            const nextIndex = activeIndex === products.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(nextIndex);
        }
    };

    const previous = () => {
        if (!animating) {
            const nextIndex = activeIndex === 0 ? products.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
        }
    };

    const renderTiles = () => products.map(x => (
        <ServiceMiniSiteTile
            id={x.id}
            name={x.name}
            description={x.description}
            image={x.imageThumbnail ?? x.image}
            paymentFrequency={x.paymentFrequency}
            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
            postageCharge={x.postageCharge}
            moreDetails={x.moreInformation}
            images={x.images}
            showEditButton={false}
            isInBasket={itemIdsInCart.includes(x.id.toUpperCase())}
            onAddService={(qty, variations) => addUpsellToBasket(x, qty, variations)}
            addQuantity={x.addQuantity}
            minimumQuantity={x.minimumQuantity}
            isBeingUsedForPackages={false}
            variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
            productVariant={x.productVariant}
            initialCharge={x.initialCharge}
            isVatRequired={x.isVatRequired}
            categoryId={x.categoryId}
            categoryName={x.categoryName}
            subCategoryName={x.subCategoryName}
            isUpsell
            canChooseQuantity={x.addQuantity}
            supplierId={x.supplierId}
            supplierName={x.supplierName}
            slug={x.slug}
            slugId={x.slugId}
        />
    ));

    const renderSlides = () => renderTiles().map(tile => (
        <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={`${tile.props.id}`} className="pb-2">
            {tile}
        </CarouselItem>
    ));

    return (
        <Modal isOpen centered size="xl" contentClassName="bg-white">
            <ModalBody className="d-flex flex-column p-lg-5">
                <Visible below="Lg">
                    <h4 className="font-weight-bold mb-4">
                        You might want these other services as well!
                    </h4>
                </Visible>
                <Visible forAndAbove="Lg">
                    <h3 className="font-weight-bold mb-4">
                        You might want these other services as well!
                    </h3>
                </Visible>

                <p>
                    Your supplier has recommended the following products/services to compliment your purchase. Take a look and see if there&apos;s anything of interest for you.
                    Don&apos;t delay, prices may change on this
                    awesome hand picked selection.
                </p>

                <div className={classnames(styles.tileWrapper, "mx-auto")}>
                    <Visible below="Lg">
                        <Carousel next={next} previous={previous} activeIndex={activeIndex}>
                            {renderSlides()}
                            {products.length > 1 && (
                                <>
                                    <Next onClick={next} />
                                    <Prev onClick={previous} />
                                </>
                            )}
                        </Carousel>
                    </Visible>
                    <Visible forAndAbove="Lg">
                        <>{renderTiles()}</>
                    </Visible>
                </div>

                <Button color="secondary" onClick={onClose} className="px-4 mx-auto mt-4">Close</Button>
            </ModalBody>
        </Modal>
    );
};

export { UpsellModal };
