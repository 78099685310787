import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { Button, Col, Media, Row } from "reactstrap";
import { Link, Route } from "react-router-dom";
import { push } from "connected-react-router";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import Spinner from "@/Components/Spinner";
import { Notifications } from "@/Components/Notifications";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import { OrderSearch } from "@/Components/OrderSearch";
import { CustomerOrderDetailsModal } from "@/Modals/CustomerOrderDetailsModal";
import { RequestCancellationModal } from "@/Modals/RequestCancellationModal";
import SortOrdersModal from "@/Modals/SortOrdersModal";
import FilterOrdersModal from "@/Modals/FilterOrdersModal";
import formatDateHelper from "@/Utils/formatDateHelper";
import api from "@/Utils/api";
import { pascalCaseToTitleCase } from "@/Utils/formatStringHelper";
import { Paging } from "@/Components/Paging";

export class CustomerOrdersPage extends React.PureComponent {
    static formatSubscriptionPaymentStatus(subscriptionPaymentStatus, subscriptionPaymentDate, id, isResolved) {
        if (!subscriptionPaymentStatus) {
            return "-";
        }

        if ((subscriptionPaymentStatus === "InvoicePaymentFailed"
                || subscriptionPaymentStatus === "InvoicePaymentActionRequired")
            && !isResolved) {
            return (
                <Link
                    to={`/amend-payment-details/${id}`}
                    className="text-primary tw-underline tw-font-bold"
                >
                    <i className="fa fa-exclamation-circle tw-text-red-600 tw-pr-2" />
                    Update Payment Details
                </Link>
            );
        }

        if ((subscriptionPaymentStatus === "InvoicePaymentFailed"
                || subscriptionPaymentStatus === "InvoicePaymentActionRequired")
            && isResolved) {
            return "Awaiting Payment";
        }

        return subscriptionPaymentStatus === "InvoicePaid"
            ? formatDateHelper.format(subscriptionPaymentDate, "DD/MM/YYYY")
            : pascalCaseToTitleCase(subscriptionPaymentStatus.replace("Invoice", ""));
    }

    state = {
        loading: true,
        orders: [],
        metaData: {},
        sortBy: "orderDate",
        sortAscending: false,
        dateFilter: 3,
        filters: [],
        sortModalOpen: false,
        filterModalOpen: false,
        includeSubscriptions: true,
        searchTerm: "",
        pageNumber: 0,
        pageLength: 10,
        totalPages: 0,
    };

    componentDidMount() {
        document.title = "BSC - Customer Orders";

        this.getOrdersFromApi();
    }

    getOrdersFromApi = () => {
        let url = `orders/customer?dateFilter=${this.state.dateFilter}`;
        url = this.state.searchTerm ? `${url}&searchTerm=${encodeURIComponent(this.state.searchTerm)}` : url;
        url = this.state.filters ? `${url}&statusFilter=${this.state.filters.join(",")}` : url;
        url += `&includeSubscriptions=${this.state.includeSubscriptions ? "true" : "false"}`;
        url += `&pageNumber=${this.state.pageNumber}`;
        url += `&pageLength=${this.state.pageLength}`;
        url += `&sortBy=${this.state.sortBy}`;
        url += `&sortAscending=${this.state.sortAscending}`;

        api.get(url)
            .then((response) => {
                this.setState({
                    ...response,
                    selected: response.orders.find(x => x.id === this.props.match.params.id),
                    totalPages: response.totalPages,
                    loading: false,
                }, this.updateFilters);
            })
            .catch((error) => {
                this.props.pushToError(error);
            });
    };

    setFilter = (value) => {
        this.setState(prevState => ({ ...prevState, dateFilter: value, pageNumber: 0 }), () => this.getOrdersFromApi());
    };

    toggleFilter = (value) => {
        if (this.state.filters.includes(value)) {
            this.setState(prevState => ({ ...prevState, filters: prevState.filters.filter(x => x !== value), pageNumber: 0 }), () => {
                this.getOrdersFromApi();
            });
        } else {
            this.setState(prevState => ({ ...prevState, filters: [...prevState.filters, value], pageNumber: 0 }), () => this.getOrdersFromApi());
        }
    };

    toggleRadioFilter = values => () => {
        this.setState(prevState => ({ ...prevState, filters: [...values], pageNumber: 0 }), () => this.getOrdersFromApi());
    };

    toggleSubscriptions = () => {
        this.setState(prevState => ({ ...prevState, includeSubscriptions: !prevState.includeSubscriptions, pageNumber: 0 }), () => this.getOrdersFromApi());
    };

    showSortModal = (shouldShow) => (
        this.setState(prevState => ({ ...prevState, sortModalOpen: shouldShow }))
    );

    showFilterModal = (shouldShow) => (
        this.setState(prevState => ({ ...prevState, filterModalOpen: shouldShow }))
    );

    sortOrders = (key) => {
        this.sort(key)();
    };

    sort = key => () => {
        this.setState((prevState) => {
            if (prevState.sortBy === key) {
                return { sortAscending: !prevState.sortAscending };
            }
            return {
                sortBy: key,
                sortAscending: true,
            };
        }, () => this.getOrdersFromApi());
    };

    onChangePage = (page) => {
        this.setState(prevState => ({ ...prevState, pageNumber: page }), () => this.getOrdersFromApi());
    };

    onSearchTerm = (searchTerm) => {
        this.setState(prevState => ({ ...prevState, searchTerm, pageNumber: 0 }), () => this.getOrdersFromApi());
    };

    onViewDetails = order => () => {
        this.setState(prevState => ({ ...prevState, selected: order }), () => this.props.pushToDetails(order.id));
    };

    onOrderStatusChanged = (orderId, status) => {
        this.setState(prevState => ({
            ...prevState,
            orders: prevState.orders.map((x) => {
                if (x.id === orderId) {
                    return {
                        ...x,
                        status,
                    };
                }
                return x;
            }),
        }));
    };

    getSortClassName = columnName => {
        if (columnName === this.state.sortBy) {
            return `fa fa-sort-${this.state.sortAscending ? "up" : "down"} pl-1`;
        }
        return "fa fa-sort pl-1";
    };

    updateFilters() {
        const filters = this.state.metaData.statusFilters;

        const allFilters = Object.keys(filters)
            .map(x => filters[x]);

        if (this.state.filters.length === 0) {
            this.setState(prevState => ({ ...prevState, filters: allFilters }));
        }
    }

    renderHeader = () => (
        <Row className="d-none d-md-flex border-bottom align-items-center">
            <Col md="2">
                <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("supplierName")}>
                    Sold By <i className={this.getSortClassName("supplierName")} />
                </button>
            </Col>
            <Col md="2">
                <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("serviceName")}>
                    Item <i className={this.getSortClassName("serviceName")} />
                </button>
            </Col>
            <Col md="1">
                <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderDate")}>
                    Order Date <i className={this.getSortClassName("orderDate")} />
                </button>
            </Col>
            <Col md="2">
                <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("orderReference")}>
                    Order Number <i className={this.getSortClassName("orderReference")} />
                </button>
            </Col>
            <Col md="2">
                <button type="button" className="bg-transparent p-0 border-0" onClick={this.sort("subscriptionPaymentStatus")}>
                    Subscription Payment Status <i className={this.getSortClassName("subscriptionPaymentStatus")} />
                </button>
            </Col>
            <Col md="1">
                <button type="button" className="bg-transparent p-0 border-0 pl-2" onClick={this.sort("status", !this.state)}>
                    Status <i className={this.getSortClassName("status")} />
                </button>
            </Col>
            <Col md="2" className="text-right">
                Actions
            </Col>
        </Row>
    );

    renderOrders = () => (
        <>
            {this.state.orders?.map(x => (
                <Row
                    data-testid={x.id}
                    key={x.id}
                    className={classnames(styles.orderItem, "py-2 mb-3 mb-md-0 border align-items-center cursor-pointer")}
                    onClick={this.onViewDetails(x)}
                >
                    <Col md="2" className="pt-2 pt-md-0">
                        <h5 className="d-md-none">
                            <Link to={`/marketplace/supplier-directory/${x.supplierId}`} className="text-primary">{x.supplierName}</Link>
                        </h5>
                        <Link to={`/marketplace/supplier-directory/${x.supplierId}`} className="d-none d-md-block">{x.supplierName}</Link>
                    </Col>
                    <Col md="2">
                        <h4 className="d-md-none"><strong>{x.serviceName}</strong></h4>
                        <span className="d-none d-md-block">{x.serviceName}</span>
                    </Col>
                    <Col md="1">
                        <span className={classnames(styles.orderDateTime, "mr-1 d-md-none")}>Ordered On:</span>
                        <span className={classnames(styles.orderDateTime, "d-md-block mr-1")}>{formatDateHelper.format(x.orderDate, "DD/MM/YYYY")}</span>
                        <span className={styles.orderTime}>{formatDateHelper.format(x.orderDate, "HH:mm:ss")}</span>
                    </Col>
                    <Col md="2" className={classnames(styles.orderReference, "overflow-hidden")}>
                        <span className="mr-1 d-md-none">Order #</span>
                        {x.orderReference}
                    </Col>
                    <Col md="2">
                        <span className={classnames(styles.orderDateTime, "mr-1 d-md-none")}>Subscription Payment Status:</span>
                        <span className={styles.orderDateTime}>
                            {CustomerOrdersPage.formatSubscriptionPaymentStatus(x.subscriptionPaymentStatus, x.subscriptionPaymentDate, x.id, x.paymentMethodResolved)}
                        </span>
                        {
                            x.canAmendSubscription && (
                                <button type="button" className="bg-transparent border-0 p-0 h4 w-25">
                                    <Link to={`/amend-payment-details/${x.id}`}>
                                        <i className="fas fa-edit text-primary" />
                                    </Link>
                                </button>)
                        }
                    </Col>
                    <Col md="1" className="mb-2 mb-md-0">
                        <OrderStatusIcon status={x.status} />
                    </Col>
                    <Col className="d-flex align-items-center d-md-none">
                        <strong><h3 className="mb-0">£{x.amount}</h3></strong>
                    </Col>
                    <Col md="2" className="d-flex justify-content-end">
                        <Link to={`/marketplace/supplier-directory/${x.supplierId}`} className="btn btn-primary">Buy Again</Link>
                    </Col>

                </Row>
            ))}
            <Paging
                className="md:tw-pt-2"
                onChange={this.onChangePage}
                activePage={this.state.pageNumber}
                pageCount={this.state.totalPages}
            />
        </>
    );

    renderNoOrders = () => (
        <Col xs="12" className="d-flex flex-column pb-3 mb-3">
            <div className="text-center mb-3 pb-3">
                <Media src={`${window.cdnUrl}misc/calvin-empty-box.png`} alt="No result found" className={classnames(styles.noResultsImage, "mx-auto")} />
                <h5> Uh-oh, looks like you&#39;ve not made any purchases from our suppliers yet.</h5>
                <h5 className="mb-3"> Come on have a look at our marketplace!</h5>
                <Button color="primary" tag={Link} to="/marketplace">Take me to the marketplace!</Button>
            </div>
        </Col>
    );

    render() {
        if (this.state.loading) {
            return (
                <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                    <Spinner className="align-self-center" />
                </div>
            );
        }
        return (
            <>
                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border pb-3">
                        <div className="p-3 mt-2">
                            <h3 className="font-weight-bold">Manage Orders</h3>
                        </div>
                        <Notifications
                            openOrders={this.props.notifications.openOrders}
                            placedOrders={this.props.notifications.placedOrders}
                            unreadMessages={this.props.notifications.unreadMessages}
                            ordersToReview={this.props.notifications.ordersToReview}
                            isSupplier={this.props.isSupplier}
                            error={this.state.error}
                            isCollapsed
                        />
                    </Col>
                </Row>
                <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                    <Col className="bg-white box-shadow border">
                        <OrderSearch
                            toggleStatusFilter={this.toggleFilter}
                            toggleDateFilter={this.setFilter}
                            onSearch={this.onSearchTerm}
                            currentStatusFilters={this.state.filters}
                            currentDateFilter={this.state.dateFilter}
                            dateFilters={this.state.metaData.dateFilters}
                            statusFilters={this.state.metaData.statusFilters}
                            toggleStatusRadio={this.toggleRadioFilter}
                            showSortModal={this.showSortModal}
                            showFilterModal={this.showFilterModal}
                            toggleSubscriptions={this.toggleSubscriptions}
                            includeSubscriptions={this.state.includeSubscriptions}
                        />

                        <Col className="pb-4">
                            {
                                this.state.orders.length === 0
                                    ? this.renderNoOrders()
                                    : this.renderHeader()
                            }
                            {
                                this.renderOrders()
                            }
                        </Col>
                    </Col>
                </Row>

                <FilterOrdersModal
                    isOpen={this.state.filterModalOpen}
                    onClose={() => this.showFilterModal(false)}
                    toggleDateFilter={this.setFilter}
                    dateFilters={this.state.metaData.dateFilters}
                    currentDateFilter={this.state.dateFilter}
                    toggleStatusFilter={this.toggleFilter}
                    statusFilters={this.state.metaData.statusFilters}
                    currentStatusFilters={this.state.filters}
                />

                <SortOrdersModal
                    isOpen={this.state.sortModalOpen}
                    onClose={() => this.showSortModal(false)}
                    onSort={this.sortOrders}
                    selectedKey={this.state.sortBy}
                    ascending={this.state.sortAscending}
                />

                <Route
                    path="/customer-orders/:id/details"
                    render={() => <CustomerOrderDetailsModal id={this.state.selected.id} />}
                />

                <Route
                    path="/customer-orders/:id/details/cancellation"
                    render={() => (
                        <RequestCancellationModal
                            id={this.state.selected.id}
                            supplierId={this.state.selected.supplierId}
                            isSubscription={this.state.selected.isSubscription}
                            onOrderStatusChanged={this.onOrderStatusChanged}
                        />)}
                />
                <Footer />
            </>
        );
    }
}

CustomerOrdersPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    pushToError: PropTypes.func.isRequired,
    pushToDetails: PropTypes.func.isRequired,
    isSupplier: PropTypes.bool.isRequired,
    notifications: PropTypes.shape({
        openOrders: PropTypes.number,
        placedOrders: PropTypes.number,
        unreadMessages: PropTypes.number,
        ordersToReview: PropTypes.number,
    }),
};

const mapStateToProps = state => ({
    isSupplier: state.user.isSupplier,
    notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
    pushToError: error => dispatch(push({ pathname: "/error",
        state: {
            error,
            referrer: window.location.href,
        } })),
    pushToDetails: id => dispatch(push(`/customer-orders/${id}/details`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrdersPage);
