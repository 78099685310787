import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";

import { AddNewSupplier } from "./AddNewSupplier";
import { DataTable } from "./DataTable";
import { SearchBox } from "./SearchBox";

import CloseButton from "@/Components/CloseButton";
import { useAPI } from "@/Apis/useAPI";
import { getAuthorisedSuppliers,
    exportProfiles,
    updateFlexiblePayments,
    AuthorisedSupplierType,
    deactivateSuppliers,
    updateHideDirectoryListing } from "@/Apis/SupplierAdministration";
import { LookupUsersQueryResponse, User, UserManagementUrls } from "@/Apis/UsersManagement";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import actions from "@/Store/Global/actions";

const SupplierAdministrationPage = () => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState<User[]>([]);
    const [suppliers, setSuppliers] = useState<AuthorisedSupplierType[]>([]);
    const [loadUsersTrigger, setLoadUsersTrigger] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredSuppliers, setFilteredSuppliers] = useState<AuthorisedSupplierType[]>([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState<AuthorisedSupplierType[]>([]);
    const [deactivateModalOpen, setdeactivateModalOpen] = useState<boolean>(false);
    const toastMessageContext = useToastMessageContext();

    const { get } = useAPI({ handle500WithRedirect: true });

    const toggleDeactivateModal = (): void => {
        setdeactivateModalOpen(prevState => !prevState);
    };

    const onSuppliersUpdated = (): void => {
        setLoadUsersTrigger(prevState => !prevState);
    };

    const onSupplierSelectionChanged = (supplier: AuthorisedSupplierType, selected: boolean): void => {
        setSelectedSuppliers(selected
            ? [...selectedSuppliers, supplier]
            : selectedSuppliers.filter(x => x !== supplier));
    };

    const onSelectAllChanged = (selected: boolean): void => {
        setSelectedSuppliers(selected ? suppliers : []);
    };

    const onSearchTermChanged = (term: string): void => {
        setSearchTerm(term);
    };

    const onFlexiblePaymentsChanged = (supplier: AuthorisedSupplierType, isAuthorised: boolean): void => {
        updateFlexiblePayments(supplier.id, isAuthorised)
            .then((response) => {
                const newSuppliers = suppliers.map(x => {
                    if (x.id === supplier.id) {
                        return { ...x, allowFlexiblePayments: response.allowFlexiblePayments };
                    }
                    return x;
                });

                setSuppliers(newSuppliers);
            });
    };

    const onHideDirectoryListingChanged = (supplier: AuthorisedSupplierType, isHidden: boolean): void => {
        updateHideDirectoryListing(supplier.id, isHidden)
            .then((response) => {
                const newSuppliers = suppliers.map(x => {
                    if (x.id === supplier.id) {
                        return { ...x, hideDirectoryListing: response.hideDirectoryListing };
                    }
                    return x;
                });

                setSuppliers(newSuppliers);
            });
    };

    const onExportProfile = (): void => {
        exportProfiles(selectedSuppliers.map(x => x.id))
            .then(() => {
                toastMessageContext.setSuccessMessage("Starting download...", true);
            });
    };

    const onDeactivateProfile = (): void => {
        deactivateSuppliers(selectedSuppliers.map(x => x.id))
            .then(() => {
                dispatch(actions.setToastMessage(true, "Suppliers Successfully Deactivated"));
                onSuppliersUpdated();
                toggleDeactivateModal();
            });
    };

    useEffect(() => {
        getAuthorisedSuppliers()
            .then((response) => {
                setSuppliers(response.authorisedSuppliers);
            });
        get<LookupUsersQueryResponse>(UserManagementUrls.lookupUsers)
            .then((response) => {
                setUsers(response.users);
            });
    }, [loadUsersTrigger]);

    useEffect(() => {
        setFilteredSuppliers(suppliers.filter(x => !searchTerm || (x.name || `[${x.email}]`).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));
    }, [suppliers, searchTerm]);

    return (
        <>
            <div className="box-shadow bg-white m-3 mb-5 mx-md-1 p-4">
                <div className="m-3">
                    <h4 className="font-weight-bold mb-4" data-testid="supplierAdministration-header">Supplier Administration</h4>
                    <div className="d-flex justify-content-between">
                        <div>
                            <AddNewSupplier users={users} onSupplierAdded={onSuppliersUpdated} />
                            <Button
                                color="primary"
                                className="ml-2"
                                disabled={selectedSuppliers.length === 0}
                                onClick={onExportProfile}
                            >
                                Export Selected
                            </Button>
                            <Button
                                color="danger"
                                className="ml-2"
                                disabled={selectedSuppliers.length === 0}
                                onClick={toggleDeactivateModal}
                            >
                                Deactivate Selected
                            </Button>
                        </div>
                        <SearchBox term={searchTerm} onChange={onSearchTermChanged} />
                    </div>
                </div>
                <div className="m-3">
                    <DataTable
                        suppliers={filteredSuppliers}
                        selectedSuppliers={selectedSuppliers}
                        onFlexiblePaymentsChanged={onFlexiblePaymentsChanged}
                        onSupplierSelectionChanged={onSupplierSelectionChanged}
                        onSelectAllChanged={onSelectAllChanged}
                        onHideDirectoryListingChanged={onHideDirectoryListingChanged}
                    />
                </div>
            </div>

            <Modal isOpen={deactivateModalOpen} toggle={toggleDeactivateModal} centered size="sm" data-testid="deactivateSuppliers-modal">
                <CloseButton onClick={toggleDeactivateModal} />
                <ModalBody className="p-5 d-flex flex-column">
                    <i className="fas fa-exclamation-triangle text-warning display-4 align-self-center" />
                    <p className="my-4">
                        Are you sure you want to deactivate the selected suppliers?
                    </p>

                    <div className="d-flex flex-row justify-content-center">
                        <Button color="dark" className="mr-1" onClick={toggleDeactivateModal} data-testid="deactivateSuppliers-cancel">
                            Cancel
                        </Button>
                        <Button color="danger" className="ml-1" onClick={onDeactivateProfile} data-testid="deactivateSuppliers-deactivate">
                            Deactivate
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export { SupplierAdministrationPage };
