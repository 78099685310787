import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const SideBarButton = ({ icon, text, folder, onClick, disabled }) => {
    const onButtonClick = () => onClick(text);
    return (
        <button
            type="button"
            data-testid={text}
            className={classnames(
                styles.container,
                folder.toLowerCase() === text.toLowerCase() && styles.active,
                "p-0 py-2 py-md-3 text-nowrap w-100",
            )}
            disabled={disabled || folder === text}
            onClick={onButtonClick}
        >
            {icon}
            {text}
        </button>
    );
};

SideBarButton.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

SideBarButton.defaultProps = {
    disabled: false,
};

export { SideBarButton };
