import api from "@/Utils/api";

export interface Sale {
    saleId: string;
    orderId: string;
    orderNumber: string
    orderDate: string
    orderType: string
    orderItem: {
        id: string,
        name: string,
        description: string,
    },
    supplier: {
        id: string,
        name: string,
        reference: string,
    },
    referrer: {
        name: string,
        reference: string,
    },
    orderStatus: OrderStatus,
    paymentStatus: SalesPaymentStatus,
    cardholderStatus: Cardholder,
    notes: string,
    price: number,
    isSubscription: boolean,
    paymentDate: string,
}

export type Cardholder = "NA" | "Bronze" | "Silver" | "Gold";
export type OrderStatus = "neworder" | "requestedcancellation" | "inprogress" | "complete" | "reviewed" | "cancelled";

export interface SalesRequest {
    pageNumber: number,
    date: SalesDate,
    paymentStatuses: SalesPaymentStatus[],
    orderStatus: SalesOrderStatus
    cardholder: CardholderStatus,
    referral: ReferralStatus,
    searchTerm: string,
    sortOrder: SortOrder,
    orderTypes: OrderType[]
}

export enum SortOrder {
    DateAsc,
    DateDesc,
}

export enum SalesDate {
    All = 0,
    Last7Days,
    Last14Days,
    Last30Days,
    Last90Days,
}

export enum SalesOrderStatus {
    Any = 0,
    Completed
}

export enum SalesPaymentStatus {
    Unpaid = 0,
    OnHold,
    Paid,
    Cancelled,
    Refunded
}

export enum CardholderStatus {
    All = 0,
    WithCard,
    WithoutCard
}

export enum ReferralStatus {
    All = 0,
    Yes,
    No
}

export enum OrderType {
    Product,
    Upsell,
    BespokeQuote,
    Package,
    FormationsProduct,
    CSL,
    Quotation,
    Invoice,
    OffsitePayment,
    BankAccountProduct
}

const getSales = (request: SalesRequest) => api.post("sales", request) as Promise<{ results: Sale[], pages: number }>;

interface UpdatePaymentStatus {
    salesId: string;
    paymentStatus: SalesPaymentStatus;
}

const updatePaymentStatus = (request: UpdatePaymentStatus) => api.post("sales/paymentStatus", request) as Promise<{}>;

interface UpdateNotes {
    salesId: string;
    notes: string;
}

const updateNotes = (request: UpdateNotes) => api.post("sales/notes", request) as Promise<{}>;

export {
    getSales,
    updatePaymentStatus,
    updateNotes,
};
