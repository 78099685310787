import React, { useState } from "react";
import Select from "react-select";

import { ColumnType, VariationType } from "../ColumnTypes";

import { selectTheme } from "./selectTheme";

import Textbox from "@/Components/Textbox";
import { AddressLookUp } from "@/Components/AddressLookup";
import { ConfigurableSelect } from "@/Components/ConfigurableSelect";
import { ProductQuantity } from "@/Components/ProductQuantity";
import { ConfigurableUpload } from "@/Components/ConfigurableUpload";

interface Props {
    serviceId: string;
    columns: Array<ColumnType>;
    columnId: string;
    rowId: string;

    onComponentChange(rowId: string, columnId: string);

    isEditor: boolean;
    readonly?: boolean;
}

const ComponentBuilder = (
    {
        serviceId,
        columns,
        columnId,
        rowId,
        onComponentChange,
        isEditor,
        readonly = false,
    }
        : Props,
) => {
    const [configurableSelectIsOpen, setConfigurableSelectIsOpen] = useState(false);
    const componentDictionary = {
        Textbox,
        AddressLookUp,
        ConfigurableSelect,
        ProductQuantity,
        ConfigurableUpload,
    };

    const column = columns.find(x => x.variationId === columnId) as ColumnType;
    const row = column.currentVariations.find(x => x.rowId === rowId) as VariationType;

    const Component = componentDictionary[column.component as string];

    if (!isEditor) {
        if (Component === AddressLookUp) {
            return (<AddressLookUp address={row?.value?.address} hideButtons heading="" displaySingleLine />);
        }

        if (Component === ConfigurableSelect && !readonly) {
            return (<Select
                isClearable={false}
                isSearchable={false}
                options={row?.options?.map(x => ({ label: x, value: x }))}
                styles={selectTheme}
                placeholder={`Select ${column.name}`}
                onChange={(option) => onComponentChange(rowId, columnId)({ target: { value: option?.label } })}
                value={{ label: row?.value ?? "", value: row?.value ?? "" }}
            />);
        }

        if (Component === ConfigurableUpload) {
            return (<Component documents={row?.value ?? []} readonly />);
        }

        if (Component === ProductQuantity && !readonly) {
            const quantityVariation = column.currentVariations.find(x => x.rowId === rowId);
            return (<Component value={quantityVariation?.value} onValueChanged={val => onComponentChange(rowId, columnId)({ target: { value: val } })} />);
        }

        return (<>{row?.value}</>);
    }

    let props: any;
    switch (Component) {
        case ConfigurableSelect: {
            props = {
                onValidate: () => {
                },
                value: row?.value ?? [],
                menuIsOpen: configurableSelectIsOpen,
                setMenuIsOpen: setConfigurableSelectIsOpen,
                onChange: (options) => onComponentChange(rowId, columnId)({ target: { value: options?.map(x => x.label) ?? [] } }),
            };
            break;
        }
        case Textbox: {
            props = {
                value: row?.value ?? "",
                onChange: onComponentChange(rowId, columnId),
                className: "mb-0 w-100",
                isRequired: true,
                validateOnMount: true,
                formFeedbackTop: true,
            };
            break;
        }
        case AddressLookUp: {
            props = {
                heading: "",
                onSave: (address) => onComponentChange(rowId, columnId)({ target: { value: { address } } }),
                address: row?.value?.address,
                displaySingleLine: true,
            };
            break;
        }
        case ProductQuantity: {
            props = {
                disabled: true,
                value: 1,
                classNames: "mt-4",
                onValueChanged: () => {
                },
            };
            break;
        }
        case ConfigurableUpload: {
            props = {
                rowId,
                productId: serviceId,
                documents: row?.value ?? [],
                onSuccess: (documents) => onComponentChange(rowId, columnId)({ target: { value: documents } }),
            };
            break;
        }
        default: {
            props = {};
        }
    }

    return (<Component {...props} />);
};

export { ComponentBuilder };
