import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Checkbox from "../Checkbox";

const TermsAndConditions = ({ signup, cdnUrl, checked, businessName, onToggle, documentLocation, privacyDocumentLocation, showPrivacy, thirdPartyLinks }) => {
    const business = businessName || "the Business Support Club";

    return (
        <div>
            <div className={classnames("p-3 w-100", signup ? "" : "border rounded mt-4")}>
                <Checkbox
                    testId="acceptTermsCheckbox"
                    id="terms"
                    checked={checked}
                    onChange={onToggle}
                    className="mr-0 d-inline-block"
                />
                <strong>
                    I agree to the&nbsp;
                    <a
                        href={thirdPartyLinks ? documentLocation : `${cdnUrl || window.cdnUrl}${documentLocation}`}
                        rel="noopener noreferrer"
                        className="text-primary"
                        target="_blank"
                    >
                        Terms and Conditions
                    </a>
                    {showPrivacy && (
                        <span>
                            &nbsp;and&nbsp;
                            <a
                                href={privacyDocumentLocation}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                            >
                                Privacy Policy
                            </a>
                        </span>
                    )}
                    &nbsp;of {business}.
                </strong>
            </div>
        </div>
    );
};

TermsAndConditions.propTypes = {
    businessName: PropTypes.string,
    documentLocation: PropTypes.string,
    privacyDocumentLocation: PropTypes.string,
    showPrivacy: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    cdnUrl: PropTypes.string,
    signup: PropTypes.bool,
    thirdPartyLinks: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
    signup: false,
    thirdPartyLinks: false,
};

export { TermsAndConditions };
