/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import { Route, Switch } from "react-router";
// eslint-disable-next-line import/order
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

// import bootstrap overrides and bootstrap itself - Do not move these
import "Styles/tailwind.css";
import "Styles/global.scss";
import "Styles/_custom.scss";

import { AppRouter } from "./AppRouter";
import { PartnersAppRouter } from "./PartnersAppRouter";

// eslint-disable-next-line import/extensions
import SpinnerGif from "@/Assets/Images/loading.gif";
// eslint-disable-next-line import/extensions
import { addressLookupConfiguration } from "@/Components/AddressLookup/address.configuration";
import { RaygunLogging } from "@/Components/RaygunLogging";
import ErrorWrapper from "@/Wrappers/ErrorWrapper";
import { ConfigurationProvider } from "@/Context/ConfigurationContext";
import { LoggingProvider } from "@/Context/LoggingContext";
import { BasketContextProvider } from "@/Context/BasketContext";
import { ToastMessageProvider } from "@/Context/ToastMessageContext";
import { SuppliersContextProvider } from "@/Context/SuppliersContext";
import { FilterContextProvider } from "@/Context/FilterContext";
import { FeaturesProps } from "@/Apis/Configuration";
import { createBrowserId, getBrowserId, setBrowserId } from "@/Utils/localStorage";
import actions from "@/Store/Global/actions";
import configureStore from "@/Store/configureStore";

const history = createBrowserHistory();

addressLookupConfiguration.loadingIcon = SpinnerGif;
addressLookupConfiguration.createContext = useDispatch;
addressLookupConfiguration.dispatchError = (error, dispatch) => dispatch(actions.setErrorToastMessage(true, error.message));

declare global {
    interface Window {
        cdnUrl: string;
        bscFeatures: FeaturesProps
    }
}

const App = () => {
    const [gtmParams, setGtmParams] = useState<any>({});

    useEffect(() => {
        setGtmParams({ id: `${process.env.REACT_APP_BSC_GTM_KEY}` });

        const query = queryString.parse(window.location.search);
        if (query.browserId) {
            setBrowserId(query.browserId);
        } else {
            const browserId = getBrowserId();
            if (!browserId) {
                createBrowserId();
            }
        }
    }, []);

    return (
        <>
            <GTMProvider state={gtmParams}>
                <Provider store={configureStore(history)}>
                    <ConnectedRouter history={history}>
                        <ErrorWrapper>
                            <ConfigurationProvider>
                                <ToastMessageProvider>
                                    <BasketContextProvider>
                                        <FilterContextProvider>
                                            <SuppliersContextProvider>
                                                <LoggingProvider>
                                                    <RaygunLogging />
                                                    <Switch>
                                                        <Route
                                                            path="/partners"
                                                            render={() => (<PartnersAppRouter />)}
                                                        />
                                                        <Route render={() => <AppRouter />} />
                                                    </Switch>
                                                </LoggingProvider>
                                            </SuppliersContextProvider>
                                        </FilterContextProvider>
                                    </BasketContextProvider>
                                </ToastMessageProvider>
                            </ConfigurationProvider>
                        </ErrorWrapper>
                    </ConnectedRouter>
                </Provider>
            </GTMProvider>
        </>
    );
};

export default App;
