import { Button, Modal, ModalBody } from "reactstrap";
import React from "react";

import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";

const SupplierSignUpSuccessModal = () => {
    const {
        successfulSignUp,
        formState,
        onSuccessfulSignUp,
    } = useFlexPlanSupplierSignUpContext();

    return (
        <Modal
            isOpen={successfulSignUp}
            centered
            size="md"
            className="tw-p-10"
        >
            <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8">
                <div className="tw-p-3">
                    <div className="tw-py-3 tw-space-x-3 tw-justify-center tw-flex tw-flex-row tw-items-center">
                        <i className="fa fa-check-circle fa-2x tw-text-flexplan-secondary" />
                        <span className="tw-text-base">{formState?.businessName} successfully registered to FlexPlan.</span>
                    </div>
                    <div className="tw-flex tw-space-x-3 tw-justify-center tw-mt-2">
                        <Button
                            color="primary"
                            className="tw-text-base tw-w-24"
                            onClick={onSuccessfulSignUp}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export { SupplierSignUpSuccessModal };
