import { Text, View } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

import { toCurrency } from "@/Utils/formatStringHelper";

const Total = ({ itemName, itemTotal, isGrandTotal }) => (
    <View style={{ flexDirection: "row", color: "#333", padding: "20 20 0 20", fontFamily: isGrandTotal ? "LatoBold" : "Lato" }}>
        <View style={{ flexDirection: "column", width: "50%", paddingBottom: 14 }} />
        <View style={{ flexDirection: "column", width: "25%", borderBottom: !isGrandTotal ? "1 solid #e0e0e0" : "0", paddingBottom: 14 }}>
            <Text>{itemName}</Text>
        </View>
        <View style={{ flexDirection: "column", width: "25%", borderBottom: !isGrandTotal ? "1 solid #e0e0e0" : "0", paddingBottom: 14 }}>
            <Text style={{ marginRight: 20, fontSize: isGrandTotal ? 16 : 10, color: isGrandTotal ? "#309fd0" : "#000" }}>
                £{toCurrency(itemTotal)}
            </Text>
        </View>
    </View>
);

Total.propTypes = {
    itemName: PropTypes.string.isRequired,
    itemTotal: PropTypes.number.isRequired,
    isGrandTotal: PropTypes.bool,
};

export { Total };
