import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody } from "reactstrap";

import TextArea from "@/Components/TextArea";

const SubmitQuestionModal = (props) => {
    const [question, setQuestion] = useState("");

    const setQuestionValue = (e) => { setQuestion(e.target.value); };
    const confirm = () => { props.onConfirm(question); };

    return (
        <Modal size="lg" centered isOpen>
            <ModalBody className="m-3">
                <h3 className="font-weight-bold mb-3">Submit a question</h3>
                <TextArea
                    placeholder="Enter your question here"
                    value={question}
                    maxCharacters={250}
                    onChange={setQuestionValue}
                />
                <div className="d-flex justify-content-end mt-3">
                    <Button color="secondary" className="mr-2" onClick={props.onClose}>Cancel</Button>
                    <Button color="primary" disabled={!question} onClick={confirm}>Submit</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

SubmitQuestionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export { SubmitQuestionModal };
