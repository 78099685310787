import React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";

import { formatStatus } from "@/Utils/orderStatusHelper";

export const orderStatuses = ["NewOrder", "InProgress", "Complete", "Cancelled", "RequestedCancellation"];

const OrderStatusDropDown = ({
    onSubmit,
    onStatusChange,
    selectedStatus,
    isUpdating,
}) => {
    const renderButtonIcon = isUpdating
        ? <i className="fas fa-circle-notch fa-spin text-white d-flex" />
        : <i className="fa fa-check text-white d-flex" />;

    return (
        <FormGroup>
            <Label for="orderStatusDropDown">Change Status</Label>
            <div className="d-flex">
                <div className="mr-2">
                    <Input
                        id="orderStatusDropDown"
                        type="select"
                        value={selectedStatus}
                        onChange={onStatusChange}
                    >
                        {orderStatuses
                            .map(status => <option key={status} value={status}>{formatStatus(status)}</option>)}
                    </Input>
                </div>

                <Button onClick={onSubmit} color="success" disabled={isUpdating} className="d-flex align-items-center">{renderButtonIcon}</Button>
            </div>
        </FormGroup>
    );
};

OrderStatusDropDown.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool,
};

OrderStatusDropDown.defaultProps = {
    isUpdating: false,
};

export { OrderStatusDropDown };
