export interface ExternalSignInRequest {
    provider?: string,
    providerKey: string, // The Id for the user received from the external provider
    firstName?: string,
    surname?: string,
    email: string,
    overrideLinkedProviderCheck?: boolean,
}

export enum GoogleSignInType {
    Login = "Log in",
    SignUp = "Sign up",
}
