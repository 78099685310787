import { convertArrayToQueryString, convertObjectToQueryString } from "@/FlexPlan/Utils/url/urlHelper";
import { ReferenceDataAttributeType } from "@/FlexPlan/Types/Ndis/ReferenceData/attributes";

export const FlexPlanUrls = {
    planManager: {
        register: "flexplan/planmanager/register",
        get: "flexplan/planmanager",
    },
    participants: {
        base: "flexplan/participant",
        getSingle: (id: string) => `flexplan/participant/${id}`,
        register: "flexplan/participant/register",
        search: (searchFields: object) => `flexplan/participant${convertObjectToQueryString(searchFields)}`,
        statementsEmails: {
            base: (participantId: string) => `flexplan/participant/${participantId}/statement-email`,
        },
    },
    statements: {
        getStatements: "flexplan/statement",
        send: "flexplan/statement/send",
        download: (fields: object) => `flexplan/statement/download${convertObjectToQueryString(fields)}`,
    },
    account: {
        updateSecurity: "flexplan/account/security",
    },
    suppliers: {
        register: "flexplan/supplier/register",
        supplierOrders: {
            base: "flexplan/order",
            summary: "flexplan/order/summary",
        },
        search: (searchFields: object) => `flexplan/supplier${convertObjectToQueryString(searchFields)}`,
        getSingle: (id: string) => `flexplan/supplier/${id}`,
        update: "flexplan/supplier",
        base: "flexplan/supplier",
    },
    notes: {
        get: (fields: object) => `flexplan/note${convertObjectToQueryString(fields)}`,
        base: "flexplan/note",
    },
    referenceData: {
        get: (attribute: ReferenceDataAttributeType) => `flexplan/reference-data/${attribute}`,
    },
    serviceBookings: {
        base: "flexplan/service-booking",
        maxAmountForCategory: "flexplan/service-booking/max-amount-for-category",
        get: (planId: string) => `flexplan/service-booking/${planId}`,
        getUpdate: (planId: string) => `flexplan/service-booking/update/${planId}`,
        getQuery: (fields: object) => `flexplan/service-booking${convertObjectToQueryString(fields)}`,
    },
    serviceBookingItems: {
        get: (serviceBookingId: number) => `flexplan/service-booking-item/${serviceBookingId}`,
    },
    orders: {
        createOrder: "flexplan/order/save-order",
        submitClaim: (orderItemId: string) => `flexplan/order/submit-claim/${orderItemId}`,
        updatePaymentStatus: (orderItemId: string) => `flexplan/order/update-payment-status/${orderItemId}`,
        base: "flexplan/order",
        get: (id: string) => `flexplan/order/${id}`,
        downloadDocument: (fileId: string) => `flexplan/order/download-document/${fileId}`, // Downloads related documents for orders
        payFile: {
            base: "flexplan/order/pay-file",
            validate: (orderIds: string[]) => `flexplan/order/validate-pay-file${convertArrayToQueryString(orderIds, "orderIds", "?")}`,
        },
    },
    audits: {
        get: (searchObj: object) => `flexplan/audit${convertObjectToQueryString(searchObj)}`,
    },
};
