import React, { useEffect, useState } from "react";
import { Alert, Button, Table } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

import Modal from "@/Modals/Modal";
import { toCurrencyWithCommas } from "@/FlexPlan/Utils/currency";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import sortingUtil from "@/Utils/sortingUtil";
import Spinner from "@/Components/Spinner";
import { PayFileRow } from "@/FlexPlan/Pages/Orders/OrderManagement/Types/PayFile";
import { AbaValidationModel, AbaValidationType } from "@/FlexPlan/Pages/Orders/OrderManagement/Types/PayFile/PayFileValidation";
import { ToolTip } from "@/Components/ToolTip";

interface Props {
    isOpen: boolean,
    rows: PayFileRow[],
    toggle: () => void,
    refreshOrders: () => void,
}

const CreatePayFileModal = ({
    isOpen,
    rows,
    toggle,
    refreshOrders,
}: Props) => {
    const {
        postFile,
        loading: downloading,
    } = useAPI({ handle500WithToastMessage: true });

    const {
        get,
        loading: validationProcessing,
    } = useAPI({ handle500WithToastMessage: true });

    const {
        setPopupErrorMessage,
        setSuccessMessage,
    } = useToastMessageContext();

    const [payFileRows, setPayFileRows] = useState<PayFileRow[]>();
    const [planManagerValidation, setPlanManagerValidation] = useState<AbaValidationType>();

    const onSubmit = () => {
        postFile(FlexPlanUrls.orders.payFile.base, {
            orderIds: rows.map(x => x.id),
        })
            .then(response => {
                downloadUseAPIFile("text/plain", response);
                setSuccessMessage("Pay File generated", true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                }
            })
            .finally(() => {
                toggle(); // Close the modal
                refreshOrders();
            });
    };

    useEffect(() => {
        if (isOpen && rows.length > 0) {
            // Validate the selected rows
            get<AbaValidationModel>(FlexPlanUrls.orders.payFile.validate(rows.map(x => x.id)))
                .then(response => {
                    setPlanManagerValidation(response.abaHeader);
                    const newRows = rows.map(payFileRow => {
                        const orderValidationErrors = response.orderRows
                            .find(x => x.orderId === payFileRow.id)
                            ?.errors;

                        return {
                            ...payFileRow,
                            isInvalid: orderValidationErrors && orderValidationErrors.length > 0,
                            validationErrors: orderValidationErrors,
                        };
                    });

                    setPayFileRows(newRows);
                })
                .catch(error => {
                    if (typeof error === "string") {
                        setPopupErrorMessage(error, true);
                    } else if (error.message) {
                        setPopupErrorMessage(error.message, true);
                    }
                });
        }
    }, [isOpen]);

    const planManagerValidationFailed = planManagerValidation && !planManagerValidation.isSuccess;
    const rowValidationFailed = payFileRows?.some(x => x.isInvalid);
    const disableExportButton = validationProcessing || planManagerValidationFailed || rowValidationFailed
        || downloading || rows.length === 0;

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            toggle={toggle}
            closeButton
        >
            <div className="tw-pt-5">
                {planManagerValidationFailed && (
                    <>
                        <h6 className="tw-text-red-600">
                            <i
                                className="fa fa-lg fa-exclamation-circle tw-text-red-600 tw-cursor-pointer"
                            />&nbsp;&nbsp;
                            Plan Manager Validation Failed
                        </h6>
                        <ul className="tw-px-3 tw-list-disc tw-pl-3.5">
                            {planManagerValidation?.errors?.map(error => (
                                <li key={error.message}>{error.message}</li>
                            ))}
                        </ul>
                    </>
                )}
                {rowValidationFailed && (
                    <Alert color="danger" className="!-tw-mt-1 tw-mb-2">
                        One or more rows has failed validation. Hover over the exclamation icon for more details.
                    </Alert>
                )}
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>VALIDATION</th>
                            <th style={{ width: "16.6" }}>ORDER NO.</th>
                            <th style={{ width: "25" }}>PARTICIPANT</th>
                            <th style={{ width: "16.6" }}>SUPPLIER</th>
                            <th style={{ width: "16.6" }}>SUPPLIER INVOICE NO.</th>
                            <th style={{ width: "16.6" }}>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length === 0 && (
                            <tr>
                                <td colSpan={6}>No orders have been selected.</td>
                            </tr>
                        )}
                        {validationProcessing && (
                            <tr>
                                <td colSpan={6}>
                                    <Spinner className="tw-mx-auto" />
                                </td>
                            </tr>
                        )}
                        {!validationProcessing && payFileRows && payFileRows.length > 0 && payFileRows
                            .sort((x, y) => sortingUtil.sort<PayFileRow>(x, y, "isInvalid", false))
                            .map(order => {
                                const classNameString = classnames(order.isInvalid && "tw-text-red-600");
                                return (
                                    <tr key={order.id}>
                                        <td>
                                            {order.isInvalid ? (
                                                <ToolTip
                                                    id={`error-exclamation-${order.id}`}
                                                    placement="left-start"
                                                    description={(
                                                        <ul className="tw-px-3 tw-list-disc">
                                                            {order.validationErrors?.map(error => (
                                                                <li key={error.message}>{error.message}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                >
                                                    <span className="tw-text-red-600 tw-font-semibold">
                                                        <i
                                                            className="fa fa-lg fa-exclamation-circle tw-text-red-600 tw-cursor-pointer"
                                                            id={`error-exclamation-${order.id}`}
                                                        />&nbsp;&nbsp;Failed
                                                    </span>
                                                </ToolTip>
                                            ) : (
                                                <span className="tw-text-green-600">
                                                    <i className="fa fa-check-circle fa-lg tw-text-green-600" />
                                                &nbsp;&nbsp;Passed
                                                </span>
                                            )}
                                        </td>
                                        <td>{order.orderNumber}</td>
                                        <td>
                                            <Link
                                                to={`/participants/${order.participantId}/personal-information`}
                                                target="_blank"
                                            >
                                                {order.participantName}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/suppliers/${order.supplierId}/public-information`}
                                                target="_blank"
                                            >
                                                {order.supplierBusinessName}
                                            </Link>
                                        </td>
                                        <td className={classNameString}>{order.invoiceNumber}</td>
                                        <td>{toCurrencyWithCommas(order.approvedTotal)}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
                <div className="tw-flex tw-justify-center tw-mt-8">
                    <Button
                        color="primary"
                        onClick={onSubmit}
                        disabled={disableExportButton}
                    >
                        Export Payment File
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export { CreatePayFileModal };
