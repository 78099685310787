/* eslint-disable import/extensions */
import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { v4 as uuid } from "uuid";

import CloseButton from "@/Components/CloseButton";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { AttachmentType, MessagingUrls, StartConversationRequest } from "@/Apis/Messaging";
import { DashboardTile } from "@/Pages/DashboardPage/DashboardTile";
import actions from "@/Store/Global/actions";
import { useAPI } from "@/Apis/useAPI";
import BecomeASupplierImg from "@/Assets/Images/Dashboard/Tiles/Become-A-Supplier.png";
import MobileBecomeASupplierImg from "@/Assets/Images/Dashboard/Tiles/Mobile/Become-A-Supplier.png";

interface Props {
    isSupplier: boolean,
    messagingRecipientId: string,
}

const BecomeASupplier = ({ isSupplier, messagingRecipientId }: Props) => {
    const dispatch = useDispatch();
    const pushToMessages = conversationId => dispatch(push(`/messages/sent/${conversationId}`));
    const { post } = useAPI({ handle500WithRedirect: true });

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = async () => {
        setModalOpen(prevState => !prevState);
    };

    const sendNotification = async () => {
        const conversationId = uuid();
        const messageBody = createEditorState("I'd like to find out how to become a BSC Supplier. Please get in touch here or via my registered e-mail.");

        const data = {
            conversationId,
            recipients: [messagingRecipientId],
            subject: "Become A Supplier",
            body: getContentStateAsJson(messageBody),
            attachments: [] as Array<AttachmentType>,
            isUrgent: false,
            relatedOrderId: null,
        } as StartConversationRequest;

        post(MessagingUrls.startConversation, data).then(() => {
            dispatch(actions.setToastMessage(true, "Message Successfully Sent"));
            pushToMessages(conversationId);
        });
    };

    return (
        <>
            <DashboardTile
                title={!isSupplier ? "Become a Supplier" : "My Minisite"}
                contentTitle={!isSupplier ? "BECOME A SUPPLIER" : "MY MINISITE"}
                contentDescription={!isSupplier
                    ? "You can build your own mini e-commerce site for FREE!"
                    : "Add products, services, bespoke quotations and change your site branding. All in one place!"}
                desktopImage={BecomeASupplierImg}
                mobileImage={MobileBecomeASupplierImg}
                link={!isSupplier ? "#" : "/mini-site"}
                onClick={!isSupplier ? toggleModal : undefined}
            />

            <Modal isOpen={modalOpen} toggle={toggleModal} centered size="sm" data-testid="becomeASupplier-modal">
                <CloseButton onClick={toggleModal} />
                <ModalBody className="p-5 d-flex flex-column">
                    <i className="fas fa-info-circle text-primary display-4 align-self-center" />
                    <p className="my-4">
                        Notify BSC Admin of your intent to become a supplier.
                    </p>

                    <div className="d-flex flex-row">
                        <Button color="dark" className="w-40 mr-1" onClick={toggleModal} data-testid="becomeASupplier-cancel">
                            Cancel
                        </Button>
                        <Button color="primary" className="w-60 ml-1" onClick={sendNotification} data-testid="becomeASupplier-send">
                            Send notification
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export { BecomeASupplier };
