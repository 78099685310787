/* eslint-disable import/extensions */
import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import classnames from "classnames";

import NoImageProduct from "@/Assets/Images/no-image-product.png";
import { convertJSONRichTextToHTML, convertJSONRichTextToPlainText } from "@/Utils/richtextEditorHelper";
import PostPurchaseReadMoreModal from "@/Modals/PostPurchaseReadMoreModal";
import UseDownloadPostPurchaseAttachments from "@/Hooks/PostPurchase/useDownloadPostPurchaseAttachments";
import { ProductType } from "@/Apis/Products/ProductType";
import { Image } from "@/Components/Image";
import { PricingPopover } from "@/Components/PricingPopover";
import PostPurchaseProductButtons from "@/Components/PostPurchase/PostPurchaseProduct/PostPurchaseProductButtons";
import { CircleButton } from "@/Components/CircleButton";
import Spinner from "@/Components/Spinner";

interface Props {
    product: ProductType,
    downloadAttachmentsOnFirstRender?: boolean,
    modalOpen?: boolean
}

const PostPurchaseProduct = ({ product, downloadAttachmentsOnFirstRender = false, modalOpen = false }: Props) => {
    const [readMoreModalOpen, setReadMoreModalOpen] = useState<boolean>(modalOpen);

    const toggleModal = () => setReadMoreModalOpen(prevState => !prevState);

    // If post purchase is enabled, we will try to display post purchase information (as plain text)
    const plainTextPurchaseInformation = product.isPostPurchaseEnabled
        ? convertJSONRichTextToPlainText(product.postPurchaseInformation) ?? convertJSONRichTextToPlainText(product.moreInformation) ?? product.description
        : convertJSONRichTextToPlainText(product.moreInformation) ?? product.description;

    const postPurchaseRichText = product.isPostPurchaseEnabled
        ? convertJSONRichTextToHTML(product.postPurchaseInformation) ?? convertJSONRichTextToHTML(product.moreInformation)
        : convertJSONRichTextToHTML(product.moreInformation);

    const { downloadAttachments, loading: loadingViewAttachments } = UseDownloadPostPurchaseAttachments({
        downloadOnFirstRender: downloadAttachmentsOnFirstRender,
        product,
    });

    return (
        <div
            className={classnames("tw-w-full tw-grid tw-grid-cols-3 lg:tw-grid-cols-8 tw-bg-white tw-border tw-rounded-lg lg:tw-h-56 "
            + "tw-overflow-y-hidden")}
        >
            <div className="tw-flex tw-col-span-1 lg:tw-col-span-2 tw-order-2 lg:tw-order-1 tw-justify-center">
                <Image
                    src={product.imageThumbnail ?? product.image ?? NoImageProduct}
                    className="tw-pr-4 tw-h-20 md:tw-h-36 lg:tw-h-56 tw-my-auto lg:tw-my-0 lg:tw-p-5"
                    alt="Product's thumnail"
                />
            </div>
            <div className="tw-col-span-2 lg:tw-col-span-6 tw-order-1 lg:tw-order-2 tw-flex tw-flex-col lg:tw-h-56 tw-p-4 lg:tw-py-5 lg:tw-pr-5 lg:tw-pl-0">
                <div className="tw-font-semibold tw-text-lg">{product.name}</div>
                <div>
                    <span className="tw-text-brand-primary tw-text-sm">
                        {product.supplierName}
                    </span> • <span className="tw-text-sm">{product.categoryName}</span>
                </div>
                <PricingPopover
                    productId={product.id}
                    initialChargeWithVatIfApplicable={product.initialChargeWithVatIfApplicable}
                    initialCharge={product.initialCharge}
                    paymentFrequency={product.paymentFrequency ?? "N/A"}
                    postageCharge={product.postageCharge}
                    minimumQuantity={product.quantityOrdered}
                    isVatRequired={product.isVatRequired}
                    componentName="tile"
                    hasNAProducts={product.hasNAProducts}
                    isOffsitePayment={product.isOffsitePayment}
                    tileDisplay
                    isDelayedPayment={product.isDelayedPayment}
                    delayedPaymentFor={product.delayedPaymentFor}
                    delayedPaymentPeriod={product.delayedPaymentPeriod}
                    discountRate={product.discountRate}
                />
                <div className="tw-relative">
                    <div className="tw-line-clamp-2 tw-text-sm">
                        {parse(plainTextPurchaseInformation?.replace(/\n/g, "<br>") ?? "")}
                    </div>
                    <button
                        type="button"
                        className="tw-relative tw-font-semibold tw-underline tw-text-brand-primary tw-bg-white tw-w-full tw-text-left"
                        onClick={toggleModal}
                    >
                        <div className="tw-text-sm">Read More</div>
                    </button>
                </div>
                <div
                    className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between !tw-mt-auto tw-pb-1 lg:tw-pb-4 tw-space-y-2 lg:tw-space-y-0"
                >
                    <div className="tw-hidden lg:tw-block tw-w-full">
                        <PostPurchaseProductButtons
                            product={product}
                            className="tw-flex tw-space-y-2 lg:tw-space-y-0 lg:tw-space-x-3 tw-flex-grow tw-flex-col tw-m-auto lg:tw-flex-row"
                            onViewAttachments={downloadAttachments}
                            viewAttachmentsLoading={loadingViewAttachments}
                        />
                    </div>
                    <p className={classnames("tw-w-full tw-text-brand-primary tw-text-sm lg:!tw-mt-auto "
                        + "lg:tw-text-right lg:tw-pl-2 tw-whitespace-nowrap")}
                    >
                        Order Ref. <span className="tw-text-base tw-font-semibold">{product.orderReference}</span>
                    </p>
                </div>
            </div>
            <div className="tw-border-t-2 tw-w-full tw-flex tw-flex-row tw-justify-between tw-col-span-3 tw-block lg:tw-hidden tw-order-3 tw-px-5 tw-py-3">
                <Link to={`/product/${product.slugId}/${product.slug}`}>
                    <Button color="primary" outline>View Details</Button>
                </Link>
                <CircleButton onClick={downloadAttachments} className="tw-bg-brand-accent-yellow tw-w-[32px] tw-h-[32px]" disabled={loadingViewAttachments}>
                    {loadingViewAttachments ? <Spinner size="12" /> : <i className="fa fa-download" />}
                </CircleButton>
            </div>
            <PostPurchaseReadMoreModal
                isOpen={readMoreModalOpen}
                toggle={toggleModal}
                product={product}
                onViewAttachments={downloadAttachments}
                viewAttachmentsLoading={loadingViewAttachments}
            >
                {postPurchaseRichText}
            </PostPurchaseReadMoreModal>
        </div>
    );
};

export default PostPurchaseProduct;
