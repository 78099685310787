/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import InboxIcon from "@/Assets/Icons/Coloured/inbox.svg";
import DraftsIcon from "@/Assets/Icons/Coloured/drafts.svg";
import ArchivedIcon from "@/Assets/Icons/Coloured/archived-messages.svg";
import DeletedIcon from "@/Assets/Icons/Coloured/deleted-messages.svg";
import { SideBarButton } from "@/Components/SideBarButton";
import { Folder } from "@/Apis/Messaging";

interface Props {
    folder: string;
    changeFolder(folder: Folder): void;
    loading?: boolean;
    className?: string
}

const MessagingSideBar = ({ folder, changeFolder, loading = false, className }: Props) => (
    <div className={classnames(styles.messageSlider, className && className)}>
        <div className={classnames("w-100 mt-1")}>
            <SideBarButton
                icon={<InboxIcon />}
                text="Inbox"
                folder={folder}
                onClick={changeFolder}
                disabled={loading}
            />
            <SideBarButton
                icon={<DraftsIcon />}
                text="Drafts"
                folder={folder}
                onClick={changeFolder}
                disabled={loading}
            />
            <SideBarButton
                icon={<ArchivedIcon />}
                text="Sent"
                folder={folder}
                onClick={changeFolder}
                disabled={loading}
            />
            <SideBarButton
                icon={<DeletedIcon />}
                text="Deleted"
                folder={folder}
                onClick={changeFolder}
                disabled={loading}
            />
        </div>
    </div>
);

export default MessagingSideBar;
