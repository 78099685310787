import { useState } from "react";

const useInvalidFields = <T>() => {
    const [errors, setErrors] = useState<any>({ });
    const removeInvalidField = (field: keyof T | string) => setErrors(errors => {
        const updatedErrors = errors;
        delete updatedErrors[field];
        return updatedErrors;
    });

    const setInvalidField = (fieldName: keyof T | string, error?: string) => setErrors(errors => ({
        ...errors,
        [fieldName]: error || true,
    }));

    const setAllInvalidFields = (errors: any) => setErrors(errors);

    return {
        errors,
        removeInvalidField,
        setInvalidField,
        setAllInvalidFields,
    };
};

export { useInvalidFields };
