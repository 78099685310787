import React, { useEffect } from "react";
import { Button, Table } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import FlexPlanBasicWrapper from "@/FlexPlan/Wrappers/FlexPlanBasicWrapper";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import Spinner from "@/Components/Spinner";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { ManageSupplierColumnType, SupplierListingType } from "@/FlexPlan/Pages/Suppliers/SupplierManagement/Types";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import useClientSidePagination from "@/Hooks/useClientSidePagination";
import { Paging } from "@/Components/Paging";
import { FlexPlanSupplier, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";
import { getFiltersFromEnum } from "@/FlexPlan/Utils/enum";
import { ListingScreenDropdownFilter } from "@/FlexPlan/Components/StatusFilterDropdown";

const columns: ManageSupplierColumnType[] = [
    {
        name: "Business Name",
        columnSort: "businessName",
    },
    {
        name: "ABN",
        columnSort: "abn",
    },
    {
        name: "City",
        columnSort: "city",
    },
];
const SupplierManagement = () => {
    const { push } = useHistory();
    const {
        get,
        loading,
    } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage } = useToastMessageContext();
    const {
        onSearch,
        setItemsForPagination,
        onSort,
        sortColumn,
        sortDescending,
        paginatedItems,
        pageNumber,
        onPageSelected,
        totalPages,
        filters,
        onChangeFilter,
    } = useClientSidePagination<SupplierListingType>(["businessName", "abn", "contactEmail"], {
        field: "supplierType",
        filters: getFiltersFromEnum(SupplierType, true),
    });

    useEffect(() => { // fetch the suppliers
        get<FlexPlanSupplier[]>(FlexPlanUrls.suppliers.base)
            .then(response => {
                setItemsForPagination(response.map(supplier => ({
                    ...supplier,
                    city: supplier.address?.city,
                } as SupplierListingType)));
            })
            .catch(error => {
                setPopupErrorMessage(error, true);
            });
    }, []);

    const renderSortIcon = (column: keyof SupplierListingType) => {
        if (sortColumn && sortColumn === column) {
            if (sortDescending) {
                return <i className="fa fa-sort-down" />;
            }
            return <i className="fa fa-sort-up" />;
        }

        return <i className="fa fa-sort" />;
    };

    return (
        <FlexPlanBasicWrapper title="Manage Suppliers" className="tw-flex tw-flex-col tw-space-y-3">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-space-x-3">
                <div className="tw-relative tw-w-full md:tw-w-1/3">
                    <DebounceTextbox
                        placeholder="Search Supplier"
                        callBack={onSearch}
                    />
                    <i className="fa-lg fas fa-search tw-absolute tw-right-4 tw-top-2" />
                </div>
                <Button color="primary" onClick={() => push("/supplier/sign-up")} className="tw-h-9">
                    Add Supplier
                </Button>
                {filters && (
                    <ListingScreenDropdownFilter
                        filters={filters}
                        onChange={onChangeFilter}
                        buttonName="Supplier Type"
                        titleName="Supplier Type"
                        className="tw-mt-1.5"
                    />
                )}
            </div>
            <Table responsive hover className="!tw-mx-auto">
                <thead>
                    <tr>
                        {columns.map(col => (
                            <th style={{ width: "33%" }} key={col.name}>
                                <button
                                    type="button"
                                    className="bg-transparent p-0 border-0"
                                    onClick={() => onSort(col.columnSort)}
                                >
                                    {col.name}&nbsp;
                                    {renderSortIcon(col.columnSort)}&nbsp;
                                </button>
                            </th>
                        ))};
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={4}>
                                <Spinner className="tw-mx-auto tw-mt-4" />
                            </td>
                        </tr>
                    )}
                    {!loading && paginatedItems?.length === 0 && (
                        <td colSpan={4}>
                            No suppliers to display
                        </td>
                    )}
                    {!loading && paginatedItems && paginatedItems?.length > 0 && (
                        paginatedItems?.map(supplier => (
                            <tr key={supplier.id}>
                                <td>
                                    <Link
                                        to={`/suppliers/${supplier.id}/public-information`}
                                        className="!tw-text-blue-600 !tw-hover:text-blue-800 !tw-visited:text-purple-600"
                                    >
                                        {supplier.businessName}
                                    </Link>
                                </td>
                                <td>
                                    {supplier.abn}
                                </td>
                                <td>
                                    {supplier.city}
                                </td>

                            </tr>
                        )))}
                </tbody>
            </Table>
            <Paging activePage={pageNumber} onChange={onPageSelected} pageCount={totalPages} />
        </FlexPlanBasicWrapper>
    );
};

export { SupplierManagement };
