import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";

const AuthenticationPage = ({ children, hideFooter }) => (
    <Container fluid className={styles.container}>
        <div className={styles.content}>
            {children}
            {!hideFooter && <Footer />}
        </div>
    </Container>
);

AuthenticationPage.propTypes = {
    children: PropTypes.node.isRequired,
    hideFooter: PropTypes.bool,
};

export { AuthenticationPage };
