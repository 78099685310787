import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const SupplierButton = props => (
    <button
        type="button"
        className={classnames(styles.container, "position-absolute box-shadow-bottom-2 text-white bg-primary border-0 pr-4 p-0", props.className)}
        onClick={props.onClick}
    >
        Suppliers
        <i className="fa fa-angle-double-right ml-1 mr-0" />

    </button>
);

SupplierButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default SupplierButton;
