import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { encodeFilesToBase64WithFileName } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";

interface Props {
    onAdd(encodedFile): void;
    children: React.ReactNode;
    accept?: string;
    preDropHook?(acceptedFiles): boolean;
    dragActiveHook?(isDragActive): void;
    noClick?: boolean;
    className?: string;
    validationContent?: string;
    disabled?: boolean;
    maxSize?: number;
}

const DropZoneArea = ({ onAdd,
    children,
    preDropHook,
    dragActiveHook,
    accept = acceptedFileTypes.documents,
    noClick,
    className,
    validationContent,
    disabled = false,
    maxSize = 8000000 }: Props) => {
    const [showValidation, setShowValidation] = useState(false);

    const onDrop = useCallback(async acceptedFiles => {
        let success = true;
        if (preDropHook) {
            success = preDropHook(acceptedFiles);
        }
        if (success && acceptedFiles.length > 0) {
            const encodedFiles = await encodeFilesToBase64WithFileName(acceptedFiles);
            onAdd(encodedFiles);
        }
    }, []);

    const onDropRejected = () => {
        setShowValidation(true);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected,
        multiple: true,
        maxSize,
        accept,
        noClick,
    });

    useEffect(() => {
        if (dragActiveHook) {
            dragActiveHook(isDragActive);
        }
    }, [isDragActive]);

    return (
        <>
            {showValidation && <div className="alert alert-warning">{validationContent ?? `Files must be less than ${maxSize / 1000000}MB and a ${fileTypesText(accept)}.`}</div>}
            <div {...getRootProps()} tabIndex={-1} className={classnames(className && className, !className && styles.dropzone)}>
                <input {...getInputProps()} disabled={disabled} />
                {children}
            </div>
        </>
    );
};

export { DropZoneArea };
