import { isNumeric } from "rxjs/internal-compatibility";

import { toCurrency } from "./formatStringHelper";

import { ProductVariants } from "@/Utils/ProductVariants";
import { ProductVariations } from "@/Utils/ProductVariations";

export const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateEmptyGuid = value => value === "" || /\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-\b[0-9a-fA-F]{12}\b/.test(value);

// The Email field has at least two levels of domains. Eg. name@add.ress, value@value.value
export const validateEmailFormat = (email) => /^([\w.-]+)@([\w.-]+)(\.\w+)$/.test(email);

export const serviceValidator = (service, allowFlexiblePayments) => {
    const errors = {};
    let canMoveToNextScreen = false;
    let hasEnoughToSaveService = false;

    if (!!service.categoryId && !!service.subCategoryName && !!service.name) {
        if (service.description && service.image) {
            canMoveToNextScreen = true;

            if ((allowFlexiblePayments && service.paymentFrequency === "N/A") || service.initialCharge) {
                hasEnoughToSaveService = true;
            }
        }
    }

    const parsedDelayedPaymentFor = parseInt(service.delayedPaymentFor, 10);

    if (service.isDelayedPayment && (!Number.isInteger(parsedDelayedPaymentFor) || parseInt(service.delayedPaymentFor, 10) <= 0)) {
        errors.delayedPaymentFor = "Must be a number greater than 0";
        hasEnoughToSaveService = false;
    }

    const validateCharge = (name, charge) => {
        const stringAfterDecimalPoint = (value) => {
            const s = value.toString();
            return s.indexOf(".") > -1 ? s.substring(s.indexOf(".") + 1, s.length) : "";
        };
        if (!charge) {
            return;
        }
        const afterDecimalPoint = stringAfterDecimalPoint(charge);

        if (!isNumeric(charge) || Number(charge) < 0 || afterDecimalPoint.length > 2 || afterDecimalPoint.indexOf(".") > -1) {
            errors[name] = "Price is invalid";
            hasEnoughToSaveService = false;
        }
    };

    if (allowFlexiblePayments && service.paymentFrequency !== "N/A") {
        validateCharge("initialCharge", service.initialCharge);
        validateCharge("postageCharge", service.postageCharge);

        if (!service.isFreeProduct && parseFloat(toCurrency(service.initialCharge)) < parseFloat("0.01")) {
            errors.initialCharge = "Price must be greater than 0";
            hasEnoughToSaveService = false;
        }
    }

    if (allowFlexiblePayments && service.isOffsitePayment && !service.termsDocument) {
        errors.offsitePaymentTermsDocument = "Terms and Conditions document must be uploaded in the General tab for services set up with Flexible Payment options.";
        hasEnoughToSaveService = false;
    }

    service.upsellItems.forEach(product => {
        if (!validateEmptyGuid(product)) {
            hasEnoughToSaveService = false;
        }
    });

    if (ProductVariants.canBeCustomised(service.productVariant)) {
        const variationItems = ProductVariations.getVariationsFromServiceOrDefault(service).variationItems;
        const invalidVariationsItemsCount = variationItems.filter(x => (ProductVariations.supplierConfigured(x) && !x.value)
            || (x.component === "ConfigurableSelect" && x.value.length === 0)).length;

        if (variationItems.length === 0 || invalidVariationsItemsCount > 0) {
            hasEnoughToSaveService = false;
        }
    }

    if (service.addQuantity && parseInt(service.minimumQuantity, 10) < 1) {
        hasEnoughToSaveService = false;
    }

    return {
        hasEnoughToSaveService,
        canMoveToNextScreen,
        errors,
    };
};
