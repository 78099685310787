import React from "react";
import classnames from "classnames";

import { ComponentBuilder } from "./ComponentBuilder";
import { ColumnType } from "./ColumnTypes";
import styles from "./styles.module.scss";

import { ProductVariations } from "@/Utils/ProductVariations";

interface Props {
    serviceId: string;
    columns: Array<ColumnType>;
    rowId: string;
    onComponentChange?;
    isEditor: boolean;
    addToCartButton?: React.ReactElement,
    readonly?: boolean;
}

const ConfigureProductRow = ({ serviceId, columns, rowId, onComponentChange, isEditor, addToCartButton, readonly = false }: Props) => (
    <tr className="d-block">
        {columns.filter(x => x.checked)
            .map((column) => (
                <th scope="col" key={`${column.variationId}-${rowId}`} className={styles.column}>
                    <div className={classnames(styles.container, "d-flex font-weight-normal")} style={{ width: ProductVariations.getComponentMeta(column.component).width }}>
                        <ComponentBuilder
                            serviceId={serviceId}
                            columns={columns}
                            columnId={column.variationId}
                            rowId={rowId}
                            onComponentChange={onComponentChange}
                            isEditor={isEditor}
                            readonly={readonly}
                        />
                    </div>
                </th>))}
        {!isEditor && addToCartButton && (
            <th scope="col" className={styles.column}>
                <div className={classnames(styles.selectRow, "d-flex")}>
                    {addToCartButton}
                </div>
            </th>)}
    </tr>);

export { ConfigureProductRow };
