import React from "react";

export type ContextualFragment<T> = React.ReactElement | React.ReactFragment | ((context: T | null | undefined) =>
    React.ReactFragment | React.ReactElement | null | undefined) | null | undefined;

export const renderOr = <T>(
    context: T,
    fragment: ContextualFragment<T>,
    or: React.ReactFragment | React.ReactElement,
    allowString: boolean = true) => {
    if (!fragment) {
        return or;
    }
    if (typeof fragment === "function") {
        return fragment(context);
    }
    if (!allowString) {
        return or;
    }
    return fragment;
};
