import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { BlobProvider } from "@react-pdf/renderer";
import { push } from "connected-react-router";

import { Invoice } from "@/Components/Invoice";
import api from "@/Utils/api";

const DownloadInvoice = ({ orderId, disabled }) => {
    const dispatch = useDispatch();
    const onPush = path => () => dispatch(push(path));

    const [loading, setLoading] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [pdfDocumentUrl, setPdfDocumentUrl] = useState(null);

    const downloadPdf = (downloadUrl) => {
        if (downloadUrl) {
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `Invoice-${invoiceDetails.orderReference}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        }
    };

    const downloadInvoice = () => {
        setLoading(true);

        if (pdfDocumentUrl) {
            downloadPdf(pdfDocumentUrl);
        } else {
            api.get(`invoice/${orderId}`)
                .then(response => setInvoiceDetails(response))
                .catch(error => onPush({ pathname: "/error", state: { error, referrer: window.location.href } }));
        }
    };

    return (
        <>
            {invoiceDetails && !pdfDocumentUrl && (
                <BlobProvider document={<Invoice {...invoiceDetails} />}>
                    {({ url }) => {
                        downloadPdf(url);
                        setPdfDocumentUrl(url);
                        return null;
                    }}
                </BlobProvider>
            )}
            <Button className="mb-3 mt-1" color="primary" block disabled={disabled || loading} onClick={downloadInvoice}>Download Invoice</Button>
        </>
    );
};

DownloadInvoice.propTypes = {
    orderId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export { DownloadInvoice };
