import React, { useRef, useEffect, ReactNode, useCallback } from "react";
import classnames from "classnames";

interface Props {
    children?: ReactNode;
    onClickAway: (e) => void;
    className?: string;
}

const ClickAwayListener = ({ onClickAway, children, className }: Props) => {
    const ref: any = useRef(null);

    const handleClickAway = useCallback((event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClickAway(event);
        }
    }, [onClickAway]);

    useEffect(() => {
        document.addEventListener("click", handleClickAway);
        return () => document.removeEventListener("click", handleClickAway);
    }, [onClickAway, handleClickAway]);

    return <div ref={ref} className={classnames(className)}>{children}</div>;
};

export { ClickAwayListener };
