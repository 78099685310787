import React from "react";

import Spinner from "@/Components/Spinner";
import { CompanySection } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/CompanySection";
import { ContactSection } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ContactSection";
import { NdisSection } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/NdisSection";
import { SupportDocumentsSection } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/SupportDocumentsSection";
import { usePlanManagerProfile } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Hooks/usePlanManagerProfileTab";
import { BankDetailsSection } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/BankDetailsSection";

const ProfileTab = () => {
    const { loading,
        handleErrorStatusChange,
        errors, handleFieldChange,
        handleSelectEditSection,
        handleSaveClick,
        profileTabState,
        setProfileTabProfileTabState } = usePlanManagerProfile();
    if (loading) {
        return (
            <Spinner className="tw-mx-auto tw-mt-10" />
        );
    }
    return (
        <div className="mb-5">
            <div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <CompanySection
                        state={profileTabState}
                        errors={errors}
                        handleErrorStatusChange={handleErrorStatusChange}
                        handleFieldChange={handleFieldChange}
                        handleSelectEditSection={handleSelectEditSection}
                        handleSaveClick={handleSaveClick}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <ContactSection
                        handleSelectEditSection={handleSelectEditSection}
                        setState={setProfileTabProfileTabState}
                        state={profileTabState}
                        handleSaveClick={handleSaveClick}
                        errors={errors}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <NdisSection
                        state={profileTabState}
                        errors={errors}
                        handleErrorStatusChange={handleErrorStatusChange}
                        handleFieldChange={handleFieldChange}
                        handleSelectEditSection={handleSelectEditSection}
                        handleSaveClick={handleSaveClick}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <SupportDocumentsSection
                        handleSelectEditSection={handleSelectEditSection}
                        state={profileTabState}
                        errors={errors}
                        handleSaveClick={handleSaveClick}
                        setState={setProfileTabProfileTabState}
                    />
                </div>
                <div className="border rounded-lg p-4 m-lg-3 mt-4">
                    <BankDetailsSection
                        state={profileTabState}
                        errors={errors}
                        handleErrorStatusChange={handleErrorStatusChange}
                        handleFieldChange={handleFieldChange}
                        handleSelectEditSection={handleSelectEditSection}
                        handleSaveClick={handleSaveClick}
                    />
                </div>
            </div>
        </div>
    );
};

export { ProfileTab };
