import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";

// Standard cancel button seen on FlexPlan forms. Simply goes to the previous page.
interface Props {
    buttonText?: string,
    className?: string,
    size?: "lg" | "md" | "sm",
    displayConfirmationModal?: boolean,
    breadcrumbsBackButton?: boolean, // CHanges look of the button
    disabled?: boolean,
}

export const BackButton = ({ buttonText = "Back", className, size = "lg", displayConfirmationModal = false, breadcrumbsBackButton = false, disabled = false }: Props) => {
    const { goBack } = useHistory();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const toggleModal = () => setModalOpen(prev => !prev);

    return (
        <>
            {breadcrumbsBackButton ? (
                <button type="button" onClick={displayConfirmationModal ? toggleModal : goBack} className="tw-text-black">
                    <i className="fa fa-arrow-circle-left" /> &nbsp;Back
                </button>
            ) : (
                <Button
                    onClick={displayConfirmationModal ? toggleModal : goBack}
                    color="primary"
                    className={className}
                    size={size}
                    type="button"
                    outline
                    disabled={disabled}
                >
                    {buttonText}
                </Button>
            )}

            {displayConfirmationModal && (
                <ConfirmCancelModal
                    isOpen={modalOpen}
                    text="Are you sure you want to discard your changes?"
                    onConfirm={goBack}
                    onClose={toggleModal}
                />
            )}
        </>
    );
};
