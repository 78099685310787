import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";

const SupplierAlreadyExistsModal = () => {
    const {
        supplierAlreadyExists,
        onCloseSupplierAlreadyExists,
        onConfirmSupplierAlreadyExists,
    } = useFlexPlanSupplierSignUpContext();

    return (
        <Modal
            isOpen={!!supplierAlreadyExists}
            centered
            size="md"
            className="tw-p-10"
        >
            <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8">
                <>
                    <div className="tw-py-3 tw-space-x-3 tw-justify-center tw-flex tw-flex-row tw-items-center ">
                        <i className="fa fa-exclamation-circle fa-2x tw-text-amber-400" />
                        <span className="tw-text-base">
                            You have an existing supplier {supplierAlreadyExists?.businessName} with the same ABN.
                        </span>
                    </div>
                    <div className="tw-flex tw-space-x-3 tw-justify-center tw-mt-2">
                        <Button color="primary" className="tw-text-base" outline onClick={onCloseSupplierAlreadyExists}>
                            Update ABN
                        </Button>
                        <Button color="primary" className="tw-text-base" onClick={onConfirmSupplierAlreadyExists}>
                            Go to Supplier Profile
                        </Button>
                    </div>
                </>
            </ModalBody>
        </Modal>

    );
};

export { SupplierAlreadyExistsModal };
