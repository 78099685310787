import { push } from "connected-react-router";
import { Dispatch } from "redux";

import api from "@/Utils/api";
import { OrderType } from "@/Apis/SalesManagement";

export type PaymentSource = "free" | "stripe" | "paypal";

export const OrderTypes = {
    Product: 0,
    Upsell: 1,
    BespokeQuote: 2,
    Package: 3,
    FormationsProduct: 4,
    ReadyMadeFormationsProduct: 8, // Get from OrderType.cs back-end
    BankAccountProduct: 9,
};

export interface PaymentDetails {
    source: PaymentSource;
    orderIds: string[];
    PaypalOrderId?: string;
    paypalResponse?: any;
    stripePaymentId?: string;
}

export interface GetSubscriptionPaymentsResponse {
    subscriptionPayments: SubscriptionPayment[];
}

export interface SubscriptionPayment {
    paymentDate: string | null;
    dueDate: string | null;
    amountDue: string;
    payment: string;
    paymentStatus: string;
    invoice: Invoice;
}

export interface Invoice {
    customerName: string;
    customerAddressLine1: string;
    customerAddressLine2: string;
    customerCity: string;
    customerPostCode: string;
    orderReference: string;
    datePaid: string;
    supplierName: string;
    supplierLogo: string;
    customerRegistrationNumber: string;
    productName: string;
    quantity: number;
    priceExcludingVat: number;
    postage: number;
    grandTotal: number;
    tagLine: string;
    showName: boolean | null;
    showLogo: boolean;
    showTagline: boolean;
    nameAndTaglineColor: string;
    headerImage: string;
    headerColour: string;
    vatAmount: number;
    customerVatNumber: string;
    supplierAddressLine1: string;
    supplierAddressLine2: string;
    supplierCity: string;
    supplierPostCode: string;
    supplierRegistrationNumber: string;
    supplierVatNumber: string;
    customerCompanyName: string;
    supplierCompanyName: string;
}

export interface CheckoutResponse {
    payments: CheckoutResponsePayment[]
}

export interface CheckoutResponsePayment {
    paymentId: string,
    orders: CheckoutResponseOrders[]
}

interface CheckoutResponseOrders {
    supplierId: string,
    supplierName: string,
    productId: string,
    productName: string,
    isPostPurchaseEnabled: boolean,
    orderType: OrderType,
    basketId: string,
}

// useApi style api
export class OrdersUrls {
    private static supplierOrders = "supplier-orders";

    private static customerOrders = "customer-orders";

    static getSubscriptionPayments = (orderId:string, isCustomer?: boolean) => (isCustomer
        ? `${OrdersUrls.customerOrders}/${orderId}/subscription-payments`
        : `${OrdersUrls.supplierOrders}/${orderId}/subscription-payments`);
}

export interface PostPurchaseCheckoutLocationState {
    basketId: string,
}

export const completeOrder = (details: PaymentDetails) => api.post<CheckoutResponse>("basket/completeOrder", details);

export const onOrderComplete = (dispatch: Dispatch<any>, onPaymentSuccess?: () => void) => (payments: CheckoutResponsePayment[]) => {
    if (onPaymentSuccess) {
        onPaymentSuccess();
    }

    const basketId: string = payments.flatMap(x => x.orders.filter(xx => !!xx.basketId))[0].basketId;

    dispatch(push("/post-purchase-checkout", {
        basketId,
    }));
};

export interface OrderInvoiceResponse {
    customerName: string,
    customerAddressLine1: string,
    customerAddressLine2: string,
    customerCity: string,
    customerPostCode: string,
    customerRegistrationNumber: string,
    customerVatNumber: string,
    supplierName: string,
    supplierLogo: string,
    supplierAddressLine1: string,
    supplierAddressLine2: string,
    supplierCity: string,
    supplierPostCode: string,
    supplierRegistrationNumber: string,
    supplierVatNumber: string,
    orderReference: string,
    datePaid: string,
    productName: string,
    quantity: number,
    priceExcludingVat: number,
    vatAmount: number,
    postage: number,
    grandTotal: number,
}
