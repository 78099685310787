import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button } from "reactstrap";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import { ClickAwayListener } from "@/Components/ClickAwayListener";
import { OnboardingProps, OnboardingSteps } from "@/Context/OnboardingContext/onBoardingSteps";

const Onboarding = ({ name, onDisplay, onEndDisplay, content, onContinue, onClose }: OnboardingProps) => {
    const [displayed, setDisplayed] = useState(false);
    const [animate, setAnimate] = useState(false);
    const onboardingStep = Object.entries(OnboardingSteps).map(([key, value]) => ({ key, value })).find(x => x.value === name)?.key;
    const lastOnBoardingStep = Object.values(OnboardingSteps)[Object.values(OnboardingSteps).length - 1];
    const closeHelp = () => {
        if (onEndDisplay) {
            onEndDisplay();
        }
        onClose();
    };
    const continueHelp = () => {
        setAnimate(true);
        if (onEndDisplay) {
            onEndDisplay();
        }
        onContinue();
    };

    useEffect(() => {
        if (onDisplay) {
            onDisplay();
        }
        setDisplayed(true);
    }, [name]);

    return (
        <>
            <ClickAwayListener onClickAway={name === OnboardingSteps.searchRecommendations && !displayed ? () => {} : closeHelp}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    onClick={(e) => { e.stopPropagation(); }}
                    data-testid={`${name}-help-popup`}
                    className={classnames("position-absolute mt-3 rounded box-shadow bg-white d-flex flex-column",
                        styles[onboardingStep as string], styles.onboardingMessage, animate && styles.animate)}
                >
                    <CloseButton onClick={closeHelp} className="text-white" />
                    <div className="d-flex align-items-center p-3 bg-primary rounded-top text-white">
                        <h6 className="mb-0">{name}</h6>
                    </div>
                    <div className="p-3 h6 flex-grow-1">
                        {content}
                    </div>
                    <div className="pl-3 pr-3 pb-3 d-flex">
                        {name !== lastOnBoardingStep && (
                            <>
                                <Button
                                    data-testid={`${name}-help-Close`}
                                    className="flex-grow-1 p-2 mr-1 bg-transparent text-secondary"
                                    onClick={closeHelp}
                                >
                                    Close
                                </Button>
                                <Button
                                    data-testid={`${name}-help-Continue`}
                                    color="primary"
                                    className={classnames(styles.button, "flex-grow-1 p-2 ml-1")}
                                    onClick={continueHelp}
                                >
                                    Continue
                                </Button>
                            </>
                        )}
                        {name === lastOnBoardingStep && (
                            <>
                                <Button color="primary" className="flex-grow-1 p-2 mx-5" onClick={closeHelp}>OK</Button>
                            </>
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        </>);
};

export { Onboarding };
