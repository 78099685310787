import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col } from "reactstrap";

import Spinner from "@/Components/Spinner";
import { getToken } from "@/Utils/authentication";
import globalActions from "@/Store/Global/actions";
import userActions from "@/Store/User/actions";

const authenticationPaths = ["/sign-up", "/login"];

export class PrivateRoute extends React.PureComponent {
    componentDidMount() {
        if (!this.props.hasUserData) {
            this.props.loadUser();
        } else if (this.props.guestCheckout) {
            this.props.pushcheckout();
        } else if (!this.props.isOnboarded) {
            this.props.pushToWelcomeModal();
        }
    }

    render() {
        if (!this.props.hasUserData) {
            return (
                <Row className="flex-grow-1">
                    <Col xs={12} className="d-flex align-items-center justify-content-center mt-5">
                        <Spinner />
                    </Col>
                </Row>
            );
        }

        if (this.props.adminOnly && !this.props.isAdmin) {
            this.props.pushToHome();
        }

        if (this.props.supplierOnly && !this.props.isSupplier) {
            this.props.pushToHome();
        }

        if (authenticationPaths.includes(this.props.location?.pathname)) {
            this.props.pushToHome();
            this.props.setToastMessage();
        }

        const { component: Component, ...rest } = this.props;

        if (!getToken()) {
            this.props.pushToLogin();
            this.props.setToastMessageInactive();
        }
        return (
            <>
                <Route {...rest} render={props => <Component {...props} />} />
            </>
        );
    }
}

PrivateRoute.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    children: PropTypes.element,
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isAdmin: PropTypes.bool,
    isSupplier: PropTypes.bool,
    hasUserData: PropTypes.bool,
    isOnboarded: PropTypes.bool,
    loadUser: PropTypes.func,
    pushToWelcomeModal: PropTypes.func,
    pushToHome: PropTypes.func,
    pushToLogin: PropTypes.func,
    setToastMessage: PropTypes.func,
    setToastMessageInactive: PropTypes.func,
    adminOnly: PropTypes.bool,
    supplierOnly: PropTypes.bool,
    guestCheckout: PropTypes.bool,
    pushcheckout: PropTypes.func,
};

PrivateRoute.defaultProps = {
    isSupplier: false,
    hasUserData: false,
    isOnboarded: false,
    loadUser: () => { },
    pushToWelcomeModal: () => { },
    pushToHome: () => { },
    pushToLogin: () => { },
    setToastMessage: () => { },
    setToastMessageInactive: () => { },
    adminOnly: false,
    supplierOnly: false,
    guestCheckout: false,
    pushcheckout: () => { },
};

const mapStateToProps = state => ({
    isAdmin: state.user.isAdmin,
    isSupplier: state.user.isSupplier,
    hasUserData: state.user.hasUserData,
    isOnboarded: state.user.isOnboarded,
    guestCheckout: state.user.guestCheckout,
});

const mapDispatchToProps = dispatch => ({
    loadUser: () => dispatch(userActions.loadUser$()),
    pushToWelcomeModal: () => dispatch(push({ pathname: "/marketplace" })),
    pushcheckout: () => dispatch(push({ pathname: "/checkout" })),
    pushToHome: () => dispatch(push("/")),
    pushToLogin: () => dispatch(push("/login")),
    setToastMessage: () => dispatch(globalActions.setToastMessage(true, "You are already authenticated.")),
    setToastMessageInactive: () => dispatch(globalActions.setToastMessage(true, "You have been logged out due to inactivity.")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
