import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Col } from "reactstrap";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import CategoryTabs from "@/Components/CategoryTabs";
import Spinner from "@/Components/Spinner";
import { SupplierHeader } from "@/Components/SupplierHeader";
import { SupplierDescription } from "@/Components/SupplierDescription";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { PackageTile } from "@/Components/PackageTile";
import { EnquiryFormMiniSiteTile } from "@/Components/EnquiryFormMiniSiteTile";
import { ProductVariants } from "@/Utils/ProductVariants";
import { ProductVariations } from "@/Utils/ProductVariations";
import { useMinisiteContext } from "@/Context/MinisiteContext";
import { Template } from "@/Pages/MiniSiteEditorPage/Template";
import { MinisiteEditState } from "@/Pages/MiniSiteEditorPage/Types";

const Presentation = (props) => {
    const [searchTerm, setSearchTerm] = useState("");

    const {
        isDirty,
        saving,
        miniSite,
        activeCategoryTab,
        setActiveCategoryTab,
        saveSupplier,
        onMouseEnterTile,
        onMouseLeaveTile,
        showEditHeaderButton,
        onMouseEnterDesc,
        onMouseLeaveDesc,
        showEditDescButton,
        changeEditorType,
        onMouseEnterHeader,
        onMouseLeaveHeader,
    } = useMinisiteContext();

    const displayProducts = miniSite.services
        .filter((x) => {
            if (searchTerm) {
                const lowerTerm = searchTerm.toLowerCase();
                return x.categoryName.toLowerCase()
                    .includes(lowerTerm) || x.name.toLowerCase()
                    .includes(lowerTerm);
            }

            return x.categoryName === activeCategoryTab;
        })
        .reduce((acc, curValue) => {
            if (!acc[curValue.subCategoryName]) {
                acc[curValue.subCategoryName] = [curValue];
            } else {
                acc[curValue.subCategoryName].push(curValue);
            }
            return acc;
        }, {});

    return (
        <div className={classnames(styles.background, "d-flex flex-grow-1 flex-column p-0")}>
            <div className={classnames(styles.navigation, "box-shadow-bottom-2 text-white d-flex align-items-center px-3")}>
                <h5 className="m-0">Shop Editor</h5>
                <div className="ml-auto">
                    {isDirty && <span className={classnames(styles.isDirtyLabel, "px-3")}>You have unsaved changes.</span>}
                    <Link to={`/marketplace/supplier-directory/${miniSite.id}`} className="bg-transparent border-0 p-3 text-white">
                        <i className="fa fa-door-open mr-2" />
                        Exit MiniSite
                    </Link>
                    <button
                        type="button"
                        disabled={!isDirty}
                        className="bg-transparent border-0 p-3 text-white"
                        onClick={saveSupplier}
                        data-testid="save-supplier-changes"
                    >
                        {saving ? <Spinner className="mx-4 p-2" /> : (
                            <>
                                <i className="fa fa-save mr-2" />
                                Save changes
                            </>
                        )}
                    </button>
                </div>
            </div>

            <div className="mx-4 mt-5 mb-2 bg-white pb-3 box-shadow">
                <SupplierHeader
                    showLogo={miniSite.showLogo}
                    showName={miniSite.showName}
                    showTagline={miniSite.showTagline}
                    headerColour={miniSite.headerColour}
                    headerImage={miniSite.headerImage}
                    nameAndTaglineColour={miniSite.nameAndTaglineColour}
                    logo={miniSite.logo}
                    name={miniSite.name}
                    tagline={miniSite.tagline}
                    showEditHeaderButton={showEditHeaderButton}
                    onEditHeaderClick={() => changeEditorType(MinisiteEditState.editHeader)}
                    onMouseEnter={onMouseEnterHeader}
                    onMouseLeave={onMouseLeaveHeader}
                />
                <SupplierDescription
                    hideDescription
                    rating={miniSite.rating}
                    showEditButton={showEditDescButton}
                    onEditClick={() => changeEditorType(MinisiteEditState.editDescription)}
                    onMouseEnter={onMouseEnterDesc}
                    onMouseLeave={onMouseLeaveDesc}
                />
                <Template />
                <div className={classnames(styles.searchContainer, "p-3 d-flex")}>
                    <div className="rounded border d-flex align-items-center pl-2 mr-2 flex-grow-1">
                        <i className="fa fa-search mr-2" />
                        <input className="w-100 border-0 pl-2 py-1" placeholder={`Search ${miniSite.name || "My Brand"}`} onChange={e => setSearchTerm(e.target.value)} />
                    </div>
                    <Button className="px-3 border" disabled outline> <i className="fa fa-filter mr-2" />Filters</Button>
                </div>

                {activeCategoryTab && miniSite.categoriesToDisplay.length > 0 && !searchTerm && (
                    <CategoryTabs
                        onChange={setActiveCategoryTab}
                        headerColour={miniSite.headerColour}
                        categories={miniSite.categoriesToDisplay}
                        activeCategoryTab={activeCategoryTab}
                    />
                )}

                <div className="d-flex flex-wrap">
                    {Object.keys(displayProducts)
                        .sort().map(subCategory => (
                            <Col xs="12" key={subCategory} className="p-0">
                                <div className="bg-secondary text-white my-4 mx-3 px-3 py-2 text-uppercase">{subCategory}</div>
                                {displayProducts[subCategory].map(x => {
                                    if (ProductVariants.canBeAddedToBasket(x.productVariant)) {
                                        return (<ServiceMiniSiteTile
                                            id={x.id}
                                            key={`${x.id}-${x.name}`}
                                            name={x.name}
                                            description={x.description}
                                            image={x.imageThumbnail ?? x.image}
                                            paymentFrequency={x.paymentFrequency}
                                            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                            postageCharge={x.postageCharge}
                                            images={x.images}
                                            moreDetails={x.moreInformation}
                                            showEditButton
                                            onEditService={(() => props.onEditService(x))}
                                            onMouseEnter={onMouseEnterTile}
                                            onMouseLeave={onMouseLeaveTile}
                                            disableAddService
                                            addQuantity={x.addQuantity}
                                            minimumQuantity={x.minimumQuantity}
                                            variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
                                            productVariant={x.productVariant}
                                            isVatRequired={x.isVatRequired}
                                            initialCharge={x.initialCharge}
                                            categoryId={x.categoryId}
                                            categoryName={x.categoryName}
                                            subCategoryName={x.subCategoryName}
                                            supplierId={x.supplierId}
                                            supplierName={x.supplierName}
                                            slug={x.slug}
                                            slugId={x.slugId}
                                            isDelayedPayment={x.isDelayedPayment}
                                            delayedPaymentFor={x.delayedPaymentFor}
                                            delayedPaymentPeriod={x.delayedPaymentPeriod}
                                        />);
                                    }
                                    if (x.productVariant === ProductVariants.enquiryFormProduct) {
                                        return (<EnquiryFormMiniSiteTile
                                            id={x.id}
                                            key={`${x.id}-${x.name}`}
                                            name={x.name}
                                            description={x.description}
                                            image={x.imageThumbnail ?? x.image}
                                            startingPrice={x.startingPrice}
                                            images={x.images}
                                            categoryId={x.categoryId}
                                            categoryName={x.categoryName}
                                            supplierId={x.supplierId}
                                            supplierName={x.supplierName}
                                            moreDetails={x.moreInformation}
                                            showEditButton
                                            onEditEnquiryForm={(() => { props.onEditEnquiry(x); })}
                                            onMouseEnter={(() => onMouseEnterTile(x.id))}
                                            onMouseLeave={onMouseLeaveTile}
                                            disableOpenEnquiryForm
                                            slug={x.slug}
                                            slugId={x.slugId}
                                        />);
                                    }
                                    if (x.categoryName === "Packages") {
                                        return (<PackageTile
                                            key={`${x.id}-${x.name}`}
                                            id={x.id}
                                            code={x.code}
                                            name={x.name}
                                            description={x.description}
                                            images={x.images}
                                            image={x.images ? x.images[0] : null}
                                            initialCharge={x.initialCharge}
                                            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                            postageCharge={x.postageCharge}
                                            showEditButton
                                            onEditPackage={(() => { props.onEditPackage(x); })}
                                            supplierName={x.supplierName}
                                            productPriceSummary={x.productPrices}
                                            hasNAProducts={x.hasNAProducts}
                                        />);
                                    }
                                    return (<></>);
                                })}
                            </Col>
                        ))}
                </div>
            </div>

            <div className="d-flex mx-4 mb-4">
                <div className={classnames(styles.addButton, "mr-3 pt-3 pt-md-4 bg-white my-3 box-shadow")}>
                    <h5 className="font-weight-bold px-3 mb-3" data-testid="add-service">
                        <i className="fa fa-users-cog mr-2" />
                        Add a Service
                    </h5>
                    <span />
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            data-testid="add-service-button"
                            className="bg-transparent border-0 p-3 d-flex justify-content-end w-100"
                            onClick={props.onAddService}
                        >
                            <i className="fa fa-plus-circle mr-2" />
                            Add
                        </button>
                    </div>
                </div>
                <div className={classnames(styles.addButton, "pt-3 pt-md-4 bg-white my-3 box-shadow")}>
                    <h5 className="font-weight-bold px-3 mb-3" data-testid="add-enquiry">
                        <i className="fa fa-newspaper mr-2" />
                        Add Enquiry Form
                    </h5>
                    <span />
                    <button
                        type="button"
                        data-testid="add-enquiry-button"
                        className="bg-transparent border-0 p-3 d-flex justify-content-end w-100"
                        onClick={props.onAddEnquiry}
                    >
                        <i className="fa fa-plus-circle mr-2" />
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

Presentation.propTypes = {
    onAddService: PropTypes.func.isRequired,
    onEditService: PropTypes.func.isRequired,
    onEditPackage: PropTypes.func.isRequired,
    onAddEnquiry: PropTypes.func.isRequired,
    onEditEnquiry: PropTypes.func.isRequired,
};

export { Presentation };
