import React, { useState } from "react";
import classnames from "classnames";

import { ClickAwayListener } from "@/Components/ClickAwayListener";
import { CircleButton } from "@/Components/CircleButton";

interface MenuItem {
    title: string,
    onClick: () => void,
}

interface Props {
    items: MenuItem[],
    buttonBody: React.ReactNode,
    buttonClasses?: string,
    menuClasses?: string // Pass in the position e.g. !-tw-top-6 !-tw-left-52
}

// Supply a list of buttons, their their handlers, and have a menue
const PopoverMenu = ({ items, buttonBody, buttonClasses, menuClasses = "!tw-top-0 !tw-left-0" }: Props) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    return (
        <div className="tw-relative">
            <ClickAwayListener onClickAway={() => setMenuIsOpen(false)}>
                <CircleButton
                    className={buttonClasses}
                    onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                    }}
                >
                    {buttonBody}
                </CircleButton>
            </ClickAwayListener>
            {menuIsOpen && (
                <div className={classnames("tw-absolute dropdown-menu show", menuClasses)}>
                    {items.map(item => (
                        <button
                            type="button"
                            className="dropdown-item"
                            onClick={item.onClick}
                            key={item.title}
                        >
                            {item.title}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PopoverMenu;
