import React from "react";
import { Switch, Route } from "react-router-dom";

import { SupplierDirectoryPage } from "./SupplierDirectoryPage";

import { MarketplacePage } from "@/Pages/Marketplace/MarketplacePage";

const Marketplace = () => (
    <>
        <Route
            path="/marketplace"
            render={({ match: { url } }) => (
                <Switch>
                    <Route exact path={`${url}/supplier-directory/:id`} component={SupplierDirectoryPage} />
                    <Route path={`${url}/supplier-directory`} component={SupplierDirectoryPage} />

                    <Route exact path={`${url}/:id`} component={MarketplacePage} />
                    <Route exact path={`${url}`} component={MarketplacePage} />
                </Switch>
            )}
        />
        <Route
            path="/"
            render={({ match: { url } }) => (
                <Switch>
                    <Route exact path={`${url}`} component={MarketplacePage} />
                </Switch>
            )}
        />
    </>
);

export default Marketplace;
