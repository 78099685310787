import { Order, OrderRow } from "../Types";

import { FlexPlanOrderStatus, PaymentRequestStatus } from "@/FlexPlan/Types/Order";

export const openRows = (orderRow: OrderRow) => orderRow.status === PaymentRequestStatus.AwaitingApproval
        || orderRow.status === PaymentRequestStatus.Open
        || orderRow.status === PaymentRequestStatus.Paid
        || orderRow.status === PaymentRequestStatus.PendingPayment
        || orderRow.status === PaymentRequestStatus.NoStatus;

export const approvedRows = (orderRow: OrderRow) => orderRow.status === PaymentRequestStatus.Paid
        || orderRow.status === PaymentRequestStatus.PendingPayment;

export const failedRows = (orderRow: OrderRow) => orderRow.status === PaymentRequestStatus.Cancelled
        || orderRow.status === PaymentRequestStatus.Deleted
        || orderRow.status === PaymentRequestStatus.Rejected
        || orderRow.status === PaymentRequestStatus.Incomplete
        || orderRow.status === PaymentRequestStatus.Error;

export const isApprovedOrder = (orderStatus?: FlexPlanOrderStatus): boolean => !!orderStatus && [
    FlexPlanOrderStatus.Approved,
    FlexPlanOrderStatus.PayFileCreated,
    FlexPlanOrderStatus.PaidFull,
    FlexPlanOrderStatus.PaidPartial,
]
    .includes(orderStatus);

export const isSubmittedOrder = (order?: Order): boolean => !!order && order.items?.some(orderItem => orderItem.status !== PaymentRequestStatus.NoStatus);
