import React, { useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";

interface Props {
    lines: number;
    text: string;
    truncateText?: string;
    truncateTextAlign?: "left" | "right" | "center";
    truncateBackgroundOverlay?: string;
    className?: string;
    contentClassName?: string;
}

const MultilineTextTruncate = ({
    lines,
    text,
    className,
    contentClassName,
    truncateText,
    truncateTextAlign = "center",
    truncateBackgroundOverlay = "rgba(255,255,255,0.9)",
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const [hasMoreText, setHasMoreText] = useState(false);
    const [computedContentHeight, setComputedContentHeight] = useState<number | null>(null);
    const [computedContainerMaxHeight, setComputedContainerMaxHeight] = useState<number | null>(null);
    const [computedContainerLineHeight, setComputedContainerLineHeight] = useState<number | null>(null);
    const [computedContainerWidth, setComputedContainerWidth] = useState<number | null>(null);

    const parseComputedStylePixelUnitValue = (styleValueWithPixelUnit) => (parseInt(styleValueWithPixelUnit.replace("px", ""), 10));

    useLayoutEffect(() => {
        if (contentRef?.current) {
            setComputedContentHeight(parseComputedStylePixelUnitValue(window.getComputedStyle(contentRef.current).height));
        }
    }, [contentRef]);

    useLayoutEffect(() => {
        if (containerRef?.current && computedContentHeight) {
            const maxHeight = parseComputedStylePixelUnitValue(window.getComputedStyle(containerRef.current).lineHeight) * lines;
            setComputedContainerMaxHeight(maxHeight);
            setComputedContainerWidth(parseComputedStylePixelUnitValue(window.getComputedStyle(containerRef.current).width));
            setComputedContainerLineHeight(parseComputedStylePixelUnitValue(window.getComputedStyle(containerRef.current).lineHeight));

            if (computedContentHeight > maxHeight) {
                setHasMoreText(true);
            }
        }
    }, [containerRef, computedContentHeight]);

    return (
        <>
            <div
                ref={containerRef}
                className={classnames("overflow-hidden", className && className)}
                style={{ maxHeight: `${computedContainerMaxHeight}px` }}
            >
                {hasMoreText && (
                    <div
                        className="position-absolute"
                        style={{
                            marginTop: `${(computedContainerMaxHeight ?? 0) - (computedContainerLineHeight && computedContainerLineHeight > 0
                                ? computedContainerLineHeight / 2
                                : 0)}px`,
                            height: `${(computedContainerLineHeight ?? 0)}px`,
                            width: `${(computedContainerWidth ?? 0)}px`,
                            background: truncateBackgroundOverlay,
                            textAlign: truncateTextAlign,
                        }}
                    >
                        {truncateText}
                    </div>)}
                <div ref={contentRef} className={classnames(contentClassName && contentClassName)} style={{ lineHeight: `${computedContainerLineHeight}px` }}>
                    {text}
                </div>
            </div>
        </>);
};

export { MultilineTextTruncate };
