import React from "react";
import PropTypes from "prop-types";

import { features } from "@/Utils/features";

const Feature = ({ name, children }) => (
    features.isEnabled(name) ? children : (<></>)
);

Feature.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export { Feature };
