import { Text, View } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

import { toCurrency } from "@/Utils/formatStringHelper";

const Order = ({ productName, quantity, priceExcludingVat }) => {
    const OrderDetailsHeader = () => (
        <View style={{ flexDirection: "row", fontSize: "8", fontFamily: "LatoBold", padding: "30 20 0 20", color: "#999" }}>
            <View style={{ flexDirection: "column", width: "50%" }}>
                <Text style={{ marginLeft: 20 }}>DESCRIPTION</Text>
            </View>

            <View style={{ flexDirection: "column", width: "25%" }}>
                <Text>QUANTITY</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%" }}>
                <Text style={{ marginRight: 20 }}>AMOUNT</Text>
            </View>
        </View>
    );

    const OrderDetails = () => (
        <View style={{ flexDirection: "row", color: "#333", padding: "20 20 0 20" }}>
            <View style={{ flexDirection: "column", width: "50%", borderBottom: "1 solid #e0e0e0", paddingBottom: 14 }}>
                <Text style={{ marginLeft: 20 }}>{productName.toUpperCase()}</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%", borderBottom: "1 solid #e0e0e0", paddingBottom: 14 }}>
                <Text>{quantity}</Text>
            </View>
            <View style={{ flexDirection: "column", width: "25%", borderBottom: "1 solid #e0e0e0", paddingBottom: 14 }}>
                <Text style={{ marginRight: 20 }}>£{toCurrency(priceExcludingVat)}</Text>
            </View>
        </View>
    );

    return (
        <>
            <OrderDetailsHeader />
            <OrderDetails />
        </>);
};

Order.propTypes = {
    productName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    priceExcludingVat: PropTypes.number.isRequired,
};

export { Order };
