import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button, Collapse } from "reactstrap";

import styles from "./styles.module.scss";

const AccordionHeading = ({ heading, hideHeading, children, open, onToggle, headerClassName, className, iconClassName, collapseClassName, disabled, hideIcon }) => (
    <>
        <Button
            onClick={onToggle}
            color="text"
            className={classnames(styles.header, "w-100 d-flex justify-content-between", className)}
            disabled={disabled}
            data-testid={`field-heading-${heading}`}
        >
            {!hideHeading && <span className={headerClassName}>{heading}</span>}
            {!disabled && !hideIcon && <i className={classnames(iconClassName || "fas fa-angle-down", open ? "fa-rotate-180" : "")} />}
        </Button>
        <Collapse isOpen={open} className={classnames("w-100", collapseClassName)}>
            {children}
        </Collapse>
    </>
);

AccordionHeading.propTypes = {
    heading: PropTypes.string.isRequired,
    hideHeading: PropTypes.bool,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    collapseClassName: PropTypes.string,
    disabled: PropTypes.bool,
    hideIcon: PropTypes.bool,
};

export { AccordionHeading };
