import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Button } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Footer from "@/Components/Footer";
import { Paging } from "@/Components/Paging";
import { SortOrder, User } from "@/Apis/UsersManagement";
import { useAPI } from "@/Apis/useAPI";

export enum SortColumn {
    FirstName,
    Surname,
    Email,
    Supplier
}

const UserListingPage = () => {
    document.title = "BSC - Users Management";
    const [users, setUsers] = useState<User[]>([]);
    const [pageLength, setPageLength] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [sortColumn, setSortColumn] = useState(SortColumn.Email);
    const [sortAsc, setSortAsc] = useState(true);
    const { loading, post } = useAPI({ handle500WithToastMessage: true });
    const [searchValueChange, setsearchValueChange] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const onChangeSearchTerm = (e) => {
        const searchValue = e.target.value;
        setsearchValueChange(searchValue);
        if (searchValue === "") {
            setPageNumber(0);
            setSearchTerm(searchValue);
        }
    };
    const handleSearch = () => {
        setPageNumber(0);
        setSearchTerm(searchValueChange);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            setPageNumber(0);
            setSearchTerm(searchValueChange);
        }
    };

    useEffect(() => {
        const sortOrderValue = `${SortColumn[sortColumn]}${sortAsc ? "Asc" : "Desc"}`;
        const sortOrder = sortOrderValue as unknown as SortOrder;
        const searchQuery = {
            page: pageNumber,
            sortOrder,
            searchTerm,
        };

        post("users/search", searchQuery)
            .then((response: any) => {
                setUsers(response.users);
                setPageLength(response.totalPages);
            });
    }, [pageNumber, sortAsc, sortColumn, searchTerm]);

    const changePage = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };

    const setSort = (col: SortColumn) => {
        if (col === sortColumn) {
            setSortAsc(!sortAsc);
        } else {
            setSortAsc(true);
            setSortColumn(col);
        }
    };

    const renderHeader = () => (
        <Row className="d-none d-md-flex border-bottom align-items-center">
            <Col md="3">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.Email)}
                >
                    Email <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="2">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.FirstName)}
                >
                    Forename <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="2">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.Surname)}
                >
                    Surname <i className="fa fa-sort" />
                </button>
            </Col>
            <Col md="3">
                <button
                    type="button"
                    className="bg-transparent p-0 border-0"
                    onClick={() => setSort(SortColumn.Supplier)}
                >
                    Supplier <i className="fa fa-sort" />
                </button>
            </Col>

        </Row>
    );

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }
    return (
        <>
            <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                <Col className="bg-white box-shadow border pb-3">
                    <div className="p-3 mt-2">
                        <h3 className="font-weight-bold">Users Management</h3>
                    </div>
                    <div className="d-flex flex-sm-wrap">
                        <div className="flex-grow w-50">
                            <div className="ml-4 border rounded mb-4 d-flex justify-content-between">
                                <input
                                    type="text"
                                    className="m-2 w-100"
                                    placeholder="Search Users"
                                    value={searchValueChange}
                                    onChange={onChangeSearchTerm}
                                    onKeyDown={handleKeyDown}
                                />
                                <i className={classnames(styles.icon, "fa fa-search mt-1 mr-2 pr-2")} />
                            </div>
                        </div>
                        <div className="ml-2">
                            <Button color="primary" className={classnames(styles.btnSearch)} onClick={handleSearch}>Search </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="px-0 px-md-3 mt-3 flex-shrink-0">
                <Col className="bg-white box-shadow border py-4">
                    {
                        users && users.length === 0
                            ? <p className="m-2 mt-4">No results found</p>
                            : renderHeader()
                    }
                    {
                        users.map(x => (
                            <Row
                                data-testid={x.userId}
                                key={x.userId}
                            >
                                <Col md="3">
                                    <Link
                                        to={`/manage/user/${x.userId}`}
                                        className="text-primary"
                                    >
                                        {x.email}
                                    </Link>
                                </Col>
                                <Col md="2">
                                    {x.firstName}
                                </Col>
                                <Col md="2">
                                    {x.surname}
                                </Col>
                                <Col md="3">
                                    {x.supplierName}
                                </Col>

                            </Row>
                        ))
                    }
                </Col>
            </Row>
            <Paging onChange={changePage} activePage={pageNumber} pageCount={pageLength} />
            <Footer />
        </>
    );
};

export { UserListingPage };
