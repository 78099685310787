import { ParticipantStatus } from "@/FlexPlan/Types/Participant";
import { enumDisplay } from "@/Utils/formatStringHelper";

export const renderParticipantStatusLabel = (status: ParticipantStatus | string): string => {
    if (status === ParticipantStatus.ExpiringOneMonth) return "Expiring < 1 month";
    if (status === ParticipantStatus.ExpiringThreeMonths) return "Expiring < 3 months";
    return enumDisplay(status);
};

export const getColour = (status: ParticipantStatus): string => {
    switch (status) {
        case ParticipantStatus.StandardServiceBooking:
            return "tw-text-blue-600";
        case ParticipantStatus.PlanManagedServiceBooking:
            return "tw-text-violet-600";
        case ParticipantStatus.Active:
            return "tw-text-green-400";
        case ParticipantStatus.Inactive:
            return "tw-text-gray-400";
        case ParticipantStatus.ExpiringOneMonth:
            return "tw-text-red-400";
        case ParticipantStatus.ExpiringThreeMonths:
            return "tw-text-red-300";
        case ParticipantStatus.Expired:
            return "tw-text-orange-600";

        default:
            return "tw-text-green-500";
    }
};
