import { SupplierProfileAction, SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";
import { FlexPlanSupplier, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";

export const supplierProfileReducer = (currentState: FlexPlanSupplier, action: SupplierProfileAction) => {
    switch (action.type) {
        case SupplierProfileActions.Seed: {
            return action.value;
        }
        case SupplierProfileActions.Update: {
            switch (action.field) {
                case "bsb": {
                    const currentBsb = currentState.bsb ?? "";
                    let inputBsbValue = action.value ?? "";
                    if (/^\d{0,3}-?\d{0,3}$/.test(inputBsbValue) || !inputBsbValue) {
                        if (inputBsbValue.length === 3) {
                            if (/^\d{3}-$/.test(currentBsb)) {
                                inputBsbValue = inputBsbValue.slice(0, 2);
                            } else {
                                inputBsbValue += "-";
                            }
                        }
                        if (/^\d{3}-\d{3}$/.test(currentBsb) && /^\d{6}$/.test(inputBsbValue)) {
                            return currentState;
                        }
                        return {
                            ...currentState,
                            [action.field]: inputBsbValue,
                        };
                    }
                    return currentState;
                }
                case "accountNumber": {
                    const inputAccountNumberValue = action.value ?? "";
                    if ((/^\d{0,9}$/.test(inputAccountNumberValue))
                        || !inputAccountNumberValue) {
                        return {
                            ...currentState,
                            [action.field]: inputAccountNumberValue,
                        };
                    }
                    return currentState;
                }
                case "supplierType": {
                    let abn = currentState.abn;
                    if (action.value !== SupplierType.Abn) {
                        // Remove the ABN if we are no longer an ABN supplier
                        abn = "";
                    }
                    return {
                        ...currentState,
                        [action.field]: action.value,
                        abn,
                    };
                }
                default:
                    return {
                        ...currentState,
                        [action.field!]: action.value,
                    };
            }
        }
        default: return currentState;
    }
};
