import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Label } from "reactstrap";
import moment from "moment";

import { TransferCompany } from "@/Apis/TransferCompany";
import { SelectItem } from "@/Apis/SelectItem";
import Textbox from "@/Components/Textbox";
import { isEmail, isEmptyOrSpaces, isValidCompanyNumber, isValidPhoneNumber } from "@/Utils/stringHelper";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import DateTextbox from "@/Components/DateTextbox";
import { countries } from "@/Apis/Countries";
import { AddressType } from "@/Apis/Profile";

interface Props {
    orderId: string,
    loading: boolean;
}

export const TransferCompanyTab = ({ orderId, loading }: Props) => {
    const { get, post } = useAPI({ handle500WithToastMessage: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [formData, setFormData] = useState<TransferCompany>();
    const [sicCodes, setSicCodes] = useState<SelectItem[]>([]);

    const [processing, setProcessing] = useState(false);

    const getSicCodes = () => {
        setProcessing(true);
        get<any>("suaz/sicCodes")
            .then((response) => {
                const sicCodeItems = response?.map(x => ({ value: x.id, label: `${x.description} (${x.id})` }));
                setSicCodes(sicCodeItems);
            })
            .catch(error => {
                setPopupErrorMessage(error, true);
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    useEffect(() => {
        getSicCodes();
    }, []);

    const onTextInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target ? e.target.value : e;
        setFormData({
            ...formData,
            [field]: value,
        } as TransferCompany);
    };

    const onControlInput = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
        } as TransferCompany);
    };

    const onDateInput = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
            formationDateStr: value ? moment(value).format("YYYY/MM/DD") : value,
        } as TransferCompany);
    };

    const isInvalidForm = () => isEmptyOrSpaces(formData?.name)
                                || isEmptyOrSpaces(formData?.companyNumber) || !isValidCompanyNumber(formData?.companyNumber)
                                || isEmptyOrSpaces(formData?.telephoneNumber) || !isValidPhoneNumber(formData?.telephoneNumber)
                                || isEmptyOrSpaces(formData?.formationDate)
                                || isEmptyOrSpaces(formData?.sicCode)
                                || isEmptyOrSpaces(formData?.email) || !isEmail(formData?.email);

    const onSave = () => () => {
        if (!isInvalidForm()) {
            setProcessing(true);
            post(`supplier-orders/${orderId}/user-registration`, formData)
                .then(() => {
                    setSuccessMessage("User registration saved successfully", true);
                })
                .catch(error => {
                    setPopupErrorMessage(error, true);
                })
                .finally(() => {
                    setProcessing(false);
                });
        }
    };

    return (
        <>
            <div>
                <Textbox
                    label="Company Name *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("name")}
                    value={formData?.name}
                    disabled={processing}
                />
                <Textbox
                    label="Company Number *"
                    maxLength={8}
                    className="!tw-mb-2"
                    onChange={onTextInput("companyNumber")}
                    value={formData?.companyNumber}
                    disabled={processing}
                    placeholder="CRN must be 2 letters followed by 6 digits, or a single 0 or 1 followed by 7 digits"
                />
                <Textbox
                    label="Telephone Number *"
                    maxLength={11}
                    className="!tw-mb-2"
                    onChange={onTextInput("telephoneNumber")}
                    value={formData?.telephoneNumber}
                    disabled={processing}
                    placeholder="Telephone number must start with a zero and is followed by exactly 10 digits"
                />
                <DateTextbox
                    label="Formation Date *"
                    changeDate={e => onDateInput("formationDate", e)}
                    currentDate={!formData?.formationDate ? "" : formData?.formationDate}
                    disabled={processing}
                    min={new Date(1900, 0, 1)}
                    max={new Date(2050, 0, 1)}
                />
                <Textbox
                    label="Email *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("email")}
                    value={formData?.email}
                    placeholder="someone@example.com"
                    disabled={processing}
                />
                <Textbox
                    label="Domain"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("domain")}
                    value={formData?.domain}
                    disabled={processing}
                />
                <div className="tw-mb-2">
                    <Label>SIC code *</Label>
                    <Select
                        value={sicCodes?.find(x => x.value === formData?.sicCode)}
                        onChange={e => onControlInput("sicCode", e?.value)}
                        options={sicCodes}
                        isDisabled={processing}
                        isClearable
                    />
                </div>
                <div className="tw-mb-2">
                    <Label>Country Of Registration</Label>
                    <Select
                        value={countries?.map((x) => ({ label: x, value: x }))?.find(x => x.value === formData?.companiesCountryOfRegistration)}
                        onChange={e => onControlInput("companiesCountryOfRegistration", e?.value)}
                        options={countries?.map((x) => ({ label: x, value: x }))}
                        isDisabled={processing}
                        isClearable
                    />
                </div>
                <FloatLabelAddressLookUp
                    customHeading={<div className="tw-text-sm tw-pb-2">Company Address</div>}
                    address={formData?.companiesAddress ?? { premise: "", addressLine2: "", addressLine1: "", postCode: "", city: "" } as AddressType}
                    onSave={onTextInput("companiesAddress")}
                />
            </div>
            <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-3">
                <Button
                    color="primary"
                    onClick={onSave()}
                    disabled={loading || processing || isInvalidForm()}
                    data-testid="btn-save"
                    size="lg"
                >
                    {processing ? (<Spinner className="mx-auto" />) : "Transfer Company"}
                </Button>
            </div>
        </>
    );
};
