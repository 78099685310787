import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Dropzone from "react-dropzone";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import { encodeFilesToBase64 } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";

const DropZoneButton = ({ onChange, showRemoveButton, image, noImageText, containerClasses, imageClasses, accept = acceptedFileTypes.documents, testId, maxSize }) => {
    const [error, setError] = useState("");

    const onDrop = async (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles?.length > 0) {
            let errorMessage = "";
            const errorCode = rejectedFiles[0].errors[0].code;

            switch (errorCode) {
                case "file-too-large":
                    errorMessage = `File cannot be more than ${maxSize / 1000 / 1000} MB`;
                    break;
                case "file-invalid-type":
                    errorMessage = `File type must be ${fileTypesText(accept)}`;
                    break;
                default:
                    errorMessage = "";
            }

            setError(errorMessage);
            return;
        }

        setError("");
        const encodedFile = await encodeFilesToBase64(acceptedFiles);
        onChange({ target: { value: encodedFile[0] } });
    };

    return (
        <div className={classnames(containerClasses || "d-flex align-items-center justify-content-between")}>
            {image ? (
                <div className={classnames(styles.image, imageClasses, "tw-mb-2")}>
                    <Image src={image} alt="logo" />
                </div>
            ) : noImageText}

            <div className="d-flex">
                {showRemoveButton && image && <Button type="button" color="secondary" onClick={onChange} className="mx-2">Remove File</Button>}
                <Dropzone onDrop={onDrop} multiple maxSize={maxSize} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <Button type="button" color="primary" {...getRootProps()}>
                            <input {...getInputProps()} data-testid={testId} />
                            Pick File
                        </Button>
                    )}
                </Dropzone>
            </div>
            {error && (
                <div className="tw-text-red-600 tw-font-semibold tw-mt-1">{error}</div>
            )}
        </div>
    );
};

DropZoneButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    showRemoveButton: PropTypes.bool,
    image: PropTypes.string,
    noImageText: PropTypes.node,
    containerClasses: PropTypes.string,
    imageClasses: PropTypes.string,
    accept: PropTypes.string,
    testId: PropTypes.string,
    maxSize: PropTypes.number,
};

DropZoneButton.defaultProps = {
    showRemoveButton: false,
    testId: "DropZoneButton",
    maxSize: 8000000,
};

export default DropZoneButton;
