import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import { useLocation } from "react-router";

import NoResultsFound from "@/Components/NoResultsFound";
import SupplierTile from "@/Pages/Packages/PackageDirectoryPage/SupplierTile";

const Suppliers = (props) => {
    const location = useLocation();
    const query = queryString.parse(location.search);
    const onSupplierClick = id => () => props.onSupplierClick(id);

    return (
        <Col xs="12" md={query.cart ? "9" : "12"} className={classnames(query.cart && "d-none d-md-flex")}>
            <Row className="flex-grow-1 align-content-start">
                <div className="col-md-12">
                    {props.suppliers.length === 0 ? <NoResultsFound /> : (
                        props.suppliers.map(x => (
                            <SupplierTile
                                key={x.id}
                                id={x.id}
                                name={x.name}
                                description={x.description}
                                categoryName={(x.categories || []).join(", ")}
                                primarySiteColour={x.primarySiteColour}
                                logo={x.logo}
                                rating={x.rating}
                                onSupplierClick={onSupplierClick(x.id)}
                            />
                        )))}
                </div>
            </Row>
        </Col>
    );
};

Suppliers.propTypes = {
    onSupplierClick: PropTypes.func.isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.string).isRequired,
        primarySiteColour: PropTypes.string.isRequired,
        logo: PropTypes.string,
        rating: PropTypes.number.isRequired,
    })),
};

Suppliers.defaultProps = {
    suppliers: [],
};

export default Suppliers;
