import { features } from "@/Utils/features";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";

export interface NavigationItems {
    general: string;
    pricing: string;
    configure: string;
    "more-info": string;
    forms: string;
    upsell: string;
    "post-purchase": string;
}

export interface NavigationType {
    current: keyof NavigationItems;
    next?: keyof NavigationItems;
    previous?: keyof NavigationItems;
    scrollbar: boolean;
    disabled: boolean;
}

const legacyNavigation: Array<NavigationType> = [
    {
        current: "general",
        next: "pricing",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "pricing",
        next: "more-info",
        previous: "general",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "more-info",
        next: "forms",
        previous: "pricing",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "forms",
        next: "upsell",
        previous: "more-info",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "upsell",
        next: "post-purchase",
        previous: "forms",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "post-purchase",
        previous: "upsell",
        scrollbar: true,
        disabled: false,
    },
];

const navigationCustomisedProducts: Array<NavigationType> = [
    {
        current: "general",
        next: "pricing",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "pricing",
        next: "configure",
        previous: "general",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "configure",
        next: "more-info",
        previous: "pricing",
        scrollbar: false,
        disabled: true,
    },
    {
        current: "more-info",
        next: "forms",
        previous: "configure",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "forms",
        next: "upsell",
        previous: "more-info",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "upsell",
        next: "post-purchase",
        previous: "forms",
        scrollbar: true,
        disabled: false,
    },
    {
        current: "post-purchase",
        previous: "upsell",
        scrollbar: true,
        disabled: false,
    },
];

export const getNavigationItems = (productVariant: keyof ProductVariant) => {
    let navigation = legacyNavigation;

    if (features.isEnabled(features.customisedProducts)) {
        navigation = navigationCustomisedProducts;
        (navigation.find(x => x.current === "configure") as NavigationType).disabled = !ProductVariants.canBeCustomised(productVariant);
    }

    return navigation;
};
