import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { useFilterContext } from "@/Context/FilterContext";

interface Props {
    testId?: string,
}

export const FilterToggle = ({ testId } : Props) => {
    const { filterIsShowing, toggleFilters } = useFilterContext();

    const clickShow = () => {
        if (!filterIsShowing) {
            toggleFilters(true);
        }
    };

    const clickHide = () => {
        if (filterIsShowing) {
            toggleFilters(false);
        }
    };

    return (
        <>
            <div>
                <button
                    type="button"
                    onClick={clickShow}
                    disabled={false}
                    id={`${testId}-list`}
                    className={classnames(styles.gridButton, filterIsShowing && "d-none", "border-0")}
                    data-testid={`${testId}-list`}
                >
                    <i
                        className="fa fa-plus"
                    />
                    <span className="ml-1">SHOW FILTERS</span>
                </button>
                <button
                    type="button"
                    onClick={clickHide}
                    disabled={false}
                    id={`${testId}-grid`}
                    className={classnames(styles.gridButton, !filterIsShowing && "d-none", "border-0")}
                    data-testid={`${testId}-grid`}
                >
                    <i
                        className="fa fa-minus"
                    />
                    <span className="ml-1">HIDE FILTERS</span>
                </button>
            </div>
        </>
    );
};
