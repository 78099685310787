import React, { useState, useContext, ReactNode } from "react";

import { useAPI } from "@/Apis/useAPI";
import { SupplierFieldType, SupplierFilterType, SupplierType } from "@/Apis/Suppliers";
import * as globalConstants from "@/Store/Global/constants";
import sortingUtil from "@/Utils/sortingUtil";

export interface SuppliersContextType {
    loading: boolean;
    supplier: SupplierType;
    categories: SupplierFilterType[];
    loadCategoryListing({ filters, searchTerm, supplierId }): void;
    loadMiniSiteWithCategoryListing({ filters, searchTerm, supplierId }): void;
    loadMiniSite(supplierId): void;
    loadSupplierDashboard({ filters }): void;
    loadMiniSiteWithSupplierDashboard({ filters }): void;
    clearSuppliers(): void;
}

const SuppliersContext = React.createContext<SuppliersContextType>({
    loading: true,
    supplier: { } as SupplierType,
    categories: [] as SupplierFilterType[],
    loadCategoryListing: () => {},
    loadMiniSiteWithCategoryListing: () => {},
    loadMiniSite: () => {},
    loadSupplierDashboard: () => {},
    loadMiniSiteWithSupplierDashboard: () => {},
    clearSuppliers: () => {},
});
const useSuppliersContext = () => useContext(SuppliersContext);

const SuppliersContextProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState<SupplierFilterType[]>([]);
    const [supplier, setSupplier] = useState<SupplierType>({ suppliers: [] as SupplierFieldType[], filters: [] as SupplierFilterType[], supplierMiniSite: { } } as SupplierType);
    const { get } = useAPI({ handle500WithRedirect: true });

    const buildCommon = (urlStart, payload) => {
        let url = urlStart;
        if (payload.supplierId) {
            url += `&supplierId=${payload.supplierId}`;
        }
        if (payload.filters.length > 0) {
            url += `&categoryIds=${payload.filters.join("&categoryIds=")}`;
        }
        if (payload.searchTerm) {
            url += `&searchTerm=${payload.searchTerm}`;
        }
        return url;
    };

    const clearSuppliers = () => {
        setLoading(true);
        setSupplier((currentState) => ({
            ...currentState,
            suppliers: [],
        }));
    };
    const setMiniSiteAndSuppliers = (response) => {
        const categoryList = [...response.filters.filter(x => x.name !== globalConstants.ENQUIRY_FORM)];

        setCategories(categoryList.sort((x, y) => sortingUtil.sort(x, y, "name", true)));

        setSupplier((currentState) => ({
            ...currentState,
            suppliers: [...response.suppliers],
            filters: [...response.filters.filter(x => x.name !== globalConstants.ENQUIRY_FORM)],
            supplierMiniSite: {
                ...currentState.supplierMiniSite,
                logo: undefined,
                description: undefined,
                ...response.supplierMiniSite,
            },
        }));
    };
    const setSupplierMiniSite = (response) => {
        setSupplier((currentState) => ({
            ...currentState,
            supplierMiniSite: {
                ...response,
            },
        }));
    };
    const buildCategoryListingUrl = payload => buildCommon("suppliers/categorylisting?", payload);
    const buildSupplierDirectoryUrl = payload => buildCommon("suppliers/directory?", payload);

    const loadCategoryListing = (payload) => {
        get<SupplierType>(buildCategoryListingUrl(payload))
            .then(response => {
                setMiniSiteAndSuppliers(response);
                setLoading(false);
            });
    };

    const loadMiniSite = (supplierId) => {
        get<SupplierType>(`suppliers/${supplierId}`)
            .then(response => {
                setSupplierMiniSite(response);
                setLoading(false);
            });
    };

    const loadSupplierDashboard = (payload) => {
        get<SupplierType>(buildSupplierDirectoryUrl(payload))
            .then(response => {
                setMiniSiteAndSuppliers(response);
                setLoading(false);
            });
    };

    return (
        <SuppliersContext.Provider value={
            {
                loading,
                supplier,
                categories,
                loadCategoryListing,
                loadMiniSite,
                loadSupplierDashboard,
                clearSuppliers,
            } as SuppliersContextType
        }
        >
            {children}
        </SuppliersContext.Provider>);
};

export { useSuppliersContext, SuppliersContextProvider };
