import { v4 as uuid } from "uuid";

import { PreviousPackageSelectItem } from "@/Apis/Receipts/Create/PreviousReceiptsDropdown";
import { EncodedFile } from "@/Utils/base64EncodeHelper";

export enum ReceiptType {
    Quotation = 1,
    Invoice = 2,
}

export interface CreateReceiptFormFields {
    id: string,
    recipient: ReceiptRecipient
    paymentType: ReceiptPaymentType,
    lines: ReceiptLineItem[],
    savePackage: boolean,
    packageName: string,
    packageDescription: string,
    previousPackageSelected: PreviousPackageSelectItem,
    attachments: ReceiptAttachment[],
    referenceNumber: number | undefined,
    receiptType: ReceiptType,
}

interface ReceiptItemBase extends CalculateLineItemTotal {
    id: string,
    itemCode: string,
    name: string,
    description: string,
    receiptUnit: number,
    isSaved: boolean,
    isAddNew: boolean,
    changeVatPercentage: boolean,
    setRecurrence: boolean,
    receiptRecurrenceFrequency: ReceiptRecurrenceFrequency,
    changeDelayPayment: boolean,
    delayPaymentValue: number,
    receiptDelayPayment: number,
    changeHexagonRewardPercentage: boolean,
    hexagonRewardPercentage: number,
    receiptType: ReceiptType,
}

export interface ReceiptLineItem extends ReceiptItemBase {
    receiptId?: string | undefined,
    isPackageItem?: boolean,
    originalId?: string, // We need to track the original Id of the line as we can change the saved item
}

export interface ReceiptSavedItem extends ReceiptItemBase {
    supplierId: string,
}

// Receipt Details Form

export enum ReceiptPaymentType {
    Online = 1,
    Offsite = 2,
}

export const defaultReceiptLines: ReceiptLineItem[] = [{
    id: uuid(),
    itemCode: "",
    name: "",
    description: "",
    pricing: 0,
    discountPercentage: 0,
    receiptUnit: 1,
    quantity: 0,
    hasVat: true,
    isSaved: false,
    isAddNew: true,
    changeVatPercentage: false,
    vatPercentage: 20,
    setRecurrence: false,
    receiptRecurrenceFrequency: 0,
    changeDelayPayment: false,
    delayPaymentValue: 0,
    receiptDelayPayment: 0,
    changeHexagonRewardPercentage: false,
    hexagonRewardPercentage: 0,
    receiptType: ReceiptType.Quotation,
    isPackageItem: false, // Has the line in the form come from a previous package?
}];

export const defaultReceiptForm: CreateReceiptFormFields = {
    paymentType: ReceiptPaymentType.Online,
    recipient: { firstName: "", surname: "", email: "" } as ReceiptRecipient,
    lines: defaultReceiptLines,
    savePackage: false,
    attachments: [] as ReceiptAttachment[],
} as CreateReceiptFormFields;

export interface CalculateLineItemTotal {
    quantity: number,
    pricing: number,
    discountPercentage: number, // This should be the discount percentage e.g. 25
    vatPercentage: number, // This should be the VAT percentage e.g. 10
    hasVat: boolean
}

export interface ReceiptRecipient {
    id: string,
    firstName: string,
    surname: string,
    email: string
}

export interface CreateReceiptResponse {
    id: number
}

export enum ReceiptRecurrenceFrequency {
    EmptySelection = 0,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4,
}

export interface ReceiptAttachment extends EncodedFile {
    id: string,
    isTermsAndConditions: boolean
}
