import { useEffect, useState } from "react";

import { Note, NoteType } from "@/FlexPlan/Types/Notes";
import { useAPI } from "@/Apis/useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { exceedsMaxStringLength } from "@/Utils/stringHelper";
import { useForm } from "@/Hooks/useForm";
import { NoteModalType } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Types";
import { endOfDate } from "@/FlexPlan/Utils/date";

const useNotes = (noteType: NoteType, entityId: string) => {
    const { get, loading, post } = useAPI({ handle500WithToastMessage: true });
    const [error, setError] = useState<string>();

    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(prev => !prev);
    const [notes, setNotes] = useState<Note[]>();

    const {
        onChange,
        formState,
        setInvalidField,
        invalidFields,
        clearField,
        onDateTextboxChange,
        onDebounceSearch,
        setInvalidFields,
    } = useForm<NoteModalType>({ search: "", description: "" } as NoteModalType);

    const [reloadDataToggle, setReloadDataToggle] = useState<boolean>(false);
    const reloadData = () => setReloadDataToggle(prev => !prev);

    useEffect(() => {
        setNotes([]);
        get<Note[]>(FlexPlanUrls.notes.get({
            noteType,
            entityId,
            pageNumber: 0,
            pageLength: 5,
            ...formState,
            dateTo: formState.dateTo ? endOfDate(formState.dateTo) : "",
        }))
            .then(setNotes)
            .catch(error => {
                if (typeof error === "string") {
                    setError(error);
                }

                if (error.validationFailed) { // Validation errors
                    setInvalidFields(error.errors);
                }
            });
    }, [formState.search, formState.dateFrom, formState.dateTo, entityId, noteType, reloadDataToggle]);

    // Adding a new note

    const hasErrors = (): boolean => {
        let errorFound: boolean = false;

        if (!formState.description || exceedsMaxStringLength(formState.description)) {
            errorFound = true;
            setInvalidField("description");
        }

        return errorFound;
    };

    const onSubmit = () => {
        if (hasErrors()) {
            return;
        }

        post(FlexPlanUrls.notes.base, {
            description: formState.description,
            noteType,
            entityId,
        })
            .then(() => {
                toggleModal();
                clearField("description"); // Reset the search
                reloadData();
            })
            .catch(error => setError(error));
    };

    return {
        error,
        onDebounceSearch,
        notes,
        formState,
        onChange,
        loading,
        open,
        invalidFields,
        toggleModal,
        onSubmit,
        onDateTextboxChange,
    };
};

export { useNotes };
