import React from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import RadioButton from "@/Components/RadioButton";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { closeFilterValues } from "@/Utils/orderStatusHelper";

const OrderSearch = (
    {
        toggleStatusFilter,
        toggleDateFilter,
        onSearch,
        dateFilters,
        statusFilters,
        toggleStatusRadio,
        currentDateFilter,
        currentStatusFilters,
        showSortModal,
        showFilterModal,
        toggleSubscriptions,
        includeSubscriptions,
    },
) => {
    const allFilterValues = Object.keys(statusFilters).map(x => statusFilters[x]);
    return (
        <div className="my-3 d-flex flex-column flex-md-row align-items-md-center">
            <div className="d-flex">
                <RadioButton
                    className="mr-4"
                    label="All"
                    id="all"
                    checked={currentStatusFilters.length === allFilterValues.length}
                    value=""
                    onChange={toggleStatusRadio(allFilterValues)}
                />
                <RadioButton
                    className="mr-4"
                    id="open"
                    label="Open"
                    checked={closeFilterValues.every(x => !currentStatusFilters.includes(x))}
                    onChange={toggleStatusRadio(allFilterValues.filter(x => !closeFilterValues.includes(x)))}
                />
                <RadioButton
                    className="mr-4"
                    id="closed"
                    label="Closed"
                    checked={closeFilterValues.every(x => currentStatusFilters.includes(x)) && currentStatusFilters.length === 2}
                    onChange={toggleStatusRadio(closeFilterValues)}
                />
            </div>
            <div className="d-flex justify-content-md-end mt w-100 mt-3 mt-md-0">
                <DebounceTextbox
                    placeholder="Search Orders"
                    callBack={onSearch}
                    className={classnames(styles.searchBox, "mb-0")}
                />
                <div className={classnames(styles.filterButton)}>

                    <UncontrolledDropdown direction="down" className="pl-2 d-none d-md-block">
                        <DropdownToggle className={classnames(styles.filterButton, "bg-transparent border-0 px-3 py-2 text-dark")}>
                            <i className="fa fa-filter" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {Object.keys(dateFilters)
                                .map((x) => {
                                    const filter = dateFilters[x];
                                    return (
                                        <DropdownItem
                                            key={filter}
                                            value={filter}
                                            onClick={() => toggleDateFilter(filter)}
                                            className={classnames(currentDateFilter === filter && "text-primary")}
                                        >
                                            {x}
                                        </DropdownItem>
                                    );
                                })}
                            <DropdownItem divider />
                            <DropdownItem className="d-flex">
                                <Checkbox
                                    id="subscriptions"
                                    value="subscriptions"
                                    onChange={toggleSubscriptions}
                                    checked={includeSubscriptions}
                                /> Subscriptions
                            </DropdownItem>
                            <DropdownItem divider />
                            {Object.keys(statusFilters)
                                .map((x) => {
                                    const statusFilter = statusFilters[x];
                                    return (
                                        <DropdownItem className="d-flex" key={statusFilter}>
                                            <Checkbox
                                                id={statusFilter.toString()}
                                                value={statusFilter}
                                                onChange={() => toggleStatusFilter(statusFilter)}
                                                checked={currentStatusFilters.includes(statusFilter)}
                                            /> {x}
                                        </DropdownItem>
                                    );
                                })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <Button
                    className={classnames(styles.filterButton, "bg-transparent border-0 px-3 py-2 text-dark d-md-none")}
                    onClick={() => showFilterModal(true)}
                >
                    <i className="fa fa-filter" />
                </Button>
                <Button
                    className={classnames(styles.sortButton, "bg-transparent border-0 px-3 py-2 text-dark d-md-none")}
                    onClick={() => showSortModal(true)}
                >
                    <i className="fa fa-sort" />
                </Button>
            </div>
        </div>
    );
};

OrderSearch.propTypes = {
    toggleStatusFilter: PropTypes.func.isRequired,
    toggleDateFilter: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    dateFilters: PropTypes.shape({}).isRequired,
    statusFilters: PropTypes.shape({}).isRequired,
    toggleStatusRadio: PropTypes.func.isRequired,
    currentDateFilter: PropTypes.number,
    currentStatusFilters: PropTypes.arrayOf(PropTypes.string),
    showSortModal: PropTypes.func,
    showFilterModal: PropTypes.func,
    toggleSubscriptions: PropTypes.func.isRequired,
    includeSubscriptions: PropTypes.bool.isRequired,
};

OrderSearch.defaultProps = {
    currentStatusFilters: [],
    showSortModal: () => {},
    showFilterModal: () => {},
};

export { OrderSearch };
