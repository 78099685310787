import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
    onChange(value: string): void;
    value: string;
    placeholder?: string;
    disabled?: boolean;
}

const FloatLabelSearchTextBox = ({ placeholder, onChange, disabled, value }: Props) => {
    const onClear = () => onChange("");
    const onInputChange = (e) => onChange(e.target.value);

    return (
        <div className={classnames(styles.floatLabel)}>
            <i className={classnames(styles.searchIcon, "fa fa-search mr-2")} />
            <input
                className="rounded-top"
                placeholder={placeholder}
                onChange={onInputChange}
                disabled={disabled}
                value={value}
            />
            <button className={styles.clearIcon} type="button" onClick={onClear}>
                <i className="fas fa-times mr-2" />
            </button>

        </div>
    );
};

export { FloatLabelSearchTextBox };
