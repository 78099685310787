import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Col, Media, Row } from "reactstrap";

import styles from "./styles.module.scss";

import { AuthenticationPage } from "@/Pages/AuthenticationPage";
import { features } from "@/Utils/features";
// eslint-disable-next-line import/extensions
import FlexPlanLogo from "@/FlexPlan/Assets/Images/flex-plan-logo.svg";

const LoginBackgroundWrapper = props => (
    <AuthenticationPage hideFooter={features.isEnabled(features.FlexPlan)}>
        <Row className={classnames(styles.container, "flex-column justify-content-center align-items-center flex-grow-1")}>
            <Col xs="11" md="10" lg="8" className={classnames(styles.content, "p-3 p-md-4 pb-md-5 my-3")}>
                <div className={classnames(styles.logoContainer, "pt-3")}>
                    {features.isEnabled(features.FlexPlan)
                        ? (<div className="tw-text-flexplan-primary tw-mx-auto"><FlexPlanLogo className="tw-w-64" /></div>)
                        : (<Media object src={`${window.cdnUrl}misc/logo-with-text.jpg`} alt="logo" className="mx-auto w-100" />)}
                </div>
                {props.children}
            </Col>
        </Row>
    </AuthenticationPage>
);

LoginBackgroundWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LoginBackgroundWrapper;
