import parse from "html-react-parser";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { createEditorState } from "@/Components/RichTextEditor/Utils";

export const convertJSONRichTextToHTML = (jsonString: string | undefined) => {
    const editorState = createEditorState(jsonString);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    return parse(markup);
};

export const convertJSONRichTextToPlainText = (jsonString: string | undefined): string | undefined => {
    if (!jsonString) return undefined;

    const editorState = createEditorState(jsonString);
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    if (!rawContentState.blocks || rawContentState.blocks.length === 0) return undefined;

    const textJoined = rawContentState.blocks.map(x => x.text).join("\n");

    return textJoined.length === 0 ? undefined : textJoined.replace(/^\s*[\r\n]/gm, "").trim();
};
