import React from "react";

import PostPurchaseYellowAccentButton from "@/Components/PostPurchase/PostPurchaseYellowAccentButton";
import Spinner from "@/Components/Spinner";
import { ProductType } from "@/Apis/Products/ProductType";

interface Props {
    product: ProductType,
    className?: string,
    buttonClasses?: string,
    onViewAttachments: () => void,
    viewAttachmentsLoading?: boolean,
}

const PostPurchaseProductButtons = ({ product, className, buttonClasses, onViewAttachments, viewAttachmentsLoading = false }: Props) => (
    <div className={className}>
        <PostPurchaseYellowAccentButton to={`/product/${product.slugId}/${product.slug}`} className={buttonClasses}>
            <div className="tw-text-sm">View Product Details</div>
        </PostPurchaseYellowAccentButton>
        <PostPurchaseYellowAccentButton onClick={onViewAttachments} disabled={viewAttachmentsLoading} className={buttonClasses}>
            {viewAttachmentsLoading ? <Spinner className="mx-auto" size="22" /> : <div className="tw-text-sm">View Attachments</div>}
        </PostPurchaseYellowAccentButton>
    </div>
);

export default PostPurchaseProductButtons;
