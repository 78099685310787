import React from "react";

import LoginBackgroundWrapper from "@/Wrappers/LoginBackgroundWrapper";

const ForbiddenPage = () => (
    <LoginBackgroundWrapper>
        <div className="tw-text-base">
            You are not authorized to view this page.
        </div>
    </LoginBackgroundWrapper>
);

export { ForbiddenPage };
