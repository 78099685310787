import React from "react";
import { Switch, Route } from "react-router-dom";

import PackageWelcomePage from "./PackageWelcomePage";
import { PackageDirectoryPage } from "./PackageDirectoryPage";
import RedeemPackagesPage from "./RedeemPackagesPage";
import ManagePackagesPage from "./ManagePackagesPage";

import PrivateRoute from "@/Pages/PrivateRoute";

const Packages = () => (
    <Route
        path="/packages"
        render={({ match: { url } }) => (
            <Switch>
                <Route exact path={`${url}/redeem`} component={RedeemPackagesPage} />
                <PrivateRoute exact path={`${url}/package-directory/:id`} component={PackageDirectoryPage} />
                <PrivateRoute path={`${url}/package-directory`} component={PackageDirectoryPage} />
                <PrivateRoute path={`${url}/manage`} component={ManagePackagesPage} />

                <PrivateRoute path={`${url}/`} component={PackageWelcomePage} />
            </Switch>
        )}
    />
);

export default Packages;
