import React from "react";
import Select from "react-select";
import { Button, FormFeedback, FormGroup, Label } from "reactstrap";
import classnames from "classnames";

import Textbox from "@/Components/Textbox";
import { SelectItem } from "@/Apis/SelectItem";
import { FlexPlanRoles, flexPlanRoleToLabel } from "@/FlexPlan/Types/Roles";
import { LoqateAddress } from "@/FlexPlan/Types/Address";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";

interface Props {
    id: string, // This ID is used only in the client side - Allowing us to update state correctly
    areaTitle: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    address: LoqateAddress | null,
    role: string,
    onChange: (contactId: string, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void,
    displayAddAnotherContactButton: boolean,
    onAddNewContact: () => void,
    className?: string,
    invalidFields: any,
    onAddressSaved: (contactId: string, address: LoqateAddress) => void,
    onAddressChanged: (contactId: string) => void,
}

const roleOptions = Object.keys(FlexPlanRoles).map(x => ({
    label: flexPlanRoleToLabel(FlexPlanRoles[x]),
    value: FlexPlanRoles[x],
} as SelectItem));

const ContactForm = ({
    id,
    areaTitle = "MAIN CONTACT",
    firstName,
    lastName,
    email,
    phone,
    address,
    role,
    onChange,
    displayAddAnotherContactButton,
    onAddNewContact,
    className = "",
    invalidFields,
    onAddressSaved,
    onAddressChanged,
}: Props) => (
    <div className={classnames("tw-border-2 tw-p-4 md:tw-p-8", className)}>
        <div className="tw-text-lg tw-font-semibold">{areaTitle}</div>
        <Textbox
            className="tw-mt-5 tw-text-base"
            label="First Name *"
            onChange={onChange(id, "firstName")}
            value={firstName}
            error={invalidFields && invalidFields[`${id}-firstName`] && "Please enter a valid First name. Must be less than 255 characters."}
        />
        <Textbox
            label="Surname *"
            className="tw-text-base"
            onChange={onChange(id, "lastName")}
            value={lastName}
            error={invalidFields && invalidFields[`${id}-lastName`] && "Please enter a valid Surname. Must be less than 255 characters."}
        />
        <Textbox
            label="E-mail Address *"
            className="tw-text-base"
            onChange={onChange(id, "email")}
            value={email}
            error={invalidFields && invalidFields[`${id}-email`] && "Please enter a valid E-mail Address"}
        />
        <Textbox
            label="Phone *"
            className="tw-text-base"
            onChange={onChange(id, "phone")}
            value={phone}
            error={invalidFields && invalidFields[`${id}-phone`] && "Please enter a valid Phone number"}
        />
        <FloatLabelAddressLookUp
            customHeading={<div className="tw-text-base tw-pb-2">Address *</div>}
            address={address}
            onSave={address => onAddressSaved(id, address)}
            onStateChange={() => onAddressChanged(id)}
            editButtonText="Choose Address"
            buttonClasses="!tw-text-base"
            error={invalidFields && invalidFields[`${id}-address`] && "Address is required"}
            errorClasses="font-weight-bold !tw-text-bootstrap-error-size tw-mt-2"
        />
        <FormGroup>
            <Label className="tw-text-base">Role *</Label>
            <Select
                value={roleOptions.find(x => x.value === role)}
                onChange={e => onChange(id, "role")({ target: { value: e.value } } as any)}
                options={roleOptions}
            />
            {invalidFields && invalidFields[`${id}-role`] && (
                <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                    Role is required
                </FormFeedback>
            )}
            {invalidFields && invalidFields.planManagerRequired && (
                <FormFeedback className="font-weight-bold !tw-block !tw-text-bootstrap-error-size !tw-mt-2">
                    At least one contact is required to have the role of Plan Manager
                </FormFeedback>
            )}
        </FormGroup>
        {displayAddAnotherContactButton && (
            <div className="tw-flex tw-mt-3">
                <Button
                    color="primary"
                    type="button"
                    onClick={onAddNewContact}
                    className="tw-ml-auto !tw-text-base"
                >
                    Add Another Contact
                </Button>
            </div>
        )}
    </div>
);

export { ContactForm };
