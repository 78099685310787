/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import UrgentIcon from "@/Assets/Icons/Coloured/urgent.svg";
import CloseIcon from "@/Assets/Icons/Solid/close.svg";
import ImageIcon from "@/Assets/Icons/Solid/image.svg";
import AttachmentIcon from "@/Assets/Icons/Solid/attach-file.svg";

class Tag extends React.PureComponent {
    getIcon = (name, useAttachmentIcon) => {
        if (name === "Urgent") {
            return <UrgentIcon />;
        }
        if (name.endsWith("jpg") || name.endsWith("jpeg") || name.endsWith("png")) {
            return <ImageIcon />;
        }
        if (useAttachmentIcon) {
            return <AttachmentIcon />;
        }
        return false;
    };

    render() {
        if (this.props.url) {
            return (
                <a
                    href={this.props.url}
                    download={this.props.name}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classnames(styles.container, styles.link, "mb-1 text-decoration-none", this.props.classnames)}
                >
                    {this.props.icon ? this.props.icon : this.getIcon(this.props.name, this.props.useAttachmentIcon)}
                    <span>{this.props.name}</span>
                    {this.props.displayClose && <button type="button" className={styles.close} onClick={this.props.onClose}><CloseIcon /></button>}
                </a>
            );
        }

        return (
            <div className={classnames(styles.container, "mb-1", this.props.classnames)}>
                {this.props.icon ? this.props.icon : this.getIcon(this.props.name, this.props.useAttachmentIcon)}
                <span>{this.props.name}</span>
                {this.props.displayClose && <button type="button" className={styles.close} onClick={this.props.onClose}><CloseIcon /></button>}
            </div>
        );
    }
}

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    classnames: PropTypes.string,
    icon: PropTypes.node,
    url: PropTypes.string,
    useAttachmentIcon: PropTypes.bool,
    displayClose: PropTypes.bool,
    onClose: PropTypes.func,
};

export default Tag;
