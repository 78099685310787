import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Button } from "reactstrap";

import styles from "./styles.module.scss";

import * as CookiesApi from "@/Apis/Cookies";
import { getCookiesAccepted, setCookiesAccepted, removeCookiesAccepted } from "@/Utils/localStorage";

const CookieConfirmation = () => {
    const [showCookies, setShowCookies] = useState(true);
    const loggedIn = useSelector<any>(state => state.user.isLoggedIn);

    useEffect(() => {
        if (loggedIn) {
            CookiesApi.getHasAccepted()
                .then(({ isVerified }) => {
                    setShowCookies(!isVerified);
                });
        } else {
            const accepted = getCookiesAccepted();
            setShowCookies(accepted === undefined);
        }
    }, [loggedIn]);

    const onAccepted = () => {
        CookiesApi.accept()
            .then(() => {
                setCookiesAccepted();
                setShowCookies(false);
            })
            .catch(() => {
                setShowCookies(true);
                removeCookiesAccepted();
            });
    };

    return (
        <>
            {showCookies && (
                <footer className={classnames(styles.container, "text-center p-4")}>
                    <h5 className="text-white d-inline-block">
                        Our site uses cookies to give you the best experience. To find out more&nbsp;
                        <a href="/cookie-policy" rel="noopener noreferrer" target="_blank" className="text-warning">click here</a>
                    </h5>
                    <Button color="primary" className={classnames(styles.button, "mx-4 px-4 mt-2 mt-lg-0")} onClick={onAccepted} data-testid="accept-cookies">
                        I accept
                    </Button>
                </footer>
            )}
        </>
    );
};

export { CookieConfirmation };
