import api from "@/Utils/api";

const generateQuote = (command) => api.post("bespokequotes", command);

const updateQuote = (command) => api.put("bespokequotes", command);

const removeQuote = (id) => api.del("bespokequotes", { quoteId: id });

const acceptQuote = (id) => api.post("bespokequotes/accept", { quoteId: id });

const rejectQuote = (id) => api.post("bespokequotes/reject", { quoteId: id });

const sendRejectQuoteReason = (id, reason) => api.post("bespokequotes/reject/reason", { quoteId: id, description: reason });

const proceedQuote = (id) => api.post("bespokequotes/proceed", { quoteId: id });

export {
    generateQuote,
    updateQuote,
    removeQuote,
    acceptQuote,
    rejectQuote,
    sendRejectQuoteReason,
    proceedQuote,
};
