/* eslint-disable import/extensions */
import React, { useState } from "react";
import { Button } from "reactstrap";

import Logo from "@/Assets/Icons/Solid/logo.svg";
import Textbox from "@/Components/Textbox";
import Spinner from "@/Components/Spinner";
import Modal from "@/Modals/Modal";
import { CreateReceiptFormFields } from "@/Apis/Receipts/Create";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";

interface Props {
    isOpen: boolean,
    onClose: () => void,
    setFormValues: React.Dispatch<React.SetStateAction<CreateReceiptFormFields>>,
    formValues: CreateReceiptFormFields,
    onSuccess: () => void,
}

const CreateCustomerAccountModal = ({ isOpen, onClose, setFormValues, formValues, onSuccess }: Props) => {
    const { post, loading } = useAPI({ handle500WithToastMessage: true });
    const [errors, setErrors] = useState<{ firstName?: any, lastName?: any, email?: any }>();
    const { setSuccessMessage } = useToastMessageContext();

    const onChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            recipient: {
                ...formValues.recipient,
                [field]: e.target.value,
            },
        });

        setErrors({
            ...errors,
            [field]: "",
        });
    };

    const onSubmit = () => {
        post(Urls.account.createCustomer, {
            firstName: formValues.recipient.firstName,
            lastName: formValues.recipient.surname,
            email: formValues.recipient.email,
        })
            .then(response => {
                setSuccessMessage(`User ${formValues.recipient.email} created successfully.`, true);

                setFormValues({
                    ...formValues,
                    recipient: {
                        ...formValues.recipient,
                        id: response.profileMetadata.userId,
                    },
                });

                onSuccess(); // Register success
                onClose(); // Close the modal
            })
            .catch(response => {
                if (response.validationFailed) {
                    setErrors({
                        email: response.errors.email,
                        firstName: response.errors.firstName,
                        lastName: response.errors.lastName,
                    });
                }
            });
    };

    return (
        <Modal isOpen={isOpen} size="md">
            <div className="tw-text-brand-primary tw-h-16 tw-w-16 tw-mx-auto" data-testid="logo">
                <Logo />
            </div>
            <div className="tw-flex tw-justify-center tw-mb-3 tw-mt-2">
                <h3 className="tw-text-brand-primary tw-mx-auto tw-font-bold">Create Customer Account</h3>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center">
                {loading
                    ? <Spinner />
                    : (
                        <>
                            <Textbox
                                placeholder="First Name"
                                value={formValues.recipient.firstName}
                                onChange={onChange("firstName")}
                                className="tw-w-full"
                                error={errors?.firstName}
                                autoFocus
                                data-testid="firstName"
                            />
                            <Textbox
                                placeholder="Last Name"
                                value={formValues.recipient.surname}
                                error={errors?.lastName}
                                onChange={onChange("surname")}
                                className="tw-w-full"
                                data-testid="lastName"
                            />
                            <Textbox
                                placeholder="Email"
                                value={formValues.recipient.email}
                                error={errors?.email}
                                onChange={onChange("email")}
                                className="tw-w-full"
                                data-testid="email"
                            />
                        </>
                    )}
            </div>
            <div className="tw-flex tw-space-x-4 tw-w-full tw-justify-center tw-mt-4">
                <Button color="primary" size="lg" name="Proceed" disabled={loading} onClick={onSubmit} data-testid="submit-button">
                    Proceed
                </Button>
                <Button
                    color="primary"
                    size="lg"
                    outline
                    name="Cancel"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </div>
        </Modal>
    );
};

export default CreateCustomerAccountModal;
