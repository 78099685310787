/* eslint-disable import/extensions */
import React from "react";
import { Modal, ModalBody } from "reactstrap";

import NoImageProduct from "@/Assets/Images/no-image-product.png";
import { ProductType } from "@/Apis/Products/ProductType";
import CloseButton from "@/Components/CloseButton";
import { Image } from "@/Components/Image";
import { PricingPopover } from "@/Components/PricingPopover";
import PostPurchaseProductButtons from "@/Components/PostPurchase/PostPurchaseProduct/PostPurchaseProductButtons";

interface Props {
    isOpen: boolean,
    toggle: () => void,
    product: ProductType,
    children: React.ReactNode | string,
    onViewAttachments: () => void,
    viewAttachmentsLoading: boolean,
}

const PostPurchaseReadMoreModal = ({ isOpen, toggle, product, children, onViewAttachments, viewAttachmentsLoading }: Props) => (
    <Modal isOpen={isOpen} centered size="lg">
        <ModalBody className="!tw-p-8" data-testid="read-more-modal-body">
            <CloseButton onClick={toggle} />
            <div className="tw-flex tw-flex-col tw-space-y-4">
                <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-7 tw-gap-3">
                    <div className="lg:tw-col-span-3">
                        <Image
                            src={product.imageThumbnail ?? product.image ?? NoImageProduct}
                            className="tw-w-full tw-h-56"
                            alt="Product's thumnail"
                        />
                    </div>
                    <div className="lg:tw-col-span-4 tw-flex tw-flex-col">
                        <span className="tw-text-lg tw-font-semibold">{product.name}</span>
                        <div className="tw-w-full">
                            <span className="tw-text-brand-primary tw-text-sm">
                                {product.supplierName}
                            </span> • <span className="tw-text-sm">{product.categoryName}</span>
                        </div>
                        <PricingPopover
                            productId={product.id}
                            initialChargeWithVatIfApplicable={product.initialChargeWithVatIfApplicable}
                            initialCharge={product.initialCharge}
                            paymentFrequency={product.paymentFrequency ?? "N/A"}
                            postageCharge={product.postageCharge}
                            minimumQuantity={product.quantityOrdered}
                            isVatRequired={product.isVatRequired}
                            componentName="tile"
                            hasNAProducts={product.hasNAProducts}
                            isOffsitePayment={product.isOffsitePayment}
                            tileDisplay
                            isDelayedPayment={product.isDelayedPayment}
                            delayedPaymentFor={product.delayedPaymentFor}
                            delayedPaymentPeriod={product.delayedPaymentPeriod}
                        />
                        <p className="tw-text-base tw-mt-2">
                            {product.description}
                        </p>
                    </div>
                </div>
                <div className="tw-text-sm">{children}</div>
                <PostPurchaseProductButtons
                    product={product}
                    className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-space-y-5 lg:tw-space-y-0 lg:tw-space-x-3"
                    onViewAttachments={onViewAttachments}
                    viewAttachmentsLoading={viewAttachmentsLoading}
                    buttonClasses="tw-w-full"
                />
                <div className="tw-w-full tw-text-brand-primary tw-text-sm !tw-mt-auto tw-text-center tw-p-4">
                    Order Ref. <span className="tw-text-base tw-font-semibold">{product.orderReference}</span>
                </div>
            </div>
        </ModalBody>
    </Modal>
);
export default PostPurchaseReadMoreModal;
