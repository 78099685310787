import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, Label } from "reactstrap";

import styles from "./styles.module.scss";

const FloatLabelPhone = props => {
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [isValidNumberFailed, setIsValidNumberFailed] = useState(false);
    const [canValidate, setCanValidate] = useState(props.validateOnMount);

    const isValid = (value: string) => {
        const regex = new RegExp(props.regex);
        return (regex.test(value));
    };

    useEffect(() => {
        if (canValidate) {
            setIsRequiredValidationFailed(props.isRequired && !props.value);
            setIsValidNumberFailed(props.value && !isValid(props.value));
        } else if (isRequiredValidationFailed) {
            setIsRequiredValidationFailed(false);
        } else if (isValidNumberFailed) {
            setIsValidNumberFailed(false);
        }
    }, [canValidate, isRequiredValidationFailed, isValidNumberFailed, props.value, props.isRequired]);

    const onBlur = useCallback(() => {
        setCanValidate(true);
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props]);

    function onValueChanged(e) {
        if (props.onChange) {
            props.onChange(e, !(e.target.value && !isValid(e.target.value)));
        }
    }

    return (
        <FormGroup className={classnames(props.className, "text-box", props.isRequired && "text-box-required")}>
            <div className={classnames(styles.floatLabel, props.className, props.error || isRequiredValidationFailed || isValidNumberFailed ? styles.error : "")}>
                {props.label && <Label>{props.label}</Label>}
                <input
                    key={props.disabled ? "disabled" : "enabled"}
                    onKeyUp={props.onKeyUp}
                    type={props.type}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value || ""}
                    onChange={onValueChanged}
                    disabled={props.disabled}
                    onBlur={onBlur}
                    onFocus={props.onFocus}
                    style={props.inputStyle}
                    data-testid={props["data-testid"] || ""}
                />
                {!props.formFeedbackTop && (props.error || isRequiredValidationFailed) && (
                    <div className={styles.errorMessage}>{props.error || (isRequiredValidationFailed && <>{props.isRequiredError}</>)}</div>
                )}
                {!props.formFeedbackTop && isValidNumberFailed && (
                    <div className={styles.errorMessage}>{(isValidNumberFailed && <>{props.isInvalidError}</>)}</div>
                )}
            </div>
        </FormGroup>
    );
};

FloatLabelPhone.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    isRequired: PropTypes.bool,
    isInvalidError: PropTypes.string,
    isRequiredError: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputStyle: PropTypes.object,
    "data-testid": PropTypes.string,
    validateOnMount: PropTypes.bool,
    formFeedbackTop: PropTypes.bool,
    regex: PropTypes.string,
};

FloatLabelPhone.defaultProps = {
    disabled: false,
    type: "text",
    name: "",
    isRequired: false,
    isRequiredError: "This field is required",
    isInvalidError: "Number is invalid",
    validateOnMount: false,
    formFeedbackTop: false,
    regex: "^[+]?[0-9]{9,12}$", // 0 or 1 '+', followed by 9-12 digits
};

export default FloatLabelPhone;
