import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { useProducts } from "@/Pages/Marketplace/MarketplacePage/useProducts";
import { useBasketContext } from "@/Context/BasketContext";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductType } from "@/Apis/Products/ProductType";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import Spinner from "@/Components/Spinner";

interface Props {
    heading: string,
    pageLength: number,
    isYouMightLike: boolean,
    userTypeId?: string,
    isRefreshList?: boolean,
}

const UpsellProductListing = ({ heading, pageLength, isYouMightLike, userTypeId, isRefreshList }: Props) => {
    // Products
    const { products, searchProducts, searchState, hasMoreProducts, loading } = useProducts();
    const onNextPageProducts = () => searchProducts({
        ...searchState,
        isPurchaseUpsell: !isYouMightLike,
        userTypeId,
        pageLength,
        pageNumber: searchState.pageNumber + 1,
    });

    // Basket
    const { basket, addToBasket } = useBasketContext();
    const addServiceToBasket = (product: ProductType, qty, variations) => addToBasket({
        productId: product.id,
        quantity: qty,
        variations,
    });
    const itemIdsInBasket = basket.groups.flatMap(_ => _.items).map(x => x.serviceId.toUpperCase()) || [];

    useEffect(() => {
        searchProducts({
            ...searchState,
            isPurchaseUpsell: !isYouMightLike,
            userTypeId,
            pageLength,
        });
    }, [isYouMightLike, userTypeId, isRefreshList]);

    // Don't remove this it's stopping multiple (at least 4 times) expensive re-renders
    const renderProducts = React.useMemo(() => (
        products.map((x) => (<ServiceMiniSiteTile
            {...x}
            key={`${x.id}-${x.name}`}
            image={x.imageThumbnail ?? x.image}
            showEditButton={false}
            isInBasket={itemIdsInBasket.includes(x.id.toUpperCase())}
            onAddService={(qty, variations) => addServiceToBasket(x, qty, variations)}
            isBeingUsedForPackages={false}
            variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
            itemInCart={basket?.groups?.flatMap(_ => _.items)?.find(y => y.productId === x.id)}
            canChooseQuantity={x.addQuantity}
            isUpsell={!!x.upsellItems?.length}
            moreDetails={x.moreInformation}
        />))), [products]);

    return (
        <>
            <h5 className="tw-font-bold tw-pt-5 tw-pl-2 md:tw-pl-6">{heading}</h5>
            {/* No Products */}
            {(!products?.length && !loading) && (
                <div className="text-gray-500 tw-py-2 tw-pl-2 md:tw-pl-6">
                    {isYouMightLike ? <>Choose your need tags to see products that you might like.</> : <>You have not purchased any products yet.</>}
                </div>)}
            {/* Loading spinner */}
            {(!products?.length && loading) && <Spinner className="tw-mx-auto" />}
            {/* Render Products */}
            {products && products.length > 0 && (
                <>
                    <div className="tw-pt-1 tw-pb-4 md:tw-px-4">
                        <div className="tw-flex tw-flex-wrap">
                            {renderProducts}
                        </div>
                    </div>
                    <div className="tw-text-center">
                        {hasMoreProducts && (
                            <Button
                                onClick={onNextPageProducts}
                                color="primary"
                                outline
                                data-testid="load-more-button"
                                disabled={loading}
                            >
                                {loading ? <Spinner /> : <>Load more</>}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default UpsellProductListing;
