import React from "react";

interface Props {
    title: string | React.ReactNode,
    icon: React.ReactNode,
    text: string
}

const PostPurchaseStep = ({ title, icon, text }: Props) => (
    <div className="tw-grid tw-grid-cols-5 tw-gap-3">
        <div className="tw-col-span-1">{icon}</div>
        <div className="tw-col-span-4 tw-flex-col tw-flex tw-justify-center">
            <div className="tw-text-brand-primary tw-text-lg lg:tw-text-xl">{title}</div>
            <div className="tw-text-sm lg:tw-text-base">{text}</div>
        </div>
    </div>
);

export default PostPurchaseStep;
