/* eslint-disable import/extensions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Col, Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import ProfileIcon from "@/Assets/Icons/Coloured/profile.svg";
import SideBarLink from "@/Components/SideBarLink";
import { AccountPage } from "@/Pages/Profile/AccountPage";
import { ProfileTab } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/ProfileTab";

const Profile = ({ location }) => {
    const [isOpen, toggleOpen] = useState(false);
    const toggle = () => toggleOpen(!isOpen);

    const renderTabs = () => (
        <Row className="border-bottom my-2 tw-mx-5">
            <Nav tabs className="border-0">
                <NavItem className={classnames(location.pathname === "/profile/info" ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to="/profile/info">
                        Personal Information
                    </NavLink>
                </NavItem>
                <NavItem className={classnames(location.pathname === "/profile/info/account" ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary" tag={Link} to="/profile/info/account">
                        My Account
                    </NavLink>
                </NavItem>
            </Nav>
        </Row>
    );

    return (
        <Row className="box-shadow bg-white mt-5 mb-5 d-block d-md-flex mx-5">
            <Col md="4" lg="3" xl="2" className="border-right py-4 px-0 d-none d-md-flex flex-column ">
                <h3 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-none d-lg-block font-weight-bold">My Profile</h3>
                <h5 className="m-3 ml-lg-4 pl-0 pl-lg-2 mb-md-4 d-block d-lg-none font-weight-bold">My Profile</h5>
                <SideBarLink
                    icon={<ProfileIcon />}
                    text="My Information"
                    isActive={location.pathname.startsWith("/profile/info")}
                    to="/profile/info"
                />
            </Col>
            <Col>
                <Row>
                    <Col className={styles.header}>
                        <h4 className="d-inline text-white position-relative font-weight-bold">My Information</h4>
                    </Col>
                </Row>
                <Row>
                    <Navbar light className="col-12 px-0 d-md-none">
                        <NavbarToggler onClick={toggle} className="ml-auto mr-3 my-2" />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav navbar className="my-3">
                                <NavItem>
                                    <SideBarLink
                                        icon={<ProfileIcon />}
                                        text="My Information"
                                        isActive={location.pathname === "/profile/info"}
                                        to="/profile/info"
                                    />
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Row>

                <Route
                    path="/profile"
                    render={({ match: { url } }) => (
                        <Switch>
                            <Route
                                exact
                                path={`${url}/info`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <ProfileTab />
                                    </>
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/info/account`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <AccountPage />
                                    </>
                                )}
                            />
                            <Route path={`${url}/account`} component={AccountPage} />
                            <Redirect to={`${url}/info`} />
                        </Switch>
                    )}
                />
            </Col>
        </Row>
    );
};

Profile.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export { Profile };
