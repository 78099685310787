import React, { useEffect, useState } from "react";
import classnames from "classnames";
// eslint-disable-next-line import/no-unresolved
import type * as CSS from "csstype";
import { isMobile } from "react-device-detect";

import KanbanTag from "./KanbanTag";

import UpsellProductListing from "@/Pages/PurchaseUpsells/PurchasesListing";
// eslint-disable-next-line import/extensions
import TopBanner from "@/Assets/Images/PurchaseUpsell/top-banner.png";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { WaitModal } from "@/Modals/WaitModal";
import { Urls } from "@/Apis/urls";
import { TagFieldStatuses,
    PurchaseUpsell,
    TagCross,
    TagStatus,
    UserTypeItem,
    UserUpsellTagCrossArea } from "@/Apis/UserTypes";
import { useAPI } from "@/Apis/useAPI";
import { defaultDesktopHeaderItems,
    defaultMobileHeaderItems,
    MarketplaceCarousel } from "@/Components/MarketplaceCarousel";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import Spinner from "@/Components/Spinner";
import { ToolTip } from "@/Components/ToolTip";
import { DropDownOption, FloatLabelDropdown } from "@/Components/FloatLabelDropdown";

const PurchaseUpsells = () => {
    const { get, post } = useAPI({ handle500WithToastMessage: true });

    const { setPopupErrorMessage } = useToastMessageContext();

    const [purchaseUpsell, setPurchaseUpsell] = useState<PurchaseUpsell>({
        userTagStatuses: [],
    } as PurchaseUpsell);

    const [userTypeModalOpen, setUserTypeModalOpen] = useState<boolean>(false);
    const [currentUserTypeId, setCurrentUserTypeId] = useState<string>("");
    const [userTypeOptions, setUserTypeOptions] = useState<DropDownOption[]>([]);

    const [userUpsellTagCrossArea, setUserUpsellTagCrossArea] = useState<UserUpsellTagCrossArea>({
        haveTags: [],
        needTags: [],
        notInterestedTags: [],
    });

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isAreaLoading, setIsAreaLoading] = useState<boolean>(false);
    const [dragProcessing, setDragProcessing] = useState<boolean>(false);
    const [isRefreshMightList, setIsRefreshMightList] = useState<boolean>(false);
    const [percentageProgress, setPercentageProgress] = useState<number>(0);

    const progressBarPercentage: CSS.Properties = {
        width: `${percentageProgress}%`,
    };

    useEffect(() => { // Update the progress bar percentage
        if (!userUpsellTagCrossArea || !userUpsellTagCrossArea.haveTags || !userUpsellTagCrossArea.needTags) {
            return;
        }

        const haveTagsCount: number = userUpsellTagCrossArea.haveTags.length;
        const needTagsCount: number = userUpsellTagCrossArea.needTags.length;

        if (haveTagsCount === 0 && needTagsCount === 0) {
            return;
        }

        const percentageProgress = (haveTagsCount / (haveTagsCount + needTagsCount)) * 100;
        const progressRounded = Math.round(percentageProgress);
        setPercentageProgress(progressRounded);
    }, [userUpsellTagCrossArea.haveTags.length, userUpsellTagCrossArea.needTags.length]);

    const getAllUserTypeItems = () => {
        get<UserTypeItem[]>(Urls.userUpsells.userTypes.base)
            .then(response => {
                const userTypes = response.map(x => ({
                    display: x.label, value: x.value,
                }) as DropDownOption);

                setUserTypeOptions(userTypes);
            })
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true));
    };

    const getPurchaseUpsell = () => {
        setIsAreaLoading(true);
        get<PurchaseUpsell>(Urls.userUpsells.userTagStatuses.base)
            .then(response => setPurchaseUpsell(response))
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true))
            .finally(() => setIsAreaLoading(false));
    };

    const getTagStatus = (field: string) => {
        switch (field) {
            case TagFieldStatuses.haveTags:
                return TagStatus.Have;
            case TagFieldStatuses.needTags:
                return TagStatus.Need;
            default:
                return TagStatus.NotInterested;
        }
    };

    const onTagCrossAreaChanged = (field: string, results: TagCross[]) => {
        const previousTagsLength = userUpsellTagCrossArea?.[field].length;
        const newTagCrossArea = ({ ...userUpsellTagCrossArea, [field]: results });
        setUserUpsellTagCrossArea(newTagCrossArea);
        if (previousTagsLength !== results?.length && results?.length) {
            setDragProcessing(true);
            const submitData = {
                userTypeId: purchaseUpsell.userTypeId,
                userTagStatuses: results?.map(x => ({ tagId: x.id, status: getTagStatus(field) })) ?? [],
            };
            // Save user tag status
            post(Urls.userUpsells.userTagStatuses.moveTags, submitData)
                .then(() => {
                    if (field === TagFieldStatuses.needTags) {
                        setIsRefreshMightList(!isRefreshMightList);
                    }
                })
                .catch(() => {
                    setPopupErrorMessage("Unfortunately an error occurred", true);
                    getPurchaseUpsell();
                })
                .finally(() => setDragProcessing(false));
        } else if (field === TagFieldStatuses.needTags) {
            setIsRefreshMightList(!isRefreshMightList);
        }
    };

    useEffect(() => {
        setUserTypeOptions(userTypes => {
            if (!purchaseUpsell.userTypeId) { // customer has not yet selected a user type
                return [{ display: "-Select one-", value: "" }, ...userTypes]; // seed the "select one" user type
            }

            return userTypes;
        });
    }, [purchaseUpsell]);

    const onUserTypeIdChanged = (value: string) => {
        setCurrentUserTypeId(value);
        setUserTypeModalOpen(true);
    };

    const onConfirmUpdateUserType = () => {
        setIsProcessing(true);
        post<UserTypeItem[]>(Urls.userUpsells.userTagStatuses.saveCustomerUserType, { userTypeId: (!currentUserTypeId ? null : currentUserTypeId) })
            .then(() => {
                setUserTypeModalOpen(false);
                getPurchaseUpsell();
            })
            .catch(() => setPopupErrorMessage("Unfortunately an error occurred", true))
            .finally(() => setIsProcessing(false));
    };

    useEffect(() => {
        getAllUserTypeItems();
        getPurchaseUpsell();
        document.title = "BSC - Purchase Upsells";
    }, []);

    useEffect(() => {
        const newTagCrossArea = {
            haveTags: purchaseUpsell.userTagStatuses?.filter(x => x.status === TagStatus.Have)?.map(x => ({
                id: x.tagId, content: x.tagName,
            })) ?? [],
            needTags: purchaseUpsell.userTagStatuses?.filter(x => x.status === TagStatus.Need)?.map(x => ({
                id: x.tagId, content: x.tagName,
            })) ?? [],
            notInterestedTags: purchaseUpsell.userTagStatuses?.filter(x => x.status === TagStatus.NotInterested)?.map(x => ({
                id: x.tagId, content: x.tagName,
            })) ?? [],
        };
        setUserUpsellTagCrossArea(newTagCrossArea);
    }, [purchaseUpsell.userTagStatuses]);

    return (
        <div className="tw-bg-gray-100">
            <div className="tw-ml-auto tw-mr-auto tw-w-full tw-bg-white">
                <div className="tw-shadow">
                    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
                        <div className="tw-col-span-1 lg:tw-col-span-1 tw-relative">
                            <div className="tw-w-full tw-h-96 tw-relative tw-hidden lg:tw-block">
                                <picture>
                                    <img
                                        src={TopBanner}
                                        alt="Category purchases"
                                        className="tw-w-full tw-h-96"
                                    />
                                </picture>
                            </div>
                            <div
                                className={classnames("lg:tw-absolute lg:tw-bg-brand-primary lg:tw-inset-0 lg:tw-m-6 "
                                    + "lg:tw-w-3/4 lg:tw-bg-opacity-95 !tw-max-h-[360px]")}
                            >
                                <div
                                    className="tw-mx-auto lg:tw-mx-0 tw-flex tw-flex-col tw-px-6 tw-pt-6 tw-space-y-3"
                                >
                                    <div className="tw-text-brand-accent-yellow tw-text-xl lg:tw-text-2xl tw-hidden lg:tw-block">
                                        BSC Purchase History
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-text-brand-primary tw-text-xl tw-font-semibold tw-block lg:tw-hidden">
                                        Category Purchase History
                                        <ToolTip
                                            description={
                                                (
                                                    <p>
                                                        This is where we display what you have bought in each category and you can see what you should
                                                        buy next within a category. You can drag and drop the items on the <b>“Let use now what you need”</b>
                                                        section to help us streamline our recommendations to better suit your needs.
                                                    </p>
                                                )
                                            }
                                            id="ttip-category-purchase"
                                            className="tw-block lg:tw-hidden"
                                        >
                                            <i className="fas fa-info-circle tw-text-brand-accent-yellow !tw-mt-0.5 tw-pl-2" />
                                        </ToolTip>
                                    </div>
                                    <p className="tw-text-white tw-text-base tw-hidden lg:tw-block tw-text-justify">
                                        This is where we display what you have bought within BSC and you can see
                                        what we suggest you should buy next depending on what type of customer we think you are.
                                        You can drag and drop the items on the right to customise our suggestions for you.
                                    </p>
                                    <div className="tw-w-full tw-mt-3 lg:tw-w-1/2 lg:tw-mt-0">
                                        <FloatLabelDropdown
                                            label="I am a"
                                            onApply={(value) => onUserTypeIdChanged(value)}
                                            options={userTypeOptions}
                                            testId="sort-by-filter"
                                            selectedValue={purchaseUpsell.userTypeId ?? ""}
                                            disabled={isAreaLoading}
                                        />
                                    </div>
                                    <div className="tw-grid tw-grid-cols-3 lg:tw-grid-cols-1 tw-gap-3 tw-mt-3 tw-py-5 lg:tw-py-0">
                                        <div className="tw-col-span-1">
                                            <div className="lg:tw-text-base lg:tw-text-white tw-text-gray-400 tw-text-xs">
                                                Building your brand progress
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <div className="tw-w-full tw-bg-gray-200 tw-rounded-full tw-h-5">
                                                <div className="tw-bg-lime-400 tw-h-5 tw-rounded-full tw-text-right" style={progressBarPercentage}>
                                                    <div className="tw-text-sm tw-font-medium tw-text-gray-500 tw-block lg:tw-hidden tw-pr-1">{percentageProgress}%</div>
                                                </div>
                                            </div>
                                            <div className="tw-text-right tw-hidden lg:tw-block">
                                                {!isAreaLoading && <span className="tw-text-sm tw-font-medium tw-text-white">{percentageProgress}%</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col tw-space-y-3 tw-p-6">
                            <div className="tw-flex tw-flex-row">
                                <span className="tw-font-semibold tw-text-brand-primary tw-text-lg lg:tw-text-2xl">Let Us Know What You Need!</span>
                                <ToolTip
                                    description="Select the services which you may still be looking for to
                                            complete your branding by dragging and dropping the corresponding tags.
                                            This will help us streamline our recommendations to better suit your needs."
                                    id="ttip-let-us-know"
                                    className="float-left tw-block lg:tw-hidden -tw-mt-0.5 tw-pl-2"
                                >
                                    <i className="fas fa-info-circle tw-text-brand-accent-yellow" />
                                </ToolTip>
                            </div>
                            <p className="tw-text-base tw-hidden lg:tw-block tw-text-justify">
                                Select the services which you may still be looking for to complete your branding by dragging
                                and dropping on the corresponding tags to their respective purchasing status. This will help us
                                streamline our recommendations to better suit your needs.
                            </p>
                            {isAreaLoading
                                ? <div className="tw-col-span-3"><Spinner className="tw-mx-auto" /></div>
                                : <KanbanTag tagCrossArea={userUpsellTagCrossArea} onTagCrossAreaChanged={onTagCrossAreaChanged} />}
                        </div>
                    </div>
                </div>

                <div className="tw-shadow">
                    {/* Your Purchases */}
                    <UpsellProductListing
                        heading="Your Purchases"
                        pageLength={isMobile ? 4 : 8}
                        isYouMightLike={false}
                    />

                    {/* You Might Like */}
                    <UpsellProductListing
                        heading="You Might Like"
                        pageLength={isMobile ? 4 : 8}
                        isYouMightLike
                        userTypeId={purchaseUpsell.userTypeId}
                        isRefreshList={isRefreshMightList}
                    />

                    <div className="tw-p-1 sm:tw-p-3">
                        {/* Marketplace */}
                        <div className="tw-font-semibold tw-text-brand-primary tw-text-lg lg:tw-text-2xl">
                            Come check out hundreds of Suppliers in our Marketplace!
                        </div>
                        <MarketplaceCarousel
                            desktopSideBarClassName="desktop-sidebar"
                            desktopNoSideBarClassName="desktop"
                            desktopItemClassName="px-2 pt-3 pb-2 image-item"
                            mobileClassName="mobile"
                            mobileItemClassName="px-2 pt-3 pb-2 image-item"
                            desktopHeaderItems={defaultDesktopHeaderItems.map(x => ({ ...x, link: `/marketplace${x.link}` }))}
                            mobileHeaderItems={defaultMobileHeaderItems.map(x => ({ ...x, link: `/marketplace${x.link}` }))}
                            linkClassName="tw-relative"
                            displayMarketplaceLink
                        />
                    </div>
                </div>
                <ConfirmCancelModal
                    isOpen={userTypeModalOpen}
                    loading={isProcessing}
                    text="Are you sure that you want to update user type?"
                    onConfirm={onConfirmUpdateUserType}
                    onClose={() => setUserTypeModalOpen(false)}
                />
                <WaitModal isOpen={dragProcessing} />
            </div>
        </div>
    );
};

export { PurchaseUpsells };
