import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Col, Modal, ModalBody, Row } from "reactstrap";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import Spinner from "@/Components/Spinner";
import OrderStatusIcon from "@/Components/OrderStatusIcon";
import EscapeOnCloseWrapper from "@/Wrappers/EscapeOnCloseWrapper";
import formatDateHelper from "@/Utils/formatDateHelper";
import api from "@/Utils/api";

const AdminOrderAuditsModal = ({ id, orderReference, orderFulfilledBy, currentStatus, lastUpdated, lastUpdatedBy }) => {
    const dispatch = useDispatch();
    const onPush = path => dispatch(push(path));

    // const [order, setOrder] = useState({});
    const [audits, setAudits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState("");

    useEffect(() => {
        setLoading(true);
        api.get(`admin-orders/${id}`)
            .then((response) => {
                setAudits(response.results);
                setLoading(false);
            })
            .catch((error) => {
                onPush({ pathname: "/error", state: { error, referrer: window.location.href } });
            });
    }, [id]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setApiError("");
        }, 4000);

        return () => {
            clearTimeout(timeout);
        };
    }, [apiError]);

    const close = () => onPush("/admin/orders");

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white mx-5">
            <ModalBody className="d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative" size="lg">
                <CloseButton onClick={close} />
                {loading ? <Spinner className="align-self-center" /> : (
                    <>
                        <Row>
                            <Col>
                                {/* <AdminOrderAuditsModalHeader {...order} /> */}
                                {/* <OrderStatusIcon status={savedStatus} /> */}
                            </Col>
                        </Row>

                        {apiError && <div><p className="alert alert-danger">{apiError}</p></div>}

                        {loading ? <div className="mt-5 d-flex flex-grow-1 justify-content-center"><Spinner className="align-self-center" /></div> : (
                            <div className="mx-2 mx-md-0">
                                <Row className="d-none d-md-flex align-items-center">
                                    <Col>
                                        <div><small>Order Reference</small></div>
                                        <div>{orderReference}</div>
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-flex align-items-center">
                                    <Col>
                                        <div><small>Order Fulfilled By</small></div>
                                        <div>{orderFulfilledBy}</div>
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-flex align-items-center">
                                    <Col>
                                        <div><small>Current Status</small></div>
                                        {currentStatus && <div><OrderStatusIcon status={currentStatus} /></div>}
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-flex align-items-center">
                                    <Col>
                                        <div><small>Last Updated</small></div>
                                        <div>
                                            <span>{lastUpdated && formatDateHelper.format(lastUpdated, "DD/MM/YYYY")}</span>&nbsp;
                                            <span className={styles.orderTime}>{lastUpdated && formatDateHelper.format(lastUpdated, "HH:mm:ss")}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-flex align-items-center">
                                    <Col>
                                        <div><small>Last Updated By</small></div>
                                        {lastUpdatedBy}
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-flex align-items-center bg-light mt-4">
                                    <Col md="3" className="font-weight-bold">
                                        Date Changed
                                    </Col>
                                    <Col md="2" className="font-weight-bold">
                                        Field
                                    </Col>
                                    <Col md="2" className="font-weight-bold">
                                        From
                                    </Col>
                                    <Col md="2" className="font-weight-bold">
                                        To
                                    </Col>
                                    <Col md="3" className="font-weight-bold">
                                        Updated By
                                    </Col>
                                </Row>
                                {audits.map((x, i) => (
                                    <Row className={classnames(styles.row, (i % 2 === 1 ? "bg-light" : ""), "py-2 mb-3 mb-md-0 align-items-center")}>
                                        <Col md="3">
                                            <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Date Changed:</span>
                                            <span className="d-md-block mr-1">
                                                <span className="d-block">{x.dateChanged && formatDateHelper.format(x.dateChanged, "DD/MM/YYYY")}</span>
                                                <span className={styles.orderTime}>{x.dateChanged && formatDateHelper.format(x.dateChanged, "HH:mm:ss")}</span>
                                            </span>
                                        </Col>
                                        <Col md="2">
                                            <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Field:</span>
                                            <span className="d-md-block mr-1">
                                                {x.fieldChanged ? x.fieldChanged : "-"}
                                            </span>
                                        </Col>
                                        <Col md="2">
                                            <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>From:</span>
                                            <span className="d-md-block mr-1 overflow-auto">
                                                {x.from}
                                            </span>
                                        </Col>
                                        <Col md="2">
                                            <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>To:</span>
                                            <span className="d-md-block mr-1 overflow-auto">
                                                {x.to}
                                            </span>
                                        </Col>
                                        <Col md="3">
                                            <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Updated by:</span>
                                            <span className="d-md-block mr-1">
                                                {x.updatedBy}
                                            </span>
                                        </Col>
                                    </Row>
                                ))}
                            </div>)}
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

AdminOrderAuditsModal.propTypes = {
    id: PropTypes.string,
    orderReference: PropTypes.string,
    orderFulfilledBy: PropTypes.string,
    currentStatus: PropTypes.string,
    lastUpdated: PropTypes.string,
    lastUpdatedBy: PropTypes.string,
};

const EscapedAdminOrderAuditsModal = EscapeOnCloseWrapper(AdminOrderAuditsModal);
export {
    EscapedAdminOrderAuditsModal as AdminOrderAuditsModal,
    AdminOrderAuditsModal as PureAdminOrderAuditsModal,
};
