import React from "react";
import { Button, Col, Modal, ModalBody, Row, Table } from "reactstrap";

import TextArea from "@/Components/TextArea";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { useNotes } from "@/FlexPlan/Hooks/useNotes";
import { NoteType } from "@/FlexPlan/Types/Notes";
import FormatDateHelper from "@/Utils/formatDateHelper";
import Spinner from "@/Components/Spinner";
import { FormError } from "@/FlexPlan/Components/FormError";
import CloseButton from "@/Components/CloseButton";
import DateTextbox from "@/Components/DateTextbox";
import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";

const Notes = () => {
    const { formState: participantFormState } = useFlexPlanParticipantContext();

    const {
        onDebounceSearch,
        onChange,
        formState,
        notes,
        loading,
        error,
        onSubmit,
        toggleModal,
        open,
        invalidFields,
        onDateTextboxChange,
    } = useNotes(NoteType.participant, participantFormState.id);

    // This is displayed when the modal is closed
    const topItem = notes && notes[0];

    return (
        <>
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                <Row>
                    <Col xs="12" className="tw-flex tw-justify-between tw-mb-3">
                        <h4 className="font-weight-bold">Notes</h4>
                        <Button onClick={toggleModal} className="px-3" color="primary">View/Add</Button>
                    </Col>
                </Row>
                {loading
                    ? <Spinner className="tw-mx-auto tw-mt-2" />
                    : <div className="tw-line-clamp-1 tw-w-4/5">{topItem?.description}</div>}
            </div>
            <Modal
                isOpen={open}
                size="xl"
                className="tw-p-16"
            >
                <ModalBody className="tw-flex tw-flex-col tw-relative md:tw-p-12">
                    <CloseButton onClick={toggleModal} />
                    <h3>Notes</h3>
                    <TextArea
                        onChange={onChange}
                        value={formState.description}
                        name="description"
                        maxCharacters={255}
                        error={invalidFields.description && "Please enter a note with a maximum of 255 characters."}
                        placeholder="Add Note..."
                    />
                    <Button
                        color="primary"
                        className="tw-w-24"
                        onClick={onSubmit}
                        disabled={loading || (invalidFields && Object.keys(invalidFields).length > 0)}
                    >
                        Add Note
                    </Button>
                    <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-3 tw-mt-3">
                        <div className="tw-w-72 tw-relative tw-mt-8">
                            <DebounceTextbox
                                placeholder="Search notes"
                                callBack={onDebounceSearch("search")}
                                name="Search Notes"
                                inputClasses="!tw-pr-8"
                            />
                            <i className="fas fa-search tw-absolute tw-right-4 tw-top-2" />
                        </div>
                        <DateTextbox
                            currentDate={formState.dateFrom}
                            changeDate={onDateTextboxChange("dateFrom")}
                            label="Date From"
                        />
                        <DateTextbox
                            currentDate={formState.dateTo}
                            changeDate={onDateTextboxChange("dateTo")}
                            label="Date To"
                        />
                    </div>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th style={{ width: "70%" }}>
                                    Note
                                </th>
                                <th style={{ width: "10" }}>
                                    By
                                </th>
                                <th style={{ width: "20%" }}>
                                    On
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && notes && notes.map(x => (
                                <tr key={x.id}>
                                    <td>
                                        {x.description}
                                    </td>
                                    <td>
                                        {x.createdByUserFirstName}&nbsp;{x.createdByUserLastName}
                                    </td>
                                    <td>
                                        {FormatDateHelper.format(x.dateCreated, "DD/MM/YYYY hh:mm A")}
                                    </td>
                                </tr>
                            ))}
                            {loading && (
                                <tr>
                                    <td colSpan={4}><Spinner className="tw-mx-auto tw-mt-4" /></td>
                                </tr>
                            )}
                            {error && (
                                <FormError className="tw-mt-4">
                                    {error}
                                </FormError>
                            )}
                            {!loading && !error && !notes?.length && (
                                <tr>
                                    <td colSpan={4}>No notes yet.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </>
    );
};

export { Notes };
