import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { Widget } from "@/Components/Widget";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { useDebounce } from "@/Hooks/useDebounce";
import api from "@/Utils/api";
import { setToken } from "@/Utils/authentication";
import actions from "@/Store/User/actions";

interface User {
    id: string;
    name: string;
    suppliers: string[];
}

interface Error {
    error?: any;
}

interface Props {
    users: User[];
}

const ImpersonateUser = ({ users }: Props) => {
    const dispatch = useDispatch();
    const toastMessageContext = useToastMessageContext();
    const isImpersonating = useSelector<any>(state => state?.user?.isImpersonating);

    const [apiResponse, setApiResponse] = useState<Error>({});
    const [userSearch, setUserSearch] = useState<string>("");
    const searchDebounced = useDebounce(userSearch, 400);

    const submit = (userId) => {
        api.post("admin/impersonate", userId)
            .then((response) => {
                setToken(response.token, response.tokenExpiry);
                dispatch(actions.toggleImpersonating(true));// set is impersonating to true in the store
                toastMessageContext.setSuccessMessage(`You are now set as ${response.name}`, true);
                window.location.reload();
            })
            .catch(() => {
                setApiResponse({ error: "Unable to set user, please make sure they exist and try again" });
            });
    };

    const clear = () => {
        api.post("admin/clear-impersonation")
            .then((response) => {
                setToken(response.token, response.tokenExpiry);
                dispatch(actions.toggleImpersonating(false));// set is impersonating to true in the store
                toastMessageContext.setSuccessMessage(`You are now set as ${response.name}`, true);
                window.location.reload();
            })
            .catch(() => {
                setApiResponse({ error: "Unable to clear user, please try again" });
            });
    };

    return (
        <Widget title="Impersonate User">
            {isImpersonating ? (
                <div className="d-flex flex-column align-items-center flex-grow-1 px-4 mt-4">
                    <p>Click clear below to revert back you your user</p>
                    <Button color="warning" onClick={clear}>
                        Clear User Impersonation
                    </Button>
                    {apiResponse.error && <p className="mt-3 text-danger">{apiResponse.error}</p>}
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center flex-grow-1 px-4 mt-2 text-center">
                    <p><strong className="text-danger">Be careful as your actions cannot be undone.</strong></p>
                    <Input
                        placeholder="Email Search"
                        value={userSearch}
                        onChange={e => setUserSearch(e.target.value)}
                        className="mb-3"
                    />

                    <h6 className="text-left">Users</h6>
                    <div className={classnames(styles.usersHeight, "overflow-auto w-100")}>
                        {(users || [])
                            .filter(x => x.name.indexOf(searchDebounced) > -1)
                            .map(x => (
                                <button
                                    type="button"
                                    key={x.id}
                                    className="d-flex align-items-center justify-content-between py-1 w-100 text-left"
                                    onClick={() => submit(x.id)}
                                    title="Click to impersonate"
                                >
                                    {x.name} {x.suppliers.length > 0 && `(${x.suppliers.join(",")})`}
                                </button>
                            ))}
                    </div>

                    {apiResponse.error && <p className="mt-3 text-danger">{apiResponse.error}</p>}
                </div>
            )}
        </Widget>
    );
};

export { ImpersonateUser };
