import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import classnames from "classnames";
import { v4 } from "uuid";

import { PreviousOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Types/previousOrder";
import { toCurrencyWithCommas } from "@/FlexPlan/Utils/currency";
import formatDateHelper from "@/Utils/formatDateHelper";
import { useAPI } from "@/Apis/useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { ReactSelect } from "@/Components/ReactSelect";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";

const PreviousOrdersDropdown = () => {
    const { get } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage } = useToastMessageContext();

    const [previousOrders, setPreviousOrders] = useState<PreviousOrder[]>([]);

    const { disableButtons, allowOrderTemplate, onPreviousOrderSelect, formState } = useFlexPlanOrderContext();

    useEffect(() => {
        if (!allowOrderTemplate || disableButtons) {
            return;
        }
        get<PreviousOrder[]>(FlexPlanUrls.orders.base)
            .then(setPreviousOrders)
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    }, [allowOrderTemplate, disableButtons]);

    const [orders, setOrders] = useState<PreviousOrder[]>(previousOrders);
    // Locally store the selected order, no need to involve overall form state
    // We need to add the header to the start of the dropdown items importantly, it is disabled
    useEffect(() => {
        const headerOption: PreviousOrder = {
            id: v4(),
            isHeader: true,
            orderNumber: "",
            dateReceived: "",
            label: "",
            value: "",
            invoiceTotal: 0,
        };

        setOrders([headerOption, ...previousOrders
            .filter(order => (!formState.supplierId || order.supplierId === formState.supplierId)
            && (!formState.participantId || order.participantId === formState.participantId))
            .map(order => ({
                ...order,
                label: `${order.orderNumber} ${formatDateHelper.format(order.dateReceived, "DD/MM/YYYY")} ${toCurrencyWithCommas(order.invoiceTotal)}`,
                value: order.id,
            }))]);
    }, [previousOrders, formState.supplierId, formState.participantId]);

    return (
        <div className="tw-w-60 tw-mt-2">
            <Label className="tw-flex tw-items-center tw-space-x-1">
                <span>Previous Orders</span>
            </Label>
            <ReactSelect
                placeholder="Template order"
                onChange={onPreviousOrderSelect}
                options={orders}
                value={formState.previousOrderSelected}
                formatOptionLabel={(order: PreviousOrder) => (
                    <div className={classnames("tw-grid tw-grid-cols-3 tw-gap-3", order.isHeader && "tw-text-black tw-font-semibold")}>
                        <div className="tw-col-span-1">{order.isHeader ? "Order" : order.orderNumber}</div>
                        <div className="tw-col-span-1">{order.isHeader ? "Date" : formatDateHelper.format(order.dateReceived, "DD/MM/YY")}</div>
                        <div className="tw-col-span-1 tw-ml-auto">{order.isHeader ? "Total" : toCurrencyWithCommas(order.invoiceTotal)}</div>
                    </div>
                )}
                isOptionDisabled={option => option.isHeader}
                aria-label="previous-orders-select"
                isDisabled={disableButtons || !allowOrderTemplate}
            />
        </div>
    );
};

export { PreviousOrdersDropdown };
