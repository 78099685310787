import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import classnames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { Filter } from "@/FlexPlan/Types/Pagination";

interface Props {
    filters: Filter[],
    onChange: (value) => any,
    className?: string,
    buttonName?: string,
    titleName?: string,
}

const ListingScreenDropdownFilter = ({ filters, onChange, className, buttonName, titleName }: Props) => (
    <UncontrolledDropdown direction="down" className={className}>
        <DropdownToggle className={classnames(styles.filterButton, "bg-transparent border-0 p-0 text-dark !tw-font-semibold")}>
            {buttonName ?? ""}&nbsp;<i className="fa fa-filter" />
        </DropdownToggle>
        <DropdownMenu className="!tw-z-[100]">
            {titleName && <div className="tw-text-base tw-font-semibold tw-pl-6 tw-p-2">{titleName}</div>}
            {filters
                .sort((a, b) => a.position - b.position)
                .map(filter => (
                    <DropdownItem className="tw-flex tw-flex-row" key={filter.field}>
                        <Checkbox
                            onChange={onChange(filter.field)}
                            checked={filter.checked}
                            id={filter.field}
                            label={filter.label ?? filter.field}
                        />
                    </DropdownItem>
                ))}
        </DropdownMenu>
    </UncontrolledDropdown>
);

export { ListingScreenDropdownFilter };
