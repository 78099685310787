/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "reactstrap";
import classnames from "classnames";

import LoadingGif from "./loading.gif";
import { addressLookupConfiguration } from "./address.configuration";
import styles from "./styles.module.scss";

import { ManualAddress } from "@/Components/ManualAddress";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import { Visible } from "@/Components/Visible";
import { Address, AddressEvents, AddressLookupState, isValidAddress } from "@/Components/AddressLookup/address.instance";
import api from "@/Utils/api";

const AddressLookUp = ({ address,
    onSave,
    className,
    reset,
    heading,
    disabled,
    onError,
    state,
    events,
    onStateChange,
    hideButtons,
    preventEmptyReadOnly,
    loadingIcon,
    modalSearch,
    disableSaveIfInvalid,
    showReadOnlyEditButton,
    showReadOnlyRemoveButton,
    displaySingleLine,
    onCanSaveChanged,
    onManualAddressChange }) => {
    const resolvedEvents: AddressEvents = events;

    const errorDispatcher = addressLookupConfiguration.createContext();

    const [search, setSearch] = useState("");
    const [canSave, setCanSave] = useState(false);
    const [searchError, setSearchError] = useState("");
    const [currentState, setCurrentStateInternal] = useState(state || AddressLookupState.ReadOnly);
    const [searchedAddresses, setAddresses] = useState([] as Address[]);
    const [isSearching, setIsSearching] = useState(false);
    const [textboxStyle, setTextboxStyle] = useState({});

    useEffect(() => {
        const icon = loadingIcon || addressLookupConfiguration.loadingIcon || LoadingGif;
        if (isSearching && icon) {
            setTextboxStyle({ backgroundImage: `url(${icon})` });
        } else {
            setTextboxStyle({ });
        }
    }, [isSearching, loadingIcon]);

    useEffect(() => {
        let resolvedState = state;
        if (preventEmptyReadOnly && resolvedState === AddressLookupState.ReadOnly && !isValidAddress(address)) {
            resolvedState = AddressLookupState.Search;
            if (onStateChange) {
                onStateChange(resolvedState);
            }
        }
        setCurrentStateInternal(resolvedState);
    }, [preventEmptyReadOnly, address, onStateChange, setCurrentStateInternal, state]);

    const changeState = (newState: AddressLookupState, newAddress: Address | null) => {
        let resolvedState = newState;
        if (preventEmptyReadOnly && resolvedState === AddressLookupState.ReadOnly && !isValidAddress(newAddress || address)) {
            resolvedState = AddressLookupState.Search;
        }
        setCurrentStateInternal(resolvedState);
        if (onStateChange) {
            onStateChange(resolvedState);
        }
    };

    const onSaveInternal = (response, isNewAddress) => {
        if (onSave) {
            onSave(response, isNewAddress);
        }
        changeState(AddressLookupState.ReadOnly, response);
    };

    const getAddressesFromApi = (value) => {
        setIsSearching(true);
        api.get(`profile/findAddress?text=${value}`)
            .then((response) => {
                setSearchError("");
                setSearch(value);
                setAddresses(response);
                reset();
                setIsSearching(false);
            })
            .catch((error) => {
                setSearchError(error.message);
                if (onError) {
                    onError(error);
                }
                setIsSearching(false);
            });
    };

    const onGetFullAddressFromApi = value => () => {
        setIsSearching(true);
        api.get(`profile/getAddress?addressId=${value}`)
            .then((response) => {
                onSaveInternal(response, true);
                reset();
                setIsSearching(false);
            })
            .catch((error) => {
                addressLookupConfiguration.dispatchError(error, errorDispatcher);
                if (onError) {
                    onError(error);
                }
                setIsSearching(false);
            });
    };

    const getPostCodeValuesFromApi = value => () => {
        setIsSearching(true);
        api.get(`profile/findAddressForPostcode?id=${value.addressId}&postCode=${value.postCode}`)
            .then((response) => {
                setAddresses(response);
                setIsSearching(false);
            })
            .catch((error) => {
                addressLookupConfiguration.dispatchError(error, errorDispatcher);
                if (onError) {
                    onError(error);
                }
                setIsSearching(false);
            });
    };

    const goToManual = () => {
        changeState(AddressLookupState.Manual, null);
    };

    const removeAddress = () => {
        setSearch("");
        const newAddress = {} as Address;
        if (onSave) {
            onSave(newAddress);
        }
        setAddresses([]);
        changeState(AddressLookupState.Search, newAddress);
    };

    const goToSearch = () => {
        changeState(AddressLookupState.Search, null);
    };

    const goToReadOnly = () => {
        changeState(AddressLookupState.ReadOnly, null);
    };

    const onCanSaveChangedInternal = (value: boolean) => {
        if (value !== canSave) {
            setCanSave(value);
            if (onCanSaveChanged) {
                onCanSaveChanged(value);
            }
        }
    };

    const addressLookupForm = () => {
        const manualAddressJsx = () => (
            <ManualAddress
                onCanSaveChanged={onCanSaveChangedInternal}
                disableSaveIfInvalid={disableSaveIfInvalid}
                hideButtons={hideButtons && !modalSearch}
                onSave={(response) => {
                    onSaveInternal(response, false);
                }}
                isModal={modalSearch}
                onCancel={goToReadOnly}
                addressEvents={resolvedEvents}
                reset={reset}
                goToSearch={goToSearch}
                initialAddress={address}
                onManualAddressChange={onManualAddressChange}
            />
        );
        const searchJsx = () => (
            <>
                <div>
                    <Visible below="Lg">
                        <>Enter your post code *</>
                    </Visible>
                    <Visible forAndAbove="Lg">
                        <>Enter your post code, and we&apos;ll look for your address *</>
                    </Visible>
                </div>
                <DebounceTextbox
                    debounceTimeout={850}
                    callBack={getAddressesFromApi}
                    placeholder="Search for your address"
                    invalid={!!searchError}
                    error={searchError}
                    inputClasses={isSearching ? styles.loading : ""}
                    inputStyle={textboxStyle}
                />
                <div className={!!modalSearch || !!search ? classnames(styles.addressList, "my-3 border addressList") : ""}>
                    {search && (
                        <div className="overflow-y-auto">
                            <div className={styles.results}>
                                {searchedAddresses.map(x => (
                                    <div key={x.addressId} className={styles.resultRow}>
                                        <div className={`p-2 ${styles.postCode}`}>
                                            {x.postCode}
                                        </div>
                                        <div className={`p-2 ${styles.address}`}>
                                            {x.addressLine1}{x.city && <>, {x.city}</>}
                                        </div>
                                        <div className={`p-3 ${styles.action}`}>
                                            {!x.addressCount
                                            && (
                                                <Button
                                                    disabled={isSearching}
                                                    className="border-0 mx-0"
                                                    color="primary"
                                                    onClick={onGetFullAddressFromApi(x.addressId)}
                                                    data-testid="use-address"
                                                > Use address
                                                </Button>
                                            )}
                                            {!!x.addressCount
                                            && (
                                                <button
                                                    type="button"
                                                    className="bg-transparent border-0 m-0 text-primary"
                                                    onClick={getPostCodeValuesFromApi(x)}
                                                    data-testid="select-addresses"
                                                >
                                                    {x.addressCount} <i className="fa fa-angle-right" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${styles.searchButtons} ${(modalSearch ? styles.searchButtonsModal : "")}`}>
                    {
                        (!hideButtons || modalSearch)
                        && (
                            <>
                                <Button
                                    color="primary"
                                    onClick={goToManual}
                                >
                                    <Visible below="Lg">
                                        <>Enter address manually</>
                                    </Visible>
                                    <Visible forAndAbove="Lg">
                                        <>I&apos;d like to enter my full address manually</>
                                    </Visible>
                                </Button>
                                <span className={styles.filler} />
                                <Button color="secondary" className="px-3" onClick={goToReadOnly}>Cancel</Button>
                            </>
                        )
                    }
                </div>
            </>);

        const content = () => <>{currentState === AddressLookupState.Manual ? manualAddressJsx() : searchJsx()}</>;
        return modalSearch ? (
            <Modal
                isOpen
                centered
                className={styles.addressModalContainer}
                contentClassName={`${styles.addressModal} ${currentState === AddressLookupState.Manual ? styles.addressModalManual : styles.addressModalSearch} p-3`}
            >
                {content()}
            </Modal>
        )
            : (
                <div className={`${styles.addressInline} addressInline`}>
                    {content()}
                </div>
            );
    };

    const addressLineClassNames = displaySingleLine ? `${styles.addressLine} d-inline mb-0` : `${styles.addressLine} mb-1`;
    const startsWithNumber = (text: string) => text && /^[0-9]/.test(text);

    const readOnlyAddress = () => (
        !address || (!address.premise && !address.addressLine1 && !address.postCode)
            ? <span>No address provided</span>
            : (
                <div className={styles.readOnlyAddress}>
                    {startsWithNumber(address.premise) && (
                        <>
                            <p className={addressLineClassNames}>{[address.premise, address.addressLine1].join(" ")}<span className={styles.comma}>,</span></p>
                        </>
                    )}
                    {!startsWithNumber(address.premise) && `${address.premise}${address.addressLine1}` && (
                        <>
                            <p className={addressLineClassNames}>{address.premise}<span className={styles.comma}>,</span></p>
                            {address.addressLine1 && (<p className={addressLineClassNames}>{address.addressLine1}<span className={styles.comma}>,</span></p>)}
                        </>
                    )}
                    {address.addressLine2 && (<p className={addressLineClassNames}>{address.addressLine2}<span className={styles.comma}>,</span></p>)}
                    {address.city && (<p className={addressLineClassNames}>{address.city}<span className={styles.comma}>,</span></p>)}
                    {address.postCode && (<p className={addressLineClassNames}>{address.postCode}</p>)}

                    {showReadOnlyEditButton
                    && (
                        <div className={`${styles.readOnlyButtons} mt-3`}><Button color="link" className={styles.readOnlyButton} onClick={goToManual}>Edit</Button></div>
                    )}
                    {showReadOnlyRemoveButton
                    && (
                        <div className={`${styles.readOnlyButtons} mt-3`}><Button color="link" className={styles.readOnlyButton} onClick={removeAddress}>Remove</Button></div>
                    )}
                </div>
            )
    );

    return (
        <div className={classnames("d-flex flex-grow-1 flex-column mb-3 addressContent", className)}>
            <div className="flex-grow-1 d-flex justify-content-between">
                {heading !== "" && <h4 className="font-weight-bold">{!heading ? "Address" : heading}</h4>}
                {
                    ((!hideButtons && currentState === AddressLookupState.ReadOnly) || modalSearch) && !disabled
                        && <Button color="primary" className="px-3" onClick={goToSearch}>Edit</Button>
                }
            </div>
            <div className="addressContentForm">
                {currentState === AddressLookupState.Search && modalSearch ? readOnlyAddress() : null}
                {currentState === AddressLookupState.ReadOnly ? readOnlyAddress() : addressLookupForm()}
            </div>
        </div>
    );
};

AddressLookUp.propTypes = {
    events: PropTypes.instanceOf(AddressEvents),
    state: PropTypes.string,
    address: PropTypes.shape({
        premise: PropTypes.string.isRequired,
        addressLine1: PropTypes.string.isRequired,
        addressLine2: PropTypes.string,
        city: PropTypes.string.isRequired,
        postCode: PropTypes.string.isRequired,
    }),
    onSave: PropTypes.func,
    className: PropTypes.string,
    reset: PropTypes.func,
    heading: PropTypes.string,
    disabled: PropTypes.bool,
    onError: PropTypes.func,
    onStateChange: PropTypes.func,
    hideButtons: PropTypes.bool,
    preventEmptyReadOnly: PropTypes.bool,
    loadingIcon: PropTypes.string,
    modalSearch: PropTypes.bool,
    disableSaveIfInvalid: PropTypes.bool,
    showReadOnlyEditButton: PropTypes.bool,
    showReadOnlyRemoveButton: PropTypes.bool,
    onCanSaveChanged: PropTypes.func,
    displaySingleLine: PropTypes.bool,
    onManualAddressChange: PropTypes.func,
};

AddressLookUp.defaultProps = {
    state: AddressLookupState.ReadOnly,
    reset: () => {},
    heading: null,
    onError: () => {},
    hideButtons: false,
    preventEmptyReadOnly: false,
    displaySingleLine: false,
    onManualAddressChange: () => {},
};

export { AddressLookUp };
