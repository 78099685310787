import React from "react";
import { Label } from "reactstrap";
import classnames from "classnames";

import Textbox from "@/Components/Textbox";
import DropZoneButton from "@/Components/Dropzones/DropZoneButton";
import Checkbox from "@/Components/Checkbox";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";
import TextArea from "@/Components/TextArea";
import ColourPicker from "@/Components/ColourPicker";
import { useMinisiteContext } from "@/Context/MinisiteContext";
import sortingUtil from "@/Utils/sortingUtil";

const MinisiteTemplateOptions = () => {
    const {
        miniSite,
        updateSupplierTemplateSectionValue,
        updateSupplierTemplateValue,
    } = useMinisiteContext();

    return (
        <div className="tw-mt-5">
            <h3>Template options</h3>
            {miniSite.template.sections
                .sort((x, y) => sortingUtil.sort(x, y, "sectionNumber", true))
                .map(section => (
                    <div className="!tw-mt-5" key={section.sectionNumber}>
                        <h5>Section {section.sectionNumber}</h5>
                        <Textbox
                            label="Title"
                            onChange={updateSupplierTemplateSectionValue(section.sectionNumber, "title")}
                            value={section.title}
                            maxLength={255}
                        />
                        <Label>Title colour</Label>
                        <ColourPicker
                            colour={section.titleColour}
                            onColourChange={updateSupplierTemplateSectionValue(section.sectionNumber, "titleColour")}
                            className="tw-mb-3"
                            pickerClassName={classnames(section.sectionNumber === 1 && "!tw-top-10")}
                        />
                        <TextArea
                            label="Text"
                            name={`text-${section}`}
                            onChange={updateSupplierTemplateSectionValue(section.sectionNumber, "text")}
                            value={section.text}
                            maxCharacters={1000}
                            maxCharactersClassName="tw-text-gray-400"
                            placeholder={section.sectionNumber === 1 ? "This will be the description shown on the tiles in the marketplace and on your minisite" : ""}
                        />
                        <DropZoneButton
                            onChange={updateSupplierTemplateSectionValue(section.sectionNumber, "desktopImage")}
                            containerClasses="tw-border tw-rounded-lg tw-p-3"
                            image={section.desktopImage}
                            showRemoveButton
                            accept={acceptedFileTypes.miniSite}
                            maxSize={2000000}
                            noImageText={(
                                <div>
                                    <p>Please select a <span className="tw-font-semibold tw-underline">desktop</span> image for section {section.sectionNumber}.</p>
                                    <p className="tw-italic tw-text-gray-400">
                                        Max file size 2MB. File types: {fileTypesText(acceptedFileTypes.miniSite)}.
                                    </p>
                                </div>
                            )}
                        />
                        <DropZoneButton
                            onChange={updateSupplierTemplateSectionValue(section.sectionNumber, "mobileImage")}
                            containerClasses="tw-border tw-rounded-lg tw-p-3 tw-mt-3"
                            image={section.mobileImage}
                            showRemoveButton
                            accept={acceptedFileTypes.miniSite}
                            maxSize={2000000}
                            noImageText={(
                                <div>
                                    <p>Please select a <span className="tw-font-semibold tw-underline">mobile</span> image for section {section.sectionNumber}.</p>
                                    <p className="tw-italic tw-text-gray-400">
                                        Max file size 2MB. File types: {fileTypesText(acceptedFileTypes.miniSite)}.
                                    </p>
                                </div>
                            )}
                        />
                    </div>
                ))}
            {/* Checkboxes */}
            <Checkbox
                label="Show Ratings"
                onChange={updateSupplierTemplateValue("showRatings")}
                checked={miniSite.template.showRatings}
                id="show-ratings"
                labelClassName="tw-text-white"
                className="!tw-mt-3"
            />
            <Checkbox
                label="Show Contact Form"
                onChange={updateSupplierTemplateValue("showContactUs")}
                checked={miniSite.template.showContactUs}
                id="show-contact-form"
                labelClassName="tw-text-white"
            />
            <p className="tw-text-small tw-text-red-500 tw-mt-2">
                Note: If you select to show both ratings and contact form, the layout will always be the sections side by side each other.
            </p>
        </div>
    );
};

export { MinisiteTemplateOptions };
