import React, { useContext, useEffect, useRef } from "react";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";

import { getBrowserId } from "@/Utils/localStorage";
import { getToken } from "@/Utils/authentication";
import { subscriptions } from "@/Context/SignalRContext/Subscriptions";
import { features } from "@/Utils/features";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { useBasketContext } from "@/Context/BasketContext";

const SignalRContext = React.createContext({});
const useSignalRContext = () => useContext(SignalRContext);

interface Props {
    children: React.ReactNode,
}

const SignalRProvider = ({ children }: Props) => {
    const { signalRConfiguration: { uri } } = useConfigurationContext();
    const basketContext = useBasketContext();

    const hubConnectionRef = useRef({} as HubConnection);

    const start = () => {
        hubConnectionRef.current
            .start()
            .then(() => {
                subscriptions.forEach(x => {
                    hubConnectionRef.current.on(x.actionName, payload => x.action(basketContext, payload));
                });
            });
    };

    useEffect(() => {
        if (!features.isEnabled(features.signalR)) {
            return () => {
            };
        }
        let resolvedUri = (uri ?? "").trim() || window.location.origin;
        if (resolvedUri.endsWith("/")) {
            resolvedUri = resolvedUri.substring(0, resolvedUri.length - 1);
        }
        hubConnectionRef.current = new signalR.HubConnectionBuilder()
            .withUrl(`${resolvedUri}/signalr?browserId=${getBrowserId()}`, { accessTokenFactory: () => getToken() })
            .configureLogging(`${resolvedUri}`.includes("https://") ? signalR.LogLevel.None : signalR.LogLevel.Information)
            .build();

        start();

        const hubConnection = hubConnectionRef.current;

        return () => {
            hubConnection.stop().then().catch();
        };
        // eslint-disable-next-line
    }, []);

    const reconnect = () => {
        if (!features.isEnabled(features.signalR)) {
            return;
        }

        hubConnectionRef.current.stop().then(start).catch();
    };

    return (
        <SignalRContext.Provider value={{ reconnect }}>
            {children}
        </SignalRContext.Provider>
    );
};

export {
    useSignalRContext,
    SignalRProvider,
};
