import React from "react";
import { Placement } from "popper.js";

import { Popper } from "@/Components/Popper";
import { getFrequencyAlternate, getDelayedPaymentPeriodValue, toCurrency } from "@/Utils/formatStringHelper";
import { BasketGroupType } from "@/Apis/Basket";

interface Item {
    id: string;
    name: string;
    group: BasketGroupType;
    categoryName: string;
    subCategoryName: string;
    paymentFrequency: string;
    initialCharge: number;
    isDelayedPayment?: boolean;
    delayedPaymentFor?: number;
    delayedPaymentPeriod?: number;
}

interface Props {
    placement?: Placement;
    items: Item[];
}

const DueToSupplierPopup = ({ placement, items }: Props) => (
    <Popper
        targetId="dueToSupplier_moreInfo"
        placement={placement ?? "right"}
        hideArrow
        icon={(<i id="dueToSupplier_moreInfo" data-testid="pricing-popover-icon" className="pointer ml-2 fas fa-info-circle text-primary" />)}
    >
        <div style={{ width: "400px" }} data-testid="pricing-popover-body">
            <div className="tw-max-h-[500px] overflow-y-auto p-2">
                <h5 className="text-primary">Due to Supplier</h5>
                {items.length > 0 && items.map((item, idx) => (
                    <div key={item.id}>
                        {idx > 0 && <hr />}
                        <div className="d-flex justify-content-between my-3">
                            <div className="d-flex flex-column">
                                <span className="font-weight-bold">{item.name}</span>
                                <span className="text-muted small">
                                    {item.group?.key != null && item.group?.name != null
                                        ? item.group.name
                                        : <>{item.categoryName} <span className="font-weight-bold">&middot;</span> {item.subCategoryName}</>}
                                </span>
                            </div>
                            <div className="font-weight-bold text-right">
                                {(item.isDelayedPayment && "£0.00")
                                || (item.paymentFrequency === "N/A"
                                    ? "N/A"
                                    : (<>£{toCurrency(item.paymentFrequency === "N/A" ? "N/A" : item.initialCharge)}</>))}
                                {item.isDelayedPayment && (
                                    <span className="tw-text-3xs tw-text-brand-primary tw-font-normal tw-block">
                                        {(item.paymentFrequency === "N/A"
                                            ? "N/A"
                                            : (<>£{toCurrency(item.paymentFrequency === "N/A" ? "N/A" : item.initialCharge)}</>))}
                                        {/* eslint-disable-next-line max-len */}
                                        {getFrequencyAlternate(item.paymentFrequency ?? "")} {item.isDelayedPayment && (<>after {item.delayedPaymentFor} {getDelayedPaymentPeriodValue(item.delayedPaymentPeriod ?? 0, item.delayedPaymentFor ?? 1)}</>)}
                                    </span>)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Popper>);

export { DueToSupplierPopup };
