import { useEffect } from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router";

import { setToken } from "@/Utils/authentication";

const PartnerBridge = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const query = queryString.parse(location.search);
        setToken(query.token, query.expiry);
        history.push({
            pathname: "/checkout",
            state: { cfUser: true }, // company formations user. we use this to show a warning if they try and leave checkout
        });
    }, []);

    return null;
};

export { PartnerBridge };
