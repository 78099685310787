import React from "react";
import classnames from "classnames";

import { TagFieldStatuses, TagCross, UserUpsellTagCrossArea } from "@/Apis/UserTypes";
import { DraggableAreasGroup } from "@/Components/DraggableTags";

const group = new DraggableAreasGroup();
const DraggableArea1 = group.addArea("area1");
const DraggableArea2 = group.addArea("area2");
const DraggableArea3 = group.addArea("area3");

interface Props {
    tagCrossArea: UserUpsellTagCrossArea,
    onTagCrossAreaChanged: (field: string, value: TagCross[]) => void,
}

const squareClasses: string = "tw-w-full tw-p-2 tw-bg-slate-200 tw-relative tw-flex-grow";
const tagClasses: string = "tw-relative tw-m-0.5 tw-bg-[#ffce2b] tw-rounded tw-px-2 tw-text-gray-700 tw-font-semibold tw-whitespace-nowrap";
const draggableAreaClasses: string = "tw-pt-5";

const KanbanTag = ({ tagCrossArea, onTagCrossAreaChanged }: Props) => (
    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-2 lg:tw-space-y-0 lg:tw-space-x-3 lg:tw-overflow-auto lg:tw-h-60">
        <div className={classnames(squareClasses)}>
            <span className="tw-text-brand-primary tw-font-semibold tw-absolute tw-top-3 tw-left-3">I have</span>
            <DraggableArea1
                className={draggableAreaClasses}
                tags={tagCrossArea.haveTags}
                render={({ tag }) => (
                    <div className={tagClasses}>
                        {tag.content}
                    </div>
                )}
                onChange={(haveTags) => onTagCrossAreaChanged(TagFieldStatuses.haveTags, haveTags)}
            />
        </div>
        <div className={squareClasses}>
            <span className="tw-text-brand-primary tw-font-semibold tw-absolute tw-top-3 tw-left-3">I need</span>
            <DraggableArea2
                className={draggableAreaClasses}
                tags={tagCrossArea.needTags}
                render={({ tag }) => (
                    <div className={tagClasses}>
                        {tag.content}
                    </div>
                )}
                onChange={(needTags) => onTagCrossAreaChanged(TagFieldStatuses.needTags, needTags)}
            />
        </div>
        <div className={squareClasses}>
            <span className="tw-text-brand-primary tw-font-semibold tw-absolute tw-top-3 tw-left-3">Not Interested</span>
            <DraggableArea3
                className={draggableAreaClasses}
                tags={tagCrossArea.notInterestedTags}
                render={({ tag }) => (
                    <div className={tagClasses}>
                        {tag.content}
                    </div>
                )}
                onChange={(notInterestedTags) => onTagCrossAreaChanged(TagFieldStatuses.notInterestedTags, notInterestedTags)}
            />
        </div>
    </div>
);
export default KanbanTag;
