import React from "react";
import { Button } from "reactstrap";

import { DropZoneMulti } from "@/Components/Dropzones/DropZoneMulti";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { downloadUseAPIFile } from "@/Utils/dowloadHelper";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import Spinner from "@/Components/Spinner";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { isApprovedOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";

const UploadDocumentsTab = () => {
    const {
        onAddDocument,
        onDeleteDocument,
        disableButtons,
        formState,
        orderLoaded,
        savingOrder,
        onSaveOrder,
        submittingPayment,
    } = useFlexPlanOrderContext();

    const { getFile } = useAPI({ handle500WithToastMessage: true });
    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();

    const fileDownload = (fileId: string) => () => {
        getFile(FlexPlanUrls.orders.downloadDocument(fileId))
            .then(response => {
                downloadUseAPIFile("application/octet-stream", response);
                setSuccessMessage(`${response.name} downloaded`, true);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setPopupErrorMessage(error, true);
                } else if (error.message) {
                    setPopupErrorMessage(error.message, true);
                }
            });
    };

    if (!orderLoaded) { // Rendering the drop zone multi before the order has loaded results in existing documents not showing
        return <></>;
    }

    return (
        <>
            <div className="tw-border-2 tw-p-4 md:tw-p-8 tw-mt-5 tw-w-2/4">
                <h4 className="font-weight-bold">Document Upload</h4>
                <DropZoneMulti
                    disabled={disableButtons}
                    onAdd={onAddDocument}
                    onDelete={onDeleteDocument}
                    initialFiles={formState.supportingDocuments?.map(x => ({
                        id: x.id,
                        fileName: x.name,
                        data: x.data,
                        disabled: !!x.id && isApprovedOrder(formState.orderStatus),
                    }))}
                    validationContent={`Files must be less than 8MB and a ${fileTypesText(acceptedFileTypes.documents)}.`}
                    downloadable
                    onFileDownload={fileDownload}
                />
            </div>
            <Button
                color="primary"
                onClick={() => onSaveOrder(true)}
                disabled={disableButtons}
                className="tw-w-32 tw-mt-8"
            >
                {(savingOrder && (!submittingPayment)) ? <Spinner className="tw-mx-auto" size="25" /> : "Save"}
            </Button>
        </>
    );
};

export { UploadDocumentsTab };
