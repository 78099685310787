import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Label } from "reactstrap";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import api from "@/Utils/api";
import { encodeFileToBase64 } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes } from "@/Utils/constants";
import { substringFromLastIndexOf } from "@/Utils/formatStringHelper";

const FloatLabelFileUpload = ({ className, isRequired, error, heading, autoSaveRoute, onFileAdded, onFileUploaded, accept, disabled, isIdentityDocument, documentName = "" }) => {
    const uniqueId = uuid();
    const [isRequiredValidationFailed, setIsRequiredValidationFailed] = useState(false);
    const [canValidate, setCanValidate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [savingTerms, setSavingTerms] = useState(false);
    const [fileName, setFileName] = useState(substringFromLastIndexOf(documentName, "/"));

    useEffect(() => {
        if (canValidate) {
            const requiredValidationFailed = isRequired && !documentName;
            setIsRequiredValidationFailed(requiredValidationFailed);
            if (requiredValidationFailed) {
                setErrorMessage("This field is required");
            }
        }
    }, [isRequiredValidationFailed, documentName, isRequired, canValidate]);

    const onButtonClick = () => document.getElementById(uniqueId).click();

    const onAddFile = async (files) => {
        const file = files[0];

        try {
            const filenameArr = file.name.split(".");
            if (acceptedFileTypes.documents.indexOf(filenameArr[filenameArr.length - 1]) < 0) {
                throw new Error(`Unsupported file type. Accepted types: ${acceptedFileTypes.documents}`);
            }
            const encodingResult = await encodeFileToBase64(file);

            setSavingTerms(true);
            setErrorMessage("");

            const response = await api.post(autoSaveRoute, {
                fileName: file.name,
                base64Document: encodingResult.target.result,
                isIdentityDocument,
            });

            if (onFileAdded) {
                onFileAdded();
            }
            setFileName(file.name);
            onFileUploaded(response.fileLocation);
            setCanValidate(true);
        } catch (err) {
            setErrorMessage(err.message || "File upload failed.");
        }

        setSavingTerms(false);
    };

    if (disabled) {
        return (
            <FormGroup>
                <Label>{heading}</Label>
                {documentName
                    && (
                        <a href={`${window.cdnUrl}${documentName}`} target="_blank" rel="noopener noreferrer" className="d-block mb-3 font-weight-bold">
                            Download
                            <i className="ml-2 fa fa-download" />
                        </a>)}
                {!documentName
                    && <p>Not provided</p>}
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            <div className={classnames(styles.floatLabel, className, !savingTerms && (errorMessage || error || isRequiredValidationFailed) ? styles.error : "")}>
                <Label>{heading}</Label>
                <InputGroup>
                    <Input type="text" disabled className={classnames(styles.input)} value={fileName} />
                    <InputGroupAddon addonType="append">
                        <InputGroupText className={classnames(styles.button, "bg-primary text-white")} onClick={onButtonClick}>
                            Browse Files {savingTerms && <Spinner size="15" className="ml-1" />}
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                {(!savingTerms && (errorMessage || error)) && <div className={styles.errorMessage}>{errorMessage || error}</div>}
                <input type="file" id={uniqueId} className="d-none" accept={accept} onChange={e => onAddFile([...e.target.files])} />
            </div>
        </FormGroup>
    );
};

FloatLabelFileUpload.propTypes = {
    accept: PropTypes.string,
    documentName: PropTypes.string,
    heading: PropTypes.string.isRequired,
    onFileUploaded: PropTypes.func.isRequired,
    autoSaveRoute: PropTypes.string.isRequired,
    onFileAdded: PropTypes.func,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    error: PropTypes.string,
    className: PropTypes.string,
    isIdentityDocument: PropTypes.bool,
};

FloatLabelFileUpload.defaultProps = {
    accept: acceptedFileTypes.documents,
    isRequired: false,
    isIdentityDocument: false,
};

export { FloatLabelFileUpload };
