import { Button } from "reactstrap";
import React from "react";

interface Props {
    name: string,
    downloadUrl?: string,
    onDelete?: () => void,
    hideDownload?: boolean,
}

const DocumentDetails = ({ name, downloadUrl, onDelete, hideDownload = false }: Props) => {
    const classes = "my-2 py-1 pl-3 pr-2 mx-4 d-flex align-items-center justify-content-between rounded-lg border border-primary text-primary text-decoration-none";

    const fullUrl = () => {
        const win = window as any;
        return `${win.cdnUrl}${downloadUrl}`;
    };

    if (hideDownload) {
        return <div className={classes}>{name}</div>;
    }

    if (onDelete) {
        return (
            <div className={classes}>
                {name}
                <Button color="link" onClick={onDelete}><i className="far fa-trash-alt text-danger" /></Button>
            </div>
        );
    }

    return (
        <a color="link" rel="noopener noreferrer" target="_blank" href={fullUrl()} className={classes}>
            {name}
            <i className="fas fa-download" />
        </a>
    );
};

export { DocumentDetails };
