import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

const Rating = (props) => {
    const [hoverIndex, setHover] = useState(-1);
    const hover = (value) => {
        if (props.onChange) {
            setHover(value);
        }
    };

    if (!props.onChange) {
        return (
            <div className={classnames(styles.rating, props.className)}>
                {[...Array(Math.max(props.rating, 5))].map((_, i) => (
                    /* nothing else to use but the index */
                    /* eslint-disable-next-line react/no-array-index-key */
                    <span key={i} data-testid={`ratingSpan-${i}`} className={props.classNameStar}>
                        {props.rating >= (i + 1) || hoverIndex >= i
                            ? <i className="mr-2 text-warning fa fa-star" />
                            : <i className="far fa-star" />}
                    </span>
                ))}
            </div>
        );
    }
    const onClick = i => () => props.onChange(i + 1);
    const onMouseEnter = i => () => hover(i);
    const onMouseLeave = () => hover(-1);
    return (
        <div className={classnames(styles.rating, props.className)}>
            {[...Array(Math.max(props.rating, 5))].map((_, i) => (
                <button
                    /* nothing else to use but the index */
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={i}
                    type="button"
                    onClick={onClick(i)}
                    data-testid={`ratingButton-${i}`}
                    className={classnames(
                        "bg-transparent border-0 p-0",
                        props.rating > i && "text-warning",
                    )}
                    onMouseEnter={onMouseEnter(i)}
                    onMouseLeave={onMouseLeave}
                >
                    {props.rating >= (i + 1) || hoverIndex >= i
                        ? <i className="mr-2 text-warning fa fa-star" />
                        : <i className="far fa-star" />}
                </button>
            ))}
        </div>
    );
};

Rating.propTypes = {
    rating: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
    classNameStar: PropTypes.string,
};

Rating.defaultProps = {
    rating: 5,
};

export default Rating;
