/* eslint-disable import/extensions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Button, Nav, Navbar, NavbarToggler, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

// import { ReactComponent as Logo } from "Assets/Icons/Solid/logo.svg";
import styles from "./styles.module.scss";

import FlexPlanLogo from "@/FlexPlan/Assets/Images/flex-plan-logo.svg";
import Logo from "@/Assets/Icons/Solid/logo.svg";
import IndicatorIcon from "@/Assets/Icons/Coloured/indicator.svg";
import { useBasketContext } from "@/Context/BasketContext";
import { HelpList } from "@/Components/HelpList";
import { features } from "@/Utils/features";

// JC: 2022-09-22
/*
We can't do:
import { ReactComponent as MyIcon } from "./myicon.svg";
as the internet recommends, as this does not work with our configuration of Jest
*/

const Navigation = ({ toggleMenu }) => {
    const {
        basket,
        toggleBasket,
    } = useBasketContext();
    const displayBasketIndicator = basket.totalItems > 0;
    const loggedIn = useSelector<any>(state => state.user.isLoggedIn);
    const displayMessageIndicator = useSelector<any>(state => state.notifications.unreadMessages > 0);
    const isImpersonating = useSelector<any>(state => state.user.isImpersonating);
    const [isShowingHelpList, setIsShowingHelpList] = useState(false);

    const flexPlan = features.isEnabled(features.FlexPlan);
    const toggleSideBasket = (e) => {
        e.stopPropagation();
        toggleBasket();
    };

    const bgColour = flexPlan ? "tw-bg-flexplan-primary" : "tw-bg-brand-primary";
    const renderLogo = () => (
        flexPlan
            ? (<FlexPlanLogo {... { className: classnames(styles.logo, "pointer mt-2 mt-md-0 text-center md:tw-hidden") } as any} />)
            : (<Logo {...{ className: classnames(styles.logo, "pointer mt-2 mt-md-0 text-center md:tw-hidden") } as any} />)
    );

    if (!loggedIn) {
        return (
            <>
                <Navbar
                    fixed="top"
                    className={classnames(styles.container, "p-0", bgColour)}
                    dark
                    expand="lg"
                >
                    <Nav
                        navbar
                        className={classnames(styles.noEvents, "flex-grow-1 justify-content-start align-items-center w-100 position-absolute px-3 pe-none")}
                    >
                        <NavItem className={classnames(styles.events, "d-block pe-auto")}>
                            <NavLink to="/">
                                {renderLogo()}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className={classnames("d-flex align-items-center w-100 justify-content-end px-3")}>
                        <Button
                            id="help-menu-item"
                            color="link"
                            className="mt-1"
                            onClick={() => {
                                setIsShowingHelpList(true);
                            }}
                        >
                            <i className="fa fa-question-circle text-white" />
                        </Button>
                        <Button color="link" className="mt-1 tw-relative" onClick={toggleSideBasket}>
                            <i className="fa fa-shopping-cart tw-mr-0.5 lg:tw-mr-2 text-white" />
                            {displayBasketIndicator && (
                                <IndicatorIcon {...{ className: "tw-absolute -tw-top-0.5 tw-right-0.5 tw-w-2.5" } as any} />
                            )}
                        </Button>
                        <NavLink to="/login" className="mt-1 ml-3 d-block d-lg-none">
                            <i className="fa fa-user mr-2 text-white" />
                        </NavLink>
                        <NavLink to="/login" className="btn border-light text-light ml-4 d-none d-lg-block">
                            Login
                        </NavLink>
                        <NavLink to="/sign-up" className="btn btn-warning ml-4 d-none d-lg-block">
                            Sign Up
                        </NavLink>
                    </div>
                </Navbar>
                {isShowingHelpList && (
                    <div className={styles.helpPopup}>
                        <HelpList isShowing={isShowingHelpList} onHide={() => setIsShowingHelpList(false)} />
                    </div>)}
            </>
        );
    }

    return (
        <>
            <Navbar
                fixed="top"
                className={classnames(styles.container, "p-0", bgColour)}
                dark
                expand="lg"
            >
                <>
                    <NavbarToggler
                        onClick={() => toggleMenu(true)}
                        className={classnames(styles.toggler, "d-block ml-3 position-absolute")}
                    />
                    {isImpersonating && (
                        <div className="w-100 d-flex position-absolute justify-content-center mx-auto mt-3">
                            <span className="alert alert-danger font-weight-bold">You are impersonating a user!</span>
                        </div>
                    )}
                    {!isImpersonating && (
                        <Nav
                            navbar
                            className={classnames(styles.noEvents, "flex-grow-1 justify-content-start align-items-center w-100 position-absolute px-3")}
                        >
                            <NavItem className="d-block">
                                <NavLink className={styles.events} to="/">
                                    {renderLogo()}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    )}
                    {/* Only display the cart etc if it's bsc */}
                    {!flexPlan && (
                        <div
                            className={classnames("d-flex align-items-center w-100 justify-content-end tw-px-1 md:tw-px-3")}
                        >
                            <Button
                                id="help-menu-item"
                                color="link"
                                className="mt-1"
                                onClick={() => setIsShowingHelpList(true)}
                            >
                                <i className="fa fa-question-circle text-white" />
                            </Button>
                            <Button color="link" className="mt-1 tw-relative" onClick={toggleSideBasket}>
                                <i className="fa fa-shopping-cart tw-mr-0.5 lg:tw-mr-2 text-white" />
                                {displayBasketIndicator && (
                                    <IndicatorIcon {...{ className: "tw-absolute -tw-top-0.5 tw-right-0.5 tw-w-2.5" } as any} />
                                )}
                            </Button>
                            <NavLink to="/messages">
                                <Button color="link" className="mt-1 tw-relative">
                                    <>
                                        <i className="fa fa-envelope tw-mr-0.5 lg:tw-mr-2 text-white" />
                                        {displayMessageIndicator && (
                                            <IndicatorIcon {...{ className: "tw-absolute -tw-top-0.5 tw-right-0.5 tw-w-2.5" } as any} />
                                        )}
                                    </>
                                </Button>
                            </NavLink>
                        </div>
                    )}
                </>
            </Navbar>
            {isShowingHelpList && (
                <div className={styles.helpPopup}>
                    <HelpList isShowing={isShowingHelpList} onHide={() => setIsShowingHelpList(false)} />
                </div>)}
        </>
    );
};

Navigation.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
};

export { Navigation };
