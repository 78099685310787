import React from "react";
import classnames from "classnames";
import { Button, ButtonGroup, Input } from "reactstrap";

import styles from "./styles.module.scss";

interface Props {
    disabled?: boolean;
    value?: number;
    minimumValue?: number;
    onValueChanged: (val) => void;
    classNames?: string;
}

const ProductQuantity = (
    {
        disabled = false,
        minimumValue = 0,
        value = 0,
        onValueChanged,
        classNames = "",
    }: Props,
) => {
    const initialMinimumQty = minimumValue;

    const updateValue = (val) => {
        const updatedVal = Number(val);
        if (updatedVal < initialMinimumQty) {
            onValueChanged(initialMinimumQty);
        } else if (!Number.isNaN(updatedVal) && updatedVal > 0) {
            onValueChanged(updatedVal);
        } else if (updatedVal === 0) {
            onValueChanged(1); // we can't allow zero, so set to minimum of 1. user must remove item if zero wanted
        }
    };

    const onKeyUp = (e) => {
        if (e.key === "ArrowUp") {
            updateValue(value + 1);
        } else if (e.key === "ArrowDown") {
            updateValue(value - 1);
        }
    };

    return (
        <ButtonGroup className={classnames(styles.container, classNames)}>
            <Button
                size="sm"
                className={classnames(styles.button, styles.leftButton)}
                onClick={() => updateValue(value - 1)}
                disabled={value <= minimumValue || disabled}
                data-testid="decrease-quantity"
            >
                <i className="fa fa-minus-circle" />
            </Button>
            <Input
                onKeyUp={onKeyUp}
                disabled={value <= minimumValue || disabled}
                value={value.toString()}
                onChange={e => updateValue(e.target.value)}
                className={classnames(styles.input, "py-3 text-center")}
                data-testid="quantity"
            />
            <Button
                size="sm"
                disabled={disabled}
                className={classnames(styles.button, styles.rightButton)}
                onClick={() => updateValue(value + 1)}
                data-testid="increase-quantity"
            >
                <i className="fa fa-plus-circle" />
            </Button>
        </ButtonGroup>
    );
};

export { ProductQuantity };
