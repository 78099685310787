import api from "@/Utils/api";

export interface CreatePackageRequest {
    name: string;
    description: string;
    showOnMinisite: boolean;
    products: PackageItem[];
}

export interface PackageItem {
    productId: string;
    quantity: number;
    supplierName: string;
}

export interface Product {
    id: string;
    name: string;
    categoryName: string;
    subCategoryName: string;
    paymentFrequency: string;
    initialCharge: number;
    initialChargeWithVatIfApplicable: number;
    postageCharge: number;
    isVatRequired: boolean;
    productVariant: string;
    supplierName: string;
    quantity: number;
    isOfflineInvoiceable: boolean;
    isOffsitePayment: boolean;
}

export interface Package {
    id: string;
    code: string;
    dateCreated: string;
    products: Product[];
    name: string;
    description: string;
    showOnMinisite: boolean;
}

const createPackage = (pakg: CreatePackageRequest) => api.post("packages", pakg);

const getPackages = () => api.get("packages");

export {
    createPackage,
    getPackages,
};
