import React, { useState } from "react";
import Select from "react-select";
import { Button, Label } from "reactstrap";

import { EmailCredentials } from "@/Apis/EmailCredentials";
import { SelectItem } from "@/Apis/SelectItem";
import NumberField from "@/Components/NumberField";
import Textbox from "@/Components/Textbox";
import { isEmail, isEmptyOrSpaces } from "@/Utils/stringHelper";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

interface Props {
    orderId: string,
    loading: boolean;
}

export const FormEmailCredentialsTab = ({ orderId, loading }: Props) => {
    const { post } = useAPI({ handle500WithToastMessage: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [formData, setFormData] = useState<EmailCredentials>();

    const emailTypes = [
        {
            label: "POP3",
            value: "POP3",
        },
        {
            label: "IMAP",
            value: "IMAP",
        },
    ] as SelectItem[];

    const smtpEncryptionTypes = [
        {
            label: "SSL",
            value: "SSL",
        },
        {
            label: "TLS",
            value: "TLS",
        },
    ] as SelectItem[];

    const [processing, setProcessing] = useState(false);

    const onTextInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [field]: e.target.value,
        } as EmailCredentials);
    };

    const onControlInput = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
        } as EmailCredentials);
    };

    const isInvalidForm = () => isEmptyOrSpaces(formData?.type)
            || isEmptyOrSpaces(formData?.host)
            || isEmptyOrSpaces(formData?.port)
            || isEmptyOrSpaces(formData?.email) || !isEmail(formData?.email)
            || isEmptyOrSpaces(formData?.password)
            || isEmptyOrSpaces(formData?.smtpHost)
            || isEmptyOrSpaces(formData?.smtpPort)
            || isEmptyOrSpaces(formData?.smtpEncryption)
            || isEmptyOrSpaces(formData?.smtpEmail) || !isEmail(formData?.smtpEmail)
            || isEmptyOrSpaces(formData?.smtpPassword);

    const onSave = () => () => {
        if (!isInvalidForm()) {
            setProcessing(true);
            post(`supplier-orders/${orderId}/email-credentials`, formData)
                .then(() => {
                    setSuccessMessage("Email credentials saved successfully", true);
                })
                .catch(error => {
                    setPopupErrorMessage(error, true);
                })
                .finally(() => {
                    setProcessing(false);
                });
        }
    };

    return (
        <>
            <div>
                {/* Incoming */}
                <div className="tw-text-lg tw-font-bold tw-pb-2">Incoming</div>
                <div className="tw-mb-2">
                    <Label>Type *</Label>
                    <Select
                        value={emailTypes?.find(x => x.value === formData?.type)}
                        onChange={e => onControlInput("type", e?.value)}
                        options={emailTypes}
                        isDisabled={processing}
                        isClearable
                    />
                </div>
                <Textbox
                    label="Host *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("host")}
                    value={formData?.host}
                    disabled={processing}
                />
                <NumberField
                    min={0}
                    max={65535}
                    label="Port *"
                    onChange={onTextInput("port")}
                    value={formData?.port}
                    maxDecimalPlaces={0}
                    className="tw-mb-2"
                    placeholder="0-65535"
                    greaterThanMsg="Port 65535 is valid in common"
                    disabled={processing}
                />
                <Textbox
                    label="Email *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("email")}
                    value={formData?.email}
                    placeholder="someone@example.com"
                    disabled={processing}
                />
                <Textbox
                    label="Password *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("password")}
                    value={formData?.password}
                    type="password"
                    disableAutoComplete
                    disabled={processing}
                />
                {/* Outgoing */}
                <div className="tw-text-lg tw-font-bold tw-pt-4 tw-pb-2">Outgoing</div>
                <Textbox
                    label="SMTP Host *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("smtpHost")}
                    value={formData?.smtpHost}
                    disabled={processing}
                />
                <NumberField
                    min={0}
                    max={65535}
                    label="SMTP Port *"
                    onChange={onTextInput("smtpPort")}
                    value={formData?.smtpPort}
                    maxDecimalPlaces={0}
                    className="tw-mb-2"
                    placeholder="0-65535"
                    greaterThanMsg="Port 65535 is valid in common"
                    disabled={processing}
                />
                <div className="tw-mb-2">
                    <Label>SMTP Encryption *</Label>
                    <Select
                        value={smtpEncryptionTypes?.find(x => x.value === formData?.smtpEncryption)}
                        onChange={e => onControlInput("smtpEncryption", e?.value)}
                        options={smtpEncryptionTypes}
                        isDisabled={processing}
                        isClearable
                    />
                </div>
                <Textbox
                    label="SMTP Email *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("smtpEmail")}
                    value={formData?.smtpEmail}
                    placeholder="someone@example.com"
                    disabled={processing}
                />
                <Textbox
                    label="SMTP Password *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("smtpPassword")}
                    value={formData?.smtpPassword}
                    type="password"
                    disableAutoComplete
                    disabled={processing}
                />
            </div>
            <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-3">
                <Button
                    color="primary"
                    onClick={onSave()}
                    disabled={loading || processing || isInvalidForm()}
                    data-testid="btn-save"
                    size="lg"
                >
                    {processing ? (<Spinner className="mx-auto" />) : "Save"}
                </Button>
            </div>
        </>
    );
};
