import { Filter } from "@/FlexPlan/Types/Pagination";
import { FlexPlanAudit } from "@/FlexPlan/Types/Audit";

export interface FlexPlanAuditSearchForm {
    search: string,
    modules: Filter[],
    createdBy: Filter[],
    entityId: string;
    from?: Date,
    to?: Date,
}

export interface AuditResponse {
    createdByOptions: CreatedByUser[],
    data: FlexPlanAudit[],
    pageNumber: number;
    itemsPerPage: number;
    resultsCount: number;
    totalResultsCount: number;
    totalPages: number;
}

export interface CreatedByUser {
    id: string,
    fullname: string,
}

export interface PaginationParameters {
    itemsPerPage: number;
    pageNumber: number;
}

export enum AuditEntityType {
    Participant = "participant",
    Supplier = "supplier",
}
