import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { useBasketContext } from "@/Context/BasketContext";
import { useSignalRContext } from "@/Context/SignalRContext";
import { setToken } from "@/Utils/authentication";
import notificationActions from "@/Store/Notifications/actions";
import actions from "@/Store/User/actions";

const useAuthentication = () => {
    const { loadBasket } = useBasketContext();
    const signalR: any = useSignalRContext();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const sendGtmEvent = useGTMDispatch();

    const onLoginSuccess = async response => {
        setToken(response.token, response.tokenExpiry);

        await loadBasket();

        dispatch(notificationActions.loadNotifications$());
        dispatch(actions.setUser(response.profileMetadata));

        signalR.reconnect();

        if (location.state && location.state.from && location.state.from.indexOf("login") === -1) {
            dispatch(push(location.state.from));
        } else if (query.returnUrl) {
            dispatch(push(query.returnUrl));
        } else {
            dispatch(push("/dashboard"));
        }
    };

    const onSignUpSuccess = async response => {
        setToken(response.token, response.tokenExpiry);

        await loadBasket();
        dispatch(notificationActions.loadNotifications$());
        dispatch(actions.setUser(response.profileMetadata));

        sendGtmEvent(
            {
                ecommerce: null,
                event: "sign_up",
            },
        );

        signalR.reconnect();

        if (query.returnUrl) {
            dispatch(push(query.returnUrl));
        } else {
            dispatch(push("/dashboard"));
        }
    };

    return {
        onLoginSuccess,
        onSignUpSuccess,
    };
};

export { useAuthentication };
