import api from "@/Utils/api";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";
import { ProductType } from "@/Apis/Products/ProductType";

const saveProduct = (request: ProductType) => {
    if (request.productVariant === ProductVariants.singleProduct
            || request.productVariant === ProductVariants.formationsProduct
            || request.productVariant === ProductVariants.readyMadeFormationsProduct
            || request.productVariant === ProductVariants.bankAccountProduct) {
        return api.post("products/single", request);
    }
    if (request.productVariant === ProductVariants.enquiryFormProduct) {
        return api.post("products/enquiryForm", request);
    }
    if (ProductVariants.canBeCustomised(request.productVariant as keyof ProductVariant)) {
        return api.post("products/variation", request);
    }

    throw Error("Invalid product type");
};

const removeProduct = (id: string) => api.del(`products/${id}`);

const getProducts = (productIds: string[]) => api.post("products", { productIds });

const uploadProductVariantDocument = (document: {
    rowId: string;
    productId: string;
    base64: string;
    documentName: string;
}) => api.post("products/variation/document", document);

export {
    saveProduct,
    removeProduct,
    getProducts,
    uploadProductVariantDocument,
};
