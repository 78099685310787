import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Row, Col, Button } from "reactstrap";
import { Route, Switch } from "react-router-dom";
import queryString from "query-string";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";

import { getServicesToDisplay } from "../../Marketplace/miniSiteHelper";

import Footer from "@/Components/Footer";
import AtoZFilter from "@/Components/AtoZFilter";
import SupplierButton from "@/Components/SupplierButton";
import { CartButton } from "@/Components/CartButton";
import Spinner from "@/Components/Spinner";
import CloseButton from "@/Components/CloseButton";
import { CartItem } from "@/Components/CartItem";
import MiniSite from "@/Components/MiniSite";
import { DueToSupplierPopup } from "@/Components/DueToSupplierPopup";
import PackageCreatedModal from "@/Modals/PackageCreatedModal";
import { createPackage } from "@/Apis/Packages";
import SupplierTile from "@/Pages/Packages/PackageDirectoryPage/SupplierTile";
import { useSuppliersContext } from "@/Context/SuppliersContext";
import Suppliers from "@/Pages/Packages/PackageDirectoryPage/Suppliers";
import globalActions from "@/Store/Global/actions";
import { ProductVariants } from "@/Utils/ProductVariants";

const PackageDirectoryPage = () => {
    const PAGE_URL = "/packages/package-directory";
    const { supplier, loading, loadSupplierDashboard, clearSuppliers, loadMiniSite } = useSuppliersContext();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const query = queryString.parse(location.search, { arrayFormat: "comma" });
    const filters = query.filters || [];
    const [letterFilters, setLetterFilters] = useState([]);
    const [localFilters, setLocalFilters] = useState(Array.isArray(filters) ? [...filters] : [filters]);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeCategoryTab, setActiveCategoryTab] = useState("");
    const [products, setProducts] = useState([]);
    const [newPackageCode, setNewPackageCode] = useState("");
    const miniSiteRef = React.createRef();
    const [miniSiteHeight, setMiniSiteHeight] = useState("100vh");
    const [packageDetails, setPackageDetails] = useState({
        name: "Package", // default name
        description: "",
        showOnMinisite: false,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "BSC - Packages";

        if (location.state) { // package details will be set from package specifics modal
            setPackageDetails((prevState) => ({
                ...prevState,
                name: location.state.name,
                description: location.state.description,
                showOnMinisite: location.state.showOnMinisite,
            }));
        }

        loadSupplierDashboard({
            filters: localFilters,
            searchTerm,
            supplierId: match.params.id,
        });
        if (miniSiteRef.current?.clientHeight) {
            setMiniSiteHeight(`${miniSiteRef.current.clientHeight}px`);
        }
        return () => {
            clearSuppliers();
        };
    }, [searchTerm, localFilters]);

    const onSupplierClick = (id) => {
        loadMiniSite(id);
        history.push(`${PAGE_URL}/${id}${location.search}`);
        setActiveCategoryTab(undefined);
    };

    const changeCategoryTab = (tab) => {
        if (activeCategoryTab !== tab) {
            setActiveCategoryTab(tab);
        }
    };

    const onSearch = (search) => {
        setSearchTerm(search);
        setLocalFilters([]);
        history.push(PAGE_URL);
    };

    const searchDebounce = AwesomeDebouncePromise(onSearch, 400);

    const toggleMiniCart = () => {
        if (query.cart) {
            delete query.cart;
        } else {
            query.cart = "open";
        }
        history.push(`${location.pathname}?${queryString.stringify(query)}`);
    };

    const onCategoriesFiltersChange = (id) => {
        if (id === "all") {
            history.push(PAGE_URL);
            setLocalFilters([]);
            return;
        }

        setLocalFilters(prevState => {
            let newFilters;
            if (prevState.includes(id)) {
                newFilters = prevState.filter(x => x !== id);
            } else {
                newFilters = [...prevState, id];
            }

            if (newFilters.length > 0) {
                history.push(`${PAGE_URL}?filters=${newFilters.join(",")}`);
            } else {
                history.push(PAGE_URL);
            }

            return newFilters;
        });
    };

    const onLetterFiltersChange = (filter) => {
        setLetterFilters(filter);
    };

    const renderSuppliers = () => {
        const lowerCaseFilters = letterFilters.map(filter => filter.toLowerCase());
        return (
            <Suppliers
                suppliers={
                    letterFilters.length > 0
                        ? supplier.suppliers.filter(x => lowerCaseFilters.includes(x.name.toLowerCase().substring(0, 1)))
                        : supplier.suppliers
                }
                onSupplierClick={onSupplierClick}
            />
        );
    };

    const addToPackage = (service, qty) => {
        dispatch(globalActions.setToastMessage(true, "Product was added to your package"));
        setProducts(currentState => {
            const alreadyInCart = currentState.find(x => x.id === service.id);

            if (alreadyInCart) {
                return currentState.map(x => (x.id === service.id ? { ...x, quantity: x.quantity + qty } : x));
            }

            return currentState.concat([{
                quantity: qty,
                supplierId: supplier.supplierMiniSite.id,
                supplierName: supplier.supplierMiniSite.name,
                ...service,
            }]);
        });
    };

    const removeFromPackage = (service) => setProducts(currentState => (
        currentState.filter(x => x.id !== service.id)));

    const createPackageCode = () => {
        const productArr = products.map(x => ({
            productId: x.id,
            quantity: x.quantity,
            supplierName: x.supplierName,
        }));

        createPackage({
            name: packageDetails.name,
            description: packageDetails.description,
            showOnMinisite: packageDetails.showOnMinisite,
            products: productArr,
        })
            .then((response) => setNewPackageCode(response.customCode));
    };

    const closeModal = () => {
        toggleMiniCart();
        setNewPackageCode(null);
        setProducts([]);
    };

    const calculatePackageTotal = () => products
        .filter(x => !x.isOffsitePayment)
        .map(x => (x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge)
        .reduce((total, x) => total + x, 0);

    const renderSuppliersWithMiniSite = () => {
        const servicesToDisplay = getServicesToDisplay(supplier.supplierMiniSite.services, activeCategoryTab || supplier.supplierMiniSite.categoriesToDisplay[0]);
        const suppliers = letterFilters.length > 0 ? supplier.suppliers.filter(x => letterFilters.includes(x.name.substring(0, 1))) : supplier.suppliers;
        return (
            <>
                <Col
                    lg="3"
                    style={{ height: miniSiteHeight }}
                    className={classnames(query.cart ? "d-none" : "d-lg-flex flex-column", "d-none pt-2 pr-0 mb-5 overflow-y-auto")}
                >
                    {suppliers
                        .map(x => (
                            <div className="mb-3 mr-3" key={x.id + x.categoryName}>
                                <SupplierTile
                                    id={x.id}
                                    name={x.name}
                                    description={x.description}
                                    categoryName={x.categories && x.categories.join(", ")}
                                    primarySiteColour={x.primarySiteColour}
                                    logo={x.logo}
                                    rating={x.rating}
                                    onSupplierClick={() => onSupplierClick(x.id)}
                                    selectedSupplierId={supplier.supplierMiniSite.id}
                                />
                            </div>
                        ))}
                </Col>

                <Col xs="12" lg="9" className={classnames(query.cart && "d-none d-lg-flex", "pt-2")}>
                    <div ref={miniSiteRef}>
                        <MiniSite
                            selectedSupplierId={supplier.supplierMiniSite.id}
                            onClose={() => history.push(PAGE_URL)}
                            changeCategoryTab={changeCategoryTab}
                            activeCategoryTab={activeCategoryTab || supplier.supplierMiniSite.categoriesToDisplay[0]}
                            addToBasket={addToPackage}
                            itemIdsInCart={products.map(x => x.id.toUpperCase())}
                            servicesToDisplay={servicesToDisplay}
                            categoriesToDisplay={supplier.supplierMiniSite.categoriesToDisplay}
                            {...supplier.supplierMiniSite}
                            isBeingUsedForPackages
                        />
                    </div>
                </Col>
            </>
        );
    };

    return (
        <>
            <Row className="m-0 px-3 d-block">
                <AtoZFilter
                    onSearch={e => searchDebounce(e.target.value)}
                    goBack={() => history.push("/")}
                    onLetterFilter={onLetterFiltersChange}
                    onCategoryFilter={onCategoriesFiltersChange}
                    categories={filters}
                    selectedCategories={localFilters}
                    selectedLetters={letterFilters}
                />
            </Row>
            <Row className="my-3">
                <Col xs="12">
                    {query.cart && match.params.id && <div className="d-block"><SupplierButton onClick={toggleMiniCart} /></div>}
                    {!query.cart && <CartButton customText="Package" onClick={toggleMiniCart} />}
                </Col>
            </Row>

            {loading ? (<div className="d-flex justify-content-center align-items-center"><Spinner /></div>)
                : (
                    <Row className="px-2 mb-5">
                        <Route
                            render={({ match: { url } }) => (
                                <Switch>
                                    <Route exact path="/packages/package-directory/:id" render={renderSuppliersWithMiniSite} />
                                    <Route path={`${url}/`} render={renderSuppliers} />
                                </Switch>
                            )}
                        />

                        <Col xs="12" lg="3" className={classnames(query.cart && "d-flex flex-column", "d-none pt-2")}>
                            <div className="position-relative rounded-lg bg-white pb-3 box-shadow py-3 d-flex flex-column flex-grow-1">
                                <div className="p-3 flex-grow-1">
                                    <CloseButton onClick={toggleMiniCart} />
                                    <h4 className="">Package</h4>

                                    <div className="flex-grow-1">
                                        {products.map((x, i) => (
                                            <CartItem
                                                id={x.id}
                                                quantity={x.quantity}
                                                price={x.initialCharge}
                                                totalVat={x.isVatRequired ? x.initialChargeWithVatIfApplicable - x.initialCharge : 0}
                                                totalPrice={(x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge}
                                                postage={x.postageCharge}
                                                serviceName={x.name}
                                                category={x.categoryName}
                                                subcategory={x.subCategoryName}
                                                supplierId={x.supplierId}
                                                supplierName={x.supplierName}
                                                onRemove={() => removeFromPackage(x)}
                                                noBorder={i === products.length - 1}
                                                showPriceAsMinimum={x.productVariant === ProductVariants.listedProduct}
                                                paymentFrequency={x.paymentFrequency}
                                                isOffsitePayment={x.isOffsitePayment}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <div className="bg-light d-flex justify-content-between p-3 pt-4 border-bottom">
                                        <h5 className="font-weight-bold">Subtotal</h5>
                                        <h4>£{calculatePackageTotal().toFixed(2)}</h4>
                                    </div>
                                    {products.some(item => item.isOffsitePayment) && (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center p-3 text-muted font-weight-bold">
                                                <h6>DUE TO SUPPLIER</h6>
                                                <h6>
                                                    <DueToSupplierPopup
                                                        placement="left"
                                                        items={products
                                                            .filter(item => item.isOffsitePayment)
                                                            .map(x => ({
                                                                id: x.id,
                                                                name: x.name,
                                                                categoryName: x.categoryName,
                                                                subCategoryName: x.subCategoryName,
                                                                paymentFrequency: x.paymentFrequency,
                                                                initialCharge: x.initialCharge,
                                                            }))}
                                                    />
                                                </h6>
                                            </div>
                                        </>
                                    )}
                                    <div className="pt-3 text-center">
                                        <Button disabled={products.length < 2} onClick={createPackageCode} color="primary">
                                            Generate Code
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

            <Footer />

            {newPackageCode && <PackageCreatedModal code={newPackageCode} onClose={closeModal} />}
        </>
    );
};

export { PackageDirectoryPage };
