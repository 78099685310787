import api from "@/Utils/api";

const accept = () => api.post("cookies") as Promise<void>;

const getHasAccepted = () => api.get("cookies") as Promise<{ isVerified: boolean }>;

export {
    accept,
    getHasAccepted,
};
