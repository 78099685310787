import React, { ReactNode, useContext, useRef, useState } from "react";

import { useBasketContext } from "@/Context/BasketContext";
import { getOnboardingSteps, OnboardingProps, OnboardingSteps } from "@/Context/OnboardingContext/onBoardingSteps";
import { useFilterContext } from "@/Context/FilterContext";

export type OnboardingContextType = {
    showOnBoardingStep(name: OnboardingSteps): void;
    shouldShowOnboarding: boolean;
    currentOnboardingStep: OnboardingProps;
    selectRef;
    filterRef;
    closeOnboarding(): void;
    nextOnBoardingStep(): void;
    loadOnBoarding(): void;
    resetOnBoarding(): void;
}

const OnboardingContext = React.createContext<OnboardingContextType>({
    showOnBoardingStep: () => {},
    shouldShowOnboarding: false,
    currentOnboardingStep: {} as OnboardingProps,
    selectRef: {},
    filterRef: {},
    closeOnboarding: () => {},
    nextOnBoardingStep: () => {},
    loadOnBoarding: () => {},
    resetOnBoarding: () => {},
});
const useOnboardingContext = () => useContext(OnboardingContext);

const OnboardingProvider = ({ children }: { children: ReactNode }) => {
    const selectRef = useRef<any>(null); // allows us to control search bar
    const filterRef = useRef<any>(null); // allows us to control show filters
    const { toggleBasket } = useBasketContext();
    const { toggleFilters } = useFilterContext();
    const [onboardingState] = useState(getOnboardingSteps(selectRef, filterRef, toggleBasket, toggleFilters));
    const [currentOnboardingStep, setCurrentOnboardingStep] = useState<OnboardingProps>(onboardingState[0]);
    const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);

    const closeOnboarding = () => setShouldShowOnboarding(false);

    const nextOnBoardingStep = () => {
        setCurrentOnboardingStep({ ...onboardingState[onboardingState.findIndex(x => x.name === currentOnboardingStep?.name) + 1] });
    };

    const showOnBoardingStep = (name: OnboardingSteps) => {
        if (name === OnboardingSteps.searchRecommendations && selectRef.current) {
            selectRef.current.select.state.menuIsOpen = true;
        }
        document.getElementById("mainScrollableDiv")?.scrollTo(0, 0);
        setCurrentOnboardingStep({ ...onboardingState.find(x => x.name === name) as OnboardingProps });
        setShouldShowOnboarding(true);
    };

    const loadOnBoarding = () => {
        setShouldShowOnboarding(true);
    };

    const resetOnBoarding = () => {
        setShouldShowOnboarding(false);
        setCurrentOnboardingStep(onboardingState[0]);
    };

    return (
        <OnboardingContext.Provider value={
            {
                showOnBoardingStep,
                shouldShowOnboarding,
                currentOnboardingStep,
                selectRef,
                filterRef,
                closeOnboarding,
                nextOnBoardingStep,
                loadOnBoarding,
                resetOnBoarding,
            } as OnboardingContextType
        }
        >
            {children}
        </OnboardingContext.Provider>);
};

export { useOnboardingContext, OnboardingProvider };
