import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Input, Row } from "reactstrap";
import Dropzone from "react-dropzone";
import { useHistory, useLocation } from "react-router";

import styles from "./styles.module.scss";

import Textbox from "@/Components/Textbox";
import { RichTextEditor } from "@/Components/RichTextEditor";
import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { EditBespokeQuote } from "@/Components/BespokeQuotes/EditBespokeQuote";
import { BespokeQuote } from "@/Components/BespokeQuotes/BespokeQuote";
import { AccordionHeading } from "@/Components/AccordionHeading";
import ComponentBuilder from "@/Components/ComponentBuilder";
import Spinner from "@/Components/Spinner";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { acceptedFileTypes } from "@/Utils/constants";
import formatDateHelper from "@/Utils/formatDateHelper";
import { encodeFileToBase64 } from "@/Utils/base64EncodeHelper";
import { uppercaseFirstLetter } from "@/Utils/formatStringHelper";
import { AttachmentType,
    GetConversationResponse,
    MessagingUrls,
    ReplyToConversationRequest,
    UploadConversationAttachmentRequest } from "@/Apis/Messaging";
import { useAPI } from "@/Apis/useAPI";
import { Urls } from "@/Apis/urls";
import { downloadFile } from "@/Utils/dowloadHelper";
import { ReceiptStatus } from "@/Apis/Receipts";
import { isEmptyOrSpaces, receiptTypeName } from "@/Utils/stringHelper";
import { useBasketContext } from "@/Context/BasketContext";
import { AlertStyle, ErrorToastMessage } from "@/Context/ToastMessageContext/ErrorToastMessage";
import { useMessagingContext } from "@/Context/MessagingContext";
import { ReceiptType } from "@/Apis/Receipts/Create";

interface Props {
    message: GetConversationResponse;
    enquiryFormFields: any[];
    onReply(conversationId: string, data: ReplyToConversationRequest): void;
}

const MessageDetailsTab = (props: Props) => {
    const history = useHistory();
    const { addReceiptToBasket } = useBasketContext();
    const [attachments, setAttachments] = useState([] as Array<AttachmentType>);
    const [showGenerateQuoteSection, setShowGenerateQuoteSection] = useState(false);
    const [replyEditorState, setReplyEditorState] = useState(createEditorState(""));
    const [quoteSectionOpen, setQuoteSectionOpen] = useState(props.message.bespokeQuotes.length > 0);
    const [discussionSectionOpen, setDiscussionSectionOpen] = useState(false);
    const { post, get, getFile } = useAPI({ handle500WithRedirect: true });
    const { post: sendRejectionReason, loading: rejectReasonLoading } = useAPI({ handle500WithRedirect: true });

    // Supplier receipts
    const [supplierReceiptsOpen, setSupplierReceiptsOpen] = useState<boolean>(props.message.isSupplierReceipt);
    const [rejectionReason, setRejectionReason] = useState<string>();
    const [activeReceiptDecision, setActiveReceiptDecision] = useState<ReceiptStatus>();
    const messageContext = useMessagingContext();
    const receiptReferenceElement = useRef<any>();
    const location = useLocation<{ directToReceiptSection: boolean }>();
    const [error, setError] = useState<string | null>(null);
    const [rejectionReasonRequiredError, setRejectReasonRequiredError] = useState<boolean>(false);
    const [rejectionReasonSubmittedSuccessfully, setRejectionReasonSubmittedSuccessfully] = useState<boolean>(false);

    const onReceiptDecision = (decision: ReceiptStatus) => () => {
        setActiveReceiptDecision(decision);
        messageContext.toggleReceiptConfirmModal();
    };

    const onSupplierReceiptDecisionOverride = (decision: ReceiptStatus) => () => {
        messageContext.supplierQuoteDecision(decision);
    };

    useEffect(() => {
        if (location.state && location.state.directToReceiptSection) {
            receiptReferenceElement.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.state]);

    const [downloadFileProcessing, setDownloadFileProcessing] = useState<boolean>(false);

    const onEditorChange = (changedEditorState) => {
        setReplyEditorState(changedEditorState);
    };

    const onReply = () => {
        props.onReply(props.message.conversationId, {
            conversationId: props.message.conversationId,
            body: getContentStateAsJson(replyEditorState),
            attachments,
        });
        setReplyEditorState(createEditorState(""));
        setAttachments([]);
    };

    const showGenerateQuote = () => {
        setQuoteSectionOpen(true);
        setShowGenerateQuoteSection(true);
    };

    const onDrop = (files) => {
        files.forEach((x) => {
            encodeFileToBase64(x)
                .then((encodedFile) => {
                    post<AttachmentType>(MessagingUrls.uploadConversationAttachment(props.message.conversationId),
                        { base64: encodedFile.target?.result, conversationId: props.message.conversationId, filename: x.name } as UploadConversationAttachmentRequest)
                        .then(response => {
                            setAttachments(prevState => ([...prevState, response]));
                        });
                });
        });
    };

    const hideGenerateQuoteSection = () => {
        setShowGenerateQuoteSection(false);
    };

    const toggleQuotationsQuoteSection = () => {
        setQuoteSectionOpen(!quoteSectionOpen);
    };

    const toggleDiscussionsSection = () => {
        setDiscussionSectionOpen(!discussionSectionOpen);
    };

    const onCheckoutReceipt = async () => {
        if (await addReceiptToBasket({ receiptId: props.message.receiptId })) {
            history.push("/basket");
        } else {
            setError(`Unable to add ${receiptTypeName(props.message.receiptType).toLowerCase()} to basket`);
        }
    };

    const onChangeRejectionReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRejectReasonRequiredError(false);
        setRejectionReason(e.target.value);
    };

    const onSendRejectionReason = () => {
        if (isEmptyOrSpaces(rejectionReason)) {
            setRejectReasonRequiredError(true);
            return;
        }

        sendRejectionReason("receipts/reject-reason", { receiptId: props.message.receiptId, rejectionReason })
            .then(() => setRejectionReasonSubmittedSuccessfully(true));
    };

    const downloadReceiptPdf = (id: string) => {
        setDownloadFileProcessing(true);
        getFile(Urls.receipts.downloadPdf(id))
            .then(response => {
                if (response?.name && response?.contents) {
                    downloadFile(response.name, response.contents, true);
                }
            })
            .finally(() => {
                setDownloadFileProcessing(false);
            });
    };

    const downloadReceiptAttachment = (id: string) => {
        setDownloadFileProcessing(true);
        get<any>(Urls.receiptAttachments.getFile(id))
            .then(response => {
                if (response?.fileName && response?.fileContent) {
                    downloadFile(response.fileName, response.fileContent);
                }
            })
            .finally(() => {
                setDownloadFileProcessing(false);
            });
    };

    const firstMessage = props.message.conversation[props.message.conversation.length - 1];

    return (
        <>
            {error && <ErrorToastMessage message={error} alertStyle={AlertStyle.PopUp} />}
            <Row className="m-4">
                <Col xs="12" md="6">
                    <Textbox disabled label="Enquiry No." value={props.message.conversationReference} />
                </Col>
                <Col xs="12" md="6">
                    <Textbox disabled label="Enquiry Date and Time" value={formatDateHelper.forMessageListing(firstMessage.sentAt)} />
                </Col>
                <Col xs="12" md="6">
                    <Textbox disabled label="Subject" value={props.message.subject} />
                </Col>
                <Col xs="12" md="6">
                    <Textbox disabled label="Customer/Business" value={firstMessage.from} />
                </Col>

                <Col xs="12">
                    <span>Message</span>
                    <RichTextEditor
                        placeholder=""
                        editorState={createEditorState(firstMessage.body)}
                        readonly
                        showToolbar={false}
                    />
                </Col>

                <Col className="mt-4" xs="12">
                    {props.enquiryFormFields
                    && props.enquiryFormFields.map(formField => (<ComponentBuilder
                        key={formField.fieldId}
                        fields={[formField]}
                        disabled
                        onChange={() => () => { }}
                    />))}
                </Col>

                <Col xs="12">
                    <div className="font-weight-bold mt-3">Reply</div>
                    <div className="rounded-lg border">
                        <div className="border-bottom p-3 d-flex justify-content-between">
                            <span>From: You</span>
                            <span>{formatDateHelper.forMessageListing()}</span>
                        </div>
                        <Dropzone
                            onDrop={onDrop}
                            maxSize={21495808}
                            // @ts-ignore
                            onClick={evt => evt.preventDefault()}
                            tabIndex={-1}
                            noClick
                            accept={acceptedFileTypes.documents}
                        >
                            {({ getRootProps, getInputProps }) => ( // TODO fix drag and drop
                                <div {...getRootProps()} tabIndex={-1}>
                                    <input {...getInputProps()} tabIndex={-1} />
                                    <RichTextEditor
                                        placeholder="Your reply"
                                        editorState={replyEditorState}
                                        onChange={onEditorChange}
                                    />
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </Col>

                <Col xs="12" className="mt-3 mb-4">
                    <Button
                        color="primary"
                        className="mr-3"
                        disabled={replyEditorState.getCurrentContent().getPlainText() === ""}
                        onClick={onReply}
                    >
                        Send
                    </Button>
                    <Dropzone onDrop={onDrop} multiple maxSize={8000000} accept={acceptedFileTypes.documents}>
                        {({ getRootProps, getInputProps }) => (
                            <button type="button" className="p-0 border-0 bg-transparent text-dark" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <i className="fa fa-paperclip mr-2" />
                            </button>
                        )}
                    </Dropzone>
                    {attachments.length ? (
                        <span>{attachments.length} file/s attached</span>
                    ) : false}

                    {props.message.isEnquiry && props.message.isEnquirySupplier && (
                        <Button color="primary" className="float-right" outline onClick={showGenerateQuote} disabled={showGenerateQuoteSection}>
                            Generate Quote
                        </Button>
                    )}
                </Col>

                {/* If it's an enquiry, don't display our supplier receipts */}
                {!props.message.isEnquiry && props.message.isSupplierReceipt && (
                    <AccordionHeading
                        heading={`${receiptTypeName(props.message.receiptType)}s`}
                        onToggle={() => setSupplierReceiptsOpen(!supplierReceiptsOpen)}
                        open={supplierReceiptsOpen}
                        headerClassName="font-weight-bold"
                    >
                        <hr />
                        <div className="tw-border tw-border-gray-200 tw-mt-3" ref={receiptReferenceElement}>
                            <div className="tw-text-base tw-p-4">Created on: {formatDateHelper.format(props.message.receiptDateCreated, "DD MMM YYYY h:mm A")}</div>
                            <div className="tw-border tw-flex tw-flex-col tw-space-y-5 tw-items-center tw-py-4">
                                {props.message.receiptStatus === ReceiptStatus.Accepted && props.message.receiptType === ReceiptType.Quotation && (
                                    <div
                                        className="tw-border tw-border-2 tw-border-lime-400
                                         tw-text-lime-500 tw-w-4/5 lg:tw-w-2/3 tw-py-5 tw-p-3 tw-rounded-lg tw-flex tw-items-center"
                                    >
                                        Quotation accepted.
                                    </div>
                                )}
                                {props.message.receiptStatus === ReceiptStatus.Rejected && props.message.receiptType === ReceiptType.Quotation && (
                                    <div
                                        className="tw-border tw-border-2 tw-border-red-600
                                        tw-text-red-600 tw-w-4/5 lg:tw-w-2/3 tw-py-5 tw-p-3 tw-rounded-lg tw-flex tw-items-center"
                                    >
                                        Quotation rejected.
                                    </div>
                                )}
                                {downloadFileProcessing && <Spinner />}
                                <button
                                    type="button"
                                    className="tw-border tw-border-brand-primary tw-w-4/5 lg:tw-w-2/3 tw-text-brand-primary tw-p-3 tw-rounded-lg tw-flex tw-items-center"
                                    onClick={() => downloadReceiptPdf(props.message.receiptId)}
                                >
                                    <span>{receiptTypeName(props.message.receiptType)} #{props.message.receiptRefNo}</span>
                                    <i className="fa fa-download tw-ml-auto" />
                                </button>
                                {props.message.receiptAttachments.map(attach => (
                                    <button
                                        type="button"
                                        className="tw-border tw-border-brand-primary tw-w-4/5 lg:tw-w-2/3 tw-text-brand-primary tw-p-3 tw-rounded-lg tw-flex tw-items-center"
                                        onClick={() => downloadReceiptAttachment(attach.id)}
                                    >
                                        <span>{attach.filename}</span>
                                        <i className="fa fa-download tw-ml-auto" />
                                    </button>
                                ))}
                                <div className="tw-mt-4 tw-space-x-3 tw-justify-center">
                                    {props.message.receiptStatus === ReceiptStatus.Sent && props.message.receiptType === ReceiptType.Quotation && (
                                        <>
                                            <Button
                                                color="success"
                                                size="lg"
                                                className="!tw-text-white !tw-px-8"
                                                onClick={onReceiptDecision(ReceiptStatus.Accepted)}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                color="danger"
                                                size="lg"
                                                className="!tw-px-8"
                                                onClick={onReceiptDecision(ReceiptStatus.Rejected)}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                    {((props.message.receiptStatus === ReceiptStatus.Accepted
                                        && props.message.receiptType === ReceiptType.Quotation)
                                    || (props.message.receiptType === ReceiptType.Invoice)) && (
                                        <Button color="primary" size="lg" className="!tw-px-4" onClick={onCheckoutReceipt}>Proceed to Checkout</Button>
                                    )}
                                    {props.message.receiptStatus === ReceiptStatus.Void && (
                                        <Alert color="warning">This quotation is void as an updated version was created.</Alert>
                                    )}
                                </div>
                                {props.message.receiptStatus === ReceiptStatus.Rejected
                                    && props.message.receiptType === ReceiptType.Quotation
                                    && isEmptyOrSpaces(props.message.receiptRejectReason)
                                    && !rejectionReasonSubmittedSuccessfully && (
                                    <>
                                        <div className="p-2 mt-4 mx-4 d-flex justify-content-between rounded-lg border tw-w-4/5 lg:tw-w-2/3">
                                            <Input
                                                type="text"
                                                placeholder="Enter reason"
                                                onChange={onChangeRejectionReason}
                                                className="mr-2 border-0 w-75"
                                                value={rejectionReason}
                                                validation
                                                invalid={rejectionReasonRequiredError}
                                            />
                                            <Button color="primary" onClick={onSendRejectionReason} disabled={rejectReasonLoading}>
                                                {rejectReasonLoading ? <Spinner className="tw-mx-auto" /> : "Send Reason"}
                                            </Button>
                                        </div>
                                        {rejectionReasonRequiredError && (
                                            <div className="tw-text-red-600">
                                                Please enter a rejection reason.
                                            </div>
                                        )}
                                    </>
                                )}
                                {props.message.receiptStatus === ReceiptStatus.Rejected
                                    && props.message.receiptType === ReceiptType.Quotation
                                    && (!isEmptyOrSpaces(props.message.receiptRejectReason || rejectionReasonSubmittedSuccessfully)) && (
                                    <Alert color="info">
                                        Reason for rejection has already been submitted <i className="fa fa-check" />
                                    </Alert>
                                )}
                            </div>
                        </div>
                        <ConfirmCancelModal
                            isOpen={messageContext.confirmReceiptActionModalOpen}
                            text={`Are you sure you want to ${activeReceiptDecision === ReceiptStatus.Accepted ? "accept" : "reject"} this quotation?`}
                            onConfirm={onSupplierReceiptDecisionOverride(activeReceiptDecision ?? ReceiptStatus.Accepted)}
                            onClose={messageContext.toggleReceiptConfirmModal}
                            loading={messageContext.supplierReceiptLoading}
                        />
                    </AccordionHeading>
                )}

                {props.message.isEnquiry && (props.message.bespokeQuotes.length > 0 || showGenerateQuoteSection) && (
                    <AccordionHeading
                        heading="Quotations"
                        open={quoteSectionOpen}
                        onToggle={toggleQuotationsQuoteSection}
                        className="mx-3 mt-3 mb-2 border-bottom text-decoration-none"
                        headerClassName="font-weight-bold"
                    >
                        {props.message.bespokeQuotes
                            .map((x, index) => (
                                <BespokeQuote
                                    key={x.quoteId}
                                    isFirstQuote={index === 0}
                                    quote={x as any}
                                    conversationId={props.message.conversationId}
                                    isSupplier={props.message.isEnquirySupplier}
                                    supplierName={props.message.enquirySupplierName}
                                />
                            ))}

                        {showGenerateQuoteSection && (
                            <EditBespokeQuote
                                conversationId={props.message.conversationId}
                                quote={{
                                    isEnquiryOffsitePayment: props.message.isEnquiryOffsitePayment,
                                    customFields: props.message.enquiryCustomFields,
                                } as any}
                                onSuccess={hideGenerateQuoteSection}
                                onRemoveClick={hideGenerateQuoteSection}
                            />
                        )}
                    </AccordionHeading>
                )}

                <AccordionHeading
                    heading="Discussion"
                    open={discussionSectionOpen}
                    onToggle={toggleDiscussionsSection}
                    className="mx-3 mt-3 mb-2 border-bottom text-decoration-none"
                    headerClassName="font-weight-bold"
                >
                    <Col xs="12" className="mt-3">
                        <div className={styles.replies}>
                            {props.message.conversation.filter((conversation, index) => index !== props.message.conversation.length - 1)
                                .map(x => (
                                    <div key={x.messageId} className="d-flex flex-column border p-3">
                                        <div className="d-flex justify-content-between">
                                            <span>From: {uppercaseFirstLetter(x.from)}</span>
                                            <span>{formatDateHelper.forMessageListing(x.sentAt)}</span>
                                        </div>
                                        <RichTextEditor
                                            placeholder=""
                                            editorState={createEditorState(x.body)}
                                            editorReadonlyClassName="pt-3"
                                            readonly
                                            showToolbar={false}
                                        />
                                    </div>
                                ))}

                            {props.message.conversation.length === 1 && <span className="text-center mx-auto my-4">No discussion thread initiated yet.</span>}
                        </div>
                    </Col>
                </AccordionHeading>
            </Row>
        </>
    );
};

export { MessageDetailsTab };
