import React from "react";
import Select from "react-select";

// This is an override because, annoyingly, the height of the react-select
// component is slightly higher than a reactstrap input, resulting in visual inconsistency

const customStyles = {
    control: base => ({
        ...base,
        height: 35,
        minHeight: 35,
    }),
};

const ReactSelect = ({ ...props }: any) => (
    <Select
        {...props}
        styles={customStyles}
    />
);

export { ReactSelect };
