import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { Link } from "react-router-dom";

import { Participant } from "../Types";

import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useAPI } from "@/Apis/useAPI";
import { ReactSelect } from "@/Components/ReactSelect";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { isSubmittedOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { FormError } from "@/FlexPlan/Components/FormError";

const ParticipantDropdown = () => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });

    const [participants, setParticipants] = useState<Participant[]>();
    const { formState, disableButtons, invalidFields, onReactSelectChange } = useFlexPlanOrderContext();

    useEffect(() => {
        get<Participant[]>(FlexPlanUrls.participants.base)
            .then(response => setParticipants(response.map(x => ({ ...x,
                label: `${x.firstname} ${x.surname} - ${x.ndis}`,
                value: x.id }))));
    }, []);

    const NoOptionsMessage = props => (
        <components.NoOptionsMessage {...props}>
            <Link to="/participants/register" className="tw-text-black"><i className="fa fa-plus" />&nbsp; Create Participant Record</Link>
            <hr />
            <span>No records found.</span>
        </components.NoOptionsMessage>
    );

    return (
        <>
            <ReactSelect
                value={participants?.find(x => x.id === formState.participantId)}
                options={participants}
                components={{ NoOptionsMessage }}
                isSearchable
                className="tw-w-56 tw-text-black"
                onChange={onReactSelectChange("participantId")}
                isDisabled={loading || !participants || disableButtons || isSubmittedOrder(formState)}
            />
            {invalidFields.participantId && (
                <FormError>
                    {invalidFields.participantId}
                </FormError>
            )}
        </>);
};

export { ParticipantDropdown };
