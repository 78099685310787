import React, { useState } from "react";
import { Button, Row, Col, Label, Modal, ModalBody } from "reactstrap";
import { v4 as uuid } from "uuid";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Textbox from "@/Components/Textbox";
import RadioButton from "@/Components/RadioButton";
import { ColumnType, ComponentLookup } from "@/Components/ConfigureProductRow/ColumnTypes";
import { ConfigurableSelect, SelectOption } from "@/Components/ConfigurableSelect";
import { saveSupplierOwnedVariation } from "@/Apis/Suppliers";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";
import { ProductVariations } from "@/Utils/ProductVariations";

interface Props {
    toggle(): void;
    columns: Array<ColumnType>;
    onAddNewCustomColumn(updatedColumns: ColumnType[]): void;
    productVariant: keyof ProductVariant;
}

const AddCustomColumnModal = ({ toggle, columns, onAddNewCustomColumn, productVariant }: Props) => {
    const components = {
        textBox: "Textbox",
        addressLookUp: "AddressLookUp",
        configurableSelect: "ConfigurableSelect",
    };

    const [columnName, setColumnName] = useState("");
    const [ColumnComponent, setColumnComponent] = useState<ComponentLookup>(components.textBox as ComponentLookup);
    const [error, setError] = useState("");
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [selectOptions, setOptions] = useState<SelectOption[]>([]);
    const [isSaving, setIsSaving] = useState(false);

    const onSetColumnName = event => {
        setColumnName(event.target.value);
    };
    const onSetColumnComponent = component => () => {
        setError("");
        setColumnComponent(component);
    };

    const save = async () => {
        setIsSaving(true);
        const variationId = uuid();
        const newColumn: ColumnType = {
            currentVariations: ProductVariations.getDistinctRows(columns[0].currentVariations).map(rowId => ({
                component: ColumnComponent,
                name: columnName,
                rowId,
                rowIndex: 0,
                variationId,
                variationIndex: columns.length,
                value: ColumnComponent === "ConfigurableSelect" ? selectOptions.map(x => x.label) : [],
            })),
            name: columnName,
            component: ColumnComponent,
            variationId,
            variationIndex: columns.length,
            checked: false,
            defaultOptions: ColumnComponent === "ConfigurableSelect" ? selectOptions.map(x => x.label) : [],
        };
        await saveSupplierOwnedVariation(newColumn.variationId, newColumn);
        onAddNewCustomColumn([...columns, newColumn]);
        toggle();
        setIsSaving(false);
    };

    return (
        <Modal isOpen centered size="lg" contentClassName="bg-white">
            <ModalBody className={classnames(styles.modalContainer, "d-flex flex-column p-3 p-sm-5 flex-grow-1 position-relative")}>
                <h3 className="font-weight-bold mb-4">Add Custom Column</h3>

                <Textbox
                    label="Enter the label for a custom Column."
                    placeholder="Custom Column Label"
                    value={columnName}
                    onChange={onSetColumnName}
                />

                <Row className="mb-2">
                    <Col xs="12"><Label>Select custom input</Label></Col>
                    <Col xs="12" lg="3" className="mb-2">
                        <RadioButton
                            checked={ColumnComponent === components.textBox}
                            onChange={onSetColumnComponent(components.textBox)}
                            label="Text Input"
                            id={components.textBox}
                        />
                    </Col>
                    <Col xs="12" lg="3" className="mb-2">
                        <RadioButton
                            checked={ColumnComponent === components.addressLookUp}
                            onChange={onSetColumnComponent(components.addressLookUp)}
                            label="Address lookup"
                            id={components.addressLookUp}
                        />
                    </Col>
                    {productVariant === ProductVariants.customisableProduct && (
                        <Col xs="12" lg="3" className="mb-2">
                            <RadioButton
                                checked={ColumnComponent === components.configurableSelect}
                                onChange={onSetColumnComponent(components.configurableSelect)}
                                label="Drop-down"
                                id={components.configurableSelect}
                            />
                        </Col>)}
                </Row>

                {ColumnComponent === components.configurableSelect && (
                    <ConfigurableSelect menuIsOpen={selectIsOpen} setMenuIsOpen={setSelectIsOpen} onValidate={setError} onChange={(options) => setOptions(options)} />
                )}
                <div className="d-flex mt-4 pt-3 justify-content-center">
                    <Button color="secondary" className="mr-2" onClick={toggle}>Cancel</Button>
                    <Button color="primary" disabled={columnName.trim() === "" || error !== "" || isSaving} onClick={save} data-testid="save-custom-column">
                        Add {isSaving && ((<i className="ml-2 fas fa-circle-notch fa-spin" />))}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export { AddCustomColumnModal };
