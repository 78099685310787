import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useLocation } from "react-router";
import queryString from "query-string";

const PartnersCheckoutComplete = () => {
    const match = useRouteMatch<{ paymentId: string }>();
    const location = useLocation();
    const payload = queryString.parse(location.search).payload as string;
    return (<div data-payload={atob(payload)}>{match.params.paymentId}</div>);
};

export { PartnersCheckoutComplete };
