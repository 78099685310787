import React from "react";
import classnames from "classnames";

import Checkbox from "../Checkbox";

import { ToolTip } from "@/Components/ToolTip";

interface Props {
    checked: boolean;
    onToggle(): void;
    signup?: boolean,
}

const DefaultCard = ({ signup, checked, onToggle }: Props) => (
    <div className={classnames("p-3 w-100", signup ? "" : "border rounded mt-4 tw-flex tw-flex-row")}>
        <Checkbox
            testId="acceptTermsCheckbox"
            id="terms"
            checked={checked}
            onChange={onToggle}
            className="mr-0 d-inline-block"
        />
        <strong>
            Set as default payment method
        </strong>
        <ToolTip
            description="This payment method will become the default payment method for the customer. Any failing subscriptions will also use this payment method."
            id="set-as-default-tooltip"
            className="tw-pl-1"
        >
            <i className="fas fa-info-circle tw-text-gray-400" data-testid="info-icon" />
        </ToolTip>
    </div>
);

export { DefaultCard };
