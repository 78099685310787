import React from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import { push } from "connected-react-router";

import { SignUpForm } from "@/Components/SignUpForm";
import SignUpBackgroundWrapper from "@/Wrappers/SignUpBackgroundWrapper";
import { useAuthentication } from "@/Hooks/useAuthentication";

const SignUpPage = ({ location }) => {
    document.title = "BSC - Sign Up";

    const { onSignUpSuccess } = useAuthentication();
    const dispatch = useDispatch();

    const query = queryString.parse(location.search);

    const onLoginClicked = () => {
        if (query.returnUrl) {
            dispatch(push(`/login?returnUrl=${query.returnUrl}`));
        } else {
            dispatch(push("/login"));
        }
    };

    return (
        <SignUpBackgroundWrapper>
            <SignUpForm onSuccess={onSignUpSuccess} onNavigateToLogin={onLoginClicked} referralToken={query.r} displayLabels />
        </SignUpBackgroundWrapper>
    );
};

SignUpPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            from: PropTypes.string,
        }),
        search: PropTypes.string,
    }).isRequired,
};

export default SignUpPage;
