import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

import RadioButton from "@/Components/RadioButton";
import { ProductVariants } from "@/Utils/ProductVariants";

export const ProductVariant = ({ productVariant, checkedProductVariant, onChange, children, icon, disabled }) => {
    const toggleProduct = value => () => {
        if (!disabled) {
            onChange("productVariant")({ target: { value } });
        }
    };

    const handleKeyDown = (value) => (e) => {
        if (e.keyCode === 32) {
            toggleProduct(value);
        }
    };

    const checked = checkedProductVariant === productVariant;

    return (
        <div
            onClick={toggleProduct(productVariant)}
            role="button"
            tabIndex="0"
            onKeyDown={handleKeyDown(productVariant)}
            className={classnames("border rounded p-2", {
                [styles.productVariantContainerActive]: checked,
                [styles.productVariantContainer]: !checked && !disabled,
                [styles.productVariantContainerDisabled]: disabled,
            })}
        >
            <div className="mt-2">
                <RadioButton
                    disabled={disabled}
                    onChange={toggleProduct(productVariant)}
                    checked={checked}
                    label={productVariant.replace(/([A-Z])/g, " $1")}
                    name="useDescription"
                    id="useDescription"
                    className="mr-4"
                />
            </div>
            <div className={classnames(styles.productVariantDesc, "mt-2 mb-2")}>
                {children}
            </div>
            {icon}
        </div>
    );
};

ProductVariant.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    productVariant: PropTypes.oneOf([ProductVariants.singleProduct, ProductVariants.listedProduct, ProductVariants.customisableProduct]).isRequired,
    checkedProductVariant: PropTypes.oneOf([ProductVariants.singleProduct, ProductVariants.listedProduct, ProductVariants.customisableProduct]).isRequired,
    icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ProductVariant.defaultProps = {
    disabled: false,
};
