import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Media, Nav, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";

import styles from "./styles.module.scss";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import FloatLabelDate from "@/Components/FloatLabelDate";
import { useAPI } from "@/Apis/useAPI";
import { GetUserResponse, UpdateUserCommand } from "@/Apis/Usermanagement";
import FormatDateHelper from "@/Utils/formatDateHelper";
import { ConfirmDeleteUserModal } from "@/Modals/ConfirmDeleteUserModal";
import { useToastMessageContext } from "@/Context/ToastMessageContext";

const UserDetailsPage = () => {
    const history = useHistory();
    const { loading, get, post, del } = useAPI({ handle500WithToastMessage: true });
    const [editingTabs, setEditingTabs] = useState<string[]>([]);
    const [errors, setErrors] = useState({});
    const [editFields, setEditFields] = useState<UpdateUserCommand>({} as UpdateUserCommand);
    const [displayConfirmDeleteModal, setDisplayConfirmDeleteModal] = useState(false);
    const { setSuccessMessage } = useToastMessageContext();

    const routeParams: { id: any } = useParams();

    useEffect(() => {
        get<GetUserResponse>(`/users/${routeParams.id}`)
            .then((response) => {
                const updateUserCommand = {} as UpdateUserCommand;
                updateUserCommand.id = response.id;
                updateUserCommand.userName = response.userName;
                updateUserCommand.email = response.email;
                updateUserCommand.phoneNumber = response.phoneNumber;
                updateUserCommand.lockoutEnabled = response.lockoutEnabled;
                updateUserCommand.title = response.profile.title;
                updateUserCommand.forename = response.profile.forename;
                updateUserCommand.middleName = response.profile.middleName;
                updateUserCommand.surname = response.profile.surname;
                updateUserCommand.formerFirstName = response.profile.formerFirstName;
                updateUserCommand.formerLastName = response.profile.formerLastName;
                updateUserCommand.nationality = response.profile.nationality;
                updateUserCommand.nationalInsuranceNumber = response.profile.nationalInsuranceNumber;
                updateUserCommand.occupation = response.profile.occupation;
                updateUserCommand.countryOfResidence = response.profile.countryOfResidence;
                updateUserCommand.dateOfBirth = response.profile.dateOfBirth;
                updateUserCommand.isOnboarded = response.profile.isOnboarded;
                updateUserCommand.address = response.address;

                setEditFields(() => updateUserCommand);
            });
    }, []);

    const saveChanges = (changes: UpdateUserCommand) => () => {
        if (Object.keys(errors).length > 0) {
            return;
        }

        post<UpdateUserCommand>("/users", changes)
            .then((response) => {
                setEditFields(() => response);
                setEditingTabs([]);
            })
            .catch((error) => {
                if (error.validationFailed) {
                    setErrors(error.errors);
                }
            });
    };

    const openConfirmDeleteUserModal = () => {
        setDisplayConfirmDeleteModal(true);
    };

    const deleteUser = () => {
        del(`/users/${routeParams.id}`, {}).then(() => {
            setSuccessMessage("Successfully deleted user.", true);
            history.push("/manage/users");
        });
    };

    const editText = (value, e) => {
        setEditFields({ ...editFields, [value]: e.target.value });
    };

    const editSection = (value) => {
        if (editingTabs.includes(value)) {
            setEditingTabs(prevState => prevState.filter(x => x !== value));
        } else {
            setEditingTabs(prevState => [...prevState, value]);
        }
    };
    const saveAddress = (address) => saveChanges({ ...editFields, address })();
    const editDob = (date) => {
        setEditFields(prevState => ({ ...prevState, dateOfBirth: date }));
    };

    const renderTabs = () => (
        <Row className="border-bottom my-2">
            <Nav tabs className="border-0">
                <NavItem className={classNames(styles.activeTab, "font-weight-bold mx-4")}>
                    <NavLink className="px-0 border-0 text-primary">
                        Personal Information
                    </NavLink>
                </NavItem>
            </Nav>
            <div className={styles.rightButtons}>
                <Link to={`/user-orders/${routeParams.id}`} className="btn btn-primary">View Orders</Link>
                <Button onClick={openConfirmDeleteUserModal} color="danger">Delete User</Button>
            </div>
        </Row>
    );

    const renderInfoSection = () => {
        const editForm = (
            <>
                <FloatLabelTextbox
                    label="First Name*"
                    placeholder="First Name"
                    onChange={e => editText("forename", e)}
                    error={editFields.forename?.trim() === "" ? "First Name is required." : ""}
                    value={editFields.forename}
                />
                <FloatLabelTextbox
                    label="Last Name*"
                    placeholder="Surname"
                    onChange={e => editText("surname", e)}
                    error={editFields.surname?.trim() === "" ? "Surname is required." : ""}
                    value={editFields.surname}
                />
                <FloatLabelDate
                    label="Date of Birth"
                    currentDate={editFields.dateOfBirth == null ? "" : editFields.dateOfBirth}
                    changeDate={editDob}
                />
                <FloatLabelTextbox
                    label="Contact Number"
                    onChange={e => editText("phoneNumber", e)}
                    error={editFields.phoneNumber && !/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/.test(editFields.phoneNumber) ? "Invalid phone number" : ""}
                    placeholder="Change your Contact Number"
                    value={editFields.phoneNumber}
                />

                <div className="d-flex justify-content-end mt-3">
                    <Button onClick={() => editSection("Information")} color="secondary" className="mr-1">
                        Cancel
                    </Button>
                    <Button onClick={saveChanges(editFields)} color="primary">Save</Button>
                </div>
            </>
        );

        return (
            <div className="border rounded-lg p-4 m-lg-3 mt-4">
                <Row>
                    <Col xs="12" className="d-flex justify-content-between mb-3">
                        <h4 className="font-weight-bold">Basic Information</h4>
                        <Button onClick={() => editSection("Information")} className="px-3" color="primary">Edit</Button>
                    </Col>
                </Row>
                {editingTabs.includes("Information") ? editForm : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">First Name</Label>
                            <Label className="font-weight-bold">{editFields.forename}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Last Name</Label>
                            <Label className="font-weight-bold">{editFields.surname}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Date of Birth</Label>
                            <Label className="font-weight-bold">{editFields.dateOfBirth != null ? FormatDateHelper.format(editFields.dateOfBirth, "DD/MM/YYYY") : ""}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Phone Number</Label>
                            <Label className="font-weight-bold">{editFields.phoneNumber}</Label>
                        </FormGroup>
                    </>
                )}

                {displayConfirmDeleteModal && (
                    <ConfirmDeleteUserModal
                        onConfirm={deleteUser}
                        onClose={() => setDisplayConfirmDeleteModal(false)}
                        fullName={(`${editFields.forename} ${editFields.surname}` || "") as any}
                    />
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }
    return (
        <Row className="box-shadow bg-white mt-md-3 mb-5 mx-md-1 d-block d-md-flex">
            <Col>
                <Row>
                    <Col className={styles.header}>
                        <Media object src={`${window.cdnUrl}misc/calvin-services.png`} alt="header" className="h-100 p-3 x-3 position-relative" />
                        <h4 className="d-inline text-white position-relative font-weight-bold">User Information - {editFields.userName}</h4>
                    </Col>
                </Row>
                {renderTabs()}
                <div className="mb-5">
                    {renderInfoSection()}

                    <div className="d-flex flex-column border rounded-lg p-4 m-lg-3 mb-4 mt-4">
                        <FloatLabelAddressLookUp
                            address={editFields.address}
                            onSave={saveAddress}
                            className="pl-3"
                            reset={() => setErrors({})}
                        />
                    </div>

                </div>
            </Col>
        </Row>
    );
};

export { UserDetailsPage };
