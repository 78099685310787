import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import { SearchState, useProducts } from "./useProducts";
import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { EnquiryFormMiniSiteTile } from "@/Components/EnquiryFormMiniSiteTile";
import { FilterOption, MultiSelectFilter } from "@/Components/MultiSelectFilter";
import { GridListToggle } from "@/Components/GridListToggle";
import { MarketplaceSearchInput } from "@/Components/MarketplaceSearchInput";
import { DropDownOption, FloatLabelDropdown } from "@/Components/FloatLabelDropdown";
import { FilterToggle } from "@/Components/FilterToggle";
import { Onboarding } from "@/Components/Onboarding";
import { MarketplaceCarousel } from "@/Components/MarketplaceCarousel";
import Checkbox from "@/Components/Checkbox";
import { useOnboardingContext } from "@/Context/OnboardingContext";
import { OnboardingSteps } from "@/Context/OnboardingContext/onBoardingSteps";
import urlHelper from "@/Utils/urlHelper";
import { useConfigurationContext } from "@/Context/ConfigurationContext";
import { useFilterContext } from "@/Context/FilterContext";
import { useBasketContext } from "@/Context/BasketContext";
import WelcomeModal from "@/Modals/WelcomeModal";
import { ProductSortOptions, ProductType } from "@/Apis/Products/ProductType";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductVariants } from "@/Utils/ProductVariants";

const MarketplacePage = () => {
    const history = useHistory();
    const { shouldShowOnboarding, currentOnboardingStep, selectRef, closeOnboarding, nextOnBoardingStep, showOnBoardingStep, resetOnBoarding } = useOnboardingContext();
    const { loadOnBoarding } = useOnboardingContext();
    const location = useLocation();
    const user = useSelector((state: { user }) => (state.user));
    const { featuredCategories } = useConfigurationContext();
    const { basket, addToBasket } = useBasketContext();
    const { filterIsShowing } = useFilterContext();
    const [categoriesOptions, setCategoryOptions] = useState([] as FilterOption[]);
    const { categories, suppliers, products, searchProducts, noResults, searchState, setSearchTerm, toggleNoResults, hasMoreProducts, loading } = useProducts();
    const [expandedOnMobile, setExpandedOnMobile] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const sendGtmEvent = useGTMDispatch();
    const itemIdsInCart = basket.groups.flatMap(_ => _.items).map(x => x.serviceId.toUpperCase()) || [];
    const sortByOptions = Object.entries(ProductSortOptions).map(([key, value]) => ({
        display: value.split("(")[0],
        value: key,
        displayExtra: value.split("(").length === 2 ? `(${value.split("(")[1]}` : "",
    } as DropDownOption));
    const [isHexagonProduct, setIsHexagonProduct] = useState(urlHelper.getUrlParam(location.search, "isHexagonProduct")?.toLowerCase() === "true");

    const exitOnboarding = () => {
        if (!loading) {
            closeOnboarding();
        }
    };

    const searchWithFilters = (query) => { // run search using query parameters
        let categorySelections = query.categorySelections;
        if (query.categorySelections && query.categorySelections === "string") {
            categorySelections = [query.categorySelections];
        } else if (!query.categorySelections) {
            categorySelections = [];
        }
        const isAHexagonProduct = isHexagonProduct;

        let supplierSelections = query.supplierSelections;
        if (query.supplierSelections && query.supplierSelections === "string") {
            supplierSelections = [query.supplierSelections];
        } else if (!query.supplierSelections) {
            supplierSelections = [];
        }

        const newSearchTerm = query.searchTerm ? query.searchTerm : "";
        const newSortOrder = query.sortOrder ? query.sortOrder : ProductSortOptions[searchState.sortOrder];
        const newPageNumber = query.pageNumber ? parseInt(query.pageNumber, 10) : 1;

        searchProducts({
            ...searchState,
            categorySelections: categories.filter(x => categorySelections.includes(x.id)).map(x => ({
                value: x.id,
                display: x.name,
                checked: true,
            } as FilterOption)),
            supplierSelections: suppliers.filter(x => supplierSelections.includes(x.id)).map(x => ({
                value: x.id,
                display: x.name,
                checked: true,
            } as FilterOption)),
            isHexagonProduct: isAHexagonProduct,
            searchTerm: newSearchTerm,
            sortOrder: newSortOrder,
            pageNumber: newPageNumber,
        });
    };

    useEffect(() => {
        document.title = "BSC - Marketplace";
        const query = queryString.parse(location.search);
        const onBoardingStep = urlHelper.getUrlParam(location.search, "onBoardingStep") ?? "";
        if (onBoardingStep !== "") {
            showOnBoardingStep(onBoardingStep as OnboardingSteps);
        }
        const isVisitMarketplace = !!sessionStorage.getItem("isVisitMarketplace");
        if (suppliers.length === 0 && categories.length === 0 && !query?.searchTerm) {
            searchProducts({ ...searchState, loadFilters: true });

            // show onboarding/welcome modal if needed
            if ((user.hasUserData && user.isLoggedIn) && !user.isOnboarded) {
                resetOnBoarding();
                setShowWelcomeModal(true);
                sessionStorage.setItem("isVisitMarketplace", "true");
            } else if (user.isLoggedIn && !showWelcomeModal && !isVisitMarketplace) {
                loadOnBoarding();
                sessionStorage.setItem("isVisitMarketplace", "true");
            }
        } else if (query && onBoardingStep === "") { // don't run new search if onboarding is in process
            searchWithFilters(query);
        }
    }, [location]);

    useEffect(() => setCategoryOptions(categories.map((value) => ({ value: value.id, display: value.name } as FilterOption))), [categories]);

    const onClickGridView = () => setExpandedOnMobile(true);
    const onClickListView = () => setExpandedOnMobile(false);
    const addServiceToBasket = (product: ProductType, qty, variations) => addToBasket({
        productId: product.id,
        quantity: qty,
        variations,
    });
    // search query in URL will drive the product search
    const buildSearchURL = (newSearchState: SearchState) => {
        const categorySelections = newSearchState.categorySelections.map(({ value }) => value);
        const supplierSelections = newSearchState.supplierSelections.map(({ value }) => value);
        const searchStr = `?searchTerm=${newSearchState.searchTerm}&categorySelections=${categorySelections.join(",")}&supplierSelections=${supplierSelections.join(",")}
        &isHexagonProduct=${newSearchState.isHexagonProduct}`;
        const searchQuery = `${searchStr}&sortOrder=${newSearchState.sortOrder}&pageNumber=${newSearchState.pageNumber}`;
        if (location.search !== searchQuery) {
            history.push({ search: searchQuery });
        }
    };
    const onApplyCategoryFilter = (categorySelections: FilterOption[]) => buildSearchURL({ ...searchState, categorySelections, pageNumber: 1 });
    const onApplySupplierFilter = (supplierSelections: FilterOption[]) => buildSearchURL({ ...searchState, supplierSelections, pageNumber: 1 });
    const onApplySortBy = (sortBy: string) => buildSearchURL({ ...searchState, sortOrder: sortBy as ProductSortOptions, pageNumber: 1 });
    const onSearchTermChange = (searchTerm: string) => setSearchTerm(searchTerm);
    const onUpdateSearchTerm = (searchState: any) => buildSearchURL(searchState);
    const onNextPageProducts = () => searchProducts({ ...searchState, pageNumber: searchState.pageNumber + 1 });
    const [renderingProducts, setRenderingProducts] = useState(false);

    const onCheckedHexagonProductFilter = (isHexagonProduct: boolean) => {
        setIsHexagonProduct(isHexagonProduct);
        buildSearchURL({ ...searchState, isHexagonProduct, pageNumber: 1 });
    };

    // Don't remove this it's stopping multiple (at least 4 times) expensive re-renders
    const renderProducts = React.useMemo(() => {
        setRenderingProducts(true);
        sendGtmEvent(
            {
                event: "view_item_list",
                ecommerce: {
                    items: [
                        products.map(i => ({
                            item_id: `${i.id}`,
                            item_name: `${i.name}`,
                            currency: "GBP",
                            item_brand: `${i.supplierName}`,
                            item_category: `${i.categoryName}`,
                            price: i.initialCharge,
                            quantity: i.quantityOrdered,
                        }))],
                },
            },
        );
        return (
            <InfiniteScroll
                dataLength={products.length}
                next={onNextPageProducts}
                hasMore={hasMoreProducts}
                scrollableTarget="mainScrollableDiv"
                loader={searchState.pageNumber === 1 ? (<></>) : (<div className="d-flex fixed-bottom pt-4 align-items-center justify-content-center"><Spinner /></div>)}
            >
                {products.map((x, i) => {
                    if (i === products.length) {
                        setRenderingProducts(false);
                    }
                    if (ProductVariants.canBeAddedToBasket(x.productVariant)) {
                        return (<ServiceMiniSiteTile
                            key={`${x.id}-${x.name}`}
                            id={x.id}
                            name={x.name}
                            description={x.description}
                            image={x.imageThumbnail ?? x.image}
                            paymentFrequency={x.paymentFrequency}
                            initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                            postageCharge={x.postageCharge}
                            moreDetails={x.moreInformation}
                            images={x.images}
                            showEditButton={false}
                            isInBasket={itemIdsInCart.includes(x.id.toUpperCase())}
                            onAddService={(qty, variations) => addServiceToBasket(x, qty, variations)}
                            addQuantity={x.addQuantity}
                            minimumQuantity={x.minimumQuantity}
                            isBeingUsedForPackages={false}
                            variations={ProductVariations.getVariationsFromServiceOrDefault(x)}
                            productVariant={x.productVariant}
                            categoryId={x.categoryId}
                            categoryName={x.categoryName}
                            subCategoryName={x.subCategoryName}
                            itemInCart={basket?.groups?.flatMap(_ => _.items)?.find(y => y.productId === x.id)}
                            initialCharge={x.initialCharge}
                            isVatRequired={x.isVatRequired}
                            canChooseQuantity={x.addQuantity}
                            expandedMobile={expandedOnMobile}
                            supplierId={x.supplierId}
                            supplierName={x.supplierName}
                            slug={x.slug}
                            slugId={x.slugId}
                            hasNAProducts={x.hasNAProducts}
                            isOffsitePayment={x.isOffsitePayment}
                            isDelayedPayment={x.isDelayedPayment}
                            delayedPaymentFor={x.delayedPaymentFor}
                            delayedPaymentPeriod={x.delayedPaymentPeriod}
                            upsellItems={x.upsellItems}
                            isUpsell={!!x.upsellItems?.length}
                            isProductTileHidden={x.isProductTileHidden}
                            isMarketplacePage
                            onRefreshData={() => searchProducts({ ...searchState, pageNumber: 1 })}
                        />);
                    }
                    if (x.productVariant === ProductVariants.enquiryFormProduct) {
                        return (<EnquiryFormMiniSiteTile
                            key={`${x.id}-${x.name}`}
                            id={x.id}
                            name={x.name}
                            description={x.description}
                            image={x.imageThumbnail ?? x.image}
                            startingPrice={x.startingPrice}
                            images={x.images}
                            moreDetails={x.moreInformation}
                            showEditButton={false}
                            categoryId={x.categoryId}
                            categoryName={x.categoryName}
                            supplierId={x.supplierId}
                            supplierName={x.supplierName}
                            expandedMobile={expandedOnMobile}
                            slug={x.slug}
                            slugId={x.slugId}
                        />);
                    }

                    return (<></>);
                })}
            </InfiniteScroll>);
    }, [products, expandedOnMobile]);

    return (
        <div>
            {showWelcomeModal && (<WelcomeModal close={() => setShowWelcomeModal(false)} />)}
            {shouldShowOnboarding && <Onboarding {...currentOnboardingStep} onClose={exitOnboarding} onContinue={nextOnBoardingStep} />}
            <div className="bg-white box-shadow border py-4 p-3">
                <div>
                    <div className="d-flex w-100">
                        <div className="d-none d-md-flex d-wrap align-items-center">
                            <h4 className="mb-0 text-primary">Marketplace</h4>
                        </div>
                        <div className="w-100 d-flex d-wrap align-items-center">
                            <MarketplaceSearchInput searchState={searchState} onSearchTermChange={onSearchTermChange} onSearch={onUpdateSearchTerm} selectRef={selectRef} />
                        </div>
                    </div>
                </div>
                <div className="mb-0 d-sm-none mt-3 d-flex justify-content-between">
                    <FilterToggle testId="toggle-filters" />
                    <GridListToggle isExpanded={expandedOnMobile} onClickGridView={onClickGridView} onClickListView={onClickListView} testId="toggle-grid-list" />
                </div>
                <div className={classnames("d-md-flex py-3", !filterIsShowing && "d-none")}>
                    <div>
                        <MultiSelectFilter
                            label="Category"
                            onApply={onApplyCategoryFilter}
                            selections={searchState.categorySelections}
                            options={categoriesOptions}
                            testId="category-filter"
                        />
                    </div>
                    <div className="mt-3 ml-sm-3 mt-sm-0">
                        <MultiSelectFilter
                            label="Supplier"
                            onApply={onApplySupplierFilter}
                            selections={searchState.supplierSelections}
                            options={suppliers.map((value) => ({ value: value.id, display: value.name } as FilterOption))}
                            testId="supplier-filter"
                            autoComplete
                        />
                    </div>
                    <div className="mt-3 ml-sm-3 mt-sm-0">
                        <Checkbox
                            checked={isHexagonProduct}
                            id="isAHexagonProduct"
                            label="Is Hexagon Product"
                            onChange={onCheckedHexagonProductFilter}
                            data-testid="is-a-hexagon-product"
                        />
                    </div>
                    <div className="ml-md-auto mt-3 mt-sm-0">
                        <FloatLabelDropdown
                            label="Sort By"
                            onApply={onApplySortBy}
                            options={sortByOptions}
                            testId="sort-by-filter"
                            selectedValue={searchState.sortOrder}
                        />
                    </div>
                </div>
            </div>
            {noResults && (
                <div className="bg-white box-shadow border mx-4 py-4 p-3 mt-3">
                    <button
                        type="button"
                        onClick={toggleNoResults}
                        className={classnames(styles.close, "bg-transparent border-0")}
                    >
                        <i className="fa fa-times h5 text-grey" />
                    </button>
                    <h5 className="font-weight-bold">No results found {searchState.searchTerm && (<>for &#34;{searchState.searchTerm}&#34;</>)}</h5>
                    <div>Modify your search keyword or try one of our recomendations.</div>
                    <div>
                        {featuredCategories.map(x => (
                            <Button
                                key={x.id}
                                onClick={() => buildSearchURL({
                                    categorySelections: [{ display: x.name, value: x.id, checked: true }],
                                    searchTerm: "",
                                    supplierSelections: [],
                                    isHexagonProduct: false,
                                    sortOrder: ProductSortOptions.Featured,
                                    pageNumber: 1,
                                })}
                                color="link"
                                className="p-0 mr-3"
                            >{x.name}
                            </Button>))}
                    </div>
                </div>)}
            <div className="pt-1 pb-3 px-md-3">
                <MarketplaceCarousel
                    desktopSideBarClassName="desktop-sidebar"
                    desktopNoSideBarClassName="desktop"
                    desktopItemClassName="px-2 pt-3 pb-2 image-item"
                    mobileClassName="mobile"
                    mobileItemClassName="px-2 pt-3 pb-2 image-item"
                />
                {(products.length === 0 && (loading || renderingProducts)) && (<div className="d-flex pt-4 justify-content-center align-items-center"><Spinner /></div>)}
                <div className="d-flex flex-wrap justify-content-center">
                    {renderProducts}
                </div>
            </div>
        </div>
    );
};

export { MarketplacePage };
