import api from "@/Utils/api";

const verifyEmail = (token: string | null) => api.post("account/verifyemail", { token });

const resendVerificationEmail = (token: string | null) => api.post("account/resendVerifyEmail", { token });

export {
    verifyEmail,
    resendVerificationEmail,
};
