import React, { Dispatch, SetStateAction } from "react";
import { Button, Col, Row } from "reactstrap";

import { EncodedFile } from "@/Utils/base64EncodeHelper";
import { ProfileSections, ProfileTabState } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { downloadBase64File } from "@/Utils/dowloadHelper";
import { DropZoneMultiDownload } from "@/FlexPlan/Components/DropZoneMultiDownload";

interface SupportDocumentsSectionProps {
    state: ProfileTabState;
    setState: Dispatch<SetStateAction<ProfileTabState>>;
    errors;
    handleSelectEditSection(section: ProfileSections): () => void;
    handleSaveClick(): void;
}

const SupportDocumentsSection = ({ state, setState, handleSelectEditSection, handleSaveClick, errors }: SupportDocumentsSectionProps) => {
    const onChangeDocuments = (files: EncodedFile[]) => {
        setState(prev => ({
            ...prev,
            planManagerProfile: {
                ...prev.planManagerProfile,
                supportingDocuments: files,
            },
        }));
    };

    const onRemoveDocument = (fileId: string) => {
        setState(prevState => ({
            ...prevState,
            planManagerProfile: {
                ...prevState.planManagerProfile,
                supportingDocuments: [...prevState.planManagerProfile.supportingDocuments.filter(x => x.id !== fileId)],
            },
        }));
    };

    const onFileDownload = (fileId: string) => () => {
        const file = state.planManagerProfile.supportingDocuments.find(x => x.id === fileId);

        if (!file) return;

        downloadBase64File(file.name, file.data);
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-between">
                    <h4 className="font-weight-bold">Support Documents</h4>
                    {!state.selectedSections?.includes("SupportDocumentsSection") && (
                        <Button
                            onClick={handleSelectEditSection("SupportDocumentsSection")}
                            className="px-3"
                            color="primary"
                        >Edit
                        </Button>)}
                </Col>
            </Row>
            {!state.selectedSections?.includes("SupportDocumentsSection") && (
                <div className="tw-flex tw-flex-col">
                    {state.planManagerProfile?.supportingDocuments?.filter(x => x.name).map(document => (
                        <Button
                            key={`${document.id}-${document.name}`}
                            className="font-weight-bold !tw-text-left"
                            onClick={onFileDownload(document.id ?? "")}
                            disabled={!document.id}
                            color="transparent"
                        >
                            {document.name}
                        </Button>
                    ))}
                </div>
            )}
            {state.selectedSections?.includes("SupportDocumentsSection") && (
                <>
                    <DropZoneMultiDownload
                        onFilesUpdated={onChangeDocuments}
                        onDelete={onRemoveDocument}
                        onDownload={onFileDownload}
                        files={state.planManagerProfile.supportingDocuments}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <ActionButtons
                            onCancelClick={handleSelectEditSection("SupportDocumentsSection")}
                            onSaveClick={handleSaveClick}
                            disabled={errors.length > 0}
                        />
                    </div>
                </>
            )}
        </>);
};
export { SupportDocumentsSection };
