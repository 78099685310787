import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./styles.module.scss";

// eslint-disable-next-line import/extensions
import ShowIcon from "@/Assets/Icons/Coloured/show.svg";
// eslint-disable-next-line import/extensions
import HideIcon from "@/Assets/Icons/Coloured/hide.svg";

const ShowHideIconLabel = props => (
    props.show
        ? (
            <button
                type="button"
                className={classnames(styles.button, props.className)}
                onClick={props.onToggle}
            >
                <HideIcon className={styles.icon} />{!props.hideLabel && <span>Hide {props.label}</span>}
            </button>
        )
        : (
            <button
                type="button"
                className={classnames(styles.button, props.className)}
                onClick={props.onToggle}
            >
                <ShowIcon className={styles.icon} /> {!props.hideLabel && <span>Show {props.label}</span>}
            </button>
        )
);

ShowHideIconLabel.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    label: PropTypes.string,
    hideLabel: PropTypes.bool,
};

export default ShowHideIconLabel;
