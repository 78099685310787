import React from "react";
import { Button, FormGroup, Label } from "reactstrap";

import { useFlexPlanSupplierSignUpContext } from "@/FlexPlan/Pages/Suppliers/SupplierSignUp/Contexts/SupplierSignUpContext";
import Checkbox from "@/Components/Checkbox";
import Textbox from "@/Components/Textbox";
import { getFieldName, SupplierType } from "@/FlexPlan/Pages/Suppliers/Types";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import RadioButton from "@/Components/RadioButton";
import { BackButton } from "@/FlexPlan/Components/BackButton";

const PublicInformationTab = () => {
    const {
        formState,
        onCheckboxChange,
        onAbnBlur,
        onChange,
        errors,
        onAddressChange,
        onAddressSave,
        onTabChange,
        formSubmitting,
        publicFieldsHasError,
        onRadioChange,
        prepopulated,
    } = useFlexPlanSupplierSignUpContext();

    const onContinue = () => {
        if (!publicFieldsHasError()) {
            onTabChange("private")();
        }
    };

    return (
        <div>
            <Label className="tw-text-base">Supplier Type</Label>
            <FormGroup className="tw-flex tw-flex-row tw-space-x-5">
                <RadioButton
                    onChange={onRadioChange("supplierType", SupplierType.Abn)}
                    checked={formState?.supplierType === SupplierType.Abn}
                    id="supplier-type"
                    label="ABN"
                />
                <RadioButton
                    onChange={onRadioChange("supplierType", SupplierType.ExcludedSupply)}
                    checked={formState?.supplierType === SupplierType.ExcludedSupply}
                    id="supplier-type"
                    label="Excluded Supply"
                />
                <RadioButton
                    onChange={onRadioChange("supplierType", SupplierType.Reimbursement)}
                    checked={formState?.supplierType === SupplierType.Reimbursement}
                    id="supplier-type"
                    label="Reimbursement"
                />
            </FormGroup>
            {formState.supplierType === SupplierType.Abn && (
                <Textbox
                    label="ABN *"
                    className="tw-text-base"
                    onChange={onChange}
                    name={getFieldName("abn")}
                    value={formState.abn}
                    maxLength={11}
                    error={errors[getFieldName("abn")] && "Please enter a valid ABN. Must be eleven numeric characters."}
                    onBlur={onAbnBlur}
                />
            )}
            <Textbox
                label="Business Name *"
                className="tw-text-base"
                onChange={onChange}
                name={getFieldName("businessName")}
                value={formState.businessName}
                maxLength={255}
                isRequired
                error={errors[getFieldName("businessName")] && "Please enter a business name. Must be less than 255 characters."}
                disabled={prepopulated}
            />
            <FloatLabelAddressLookUp
                customHeading={<div className="tw-text-base tw-pb-2">Address *</div>}
                address={formState.address}
                onSave={onAddressSave}
                onStateChange={onAddressChange}
                editButtonText="Choose Address"
                buttonClasses="!tw-text-base"
                errorClasses="!tw-text-base"
                error={errors[getFieldName("address")] && "Please enter an address."}
                disabled={prepopulated}
            />
            <FormGroup>
                <Label className="tw-text-base">Plans Accepted</Label>
                <div className="tw-flex tw-flex-col tw-space-y-2">
                    <Checkbox
                        label="Self Managed"
                        labelClassName="!tw-text-base"
                        onChange={onCheckboxChange(getFieldName("selfManaged"), "plansAccepted")}
                        checked={formState.selfManaged ?? false}
                        id="self-managed"
                        disabled={prepopulated}
                    />
                    <Checkbox
                        label="Plan Managed"
                        labelClassName="tw-text-base"
                        onChange={onCheckboxChange(getFieldName("planManaged"), "plansAccepted")}
                        checked={formState.planManaged ?? false}
                        id="plan-managed"
                        disabled={prepopulated}
                    />
                    <Checkbox
                        label="NDIA"
                        labelClassName="tw-text-base"
                        onChange={onCheckboxChange(getFieldName("ndia"), "plansAccepted")}
                        checked={formState.ndia ?? false}
                        id="ndia"
                        disabled={prepopulated}
                    />
                </div>
            </FormGroup>
            <Textbox
                label="NDIS Registration No."
                className="tw-mt-6 tw-text-base"
                onChange={onChange}
                name={getFieldName("ndisRegistrationNumber")}
                value={formState.ndisRegistrationNumber}
                maxLength={255}
                error={errors.ndisRegistrationNumber && "Please enter a valid NDIS number."}
                disabled={prepopulated}
            />
            <div className="tw-mt-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-space-y-2 md:tw-space-y-0 md:tw-space-x-5">
                <BackButton
                    className="tw-w-full md:tw-w-72 !tw-text-base"
                    displayConfirmationModal
                />
                <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    className="tw-w-full md:tw-w-72 !tw-text-base"
                    disabled={formSubmitting || Object.keys(errors).length > 0}
                    onClick={onContinue}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export { PublicInformationTab };
