import React, { Dispatch, useContext, useReducer } from "react";

import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { supplierProfileReducer } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Reducers/SupplierProfileReducer";
import { SupplierProfileAction } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

const FlexPlanSupplierProfileContext = React.createContext<FlexPlanSupplier>({} as FlexPlanSupplier);
const FlexPlanSupplierProfileDispatchContext = React.createContext<Dispatch<SupplierProfileAction>>(() => {});

export const useFlexPlanSupplierProfileContext = () => useContext(FlexPlanSupplierProfileContext);
export const useFlexPlanSupplierProfileDispatchContext = () => useContext(FlexPlanSupplierProfileDispatchContext);

interface Props {
    children: React.ReactNode;
}

const FlexPlanSupplierContextProfileProvider = ({ children }: Props) => {
    const [supplierProfileState, dispatch] = useReducer(supplierProfileReducer, {} as FlexPlanSupplier);

    return (
        <FlexPlanSupplierProfileContext.Provider value={supplierProfileState}>
            <FlexPlanSupplierProfileDispatchContext.Provider value={dispatch}>
                {children}
            </FlexPlanSupplierProfileDispatchContext.Provider>
        </FlexPlanSupplierProfileContext.Provider>
    );
};

export { FlexPlanSupplierContextProfileProvider };
