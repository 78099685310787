import React from "react";

import { TextWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/TextWireframe";
import { ImageWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/ImageWireframe";
import { RatingsWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/RatingsWireframe";
import { useMinisiteContext } from "@/Context/MinisiteContext";
import RadioButton from "@/Components/RadioButton";

const Template1 = () => {
    const { updateSupplierTemplateValue, miniSite } = useMinisiteContext();

    return (
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-bg-white tw-h-[600px] tw-w-[400px]">
            <RadioButton
                checked={miniSite.template.selectedTemplate === 1}
                onChange={() => updateSupplierTemplateValue("selectedTemplate")(1)}
                id="template-radio"
                label="Template 1"
            />
            <TextWireframe className="tw-basis-1/5" />
            <ImageWireframe imageNumber={1} className="tw-basis-1/5" />
            <TextWireframe className="tw-basis-1/5" />
            <ImageWireframe imageNumber={2} className="tw-basis-1/5" />
            <RatingsWireframe className="tw-basis-1/5" />
        </div>
    );
};

export { Template1 };
