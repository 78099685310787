import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import classnames from "classnames";

import { SearchTextBox } from "../SearchTextBox";
import CustomerInformationDetails from "../CustomerInformationDetails";

import styles from "./styles.module.scss";

const CustomerInformation = ({ fields, saveFieldDetails, deleteFieldDetails }) => {
    const [, setSearchQuery] = useState("");
    const [fieldOptions, setFieldOptions] = useState(fields.map(x => ({
        ...x,
        isVisible: true,
        isSelected: x.value.length > 0,
    })));

    const onSearchBoxChanged = (e) => {
        const text = e.target.value;

        setSearchQuery(text);
        setFieldOptions(fieldOptions.map(x => ({
            ...x,
            isVisible: x.displayText.toLowerCase()
                .indexOf(text.toLowerCase()) > -1,
        })));
    };

    const onFieldSelected = (selectedField) => () => {
        setFieldOptions(prevFieldOptions => [...prevFieldOptions.filter(x => x.fieldId !== selectedField.fieldId),
            {
                ...selectedField,
                isSelected: true,
                isEditing: true,
            }]);
    };

    const onFieldDetailsUpdated = field => setFieldOptions(prevFieldOptions => prevFieldOptions.map((x) => {
        if (x.fieldId === field.fieldId) {
            return field;
        }
        return x;
    }));

    return (
        <>
            <div>
                {fieldOptions.filter(x => x.isSelected)
                    .map(x => (
                        <CustomerInformationDetails
                            field={x}
                            key={x.fieldId}
                            onDeleted={deleteFieldDetails}
                            onUpdated={onFieldDetailsUpdated}
                            saveFieldDetails={saveFieldDetails}
                            isEditing={x.isEditing}
                        />
                    ))}
            </div>
            <div>
                <SearchTextBox onChange={onSearchBoxChanged} placeholder="Search fields to add" />

                <div className={styles.tableWrapper}>
                    <Table striped>
                        <tbody>{fieldOptions.filter(x => x.isVisible)
                            .map(x => (
                                <tr key={x.fieldId}>
                                    <td className="py-1 border-0">
                                        <span className={classnames(x.isSelected && styles.disabled)}>{x.displayText}</span>
                                        <button
                                            type="button"
                                            onClick={onFieldSelected(x)}
                                            className={classnames("py-1 pr-2 fa-pull-right bg-transparent border-0 m-0", {
                                                [styles.pointer]: !x.isSelected,
                                                [styles.disabled]: x.isSelected,
                                            })}
                                        >
                                            <i className="fas fa-plus-circle text-primary disabled" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

CustomerInformation.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        fieldId: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        displayText: PropTypes.string.isRequired,
        value: PropTypes.string,
    })).isRequired,
    saveFieldDetails: PropTypes.func.isRequired,
    deleteFieldDetails: PropTypes.func.isRequired,
};

export default CustomerInformation;
