import React, { useState } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { ClickAwayListener } from "@/Components/ClickAwayListener";

export interface DropDownOption {
    value: string;
    display: string;
    displayExtra?: string;
}

interface Props {
    label: string;
    onApply: (selection: string) => void;
    options: DropDownOption[];
    selectedValue: string;
    testId?: string;
    disabled?: boolean;
    className?: string,
}

export const FloatLabelDropdown = ({ label, onApply, options, selectedValue, testId, disabled, className } : Props) => {
    const [showModal, setShowModal] = useState(false);

    const onHideModal = () => {
        setShowModal(false);
    };

    const onToggleModal = () => {
        setShowModal(!showModal);
    };

    const onSelectOption = (selection: DropDownOption) => {
        onApply(selection.value);
        setShowModal(false);
    };

    return (
        <>
            <div className={classnames(styles.multiSelectFilter, className)}>
                <ClickAwayListener onClickAway={onHideModal}>
                    <span>
                        <span className={classnames(styles.floatLabel)}>
                            <label className={classnames(showModal && styles.active)}>{label}</label>
                            <button
                                type="button"
                                onClick={onToggleModal}
                                className={classnames(showModal && styles.active)}
                                data-testid={`${testId}-component`}
                                disabled={disabled}
                            >
                                <div className="d-flex flex-row w-100">
                                    <label className={styles.selectionLabel}>{options.find(x => x.value === selectedValue)?.display}</label>
                                    {options.find(x => x.value === selectedValue)?.displayExtra
                                    && (<label className={styles.selectionCount}>{options.find(x => x.value === selectedValue)?.displayExtra}</label>)}
                                </div>
                            </button>
                            <i className={classnames(styles.caret, showModal ? "fa fa-angle-up" : "fa fa-angle-down")} />
                        </span>
                        {showModal && (
                            <div className={classnames(styles.optionsMenu, "dropdown-menu show !tw-z-[1001]")}>
                                {options.filter(x => x.value !== selectedValue).sort((a, b) => a?.display?.localeCompare(b?.display)).map(x => (
                                    <button
                                        key={x.value}
                                        type="button"
                                        onClick={() => onSelectOption(x)}
                                        className={classnames(styles.option, "dropdown-item")}
                                        data-testid={`${testId}-${x.value}`}
                                    >
                                        <label className={styles.selectionLabel}>{x.display}</label>
                                        {x.displayExtra && (<label className={styles.selectionCount}>{x.displayExtra}</label>)}
                                    </button>
                                ))}
                            </div>
                        )}
                    </span>
                </ClickAwayListener>
            </div>
        </>
    );
};
