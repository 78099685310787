import React from "react";

const Page = ({ title = "BSC" }: { title?: string }) => {
    document.title = `${title} - 404`;
    return (
        <div data-testid="error-message">Page not found</div>
    );
};

export default Page;
