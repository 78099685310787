import React from "react";
import Select from "react-select";
import classnames from "classnames";
import { FormGroup, Label } from "reactstrap";

import styles from "./styles.module.scss";

import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";
import { FormError } from "@/FlexPlan/Components/FormError";
import { ToolTip } from "@/Components/ToolTip";

const StatementEmailsSelect = () => {
    const {
        onMultipleEmailsSelect,
        formState,
        onNewStatementEmail,
        errors,
        multipleEmailInput,
        onMultipleEmailInput,
        onMultipleEmailSelectBlur,
    } = useFlexPlanParticipantContext();

    return (
        <FormGroup>
            <Label className="tw-flex tw-flex-row">
                Additional Statement Email Address(es)
                <ToolTip
                    id="additional-statement-email"
                    description={formState.useContactEmailForSendingStatements
                        ? "Add additional emails by typing an email address below and pressing enter."
                        : "Type an email address below and press enter."}
                >
                    <i className="fa fa-question-circle tw-text-flexplan-primary tw-ml-1" />
                </ToolTip>
            </Label>
            <Select
                value={formState.statementEmails
                    .map(x => ({
                        value: x.id,
                        label: x.email,
                        isActive: x.isActive,
                    }))}
                isClearable={false}
                isMulti
                onChange={onMultipleEmailsSelect}
                placeholder=""
                isSearchable
                onKeyDown={onNewStatementEmail}
                className={classnames("tw-max-w-[500px]", styles.reactDraggableSelect)}
                classNamePrefix="react-select"
                inputValue={multipleEmailInput}
                onInputChange={onMultipleEmailInput}
                defaultMenuIsOpen
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onBlur={onMultipleEmailSelectBlur}
            />
            {[errors?.statementEmailRequired, errors?.invalidStatementEmail, errors?.uniqueStatementEmail]
                .filter(error => !!error)
                .map(error => (
                    <FormError>
                        {error}
                    </FormError>
                ))}
        </FormGroup>
    );
};

export { StatementEmailsSelect };
