import React from "react";

import { RatingsWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/RatingsWireframe";
import { TextWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/TextWireframe";
import { ImageWireframe } from "@/Pages/MiniSiteEditorPage/Template/Components/ImageWireframe";
import { useMinisiteContext } from "@/Context/MinisiteContext";
import RadioButton from "@/Components/RadioButton";

const Template4 = () => {
    const {
        updateSupplierTemplateValue,
        miniSite,
    } = useMinisiteContext();

    return (
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-bg-white tw-h-[600px] tw-w-[400px]">
            <RadioButton
                checked={miniSite.template.selectedTemplate === 4}
                onChange={() => updateSupplierTemplateValue("selectedTemplate")(4)}
                id="template-radio"
                label="Template 4"
            />
            <div className="tw-flex tw-flex-row tw-flex-grow tw-basis-1/3">
                <ImageWireframe imageNumber={1} />
                <div className="tw-flex tw-justify-center tw-items-center tw-bg-brand-primary tw-bg-opacity-30 tw-p-4 tw-w-48">
                    <TextWireframe className="tw-w-48 tw-h-36" />
                </div>
            </div>
            <div className="tw-flex tw-flex-row tw-flex-grow tw-basis-1/3">
                <ImageWireframe imageNumber={2} />
                <div className="tw-flex tw-justify-center tw-items-center tw-bg-brand-primary tw-bg-opacity-30 tw-p-4 tw-w-48">
                    <TextWireframe className="tw-w-48 tw-h-36" />
                </div>
            </div>
            <RatingsWireframe className="tw-basis-1/3" />
        </div>
    );
};

export { Template4 };
