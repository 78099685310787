import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
    onClick: () => void,
    className?: string,
    useRelative?: boolean,
    children?: React.ReactNode,
}

const CloseButton = ({ onClick, className, useRelative, children }: Props) => (
    <button
        type="button"
        onClick={onClick}
        className={classnames(className, styles.button, !useRelative && "position-absolute", "border-0 m-0 px-2")}
        data-testid="close"
    >
        {children || <i className="fa fa-times m-0" />}
    </button>
);

export default CloseButton;
