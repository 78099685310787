import React from "react";
import classnames from "classnames";

interface Props {
    children: React.ReactNode | React.ReactNode[],
    title: string,
    className?: string,
}

// Consistent padding and styling between pages
const FlexPlanBasicWrapper = ({ children, title, className }: Props) => (
    <div className={classnames("tw-p-4 md:tw-p-10", className)}>
        <h3 className="tw-font-bold">{title}</h3>
        {children}
    </div>
);

export default FlexPlanBasicWrapper;
