import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProductCategory, ProductCategoryMapping, ReferenceData } from "@/FlexPlan/Hooks/Ndis/useProductCategories/Types";
import { useAPI } from "@/FlexPlan/Hooks/_useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { convertReferenceDataToArray } from "@/FlexPlan/Utils/Ndis/referenceData";

interface FlexPlanProductContextState {
    loading: boolean,
    error: string,
    groupedProducts: ProductCategory[],
    productCategoryItems: ReferenceData[],
}

export const initialState: FlexPlanProductContextState = {
    loading: false,
    error: "",
    groupedProducts: [],
    productCategoryItems: [],
};

const FlexPlanProductContext = React.createContext<FlexPlanProductContextState>(initialState);

export const useFlexPlanProductContext = () => useContext(FlexPlanProductContext);

interface Props {
    children: React.ReactNode,
}

// This is hard-coding the NDIS product categories to the
// identifier present on the sub-categories (of which there are hundreds).
// We believe the categories change very infrequently
const mappings: ProductCategoryMapping[] = [
    { key: "DAILY_ACTIVITIES", prefixIdentifier: "01" },
    { key: "TRANSPORT", prefixIdentifier: "02" },
    { key: "CONSUMABLES", prefixIdentifier: "03" },
    { key: "SOCIAL_COMMUNITY_CIVIC", prefixIdentifier: "04" },
    { key: "ASSISTIVE_TECHNOLOGY", prefixIdentifier: "05" },
    { key: "HOME_MODIFICATIONS", prefixIdentifier: "06" },
    { key: "SUPPORT_COORDINATION", prefixIdentifier: "07" },
    { key: "CB_HOME_LIVING", prefixIdentifier: "08" },
    { key: "CB_SOCIAL_COMMUNITY_CIVIC", prefixIdentifier: "09" },
    { key: "CB_EMPLOYMENT", prefixIdentifier: "10" },
    { key: "CB_RELATIONSHIPS", prefixIdentifier: "11" },
    { key: "CB_HEALTH_WELLBEING", prefixIdentifier: "12" },
    { key: "CB_LIFELONG_LEARNING", prefixIdentifier: "13" },
    { key: "CB_CHOICE_CONTROL", prefixIdentifier: "14" },
    { key: "CB_DAILY_ACTIVITY", prefixIdentifier: "15" },
];

export const FlexPlanProductProvider = ({ children }: Props) => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });

    const [productCategories, setProductCategories] = useState<ReferenceData[]>();
    const [productCategoryItems, setProductCategoryItems] = useState<ReferenceData[]>([]);

    // This is what we are going to return, the product items grouped by their parent category
    const [groupedProducts, setGroupedProducts] = useState<ProductCategory[]>([]);

    const [error, setError] = useState<string>("");

    const loggedIn = useSelector<any>(state => state.user.isLoggedIn);

    // Fetch the available product categories and product category items
    useEffect(() => {
        if (!loggedIn) return;

        get<{ data: any }>(FlexPlanUrls.referenceData.get("product_category"))
            .then(response => setProductCategories(convertReferenceDataToArray(response)))
            .catch(error => {
                if (typeof error === "string") {
                    setError(error);
                } else { // Could be of any shape?!
                    setError("something went wrong when trying to fetch the product category items");
                }
            });

        get<{ data: any }>(FlexPlanUrls.referenceData.get("product_category_item"))
            .then(response => setProductCategoryItems(convertReferenceDataToArray(response)))
            .catch(error => {
                if (typeof error === "string") {
                    setError(error);
                } else { // Could be of any shape?!
                    setError("something went wrong when trying to fetch the product category items");
                }
            });
    }, [loggedIn]);

    // Once we have the product categories and the product category items, we need to group the items into their parent categories
    useEffect(() => {
        if (!productCategories || !productCategoryItems) {
            return;
        }

        const productCategoriesGrouped = productCategories.map(x => {
            // Find the matching prefix identifier
            const identifier = mappings.find(xx => xx.key === x.key)?.prefixIdentifier; // e.g. "05"

            if (!identifier) return {} as ProductCategory;

            // Pull out all of the items with this matching identifier
            const items = productCategoryItems.filter(x => x.key.startsWith(identifier));

            const productCategory: ProductCategory = {
                key: x.key,
                value: x.value,
                items,
            };

            return productCategory;
        });

        setGroupedProducts(productCategoriesGrouped);
    }, [productCategories, productCategoryItems]);

    return (
        <FlexPlanProductContext.Provider value={{
            loading,
            error,
            groupedProducts,
            productCategoryItems,
        }}
        >
            {children}
        </FlexPlanProductContext.Provider>
    );
};
