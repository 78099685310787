import React, { useCallback, useState } from "react";
import DropZone from "react-dropzone";
import { Button, FormGroup, Label } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import { EncodedFile, encodeFilesToBase64WithFileName } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes } from "@/Utils/constants";
import { ConfirmModal, ConfirmModalConfiguration } from "@/Modals/ConfirmModal";

export interface DropZoneFile {
    id?: string,
    fileName: string,
    disabled?: boolean,
    isEmptyFile?: boolean,
    isUpdating?: boolean,
    data?: string | null | undefined,
}

export type DropZoneMultiProps = {
    onAdd: (files: EncodedFile[]) => Promise<any> | PromiseLike<any>,
    onDelete: (file: DropZoneFile, key: number) => Promise<any> | PromiseLike<any>,
    initialFiles?: DropZoneFile[],
    accept?: string,
    max?: number | null,
    showBrowseButton?: boolean,
    showEmptyFile?: boolean,
    showHeader?: boolean,
    dropAreaContent?: React.ReactFragment | React.ReactElement,
    validationContent?: React.ReactFragment,
    confirmDelete?: boolean | null,
    confirmDeleteConfiguration?: ConfirmModalConfiguration<DropZoneFile> | null,
    disabled?: boolean,
    buttonTextClasses?: string,
    hideDropzoneWhenDisabled?: boolean,
    downloadable?: boolean,
    onFileDownload?: (fileId: string) => () => void,
}

const DropZoneMulti = ({
    onAdd,
    onDelete,
    initialFiles = [{
        fileName: "",
        isEmptyFile: true,
        isUpdating: false,
    }],
    accept = acceptedFileTypes.documents,
    max = null,
    showBrowseButton = true,
    showEmptyFile = true,
    showHeader = true,
    dropAreaContent,
    validationContent,
    confirmDelete = false,
    confirmDeleteConfiguration: confirmDeleteProps = null,
    disabled = false,
    buttonTextClasses,
    hideDropzoneWhenDisabled = false,
    downloadable = false,
    onFileDownload,
}: DropZoneMultiProps) => {
    const [showValidation, setShowValidation] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [files, setFiles] = useState(initialFiles.length === 0 && showEmptyFile ? [{
        fileName: "",
        isEmptyFile: true,
        isUpdating: false,
    }] : initialFiles);
    const [confirmingDelete, setConfirmingDelete] = useState<DropZoneFile | null>(null);

    const updateOne = (current, key, fileName, isFileUpdating) => {
        setIsUpdating(isFileUpdating);
        const updatedFiles = [...current];
        updatedFiles[key].fileName = fileName;
        updatedFiles[key].disabled = isFileUpdating;
        updatedFiles[key].isUpdating = isFileUpdating;
        return updatedFiles;
    };

    const onDropOne = key => async (uploadedFiles: File[]) => {
        setFiles(currentFiles => [...updateOne(currentFiles, key, uploadedFiles[0].name, true)]);
        await onAdd(await encodeFilesToBase64WithFileName(uploadedFiles));
        setFiles(currentFiles => [...updateOne(currentFiles, key, uploadedFiles[0].name, false)]);
        setIsUpdating(false);
    };

    const onDropMultiple = async (uploadedFiles) => {
        setIsUpdating(true);
        await onAdd(await encodeFilesToBase64WithFileName(uploadedFiles));
        const newFiles = uploadedFiles.map(({ name }) => ({
            fileName: name,
            disabled: false,
            isUpdating: false,
        }));
        setFiles(currentFiles => [...currentFiles, ...newFiles]);
        setIsUpdating(false);
    };

    const onClickAdd = () => {
        setIsUpdating(true);
        setFiles(currentFiles => [...currentFiles, {
            fileName: "",
            disabled: false,
            isUpdating: false,
        }]);
        setIsUpdating(false);
    };

    const performDelete = async (key) => {
        setIsUpdating(true);
        setFiles((currentFiles) => {
            const updatedFiles = [...currentFiles];
            updatedFiles[key].isUpdating = true;
            return [...updatedFiles];
        });

        await onDelete(files[key], key);

        setFiles((currentFiles) => {
            const updatedFiles = [...currentFiles];
            if (key === 0 && showEmptyFile) {
                updatedFiles[key].fileName = "";
                updatedFiles[key].isEmptyFile = true;
                updatedFiles[key].isUpdating = false;
            } else {
                updatedFiles.splice(key, 1);
            }
            return [...updatedFiles];
        });

        setIsUpdating(false);
    };

    const onClickDelete = useCallback(async (key) => {
        if (!confirmingDelete) {
            if (!confirmDelete) {
                await performDelete(key);
            } else {
                setConfirmingDelete(files[key]);
            }
        }
    }, [confirmDelete, confirmingDelete, files]);

    const onDropRejected = () => {
        setShowValidation(true);
    };

    const hasMaxFiles = max != null && files?.length >= max;

    const browseButton = (file: DropZoneFile, key) => (
        <FormGroup
            key={key}
            className={`${styles.attachmentsContainer} d-flex mb-1 flex-column`}
        >
            <div className={`${styles.attachmentsContainer} d-flex mb-2`}>
                <DropZone
                    onDropAccepted={onDropOne(key)}
                    onDropRejected={onDropRejected}
                    multiple={false}
                    maxSize={8000000}
                    disabled={file.isUpdating || hasMaxFiles}
                    accept={accept}
                    noClick={disabled}
                    noDrag={disabled}
                >
                    {({
                        getRootProps,
                        getInputProps,
                    }) => (
                        <div {...getRootProps()} className={`${showBrowseButton ? styles.browserButton : styles.browserButtonNoButton} d-flex flex-grow-1 mr-2`}>
                            <input {...getInputProps()} disabled={file.disabled} />
                            <span className="d-flex align-items-center pl-2 flex-grow-1 border">{file.fileName}</span>
                            {showBrowseButton && (
                                <Button
                                    type="button"
                                    color="primary"
                                    className={styles.browserButton}
                                    disabled={file.isUpdating || file.disabled}
                                >
                                    {
                                        file.isUpdating
                                            ? <Spinner size="20" />
                                            : (
                                                <>
                                                    <i className="fas fa-folder-plus d-md-none" />
                                                    <span className={classnames("d-none d-md-inline", buttonTextClasses)}>Browse File</span>
                                                </>
                                            )
                                    }
                                </Button>)}
                        </div>
                    )}
                </DropZone>
                {downloadable && onFileDownload && file.id && (
                    <Button
                        color="primary"
                        onClick={onFileDownload(file.id)}
                        disabled={isUpdating || disabled || file.isEmptyFile}
                        className="mr-2"
                    >
                        {downloadable && <i className="fa fa-download" />}
                    </Button>
                )}
                <Button
                    color="danger"
                    onClick={() => onClickDelete(key)}
                    disabled={isUpdating || file.disabled || disabled || file.isEmptyFile}
                >
                    <i className="fa fa-trash-alt" />
                </Button>
            </div>
        </FormGroup>
    );

    const onConfirmDelete = useCallback((confirmed: boolean) => {
        setConfirmingDelete(null);
        if (confirmed) {
            performDelete(files.indexOf(confirmingDelete!));
        }
    }, [confirmingDelete]);

    return (
        <div>
            {confirmingDelete && (
                <ConfirmModal<DropZoneFile>
                    onClose={onConfirmDelete}
                    context={confirmingDelete}
                    {...confirmDeleteProps}
                />
            )}
            {showValidation && (
                <div className="alert alert-warning">
                    {validationContent ?? "Files must be less than 8MB, not a .exe or .dll."}
                </div>)}
            {showHeader && (
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <Label className="m-0" for="exampleCustomFileBrowser">Attachments</Label>
                    <Button color="primary" onClick={onClickAdd}>
                        <i className="fa fa-plus" />
                    </Button>
                </div>)}
            {files.map((x, index) => browseButton(x, index))}

            {!hasMaxFiles && !(hideDropzoneWhenDisabled && disabled) && (
                <DropZone
                    onDrop={onDropMultiple}
                    onDropRejected={onDropRejected}
                    maxSize={8000000}
                    disabled={isUpdating || hasMaxFiles}
                    accept={accept}
                    maxFiles={max ?? undefined}
                    noClick={disabled}
                    noDrag={disabled}
                >
                    {({
                        getRootProps,
                        getInputProps,
                    }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                                className={
                                    `${styles.attachmentDropZone} tw-cursor-pointer d-flex 
                                    justify-content-center align-items-end text-center px-1
                                    pt-5 mt-3 rounded font-weight-bold text-primary pb-3`
                                }
                            >
                                {isUpdating && <Spinner size="32" />}
                                {!isUpdating && (
                                    dropAreaContent ?? "You can also drag-and-drop multiple files here"
                                )}
                            </div>
                        </div>
                    )}
                </DropZone>
            )}
        </div>
    );
};

export { DropZoneMulti };
