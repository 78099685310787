import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { useAPI } from "@/Apis/useAPI";
import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { ReactSelect } from "@/Components/ReactSelect";
import { SelectItem } from "@/Apis/General/select";
import formatDateHelper from "@/Utils/formatDateHelper";
import { useFlexPlanProductContext } from "@/FlexPlan/Context/FlexPlanProductContext";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { ServiceBookingCategories } from "@/FlexPlan/Types/ServiceBooking";
import { isSubmittedOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { FormError } from "@/FlexPlan/Components/FormError";
import sortingUtil from "@/Utils/sortingUtil";

interface ServiceBookingDropdownType extends SelectItem {
    serviceBookingNumber: number,
    categories: ServiceBookingCategories[]
    startDate: string,
    endDate: string,
    isHeader: boolean, // The dropdown has a header field
}
const ServiceBookingDropdown = () => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });
    const [serviceBookings, setServiceBookings] = useState<ServiceBookingDropdownType[]>();
    const { formState, onReactSelectChange, disableButtons, invalidFields } = useFlexPlanOrderContext();
    const [error, setError] = useState<string>();

    useEffect(() => {
        get<ServiceBookingDropdownType[]>(FlexPlanUrls.serviceBookings.getQuery({
            participantId: formState.participantId,
        }))
            .then(response => {
                setServiceBookings([{ isHeader: true } as ServiceBookingDropdownType, ...response.map(booking => ({
                    ...booking,
                    value: booking.serviceBookingNumber,
                    label: "", // We have custom formatting
                }))?.sort((x, y) => sortingUtil.sort(x, y, "serviceBookingNumber", false))]);
            })
            .catch(error => {
                if (typeof error === "string") {
                    setError(error);
                } else if (error.message) {
                    setError(error.message);
                }
            });
    }, [formState.participantId]);

    const { groupedProducts } = useFlexPlanProductContext();

    // Convert categories so they're comma separated, ready to display in the dropdown
    const convertCategoriesToDisplay = (booking: ServiceBookingDropdownType) => String(booking.categories
        .map(category => groupedProducts.find(x => x.key === category.name)?.value)
        .join(", ")
        .slice(0, 17)) // Take the first 17 characters of the service booking
        .trim();

    return (
        <>
            <ReactSelect
                value={formState.ndisServiceBookingNumber
                    && serviceBookings?.find(x => x.serviceBookingNumber === formState.ndisServiceBookingNumber)}
                options={serviceBookings}
                isSearchable
                className="tw-w-96"
                onChange={onReactSelectChange("ndisServiceBookingNumber")}
                isDisabled={loading || !setServiceBookings || disableButtons || isSubmittedOrder(formState)}
                formatOptionLabel={(booking: ServiceBookingDropdownType) => (
                    <div className={classnames("tw-grid tw-grid-cols-8", booking.isHeader && "tw-text-black tw-font-semibold")}>
                        <div className="tw-col-span-2">{booking.isHeader ? "Number" : booking.serviceBookingNumber}</div>
                        <div className="tw-col-span-3 tw-whitespace-nowrap tw-overflow-hidden">{booking.isHeader ? "Categories" : `${convertCategoriesToDisplay(booking)}...`}</div>
                        <div className="tw-col-span-3">
                            {booking.isHeader
                                ? "Dates"
                                : `${formatDateHelper.format(booking.startDate, "DD/MM/YY")} - ${formatDateHelper.format(booking.endDate, "DD/MM/YY")}`}
                        </div>
                    </div>
                )}
                isOptionDisabled={option => option.isHeader}
            />
            {(invalidFields.ndisServiceBookingNumber || error) && (
                <FormError>
                    {invalidFields.ndisServiceBookingNumber ?? error}
                </FormError>
            )}
        </>);
};

export { ServiceBookingDropdown };
