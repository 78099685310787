import React from "react";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

class TextArea extends React.PureComponent {
    state = {
        charactersRemaining: this.props.value ? this.props.maxCharacters - this.props.value.length : this.props.maxCharacters,
    };

    onChange = (event) => {
        if (!this.props.disabled) {
            const remaining = event.target.value ? this.props.maxCharacters - event.target.value.length : this.props.maxCharacters;

            if (remaining >= 0) {
                const value = event.target.value;
                this.setState({ charactersRemaining: value.length ? this.props.maxCharacters - value.length : this.props.maxCharacters });
                this.props.onChange(event);
            }
        }
    };

    render() {
        return (
            <FormGroup className={this.props.className}>
                <div className="d-flex justify-content-between align-items-center">
                    <Label className={this.props.labelClassName}>{this.props.label}</Label>
                    <span className={classnames("align-self-end mb-2", this.props.maxCharactersClassName ? this.props.maxCharactersClassName : "text-primary")}>
                        {this.props.shortLabelStyle ? `${(this.props.value ? this.props.value.length : 0)}/${this.props.maxCharacters}`
                            : `${this.state.charactersRemaining} characters remaining`}
                    </span>
                </div>
                <Input
                    type="textarea"
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    invalid={!!this.props.error}
                    onChange={this.onChange}
                    className={styles.textarea}
                    rows={this.props.rows}
                    maxLength={this.props.maxCharacters}
                    disabled={this.props.disabled}
                    data-testid={this.props["data-testid"]}
                    name={this.props.name}
                />
                <FormFeedback className="font-weight-bold">{this.props.error}</FormFeedback>
            </FormGroup>
        );
    }
}

TextArea.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    shortLabelStyle: PropTypes.bool,
    maxCharacters: PropTypes.number,
    maxCharactersClassName: PropTypes.string,
    className: PropTypes.string,
    rows: PropTypes.number,
    "data-testid": PropTypes.string,
    name: PropTypes.string,
    labelClassName: PropTypes.string,
};

TextArea.defaultProps = {
    disabled: false,
    shortLabelStyle: false,
    maxCharacters: 250,
    rows: 3,
};

export default TextArea;
