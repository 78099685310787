/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

import Logo from "@/Assets/Icons/Solid/logo.svg";

const CustomerNav = (props) => {
    const localFunc = path => () => { props.push(path); };
    return (
        <div className={classnames(styles.secondLevelContainer, "bg-white")}>
            <button
                type="button"
                onClick={props.toggle}
                className={classnames(styles.logoContainer, "py-4 px-3 bg-white border-0")}
            >
                <Logo />
            </button>
            <button
                type="button"
                onClick={props.closeSecondLevel}
                className="bg-dark text-white border-0 w-100 p-0 text-left mb-3"
            >
                <div className={styles.icon}><i className="fa fa-arrow-circle-left" /></div>
                <span>Customer Dashboard</span>
            </button>

            <button
                type="button"
                data-testid="menu-button-order-management"
                onClick={localFunc("/customer-orders")}
                className="bg-white border-0 p-0 text-left d-flex align-items-center"
            >
                <div className={styles.icon}><i className="fa fa-lg fa-dolly-flatbed" /></div>
                <span className="flex-grow-1">Order Management</span>
            </button>
            <button
                type="button"
                data-testid="menu-button-purchase-upsells"
                onClick={localFunc("/purchase-upsells")}
                className="bg-white border-0 p-0 text-left d-flex align-items-center"
            >
                <div className={styles.icon}><i className="fa fa-lg fa-list" /></div>
                <span className="flex-grow-1">Purchase Upsells</span>
            </button>
            <button
                type="button"
                data-testid="menu-button-rate-suppliers"
                onClick={localFunc("/rate-suppliers")}
                className="bg-white border-0 p-0 text-left d-flex align-items-center"
            >
                <div className={styles.icon}><i className="fas fa-lg fa-star" /></div>
                <span className="flex-grow-1">Rate Supplier</span>
            </button>
            <button
                type="button"
                onClick={localFunc("/customer-faqs")}
                className="bg-white border-0 p-0 text-left d-flex align-items-center"
            >
                <div className={styles.icon}><i className="fa fa-lg fa-question" /></div>
                <span className="flex-grow-1">Help and FAQs</span>
            </button>
        </div>
    );
};

CustomerNav.propTypes = {
    toggle: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    closeSecondLevel: PropTypes.func.isRequired,
};

export default CustomerNav;
