import React from "react";
import { Modal } from "reactstrap";

import styles from "./styles.module.scss";

interface Props {
    onClose: () => void,
    onLeave: () => void,
}

const LeaveCheckoutModal = ({ onClose, onLeave }: Props) => (
    <Modal isOpen centered size="lg" className={styles.modal} data-testid="leave-checkout-warning">
        <div className="d-flex flex-column align-items-center p-3 p-md-4">
            <i className="fas fa-info-circle h1 text-warning" />
            <h6 className="mb-3">Are you sure?</h6>
            {/* eslint-disable-next-line no-irregular-whitespace */}
            <p className="mb-0 text-center">﻿If you leave this page your current items will be removed from your basket.</p>
        </div>

        <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={onLeave}>Yes, leave.</button>
        </div>
    </Modal>);

export { LeaveCheckoutModal };
