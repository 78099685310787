import React from "react";
import classnames from "classnames";

import styles from "../styles.module.scss";

import ColourPicker from "@/Components/ColourPicker";
import Checkbox from "@/Components/Checkbox";
import DropZoneButton from "@/Components/Dropzones/DropZoneButton";
import Textbox from "@/Components/Textbox";
import { acceptedFileTypes } from "@/Utils/constants";
import { useMinisiteContext } from "@/Context/MinisiteContext";

const HeaderContent = () => {
    const {
        miniSite,
        onSupplierValueChange,
        validationErrors,
    } = useMinisiteContext();

    return (
        <div className="mb-5 mt-3">
            <h3 className="mb-3">Shop Header</h3>
            <h5 className="pt-3">Header Display Options</h5>
            <div className="pb-3">
                <Checkbox
                    checked={miniSite.showLogo}
                    onChange={onSupplierValueChange("showLogo")}
                    id="logo-cb"
                    label="Show Logo"
                    className="text-white"
                    alignment="left"
                    testId="show-logo"
                />
                <Checkbox
                    checked={miniSite.showName}
                    onChange={onSupplierValueChange("showName")}
                    id="name-cb"
                    label="Show Shop Name"
                    className="text-white"
                    alignment="left"
                />
                <Checkbox
                    checked={miniSite.showTagline ?? false}
                    onChange={onSupplierValueChange("showTagline")}
                    id="tagline-cb"
                    label="Show Tagline"
                    className="text-white"
                    alignment="left"
                    testId="show-tagline"
                />
            </div>
            {validationErrors.displayOptions && <span className={styles.error}>{validationErrors.displayOptions}</span>}

            <h5 className="mt-3">Shop Name</h5>

            <Textbox
                placeholder="Shop Name"
                value={miniSite.name || ""}
                onChange={onSupplierValueChange("name")}
                error={validationErrors.name}
                className={validationErrors.name && styles.error}
                data-testid="shop-name"
            />

            <Textbox
                placeholder="Shop Tagline"
                value={miniSite.tagline || ""}
                onChange={onSupplierValueChange("tagline")}
                error={validationErrors.tagline}
                className={validationErrors.tagline && styles.error}
                data-testid="shop-tagline"
            />

            <p className="pt-3">Shop name and tagline text color</p>
            <ColourPicker
                colour={miniSite.nameAndTaglineColour}
                onColourChange={x => onSupplierValueChange("nameAndTaglineColour")({ target: { value: x } })}
                className="mb-3"
            />

            <div className="pt-3">
                <p>Upload a logo file</p>
                <DropZoneButton
                    onChange={onSupplierValueChange("logo")}
                    containerClasses={classnames("d-block border border-white rounded-lg p-3", validationErrors.logo && styles.logoError)}
                    imageClasses={classnames(styles.logo, "border bg-white rounded-lg p-2 mb-2 text-center")}
                    image={miniSite.logo}
                    noImageText={<p>Please select an image file of your logo.</p>}
                    showRemoveButton
                    accept={acceptedFileTypes.images}
                    testId="logo-upload"
                />
                {validationErrors.logo && <span className={validationErrors.logo && styles.error}>{validationErrors.logo}</span>}
            </div>

            <p className="pt-3">Header background colour</p>
            <ColourPicker
                colour={miniSite.headerColour}
                onColourChange={x => onSupplierValueChange("headerColour")({ target: { value: x } })}
                className="mb-3"
            />

            <div className="pt-3">
                <p>Upload an image to be positioned as the background for the header</p>
                <DropZoneButton
                    onChange={onSupplierValueChange("headerImage")}
                    containerClasses={classnames("d-block border border-white rounded-lg p-3", validationErrors.logo && styles.logoError)}
                    imageClasses={classnames(styles.logo, "border rounded-lg p-2 mb-2 text-center")}
                    image={miniSite.headerImage}
                    noImageText={(
                        <>
                            <p>Please select an image for the header.</p>
                            <span className={styles.imageInfo}>(File type should be .jpg or.png. Dimensions not more than 1400px x 200px and the file size no more than 2mb).</span>
                        </>
                    )}
                    showRemoveButton
                    accept={acceptedFileTypes.images}
                    testId="header-upload"
                />
            </div>

            <h5 className="pt-4">Primary Site Colour</h5>
            <ColourPicker
                colour={miniSite.primarySiteColour}
                onColourChange={x => onSupplierValueChange("primarySiteColour")({ target: { value: x } })}
                className="mb-3"
            />
        </div>
    );
};

export { HeaderContent };
