import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";

import styles from "./styles.module.scss";

import CloseButton from "@/Components/CloseButton";
import TextArea from "@/Components/TextArea";
import { ToolTip } from "@/Components/ToolTip";
import { Sale } from "@/Apis/SalesManagement";

interface Props {
    sale: Sale;
    submitNotes: (saleId: string, notes: string) => void;
}

const NotesColumn = ({ sale, submitNotes }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [localNotes, setLocalNotes] = useState(sale.notes);

    const toggle = () => setModalOpen(!modalOpen);

    useEffect(() => {
        setLocalNotes(sale.notes);
    }, [sale.notes]);

    const onNotesChanged = (e) => {
        setLocalNotes(e.target.value);
    };

    const onNotesSubmitted = () => {
        toggle();
        submitNotes(sale.saleId, localNotes);
    };

    const onClose = () => {
        toggle();
        setLocalNotes(sale.notes);
    };

    return (
        <>
            <td className="text-center">
                <ToolTip id={`pop-notes-${sale.saleId}`} description={sale.notes} emptyDescription="No notes for this item">
                    <Button color="primary" className="py-0 px-1" onClick={toggle}>
                        <i className="fas fa-pen" />
                    </Button>
                </ToolTip>
            </td>

            <Modal isOpen={modalOpen} toggle={toggle} centered size="lg">
                <CloseButton onClick={onClose} />
                <ModalBody className="p-4">
                    <h3 className="mb-4 font-weight-bold">Notes</h3>
                    <span className="text-muted d-block my-2">Order Number {sale.orderNumber}</span>
                    <p>{sale.orderItem.description}</p>

                    <TextArea onChange={onNotesChanged} value={localNotes} maxCharacters={8000} className={styles.textarea} rows={15} />

                    <div className="mt-2 d-flex d-flex justify-content-end">
                        <Button color="secondary" className="mr-1" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="primary" className="ml-1" onClick={onNotesSubmitted}>
                            Save
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export { NotesColumn };
