/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import Stripe from "@fortawesome/fontawesome-free/svgs/brands/stripe.svg";

import PayPal from "@/Assets/Images/paypal-logo.png";
import Spinner from "@/Components/Spinner";
import Footer from "@/Components/Footer";
import { SubscriptionSummary } from "@/Components/SubscriptionSummary";
import { useBasketContext } from "@/Context/BasketContext";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";
import globalActions from "@/Store/Global/actions";
import Textbox from "@/Components/Textbox";
import EnterCode from "@/Assets/Images/Redeem/enter-code.png";
import CheckoutForm from "@/Assets/Images/Redeem/fill-checkout-form.png";
import ConfirmPackage from "@/Assets/Images/Redeem/confirm-package.png";
import ProvisionOfGoods from "@/Assets/Images/Redeem/provision-of-goods.png";
import { ServiceMiniSiteTile } from "@/Components/ServiceMiniSiteTile";
import { Visible } from "@/Components/Visible";
import { GetPackageResponse, RedeemProduct } from "@/Pages/Packages/RedeemPackagesPage/Types";
import { AddToBasket } from "@/Components/AddToBasket";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import api from "@/Utils/api";
import { Urls } from "@/Apis/urls";

interface State {
    code: string;
    productIds: string;
    products: RedeemProduct[],
    loading: boolean,
    error: boolean,
}

const RedeemPackagesPage = () => {
    document.title = "BSC - Redeem Packages";

    const dispatch = useDispatch();
    const location = useLocation();
    const { applyPackage } = useBasketContext();

    const [state, setState] = useState<State>({
        code: "",
        productIds: "",
        products: [],
        loading: false,
        error: false,
    });

    const getPackage = async (code, productIds) => {
        try {
            setState(prevState => ({
                ...prevState,
                loading: true,
            }));

            const response = await api.get<GetPackageResponse>(Urls.packages.redeemPackages(code, productIds));

            if (response && Object.keys(response).length > 0) {
                const products: RedeemProduct[] = response.products.map(x => ({
                    ...x,
                    quantity: x.quantity,
                    completed: ProductVariants.canBeCustomised(x.productVariant),
                    configuredVariations: {
                        allowMultipleSelection: x.variations?.variationData?.allowMultipleSelection as boolean,
                        variationItems: [],
                    },
                }));

                setState(prevState => ({
                    ...prevState,
                    ...response,
                    productIds,
                    products,
                    loading: false,
                    error: false,
                }));
            } else {
                setState(prevState => ({
                    ...prevState,
                    error: true,
                    loading: false,
                    products: [],
                    productIds: "",
                }));
            }
        } catch {
            setState(prevState => ({
                ...prevState,
                error: true,
                loading: false,
            }));
        }
    };

    useEffect(() => {
        if (location.search) {
            const newCode = queryString.parse(location.search).code;
            const newProductIds = queryString.parse(location.search).productIds || queryString.parse(location.search).productids;
            if (typeof newCode === "string" || newCode instanceof String) {
                // @ts-ignore
                setState(prevState => ({
                    ...prevState,
                    code: newCode,
                    productIds: newProductIds,
                }));
                getPackage(newCode, newProductIds);
            }
        }
    }, []);

    const onChangeCodeSearch = (e) => {
        setState(prevState => ({
            ...prevState,
            code: e.target.value,
        }));
    };

    const applyPackageLocal = () => {
        setState(prevState => ({
            ...prevState,
            loading: true,
        }));

        const request = {
            code: state.code,
            productIds: state.productIds,
            productConfigurations: state.products.map(x => ({
                serviceId: x.id,
                quantity: x.quantity,
                variations: {
                    ...x.configuredVariations,
                },
            })),
        };

        applyPackage(request)
            .then(() => {
                dispatch(push("/basket"));
            })
            .catch(() => dispatch(globalActions.setErrorToastMessage(true, "Failed to apply package")));
    };

    const onRedeem = async () => {
        await getPackage(state.code, state.productIds);
    };

    const calculateTotalExcludingDueToSupplier = () => state.products
        .filter((product) => !product.isOffsitePayment)
        .reduce((total, x) => total + (x.initialChargeWithVatIfApplicable * x.quantity) + x.postageCharge, 0);

    const onListedProductConfigured = (serviceId) => (quantity: number, variationData: any) => {
        const products = state.products
            .map(x => (x.id === serviceId ? { ...x, completed: true, quantity, configuredVariations: variationData } : x));

        setState(prevState => ({ ...prevState, products }));
    };

    const validCode = state.products.length > 0;

    const renderStepperTile = (imagePath: string, number: number, description: string) => (
        <div className="tw-flex md:tw-flex-col tw-flex-row tw-space-y-2 tw-space-x-2 md:tw-space-x-0 md:tw-w-48 md:tw-mx-12 tw-my-5 tw-flex-grow">
            <div className="tw-w-1/2 md:tw-w-auto">
                <img src={imagePath} alt="" className="tw-bg-brand-primary tw-rounded-r-xl" />
            </div>
            <div className="tw-w-2/3 md:tw-w-auto">
                <div className="tw-text-brand-primary tw-font-semibold tw-text-base md:tw-text-xl">{`0${number}`}</div>
                <div className="md:tw-text-base tw-text-gray-500">{description}</div>
            </div>
        </div>
    );

    const renderGuide = () => (
        <div className="tw-flex tw-flex-col tw-items-center tw-pt-5">
            <h2 className="tw-text-brand-primary tw-mb-0 !tw-text-lg md:!tw-text-3xl tw-font-semibold md:tw-mt-5">How do BSC Packages work?</h2>
            <div className="tw-text-gray-500 md:tw-text-base tw-mt-5 tw-px-5 tw-text-center">
                Our BSC packages allow you to buy from multiple recommended suppliers in one go.
                Take a look below at  the simple steps to redeem your package
            </div>
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-px-5 tw-mt-2 md:tw-mt-5 tw-justify-center">
                {renderStepperTile(EnterCode, 1, "Enter your package code")}
                {renderStepperTile(CheckoutForm, 2, "Fill up the Checkout Form")}
                {renderStepperTile(ConfirmPackage, 3, "Confirm your package inclusions and pay")}
                {renderStepperTile(ProvisionOfGoods, 4, "Each supplier will get in touch with you to deal with the provision of the goods and/or services")}
            </div>
            <div className="tw-border tw-rounded-lg tw-px-2 tw-py-3 tw-mx-5 tw-mt-5 md:tw-mt-10">
                <div className="tw-text-brand-primary">
                    Pay securely through our payment processing partners and manage everything through your BSC account
                </div>
                <div className="tw-flex tw-flex-row tw-space-x-3 tw-justify-center tw-items-center">
                    <img src={PayPal} alt="pay pal logo" width="80" className="tw-mt-3" />
                    <div className="tw-w-14 tw-h-6 tw-text-[#635bff] -tw-mt-1.5">
                        <Stripe />
                    </div>
                </div>
            </div>
        </div>
    );

    const renderBody = () => {
        if (state.loading) {
            return <Spinner className="tw-mx-auto tw-my-10" />;
        }

        if (state.error) {
            return (
                <Alert color="danger" data-testid="invalid-code" className="tw-my-3 tw-w-1/2 tw-mx-auto">
                    <i className="text-danger fa fa-exclamation-circle tw-mr-1 fa-lg" />
                    The code you entered is invalid. Please check and try again.
                </Alert>
            );
        }

        if (validCode) {
            return (
                <div className="mx-0 mx-lg-3 tw-mb-10">
                    <div className="tw-text-brand-primary tw-font-semibold tw-my-5 tw-text-center md:tw-text-base tw-mx-3">
                        The items below are included in the package. Click on “Next” at the bottom to proceed.
                    </div>
                    <div className="tw-shadow-xl tw-rounded-lg tw-bg-white tw-mx-3 tw-justify-center tw-py-4 tw-px-3">
                        <div className="tw-flex tw-flex-row tw-flex-wrap tw-mb-5">
                            {state.products.map(x => (
                                <ServiceMiniSiteTile
                                    redeemPackageMode
                                    key={`${x.id}-${x.name}`}
                                    id={x.id}
                                    onAddService={onListedProductConfigured(x.id)}
                                    name={x.name}
                                    description={x.description}
                                    image={x.imageThumbnail ?? x.image}
                                    paymentFrequency={x.paymentFrequency}
                                    initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                    postageCharge={x.postageCharge}
                                    moreDetails={x.moreInformation}
                                    addToBasket={ProductVariants.canBeCustomised(x.productVariant)
                                        ? (
                                            <AddToBasket
                                                id={x.id}
                                                minimumQuantity={x.minimumQuantity}
                                                variations={x.variations.variationData}
                                                serviceVariations={x.configuredVariations}
                                                onAddService={onListedProductConfigured(x.id)}
                                                name={x.name}
                                                priceElement={<ServiceTilePriceSection
                                                    addQuantity={false}
                                                    disableAddService
                                                    postageCharge={x.postageCharge}
                                                    paymentFrequency={x.paymentFrequency}
                                                    initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                                    containerClassNames="w-100 bg-white d-flex h-20px"
                                                    priceFontClassNames="font-weight-bold h6"
                                                />}
                                                initialChargeWithVatIfApplicable={x.initialChargeWithVatIfApplicable}
                                                postageCharge={x.postageCharge}
                                                productVariant={x.productVariant as keyof ProductVariant}
                                                isBeingUsedForPackages={false}
                                                quantityInBasket={x.quantity}
                                                disableAddService={false}
                                                onCartClick={() => {}}
                                            />)
                                        : undefined}
                                    images={x.images}
                                    showEditButton={false}
                                    addQuantity={x.addQuantity}
                                    minimumQuantity={x.minimumQuantity}
                                    variations={x.configuredVariations}
                                    productVariant={x.productVariant}
                                    categoryId={x.categoryId}
                                    categoryName={x.categoryName}
                                    subCategoryName={x.subCategoryName}
                                    initialCharge={x.initialCharge}
                                    isVatRequired={x.isVatRequired}
                                    canChooseQuantity={false} // That's determined by the package
                                    supplierId={x.supplierId}
                                    supplierName={x.supplierName}
                                    slug={x.slug}
                                    slugId={x.slugId}
                                    hasNAProducts={x.hasNAProducts}
                                    isOffsitePayment={x.isOffsitePayment}
                                    isDelayedPayment={x.isDelayedPayment}
                                    delayedPaymentFor={x.delayedPaymentFor}
                                    delayedPaymentPeriod={x.delayedPaymentPeriod}
                                    upsellItems={x.upsellItems}
                                    isUpsell={!!x.upsellItems?.length}
                                    isProductTileHidden={x.isProductTileHidden}
                                />
                            ))}
                        </div>
                        <div className="tw-bg-gray-100 d-flex justify-content-center p-4 tw-mx-3">
                            <h4 className="font-weight-bold flex-grow-1">Total Due Today</h4>
                            <h4 className="font-weight-bold">£{calculateTotalExcludingDueToSupplier()
                                .toFixed(2)}
                            </h4>
                        </div>
                        <SubscriptionSummary services={state.products} />
                        <div className="d-flex justify-content-end p-4 tw-bg-white">
                            <Button color="primary" onClick={applyPackageLocal} className="pr-3">Next</Button>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;
    };

    return (
        <div className={classnames(validCode && "tw-bg-gray-100")}>
            <div
                className="tw-flex tw-flex-col tw-items-center tw-bg-gradient-to-r tw-from-[#33679a] tw-to-[#1c3264] tw-rounded-b-3xl
                    tw-py-8 tw-text-white"
            >
                <h1 className="tw-mb-0 !tw-text-lg md:!tw-text-4xl tw-font-semibold tw-mx-1">Hi there, let&apos;s redeem your package!</h1>
                <div className="md:tw-text-base tw-my-5 tw-px-3">Enter your package code below to redeem your pre-filled basket.</div>
                <div className="tw-relative">
                    <Textbox
                        onChange={onChangeCodeSearch}
                        value={state.code}
                        placeholder="Enter your package code here"
                        className={classnames("md:tw-w-[30rem] !tw-mb-1", validCode && "rounded-lg border border-success")}
                        inputClasses="!tw-pr-24 !tw-py-6"
                        data-testid="code-search"
                    />
                    <button
                        type="button"
                        className="tw-absolute tw-right-3 tw-top-2.5 tw-px-2 tw-py-0.5 tw-bg-brand-accent-yellow tw-rounded-md tw-h-8 tw-w-16"
                        onClick={onRedeem}
                        disabled={state.loading}
                        data-testid="redeem-button"
                    >
                        Redeem
                    </button>
                    {validCode && (
                        <Visible forAndAbove="Md">
                            <i className="fa fa-check-circle text-success tw-absolute tw-top-3.5 tw-right-20 fa-lg" />
                        </Visible>
                    )}
                    <Link
                        to="/packages"
                        className="font-weight-bold tw-text-brand-accent-yellow tw-underline hover:tw-text-brand-accent-yellow"
                    >
                        Interested in building a package and earning from it?
                    </Link>
                </div>
            </div>
            {renderBody()}
            {renderGuide()}
            <Footer />
        </div>
    );
};

export default RedeemPackagesPage;
