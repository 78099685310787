import { v4 } from "uuid";

import { BookingTypeNdis, PlanManagedBooking, StandardBooking, StatedSupportBooking } from "@/FlexPlan/Pages/ServiceBookings/ManageServiceBookings/Types/index";
import { ServiceBookingStatus } from "@/FlexPlan/Types/Ndis/ServiceBookings/serviceBookingStatus";

export interface GetServiceBookingResponse {
    bookings: ServiceBookingResponse[],
    participantFirstName: string,
    participantSurname: string,
    planStartDate: string;
    planEndDate: string;
}

export interface ServiceBookingResponse {
    id: string;
    serviceBookingNumber: number;
    startDate: string;
    endDate: string;
    type: BookingTypeNdis;
    items: ServiceBookingResponseRow[];
    status?: ServiceBookingStatus;
}

export interface ServiceBookingResponseRow {
    id: string;
    category: string;
    itemNumber?: string;
    price: number;
    quantity: number;
    spent: number;
    remaining: number;
}

export const getDefaultStandardBooking = (): StandardBooking => ({
    id: v4(),
    items: [{
        id: v4(),
        position: 1,
    }],
    isLoaded: false,
} as StandardBooking);

export const getDefaultPlanManagedBooking = (): PlanManagedBooking => ({ // Standard set of rows (see below), always the same. No "Add Row"  functionality
    id: v4(),
    isLoaded: false,
    items: [
        {
            id: v4(),
            budgetType: "Core",
            category: "Daily Activities",
            ndisCategoryName: "DAILY_ACTIVITIES",
            position: 1,
            displayViewMax: false,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "Consumables",
            ndisCategoryName: "CONSUMABLES",
            position: 2,
            displayViewMax: false,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "Social, Community and Civic Participation",
            ndisCategoryName: "SOCIAL_COMMUNITY_CIVIC",
            position: 3,
            displayViewMax: false,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "Transport",
            ndisCategoryName: "TRANSPORT",
            position: 4,
            displayViewMax: false,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            budgetType: "CAPACITY BUILDING",
            category: "Support Coordination",
            ndisCategoryName: "SUPPORT_COORDINATION",
            position: 5,
            displayViewMax: true,
            remaining: 0,
            spent: 0,

        },
        {
            id: v4(),
            category: "CB Home Living",
            ndisCategoryName: "CB_HOME_LIVING",
            position: 6,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Social, Community, Civic",
            ndisCategoryName: "CB_SOCIAL_COMMUNITY_CIVIC",
            position: 7,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Employment",
            ndisCategoryName: "CB_EMPLOYMENT",
            position: 8,
            displayViewMax: true,
            remaining: 0,
            spent: 0,

        },
        {
            id: v4(),
            category: "CB Relationships",
            ndisCategoryName: "CB_RELATIONSHIPS",
            position: 9,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Health & Wellbeing",
            ndisCategoryName: "CB_HEALTH_WELLBEING",
            position: 10,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Lifelong Learning",
            ndisCategoryName: "CB_LIFELONG_LEARNING",
            position: 11,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Choice & Control",
            ndisCategoryName: "CB_CHOICE_CONTROL",
            position: 12,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "CB Daily Activity",
            ndisCategoryName: "CB_DAILY_ACTIVITY",
            position: 13,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        // There is Horizontal Row (HR) Here in the Markup
        {
            id: v4(),
            budgetType: "CAPITAL",
            category: "Assistive Technology",
            ndisCategoryName: "ASSISTIVE_TECHNOLOGY",
            position: 14,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
        {
            id: v4(),
            category: "Home Modifications",
            ndisCategoryName: "HOME_MODIFICATIONS",
            position: 15,
            displayViewMax: true,
            remaining: 0,
            spent: 0,
        },
    ],
} as PlanManagedBooking);

export const getDefaultStatedSupportBooking = (): StatedSupportBooking => ({
    id: v4(),
    items: [{
        id: v4(),
        position: 1,
    }],
    isLoaded: false,
} as StatedSupportBooking);
