import React from "react";
import classnames from "classnames";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { SectionContent } from "@/Components/MinisiteContent/SiteContent/SectionContent";
import { SectionImage } from "@/Components/MinisiteContent/SiteContent/SectionImage";
import { RatingsAndContactUs } from "@/Components/MinisiteContent/SiteContent/RatingsAndContactUs";

interface Props {
    siteType: MiniSiteType,
}

const Template4 = ({
    siteType,
}: Props) => (
    <div data-testid="site-template-4">
        {siteType?.template?.sections?.map((section) => (
            <div key={section.sectionNumber} className="tw-relative">
                <SectionImage siteSection={section} classNameImg="tw-h-[370px] sm:tw-h-[235px]" />
                <div className={classnames("tw-absolute tw-w-[90%] tw-ml-5 tw-right-x-5 tw-bottom-2.5 sm:tw-right-2.5",
                    "sm:tw-w-[55%] md:tw-w-[45%] tw-shadow-2xl tw-px-4 tw-pb-4 tw-bg-white tw-opacity-90")}
                >
                    <>
                        <div
                            className="tw-break-words tw-line-clamp-1 tw-font-bold tw-text-2xl tw-mt-5"
                            style={{ color: section?.titleColour }}
                        >
                            {section?.title}
                        </div>
                        <div className="tw-mt-2.5">
                            <SectionContent section={section} />
                        </div>
                    </>
                </div>
            </div>
        ))}

        <RatingsAndContactUs siteType={siteType} />
    </div>
);

export { Template4 };
