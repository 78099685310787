import { EditorState, convertFromRaw, ContentState, convertToRaw } from "draft-js";

const tryParseJson = (content) => {
    try {
        if (!content) {
            return false;
        }
        const rawContent = JSON.parse(content);
        if (rawContent) {
            return rawContent;
        }
    } catch {
        return false;
    }
    return false;
};

// Takes nothing, text or json string of RawContent and either creates with Empty, Text or Raw
const createEditorState = (initialContent) => {
    if (!initialContent) {
        return EditorState.createEmpty();
    }

    const AndHasContentBlocks = (parsedJson) => parsedJson.blocks;
    const isJson = tryParseJson(initialContent);

    if (isJson && AndHasContentBlocks(isJson)) {
        return EditorState.createWithContent(convertFromRaw(isJson));
    }

    if (isJson) {
        return EditorState.createEmpty();
    }

    if (typeof initialContent === "string" || initialContent instanceof String) {
        return EditorState.createWithContent(ContentState.createFromText(initialContent));
    }

    return EditorState.createEmpty();
};

const getContentStateAsJson = (editorState) => JSON.stringify(convertToRaw(editorState.getCurrentContent()));

export { createEditorState, getContentStateAsJson };
