/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Alert, Nav, NavItem, NavLink } from "reactstrap";
import { Link, Route, Switch } from "react-router-dom";
import classnames from "classnames";
import { useHistory, useLocation, useParams } from "react-router";

import styles from "./styles.module.scss";

import ArrowLeft from "@/Assets/Icons/Solid/arrow-left-circle.svg";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import Spinner from "@/Components/Spinner";
import { ReceiptsDetailsTab } from "@/Pages/Receipts/Create/DetailsTab";
import { ReceiptsAttachmentsPage } from "@/Pages/Receipts/Create/AttachmentsTab";
import { CreateReceiptFormFields, defaultReceiptForm, ReceiptType } from "@/Apis/Receipts/Create";
import useDidMountEffect from "@/Hooks/useDidMountEffect";
import { useReceiptsContext } from "@/Context/ReceiptsContext";
import { useAPI } from "@/Apis/useAPI";
import urlHelper from "@/Utils/urlHelper";
import { Urls } from "@/Apis/urls";
import { toPaddedNumber } from "@/Utils/formatStringHelper";
import { useReceiptDocumentTitle } from "@/Hooks/useReceiptDocumentTitle";

interface LocationState {
    from: string,
}

const UpsertReceipt = () => {
    // Hooks
    const location = useLocation<LocationState>();
    const { pageTypePluralLowercase, pageTypeString, pageType: receiptType, pageTypeLowercase } = useReceiptsContext();
    const { push } = useHistory();
    const routeParams: { id: string | undefined } = useParams();
    const { get } = useAPI({ handle500WithToastMessage: true });
    useReceiptDocumentTitle(); // Set the document

    // State
    const [userHasInputted, setUserHasInputted] = useState<boolean>(false);
    const [confirmBackModalOpen, setConfirmBackModalOpen] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<CreateReceiptFormFields>({
        ...defaultReceiptForm as CreateReceiptFormFields,
        lines: [...defaultReceiptForm.lines.map(x => ({ ...x, receiptType }))],
        receiptType,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const isEditMode = !!routeParams.id;

    useDidMountEffect(() => {
        if (!loading) { // If we're loading, then we're rendering data from the backend, the user has not interacted
            setUserHasInputted(true);
        }
    }, [formValues]);

    useEffect(() => {
        if (!routeParams.id) {
            return;
        }

        setLoading(true);

        get<CreateReceiptFormFields>(Urls.receipts.getSingle(routeParams.id))
            .then(response => setFormValues({ ...response, lines: response.lines.map(x => ({ ...x, originalId: x.id, receiptType })) }))
            .catch(() => setError(`The ${pageTypeLowercase} could not be found at this time.`))
            .finally(() => setLoading(false));
    }, [routeParams.id]);

    const onBack = (override: boolean = false) => () => {
        if (override || !userHasInputted) {
            setConfirmBackModalOpen(false);
            if (location.state && location.state.from) {
                push(location.state.from);
            } else if (receiptType === ReceiptType.Quotation) { // Default to quotation management page
                push("/quotations/manage");
            } else { // Invoice - Default to dashboard; There is no management page
                push("/dashboard");
            }
        } else {
            setConfirmBackModalOpen(true);
        }
    };

    const renderTabs = () => (
        <div className="tw-border-b-2">
            <Nav tabs className="border-0">
                <NavItem className={classnames(urlHelper.urlContains(location, "details") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink
                        className="px-0 border-0"
                        tag={Link}
                        to={{
                            pathname: isEditMode ? `/${pageTypePluralLowercase}/${routeParams.id}/details` : `/${pageTypePluralLowercase}/details`,
                            state: location.state,
                        }}
                    >
                        Details
                    </NavLink>
                </NavItem>
                <NavItem className={classnames(urlHelper.urlContains(location, "attachments") ? styles.activeTab : styles.tab, "font-weight-bold mx-4")}>
                    <NavLink
                        className="px-0 border-0"
                        tag={Link}
                        to={{
                            pathname: isEditMode ? `/${pageTypePluralLowercase}/${routeParams.id}/attachments` : `/${pageTypePluralLowercase}/attachments`,
                            state: location.state, // Pass the location state between the tabs
                        }}
                        state
                    >
                        Attachments
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    );

    return (
        <div className="md:tw-p-8 tw-p-2">
            <button
                type="button"
                className="tw-flex tw-space-x-2 tw-items-center tw-font-bold"
                onClick={onBack()}
            >
                <ArrowLeft />
                <span className="tw-text-sm">Back</span>
            </button>
            <h3
                className="tw-font-bold tw-mt-8 md:tw-ml-6"
            >
                {!isEditMode && "New"} {pageTypeString} {isEditMode && formValues.referenceNumber && `#${toPaddedNumber(formValues.referenceNumber)}`}
            </h3>
            <main className="md:tw-pl-8 tw-pl-2 tw-pr-2">
                <Route
                    path={`/${pageTypePluralLowercase}`}
                    render={({ match: { url } }) => (
                        <Switch>
                            <Route
                                exact
                                path={isEditMode ? `${url}/${routeParams.id}/details` : `${url}/details`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        {error && <Alert color="danger" className="mt-4">{error}</Alert>}
                                        {loading
                                            ? (<Spinner size="32" className="tw-mx-auto tw-mt-8" />)
                                            : (<ReceiptsDetailsTab isEditMode={isEditMode} setFormValues={setFormValues} formValues={formValues} />)}
                                    </>
                                )}
                            />
                            <Route
                                exact
                                path={isEditMode ? `${url}/${routeParams.id}/attachments` : `${url}/attachments`}
                                render={() => (
                                    <>
                                        {renderTabs()}
                                        <ReceiptsAttachmentsPage
                                            formValues={formValues}
                                            setFormValues={setFormValues}
                                        />
                                    </>
                                )}
                            />
                        </Switch>
                    )}
                />
            </main>
            <ConfirmCancelModal
                isOpen={confirmBackModalOpen}
                text="Are you sure you want to leave without saving your changes?"
                onConfirm={onBack(true)}
                onClose={() => setConfirmBackModalOpen(false)}
            />
        </div>
    );
};

export { UpsertReceipt };
