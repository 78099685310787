/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";

import image1 from "@/Assets/Images/Marketplace/desktop/Header1Branding.png";
import image2 from "@/Assets/Images/Marketplace/desktop/Header2VirtualOffice.png";
import image3 from "@/Assets/Images/Marketplace/desktop/Header3Accounting.png";
import image4 from "@/Assets/Images/Marketplace/desktop/Header4WhatNew.png";
import image5 from "@/Assets/Images/Marketplace/mobile/Header1Branding.png";
import image6 from "@/Assets/Images/Marketplace/mobile/Header2VirtualOffice.png";
import image7 from "@/Assets/Images/Marketplace/mobile/Header3Accounting.png";
import image8 from "@/Assets/Images/Marketplace/mobile/Header4WhatNew.png";
import PostPurchaseLinkTab from "@/Components/PostPurchase/PostPurchaseLinkTab";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export interface HeaderItem {
    key: string;
    link: string;
    image: string;
}

export const defaultDesktopHeaderItems: HeaderItem[] = [
    { key: "1", link: "?categorySelections=e6e30831-87f1-43ec-aa9e-de7316e4b571", image: image1 },
    { key: "2", link: "?categorySelections=b11f6f28-f9c5-4d32-8fd7-3b44dc34c5fb,fe48125d-f2af-4fa9-8cfe-5e4bceec1b7a", image: image2 },
    { key: "3", link: "?categorySelections=7e0580c3-837e-4fa1-9e76-ed24ee68ea17", image: image3 },
    { key: "4",
        link: "?supplierSelections=9acf16fc-8452-4bbc-8241-fb2b9f27d1e7,246af4c4-7be2-4099-88d3-f328a839f6b5,5ca127b7-c951-4e74-a285-b54d1d77a77d",
        image: image4 },
];

export const defaultMobileHeaderItems: HeaderItem[] = [
    { key: "1", link: "?categorySelections=e6e30831-87f1-43ec-aa9e-de7316e4b571", image: image5 },
    { key: "2", link: "?categorySelections=b11f6f28-f9c5-4d32-8fd7-3b44dc34c5fb,fe48125d-f2af-4fa9-8cfe-5e4bceec1b7a", image: image6 },
    { key: "3", link: "?categorySelections=7e0580c3-837e-4fa1-9e76-ed24ee68ea17", image: image7 },
    { key: "4",
        link: "?supplierSelections=9acf16fc-8452-4bbc-8241-fb2b9f27d1e7,246af4c4-7be2-4099-88d3-f328a839f6b5,5ca127b7-c951-4e74-a285-b54d1d77a77d",
        image: image8 },
];

interface Props {
    desktopSideBarClassName?: string,
    desktopNoSideBarClassName?: string,
    desktopItemClassName?: string,
    mobileClassName?: string,
    mobileItemClassName?: string,
    linkClassName?: string,
    children?: React.ReactNode, // Rendered as well as the images
    mobileHeaderItems?: HeaderItem[],
    desktopHeaderItems?: HeaderItem[],
    displayMarketplaceLink?: boolean
}

const MarketplaceCarousel = ({ desktopSideBarClassName,
    desktopNoSideBarClassName,
    mobileClassName,
    mobileItemClassName,
    desktopItemClassName,
    mobileHeaderItems = defaultMobileHeaderItems,
    desktopHeaderItems = defaultDesktopHeaderItems,
    linkClassName,
    children,
    displayMarketplaceLink = false } : Props) => {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);
    const isMobile = (width <= responsive.mobile.breakpoint.max);

    const [sideBar, setSideBar] = useState<boolean>(false);

    const user = useSelector((state: { user }) => (state.user));

    useEffect(() => {
        setSideBar(user.hasUserData && user.isLoggedIn && width >= 992);
    }, [user.hasUserData, user.isLoggedIn, width]);

    return (
        <div data-testid="marketplace-headers">
            {isMobile && (
                <Carousel
                    ssr
                    itemClass={mobileItemClassName}
                    responsive={responsive}
                    infinite
                    autoPlay
                    className={mobileClassName}
                >
                    {mobileHeaderItems.map(image => (
                        displayMarketplaceLink ? (
                            <div key={image.key} className={linkClassName}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={image.image} alt="header-image" className="tw-w-full" />
                                <PostPurchaseLinkTab
                                    url={image.link}
                                    text="Visit Marketplace"
                                    className="tw-absolute tw-bottom-0 tw-right-0 tw-rounded-br"
                                />
                                {children}
                            </div>
                        ) : (
                            <Link to={image.link} key={image.key}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={image.image} alt="header-image" className="tw-w-full" />
                            </Link>
                        )
                    ))}
                </Carousel>)}
            {!isMobile && (
                <Carousel
                    ssr
                    itemClass={desktopItemClassName}
                    responsive={responsive}
                    infinite
                    autoPlay
                    className={sideBar ? desktopSideBarClassName : desktopNoSideBarClassName}
                    autoPlaySpeed={9000}
                >
                    {desktopHeaderItems.map(image => (
                        displayMarketplaceLink ? (
                            <div key={image.key} className={linkClassName}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={image.image} alt="header-image" className="tw-w-full" />
                                <PostPurchaseLinkTab
                                    url={image.link}
                                    text="Visit Marketplace"
                                    className="tw-absolute tw-bottom-0 tw-right-0 tw-rounded-br"
                                />
                                {children}
                            </div>
                        ) : (
                            <Link to={image.link} key={image.key} className={linkClassName}>
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={image.image} alt="header-image" className="tw-w-full" />
                                {children}
                            </Link>
                        )
                    ))}
                </Carousel>)}
        </div>
    );
};

export { MarketplaceCarousel };
