import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { ViewBespokeQuoteItem } from "../ViewBespokeQuoteItem";

import { DocumentDetails } from "@/Components/DocumentUpload/DocumentDetails";
import * as BespokeQuoteStatus from "@/Components/BespokeQuotes/BespokeQuoteStatues";
import { SupplierActionGroup } from "@/Components/BespokeQuotes/ViewBespokeQuote/SupplierActionGroup";
import { CustomerActionGroup } from "@/Components/BespokeQuotes/ViewBespokeQuote/CustomerActionGroup";
import { AccordionHeading } from "@/Components/AccordionHeading";
import * as QuoteApi from "@/Apis/BespokeQuote";
import { useMessagingContext } from "@/Context/MessagingContext";
import { useBasketContext } from "@/Context/BasketContext";
import * as VatHelper from "@/Utils/vatHelper";
import globalActions from "@/Store/Global/actions";
import { substringFromLastIndexOf } from "@/Utils/formatStringHelper";
import dateHelpers from "@/Utils/formatDateHelper";

const ViewBespokeQuote = ({ quote, supplierName, isSupplier, onRemoveClick, onEditClick, isFirstQuote }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0);
    const messagingContext = useMessagingContext();
    const { loadBasket } = useBasketContext();
    const [isOpen, toggle] = useState(isFirstQuote);

    const acceptQuote = async () => {
        await QuoteApi.acceptQuote(quote.quoteId);
        messagingContext.quoteAccepted(quote.quoteId);
        dispatch(globalActions.setToastMessage(true, "Quotation sent successfully!"));
    };

    const rejectQuote = async () => {
        await QuoteApi.rejectQuote(quote.quoteId);
        messagingContext.quoteRejected(quote.quoteId);
    };

    const quoteRejectionReasonAdded = async (rejectionReason) => {
        await QuoteApi.sendRejectQuoteReason(quote.quoteId, rejectionReason);
        messagingContext.quoteReasonAdded({ quoteId: quote.quoteId, reason: rejectionReason });
    };

    const proceedToCheckout = async () => {
        await QuoteApi.proceedQuote(quote.quoteId);
        messagingContext.quoteProceeded(quote.quoteId);
        loadBasket();
        history.push("/basket");
    };

    useEffect(() => {
        let quoteTotal = 0;
        quote.items.forEach(x => {
            quoteTotal += x.total;
            if (x.applyVat) {
                quoteTotal += VatHelper.calculateVat(x.total);
            }
        });

        setTotal(quoteTotal);
    }, [quote.items]);

    const onToggle = () => toggle(!isOpen);

    return (
        <div className="rounded-lg border mx-3 mt-2 mb-4" data-testid="view-bespoke-quote">
            <AccordionHeading
                heading={`Created on: ${dateHelpers.forBespokeQuote(quote.createdAt)}`}
                open={isOpen}
                onToggle={onToggle}
                className="border-0 px-3 pt-3 pb-2 m-0"
                collapseClassName="border-top"
            >
                {isSupplier && quote.status === BespokeQuoteStatus.rejected && (
                    <>
                        <div className="m-4 p-3 rounded-lg border border-danger text-danger font-weight-bold">Quotation declined</div>

                        {quote.rejectionReason && (
                            <div className="m-4">
                                <p className="font-weight-bold">Reason</p>
                                <p>{quote.rejectionReason}</p>
                            </div>)}
                    </>)}

                {isSupplier && quote.status === BespokeQuoteStatus.accepted && (
                    <div className="m-4 p-3 rounded-lg border border-success text-success font-weight-bold">
                        <span>Quotation accepted</span>
                    </div>)}

                {isSupplier && quote.status === BespokeQuoteStatus.checkedOut && (
                    <div className="m-4 p-3 rounded-lg border border-success text-success font-weight-bold">
                        <span>Quotation proceeded to checkout</span>
                    </div>)}

                {quote.items.map((item) => <ViewBespokeQuoteItem item={item} key={item.id} />)}

                <div className="d-flex justify-content-end">
                    <div className="m-2 mr-5">
                        <span className="text-right">Total Price</span>
                        <h3 className="text-right">£{total.toFixed(2)}</h3>
                    </div>
                </div>

                {quote.termsDocuments.map(doc => (
                    <DocumentDetails name={substringFromLastIndexOf(doc.name, "/")} downloadUrl={doc.location} hideDownload={isSupplier} key={doc.name} />
                ))}

                {isSupplier && <SupplierActionGroup quote={quote} onEditClick={onEditClick} onRemoveClick={onRemoveClick} />}

                {!isSupplier && (
                    <CustomerActionGroup
                        quote={quote}
                        acceptQuote={acceptQuote}
                        rejectQuote={rejectQuote}
                        supplierName={supplierName}
                        proceedToCheckout={proceedToCheckout}
                        quoteRejectionReasonAdded={quoteRejectionReasonAdded}
                    />
                )}
            </AccordionHeading>
        </div>
    );
};

ViewBespokeQuote.propTypes = {
    isSupplier: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    supplierName: PropTypes.string.isRequired,
    quote: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rejectionReason: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        quoteId: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            price: PropTypes.number.isRequired,
            pricingUnit: PropTypes.string.isRequired,
            totalUnits: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
            applyVat: PropTypes.bool.isRequired,
        }).isRequired),
        termsDocuments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
        }).isRequired),
        customFields: PropTypes.arrayOf(PropTypes.shape({
            fieldId: PropTypes.string.isRequired,
            isRequired: PropTypes.bool.isRequired,
        }).isRequired),
    }).isRequired,
    isFirstQuote: PropTypes.bool.isRequired,
};

export { ViewBespokeQuote };
