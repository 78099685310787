import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { ArrowProps } from "react-multi-carousel/lib/types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import classnames from "classnames";

import SupplierTile from "@/Components/SupplierTile";
import { sponsoredSuppliersResponsive } from "@/Apis/CarouselConfig";

interface Props {
    className?: string,
    itemClassName?: string,
    sponsoredSuppliers?: any[],
}

const SponsoredSuppliersCarousel = ({ className,
    itemClassName,
    sponsoredSuppliers } : Props) => {
    const dispatch = useDispatch();

    const onSupplierClick = (supplierId: number) => {
        dispatch(push(`marketplace/supplier-directory/${supplierId}`));
    };

    const CustomLeftArrow = ({ onClick }: ArrowProps) => (
        <button
            type="button"
            className={classnames("tw-absolute tw-bg-gray-200 lg:tw-bg-brand-primary tw-py-1 lg:tw-py-1 tw-px-3 tw-left-10 tw-rounded-full",
                "hover:tw-text-brand-primary lg:hover:tw-text-white tw-text-brand-primary lg:tw-rounded-md lg:tw-text-white")}
            onClick={onClick}
            data-testid="previous"
        >
            <i className="fa fa-caret-left tw-hidden lg:tw-block" />
            <i className="fas fa-angle-left tw-text-2xl tw-block lg:tw-hidden" />
        </button>
    );

    const CustomRightArrow = ({ onClick }: ArrowProps) => (
        <button
            type="button"
            className={classnames("tw-absolute tw-bg-gray-200 lg:tw-bg-brand-primary tw-py-1 lg:tw-py-1 tw-px-3 tw-right-10 tw-rounded-full",
                "hover:tw-text-brand-primary lg:hover:tw-text-white tw-text-brand-primary lg:tw-rounded-md lg:tw-text-white")}
            onClick={onClick}
            data-testid="previous"
        >
            <i className="fa fa-caret-right tw-hidden lg:tw-block" />
            <i className="fas fa-angle-right tw-text-2xl tw-block lg:tw-hidden" />
        </button>
    );

    return (
        <div data-testid="sponsored-suppliers-carousel">
            <Carousel
                ssr
                itemClass={itemClassName}
                responsive={sponsoredSuppliersResponsive}
                infinite
                autoPlay={false}
                className={className}
                autoPlaySpeed={9000}
                arrows
                partialVisible
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {sponsoredSuppliers?.map(activeSupplier => (
                    <div key={`tile-${activeSupplier.id}`} data-testid={activeSupplier.id}>
                        <SupplierTile
                            key={activeSupplier.id}
                            id={activeSupplier.id}
                            name={activeSupplier.name}
                            description={activeSupplier.description}
                            categoryName={activeSupplier.categories.join(", ")}
                            primarySiteColour={activeSupplier.primarySiteColour}
                            rating={activeSupplier.rating}
                            onSupplierClick={() => onSupplierClick(activeSupplier.id)}
                            logo={activeSupplier.logo}
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export { SponsoredSuppliersCarousel };
