import React from "react";
import { Button, Spinner } from "reactstrap";

import TextArea from "@/Components/TextArea";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";

const ClaimNotesTab = () => {
    const { onChange, onSaveOrder, disableButtons, savingOrder, formState } = useFlexPlanOrderContext();

    return (
        <>
            <TextArea
                maxCharacters={1000}
                className="lg:tw-w-2/3 2xl:tw-w-1/2"
                rows={15}
                value={formState.claimNotes ?? ""}
                onChange={onChange("claimNotes")}
            />
            <Button
                onClick={() => onSaveOrder(true)}
                disabled={disableButtons}
                color="primary"
                className="tw-w-40"
            >
                {savingOrder ? <Spinner className="tw-mx-auto" size="25" /> : "Save Claim Notes"}
            </Button>
        </>
    );
};

export { ClaimNotesTab };
