import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const ESCAPE_KEY = 27;

const EscapeOnCloseWrapper = (ComposedComponent, useOnClose) => {
    class WrappedComponent extends React.Component {
        componentDidMount() {
            document.addEventListener("keydown", this.escFunction, false);
        }

        componentWillUnmount() {
            document.removeEventListener("keydown", this.escFunction, false);
        }

        escFunction = (event) => {
            if (event.keyCode === ESCAPE_KEY) {
                if (useOnClose) {
                    this.props.onClose();
                } else if (this.props.history && this.props.history.goBack) {
                    this.props.history.goBack();
                }
            }
        };

        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }

    WrappedComponent.propTypes = {
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        onClose: PropTypes.func,
    };

    return withRouter(WrappedComponent);
};

export default EscapeOnCloseWrapper;
