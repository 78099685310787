import React from "react";

import Rating from "@/Components/Rating";

interface Props {
    rating?: number,
}

const Ratings = ({
    rating,
}: Props) => (
    <>
        <div className="tw-text-brand-primary tw-font-bold tw-text-2xl">Ratings</div>
        {rating && (rating > 0) && (<Rating rating={rating} className="tw-w-full" />)}
        {rating && (rating < 1) && (<div className="tw-w-full">Not rated</div>)}
    </>
);

export { Ratings };
