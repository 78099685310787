import React, { ChangeEvent } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import { getProfileFieldName, ProfileSections, ProfileTabState } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Types/PlanManagerProfile";
import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { isNumericString, isValidBsb } from "@/FlexPlan/Utils/validtor";

interface CompanySectionProps {
    state: ProfileTabState;
    errors;

    handleErrorStatusChange(hasError: boolean, field: string): void;

    handleFieldChange(e: ChangeEvent<HTMLInputElement>): void;

    handleSelectEditSection(section: ProfileSections): () => void;

    handleSaveClick(): void;
}

const BankDetailsSection = ({
    handleFieldChange,
    state,
    handleSelectEditSection,
    handleSaveClick,
    errors,
    handleErrorStatusChange,
}: CompanySectionProps) => (
    <>
        <Row>
            <Col xs="12" className="d-flex justify-content-between mb-3">
                <h4 className="font-weight-bold">Bank Details</h4>
                {!state.selectedSections?.includes("CompanySection")
                    && <Button onClick={handleSelectEditSection("BankDetailsSection")} className="px-3" color="primary">Edit</Button>}
            </Col>
        </Row>
        {!state.selectedSections?.includes("BankDetailsSection") && (
            <>
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">Bank Code</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.bankCode}</Label>
                </FormGroup>
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">BSB</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.bsb}</Label>
                </FormGroup>
                <FormGroup className="d-flex w-75 mb-2">
                    <Label className="w-25">Account Number</Label>
                    <Label className="font-weight-bold">{state.planManagerProfile?.accountNumber}</Label>
                </FormGroup>
            </>
        )}
        {state.selectedSections?.includes("BankDetailsSection") && (
            <>
                <FloatLabelTextbox
                    label="Bank Code"
                    name={getProfileFieldName("bankCode")}
                    error={(isEmptyOrSpaces(state.planManagerProfile?.bankCode) || (state.planManagerProfile?.bankCode?.length !== 3))
                        ? "Bank Code is required and must be 3 characters." : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.bankCode}
                    onChange={handleFieldChange}
                    maxLength={3}
                />
                <FloatLabelTextbox
                    label="BSB"
                    name={getProfileFieldName("bsb")}
                    error={(!isValidBsb(state.planManagerProfile?.bsb))
                        ? "The BSB is required and allows 6 digits." : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.bsb}
                    onChange={handleFieldChange}
                    maxLength={7}
                />
                <FloatLabelTextbox
                    label="Account Number"
                    name={getProfileFieldName("accountNumber")}
                    error={(!isNumericString(state.planManagerProfile?.accountNumber))
                        ? "The Account Number is required and allows a maximum of 9 digits." : ""}
                    onErrorStatusChange={handleErrorStatusChange}
                    value={state.planManagerProfile?.accountNumber}
                    onChange={handleFieldChange}
                    maxLength={9}
                />
                <div className="d-flex justify-content-end mt-3">
                    <ActionButtons
                        onCancelClick={handleSelectEditSection("BankDetailsSection")}
                        onSaveClick={handleSaveClick}
                        disabled={errors.length > 0}
                    />
                </div>
            </>
        )}
    </>
);

export { BankDetailsSection };
