import * as constants from "./constants";

import { uppercaseFirstLetter } from "@/Utils/formatStringHelper";

export const initialState = {
    isLoggedIn: false,
    hasUserData: false,
    hasLoggedInAtLeastOnce: false,
    isOnboarded: false,
    roles: [],
    isAdmin: false,
    isDeveloper: false,
    isSupplier: false,
    allowFlexiblePayments: false,
    forename: undefined,
    surname: undefined,
    email: undefined,
    isImpersonating: false,
    guestCheckout: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.CLEAR_USER:
        case constants.LOAD_USER_ERROR:
            return { ...initialState };
        case constants.SET_USER:
            return {
                ...state,
                userId: action.payload.userId,
                isLoggedIn: true,
                hasUserData: true,
                forename: action.payload.forename && uppercaseFirstLetter(action.payload.forename),
                surname: action.payload.surname && uppercaseFirstLetter(action.payload.surname),
                email: action.payload.email,
                hasLoggedInAtLeastOnce: action.payload.hasLoggedInAtLeastOnce,
                isOnboarded: action.payload.isOnboarded,
                isSupplier: action.payload.isSupplier,
                allowFlexiblePayments: action.payload.allowFlexiblePayments,
                roles: action.payload.roles,
                isAdmin: action.payload.roles.includes("Administrator"),
                isDeveloper: action.payload.roles.includes("Developer"),
                isImpersonating: action.payload.isImpersonating,
                guestCheckout: action.payload.guestCheckout,
            };
        case constants.TOGGLE_IMPERSONATING:
            return {
                ...state,
                isImpersonating: action.payload,
            };
        default:
            return state;
    }
};
