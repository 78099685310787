import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

import styles from "./styles.module.scss";

import actions from "@/Store/Global/actions";

const SuccessToastMessage = ({ callback, hideToastMessage, message }) => {
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDisplay(false);
            if (callback) {
                callback();
            } else {
                hideToastMessage();
            }
        }, 5000);

        // this will clear Timeout when component unmounts like in willComponentUnmount
        return () => { clearTimeout(timeout); };
    }, [callback, hideToastMessage]);

    if (display) {
        return (
            <div className={styles.container}>
                <Alert className={classnames(styles.toast, "text-primary border-0 position-absolute d-flex align-items-center")} isOpen>
                    <i className="fa fa-check-circle mt-1" />
                    <span>{message}</span>
                </Alert>
            </div>
        );
    }
    return false;
};

SuccessToastMessage.propTypes = {
    message: PropTypes.string.isRequired,
    callback: PropTypes.func,
    hideToastMessage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    message: state.global.toastMessage,
});

const mapDispatchToProps = dispatch => ({
    hideToastMessage: () => dispatch(actions.setToastMessage(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessToastMessage);
