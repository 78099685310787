import { mergeMap, map, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";

import actions from "./actions";
import * as constants from "./constants";

import api from "@/Utils/api";
import errorHelper from "@/Utils/errorHelper";

const loadNotifications$ = action$ => action$.pipe(
    ofType(constants.LOAD_NOTIFICATIONS_$),
    mergeMap(() => ajax(api.buildRequestForEpic("notifications", "GET")).pipe(
        map(result => actions.notificationsLoaded(result.response)),
        catchError(errorHelper),
    )),
);

export default {
    loadNotifications$,
};
