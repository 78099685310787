/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { Page, Document, Font } from "@react-pdf/renderer";

import { Header } from "./Layout/Header";
import { Order } from "./Layout/Order";
import { Total } from "./Layout/Total";
import { Footer } from "./Layout/Footer";

import LatoBold from "@/Assets/Fonts/Lato-Bold.ttf";
import Lato from "@/Assets/Fonts/Lato-Regular.ttf";

const Invoice = (props) => {
    Font.register({ family: "Lato", src: Lato });
    Font.register({ family: "LatoBold", src: LatoBold });

    return (
        <Document>
            <Page size="A4" style={{ backgroundColor: "#fff", fontSize: "10", fontFamily: "Lato" }}>
                <Header {...props} />
                <Order priceExcludingVat={props.priceExcludingVat} productName={props.productName} quantity={props.quantity} />
                <Total itemName="POSTAGE" itemTotal={props.postage} />
                <Total itemName="TAX" itemTotal={props.vatAmount} />
                <Total itemName="GRAND TOTAL" itemTotal={props.grandTotal} isGrandTotal />
                <Footer />
            </Page>
        </Document>);
};

Invoice.propTypes = {
    customerName: PropTypes.string.isRequired,
    customerAddressLine1: PropTypes.string,
    customerAddressLine2: PropTypes.string,
    customerCity: PropTypes.string,
    customerPostCode: PropTypes.string,
    customerRegistrationNumber: PropTypes.string,
    customerVatNumber: PropTypes.string,
    supplierName: PropTypes.string.isRequired,
    supplierLogo: PropTypes.string.isRequired,
    supplierAddressLine1: PropTypes.string,
    supplierAddressLine2: PropTypes.string,
    supplierCity: PropTypes.string,
    supplierPostCode: PropTypes.string,
    supplierRegistrationNumber: PropTypes.string,
    supplierVatNumber: PropTypes.string,
    orderReference: PropTypes.string.isRequired,
    datePaid: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    priceExcludingVat: PropTypes.number.isRequired,
    vatAmount: PropTypes.number.isRequired,
    postage: PropTypes.number.isRequired,
    grandTotal: PropTypes.number.isRequired,
};

export { Invoice };
