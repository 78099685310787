import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { defaultMinisiteTemplate, MinisiteTemplateForm, MinisiteTemplateSectionForm, MiniSiteType, ValidationErrors } from "@/Apis/Suppliers/MiniSiteType";
import { ProductType } from "@/Apis/Products/ProductType";
import { Package } from "@/Apis/Packages";
import { removeProduct, saveProduct } from "@/Apis/Products";
import actions from "@/Store/Global/actions";
import { CategoryType } from "@/Apis/Suppliers/CategoryType";
import { ProductVariants } from "@/Utils/ProductVariants";
import api from "@/Utils/api";
import { getSupplierMinisite, saveSupplierMinisite } from "@/Apis/Suppliers";
import * as globalConstants from "@/Store/Global/constants";

export interface MinisiteContextState {
    miniSite: MiniSiteType,
    loadMiniSite: (value: MiniSiteType) => void,
    updateSupplierValue: (key: keyof MiniSiteType, value: any) => void,
    updateProduct: (product: ProductType) => void,
    deleteProduct: (id: string) => void,
    loading: boolean,
    saveSupplier: () => void,
    setShowEditHeaderButton: React.Dispatch<React.SetStateAction<boolean>>,
    showEditHeaderButton: boolean,
    setShowEditDescButton: React.Dispatch<React.SetStateAction<boolean>>,
    showEditDescButton: boolean,
    setShowEditButtonTileId: React.Dispatch<React.SetStateAction<string | undefined>>,
    showEditButtonTileId: string | undefined,
    apiError: string,
    validationErrors: any,
    changeEditorType: (type: string) => void,
    editorType: string | null,
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>,
    showSideBar: boolean,
    setActiveCategoryTab: React.Dispatch<React.SetStateAction<string | undefined>>,
    activeCategoryTab: string | undefined,
    isDirty: boolean,
    saving: boolean,
    updatePackage: (packageItem: Package) => void,
    toggleDirty: () => void,
    onSupplierValueChange: (key: string) => (e: any) => void,
    onMouseEnterHeader: () => void,
    onMouseLeaveHeader: () => void,
    onMouseEnterDesc: () => void,
    onMouseLeaveDesc: () => void,
    onMouseEnterTile: (id: string) => void,
    onMouseLeaveTile: () => void,
    updateSupplierTemplateSectionValue: (sectionNumber: number, key: keyof MinisiteTemplateSectionForm) => (e: any) => void,
    updateSupplierTemplateValue: (key: keyof MinisiteTemplateForm) => (e: any) => void,
}

export const initialState: MinisiteContextState = {
    miniSite: { } as MiniSiteType,
    loadMiniSite: () => {},
    updateSupplierValue: () => {},
    updateProduct: () => {},
    deleteProduct: () => {},
    loading: false,
    saveSupplier: () => {},
    setShowEditHeaderButton: () => {},
    showEditHeaderButton: false,
    setShowEditDescButton: () => {},
    showEditDescButton: false,
    setShowEditButtonTileId: () => {},
    showEditButtonTileId: undefined,
    apiError: "",
    validationErrors: {},
    changeEditorType: () => {},
    editorType: null,
    setShowSideBar: () => {},
    showSideBar: false,
    setActiveCategoryTab: () => {},
    activeCategoryTab: undefined,
    isDirty: false,
    saving: false,
    updatePackage: () => {},
    toggleDirty: () => {},
    onSupplierValueChange: () => () => {},
    onMouseEnterHeader: () => {},
    onMouseLeaveHeader: () => {},
    onMouseEnterDesc: () => {},
    onMouseLeaveDesc: () => {},
    onMouseEnterTile: () => {},
    onMouseLeaveTile: () => {},
    updateSupplierTemplateSectionValue: () => () => {},
    updateSupplierTemplateValue: () => () => {},
};

const MinisiteContext = React.createContext<MinisiteContextState>(initialState);

export const useMinisiteContext = () => useContext(MinisiteContext);

interface Props {
    children: React.ReactNode,
}

export const MinisiteProvider = ({ children }: Props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [apiError, setApiError] = useState("");
    const [validationErrors, setValidationErrors] = useState({});
    const [showSideBar, setShowSideBar] = useState(true);
    const [showEditHeaderButton, setShowEditHeaderButton] = useState(false);
    const [showEditDescButton, setShowEditDescButton] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editorType, setEditorType] = useState<string | null>(null);
    const [showEditButtonTileId, setShowEditButtonTileId] = useState<string | undefined>(undefined);
    const [isDirty, setIsDirty] = useState(false);
    const [activeCategoryTab, setActiveCategoryTab] = useState<string | undefined>(undefined);
    const [miniSite, setMiniSite] = useState<MiniSiteType>({
        nameAndTaglineColour: "#FFFFFF",
        headerColour: "#4C4C4C",
        primarySiteColour: "#000000",
        showLogo: true,
        showName: true,
        showTagline: false,
        services: [],
        categoriesToDisplay: [],
        template: defaultMinisiteTemplate,
    });

    const updateSupplierValue = (key: keyof MiniSiteType, value) => {
        setMiniSite(prevState => ({
            ...prevState,
            [key]: value,
        }));

        setIsDirty(true);
    };

    const onApiError = (responseError) => {
        if (responseError.validationFailed) {
            setApiError({ ...responseError });
            setSaving(false);
        } else {
            setApiError(responseError.message || responseError.toString());
            setSaving(false);
        }
    };

    const updateProduct = (product: ProductType) => {
        setSaving(true);
        saveProduct(product)
            .then((response) => {
                dispatch(actions.setToastMessage(true, "Changes Successfully Saved"));
                setSaving(false);
                setMiniSite(prevState => {
                    const categoryName = prevState.metaData?.categories.find(x => x.id === response?.categoryId)?.name;
                    // Update metadata used for subCategory selection with saved subCategory, just in case it's new.
                    let miniSiteSubCategories: {} = { ...prevState.metaData?.subCategories };
                    const category = miniSiteSubCategories[response.categoryId];
                    if (!category) {
                        // category doesnt exist so create the category and then add the subcategory
                        const newMiniSiteSubCategories = [];
                        Object.keys(miniSiteSubCategories).map((key) => {
                            // @ts-ignore
                            newMiniSiteSubCategories.push({ [key]: miniSiteSubCategories[key] });
                            return newMiniSiteSubCategories;
                        });
                        // @ts-ignore
                        newMiniSiteSubCategories.push({ [response.categoryId]: [{ id: response.subcategoryId, name: response.subcategoryName }] });
                        miniSiteSubCategories = newMiniSiteSubCategories;
                    } else {
                        const subCategory = (category as CategoryType[]).filter(x => x.id === response.subCategoryId);
                        if (!subCategory || subCategory.length === 0) {
                            miniSiteSubCategories[response.categoryId].push({ id: response.subcategoryId, name: response.subcategoryName });
                        }
                    }

                    const services = prevState.services.filter(x => x.id !== response?.id);
                    setActiveCategoryTab(categoryName);
                    return {
                        ...prevState,
                        services: [...services, {
                            ...response,
                            productVariant: (response.productVariant === ProductVariants.formationsProduct
                                || response.productVariant === ProductVariants.readyMadeFormationsProduct
                                || response.productVariant === ProductVariants.bankAccountProduct)
                                ? ProductVariants.singleProduct : response.productVariant,
                            productType: response.productVariant,
                            isCompanyFormationsProduct: response.productVariant === ProductVariants.formationsProduct,
                            categoryName,
                        }],
                        // filter here will remove and re-add the category to the display list, or do nothing if it doesnt exist there already
                        categoriesToDisplay: [...prevState.categoriesToDisplay.filter(x => x !== categoryName), categoryName],
                        metaData: { ...prevState.metaData, subCategories: miniSiteSubCategories },
                    } as MiniSiteType;
                });
            })
            .catch(onApiError);
    };

    const updatePackage = (packageItem: Package) => {
        setSaving(true);
        api.put("packages",
            { packageId: packageItem.id, name: packageItem.name, description: packageItem.description, showOnMinisite: packageItem.showOnMinisite })
            .then((response) => {
                dispatch(actions.setToastMessage(true, "Changes Successfully Saved"));
                setSaving(false);
                setMiniSite(prevState => { // remove the package that's been edited and re-add updated one
                    const services = prevState.services.filter(x => x.id !== response?.id);
                    setActiveCategoryTab(response.categoryName);
                    return {
                        ...prevState,
                        services: [...services, { ...response }],
                    } as MiniSiteType;
                });
            })
            .catch(onApiError);
    };

    // TODO we're losing packages category when a service/enquiry is deleted
    const deleteProduct = (id: string) => {
        removeProduct(id)
            .then(() => {
                setSaving(true);
                setMiniSite(prevState => {
                    setSaving(false);
                    // services or forms without the one to be delete
                    const allItems = prevState.services.filter(x => x.id !== id);
                    // get the current categories based on the category id from the services
                    const currentCategories = prevState?.metaData?.categories.filter(x => allItems.some(s => s.categoryId === x.id));
                    // filter the categories to display using the name from the current categories
                    const categoriesToDisplay = prevState.categoriesToDisplay.filter(x => currentCategories?.some(c => c.name === x));

                    // change the active category if there are no more services in the selected category
                    const activeCategoryName = !categoriesToDisplay.includes(activeCategoryTab as string) && categoriesToDisplay.length > 0
                        ? categoriesToDisplay[0]
                        : activeCategoryTab;
                    setActiveCategoryTab(activeCategoryName);
                    return ({
                        ...prevState,
                        services: allItems,
                        categoriesToDisplay,
                    });
                });
            })
            .catch(onApiError);
    };

    const loadMiniSite = useCallback((value: MiniSiteType) => {
        setMiniSite({ ...miniSite, ...value } as MiniSiteType);
    }, [miniSite]);

    useEffect(() => {
        getSupplierMinisite()
            .then((response) => {
                loadMiniSite({ ...response,
                    services: response.services.map(x => ({
                        ...x,
                        productVariant: (x.productVariant === ProductVariants.formationsProduct
                            || x.productVariant === ProductVariants.readyMadeFormationsProduct
                            || x.productVariant === ProductVariants.bankAccountProduct) ? ProductVariants.singleProduct : x.productVariant,
                        productType: x.productVariant,
                        isCompanyFormationsProduct: x.productVariant === ProductVariants.formationsProduct,
                    })),
                    template: response.template?.sections?.length > 0 ? response.template : defaultMinisiteTemplate });

                setActiveCategoryTab(response.categoriesToDisplay && response.categoriesToDisplay[0]);
                setLoading(false);
            })
            .catch(onApiError);
    }, []);

    useEffect(() => {
        if (apiError) {
            const timeout = setTimeout(() => {
                setApiError("");
            }, 4000);

            return () => {
                clearTimeout(timeout);
            };
        }

        return () => {
        };
    }, [apiError]);

    const validateMiniSite = () => {
        const errors: ValidationErrors = {};

        if (!miniSite.name) {
            errors.name = "Your minisite name is required.";
        }
        if (!miniSite.logo) {
            errors.logo = "Please specify a logo.";
        }
        if (miniSite.showTagline && !miniSite.tagline) {
            errors.tagline = "Please specify a tagline.";
        }
        if (miniSite.services.length === 0) {
            errors.services = "Note: You will not be able to publish your shop and it will not be visible to customers until you have added a product or an enquiry.";
        }
        return errors;
    };

    const saveSupplier = () => {
        setSaving(true);
        const currentValidationErrors = validateMiniSite();
        if (Object.keys(currentValidationErrors).length === 0) {
            const body = {
                ...miniSite,
                services: miniSite.services.map(x => ({
                    ...x,
                    subCategory: x.subCategoryName || x.subCategoryId,
                })),
            };
            saveSupplierMinisite(body)
                .then((response) => {
                    loadMiniSite({ ...response });
                    dispatch(actions.setToastMessage(true, "Changes Successfully Saved"));
                    setIsDirty(false);
                    setShowSideBar(false);
                    setSaving(false);
                    setApiError("");
                })
                .catch((responseError) => onApiError(responseError));
        }
        setValidationErrors(currentValidationErrors);
        setEditorType(Object.keys(currentValidationErrors).length === 1 && currentValidationErrors.description
            ? globalConstants.sideBarTypes.editDescription
            : globalConstants.sideBarTypes.editHeader);
        setShowSideBar(true);
        setSaving(false);
    };

    const changeEditorType = (type: string) => {
        setEditorType(type);
        setShowSideBar(true);
    };

    const toggleDirty = () => setIsDirty(true);

    const onSupplierValueChange = key => (e) => {
        const value = e.target ? e.target.value : e;
        updateSupplierValue(key, value);
    };

    const updateSupplierTemplateSectionValue = (sectionNumber: number, key: keyof MinisiteTemplateSectionForm) => (e: any) => {
        const value = e.target ? e.target.value : e;

        setMiniSite(prev => {
            let matchingSection = prev.template.sections.find(x => x.sectionNumber === sectionNumber);

            if (!matchingSection) return prev;

            matchingSection = {
                ...matchingSection,
                [key]: value,
            };

            return {
                ...prev,
                template: {
                    ...prev.template,
                    sections: [...prev.template.sections.filter(x => x.sectionNumber !== sectionNumber), matchingSection],
                },
            };
        });
        setIsDirty(true);
    };

    const updateSupplierTemplateValue = (key: keyof MinisiteTemplateForm) => (e: any) => {
        const value = e.target ? e.target.value : e;

        setMiniSite(prev => ({
            ...prev,
            template: {
                ...prev.template,
                [key]: value,
            },
        }));
        setIsDirty(true);
    };

    const onMouseEnterHeader = () => setShowEditHeaderButton(true);

    const onMouseLeaveHeader = () => setShowEditHeaderButton(false);

    const onMouseEnterDesc = () => setShowEditDescButton(true);

    const onMouseLeaveDesc = () => setShowEditDescButton(false);

    const onMouseEnterTile = (id: string) => setShowEditButtonTileId(id);

    const onMouseLeaveTile = () => setShowEditButtonTileId(undefined);

    return (
        <MinisiteContext.Provider value={{
            miniSite,
            loadMiniSite,
            updateSupplierValue,
            updateProduct,
            deleteProduct,
            loading,
            saveSupplier,
            setShowEditHeaderButton,
            showEditHeaderButton,
            setShowEditDescButton,
            showEditDescButton,
            setShowEditButtonTileId,
            showEditButtonTileId,
            apiError,
            validationErrors,
            changeEditorType,
            editorType,
            setShowSideBar,
            showSideBar,
            setActiveCategoryTab,
            activeCategoryTab,
            isDirty,
            saving,
            updatePackage,
            toggleDirty,
            onSupplierValueChange,
            onMouseEnterHeader,
            onMouseLeaveHeader,
            onMouseEnterDesc,
            onMouseLeaveDesc,
            onMouseEnterTile,
            onMouseLeaveTile,
            updateSupplierTemplateValue,
            updateSupplierTemplateSectionValue,
        }}
        >
            {children}
        </MinisiteContext.Provider>
    );
};
