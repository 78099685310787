import React, { useState } from "react";
import DropZone from "react-dropzone";
import { Alert, Button, FormGroup } from "reactstrap";
import { v4 } from "uuid";

import { EncodedFile, encodeFilesToBase64WithFileName, encodeFileToBase64WithFileName } from "@/Utils/base64EncodeHelper";
import { acceptedFileTypes, fileTypesText } from "@/Utils/constants";

interface Props {
    onFilesUpdated: (files: EncodedFile[]) => void,
    onDelete: (fileId: string) => void,
    onDownload: (fileId: string) => () => void,
    files: EncodedFile[],
    acceptFileTypes?: string,
    maxFileSize?: number, // bytes
}

// A more opinionated and simplified multi dropdown designed for FlexPlan
const DropZoneMultiDownload = ({
    onFilesUpdated,
    onDelete,
    onDownload,
    files,
    acceptFileTypes = acceptedFileTypes.documents,
    maxFileSize = 8 * 1000 * 1000, // 8MB
}: Props) => {
    const [error, setError] = useState<string>();

    const onDropMulti = async (newFiles: File[]) => {
        const filesBase64 = await encodeFilesToBase64WithFileName(newFiles);
        onFilesUpdated([...files, ...filesBase64.map(file => ({ id: v4(), ...file }))]);
    };

    const onDropSingle = (fileId: string) => async (dropFiles: File[]) => {
        const base64File: EncodedFile = await encodeFileToBase64WithFileName(dropFiles[0]);

        // loop through the existing files and pull out the placeholder file
        // update the file name and data
        const updatedFiles = files.map(file => {
            const existingFile = file;

            if (existingFile.id === fileId) { // We have found our placeholder
                existingFile.name = base64File.name;
                existingFile.data = base64File.data;
            }

            return existingFile;
        });

        onFilesUpdated(updatedFiles);
    };

    const onClickAddNewFile = () => onFilesUpdated([{ id: v4(), name: "", data: "" }, ...files]);

    const onDropRejected = () => setError(`Files must be less than ${maxFileSize / 1000 / 1000}MB and of type ${fileTypesText(acceptFileTypes)}`);

    return (
        <>
            {error && (
                <Alert color="danger">
                    {error}
                </Alert>
            )}
            <div className="tw-mb-2 tw-flex tw-justify-end">
                <Button color="primary" onClick={onClickAddNewFile}>
                    <i className="fa fa-plus" />
                </Button>
            </div>
            {files.map(file => (
                <FormGroup
                    key={file.id}
                >
                    <div className="tw-flex tw-flex-row tw-space-x-2 tw-mb-1">
                        <DropZone
                            onDropAccepted={onDropSingle(file.id ?? "")}
                            onDropRejected={onDropRejected}
                            multiple={false}
                            maxSize={maxFileSize}
                            accept={acceptFileTypes}
                        >
                            {({
                                getRootProps,
                                getInputProps,
                            }) => (
                                <div {...getRootProps()} className="tw-flex tw-flex-grow">
                                    <input {...getInputProps()} disabled={file.disabled} />
                                    <span className="d-flex align-items-center pl-2 flex-grow-1 border">{file.name}</span>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="!tw-rounded-l-none"
                                        disabled={file.disabled}
                                    >
                                        <>
                                            <i className="fas fa-folder-plus d-md-none" />
                                            <span className="d-none d-md-inline">Browse File</span>
                                        </>
                                    </Button>
                                </div>
                            )}
                        </DropZone>
                        {file.id && (
                            <Button
                                color="primary"
                                onClick={onDownload(file.id)}
                                disabled={!file.data}
                            >
                                <i className="fa fa-download" />
                            </Button>
                        )}
                        {file.id && (
                            <Button
                                color="danger"
                                onClick={() => onDelete(file.id ?? "")}
                                disabled={file.disabled}
                            >
                                <i className="fa fa-trash-alt" />
                            </Button>
                        )}
                    </div>
                </FormGroup>
            ))}
            <DropZone
                onDrop={onDropMulti}
                onDropRejected={onDropRejected}
                maxSize={maxFileSize}
                accept={acceptFileTypes}
            >
                {({
                    getRootProps,
                    getInputProps,
                }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                            className="tw-border tw-border-dashed tw-border-gray-400 tw-rounded-md
                            tw-py-10 tw-px-2 tw-flex tw-justify-center tw-text-brand-primary tw-font-semibold"
                        >
                            You can also drag-and-drop multiple files here
                        </div>
                    </div>
                )}
            </DropZone>
        </>
    );
};

export { DropZoneMultiDownload };
