import React from "react";
import classnames from "classnames";

import { enumDisplay } from "@/Utils/formatStringHelper";
import { PaymentRequestStatus } from "@/FlexPlan/Types/Order";

interface Props {
    status: PaymentRequestStatus;
}

const getColour = (status: PaymentRequestStatus) => {
    switch (status) {
        case PaymentRequestStatus.NoStatus:
            return "";
        case PaymentRequestStatus.Open:
            return "tw-text-[#ffce2b]";
        case PaymentRequestStatus.PendingPayment:
            return "tw-text-[#a3cb63]";
        case PaymentRequestStatus.Paid:
            return "tw-text-[#309fd0]";
        case PaymentRequestStatus.AwaitingApproval:
            return "tw-text-[#ffce2b]";
        case PaymentRequestStatus.Rejected:
            return "tw-text-red-500";
        case PaymentRequestStatus.Incomplete:
            return "tw-text-red-500";
        case PaymentRequestStatus.Cancelled:
            return "tw-text-red-500";
        case PaymentRequestStatus.Deleted:
            return "tw-text-red-500";
        case PaymentRequestStatus.Error:
            return "tw-text-red-500";
        default: // No PR Status
            return "";
    }
};

const OrderRowStatusIcon = ({ status }: Props) => (
    <span className="tw-flex tw-items-center">
        <i className={classnames("fa fa-circle mr-2 mb-1 [&>*]:tw-w-2 [&>svg]:tw-h-2", getColour(status))} />
        <span className="tw-whitespace-nowrap">{enumDisplay(status)}</span>
    </span>
);

export default OrderRowStatusIcon;
