import React from "react";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { SectionContent } from "@/Components/MinisiteContent/SiteContent/SectionContent";
import { SectionImage } from "@/Components/MinisiteContent/SiteContent/SectionImage";
import { RatingsAndContactUs } from "@/Components/MinisiteContent/SiteContent/RatingsAndContactUs";

interface Props {
    siteType: MiniSiteType,
}

const Template2 = ({
    siteType,
}: Props) => (
    <div data-testid="site-template-2">
        {siteType?.template?.sections?.map((section, i) => (
            <div key={section.sectionNumber} className={`tw-grid tw-grid-cols-2 tw-gap-4 ${i % 2 === 1 && "tw-mt-5"}`}>
                <div className={`tw-col-span-1 ${i % 2 === 0 ? "tw-order-1" : "tw-order-2"}`}>
                    <SectionImage siteSection={section} classNameImg="tw-h-[200px] sm:tw-h-[190px]" />
                </div>
                <div className={`tw-col-span-1 ${i % 2 === 0 ? "tw-order-2" : "tw-order-1"}`}>
                    <div
                        className="tw-break-words tw-font-bold tw-text-2xl tw-mt-5"
                        style={{ color: section?.titleColour }}
                    >
                        {section?.title}
                    </div>
                    <div className="tw-mt-2.5">
                        <SectionContent section={section} />
                    </div>
                </div>
            </div>
        ))}

        <RatingsAndContactUs siteType={siteType} />
    </div>
);

export { Template2 };
