/* eslint-disable import/extensions */
import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Visa from "@/Assets/Images/Ccards/visa.png";
import MasterCard from "@/Assets/Images/Ccards/master-card.png";
import AmericanExpress from "@/Assets/Images/Ccards/american-express.png";
import Stripe from "@/Assets/Images/powered-by-stripe.svg";

interface Props {
    isAmendPayment: boolean,
    isAboveStripeMinimum: boolean,
}

const PaymentLogos = ({ isAmendPayment, isAboveStripeMinimum }: Props) => (
    <div
        className={classnames(styles.imagesWrapper, styles.cardSection, "d-flex justify-content-center mb-3 mx-2")}
    >
        <div className={classnames(styles.imageWrapper, "m-1")}>
            <img src={Visa} alt="visa" className="mx-1" />
        </div>
        <div className={classnames(styles.imageWrapper, "m-1")}>
            <img src={MasterCard} alt="master card" className="mx-1" />
        </div>
        <div className={classnames(styles.imageWrapper, "m-1")}>
            <img src={AmericanExpress} alt="american express" className="mx-1" />
        </div>
        {(isAmendPayment || isAboveStripeMinimum) && (
            <div className={classnames(styles.imageWrapper, styles.width40, "m-1 pt-1")}>
                <Stripe />
            </div>)}
    </div>
);

export default PaymentLogos;
