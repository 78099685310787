import React from "react";
import { FormGroup, Label } from "reactstrap";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import FloatLabelDate from "@/Components/FloatLabelDate";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import ProfileSection from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/Section";
import formatDateHelper from "@/Utils/formatDateHelper";
import ReadonlyProfileField from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/ReadonlyField";
import { FloatLabelDropdown } from "@/Components/FloatLabelDropdown";
import { ParticipantContactRelationship } from "@/FlexPlan/Types/Participant";
import { enumDisplay } from "@/Utils/formatStringHelper";
import { genderFloatLabelDropdown } from "@/FlexPlan/Types/Gender/Helpers";
import { Notes } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/Notes";
import { StatementEmailsSelect } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Components/StatementEmailsSelect";
import RadioButton from "@/Components/RadioButton";
import { useFlexPlanParticipantContext } from "@/FlexPlan/Pages/Participants/ParticipantProfile/Contexts/ParticipantProfileContext";
import Spinner from "@/Components/Spinner";

const PersonalInformationTab = () => {
    const {
        handleFormChange,
        editDob,
        errors,
        formState,
        onFloatingLabelDropdownSelect,
        onAddressChange,
        onSaveAddress,
        onRadioChange,
        loading,
    } = useFlexPlanParticipantContext();

    if (loading) {
        return (
            <Spinner className="tw-my-10 tw-mx-auto" size="40" />
        );
    }

    return (
        <div>
            <Notes />
            <ProfileSection
                section="Basic Information"
                editEnabledMarkup={(
                    <>
                        <FloatLabelTextbox
                            label="First Name *"
                            onChange={handleFormChange("firstName")}
                            value={formState.firstName}
                            error={errors && errors.firstName && "Please enter a First Name. Must be less than 255 characters."}
                        />
                        <FloatLabelTextbox
                            label="Last Name *"
                            onChange={handleFormChange("lastName")}
                            value={formState.lastName}
                            error={errors && errors.lastName && "Please enter a Last Name. Must be less than 255 characters."}
                            disabled
                            inputStyle={{ opacity: "0.3" }}
                        />
                        <div style={{ opacity: "0.6" }}>
                            <FloatLabelDate
                                label="Date of Birth *"
                                changeDate={editDob}
                                error={errors && errors.dateOfBirth && "Please enter a date of birth"}
                                currentDate={formState.dateOfBirth}
                                disabled
                            />
                        </div>
                        <FloatLabelTextbox
                            label="NDIS Number *"
                            onChange={handleFormChange("ndisNumber")}
                            value={formState.ndisNumber}
                            error={errors && errors.ndisNumber && "Please enter a valid NDIS number"}
                            maxLength={9}
                            disabled
                            inputStyle={{ opacity: "0.3" }}
                        />
                        <FormGroup>
                            <FloatLabelDropdown
                                label="Gender"
                                className="tw-max-w-[500px]"
                                onApply={onFloatingLabelDropdownSelect("gender")}
                                options={genderFloatLabelDropdown}
                                selectedValue={formState.gender}
                            />
                        </FormGroup>
                    </>
                )}
                editingDisableMarkup={(
                    <>
                        <ReadonlyProfileField
                            label="First Name *"
                            value={formState.firstName}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Last Name *"
                            value={formState.lastName}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Date of Birth *"
                            value={formatDateHelper.format(formState.dateOfBirth, "DD/MM/YYYY") ?? ""}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="NDIS Number *"
                            value={formState.ndisNumber}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Gender"
                            value={formState.gender}
                        />
                    </>
                )}
            />
            <div className="d-flex flex-column border rounded-lg p-4 m-lg-3 mb-4 mt-4">
                <FloatLabelAddressLookUp
                    address={formState.address}
                    onSave={onSaveAddress}
                    errorClasses="font-weight-bold !tw-text-bootstrap-error-size tw-mt-2"
                    error={errors?.address && "Please enter a valid address"}
                    onStateChange={onAddressChange}
                />
            </div>
            <ProfileSection
                section="Contact Information"
                editEnabledMarkup={(
                    <div className="tw-flex tw-flex-col">
                        <FloatLabelTextbox
                            label="Alternate Name (e.g. Nominee)"
                            onChange={handleFormChange("alternateName")}
                            value={formState.alternateName}
                            error={errors && errors.alternateName && "Must not exceed 255 characters"}
                        />
                        <FormGroup className="tw-flex-grow tw-max-w-[500px]">
                            <FloatLabelDropdown
                                label="Relationship to the participant"
                                onApply={onFloatingLabelDropdownSelect("relationshipToParticipant")}
                                options={Object.keys(ParticipantContactRelationship).map(x => ({
                                    display: enumDisplay(ParticipantContactRelationship[x]),
                                    value: ParticipantContactRelationship[x],
                                }))}
                                selectedValue={formState.relationshipToParticipant}
                            />
                        </FormGroup>
                        {formState.relationshipToParticipant === ParticipantContactRelationship.other && (
                            <FloatLabelTextbox
                                label="Specified Relationship *"
                                value={formState.otherRelationship}
                                className="tw-flex-grow tw-max-w-[500px]"
                                onChange={handleFormChange("otherRelationship")}
                                error={errors?.otherRelationship && "Please specify the relationship. Must be less than 255 characters."}
                            />
                        )}
                        <FloatLabelTextbox
                            label="Phone *"
                            value={formState.phoneNumber}
                            onChange={handleFormChange("phoneNumber")}
                            error={errors?.phoneNumber && "Please enter a valid phone number"}
                        />
                        <FloatLabelTextbox
                            label="E-mail Address *"
                            onChange={handleFormChange("email")}
                            value={formState.email}
                            error={errors && errors.email && "Please enter a valid e-mail address. Must be less than 255 characters."}
                        />
                    </div>
                )}
                editingDisableMarkup={(
                    <div>
                        <ReadonlyProfileField
                            label="Alternate Name (e.g. Nominee)"
                            value={formState.alternateName}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Relationship to the participant"
                            value={formState.relationshipToParticipant === ParticipantContactRelationship.other
                                ? formState.otherRelationship
                                : enumDisplay(formState.relationshipToParticipant)}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Phone *"
                            value={formState.phoneNumber}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="E-mail Address *"
                            value={formState.email}
                        />
                    </div>)}
            />
            <ProfileSection
                section="Statements"
                editEnabledMarkup={(
                    <div className="tw-flex tw-flex-col">
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label>Do you want to enable sending statements for this participant?</Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.sendStatements}
                                    onChange={onRadioChange("sendStatements", true)}
                                    id="send-statements"
                                    label="Yes"
                                />
                                <RadioButton
                                    checked={!formState.sendStatements}
                                    onChange={onRadioChange("sendStatements", false)}
                                    id="send-statements"
                                    label="No"
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-5">
                            <Label className="tw-flex tw-flex-row">
                                Do you want to use the participant&apos;s contact email address above?
                            </Label>
                            <div className="tw-flex tw-flex-row">
                                <RadioButton
                                    checked={formState.useContactEmailForSendingStatements}
                                    onChange={onRadioChange("useContactEmailForSendingStatements", true)}
                                    id="send-statements"
                                    label="Yes"
                                />
                                <RadioButton
                                    checked={!formState.useContactEmailForSendingStatements}
                                    onChange={onRadioChange("useContactEmailForSendingStatements", false)}
                                    id="send-statements"
                                    label="No"
                                />
                            </div>
                        </FormGroup>
                        <StatementEmailsSelect />
                    </div>
                )}
                editingDisableMarkup={(
                    <div>
                        <ReadonlyProfileField
                            label="Do you want to send statements to this Participant?"
                            value={formState.sendStatements ? "Yes" : "No"}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Do you want to use the participant's contact email address?"
                            value={formState.useContactEmailForSendingStatements ? "Yes" : "No"}
                        />
                        <hr />
                        <ReadonlyProfileField
                            label="Statement Email address(es)"
                            value={formState.statementEmails?.map(x => x.email).join(", ")}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default PersonalInformationTab;
