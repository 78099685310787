import React from "react";

import CloseButton from "@/Components/CloseButton";
import Modal from "@/Modals/Modal";
import { ReducedBasketFieldsType } from "@/Apis/Basket";

const SupplierDisclaimerModal = ({ onClose, fields }: { onClose(): void, fields: ReducedBasketFieldsType[] }) => (
    <Modal
        isOpen
        objectTitle={
            <>
                <h4 className="mb-0">Supplier Disclaimer</h4>
                <CloseButton onClick={onClose} />
            </>
        }
        toggle={onClose}
    >
        <p className="pb-2">
            The data you have entered while filling out your forms goes to your supplier and is accessed on
            Business Support Club. Below you can see what data each supplier has requested.
        </p>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Field</th>
                    <th scope="col">Requested By</th>
                </tr>
            </thead>
            <tbody>
                {fields.map((field) => (
                    <tr>
                        <td>{field.displayText}</td>
                        <td>
                            {field.supplierNames.map(supplierName => (<>{supplierName}<br /></>))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </Modal>
);

export { SupplierDisclaimerModal };
