/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";

import { ProductVariant } from "./ProductVariant";
import styles from "./styles.module.scss";

import SingleProductIcon from "@/Assets/Icons/Coloured/single-product.svg";
import ListedIcon from "@/Assets/Icons/Coloured/listed-product.svg";
import CustomisableIcon from "@/Assets/Icons/Coloured/customisable-product.svg";
import { Popper } from "@/Components/Popper";
import { Feature } from "@/Components/Feature";
import Checkbox from "@/Components/Checkbox";
import { features } from "@/Utils/features";
import { ProductVariants } from "@/Utils/ProductVariants";

const Pricing = ({
    initialCharge = 0,
    postageCharge = 0,
    isVatRequired = false,
    frequencies,
    paymentFrequency = "",
    addQuantity = false,
    minimumQuantity = 0,
    productVariant = ProductVariants.singleProduct,
    isOffsitePayment = false,
    allowFlexiblePayments = false,
    isFreeProduct = false,
    isOfflineInvoiceable = false,
    supplierCanOfflineInvoice = false,
    hideInMarketplace = false,
    isAHexagonProduct = false,
    isDelayedPayment = false,
    delayedPaymentFor = 1,
    delayedPaymentPeriod = "0",
    delayedPaymentPeriodList = [],
    errors = {},
    onChange,
    onChangeMultiple,
}) => {
    const [addPostage, setTogglePostage] = useState(Number(postageCharge) !== 0);
    const togglePostage = (on) => {
        if (!on) {
            onChange("postageCharge")({ target: { value: "0.00" } });
        }
        setTogglePostage(on);
    };
    const onPaymentFrequencyChange = (e) => {
        const partialService = {
            paymentFrequency: e.target.value,
            initialCharge: e.target.value === "N/A" ? "" : initialCharge,
            postageCharge: e.target.value === "N/A" ? "" : postageCharge,
            addPostage: e.target.value === "N/A" ? false : addPostage,
            isFreeProduct: e.target.value === "N/A" ? false : isFreeProduct,
            hideInMarketplace: e.target.value === "N/A" ? false : hideInMarketplace,
            isAHexagonProduct: e.target.value === "N/A" ? false : isAHexagonProduct,
        };

        onChangeMultiple(partialService);

        if (e.target.value === "N/A") {
            togglePostage(false);
        }
    };

    const toggleOffsitePayment = (checked) => {
        const partialService = {
            isOffsitePayment: checked,
        };

        onChangeMultiple(partialService);
    };

    const toggleFreeProductChange = (checked) => {
        if (checked) {
            onChange("initialCharge")({ target: { value: "0.00" } });
        }
        onChange("isFreeProduct")({ target: { value: checked } });
    };

    const toggleInvoice = (checked) => {
        onChange("isOfflineInvoiceable")({ target: { value: checked } });
        if (checked) {
            onChange("hideInMarketplace")({ target: { value: true } });
            onChange("isFreeProduct")({ target: { value: false } });
        }
    };

    useEffect(() => {
        // Reset defaults for delayed payments
        if (!isDelayedPayment) {
            onChange("delayedPaymentFor")({ target: { value: 1 } });
            onChange("delayedPaymentPeriod")({ target: { value: "0" } }); // Default to Weeks
        }
        // Reset delayed payment values to default if it is IsOffSitePayment
        // Reset IsFreeProduct if off site payment is set
        if (isOffsitePayment) {
            onChange("delayedPaymentFor")({ target: { value: 1 } });
            onChange("delayedPaymentPeriod")({ target: { value: "0" } });
            onChange("isDelayedPayment")({ target: { value: false } });
            onChange("isFreeProduct")({ target: { value: false } });
        }
        // Reset off site payment to default if it's freeProduct
        if (isFreeProduct) {
            onChange("isOffsitePayment")({ target: { value: false } });
        }
    }, [isDelayedPayment, isOffsitePayment, isFreeProduct]);

    return (
        <Row className="align-items-end mt-4 pr-4 pl-4">
            <Feature name={features.customisedProducts}>
                <Col xs="12" md="12" className="mb-2">
                    <div className={styles.productVariantTitle}>Select table layout from below</div>
                </Col>
                <Col xs="12" md="4" className="pr-0">
                    <ProductVariant
                        productVariant={ProductVariants.singleProduct}
                        checkedProductVariant={productVariant}
                        onChange={onChange}
                        icon={<SingleProductIcon className={classnames(styles.productVariantIcon, "mb-2")} />}
                    >
                        This will configure a product/service with a set price and quantity in the product tile.
                        This won’t let customer configure order any further.
                    </ProductVariant>
                </Col>
                <Col xs="12" md="4" className="pr-0">
                    <ProductVariant
                        productVariant={ProductVariants.listedProduct}
                        checkedProductVariant={productVariant}
                        onChange={onChange}
                        icon={<ListedIcon className={classnames(styles.productVariantIconListed, "mb-2")} />}
                    >
                        In listed tables, the customer can select which items from the list they would want to add to the cart.
                        There are no customisations for the specific product / service on the list.
                    </ProductVariant>
                </Col>
                <Col xs="12" md="4" className="pr-0">
                    <ProductVariant
                        productVariant={ProductVariants.customisableProduct}
                        checkedProductVariant={productVariant}
                        onChange={onChange}
                        icon={<CustomisableIcon className={classnames(styles.productVariantIcon, "mb-2")} />}
                    >
                        In customisable tables, the customer can configure the combination and quantity of products they add from the selection provided by the supplier.
                    </ProductVariant>
                </Col>
            </Feature>

            {allowFlexiblePayments && (
                <Col xs="12" className="mt-md-5">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <Checkbox
                                checked={isOffsitePayment}
                                disabled={isFreeProduct}
                                id="isOffsitePayment"
                                label="Off-site Payment"
                                onChange={toggleOffsitePayment}
                                testId="isOffsitePayment"
                                className="d-inline-block mr-0"
                            />
                            <Popper
                                placement="right"
                                targetId="addAService-offsitePayment"
                                popoverClassName={classnames(styles.popper)}
                                popoverBodyClassName={classnames(styles.popperBody, "shadow-lg p-3")}
                                icon={<i
                                    id="addAService-offsitePayment"
                                    className="font-weight-bold mb-0 pointer text-primary fas fa-info-circle"
                                    data-testid="addAService-offsitePayment"
                                />}
                            >
                                Payment terms are defined within the Supplier Terms and Conditions document uploaded.
                                BSC will generate invoice or order sheet depending on price selection.
                            </Popper>
                        </div>
                    </div>
                </Col>
            )}
            <Col xs="12" md="6">
                <Label>Cost *</Label>
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <Input
                            type="text"
                            disabled={isFreeProduct || (allowFlexiblePayments && paymentFrequency === "N/A")}
                            placeholder="Price of product/service"
                            value={(allowFlexiblePayments && paymentFrequency === "N/A") ? "" : initialCharge || ""}
                            invalid={!!errors.initialCharge}
                            onChange={onChange("initialCharge")}
                            data-testid="service-cost"
                        />
                    </div>
                </div>
                <FormFeedback className="mt-0 font-weight-bold d-block">{errors.initialCharge}&nbsp;</FormFeedback>
            </Col>
            <Col xs="12" md="6" className="mt-md-5">
                <div className="d-flex py-4">
                    <div className="flex-grow-1">
                        <Checkbox
                            checked={isFreeProduct}
                            disabled={isOfflineInvoiceable || (allowFlexiblePayments && paymentFrequency === "N/A") || isOffsitePayment}
                            id="isFreeProduct"
                            label="Free Product"
                            onChange={toggleFreeProductChange}
                            testId="isFreeProduct"
                        />
                    </div>
                    <div className={styles.invoice}>
                        {supplierCanOfflineInvoice && (
                            <Checkbox
                                checked={isOfflineInvoiceable}
                                disabled={allowFlexiblePayments && paymentFrequency === "N/A"}
                                id="isOfflineInvoiceable"
                                label="Invoice"
                                onChange={toggleInvoice}
                                testId="isOfflineInvoiceable"
                            />)}
                    </div>
                </div>
            </Col>

            <Col xs="12" md="6" className="mt-4">
                <div className="d-flex">
                    <Input
                        type="select"
                        value={paymentFrequency || frequencies[0]}
                        onChange={onPaymentFrequencyChange}
                        data-testid="payment-frequency-select"
                    >
                        {frequencies.map(x => <option key={x} value={x}>{x}</option>)}
                    </Input>
                </div>
            </Col>
            <Col xs="12" md="6" className="mt-4">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <Checkbox
                            checked={isVatRequired && Number(initialCharge) !== 0}
                            disabled={Number(initialCharge) === 0 || (allowFlexiblePayments && paymentFrequency === "N/A")}
                            id="isVatRequired"
                            label="Apply VAT"
                            onChange={(value) => {
                                onChange("isVatRequired")({ target: { value } });
                            }}
                            testId="service-apply-vat"
                        />
                    </div>
                    <div>
                        <Checkbox
                            checked={hideInMarketplace}
                            disabled={isOfflineInvoiceable}
                            id="hideInMarketplace"
                            label="Hide from Marketplace"
                            onChange={(value) => {
                                onChange("hideInMarketplace")({ target: { value } });
                            }}
                            testId="hideInMarketplace"
                        />
                    </div>
                </div>
            </Col>

            {!isOffsitePayment && (
                <Col xs="12" md="6" className="mt-4">
                    <label>Payment Delay</label>
                    <div className="d-md-flex">
                        <Checkbox
                            checked={isDelayedPayment}
                            id="isDelayedPayment"
                            label="Delay payment for"
                            disabled={!paymentFrequency.includes("Subscription")}
                            onChange={(value) => {
                                onChange("isDelayedPayment")({ target: { value } });
                            }}
                        />
                        <div className="flex-grow-1 mr-2">
                            <Input
                                type="number"
                                placeholder=""
                                value={delayedPaymentFor}
                                disabled={!isDelayedPayment}
                                invalid={!!errors.delayedPaymentFor}
                                onChange={onChange("delayedPaymentFor")}
                            />
                            <FormFeedback className="mt-0 font-weight-bold d-block">{errors.delayedPaymentFor}&nbsp;</FormFeedback>
                        </div>
                        <div className="flex-grow-1">
                            <Input
                                type="select"
                                value={delayedPaymentPeriod}
                                disabled={!isDelayedPayment}
                                onChange={onChange("delayedPaymentPeriod")}
                            >
                                {delayedPaymentPeriodList.map(x => <option key={x.value} value={x.value}>{x.name}</option>)}
                            </Input>
                        </div>
                    </div>
                </Col>
            )}

            <Col xs="12" md="6" className="mt-4">
                <Label>Minimum Quantity</Label>
                <div className="d-md-flex">
                    <Checkbox
                        checked={addQuantity}
                        id="addQuantity"
                        label="Add Quantity To Service"
                        disabled={ProductVariants.listedProduct === productVariant}
                        onChange={(value) => {
                            onChange("addQuantity")({ target: { value } });
                        }}
                    />
                    <div className="flex-grow-1">
                        <Input
                            type="number"
                            placeholder="Minimum quantity required"
                            value={minimumQuantity}
                            onChange={onChange("minimumQuantity")}
                            disabled={!addQuantity}
                            invalid={addQuantity && minimumQuantity.toString() === "0"}
                        />
                    </div>
                </div>
            </Col>
            <Col xs="12" md="6" className="mt-4">
                <Label>Postage Price</Label>
                <div className="d-md-flex">
                    <Checkbox
                        checked={addPostage}
                        id="addPostage"
                        label="Add Postage Cost"
                        onChange={togglePostage}
                        disabled={allowFlexiblePayments && paymentFrequency === "N/A"}
                    />
                    <div className="flex-grow-1">
                        <Input
                            type="text"
                            value={paymentFrequency === "N/A" ? "" : postageCharge || "0.00"}
                            placeholder="Price of postage"
                            onChange={onChange("postageCharge")}
                            invalid={!!errors.postageCharge}
                            disabled={!addPostage || (allowFlexiblePayments && paymentFrequency === "N/A")}
                            data-testid="postage-cost-input-id"
                        />
                        <FormFeedback className="mt-0 font-weight-bold">{errors.postageCharge}</FormFeedback>
                    </div>
                </div>
            </Col>
            <Col xs="12" md="6" className="mt-4">
                <div className="tw-flex tw-flex-row">
                    <div className="flex-grow-1">
                        <Label>Hexagon Product</Label>
                        <Checkbox
                            checked={isAHexagonProduct}
                            id="isHexagonProduct"
                            label="Is Hexagon Product"
                            onChange={(value) => {
                                onChange("isAHexagonProduct")({ target: { value } });
                            }}
                            data-testid="is-hexagon-product"
                        />
                    </div>
                </div>
            </Col>
        </Row>

    );
};

Pricing.propTypes = {
    frequencies: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeMultiple: PropTypes.func.isRequired,
    initialCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    postageCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paymentFrequency: PropTypes.string,
    isVatRequired: PropTypes.bool,
    addQuantity: PropTypes.bool,
    minimumQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productVariant: PropTypes.oneOf([ProductVariants.singleProduct, ProductVariants.listedProduct, ProductVariants.customisableProduct]),
    isOfflineInvoiceable: PropTypes.bool,
    isOffsitePayment: PropTypes.bool,
    allowFlexiblePayments: PropTypes.bool,
    isFreeProduct: PropTypes.bool,
    supplierCanOfflineInvoice: PropTypes.bool,
    hideInMarketplace: PropTypes.bool,
    errors: PropTypes.shape(),
    isAHexagonProduct: PropTypes.bool,
    isDelayedPayment: PropTypes.bool,
    delayedPaymentFor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    delayedPaymentPeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
    delayedPaymentPeriodList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    })).isRequired,
};

export { Pricing };
