/* eslint-disable no-param-reassign,import/extensions */
import React, { ReactNode } from "react";

import ExploreLogo from "@/Assets/Images/Onboarding/onboarding-explore marketplace.svg";
import SearchLogo from "@/Assets/Images/Onboarding/onboarding-search.svg";
import RecommendationsLogo from "@/Assets/Images/Onboarding/onboarding-search recommendations.svg";
import FiltersLogo from "@/Assets/Images/Onboarding/onboarding-filters.svg";
import ProductsLogo from "@/Assets/Images/Onboarding/onboarding-browse products.svg";
import DetailsLogo from "@/Assets/Images/Onboarding/onboarding-view details.svg";
import PurchaseLogo from "@/Assets/Images/Onboarding/onboarding-purchase.svg";
import AddedToCartLogo from "@/Assets/Images/Onboarding/onboarding-added to cart.svg";
import CheckoutLogo from "@/Assets/Images/Onboarding/onboarding-checkout.svg";
import RevisitLogo from "@/Assets/Images/Onboarding/onboarding-revisit guide.svg";

export type OnboardingProps = {
    name: OnboardingSteps;
    onDisplay?(): void;
    onEndDisplay?(): void;
    onClose(): void;
    onContinue(): void;
    content: ReactNode;
};

export enum OnboardingSteps {
    exploreTheMarketplace = "Explore the Marketplace",
    search = "Search",
    searchRecommendations = "Search Recommendations",
    filters = "Filters",
    browseProductsAndServices = "Browse Products and Services",
    viewDetails = "View Details",
    purchase = "Purchase",
    addedToCart = "Added to Cart",
    checkout = "Checkout",
    revisitTheGuide = "Revisit the Guide",
}

export const getOnboardingSteps = (selectRef, filterRef, toggleBasket: (show?:boolean) => void, toggleFilters: (show?:boolean) => void) => ([{
    name: OnboardingSteps.exploreTheMarketplace,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <ExploreLogo />
            </div>
            <p>
                BSC’s Marketplace allows you to browse suppliers
                and products that cater to every business need, in
                one easy-to-navigate platform.
            </p>
        </>),
},
{
    name: OnboardingSteps.search,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <SearchLogo />
            </div>
            <p>Find what you need with the search bar.</p>
            <p>Try typing in a keyword (e.g. branding) or the name of a supplier.</p>
        </>),
    onDisplay: () => {
        selectRef.current.select.state.menuIsOpen = true;
    },
},
{
    name: OnboardingSteps.searchRecommendations,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <RecommendationsLogo />
            </div>
            <p>Have a look at the search recommendations to start your search.</p>
        </>),
    onDisplay: () => { selectRef.current.select.state.menuIsOpen = false; },
    onEndDisplay: () => { selectRef.current.select.state.menuIsOpen = false; },
},
{
    name: OnboardingSteps.filters,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <FiltersLogo />
            </div>
            <p>Refine your options using the Category, Supplier, and Sort By filters.</p>
        </>),
    onDisplay: () => toggleFilters(true),
    onEndDisplay: () => toggleFilters(false),
},
{
    name: OnboardingSteps.browseProductsAndServices,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <ProductsLogo />
            </div>
            <p>Pick from a wide selection of verified suppliers, providing a range of products and services to support your business.</p>
        </>),
},
{
    name: OnboardingSteps.viewDetails,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <DetailsLogo />
            </div>
            <p>Each product/service tile shows you basic information about an item at a glance. You can read more about it by clicking the View Details button.</p>
        </>),
},
{
    name: OnboardingSteps.purchase,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <PurchaseLogo />
            </div>
            <p>Quickly add the item to your cart by clicking the yellow button.<br /></p>
        </>),
},
{
    name: OnboardingSteps.addedToCart,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <AddedToCartLogo />
            </div>
            <p>
                A success message shows that the chosen item has
                been added to your cart. From here, you can view
                the items in your cart - click View Cart - or proceed
                straight to checkout - click Checkout.
            </p>
            <p>
                You can also continue to browse the Marketplace, and add more items to your cart.
            </p>
        </>),
},
{
    name: OnboardingSteps.checkout,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <CheckoutLogo />
            </div>
            <p>Review the items added to your cart, and click Checkout to proceed to purchase.</p>
        </>),
    onDisplay: () => toggleBasket(true),
    onEndDisplay: () => toggleBasket(false),
},
{
    name: OnboardingSteps.revisitTheGuide,
    content: (
        <>
            <div className="mb-4 d-flex justify-content-center">
                <RevisitLogo />
            </div>
            <p>
                You can revisit this guide at any time by clicking the Help icon (?) in the upper right corner of the screen.
                Conveniently return to a topic you want to review.
            </p>
        </>),
    onDisplay: () => selectRef.current.blur(),
},
] as OnboardingProps[]);
