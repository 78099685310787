import { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { getToken, removeToken } from "@/Utils/authentication";
import { getBrowserId } from "@/Utils/localStorage";
import userActions from "@/Store/User/actions";
import actions from "@/Store/Global/actions";

const configuration = { domainUrl: "/api/" };

interface OptionsProps {
    handle500WithRedirect?: boolean;
    handle500WithToastMessage?: boolean;
}

const getHeaders = (): HeadersInit => {
    const token = getToken();
    const headers: HeadersInit = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-browserid": getBrowserId(),
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return headers;
};

export interface FileResponse {
    name: string;
    contents: Blob;
}
export interface ErrorResponse {
    errors: any;
    validationFailed: boolean;
}
type ResponseKinds = "auto" | "blob";
const parseResponse = async (response: Response, as: ResponseKinds) => {
    if (as === "blob") {
        const header = response.headers.get("Content-Disposition");
        const parts = header!.split(";");
        const filename = parts[1].split("=")[1];
        return {
            name: filename,
            contents: await response.blob(),
        } as FileResponse;
    }
    const contentType = response.headers.get("content-type");
    if (contentType === "text/plain; charset=utf-8") {
        return response.text();
    }
    const json = await response.json();
    return json;
};

const useAPI = ({ handle500WithRedirect, handle500WithToastMessage }: OptionsProps = { handle500WithRedirect: false, handle500WithToastMessage: false }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { push } = useHistory();
    const dispatch = useDispatch();

    const common = async (method, url, body, settings?: RequestSettings, as: ResponseKinds = "auto") => {
        try {
            setLoading(true);
            const response = await fetch(configuration.domainUrl + url, {
                method,
                body: JSON.stringify(body),
                headers: getHeaders() as HeadersInit,
            });
            setLoading(false);
            if (!response.ok) {
                if (response.status >= 405) {
                    const error = await response.json();
                    throw { message: (error && (error.message || error.Message)) };
                } else if (response.status === 401) {
                    removeToken();
                    throw { message: response.statusText, status: response.status };
                } else if (response.status === 404) {
                    throw { message: response.statusText };
                } else {
                    throw await parseResponse(response, as);
                }
            }

            if (response.status === 204) {
                return {};
            }

            return await parseResponse(response, as);
        } catch (err) {
            const error = err as any;
            if (error.status === 401) {
                removeToken();
                dispatch(userActions.clearUser());
                push(settings?.loginUri ?? "/login", "You must be logged in to perform that action.");
            }
            if (error.status === 500) {
                if (handle500WithToastMessage) {
                    dispatch(actions.setErrorToastMessage(true, "Unfortunately an error occurred. If this problem persists, please contact us."));
                }
                if (handle500WithRedirect) {
                    push("/error", { error });
                }
            }

            if (error.message?.indexOf("Unexpected token E in JSON at position") > -1) {
                push("/error", { error: "There is an issue with server. It may not be running." });
            }
            setLoading(false);
            return Promise.reject(error.message ?? error);
        } finally {
            setLoading(false);
        }
    };

    const get = async <T>(url: string, settings?: RequestSettings): Promise<T> => common("GET", url, undefined, settings);
    const getFile = async (url: string, settings?: RequestSettings): Promise<FileResponse> => common("GET", url, undefined, settings, "blob");
    const postFile = async (url: string, body, settings?: RequestSettings): Promise<FileResponse> => common("POST", url, body, settings, "blob");
    const post = async <T = any>(url: string, body, settings?: RequestSettings): Promise<T> => common("POST", url, body, settings);
    const del = (url, body, settings?: RequestSettings) => common("DELETE", url, body, settings);
    const put = async <T = any>(url: string, body, settings?: RequestSettings): Promise<T> => common("PUT", url, body, settings);

    return { loading, get, getFile, postFile, post, del, put };
};

export interface RequestSettings {
    loginUri?: string,
}

export { useAPI };
