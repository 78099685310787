import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";

import CloseButton from "@/Components/CloseButton";

interface Props {
    version?: "v1" | "v2",
    isOpen: boolean,
    toggle: () => void,
    referralLink: string,
    createReferral: () => void,
    copyToClipboard: () => void,
}

const ReferralLinkModal = ({ version = "v1", isOpen, toggle, referralLink, createReferral, copyToClipboard }: Props) => {
    if (version === "v1") {
        return (
            <Modal isOpen={isOpen} toggle={toggle} centered size="md">
                <CloseButton onClick={toggle} />
                <ModalBody className="p-5 d-flex flex-column">
                    <h3>Create a Referral link</h3>
                    <p className="my-4">
                        If a user redeems your referral link, you will earn <strong>3% commission</strong> on all their
                        BSC purchases for life.
                    </p>

                    <h5 className="text-primary mb-4" id="link-text">{referralLink}</h5>

                    <div className="d-flex flex-row">
                        <Button color="primary" outline className="w-50 mr-1" onClick={createReferral}>
                            <i className="fas fa-redo-alt" /> Create New Link
                        </Button>
                        <Button color="primary" className="w-50 ml-1" onClick={copyToClipboard}>
                            <i className="fas fa-copy" /> Copy Link
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    return ( // V2
        <Modal
            isOpen={isOpen}
            centered
            size="lg"
            className="sm:tw-p-10 !tw-rounded-xl"
            contentClassName="!tw-rounded-2xl"
        >
            <ModalHeader className="tw-space-x-1 tw-bg-brand-primary tw-text-white !tw-rounded-t-xl">
                Create a Referral Link
                <CloseButton onClick={toggle} className="!tw-text-white" />
            </ModalHeader>
            <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8 tw-space-y-3">
                <div className="tw-py-3 tw-text-base" data-testid="modal-text">
                    If a user redeeems your referral link, you will earn 3% commission on all their BSC purchases for life.
                </div>
                <div className="tw-w-full tw-px-4 tw-border-2 tw-h-14 tw-flex tw-justify-center tw-items-center">
                    <button
                        type="button"
                        className="tw-text-brand-primary tw-underline hover:tw-cursor-pointer"
                        onClick={copyToClipboard}
                    >
                        {referralLink}
                    </button>
                </div>
                <p className="tw-text-sm tw-text-center tw-py-3">You can revisit your referral link from your My Referrals page{" "}
                    <Link to="/profile/referrals" className="tw-underline">here</Link>
                </p>
                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-3 tw-items-center tw-w-full lg:tw-space-y-0 lg:tw-space-x-3 tw-justify-center tw-mt-2">
                    <Button color="primary" className="tw-text-base tw-w-full lg:tw-w-1/4" onClick={createReferral}>Create new link</Button>
                    <Button color="primary" className="tw-text-base tw-w-full lg:tw-w-1/4" onClick={copyToClipboard} data-testid="modal-submit-button">Copy link</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ReferralLinkModal;
