import React from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

import FloatLabelTextbox from "@/Components/FloatLabelTextbox";
import { ActionButtons } from "@/FlexPlan/Pages/PlanManagers/PlanManagerProfile/Components/ActionButtons";
import { useFlexPlanSupplierProfileContext, useFlexPlanSupplierProfileDispatchContext } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Contexts/SupplierProfileContext";
import { FlexPlanSupplier } from "@/FlexPlan/Pages/Suppliers/Types";
import { SupplierSections } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types";
import { SupplierProfileActions } from "@/FlexPlan/Pages/Suppliers/SupplierProfile/Types/Reducer";

interface Props {
    onSave: (formState?: FlexPlanSupplier) => void,
    removeErrorField: (field: keyof FlexPlanSupplier) => void,
    onChangeEditableSections: (value: SupplierSections) => () => void,
    errors: any,
    openSections: SupplierSections[],
}

const BankDetailsSection = ({ onSave, removeErrorField, onChangeEditableSections, errors, openSections }: Props) => {
    const supplierProfileState = useFlexPlanSupplierProfileContext();
    const dispatch = useFlexPlanSupplierProfileDispatchContext();

    const onChange = (e: { target: { name: keyof FlexPlanSupplier, value: any } }) => {
        dispatch({
            type: SupplierProfileActions.Update,
            field: e.target.name,
            value: e.target.value,
        });
        removeErrorField(e.target.name);
    };

    return (
        <>
            <Row>
                <Col xs="12" className="d-flex justify-content-between mb-3">
                    <h4 className="font-weight-bold">Bank Details</h4>
                    {!openSections.includes("Bank Details") && <Button className="px-3" color="primary" onClick={onChangeEditableSections("Bank Details")}>Edit</Button>}
                </Col>
            </Row>
            <>
                {openSections.includes("Bank Details") ? (
                    <>
                        <FloatLabelTextbox
                            label="Bank Name"
                            value={supplierProfileState.bankName}
                            name="bankName"
                            onChange={onChange}
                            error={errors.bankName}
                            maxLength={255}
                        />
                        <FloatLabelTextbox
                            label="Account Name"
                            value={supplierProfileState.accountName}
                            name="accountName"
                            onChange={onChange}
                            error={errors.accountName}
                            maxLength={32}
                        />
                        <FloatLabelTextbox
                            label="BSB"
                            value={supplierProfileState.bsb}
                            name="bsb"
                            onChange={onChange}
                            error={errors.bsb}
                            maxLength={7}
                        />
                        <FloatLabelTextbox
                            label="Account Number"
                            value={supplierProfileState.accountNumber}
                            name="accountNumber"
                            onChange={onChange}
                            error={errors.accountNumber}
                            maxLength={9}
                        />
                        <div className="d-flex justify-content-end mt-3">
                            <ActionButtons onCancelClick={onChangeEditableSections("Bank Details")} onSaveClick={onSave} />
                        </div>
                    </>
                ) : (
                    <>
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Bank Name</Label>
                            <Label className="font-weight-bold">{supplierProfileState.bankName}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Account Name</Label>
                            <Label className="font-weight-bold">{supplierProfileState.accountName}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">BSB</Label>
                            <Label className="font-weight-bold">{supplierProfileState.bsb}</Label>
                        </FormGroup>
                        <hr />
                        <FormGroup className="d-flex w-75 mb-2">
                            <Label className="w-25">Account Number</Label>
                            <Label className="font-weight-bold">{supplierProfileState.accountNumber}</Label>
                        </FormGroup>
                    </>
                )}
            </>
        </>
    );
};

export { BankDetailsSection };
