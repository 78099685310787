import store from "store";

export const JWT = "__tbsc.auth";

export const removeToken = () => { store.remove(JWT); };

const getTokenExpiry = () => {
    const accessToken = store.get(JWT);

    if (accessToken) {
        const tokenExpiry = parseInt(accessToken.expiry || "0", 10);
        return tokenExpiry * 1000;
    }

    return null;
};

export const tokenExpired = () => {
    const expiry = getTokenExpiry();
    if (expiry === null) {
        return false;
    }
    return expiry <= new Date().getTime();
};

export const getToken = () => ((getTokenExpiry() && !tokenExpired()) ? store.get(JWT).token : null);

export const setToken = (token, expiry) => { store.set(JWT, { token, expiry }); };

export const hasToken = () => Boolean(getToken());

export const decodeCustomToken = (token) => {
    try {
        const base64Url = token.split(".")[1];
        return JSON.parse(atob(base64Url));
    } catch (e) {
        return { error: "Invalid token" };
    }
};

export const decodeToken = () => {
    const token = getToken();
    const decodedToken = decodeCustomToken(token);

    if (decodedToken?.error) {
        removeToken();
    }

    return decodedToken;
};
