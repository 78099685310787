import { v4 } from "uuid";

import { Order, OrderRow } from "@/FlexPlan/Pages/Orders/OrderDetails/Types";
import { FlexPlanOrderStatus, PaymentRequestStatus } from "@/FlexPlan/Types/Order";
import { toTwoDecimalPlaces } from "@/FlexPlan/Utils/currency";

const newOrderItem = (position: number): OrderRow => ({
    id: v4(),
    itemCode: "",
    description: "",
    startDate: "",
    endDate: "",
    quantity: toTwoDecimalPlaces(0),
    rate: toTwoDecimalPlaces(0),
    isGst: false,
    total: toTwoDecimalPlaces(0),
    amountApproved: toTwoDecimalPlaces(0),
    status: PaymentRequestStatus.NoStatus,
    isChecked: false,
    position,
    dateCreated: null,
    refDocNo: v4(),
    invalidFields: { },
});

const newOrder = (): Order => ({
    id: v4(),
    orderLoaded: true,
    allowOrderTemplate: true,
    orderStatus: FlexPlanOrderStatus.New,
    orderNumber: undefined,
    invoiceNumber: "",
    invoiceTotal: toTwoDecimalPlaces(0),
    claimNotes: "",
    supportingDocuments: [],
    dateCreated: null,
    items: [newOrderItem(1)],
});

export { newOrderItem, newOrder };
