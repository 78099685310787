import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Button, Carousel, CarouselControl, CarouselItem, Modal, ModalBody, Spinner } from "reactstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import styles from "./styles.module.scss";

import { Image } from "@/Components/Image";
import { createEditorState } from "@/Components/RichTextEditor/Utils";
import { ProductQuantity } from "@/Components/ProductQuantity";
import { AccordionHeading } from "@/Components/AccordionHeading";
import { PageBreadcrumbs } from "@/Components/PageBreadcrumbs";
import { ServiceTilePriceSection } from "@/Components/ServiceTilePriceSection";
import { useAPI } from "@/Apis/useAPI";
import { ProductType } from "@/Apis/Products/ProductType";
import { OrderType } from "@/Apis/Basket";
import { useBasketContext } from "@/Context/BasketContext";
import { EnquiryFormModal } from "@/Modals/EnquiryFormModal";
import { ConfigureBasketModal } from "@/Modals/ConfigureBasketModal";
import globalActions from "@/Store/Global/actions";
import { ProductVariations } from "@/Utils/ProductVariations";
import { ProductVariant, ProductVariants } from "@/Utils/ProductVariants";
import { toCurrency } from "@/Utils/formatStringHelper";

export const ProductDetailsPage = () => {
    const { loading, get } = useAPI({ handle500WithToastMessage: true });
    const [productDetails, setProductDetails] = useState<ProductType>({} as ProductType);
    const [qty, setQty] = useState(1);
    const { addToBasket } = useBasketContext();
    const dispatch = useDispatch();
    const routeParams: { slugId: string } = useParams();
    const [showImagesExpanded, setImagesExpanded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [moreInformationExpanded, setMoreInformationExpanded] = useState(true);
    const [navigation, setNavigation] = useState<any[]>([]);
    const [isEnquiry, setIsEnquiry] = useState(false);
    const [canBeCustomised, setCanBeCustomised] = useState(false);
    const [isProduct, setIsProduct] = useState(true);
    const [showEnquiryFormModal, setShowEnquiryFormModal] = useState(false);
    const [showConfigureBasketModal, setShowConfigureBasketModal] = useState(false);

    const onConfigureClick = () => setShowConfigureBasketModal(!showConfigureBasketModal);
    const imagesLength = () => (productDetails.images ? productDetails.images.length : 0);
    const toggleImagesExpanded = () => { setImagesExpanded(!showImagesExpanded); };
    const next = () => { setActiveIndex(activeIndex === imagesLength() - 1 ? 0 : activeIndex + 1); };
    const previous = () => { setActiveIndex(activeIndex === 0 ? imagesLength() - 1 : activeIndex - 1); };
    const toggleMoreInformationExpanded = () => setMoreInformationExpanded(!moreInformationExpanded);

    const priceProps = {
        quantity: qty,
        onQtyChanged: setQty,
        minimumQuantity: productDetails.minimumQuantity,
        addQuantity: canBeCustomised ? false : productDetails.addQuantity,
        disableAddService: false,
        postageCharge: productDetails.postageCharge,
        paymentFrequency: productDetails.paymentFrequency,
        initialChargeWithVatIfApplicable: productDetails.initialChargeWithVatIfApplicable,
        isBeingUsedForPackages: false,
    };

    const vat = () => {
        if (!productDetails) {
            return 0;
        }
        return (productDetails.isVatRequired ? productDetails.initialChargeWithVatIfApplicable - productDetails.initialCharge : 0) * qty;
    };

    const total = () => {
        if (!productDetails) {
            return 0;
        }
        return (((productDetails.initialCharge * qty) + vat()) * ((100 - (productDetails.discountRate ?? 0)) / 100)) + productDetails.postageCharge;
    };

    const addToCart = () => {
        addToBasket({ productId: productDetails.id, quantity: qty, orderType: OrderType.Product, variations: null });
    };

    const addServiceToBasket = (product: ProductType, qty, variations) => addToBasket({
        productId: product.id,
        quantity: qty,
        variations,
    });

    const copyToClipboard = (text, message) => {
        navigator.clipboard.writeText(text);
        dispatch(globalActions.setToastMessage(true, message));
    };

    const initNavigation = (product: ProductType) => {
        setNavigation([
            {
                link: "/marketplace",
                name: "Marketplace",
            },
            {
                link: `/marketplace/supplier-directory/${product.supplierId}`,
                name: product.supplierName,
            },
            {
                link: `/marketplace?categorySelections=${product.categoryId}`,
                name: product.categoryName,
            },
            {
                link: "#",
                name: product.name,
            },
        ]);
    };

    useEffect(() => {
        get<ProductType>(`products/slug/${routeParams.slugId}`)
            .then((response) => {
                if (response.id) {
                    setProductDetails(response);
                    setIsProduct(ProductVariants.canBeAddedToBasket(response.productVariant));
                    setCanBeCustomised(ProductVariants.canBeCustomised(response.productVariant as keyof ProductVariant));
                    setIsEnquiry(response.productVariant === ProductVariants.enquiryFormProduct);
                    initNavigation(response);
                    setQty(response.minimumQuantity || 1);
                }
            });
    }, [routeParams.slugId]);

    const getMoreInfoText = (moreInfoObjString: string | undefined) => {
        const editorState = createEditorState(moreInfoObjString);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        return parse(markup);
    };

    const renderAddToBasketPopover = () => (
        <>
            <div className="text-right mt-3 mb-3">
                <button
                    type="button"
                    onClick={() => copyToClipboard(productDetails.id, "Product/service code has been copied")}
                    className={classnames(styles.optionsButton,
                        "text-dark border-0 bg-light justify-content-center rounded-circle align-items-center mr-2")}
                >
                    <i className="fa fa-copy" />
                </button>
                <button
                    type="button"
                    onClick={() => copyToClipboard(window.location.href, "Product/service link has been copied")}
                    className={classnames(styles.optionsButton,
                        "text-dark border-0 bg-light justify-content-center rounded-circle align-items-center")}
                >
                    <i className="fas fa-link" />
                </button>
            </div>
            <div className="border rounded pl-3 pr-3 font-weight-bold">
                <div className="border-bottom">
                    <div className="d-flex align-items-center pt-2">
                        <h5 className="font-weight-bold">Price</h5>
                    </div>
                </div>

                {isProduct && (
                    <div data-testid="product-info" className="pt-3 pb-3">
                        <div className="w-100">
                            <div className="border-bottom">
                                <div className="d-flex justify-content-between">
                                    <p>Product/Service</p>
                                    <p>£{toCurrency(productDetails.initialCharge)}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>{`${priceProps.minimumQuantity > 0 ? "Minimum Quantity" : "Quantity"}`}</p>
                                    <p data-testid="qty-display">{priceProps.minimumQuantity > 0 ? priceProps.minimumQuantity : qty}</p>
                                </div>
                                <div>
                                    <p className="text-muted text-uppercase font-size-sm">Additional Fees</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Postage Price</p>
                                    <p>£{toCurrency(productDetails.postageCharge)}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>VAT</p>
                                    <p>£{toCurrency(vat())}</p>
                                </div>
                                {!!productDetails.discountRate && (
                                    <div className="d-flex justify-content-between">
                                        <p>DISCOUNTS</p>
                                        <p>{toCurrency(productDetails.discountRate)}%</p>
                                    </div>)}
                            </div>
                            <div className={classnames("d-flex justify-content-between text-primary pt-3", styles.totalPrice)}>
                                <p className="text-uppercase">Total</p>
                                <p>£{toCurrency(total())}</p>
                            </div>
                            <div className={classnames(styles.frequency, "text-right text-muted text-uppercase font-size-sm")}>
                                {productDetails.paymentFrequency}
                            </div>
                        </div>
                    </div>
                )}

                {isEnquiry && (
                    <div data-testid="enquire-info" className="pt-3 pb-3">
                        <div className="w-100">
                            <div className={classnames("d-flex justify-content-between text-primary pt-3", styles.totalPrice)}>
                                <p className="text-uppercase">From</p>
                                <p>£{toCurrency(total())}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isProduct && (
                <div className="d-flex mt-3">
                    {!canBeCustomised ? (
                        <>
                            <ProductQuantity
                                classNames={classnames("justify-self-end mr-3", styles.productQuantity)}
                                value={qty}
                                onValueChanged={(val) => setQty(val || 1)}
                                minimumValue={priceProps.minimumQuantity || 1}
                            />
                            <Button
                                color="primary"
                                type="button"
                                onClick={addToCart}
                                data-testid="add-to-cart-btn"
                                className={classnames(styles.addToCartButton, "font-weight-bold m-auto mt-3")}
                                disabled={qty === 0}
                            >
                                <i className="fas fa-shopping-cart mr-1" />
                                Add to Cart
                            </Button>
                        </>)
                        : (
                            <Button
                                color="primary"
                                type="button"
                                onClick={onConfigureClick}
                                data-testid="add-to-cart-btn"
                                className={classnames(styles.addToCartButton, "w-100 font-weight-bold m-auto mt-3")}
                                disabled={qty === 0}
                            >
                                <i className="fas fa-pencil-ruler mr-1" />
                                Configure
                            </Button>)}
                </div>
            )}
            {isEnquiry && (
                <div className="d-flex mt-3">
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => setShowEnquiryFormModal(true)}
                        data-testid="enquire-button"
                        className="font-weight-bold w-100 m-auto mt-3"
                    >
                        <i className="fas fa-paper-plane mr-1" />
                        Enquire
                    </Button>
                </div>
            )}
        </>
    );

    if (loading) {
        return (
            <div className="mt-5 d-flex flex-grow-1 justify-content-center">
                <Spinner className="align-self-center" />
            </div>
        );
    }

    if (showImagesExpanded) {
        return (
            <Modal isOpen centered className={classnames(styles.fullScreen, "h-100 w-100 m-0 ")} contentClassName="h-100 rounded-0">
                <ModalBody className="pr-0">
                    <div className="d-flex align-items-center justify-content-between m-4">
                        <h3 className="font-weight-bold">{productDetails.name}</h3>
                        <div className="d-flex align-items-center font-weight-bold">
                            <h5>{activeIndex + 1} of {productDetails.images?.length}</h5>
                            <button type="button" onClick={toggleImagesExpanded} className="mr-4 btn">
                                <h3 className="font-weight-bold"><i className="fa fa-times" /></h3>
                            </button>
                        </div>
                    </div>

                    <div className="text-center container">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                            {productDetails.images?.map((item) => (
                                <CarouselItem className={classnames(styles.carouselitem)} key={item.name}>
                                    <Image testId={`image-expanded-${item.data}`} src={item.data} alt={item.name} />
                                </CarouselItem>
                            ))}
                            {imagesLength() > 1 && (
                                <>
                                    <CarouselControl className={styles.previousCarousel} direction="prev" directionText="Previous" onClickHandler={previous} />
                                    <CarouselControl className={styles.nextCarousel} direction="next" directionText="Next" onClickHandler={next} />
                                </>)}
                        </Carousel>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    return (
        <div className="bg-white">
            {productDetails && (
                <div data-testid="container" className="container pb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <PageBreadcrumbs navigation={navigation} />
                        </div>
                        <div className="col-md-9">
                            <div className="d-flex flex-column">
                                <div className="mt-4  d-flex flex-column flex-md-row justify-content-between">
                                    <div>
                                        <h3 className="font-weight-bold">{productDetails.name}</h3>
                                        <div className={classnames(styles.categoryName, "mt-1 mb-2 text-muted")}>
                                            <Link to={`/marketplace/supplier-directory/${productDetails.supplierId}`}>{productDetails.supplierName}</Link>
                                            <span> • </span>
                                            <Link to={`/marketplace?categorySelections=${productDetails.categoryId}`}>{productDetails.categoryName}</Link>
                                        </div>
                                        <p>
                                            {productDetails.description}
                                        </p>
                                    </div>
                                </div>

                                <div className="justify-content-between">
                                    <div className="mr-lg-4 position-relative">
                                        {imagesLength() > 0 && (
                                            <>
                                                <Carousel className={classnames(styles.imageContainer, "mb-4")} activeIndex={activeIndex} next={next} previous={previous}>
                                                    {productDetails.images?.map(item => (
                                                        <CarouselItem className="text-center" key={item.name}>
                                                            <Image
                                                                src={item.data}
                                                                alt={item.name}
                                                                testId={`image-${item.data}`}
                                                                className={classnames(styles.carouselImage, "w-75 w-lg-auto my-auto oo d-block w-100")}
                                                            />
                                                        </CarouselItem>
                                                    ))}
                                                    {imagesLength() > 1 && (
                                                        <>
                                                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                                                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                                                        </>)}
                                                </Carousel>

                                                <div className={styles.floatRightButton}>
                                                    <Button
                                                        data-testid="expandImageButton"
                                                        onClick={toggleImagesExpanded}
                                                        className={classnames(styles.expand, "text-white border-0")}
                                                    >
                                                        <span>{activeIndex + 1} of {imagesLength()}</span>
                                                        <i className="fa fa-expand-arrows-alt ml-2" />
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            {renderAddToBasketPopover()}
                        </div>
                        <div className={classnames(styles.moreInformationToggle, "mt-3 col-md-12")}>
                            <AccordionHeading
                                heading="MORE INFORMATION"
                                hideHeading={false}
                                open={moreInformationExpanded}
                                onToggle={toggleMoreInformationExpanded}
                                className="border-0 px-3 pt-2 pb-2 m-0 d-flex"
                            >
                                <div className={classnames(styles.editorNoBorder, "col-md-12 mt-3")}>
                                    {productDetails.moreInformation
                                        && getMoreInfoText(productDetails.moreInformation)}
                                </div>
                            </AccordionHeading>
                        </div>
                        {showEnquiryFormModal && (
                            <div data-testid="enquiry-form-modal">
                                <EnquiryFormModal
                                    onClose={() => setShowEnquiryFormModal(false)}
                                    enquiryFormId={productDetails.id}
                                    name={productDetails.name}
                                    description={productDetails.description}
                                    image={productDetails.image}
                                    startingPrice={productDetails.startingPrice}
                                />
                            </div>
                        )}
                    </div>
                </div>)}
            {showConfigureBasketModal && <ConfigureBasketModal
                id={productDetails.id}
                minimumQuantity={productDetails.minimumQuantity}
                variations={ProductVariations.getVariationsFromServiceOrDefault(productDetails)}
                name={productDetails.name}
                onAddToBasket={(qty, variations) => addServiceToBasket(productDetails, qty, variations)}
                priceElement={<ServiceTilePriceSection
                    {...priceProps}
                    containerClassNames="w-100 bg-white d-flex h-20px"
                    priceFontClassNames="font-weight-bold h6"
                />}
                toggle={onConfigureClick}
                initialChargeWithVatIfApplicable={productDetails.initialChargeWithVatIfApplicable}
                postageCharge={productDetails.postageCharge}
                productVariant={productDetails.productVariant as keyof ProductVariant}
            />}
        </div>
    );
};
