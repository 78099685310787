import { ProductType } from "../Products/ProductType";

import { CategoryType } from "@/Apis/Suppliers/CategoryType";

export interface MiniSiteType {
    id?: string;
    name?: string;
    tagline?: string;
    description?: string;
    logo?: string;
    showName: boolean;
    showLogo: boolean;
    showTagline?: boolean;
    nameAndTaglineColour?: string;
    headerColour?: string;
    headerImage?: string;
    primarySiteColour?: string;
    rating?: number;
    services: Array<ProductType>;
    categoriesToDisplay: Array<string>;
    metaData?: MetaDataType;
    template: MinisiteTemplateForm;
    featuredLatestReview?: FeaturedLatestReview;
    userId?: string;
}

export interface MinisiteTemplateForm {
    id?: string,
    showRatings: boolean,
    showContactUs: boolean,
    selectedTemplate?: number,
    sections: MinisiteTemplateSectionForm[]
}

export interface MinisiteTemplateSectionForm {
    id?: string,
    title: string,
    titleColour: string,
    text: string,
    desktopImage: string,
    mobileImage: string,
    sectionNumber: number,
}

const defaultMinisiteTemplateSection = (sectionNumber: number): MinisiteTemplateSectionForm => ({
    title: "",
    titleColour: "#000000",
    text: "",
    desktopImage: "",
    mobileImage: "",
    sectionNumber,
});

export const defaultMinisiteTemplate: MinisiteTemplateForm = {
    sections: [defaultMinisiteTemplateSection(1), defaultMinisiteTemplateSection(2)],
    showRatings: false,
    showContactUs: false,
    selectedTemplate: 1,
};

export interface MetaDataType {
    categories: Array<CategoryType>;
    subCategories: Array<[string, CategoryType]>;
    paymentFrequencies: Array<string>;
    fields: Array<FieldType>;
}

export interface FieldType {
    id: string;
    displayText: string;
    description?: string;
    ordinal: number;
}

export interface ValidationErrors {
    services?: string;
    tagline?: string;
    logo?: string;
    name?: string;
    description?: string;
    displayOptions?: string,
}

export interface FeaturedLatestReview {
    date: Date;
    content: string;
    rating: number;
    isFeatured: boolean;
}
