import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import classnames from "classnames";

import styles from "./styles.module.scss";

import Checkbox from "@/Components/Checkbox";
import { ColumnType } from "@/Components/ConfigureProductRow/ColumnTypes";
import { AddCustomColumnModal } from "@/Modals/ServiceModal/Configure/AddCustomColumnModal";
import { ProductVariant } from "@/Utils/ProductVariants";

interface Props {
    onAddColumns(columns: Array<ColumnType>): void;
    toggle(): void;
    columns: Array<ColumnType>;
    setVariationState(dispatch);
    productVariant: keyof ProductVariant;
}

const CustomiseModal = ({ onAddColumns, toggle, columns, setVariationState, productVariant }: Props) => {
    const [columnsState, setColumnsState] = useState(columns);
    const [showCustomColumnModal, setshowCustomColumnModal] = useState(false);
    const findColumn = (updatedColumns: Array<ColumnType>, columnId: string) => updatedColumns.find(x => x.variationId === columnId) as ColumnType;

    const toggleShowColumnModal = () => setshowCustomColumnModal(!showCustomColumnModal);

    const onSelectColumn = (columnId: string) => value => {
        const updatedSelectedColumns = [...columnsState];
        findColumn(updatedSelectedColumns, columnId).checked = value;
        setColumnsState(updatedSelectedColumns);
    };

    const onSave = () => {
        onAddColumns(columnsState);
        toggle();
    };

    const onAddNewCustomColumn = (updatedColumns: ColumnType[]) => {
        setColumnsState([...updatedColumns]);
        setVariationState(currentState => ({ ...currentState, columns: [...updatedColumns] }));
    };

    return (
        <>
            <Modal isOpen centered size="lm">
                <div className={styles.modal}>
                    <ModalHeader toggle={toggle} className={styles.modalHeader}>
                        <span className="font-weight-bold">Add Columns</span>
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            You can choose from the list of columns below which ones will be appropriate for your
                            product / service.
                            Or click “Customise” to configure your own column.
                        </p>
                        <div className={classnames(styles.popularColumns, "border rounded")}>
                            <div className="font-weight-bold">Popular Columns</div>
                            <div className={classnames("overflow-y-auto", styles.content)}>
                                {columnsState.map((x) => (
                                    <div className="d-flex justify-content-between mt-3" key={x.variationId}>
                                        <div>
                                            <label htmlFor={x.variationId}>{x.name}</label>
                                        </div>
                                        <div>
                                            <Checkbox onChange={onSelectColumn(x.variationId)} id={x.variationId} checked={x.checked} disabled={x.sticky} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="d-flex mt-5">
                            <Button className="w-50 mr-5 font-weight-bold" color="primary" outline data-testid="customise" onClick={toggleShowColumnModal}>
                                Customise
                            </Button>
                            <Button onClick={onSave} className="w-50 mr-auto font-weight-bold" color="primary" data-testid="save">
                                Save Column Changes
                            </Button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>

            {showCustomColumnModal && (<AddCustomColumnModal
                toggle={toggleShowColumnModal}
                columns={columns}
                onAddNewCustomColumn={onAddNewCustomColumn}
                productVariant={productVariant}
            />)}
        </>
    );
};

export default CustomiseModal;
