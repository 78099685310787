import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";

import { StartConversationRequest, MessagingUrls } from "@/Apis/Messaging";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { isEmptyOrSpaces } from "@/Utils/stringHelper";

interface Props {
    userId?: string
}

const ContactUs = ({
    userId,
}: Props) => {
    const loggedIn = useSelector<any>(state => state.user.isLoggedIn);
    const { post, loading } = useAPI({ handle500WithRedirect: true });
    const { setPopupErrorMessage, setSuccessMessage } = useToastMessageContext();

    const [body, setBody] = useState("");

    const onSend = () => {
        if (userId) {
            const data = {
                conversationId: uuid(),
                recipients: [userId],
                subject: "Customer Enquiry",
                body,
                attachments: [],
                isUrgent: false,
                relatedOrderId: null,
            } as StartConversationRequest;

            post(MessagingUrls.startConversation, data)
                .then(() => {
                    setSuccessMessage("Message successfully sent.", true);
                    setBody("");
                })
                .catch(() => {
                    setPopupErrorMessage("Unfortunately an error occurred.", true);
                });
        }
    };

    return (
        <div className="tw-w-full">
            <div className="tw-text-brand-primary tw-font-bold tw-text-2xl">Contact Us</div>
            {!loggedIn && <span className="tw-text-xs tw-text-gray-500 tw-italic">Please login before sending an inquiry.</span>}
            <div className="tw-relative">
                <Input
                    type="textarea"
                    placeholder="Message"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    rows={4}
                    name="message"
                    className="tw-my-2.5"
                    disabled={loading}
                />
                <Button
                    color="primary"
                    onClick={onSend}
                    className="tw-absolute tw-right-4 tw-bottom-4"
                    disabled={loading || !loggedIn || isEmptyOrSpaces(body)}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export { ContactUs };
