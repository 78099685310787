import React from "react";
import { Alert } from "reactstrap";

import { CompanyFormationStatus } from "@/Apis/Orders/CompanyFormation";

interface Props {
    companyFormationStatus: CompanyFormationStatus,
    companyFormationRejectReasons: CompanyFormationRejectReasons[],
}

interface CompanyFormationRejectReasons {
    rejectCode: string,
    description: string,
}

const CompanyFormationDetails = ({ companyFormationStatus, companyFormationRejectReasons }: Props) => {
    const getAlertColour = (status: CompanyFormationStatus): string => {
        switch (status) {
            case CompanyFormationStatus.Unknown:
                return "dark";
            case CompanyFormationStatus.Accepted:
                return "success";
            case CompanyFormationStatus.Rejected:
                return "danger";
            case CompanyFormationStatus.Pending:
                return "primary";
            case CompanyFormationStatus.Failure:
                return "danger";
            default: return "light";
        }
    };

    return (
        <div>
            {companyFormationStatus && (
                <Alert color={getAlertColour(companyFormationStatus)} className="tw-font-semibold">
                    Submission status: {companyFormationStatus}
                    {companyFormationStatus === CompanyFormationStatus.Rejected && (
                        <ul className="tw-flex tw-flex-col tw-space-y-3 tw-mt-3 tw-mb-0">
                            {companyFormationRejectReasons.map(reason => (
                                <li>
                                    <div className="tw-text-xs">
                                        Company House Reject Code: {reason.rejectCode}
                                    </div>
                                    <div className="tw-text-xs">
                                        Description: {reason.description}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </Alert>
            )}
        </div>
    );
};

export { CompanyFormationDetails };
