import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Alert, Label } from "reactstrap";

import { useReceiptsContext } from "@/Context/ReceiptsContext";
import { ToolTip } from "@/Components/ToolTip";
import { useAPI } from "@/Apis/useAPI";
import { PreviousPackageSelectItem } from "@/Apis/Receipts/Create/PreviousReceiptsDropdown";
import { Urls } from "@/Apis/urls";
import { CreateReceiptFormFields, defaultReceiptForm, defaultReceiptLines } from "@/Apis/Receipts/Create";
import { PreviousPackageResponse } from "@/Apis/Receipts";
import { toCurrencyWithCommas, toPaddedNumber } from "@/Utils/formatStringHelper";

interface Props {
    className?: string,
    formValues: CreateReceiptFormFields,
    setFormValues: React.Dispatch<React.SetStateAction<CreateReceiptFormFields>>,
    toggleLoading: () => void,
    disabled?: boolean,
}

const PreviousReceiptPackagesDropdown = ({ className,
    formValues,
    setFormValues,
    toggleLoading,
    disabled }: Props) => {
    const [selectItems, setSelectItems] = useState<PreviousPackageSelectItem[]>();
    const { get } = useAPI({ handle500WithToastMessage: true });
    const [error, setError] = useState<string>();
    const { pageType } = useReceiptsContext();

    const onPreviousReceiptSelect = (selected: PreviousPackageSelectItem) => {
        if (!selected) { // Null entry, the selection has been cleared, return to default lines
            // Get any custom lines the user added
            const addedLines = formValues.lines.filter(x => !x.isPackageItem && x.id !== defaultReceiptLines[0].id);

            // Remove the package line items
            setFormValues({
                ...formValues,
                lines: addedLines.length > 0 ? addedLines : defaultReceiptLines.map(x => ({ ...x, receiptType: pageType })),
                previousPackageSelected: defaultReceiptForm.previousPackageSelected,
            });
            return;
        }

        toggleLoading();
        get<PreviousPackageResponse>(Urls.receipts.packages.single(selected.value))
            .then(response => {
                if (response.lineItems && response.lineItems.length > 0) {
                    setFormValues({
                        ...formValues,
                        previousPackageSelected: selected,
                        savePackage: false,
                        lines: [...response.lineItems.map(x => ({ ...x, isSaved: false, receiptType: pageType, isPackageItem: true }))],
                    });
                }
            })
            .catch(error => setError(error))
            .finally(() => toggleLoading());
    };

    useEffect(() => {
        get<PreviousPackageSelectItem[]>(Urls.receipts.packages.previous)
            .then(response => {
                const headerOption: PreviousPackageSelectItem = {
                    isDisabled: true,
                    value: "",
                    total: "Total",
                    referenceNumber: "Reference No.",
                    label: "Package Name",
                    labelName: "Package Name",
                };
                setSelectItems([headerOption, ...response.map(x => ({ ...x, labelName: x.label, label: `${x.label} ${x.referenceNumber}` }))]);
            })
            .catch(error => setError(error));
    }, []);

    const formatOptionsLabel = (selectItem: PreviousPackageSelectItem) => (
        <div className={`tw-grid tw-grid-cols-5 tw-gap-4 ${selectItem.isDisabled && "tw-text-black tw-font-semibold"}`}>
            <div className="tw-col-span-1">{toPaddedNumber(selectItem.referenceNumber)}</div>
            <div className="tw-col-span-3">{selectItem.labelName}</div>
            <div className="tw-col-span-1">{toCurrencyWithCommas(selectItem.total)}</div>
        </div>
    );

    return (
        <div className={className}>
            <Label className="tw-flex tw-items-center tw-space-x-1">
                <span>Previous Packages</span>
                <span className="tw-text-gray-400">[optional]</span>
                <ToolTip
                    description="Load previously saved quotations and invoices."
                    id="load-previous-receipts-tooltip"
                >
                    <i className="fas fa-info-circle tw-text-gray-400" data-testid="info-icon" />
                </ToolTip>
            </Label>
            <Select
                placeholder="Search for a package"
                isClearable
                onChange={onPreviousReceiptSelect}
                options={selectItems}
                formatOptionLabel={formatOptionsLabel}
                value={formValues.previousPackageSelected}
                isOptionDisabled={option => option.isDisabled}
                aria-label="previous-packages-select"
                isDisabled={disabled}
            />
            {error && <Alert color="danger">{error}</Alert>}
        </div>
    );
};

export default PreviousReceiptPackagesDropdown;
