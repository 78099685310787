import React from "react";
import Select from "react-select";

import sortingUtil from "@/Utils/sortingUtil";

interface Props {
    appliedTo: Array<string>;
    notAppliedMessage: string;
}

const AppliedTo = ({ appliedTo, notAppliedMessage }: Props) => {
    const sortedAppliedTo = appliedTo.map(x => ({
        label: x,
        value: x,
    })).sort((x, y) => sortingUtil.sort(x, y, "label", true));

    const theme = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            borderColor: "transparent",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: "none",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            width: "0px",
        }),
    };

    return (
        <Select
            defaultValue={sortedAppliedTo}
            placeholder={<div>{notAppliedMessage}</div>}
            isMulti
            isClearable={false}
            isDisabled
            styles={theme}
        />);
};

export { AppliedTo };
