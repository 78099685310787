import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Col, Modal, ModalBody, Row } from "reactstrap";

import styles from "./styles.module.scss";

import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { GetSubscriptionPaymentsResponse, OrdersUrls, SubscriptionPayment } from "@/Apis/Orders";
import formatDateHelper from "@/Utils/formatDateHelper";
import { pascalCaseToTitleCase } from "@/Utils/formatStringHelper";
import sortingUtil from "@/Utils/sortingUtil";
import { DownloadSubscriptionInvoice } from "@/Modals/PaymentHistoryModal/DownloadSubscriptionInvoice";

interface Props {
    orderId: string;
    onClose(): void;
    isCustomer?: boolean;
}

interface SortBy {
    by: string,
    ascending: boolean,
}

const PaymentHistoryModal = ({ orderId, onClose, isCustomer = false }: Props) => {
    const { loading, get } = useAPI({ handle500WithRedirect: true });
    const [subscriptionPayments, setSubscriptionPayments] = useState([] as SubscriptionPayment[]);
    const [sortBy, setSortBy] = useState<SortBy>({ by: "paymentDate", ascending: false });

    const formatSubscriptionPaymentAmount = (paymentStatus: string, subscriptionPaymentAmount: string) => {
        if (!subscriptionPaymentAmount) {
            return "-";
        }

        if (paymentStatus === "InvoicePaymentFailed" || paymentStatus === "InvoicePaymentActionRequired") {
            return (<span className="text-danger font-weight-bold">{pascalCaseToTitleCase(paymentStatus.replace("Invoice", ""))}</span>);
        }

        return paymentStatus === "InvoicePaid"
            ? subscriptionPaymentAmount
            : pascalCaseToTitleCase(paymentStatus.replace("Invoice", ""));
    };

    const orderBy = key => () => {
        setSortBy((prevState) => {
            let newSortBy: SortBy = { by: key, ascending: false };
            if (prevState.by === key) {
                newSortBy = { by: key, ascending: !prevState.ascending };
            }
            setSubscriptionPayments(subscriptionPayments.sort((x, y) => sortingUtil.sort(x, y, newSortBy.by, newSortBy.ascending)));
            return newSortBy;
        });
    };

    useEffect(() => {
        get<GetSubscriptionPaymentsResponse>(OrdersUrls.getSubscriptionPayments(orderId, isCustomer))
            .then(response => {
                setSubscriptionPayments(response.subscriptionPayments.sort((x, y) => sortingUtil.sort(x, y, sortBy.by, sortBy.ascending)));
                orderBy("paymentDate");
            });
    }, [orderId]);

    return (
        <Modal isOpen centered size="lg" data-testid="payment-history-modal">
            <ModalBody className={classnames(styles.modalBody, "p-md-5")}>
                <h4 className="font-weight-bold mb-4">Payment History</h4>
                <button
                    type="button"
                    onClick={onClose}
                    className={classnames(styles.close, "position-absolute")}
                >
                    <i className="fa fa-times" />
                </button>

                {loading ? <div className="mt-5 d-flex flex-grow-1 justify-content-center"><Spinner className="align-self-center" /></div> : (
                    <div className="mx-2 mx-md-0">
                        <Row className="d-none d-md-flex border-bottom align-items-center">
                            <Col md="3">
                                <button type="button" className="bg-transparent p-0 border-0" onClick={orderBy("paymentDate")}>Payment Date <i className="fa fa-sort" /></button>
                            </Col>
                            <Col md="2">
                                <button type="button" className="bg-transparent p-0 border-0" onClick={orderBy("dueDate")}>Due Date <i className="fa fa-sort" /></button>
                            </Col>
                            <Col md="2">
                                <button type="button" className="bg-transparent p-0 border-0" onClick={orderBy("amountDue")}>Amount Due <i className="fa fa-sort" /></button>
                            </Col>
                            <Col md="2">
                                <button type="button" className="bg-transparent p-0 border-0" onClick={orderBy("payment")}>Payment <i className="fa fa-sort" /></button>
                            </Col>
                            <Col md="3">
                                Invoice
                            </Col>
                        </Row>
                        {subscriptionPayments.map(x => (
                            <Row className={classnames(styles.row, "py-2 mb-3 mb-md-0 border align-items-center")}>
                                <Col md="3">
                                    <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Payment Date:</span>
                                    <span className={classnames(styles.orderDateTime, "d-md-block mr-1")}>
                                        {x.paymentDate ? formatDateHelper.format(x.paymentDate, "DD/MM/YYYY") : "-"}
                                    </span>
                                </Col>
                                <Col md="2">
                                    <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Due Date:</span>
                                    <span className={classnames(styles.orderDateTime, "d-md-block mr-1")}>
                                        {x.paymentDate ? formatDateHelper.format(x.dueDate, "DD/MM/YYYY") : "-"}
                                    </span>
                                </Col>
                                <Col md="2">
                                    <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Amount Due:</span>
                                    <span className={classnames(styles.orderDateTime, "d-md-block mr-1")}>
                                        {x.amountDue}
                                    </span>
                                </Col>
                                <Col md="2">
                                    <span className={classnames(styles.mobileLabel, "mr-1 d-md-none")}>Payment:</span>
                                    <span className={classnames(styles.orderDateTime, "d-md-block mr-1")}>
                                        {formatSubscriptionPaymentAmount(x.paymentStatus, x.payment)}
                                    </span>
                                </Col>
                                <Col md="3" className="d-flex justify-content-end">
                                    {x.paymentDate && (<DownloadSubscriptionInvoice
                                        linkText={`${formatDateHelper.format(x.paymentDate, "YYYYMMDD")}.pdf`}
                                        invoiceDetails={x.invoice}
                                    />)}
                                </Col>
                            </Row>
                        ))}
                    </div>)}
            </ModalBody>
        </Modal>
    );
};

export { PaymentHistoryModal };
