import React from "react";
import PropTypes from "prop-types";
import { Col, Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";

export class ErrorPage extends React.PureComponent {
    componentDidMount() {
        document.title = "BSC - Error";
    }

    render() {
        return (
            <Col xs="10" className="mx-auto">
                <Alert color="danger" className="m-3 mt-md-5 pt-3">
                    <p>Unfortunately an error occurred.</p>
                    <p>If this problem persists, please contact us.</p>
                </Alert>
                <div className="m-3 my-md-5">
                    <Button className="mr-2" color="primary" tag={Link} to="/">Home</Button>
                    {this.props.location.state && this.props.location.state.referrer && <Button color="primary" tag="a" href={this.props.location.state.referrer}>Go Back</Button>}
                </div>
            </Col>
        );
    }
}

ErrorPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    }).isRequired,
};

const mapStateToProps = state => ({
    error: state.global.error,
});

const mapDispatchToProps = dispatch => ({
    pushTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
