import React, { useEffect } from "react";
import { components } from "react-select";
import { Link } from "react-router-dom";

import { FlexPlanSupplierDropdownType } from "../Types";

import { FlexPlanUrls } from "@/FlexPlan/Utils/url";
import { useAPI } from "@/Apis/useAPI";
import { ReactSelect } from "@/Components/ReactSelect";
import { useFlexPlanOrderContext } from "@/FlexPlan/Pages/Orders/OrderDetails/Context/OrderDetailsContext";
import { isSubmittedOrder } from "@/FlexPlan/Pages/Orders/OrderDetails/Utils";
import { FormError } from "@/FlexPlan/Components/FormError";

const SupplierDropdown = () => {
    const { get, loading } = useAPI({ handle500WithToastMessage: true });

    const {
        formState,
        onReactSelectChange,
        disableButtons,
        invalidFields,
        setSupplierList,
        supplierList,
        newOrder,
    } = useFlexPlanOrderContext();

    useEffect(() => {
        get<FlexPlanSupplierDropdownType[]>(FlexPlanUrls.suppliers.base)
            .then(response => {
                setSupplierList(response.map(x => ({
                    ...x,
                    label: x.abn ? `${x.businessName} - ${x.abn}` : x.businessName,
                    value: x.id!,
                })));
            });
    }, []);

    const NoOptionsMessage = props => (
        <components.NoOptionsMessage {...props}>
            <Link to="/supplier/sign-up" className="tw-text-black"><i className="fa fa-plus" />&nbsp; Create Supplier Record</Link>
            <hr />
            <span>No records found.</span>
        </components.NoOptionsMessage>
    );

    return (
        <>
            <ReactSelect
                value={supplierList?.find(x => x.id === formState.supplierId)}
                options={supplierList}
                components={{ NoOptionsMessage }}
                isSearchable
                className="tw-w-56 tw-text-black"
                onChange={onReactSelectChange("supplierId")}
                isDisabled={loading || !supplierList || disableButtons || isSubmittedOrder(formState) || !newOrder}
            />
            {invalidFields.supplierId && (
                <FormError>
                    {invalidFields.supplierId}
                </FormError>
            )}
        </>);
};

export { SupplierDropdown };
