import { combineEpics } from "redux-observable";

import UserEpics from "@/Store/User/epics";
import NotificationEpics from "@/Store/Notifications/epics";

const rootEpic = combineEpics(
    ...Object.values(UserEpics),
    ...Object.values(NotificationEpics),
);

export default rootEpic;
