import React from "react";
import { Modal, Button, ModalBody, ModalHeader } from "reactstrap";

import CloseButton from "@/Components/CloseButton";
import Spinner from "@/Components/Spinner";

interface Props {
    isOpen: boolean,
    size?: "lg" | "md" | "sm",
    title?: string,
    text: string | React.ReactNode,
    onConfirm: () => void,
    onClose: () => void,
    buttonConfirmText?: string,
    buttonCancelText?: string,
    loading?: boolean,
}

const ConfirmCancelModal = ({
    isOpen,
    size = "lg",
    title = "Confirmation Required",
    text,
    onConfirm,
    onClose,
    buttonConfirmText = "Proceed",
    buttonCancelText = "Cancel",
    loading = false,
}: Props) => (
    <Modal
        isOpen={isOpen}
        centered
        size={size}
        className="tw-p-10"
    >
        <ModalHeader className="tw-space-x-1">
            <i className="fa fa-exclamation-triangle tw-text-amber-400" />&nbsp;{title}
            { loading ? <></> : <CloseButton onClick={onClose} /> }
        </ModalHeader>
        <ModalBody className="tw-flex tw-flex-col tw-relative tw-p-8">
            {loading ? (<Spinner className="tw-mx-auto tw-my-4" />)
                : (
                    <>
                        <div className="tw-py-3 tw-text-base" data-testid="modal-text">{text}</div>
                        <div className="tw-flex tw-space-x-3 tw-justify-center tw-mt-2">
                            <Button color="primary" className="tw-text-base" onClick={onConfirm} data-testid="modal-submit-button">{buttonConfirmText}</Button>
                            <Button color="primary" className="tw-text-base" outline onClick={onClose}>{buttonCancelText}</Button>
                        </div>
                    </>
                )}
        </ModalBody>
    </Modal>
);

export { ConfirmCancelModal };
