import React, { useState } from "react";

import { getSupplierDirectory } from "@/Utils/localStorage";
import { MinisiteContent } from "@/Components/MinisiteContent";
import { getActiveCategory, getServicesToDisplay } from "@/Pages/Marketplace/miniSiteHelper";

const SitePreview = () => {
    const supplierDirectory = getSupplierDirectory();
    if (!supplierDirectory || !supplierDirectory?.template) {
        return <>Please select template!</>;
    }

    const supplierMiniSite = { ...supplierDirectory,
        services: supplierDirectory.services?.filter(x => !x.code && x.categoryName !== "Packages"),
        categoriesToDisplay: supplierDirectory.categoriesToDisplay?.filter(x => x !== "Packages") };

    const [activeCategoryTab, setActiveCategoryTab] = useState("");
    const changeCategoryTab = (tab) => {
        if (activeCategoryTab !== tab) {
            setActiveCategoryTab(tab);
        }
    };

    return (
        <div
            className="tw-flex justify-content-start justify-content-lg-center align-items-center position-relative"
        >
            <MinisiteContent
                changeCategoryTab={changeCategoryTab}
                activeCategoryTab={getActiveCategory({ activeCategoryTab }, supplierMiniSite.categoriesToDisplay)}
                servicesToDisplay={getServicesToDisplay(supplierMiniSite.services, getActiveCategory({ activeCategoryTab }, supplierMiniSite.categoriesToDisplay))}
                minisiteType={supplierMiniSite}
                isPreview
            />
        </div>
    );
};

export { SitePreview };
