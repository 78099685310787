export enum NoteType {
    participant = "Participant"
}

export interface Note {
    id: string,
    description: string,
    createdByUserFirstName: string,
    createdByUserLastName: string,
    dateCreated: string,
    participantFirstName: string,
    participantLastName: string,
    noteType: NoteType,
}
