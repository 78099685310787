import React from "react";

import { MiniSiteType } from "@/Apis/Suppliers/MiniSiteType";
import { Template1 } from "@/Components/MinisiteContent/SiteTemplate/Template1";
import { Template2 } from "@/Components/MinisiteContent/SiteTemplate/Template2";
import { Template3 } from "@/Components/MinisiteContent/SiteTemplate/Template3";
import { Template4 } from "@/Components/MinisiteContent/SiteTemplate/Template4";

interface Props {
    siteType: MiniSiteType,
}

const SiteTemplate = ({
    siteType,
}: Props) => {
    if (siteType?.template?.selectedTemplate === 1) {
        return <Template1 siteType={siteType} />;
    }

    if (siteType?.template?.selectedTemplate === 2) {
        return <Template2 siteType={siteType} />;
    }

    if (siteType?.template?.selectedTemplate === 3) {
        return <Template3 siteType={siteType} />;
    }

    if (siteType?.template?.selectedTemplate === 4) {
        return <Template4 siteType={siteType} />;
    }

    return <></>;
};

export { SiteTemplate };
