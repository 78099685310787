import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
    onClick: () => void;
}

const Prev = ({ onClick }: Props) => {
    const onKeyPress = (e) => {
        if (e.key === "ArrowLeft") {
            onClick();
        }
    };

    return (
        <a className="carousel-control-prev !tw-w-[5%]" onClick={onClick} onKeyDown={onKeyPress} role="button" tabIndex={0}>
            <i className={classnames(styles.control, styles.controlPrev, "fas fa-chevron-left text-primary")} />
        </a>
    );
};

const Next = ({ onClick }: Props) => {
    const onKeyPress = (e) => {
        if (e.key === "ArrowRight") {
            onClick();
        }
    };

    return (
        <a className="carousel-control-next !tw-w-[5%]" onClick={onClick} onKeyDown={onKeyPress} role="button" tabIndex={0}>
            <i className={classnames(styles.control, styles.controlNext, "fas fa-chevron-right text-primary")} />
        </a>
    );
};

export { Prev, Next };
