import { ComponentLookup, Variations, VariationType } from "@/Components/ConfigureProductRow/ColumnTypes";

interface ComponentMeta {
    width: string;
    showHeader: boolean;
}

class ProductVariations {
    static getVariationsFromServiceOrDefault(service: any): Variations {
        return service.variations ? this.getVariationsOrDefault(service.variations) : this.defaultVariations;
    }

    static getVariationsOrDefault(variations: any): Variations {
        return variations?.variationData ? variations.variationData : this.defaultVariations;
    }

    static getDistinctRows(variationItems: VariationType[]): string[] {
        return variationItems.map(x => x.rowId).filter((value, index, self) => self.indexOf(value) === index);
    }

    static getComponentMeta(componentLookup: ComponentLookup): ComponentMeta {
        switch (componentLookup) {
            case "Textbox":
                return { width: "160px", showHeader: true };
            case "AddressLookUp":
                return { width: "260px", showHeader: true };
            case "ConfigurableUpload":
                return { width: "60px", showHeader: false };
            case "ConfigurableSelect":
                return { width: "280px", showHeader: true };
            default:
                return { width: "165px", showHeader: true };
        }
    }

    private static get defaultVariations(): Variations {
        return { allowMultipleSelection: true, variationItems: [] };
    }

    static supplierConfigured(variation: VariationType): boolean {
        return variation.name !== "Quantity";
    }
}

export { ProductVariations };
