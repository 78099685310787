import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Route, Switch } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useHistory, useLocation, useRouteMatch } from "react-router";

import { getActiveCategory, getServicesToDisplay } from "@/Pages/Marketplace/miniSiteHelper";
import Suppliers from "@/Pages/Marketplace/SupplierDirectoryPage/Suppliers";
import AtoZFilter from "@/Components/AtoZFilter";
import Spinner from "@/Components/Spinner";
import SupplierTile from "@/Components/SupplierTile";
import { useBasketContext } from "@/Context/BasketContext";
import { useSuppliersContext } from "@/Context/SuppliersContext";
import sortingUtil from "@/Utils/sortingUtil";
import { MinisiteContent } from "@/Components/MinisiteContent";

const SupplierDirectoryPage = () => {
    document.title = "BSC - Supplier Directory";
    const PAGE_URL = "/marketplace/supplier-directory";
    const marketPlaceUrl = "/marketplace";
    const { addToBasket, basket } = useBasketContext();
    const { supplier, loading, loadSupplierDashboard, clearSuppliers, loadMiniSite } = useSuppliersContext();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<any>();
    const query = queryString.parse(location.search, { arrayFormat: "comma" });
    const parsedFilters = query.filters || [];
    const [localFilters, setLocalFilters] = useState(Array.isArray(parsedFilters) ? [...parsedFilters] : [parsedFilters]);
    const [letterFilters, setLetterFilters] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState(query.searchTerm);
    const [activeCategoryTab, setActiveCategoryTab] = useState("");
    const suppliers = supplier.suppliers.sort((x, y) => sortingUtil.sort(x, y, "name", true));
    const filters = supplier.filters.sort((x, y) => sortingUtil.sort(x, y, "name", true));
    const itemIdsInCart = basket.groups.flatMap(_ => _.items).map(x => x.serviceId.toUpperCase()) || [];
    const categoriesToDisplay = supplier.supplierMiniSite.categoriesToDisplay;
    const services = supplier.supplierMiniSite.services;
    const supplierMiniSite = supplier.supplierMiniSite;

    useEffect(() => {
        loadSupplierDashboard({
            filters: localFilters,
            searchTerm,
            supplierId: match.params.id,
        } as any);
        return () => {
            clearSuppliers();
        };
    }, [searchTerm, localFilters]);

    const onMarketPlaceClick = () => {
        history.push(`${marketPlaceUrl}`);
        setActiveCategoryTab("");
    };

    const onSupplierClick = id => {
        loadMiniSite(id);
        history.push(`${PAGE_URL}/${id}${location.search}`);
        setActiveCategoryTab("");
    };

    const changeCategoryTab = (tab) => {
        if (activeCategoryTab !== tab) {
            setActiveCategoryTab(tab);
        }
    };

    const onSearch = (searchTermValue) => {
        setSearchTerm(searchTermValue);
        setLocalFilters([]);
        history.push(PAGE_URL);
    };

    const searchDebounce = AwesomeDebouncePromise(onSearch, 400);

    const onCategoriesFiltersChange = (id) => {
        if (id === "all") {
            history.push(PAGE_URL);
            setLocalFilters([]);
            return;
        }

        setLocalFilters(prevState => {
            let newFilters = prevState;
            if (prevState.includes(id)) {
                newFilters = prevState.filter(x => x !== id);
            } else {
                newFilters = [...prevState, id];
            }

            if (newFilters.length > 0) {
                history.push(`${PAGE_URL}?filters=${newFilters.join(",")}`);
            } else {
                history.push(PAGE_URL);
            }

            return newFilters;
        });
    };

    const pushToSuppliers = () => history.push(PAGE_URL);

    const onLetterFiltersChange = (letterFilterValue) => {
        setLetterFilters(letterFilterValue);
        pushToSuppliers();
    };

    const renderSuppliers = () => (
        <Suppliers
            suppliers={
                (letterFilters.length > 0
                    ? suppliers.filter(x => letterFilters.map(filter => filter.toLowerCase()).includes(x.name.toLowerCase().substring(0, 1)))
                    : suppliers) as any
            }
            onSupplierClick={onSupplierClick}
        />);

    const addToItemBasket = (service, quantity, variations) => addToBasket({
        productId: service.id,
        quantity,
        variations,
    });

    const goBack = () => {
        history.push("/");
    };

    const miniSiteRef = useRef<HTMLDivElement>(null);
    const [miniSiteHeight, setMiniSiteHeight] = useState("100vh");
    useEffect(() => {
        if (miniSiteRef.current?.clientHeight) {
            const height = Math.max(miniSiteRef.current?.clientHeight, 960);
            setMiniSiteHeight(`${height}px`);
        }
    });

    const renderSuppliersWithMiniSite = () => {
        const suppliersToRender = (letterFilters.length > 0 ? suppliers.filter(x => letterFilters.includes(x.name.substring(0, 1))) : suppliers);
        return (
            <div className="tw-flex tw-flex-col xl:tw-grid xl:tw-grid-cols-[1fr_3fr]">
                {/* Bootstrap's overflow-y-auto uses display:block, overriding hidden and flex.
                    Tailwind's overflow does not render the custom scrollbar by default.
                    Making hidden and flex use !important is a compromise until we create a Tailwind equivalent for the custom scrollbar.
                    - RRW 10/06/2022 */}
                <div
                    className={classnames("!tw-hidden tw-pt-2 tw-pr-2 tw-space-y-3 overflow-y-auto", query.cart ? "tw-hidden" : "xl:!tw-flex xl:!tw-flex-col")}
                    style={{ height: miniSiteHeight }}
                >
                    {suppliersToRender.map(x => (
                        <div key={x.id + x.categoryName}>
                            <SupplierTile
                                id={x.id}
                                name={x.name}
                                description={x.description}
                                categoryName={x.categories && x.categories.join(", ")}
                                primarySiteColour={x.primarySiteColour}
                                logo={x.logo}
                                rating={x.rating as any}
                                onSupplierClick={() => onSupplierClick(x.id)}
                                selectedSupplierId={match.params.id}
                            />
                        </div>))}
                </div>

                <div className={classnames(query.cart && "tw-hidden xl:tw-flex", "pt-2")}>
                    <div ref={miniSiteRef} className="md:tw-pl-3">
                        <MinisiteContent
                            onClose={pushToSuppliers}
                            changeCategoryTab={changeCategoryTab}
                            activeCategoryTab={getActiveCategory({ activeCategoryTab }, categoriesToDisplay)}
                            addToBasket={addToItemBasket}
                            itemIdsInCart={itemIdsInCart}
                            servicesToDisplay={getServicesToDisplay(services, getActiveCategory({ activeCategoryTab }, categoriesToDisplay))}
                            minisiteType={supplierMiniSite}
                        />
                    </div>
                </div>
            </div>);
    };

    return (
        <div className="tw-w-full 2xl:tw-max-w-[1228px] 3xl:tw-max-w-[1920px] tw-mx-auto tw-py-2 md:tw-px-5 md:tw-py-6">
            <div className="tw-bg-supplier-directory-header tw-bg-top-offset tw-bg-no-repeat tw-bg-zoom-in tw-rounded-t-xl tw-pb-4 md:tw-pb-8">
                <div className="m-0 flex-column flex-md-row justify-content-start flex-shrink-0">
                    <div className="tw-px-6 tw-py-1 md:tw-pt-3 md:tw-pb-2 xl:tw-pb-4">
                        <button
                            type="button"
                            onClick={goBack}
                            className="mt-2 p-0 mr-3 bg-transparent border-0 tw-flex tw-flex-row tw-flex-justify-center"
                        >
                            <i className="fa fa-arrow-circle-left mr-2 tw-text-xl tw-text-brand-primary" />
                            <span className="align-self-center d-sm-inline tw-text-sm tw-text-brand-primary">Back</span>
                        </button>
                    </div>
                </div>

                <div className={classnames(
                    "tw-mb-5 tw-w-[90%] md:tw-w-[85%] md:tw-max-w-[53rem] tw-h-24 md:tw-h-32 tw-bg-brand-twilight-blue tw-bg-opacity-90 tw-backdrop-blur-xs",
                    "tw-pl-6 tw-pr-2 tw-py-4 md:tw-pl-6 md:tw-py-6 md:tw-pr-8 md:tw-ml-6 xl:tw-pl-16 xl:tw-ml-0 3xl:tw-ml-7 ",
                )}
                >
                    <p className="tw-text-2xs tw-font-light md:tw-text-2xl xl:tw-text-3xl xl:tw-font-medium tw-text-white tw-tracking-wide md:tw-tracking-normal">
                        <span className="tw-text-2xs tw-font-normal md:tw-text-2xl xl:tw-text-3xl xl:tw-font-semibold tw-text-brand-accent-yellow">
                            Thousands
                        </span> of suppliers in the BSC Supplier Directory
                    </p>
                    <p className={classnames(
                        "tw-flex tw-flex-row tw-justify-start tw-text-3xs tw-font-thin md:tw-text-lg xl:tw-text-xl xl:tw-font-normal",
                        "tw-tracking-wider md:tw-tracking-wide tw-text-white",
                    )}
                    >
                        Or why not try our Marketplace to compare products directly
                        <button type="submit" onClick={onMarketPlaceClick}>
                            <i className="fa fa-arrow-circle-right tw-ml-2 tw-text-base
                                -tw-mt-1 md:-tw-mt-0.5 md:tw-text-xl xl:tw-text-2xl xl:-tw-mt-1 tw-text-brand-accent-yellow"
                            />
                        </button>
                    </p>
                </div>

                <div className={classnames(
                    "tw-m-0 tw-px-0 tw-pt-0.5 md:tw-pt-2 xl:tw-pt-0 flex-shrink-0 tw-bg-transparent tw-h-[5.5rem] md:tw-h-32 tw-w-[95%]",
                    "tw-mx-auto xl:tw-ml-7 xl:tw-mr-0 xl:tw-max-w-[60rem] 2xl:tw-ml-7",
                )}
                >
                    <AtoZFilter
                        onSearch={e => searchDebounce(e.target.value)}
                        goBack={goBack}
                        onLetterFilter={onLetterFiltersChange}
                        onCategoryFilter={onCategoriesFiltersChange}
                        categories={filters}
                        selectedCategories={localFilters}
                        selectedLetters={letterFilters}
                        isReskin2022
                    />
                </div>
            </div>
            {loading ? <Spinner className="tw-mx-auto tw-my-10" />
                : (
                    <div className="tw-mt-5 tw-mb-3">
                        <Route
                            render={({ match: { url } }) => (
                                <Switch>
                                    <Route exact path="/marketplace/supplier-directory/:id" render={renderSuppliersWithMiniSite} />
                                    <Route path={`${url}/`} render={renderSuppliers} />
                                </Switch>
                            )}
                        />
                    </div>
                )}
        </div>
    );
};

SupplierDirectoryPage.propTypes = {
    style: PropTypes.shape({}),
};

SupplierDirectoryPage.defaultProps = {
    style: {},
};

export { SupplierDirectoryPage };
