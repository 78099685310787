import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Button } from "reactstrap";

import { Document } from "@/Apis/Documents";
import { useForm } from "@/Hooks/useForm";
import { DebounceTextbox } from "@/Components/DebounceTextbox";
import formatDateHelper from "@/Utils/formatDateHelper";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { useDocuments } from "@/Hooks/Documents";
import { Urls } from "@/Apis/urls";
import Spinner from "@/Components/Spinner";
import { usePagination } from "@/Hooks/Pagination";
import { SendDocumentNotificationModal } from "@/Modals/Documents/SendDocumentNotificationModal";

interface SearchDocumentForm {
    receivingUserId: string,
    userEmail: string,
    companyName: string,
    orderNumber: string
    search: string,
    isRmc: boolean,
}

const DocumentSearch = () => {
    document.title = "BSC - Document Search";

    const location = useLocation();

    const {
        items: documents,
        loading,
        paging,
        reloadItems,
        setSearchFieldsObject,
        showPreviousPage,
    } = usePagination<Document, SearchDocumentForm>(Urls.documents.get, { pageNumber: 0, pageLength: 5 });

    const {
        onDownload,
        onDelete,
        processingAction,
        toggleDeleteConfirmationModal,
        documentForDelete,
        confirmDeleteModalOpen,
    } = useDocuments(reloadItems, showPreviousPage);

    const {
        onDebounceSearch,
        formState,
    } = useForm<SearchDocumentForm>(location.state);

    useEffect(() => {
        setSearchFieldsObject(formState);
    }, [formState.companyName, formState.search, formState.orderNumber, formState.userEmail]);

    // Send notification
    const [sendNotificationModalOpen, setSendNotificationModalOpen] = useState<boolean>(false);
    const toggleNotificationModal = () => setSendNotificationModalOpen(prev => !prev);

    return (
        <div className="tw-p-2 md:tw-p-4 tw-w-full lg:tw-max-w-[1240px] 3xl:tw-max-w-[1920px] tw-mx-auto">
            <div className="tw-text-lg lg:tw-text-2xl 2xl:tw-text-3xl tw-font-bold tw-py-4">Documents Management</div>
            <div className="tw-p-2 tw-w-full lg:tw-max-w-[850px] tw-mx-auto">
                <div className="tw-flex tw-flex-col">
                    <div className="tw-border tw-p-3 md:tw-px-7 tw-w-full tw-rounded-md">
                        <h4 className="tw-font-semibold">Search Documents</h4>
                        <div className="tw-flex tw-flex-col tw-py-2 md:tw-py-0 md:tw-flex-row md:tw-space-x-5">
                            <DebounceTextbox
                                label="Customer Email Address"
                                callBack={onDebounceSearch("userEmail")}
                                value={formState.userEmail}
                                placeholder="Email address"
                                disabled // This field comes from location state, user must go back to edit
                            />
                            <DebounceTextbox
                                label="Company Name"
                                callBack={onDebounceSearch("companyName")}
                                value={formState.companyName}
                                placeholder="Company name"
                                disabled // This field comes from location state, user must go back to edit
                            />
                            <DebounceTextbox
                                label="Order Number"
                                callBack={onDebounceSearch("orderNumber")}
                                name="orderNumber"
                                value={formState.orderNumber}
                                placeholder="Order Number"
                            />
                        </div>
                        <DebounceTextbox
                            placeholder="Keyword search"
                            callBack={onDebounceSearch("search")}
                            displaySearchIcon
                            className="md:tw-w-2/3"
                            name="keywordSearch"
                        />
                        <div className="tw-mt-7">
                            <div className="tw-flex tw-flex-col tw-space-y-2">
                                {loading && (
                                    <Spinner className="tw-mx-auto tw-my-10" text="Loading documents..." />
                                )}
                                {documents && !loading && documents.map(document => (
                                    <div
                                        className="tw-flex tw-flex-row tw-justify-between tw-items-center
                                        tw-border tw-border-brand-primary tw-px-1 md:tw-px-3 tw-py-1 tw-rounded-md"
                                        key={document.id}
                                    >
                                        <span className="tw-text-brand-primary tw-overflow-x-hidden">{document.name}</span>
                                        <div className="tw-flex tw-flex-row tw-items-center md:tw-space-x-2">
                                            <span className="tw-italic md:tw-mr-2">{formatDateHelper.format(document.dateCreated, "DD/MM/YYYY")}</span>
                                            <Button color="transparent" onClick={() => onDownload(document.id, document.isChaiDocument)} disabled={processingAction}>
                                                <i className="fa fa-download tw-text-brand-primary" />
                                            </Button>
                                            <Button
                                                color="transparent"
                                                onClick={() => toggleDeleteConfirmationModal(document)}
                                                disabled={processingAction || document.isChaiDocument}
                                            >
                                                <i className="fa fa-trash tw-text-red-600" />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                                {documents && documents.length === 0 && (
                                    <div
                                        className="tw-border-brand-primary tw-py-1 tw-rounded-md"
                                    >
                                        No results
                                    </div>
                                )}
                                {documents && !loading && documents.length > 0 && paging}
                                {documents && !loading && documents.length > 0 && (
                                    <div>
                                        <Button color="primary" onClick={toggleNotificationModal} className="tw-h-9">
                                            Send Notification
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {/* Confirm modal for deleting document */}
                            <ConfirmCancelModal
                                isOpen={confirmDeleteModalOpen}
                                text={`Are you sure you want to delete document "${documentForDelete?.name}"`}
                                onConfirm={onDelete}
                                onClose={toggleDeleteConfirmationModal}
                                buttonConfirmText="Yes"
                            />
                            <SendDocumentNotificationModal
                                isOpen={sendNotificationModalOpen}
                                userId={formState.receivingUserId}
                                onClose={toggleNotificationModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DocumentSearch };
